import {
  mapCapacity1h45rc3eh9p2l as mapCapacity,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  LinkedHashMap1zhqxkxv3xnkl as LinkedHashMap,
  Unit_instance1fbcbse1fwigr as Unit_instance,
  objectMeta213120oau977m as objectMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  VOID7hggqo3abtya as VOID,
  Enum3alwj03lh1n41 as Enum,
  classMetawt99a3kyl3us as classMeta,
  toString1pkumu07cwy4m as toString,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  get_lastIndexx0qsydpfv3mu as get_lastIndex,
  compareTo3ankvs086tmwq as compareTo,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  encodeToByteArray1onwao0uakjfh as encodeToByteArray,
  createThis2j2avj17cvnv2 as createThis,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Charsets_getInstanceqs70pvl4noow as Charsets_getInstance,
  encodeToByteArrayomtvgs5lyogm as encodeToByteArray_0,
  BytePacketBuilder2d5pjgm948a6v as BytePacketBuilder,
  writeShort2gpvs0skw1vt as writeShort,
  writeText338krnmr85lul as writeText,
  readBytes2b47ed7nra7rg as readBytes,
} from './ktor-ktor-io.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Companion {
  constructor() {
    Companion_instance = this;
    var tmp = this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = values();
    var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap.w7(capacity);
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'io.ktor.websocket.Companion.byCodeMap.<anonymous>' call
      var tmp$ret$0 = element.r2j_1;
      destination.r2(tmp$ret$0, element);
    }
    tmp.s2j_1 = destination;
    this.t2j_1 = Codes_INTERNAL_ERROR_getInstance();
  }
  u2j(code) {
    return this.s2j_1.z2(code);
  }
}
class Codes extends Enum {
  constructor(name, ordinal, code) {
    super(name, ordinal);
    this.r2j_1 = code;
  }
}
class CloseReason {
  constructor(code, message) {
    this.v2j_1 = code;
    this.w2j_1 = message;
  }
  x2j() {
    return Companion_getInstance().u2j(this.v2j_1);
  }
  toString() {
    var tmp0_elvis_lhs = this.x2j();
    return 'CloseReason(reason=' + toString(tmp0_elvis_lhs == null ? this.v2j_1 : tmp0_elvis_lhs) + ', message=' + this.w2j_1 + ')';
  }
  hashCode() {
    var result = this.v2j_1;
    result = imul(result, 31) + getStringHashCode(this.w2j_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CloseReason))
      return false;
    var tmp0_other_with_cast = other instanceof CloseReason ? other : THROW_CCE();
    if (!(this.v2j_1 === tmp0_other_with_cast.v2j_1))
      return false;
    if (!(this.w2j_1 === tmp0_other_with_cast.w2j_1))
      return false;
    return true;
  }
}
class NonDisposableHandle {
  nm() {
  }
  toString() {
    return 'NonDisposableHandle';
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    var tmp = this;
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlin.collections.maxByOrNull' call
      var this_0 = values_0();
      // Inline function 'kotlin.collections.isEmpty' call
      if (this_0.length === 0) {
        tmp$ret$1 = null;
        break $l$block_0;
      }
      var maxElem = this_0[0];
      var lastIndex = get_lastIndex(this_0);
      if (lastIndex === 0) {
        tmp$ret$1 = maxElem;
        break $l$block_0;
      }
      // Inline function 'io.ktor.websocket.Companion.maxOpcode.<anonymous>' call
      var maxValue = maxElem.b2k_1;
      var inductionVariable = 1;
      if (inductionVariable <= lastIndex)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var e = this_0[i];
          // Inline function 'io.ktor.websocket.Companion.maxOpcode.<anonymous>' call
          var v = e.b2k_1;
          if (compareTo(maxValue, v) < 0) {
            maxElem = e;
            maxValue = v;
          }
        }
         while (!(i === lastIndex));
      tmp$ret$1 = maxElem;
    }
    tmp.c2k_1 = ensureNotNull(tmp$ret$1).b2k_1;
    var tmp_0 = this;
    var tmp_1 = 0;
    var tmp_2 = this.c2k_1 + 1 | 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_3 = fillArrayVal(Array(tmp_2), null);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp$ret$6;
      $l$block_2: {
        // Inline function 'kotlin.collections.singleOrNull' call
        var single = null;
        var found = false;
        var indexedObject = values_0();
        var inductionVariable_0 = 0;
        var last = indexedObject.length;
        while (inductionVariable_0 < last) {
          var element = indexedObject[inductionVariable_0];
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          // Inline function 'io.ktor.websocket.Companion.byOpcodeArray.<anonymous>' call
          if (element.b2k_1 === tmp_4) {
            if (found) {
              tmp$ret$6 = null;
              break $l$block_2;
            }
            single = element;
            found = true;
          }
        }
        if (!found) {
          tmp$ret$6 = null;
          break $l$block_2;
        }
        tmp$ret$6 = single;
      }
      tmp_3[tmp_4] = tmp$ret$6;
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp_0.d2k_1 = tmp_3;
  }
}
class FrameType extends Enum {
  constructor(name, ordinal, controlFrame, opcode) {
    super(name, ordinal);
    this.a2k_1 = controlFrame;
    this.b2k_1 = opcode;
  }
}
class Frame {
  static t2k(fin, frameType, data, disposableHandle, rsv1, rsv2, rsv3) {
    Companion_getInstance_1();
    disposableHandle = disposableHandle === VOID ? NonDisposableHandle_instance : disposableHandle;
    rsv1 = rsv1 === VOID ? false : rsv1;
    rsv2 = rsv2 === VOID ? false : rsv2;
    rsv3 = rsv3 === VOID ? false : rsv3;
    var $this = createThis(this);
    $this.m2k_1 = fin;
    $this.n2k_1 = frameType;
    $this.o2k_1 = data;
    $this.p2k_1 = disposableHandle;
    $this.q2k_1 = rsv1;
    $this.r2k_1 = rsv2;
    $this.s2k_1 = rsv3;
    return $this;
  }
  toString() {
    return 'Frame ' + this.n2k_1 + ' (fin=' + this.m2k_1 + ', buffer len = ' + this.o2k_1.length + ')';
  }
}
class Binary extends Frame {
  static l2k(fin, data, rsv1, rsv2, rsv3) {
    rsv1 = rsv1 === VOID ? false : rsv1;
    rsv2 = rsv2 === VOID ? false : rsv2;
    rsv3 = rsv3 === VOID ? false : rsv3;
    return this.t2k(fin, FrameType_BINARY_getInstance(), data, NonDisposableHandle_instance, rsv1, rsv2, rsv3);
  }
  static u2k(fin, data) {
    return this.l2k(fin, data, false, false, false);
  }
}
class Text extends Frame {
  static c2l(fin, data, rsv1, rsv2, rsv3) {
    rsv1 = rsv1 === VOID ? false : rsv1;
    rsv2 = rsv2 === VOID ? false : rsv2;
    rsv3 = rsv3 === VOID ? false : rsv3;
    return this.t2k(fin, FrameType_TEXT_getInstance(), data, NonDisposableHandle_instance, rsv1, rsv2, rsv3);
  }
  static d2l(fin, data) {
    return this.c2l(fin, data, false, false, false);
  }
  static e2l(text) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'io.ktor.utils.io.core.toByteArray' call
      var charset = Charsets_getInstance().e1s_1;
      if (charset.equals(Charsets_getInstance().e1s_1)) {
        tmp$ret$0 = encodeToByteArray(text);
        break $l$block;
      }
      tmp$ret$0 = encodeToByteArray_0(charset.i1s(), text, 0, text.length);
    }
    return this.d2l(true, tmp$ret$0);
  }
}
class Close extends Frame {
  static m2l(data) {
    return this.t2k(true, FrameType_CLOSE_getInstance(), data, NonDisposableHandle_instance, false, false, false);
  }
  static n2l(reason) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'io.ktor.utils.io.core.buildPacket' call
      // Inline function 'kotlin.contracts.contract' call
      var builder = new BytePacketBuilder();
      try {
        // Inline function 'io.ktor.websocket.Close.<init>.<anonymous>' call
        writeShort(builder, reason.v2j_1);
        writeText(builder, reason.w2j_1);
        tmp$ret$0 = builder.k1i();
        break $l$block;
      } catch ($p) {
        if ($p instanceof Error) {
          var t = $p;
          builder.ww();
          throw t;
        } else {
          throw $p;
        }
      }
    }
    return this.o2l(tmp$ret$0);
  }
  static o2l(packet) {
    return this.m2l(readBytes(packet));
  }
  static p2l() {
    return this.m2l(Companion_getInstance_1().q2l_1);
  }
}
class Companion_1 {
  constructor() {
    Companion_instance_1 = this;
    this.q2l_1 = new Int8Array(0);
  }
}
//endregion
var Codes_NORMAL_instance;
var Codes_GOING_AWAY_instance;
var Codes_PROTOCOL_ERROR_instance;
var Codes_CANNOT_ACCEPT_instance;
var Codes_CLOSED_ABNORMALLY_instance;
var Codes_NOT_CONSISTENT_instance;
var Codes_VIOLATED_POLICY_instance;
var Codes_TOO_BIG_instance;
var Codes_NO_EXTENSION_instance;
var Codes_INTERNAL_ERROR_instance;
var Codes_SERVICE_RESTART_instance;
var Codes_TRY_AGAIN_LATER_instance;
var Companion_instance;
function Companion_getInstance() {
  Codes_initEntries();
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
function values() {
  return [Codes_NORMAL_getInstance(), Codes_GOING_AWAY_getInstance(), Codes_PROTOCOL_ERROR_getInstance(), Codes_CANNOT_ACCEPT_getInstance(), Codes_CLOSED_ABNORMALLY_getInstance(), Codes_NOT_CONSISTENT_getInstance(), Codes_VIOLATED_POLICY_getInstance(), Codes_TOO_BIG_getInstance(), Codes_NO_EXTENSION_getInstance(), Codes_INTERNAL_ERROR_getInstance(), Codes_SERVICE_RESTART_getInstance(), Codes_TRY_AGAIN_LATER_getInstance()];
}
var Codes_entriesInitialized;
function Codes_initEntries() {
  if (Codes_entriesInitialized)
    return Unit_instance;
  Codes_entriesInitialized = true;
  Codes_NORMAL_instance = new Codes('NORMAL', 0, 1000);
  Codes_GOING_AWAY_instance = new Codes('GOING_AWAY', 1, 1001);
  Codes_PROTOCOL_ERROR_instance = new Codes('PROTOCOL_ERROR', 2, 1002);
  Codes_CANNOT_ACCEPT_instance = new Codes('CANNOT_ACCEPT', 3, 1003);
  Codes_CLOSED_ABNORMALLY_instance = new Codes('CLOSED_ABNORMALLY', 4, 1006);
  Codes_NOT_CONSISTENT_instance = new Codes('NOT_CONSISTENT', 5, 1007);
  Codes_VIOLATED_POLICY_instance = new Codes('VIOLATED_POLICY', 6, 1008);
  Codes_TOO_BIG_instance = new Codes('TOO_BIG', 7, 1009);
  Codes_NO_EXTENSION_instance = new Codes('NO_EXTENSION', 8, 1010);
  Codes_INTERNAL_ERROR_instance = new Codes('INTERNAL_ERROR', 9, 1011);
  Codes_SERVICE_RESTART_instance = new Codes('SERVICE_RESTART', 10, 1012);
  Codes_TRY_AGAIN_LATER_instance = new Codes('TRY_AGAIN_LATER', 11, 1013);
  Companion_getInstance();
}
function Codes_NORMAL_getInstance() {
  Codes_initEntries();
  return Codes_NORMAL_instance;
}
function Codes_GOING_AWAY_getInstance() {
  Codes_initEntries();
  return Codes_GOING_AWAY_instance;
}
function Codes_PROTOCOL_ERROR_getInstance() {
  Codes_initEntries();
  return Codes_PROTOCOL_ERROR_instance;
}
function Codes_CANNOT_ACCEPT_getInstance() {
  Codes_initEntries();
  return Codes_CANNOT_ACCEPT_instance;
}
function Codes_CLOSED_ABNORMALLY_getInstance() {
  Codes_initEntries();
  return Codes_CLOSED_ABNORMALLY_instance;
}
function Codes_NOT_CONSISTENT_getInstance() {
  Codes_initEntries();
  return Codes_NOT_CONSISTENT_instance;
}
function Codes_VIOLATED_POLICY_getInstance() {
  Codes_initEntries();
  return Codes_VIOLATED_POLICY_instance;
}
function Codes_TOO_BIG_getInstance() {
  Codes_initEntries();
  return Codes_TOO_BIG_instance;
}
function Codes_NO_EXTENSION_getInstance() {
  Codes_initEntries();
  return Codes_NO_EXTENSION_instance;
}
function Codes_INTERNAL_ERROR_getInstance() {
  Codes_initEntries();
  return Codes_INTERNAL_ERROR_instance;
}
function Codes_SERVICE_RESTART_getInstance() {
  Codes_initEntries();
  return Codes_SERVICE_RESTART_instance;
}
function Codes_TRY_AGAIN_LATER_getInstance() {
  Codes_initEntries();
  return Codes_TRY_AGAIN_LATER_instance;
}
var NonDisposableHandle_instance;
function NonDisposableHandle_getInstance() {
  return NonDisposableHandle_instance;
}
var FrameType_TEXT_instance;
var FrameType_BINARY_instance;
var FrameType_CLOSE_instance;
var FrameType_PING_instance;
var FrameType_PONG_instance;
var Companion_instance_0;
function Companion_getInstance_0() {
  FrameType_initEntries();
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function values_0() {
  return [FrameType_TEXT_getInstance(), FrameType_BINARY_getInstance(), FrameType_CLOSE_getInstance(), FrameType_PING_getInstance(), FrameType_PONG_getInstance()];
}
var FrameType_entriesInitialized;
function FrameType_initEntries() {
  if (FrameType_entriesInitialized)
    return Unit_instance;
  FrameType_entriesInitialized = true;
  FrameType_TEXT_instance = new FrameType('TEXT', 0, false, 1);
  FrameType_BINARY_instance = new FrameType('BINARY', 1, false, 2);
  FrameType_CLOSE_instance = new FrameType('CLOSE', 2, true, 8);
  FrameType_PING_instance = new FrameType('PING', 3, true, 9);
  FrameType_PONG_instance = new FrameType('PONG', 4, true, 10);
  Companion_getInstance_0();
}
function FrameType_TEXT_getInstance() {
  FrameType_initEntries();
  return FrameType_TEXT_instance;
}
function FrameType_BINARY_getInstance() {
  FrameType_initEntries();
  return FrameType_BINARY_instance;
}
function FrameType_CLOSE_getInstance() {
  FrameType_initEntries();
  return FrameType_CLOSE_instance;
}
function FrameType_PING_getInstance() {
  FrameType_initEntries();
  return FrameType_PING_instance;
}
function FrameType_PONG_getInstance() {
  FrameType_initEntries();
  return FrameType_PONG_instance;
}
var Companion_instance_1;
function Companion_getInstance_1() {
  if (Companion_instance_1 === VOID)
    new Companion_1();
  return Companion_instance_1;
}
//region block: post-declaration
setMetadataFor(Companion, 'Companion', objectMeta);
setMetadataFor(Codes, 'Codes', classMeta);
setMetadataFor(CloseReason, 'CloseReason', classMeta);
setMetadataFor(NonDisposableHandle, 'NonDisposableHandle', objectMeta);
setMetadataFor(Companion_0, 'Companion', objectMeta);
setMetadataFor(FrameType, 'FrameType', classMeta);
setMetadataFor(Frame, 'Frame', classMeta);
setMetadataFor(Binary, 'Binary', classMeta);
setMetadataFor(Text, 'Text', classMeta);
setMetadataFor(Close, 'Close', classMeta, VOID, VOID, Close.p2l);
setMetadataFor(Companion_1, 'Companion', objectMeta);
//endregion
//region block: init
NonDisposableHandle_instance = new NonDisposableHandle();
//endregion
//region block: exports
export {
  Codes_CLOSED_ABNORMALLY_getInstance as Codes_CLOSED_ABNORMALLY_getInstance20xmcavfn1j8v,
  Codes_NORMAL_getInstance as Codes_NORMAL_getInstance33u9lfki98gcj,
  Companion_getInstance as Companion_getInstance3hj6iykoiauw8,
  CloseReason as CloseReason10cphaqpp3ct7,
  Binary as Binary3tlzyfojm51s4,
  Close as Close3tx65evcwi73t,
  Text as Text3e6ukp9joohql,
};
//endregion


