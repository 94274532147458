import {
  Unit_instance1fbcbse1fwigr as Unit_instance,
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  createThis2j2avj17cvnv2 as createThis,
  StringBuildermazzzhj6kkai as StringBuilder,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  interfaceMeta1u1l5puptm1ve as interfaceMeta,
  protoOf180f3jzyo7rfj as protoOf,
  objectMeta213120oau977m as objectMeta,
  VOID7hggqo3abtya as VOID,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  Enum3alwj03lh1n41 as Enum,
  stackTraceToString2670q6lbhdojj as stackTraceToString,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class BaseLogger {
  constructor(config) {
    this.cl_1 = config;
  }
  dl() {
    return this.cl_1;
  }
  el(severity, tag, throwable, message) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = this.dl().fl().r();
    while (tmp0_iterator.s()) {
      var element = tmp0_iterator.u();
      // Inline function 'co.touchlab.kermit.BaseLogger.processLog.<anonymous>' call
      if (element.hl(tag, severity)) {
        element.gl(severity, message, tag, throwable);
      }
    }
  }
}
class LogWriter {
  static il() {
    return createThis(this);
  }
  hl(tag, severity) {
    return true;
  }
}
class MessageStringFormatter {}
function formatSeverity(severity) {
  return '' + severity + ':';
}
function formatTag(tag) {
  return '(' + _Tag___get_tag__impl__7z9hd6(tag) + ')';
}
function formatMessage(severity, tag, message) {
  var tmp;
  if (severity == null) {
    var tmp_0 = tag;
    tmp = (tmp_0 == null ? null : new Tag(tmp_0)) == null;
  } else {
    tmp = false;
  }
  if (tmp)
    return _Message___get_message__impl__3t69n4(message);
  var sb = StringBuilder.ma();
  if (!(severity == null)) {
    sb.i7(this.jl(severity)).i7(' ');
  }
  var tmp_1;
  var tmp_2 = tag;
  if (!((tmp_2 == null ? null : new Tag(tmp_2)) == null)) {
    // Inline function 'kotlin.text.isNotEmpty' call
    var this_0 = _Tag___get_tag__impl__7z9hd6(tag);
    tmp_1 = charSequenceLength(this_0) > 0;
  } else {
    tmp_1 = false;
  }
  if (tmp_1) {
    sb.i7(this.kl(tag)).i7(' ');
  }
  sb.i7(_Message___get_message__impl__3t69n4(message));
  return sb.toString();
}
class DefaultFormatter {}
class Tag {
  constructor(tag) {
    this.ml_1 = tag;
  }
  toString() {
    return Tag__toString_impl_tvevk7(this.ml_1);
  }
  hashCode() {
    return Tag__hashCode_impl_848yrc(this.ml_1);
  }
  equals(other) {
    return Tag__equals_impl_6ocp5g(this.ml_1, other);
  }
}
class Severity extends Enum {}
class ConsoleWriter extends LogWriter {
  static pl(messageStringFormatter, console) {
    var $this = this.il();
    $this.nl_1 = messageStringFormatter;
    $this.ol_1 = console;
    return $this;
  }
  static ql(messageStringFormatter) {
    messageStringFormatter = messageStringFormatter === VOID ? DefaultFormatter_instance : messageStringFormatter;
    return this.pl(messageStringFormatter, ConsoleActual_instance);
  }
  gl(severity, message, tag, throwable) {
    var output = this.nl_1.ll(null, _Tag___init__impl__opaqzl(tag), _Message___init__impl__p3e8y6(message));
    if (throwable == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      output = output + (' ' + stackTraceToString(throwable));
    }
    switch (severity.dd_1) {
      case 5:
      case 4:
        this.ol_1.rl(output);
        break;
      case 3:
        this.ol_1.sl(output);
        break;
      case 2:
        this.ol_1.tl(output);
        break;
      case 1:
      case 0:
        this.ol_1.ul(output);
        break;
    }
  }
}
class JsMutableLoggerConfig {
  constructor(logWriters) {
    this.vl_1 = get_DEFAULT_MIN_SEVERITY();
    this.wl_1 = logWriters;
  }
  xl() {
    return this.vl_1;
  }
  fl() {
    return this.wl_1;
  }
}
class ConsoleActual {
  rl(output) {
    console.error(output);
  }
  sl(output) {
    console.warn(output);
  }
  tl(output) {
    console.info(output);
  }
  ul(output) {
    console.log(output);
  }
}
//endregion
function get_DEFAULT_MIN_SEVERITY() {
  _init_properties_BaseLogger_kt__lobnq7();
  return DEFAULT_MIN_SEVERITY;
}
var DEFAULT_MIN_SEVERITY;
var properties_initialized_BaseLogger_kt_e6qv19;
function _init_properties_BaseLogger_kt__lobnq7() {
  if (!properties_initialized_BaseLogger_kt_e6qv19) {
    properties_initialized_BaseLogger_kt_e6qv19 = true;
    DEFAULT_MIN_SEVERITY = Severity_Verbose_getInstance();
  }
}
var DefaultFormatter_instance;
function DefaultFormatter_getInstance() {
  return DefaultFormatter_instance;
}
function _Tag___init__impl__opaqzl(tag) {
  return tag;
}
function _Tag___get_tag__impl__7z9hd6($this) {
  return $this;
}
function Tag__toString_impl_tvevk7($this) {
  return 'Tag(tag=' + $this + ')';
}
function Tag__hashCode_impl_848yrc($this) {
  return getStringHashCode($this);
}
function Tag__equals_impl_6ocp5g($this, other) {
  if (!(other instanceof Tag))
    return false;
  if (!($this === (other instanceof Tag ? other.ml_1 : THROW_CCE())))
    return false;
  return true;
}
function _Message___init__impl__p3e8y6(message) {
  return message;
}
function _Message___get_message__impl__3t69n4($this) {
  return $this;
}
var Severity_Verbose_instance;
var Severity_Debug_instance;
var Severity_Info_instance;
var Severity_Warn_instance;
var Severity_Error_instance;
var Severity_Assert_instance;
var Severity_entriesInitialized;
function Severity_initEntries() {
  if (Severity_entriesInitialized)
    return Unit_instance;
  Severity_entriesInitialized = true;
  Severity_Verbose_instance = new Severity('Verbose', 0);
  Severity_Debug_instance = new Severity('Debug', 1);
  Severity_Info_instance = new Severity('Info', 2);
  Severity_Warn_instance = new Severity('Warn', 3);
  Severity_Error_instance = new Severity('Error', 4);
  Severity_Assert_instance = new Severity('Assert', 5);
}
function Severity_Verbose_getInstance() {
  Severity_initEntries();
  return Severity_Verbose_instance;
}
function Severity_Debug_getInstance() {
  Severity_initEntries();
  return Severity_Debug_instance;
}
function Severity_Info_getInstance() {
  Severity_initEntries();
  return Severity_Info_instance;
}
function Severity_Warn_getInstance() {
  Severity_initEntries();
  return Severity_Warn_instance;
}
function Severity_Error_getInstance() {
  Severity_initEntries();
  return Severity_Error_instance;
}
function mutableLoggerConfigInit(logWriters) {
  return new JsMutableLoggerConfig(logWriters);
}
function platformLogWriter(messageStringFormatter) {
  messageStringFormatter = messageStringFormatter === VOID ? DefaultFormatter_instance : messageStringFormatter;
  return ConsoleWriter.ql();
}
var ConsoleActual_instance;
function ConsoleActual_getInstance() {
  return ConsoleActual_instance;
}
//region block: post-declaration
setMetadataFor(BaseLogger, 'BaseLogger', classMeta);
setMetadataFor(LogWriter, 'LogWriter', classMeta);
setMetadataFor(MessageStringFormatter, 'MessageStringFormatter', interfaceMeta);
protoOf(DefaultFormatter).jl = formatSeverity;
protoOf(DefaultFormatter).kl = formatTag;
protoOf(DefaultFormatter).ll = formatMessage;
setMetadataFor(DefaultFormatter, 'DefaultFormatter', objectMeta, VOID, [MessageStringFormatter]);
setMetadataFor(Tag, 'Tag', classMeta);
setMetadataFor(Severity, 'Severity', classMeta);
setMetadataFor(ConsoleWriter, 'ConsoleWriter', classMeta, VOID, VOID, ConsoleWriter.ql);
setMetadataFor(JsMutableLoggerConfig, 'JsMutableLoggerConfig', classMeta);
setMetadataFor(ConsoleActual, 'ConsoleActual', objectMeta);
//endregion
//region block: init
DefaultFormatter_instance = new DefaultFormatter();
ConsoleActual_instance = new ConsoleActual();
//endregion
//region block: exports
export {
  BaseLogger as BaseLogger15vxnko5xe4r1,
  mutableLoggerConfigInit as mutableLoggerConfigInit3a48t6gios6eh,
  platformLogWriter as platformLogWritersobyk8c3gam2,
  Severity_Debug_getInstance as Severity_Debug_getInstance18zvrvco9dvwq,
  Severity_Error_getInstance as Severity_Error_getInstancebf6hy57pmkme,
  Severity_Info_getInstance as Severity_Info_getInstance1vh392miohgvt,
  Severity_Warn_getInstance as Severity_Warn_getInstance8tpow653hun6,
};
//endregion


