import {
  CoroutineImpl2sn3kjnwmfr10 as CoroutineImpl,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  Unit_instance1fbcbse1fwigr as Unit_instance,
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  toString1pkumu07cwy4m as toString,
  toLongw1zpgk99d84b as toLong,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  VOID7hggqo3abtya as VOID,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  Long2qws0ah9gnpki as Long,
  StringBuildermazzzhj6kkai as StringBuilder,
  protoOf180f3jzyo7rfj as protoOf,
  CancellationException3b36o9qz53rgr as CancellationException,
  KMutableProperty11e8g1gb0ecb9j as KMutableProperty1,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  IntCompanionObject_instance3tw56cgyd5vup as IntCompanionObject_instance,
  Companion_getInstance3gn12jgnf4xoo as Companion_getInstance,
  captureStack1fzi4aczwc4hg as captureStack,
  EmptyCoroutineContext_getInstance31fow51ayy30t as EmptyCoroutineContext_getInstance,
  interfaceMeta1u1l5puptm1ve as interfaceMeta,
  isInterface3d6p8outrmvmk as isInterface,
  equals2au1ep9vhcato as equals,
  UnsupportedOperationException2tkumpmhredt3 as UnsupportedOperationException,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  objectMeta213120oau977m as objectMeta,
  Exceptiondt2hlxn7j7vw as Exception,
  numberToChar93r9buh19yek as numberToChar,
  createThis2j2avj17cvnv2 as createThis,
  coerceAtLeast2bkz8m9ik7hep as coerceAtLeast,
  coerceAtMost322komnqp70ag as coerceAtMost,
  Char__toInt_impl_vasixd2xlaiz5u3itpv as Char__toInt_impl_vasixd,
  toByte4i43936u611k as toByte,
  _UShort___get_data__impl__g02459z5yfs6z0kj0 as _UShort___get_data__impl__g0245,
  toShort36kaw0zjdq3ex as toShort,
  _UShort___init__impl__jigrne3h2nm35iaibum as _UShort___init__impl__jigrne,
  Companion_getInstance2du03jiluw9jj as Companion_getInstance_0,
  charSequenceGet1vxk1y5n17t1z as charSequenceGet,
  isLowSurrogateujxcv7hjn4ma as isLowSurrogate,
  isHighSurrogate11jfjw70ar0zf as isHighSurrogate,
  _Char___init__impl__6a9atx281r2pd9o601g as _Char___init__impl__6a9atx,
  coerceAtMostmgknbongtqt as coerceAtMost_0,
  boxApply1qmzdb3dh90hg as boxApply,
  hashCodeq5arwsb9dgti as hashCode,
  lazy2hsh8ze7j6ikd as lazy,
  KProperty1ca4yb4wlo496 as KProperty1,
  IndexOutOfBoundsException1qfr429iumro0 as IndexOutOfBoundsException,
  replaceqbix900hl8kl as replace,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  setPropertiesToThrowableInstance1w2jjvl9y77yc as setPropertiesToThrowableInstance,
  charSequenceSubSequence1iwpdba8s3jc7 as charSequenceSubSequence,
  isCharSequence1ju9jr1w86plq as isCharSequence,
  trim11nh7r46at6sx as trim,
  decodeToString1x4faah2liw2p as decodeToString,
  setOf45ia9pnfhe90 as setOf,
  fillArrayVali8eppxapiek4 as fillArrayVal,
} from './kotlin-kotlin-stdlib.mjs';
import {
  atomic$ref$130aurmcwdfdf1 as atomic$ref$1,
  atomic$long$129k9zwo6n9ogd as atomic$long$1,
  atomic$int$11d5swdyn6j0pu as atomic$int$1,
  atomic$boolean$1iggki4z65a2h as atomic$boolean$1,
} from './kotlinx-atomicfu.mjs';
import {
  CoroutineScopefcb5f5dwqcas as CoroutineScope,
  cancel$default2y9qiqa2pd5sh as cancel$default,
  invokeOnCompletion$default32r7l1ykbkzvm as invokeOnCompletion$default,
  Job29shfjfygy86k as Job,
  Key_getInstance2js7sv2agqob1 as Key_getInstance,
  launch1c91vkjzdi9sd as launch,
  Key_instance2tirv2rj82ml4 as Key_instance,
  Dispatchers_getInstanceitgtkvqfcnx3 as Dispatchers_getInstance,
  Job13y4jkazwjho0 as Job_0,
} from './kotlinx-coroutines-core.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class $tryAwaitCOROUTINE$49 extends CoroutineImpl {
  constructor(_this__u8e3s4, n, resultContinuation) {
    super(resultContinuation);
    this.g1b_1 = _this__u8e3s4;
    this.h1b_1 = n;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            if (this.g1b_1.i1b_1.y1b() < this.h1b_1) {
              this.te_1 = 1;
              suspendResult = this.g1b_1.i1b_1.x1b(this.h1b_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 1:
            this.te_1 = 2;
            continue $sm;
          case 2:
            return Unit_instance;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class ByteChannelSequentialBase$beginWriteSession$1 {
  constructor(this$0) {
    this.i1b_1 = this$0;
  }
  r1e(min) {
    if (this.i1b_1.y1b() === 0)
      return null;
    return this.i1b_1.q1b_1.s1e(min);
  }
  t1e(n, $completion) {
    var tmp = new $tryAwaitCOROUTINE$49(this, n, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
}
class ByteChannelSequentialBase$readUTF8LineTo$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.c1f_1 = this$0;
    super(resultContinuation, $box);
  }
  f1f(size, $completion) {
    var tmp = this.g1f(size, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.f1f((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.te_1 = 1;
            suspendResult = this.c1f_1.h1f(this.d1f_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            if (suspendResult) {
              var tmp_0 = this;
              tmp_0.e1f_1 = this.c1f_1.r1b_1;
              this.te_1 = 2;
              continue $sm;
            } else {
              var tmp_1 = this;
              tmp_1.e1f_1 = null;
              this.te_1 = 2;
              continue $sm;
            }

          case 2:
            return this.e1f_1;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  g1f(size, completion) {
    var i = new ByteChannelSequentialBase$readUTF8LineTo$slambda(this.c1f_1, completion);
    i.d1f_1 = size;
    return i;
  }
}
class $awaitAtLeastNBytesAvailableForWriteCOROUTINE$0 extends CoroutineImpl {
  constructor(_this__u8e3s4, count, resultContinuation) {
    super(resultContinuation);
    this.q1f_1 = _this__u8e3s4;
    this.r1f_1 = count;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!(this.q1f_1.y1b() < this.r1f_1 ? !this.q1f_1.c1d() : false)) {
              this.te_1 = 5;
              continue $sm;
            }

            if (!flushImpl(this.q1f_1)) {
              this.te_1 = 2;
              suspendResult = this.q1f_1.u1b_1.s1f(ByteChannelSequentialBase$awaitAtLeastNBytesAvailableForWrite$lambda(this.q1f_1, this.r1f_1), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 3;
              continue $sm;
            }

          case 2:
            this.te_1 = 3;
            continue $sm;
          case 3:
            this.te_1 = 1;
            continue $sm;
          case 4:
            throw this.we_1;
          case 5:
            return Unit_instance;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $awaitAtLeastNBytesAvailableForReadCOROUTINE$1 extends CoroutineImpl {
  constructor(_this__u8e3s4, count, resultContinuation) {
    super(resultContinuation);
    this.b1g_1 = _this__u8e3s4;
    this.c1g_1 = count;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!(this.b1g_1.p1e() < this.c1g_1 ? !this.b1g_1.q1e() : false)) {
              this.te_1 = 4;
              continue $sm;
            }

            this.te_1 = 2;
            suspendResult = this.b1g_1.u1b_1.s1f(ByteChannelSequentialBase$awaitAtLeastNBytesAvailableForRead$lambda(this.b1g_1, this.c1g_1), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.te_1 = 1;
            continue $sm;
          case 3:
            throw this.we_1;
          case 4:
            return Unit_instance;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $writePacketCOROUTINE$8 extends CoroutineImpl {
  constructor(_this__u8e3s4, packet, resultContinuation) {
    super(resultContinuation);
    this.l1g_1 = _this__u8e3s4;
    this.m1g_1 = packet;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.l1g_1.x1b(1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var size = this.m1g_1.u1g().qe();
            this.l1g_1.q1b_1.v1g(this.m1g_1);
            this.l1g_1.w1g(size);
            return Unit_instance;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $writeFullyCOROUTINE$9 extends CoroutineImpl {
  constructor(_this__u8e3s4, src, resultContinuation) {
    super(resultContinuation);
    this.f1h_1 = _this__u8e3s4;
    this.g1h_1 = src;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.f1h_1.x1b(1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var this_0 = this.g1h_1;
            var count = this_0.r1d_1 - this_0.q1d_1 | 0;
            writeFully_2(this.f1h_1.q1b_1, this.g1h_1);
            this.f1h_1.w1g(count);
            return Unit_instance;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $writeFullyCOROUTINE$10 extends CoroutineImpl {
  constructor(_this__u8e3s4, src, offset, length, resultContinuation) {
    super(resultContinuation);
    this.p1h_1 = _this__u8e3s4;
    this.q1h_1 = src;
    this.r1h_1 = offset;
    this.s1h_1 = length;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            this.t1h_1 = this.r1h_1;
            this.u1h_1 = this.r1h_1 + this.s1h_1 | 0;
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!(this.t1h_1 < this.u1h_1)) {
              this.te_1 = 3;
              continue $sm;
            }

            this.te_1 = 2;
            suspendResult = this.p1h_1.x1b(1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var a = this.p1h_1.y1b();
            var b = this.u1h_1 - this.t1h_1 | 0;
            var bytesCount = Math.min(a, b);
            writeFully_3(this.p1h_1.q1b_1, this.q1h_1, this.t1h_1, bytesCount);
            this.t1h_1 = this.t1h_1 + bytesCount | 0;
            this.p1h_1.w1g(bytesCount);
            this.te_1 = 1;
            continue $sm;
          case 3:
            return Unit_instance;
          case 4:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $readRemainingCOROUTINE$26 extends CoroutineImpl {
  constructor(_this__u8e3s4, limit, resultContinuation) {
    super(resultContinuation);
    this.d1i_1 = _this__u8e3s4;
    this.e1i_1 = limit;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            ensureNotFailed(this.d1i_1);
            this.f1i_1 = new BytePacketBuilder();
            var tmp_0 = this;
            var a = this.e1i_1;
            var b = this.d1i_1.r1b_1.u1g();
            tmp_0.g1i_1 = a.oc(b) <= 0 ? a : b;
            this.f1i_1.j1i(this.d1i_1.r1b_1, this.g1i_1);
            this.d1i_1.x1d(this.g1i_1.qe());
            var tmp_1 = this;
            var this_0 = this.e1i_1;
            var other = this.f1i_1.k();
            tmp_1.h1i_1 = this_0.fe(toLong(other));
            if (this.h1i_1.equals(new Long(0, 0)) ? true : this.d1i_1.q1e()) {
              var tmp_2 = this;
              ensureNotFailed_0(this.d1i_1, this.f1i_1);
              tmp_2.i1i_1 = this.f1i_1.k1i();
              this.te_1 = 2;
              continue $sm;
            } else {
              this.te_1 = 1;
              suspendResult = readRemainingSuspend(this.d1i_1, this.f1i_1, this.e1i_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

          case 1:
            this.i1i_1 = suspendResult;
            this.te_1 = 2;
            continue $sm;
          case 2:
            return this.i1i_1;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $readRemainingSuspendCOROUTINE$27 extends CoroutineImpl {
  constructor(_this__u8e3s4, builder, limit, resultContinuation) {
    super(resultContinuation);
    this.l1d_1 = _this__u8e3s4;
    this.m1d_1 = builder;
    this.n1d_1 = limit;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 5;
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!(toLong(this.m1d_1.k()).oc(this.n1d_1) < 0)) {
              this.te_1 = 4;
              continue $sm;
            }

            var tmp_0 = this;
            var this_0 = this.n1d_1;
            var other = this.m1d_1.k();
            var a = this_0.fe(toLong(other));
            var b = this.l1d_1.r1b_1.u1g();
            tmp_0.o1d_1 = a.oc(b) <= 0 ? a : b;
            this.m1d_1.j1i(this.l1d_1.r1b_1, this.o1d_1);
            this.l1d_1.x1d(this.o1d_1.qe());
            ensureNotFailed_0(this.l1d_1, this.m1d_1);
            if (this.l1d_1.q1e() ? true : this.m1d_1.k() === this.n1d_1.qe()) {
              this.te_1 = 4;
              continue $sm;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 2:
            this.te_1 = 3;
            suspendResult = this.l1d_1.l1i(1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            this.te_1 = 1;
            continue $sm;
          case 4:
            ensureNotFailed_0(this.l1d_1, this.m1d_1);
            return this.m1d_1.k1i();
          case 5:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 5) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $readAvailableCOROUTINE$30 extends CoroutineImpl {
  constructor(_this__u8e3s4, dst, resultContinuation) {
    super(resultContinuation);
    this.u1i_1 = _this__u8e3s4;
    this.v1i_1 = dst;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            var tmp0_safe_receiver = this.u1i_1.v1c();
            if (tmp0_safe_receiver == null)
              null;
            else {
              throw tmp0_safe_receiver;
            }

            if (this.u1i_1.c1d() ? this.u1i_1.p1e() === 0 : false)
              return -1;
            var this_0 = this.v1i_1;
            if ((this_0.t1d_1 - this_0.r1d_1 | 0) === 0)
              return 0;
            if (this.u1i_1.p1e() === 0) {
              this.te_1 = 1;
              suspendResult = this.u1i_1.l1i(1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 1:
            this.te_1 = 2;
            continue $sm;
          case 2:
            if (!this.u1i_1.r1b_1.x1i()) {
              this.u1i_1.w1i();
            }

            var this_1 = this.v1i_1;
            var a = toLong(this_1.t1d_1 - this_1.r1d_1 | 0);
            var b = this.u1i_1.r1b_1.u1g();
            var size = (a.oc(b) <= 0 ? a : b).qe();
            readFully_2(this.u1i_1.r1b_1, this.v1i_1, size);
            this.u1i_1.x1d(size);
            return size;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $readAvailableCOROUTINE$33 extends CoroutineImpl {
  constructor(_this__u8e3s4, dst, offset, length, resultContinuation) {
    super(resultContinuation);
    this.g1j_1 = _this__u8e3s4;
    this.h1j_1 = dst;
    this.i1j_1 = offset;
    this.j1j_1 = length;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            var tmp0_safe_receiver = this.g1j_1.v1c();
            if (tmp0_safe_receiver == null)
              null;
            else {
              throw tmp0_safe_receiver;
            }

            if (this.g1j_1.c1d() ? this.g1j_1.p1e() === 0 : false)
              return -1;
            if (this.j1j_1 === 0)
              return 0;
            if (this.g1j_1.p1e() === 0) {
              this.te_1 = 1;
              suspendResult = this.g1j_1.l1i(1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 1:
            this.te_1 = 2;
            continue $sm;
          case 2:
            if (!this.g1j_1.r1b_1.x1i()) {
              this.g1j_1.w1i();
            }

            var a = toLong(this.j1j_1);
            var b = this.g1j_1.r1b_1.u1g();
            var size = (a.oc(b) <= 0 ? a : b).qe();
            readFully_1(this.g1j_1.r1b_1, this.h1j_1, this.i1j_1, size);
            this.g1j_1.x1d(size);
            return size;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $awaitInternalAtLeast1COROUTINE$38 extends CoroutineImpl {
  constructor(_this__u8e3s4, resultContinuation) {
    super(resultContinuation);
    this.s1j_1 = _this__u8e3s4;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            if (!this.s1j_1.r1b_1.u1j()) {
              var tmp_0 = this;
              tmp_0.t1j_1 = true;
              this.te_1 = 3;
              continue $sm;
            } else {
              this.te_1 = 1;
              suspendResult = this.s1j_1.l1i(1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

          case 1:
            this.t1j_1 = suspendResult;
            this.te_1 = 3;
            continue $sm;
          case 2:
            throw this.we_1;
          case 3:
            return this.t1j_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $awaitSuspendCOROUTINE$39 extends CoroutineImpl {
  constructor(_this__u8e3s4, atLeast, resultContinuation) {
    super(resultContinuation);
    this.d1k_1 = _this__u8e3s4;
    this.e1k_1 = atLeast;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            if (!(this.e1k_1 >= 0)) {
              var message = 'Failed requirement.';
              throw IllegalArgumentException.g3(toString(message));
            }

            this.te_1 = 1;
            suspendResult = this.d1k_1.f1k(this.e1k_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.d1k_1.w1i();
            var tmp0_safe_receiver = this.d1k_1.v1c();
            if (tmp0_safe_receiver == null)
              null;
            else {
              throw tmp0_safe_receiver;
            }

            return !this.d1k_1.q1e() ? this.d1k_1.p1e() >= this.e1k_1 : false;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $discardCOROUTINE$40 extends CoroutineImpl {
  constructor(_this__u8e3s4, max, resultContinuation) {
    super(resultContinuation);
    this.o1k_1 = _this__u8e3s4;
    this.p1k_1 = max;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.q1k_1 = this.o1k_1.r1b_1.s1k(this.p1k_1);
            this.o1k_1.x1d(this.q1k_1.qe());
            if (this.q1k_1.equals(this.p1k_1) ? true : this.o1k_1.q1e()) {
              var tmp_0 = this;
              ensureNotFailed(this.o1k_1);
              return this.q1k_1;
            } else {
              this.te_1 = 1;
              suspendResult = discardSuspend(this.o1k_1, this.p1k_1, this.q1k_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

          case 1:
            this.r1k_1 = suspendResult;
            this.te_1 = 2;
            continue $sm;
          case 2:
            return this.r1k_1;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $discardSuspendCOROUTINE$41 extends CoroutineImpl {
  constructor(_this__u8e3s4, max, discarded0, resultContinuation) {
    super(resultContinuation);
    this.l1e_1 = _this__u8e3s4;
    this.m1e_1 = max;
    this.n1e_1 = discarded0;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 5;
            this.o1e_1 = this.n1e_1;
            this.te_1 = 1;
            continue $sm;
          case 1:
            this.te_1 = 2;
            suspendResult = this.l1e_1.h1f(1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var ARGUMENT = suspendResult;
            if (!ARGUMENT) {
              this.te_1 = 4;
              continue $sm;
            } else {
              this.te_1 = 3;
              continue $sm;
            }

          case 3:
            var count = this.l1e_1.r1b_1.s1k(this.m1e_1.fe(this.o1e_1));
            this.l1e_1.x1d(count.qe());
            this.o1e_1 = this.o1e_1.ee(count);
            if (this.o1e_1.oc(this.m1e_1) < 0 ? !this.l1e_1.q1e() : false) {
              this.te_1 = 1;
              continue $sm;
            }

            this.te_1 = 4;
            continue $sm;
          case 4:
            ensureNotFailed(this.l1e_1);
            return this.o1e_1;
          case 5:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 5) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $readUTF8LineCOROUTINE$43 extends CoroutineImpl {
  constructor(_this__u8e3s4, limit, resultContinuation) {
    super(resultContinuation);
    this.b1l_1 = _this__u8e3s4;
    this.c1l_1 = limit;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.d1l_1 = StringBuilder.ma();
            this.te_1 = 1;
            suspendResult = this.b1l_1.e1l(this.d1l_1, this.c1l_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            if (!ARGUMENT) {
              return null;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 2:
            return this.d1l_1.toString();
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class ByteReadChannel {}
function readRemaining$default(limit, $completion, $super) {
  var tmp;
  if (limit === VOID) {
    Companion_getInstance();
    tmp = new Long(-1, 2147483647);
  } else {
    tmp = limit;
  }
  limit = tmp;
  return $super === VOID ? this.v1l(limit, $completion) : $super.v1l.call(this, limit, $completion);
}
class HasWriteSession {}
class ByteChannelSequentialBase {
  constructor(initial, autoFlush, pool) {
    pool = pool === VOID ? Companion_getInstance_4().y1d_1 : pool;
    this.j1b_1 = autoFlush;
    this.k1b_1 = atomic$ref$1(Companion_getInstance_4().a1e_1);
    this.l1b_1 = atomic$long$1(new Long(0, 0));
    this.m1b_1 = atomic$long$1(new Long(0, 0));
    this.n1b_1 = atomic$int$1(0);
    this.o1b_1 = atomic$int$1(0);
    this.p1b_1 = atomic$ref$1(null);
    this.q1b_1 = new BytePacketBuilder(pool);
    this.r1b_1 = ByteReadPacket.m1l(initial, pool);
    this.s1b_1 = atomic$int$1(0);
    this.t1b_1 = atomic$ref$1(Companion_getInstance_4().a1e_1);
    this.u1b_1 = new AwaitingSlot();
    this.v1b_1 = new Object();
    this.w1b_1 = new BytePacketBuilder();
    var count = remainingAll(initial).qe();
    this.w1g(count);
    this.n1b_1.atomicfu$addAndGet(count);
  }
  n1l() {
    return this.j1b_1;
  }
  c1d() {
    return !(this.p1b_1.kotlinx$atomicfu$value == null);
  }
  p1e() {
    return this.n1b_1.kotlinx$atomicfu$value;
  }
  y1b() {
    // Inline function 'kotlin.comparisons.maxOf' call
    var b = 4088 - this.o1b_1.kotlinx$atomicfu$value | 0;
    return Math.max(0, b);
  }
  q1e() {
    return _get_isCancelled__nhbn6y(this) ? true : this.c1d() ? this.o1b_1.kotlinx$atomicfu$value === 0 : false;
  }
  o1l() {
    return this.c1d();
  }
  v1c() {
    var tmp0_safe_receiver = this.p1b_1.kotlinx$atomicfu$value;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.z1b_1;
  }
  x1b(count, $completion) {
    var tmp = new $awaitAtLeastNBytesAvailableForWriteCOROUTINE$0(this, count, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  f1k(count, $completion) {
    var tmp = new $awaitAtLeastNBytesAvailableForReadCOROUTINE$1(this, count, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  i8() {
    flushImpl(this);
  }
  w1i() {
    // Inline function 'kotlinx.atomicfu.locks.synchronized' call
    this.v1b_1;
    // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.prepareFlushedBytes.<anonymous>' call
    unsafeAppend(this.r1b_1, this.w1b_1);
  }
  p1l(packet, $completion) {
    var tmp = new $writePacketCOROUTINE$8(this, packet, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  q1l(src, $completion) {
    var tmp = new $writeFullyCOROUTINE$9(this, src, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  r1l(src, offset, length, $completion) {
    var tmp = new $writeFullyCOROUTINE$10(this, src, offset, length, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  s1l() {
    return new ByteChannelSequentialBase$beginWriteSession$1(this);
  }
  t1l(written) {
    this.q1b_1.u1l();
    this.w1g(written);
  }
  x1d(count) {
    addBytesRead(this, count);
    this.u1b_1.b1c();
  }
  v1l(limit, $completion) {
    var tmp = new $readRemainingCOROUTINE$26(this, limit, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  x1l(dst, $completion) {
    return this.y1l(dst instanceof Buffer ? dst : THROW_CCE(), $completion);
  }
  y1l(dst, $completion) {
    var tmp = new $readAvailableCOROUTINE$30(this, dst, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  z1l(dst, offset, length, $completion) {
    var tmp = new $readAvailableCOROUTINE$33(this, dst, offset, length, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  h1f(atLeast, $completion) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(atLeast >= 0)) {
      // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.await.<anonymous>' call
      var message = "atLeast parameter shouldn't be negative: " + atLeast;
      throw IllegalArgumentException.g3(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(toLong(atLeast).oc(new Long(4088, 0)) <= 0)) {
      // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.await.<anonymous>' call
      var message_0 = "atLeast parameter shouldn't be larger than max buffer size of 4088: " + atLeast;
      throw IllegalArgumentException.g3(toString(message_0));
    }
    completeReading(this);
    if (atLeast === 0)
      return !this.q1e();
    if (this.r1b_1.u1g().oc(toLong(atLeast)) >= 0)
      return true;
    return this.l1i(atLeast, $completion);
  }
  a1m($completion) {
    var tmp = new $awaitInternalAtLeast1COROUTINE$38(this, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  l1i(atLeast, $completion) {
    var tmp = new $awaitSuspendCOROUTINE$39(this, atLeast, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  b1m(max, $completion) {
    var tmp = new $discardCOROUTINE$40(this, max, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  e1l(out, limit, $completion) {
    if (this.q1e()) {
      var cause = this.v1c();
      if (!(cause == null)) {
        throw cause;
      }
      return false;
    }
    var tmp = ByteChannelSequentialBase$readUTF8LineTo$slambda_0(this, null);
    return decodeUTF8LineLoopSuspend(out, limit, tmp, ByteChannelSequentialBase$readUTF8LineTo$lambda(this), $completion);
  }
  c1m(limit, $completion) {
    var tmp = new $readUTF8LineCOROUTINE$43(this, limit, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  nv(cause) {
    if (!(this.v1c() == null) ? true : this.c1d()) {
      return false;
    }
    return this.n15(cause == null ? CancellationException.p8('Channel cancelled') : cause);
  }
  n15(cause) {
    var closeElement = cause == null ? get_CLOSED_SUCCESS() : new CloseElement(cause);
    if (!this.p1b_1.atomicfu$compareAndSet(null, closeElement))
      return false;
    if (!(cause == null)) {
      this.r1b_1.ww();
      this.q1b_1.ww();
      this.w1b_1.ww();
    } else {
      this.i8();
    }
    this.u1b_1.d1m(cause);
    return true;
  }
  e1m(dst, limit) {
    var size = this.r1b_1.u1g();
    var tmp;
    if (size.oc(limit) <= 0) {
      dst.q1b_1.v1g(this.r1b_1);
      dst.w1g(size.qe());
      this.x1d(size.qe());
      tmp = size;
    } else {
      tmp = new Long(0, 0);
    }
    return tmp;
  }
  w1g(count) {
    addBytesWritten(this, count);
    if (this.c1d()) {
      this.q1b_1.ww();
      ensureNotClosed(this);
    }
    if (this.n1l() ? true : this.y1b() === 0) {
      this.i8();
    }
  }
}
class ClosedWriteChannelException extends CancellationException {
  static b1d(message) {
    var $this = this.p8(message);
    captureStack($this, $this.a1d_1);
    return $this;
  }
}
class CloseElement {
  constructor(cause) {
    this.z1b_1 = cause;
  }
}
class WriterScope {}
class ChannelJob {
  constructor(delegate, channel) {
    this.g1m_1 = delegate;
    this.h1m_1 = channel;
  }
  p11() {
    return this.h1m_1;
  }
  zo() {
    return this.g1m_1.zo();
  }
  t2() {
    return this.g1m_1.t2();
  }
  mq(child) {
    return this.g1m_1.mq(child);
  }
  fq(cause) {
    this.g1m_1.fq(cause);
  }
  ni(initial, operation) {
    return this.g1m_1.ni(initial, operation);
  }
  bf(key) {
    return this.g1m_1.bf(key);
  }
  wp() {
    return this.g1m_1.wp();
  }
  aq(onCancelling, invokeImmediately, handler) {
    return this.g1m_1.aq(onCancelling, invokeImmediately, handler);
  }
  zp(handler) {
    return this.g1m_1.zp(handler);
  }
  mi(key) {
    return this.g1m_1.mi(key);
  }
  oi(context) {
    return this.g1m_1.oi(context);
  }
  up() {
    return this.g1m_1.up();
  }
  toString() {
    return 'ChannelJob[' + this.g1m_1 + ']';
  }
}
class ChannelScope {
  constructor(delegate, channel) {
    this.i1m_1 = channel;
    this.j1m_1 = delegate;
  }
  p11() {
    return this.i1m_1;
  }
  yo() {
    return this.j1m_1.yo();
  }
}
class launchChannel$slambda extends CoroutineImpl {
  constructor($attachJob, $channel, $block, $dispatcher, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.s1m_1 = $attachJob;
    $box.t1m_1 = $channel;
    $box.u1m_1 = $block;
    $box.v1m_1 = $dispatcher;
    super(resultContinuation, $box);
  }
  y1m($this$launch, $completion) {
    var tmp = this.z1m($this$launch, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.y1m((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            if (this.s1m_1) {
              this.t1m_1.f1m(ensureNotNull(this.w1m_1.yo().bf(Key_instance)));
            }

            var tmp_0 = this;
            var tmp_1 = new ChannelScope(this.w1m_1, this.t1m_1);
            tmp_0.x1m_1 = isInterface(tmp_1, CoroutineScope) ? tmp_1 : THROW_CCE();
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.u1m_1(this.x1m_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.ue_1 = 3;
            this.te_1 = 4;
            continue $sm;
          case 2:
            this.ue_1 = 3;
            var tmp_2 = this.we_1;
            if (tmp_2 instanceof Error) {
              var cause = this.we_1;
              if (!equals(this.v1m_1, Dispatchers_getInstance().bx_1) ? !(this.v1m_1 == null) : false) {
                throw cause;
              }
              this.t1m_1.nv(cause);
              this.te_1 = 4;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 3:
            throw this.we_1;
          case 4:
            this.ue_1 = 3;
            return Unit_instance;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  z1m($this$launch, completion) {
    var i = new launchChannel$slambda(this.s1m_1, this.t1m_1, this.u1m_1, this.v1m_1, completion);
    i.w1m_1 = $this$launch;
    return i;
  }
}
class $requestWriteBufferCOROUTINE$52 extends CoroutineImpl {
  constructor(_this__u8e3s4, desiredSpace, resultContinuation) {
    super(resultContinuation);
    this.i1n_1 = _this__u8e3s4;
    this.j1n_1 = desiredSpace;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.k1n_1 = writeSessionFor(this.i1n_1);
            if (!(this.k1n_1 == null)) {
              this.l1n_1 = this.k1n_1.r1e(this.j1n_1);
              if (!(this.l1n_1 == null)) {
                return this.l1n_1;
              }
              this.te_1 = 2;
              suspendResult = writeBufferSuspend(this.k1n_1, this.j1n_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 1;
              continue $sm;
            }

          case 1:
            return writeBufferFallback();
          case 2:
            return suspendResult;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $writeBufferSuspendCOROUTINE$53 extends CoroutineImpl {
  constructor(session, desiredSpace, resultContinuation) {
    super(resultContinuation);
    this.u1n_1 = session;
    this.v1n_1 = desiredSpace;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.u1n_1.t1e(this.v1n_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp0_elvis_lhs = this.u1n_1.r1e(this.v1n_1);
            return tmp0_elvis_lhs == null ? this.u1n_1.r1e(1) : tmp0_elvis_lhs;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $completeWritingFallbackCOROUTINE$54 extends CoroutineImpl {
  constructor(_this__u8e3s4, buffer, resultContinuation) {
    super(resultContinuation);
    this.h1o_1 = _this__u8e3s4;
    this.i1o_1 = buffer;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            var tmp_0 = this.i1o_1;
            if (tmp_0 instanceof ChunkBuffer) {
              this.te_1 = 2;
              suspendResult = this.h1o_1.q1l(this.i1o_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 1;
              continue $sm;
            }

          case 1:
            throw UnsupportedOperationException.r5('Only ChunkBuffer instance is supported.');
          case 2:
            this.i1o_1.t1o(Companion_getInstance_4().y1d_1);
            return Unit_instance;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class MalformedInputException extends Error {
  static y1o(message) {
    var $this = createThis(this);
    setPropertiesToThrowableInstance($this, message);
    captureStack($this, $this.x1o_1);
    return $this;
  }
}
class TooLongLineException extends MalformedInputException {
  static w1o(message) {
    var $this = this.y1o(message);
    captureStack($this, $this.v1o_1);
    return $this;
  }
}
class Companion {
  constructor() {
    this.v1d_1 = 8;
  }
  w1d() {
    return Companion_getInstance_4().a1e_1;
  }
}
class Buffer {
  constructor(memory) {
    this.p1d_1 = memory;
    this.q1d_1 = 0;
    this.r1d_1 = 0;
    this.s1d_1 = 0;
    var tmp = this;
    // Inline function 'io.ktor.utils.io.bits.Memory.size32' call
    tmp.t1d_1 = this.p1d_1.z1o_1.byteLength;
    var tmp_0 = this;
    // Inline function 'io.ktor.utils.io.bits.Memory.size32' call
    tmp_0.u1d_1 = this.p1d_1.z1o_1.byteLength;
  }
  a1p(count) {
    if (count === 0)
      return Unit_instance;
    var newReadPosition = this.q1d_1 + count | 0;
    if (count < 0 ? true : newReadPosition > this.r1d_1) {
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var tmp$ret$1 = this.r1d_1 - this.q1d_1 | 0;
      discardFailed(count, tmp$ret$1);
    }
    this.q1d_1 = newReadPosition;
  }
  b1p(count) {
    var newWritePosition = this.r1d_1 + count | 0;
    if (count < 0 ? true : newWritePosition > this.t1d_1) {
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var tmp$ret$0 = this.t1d_1 - this.r1d_1 | 0;
      commitWrittenFailed(count, tmp$ret$0);
    }
    this.r1d_1 = newWritePosition;
  }
  c1p(position) {
    var limit = this.t1d_1;
    if (position < this.r1d_1) {
      var tmp = position - this.r1d_1 | 0;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var tmp$ret$0 = this.t1d_1 - this.r1d_1 | 0;
      commitWrittenFailed(tmp, tmp$ret$0);
    }
    if (position >= limit) {
      if (position === limit) {
        this.r1d_1 = position;
        return false;
      }
      var tmp_0 = position - this.r1d_1 | 0;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var tmp$ret$1 = this.t1d_1 - this.r1d_1 | 0;
      commitWrittenFailed(tmp_0, tmp$ret$1);
    }
    this.r1d_1 = position;
    return true;
  }
  d1p(position) {
    if (position < 0 ? true : position > this.r1d_1) {
      var tmp = position - this.q1d_1 | 0;
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var tmp$ret$0 = this.r1d_1 - this.q1d_1 | 0;
      discardFailed(tmp, tmp$ret$0);
    }
    if (!(this.q1d_1 === position)) {
      this.q1d_1 = position;
    }
  }
  e1p(count) {
    var newReadPosition = this.q1d_1 - count | 0;
    if (newReadPosition < this.s1d_1) {
      rewindFailed(count, this.q1d_1 - this.s1d_1 | 0);
    }
    this.q1d_1 = newReadPosition;
  }
  f1p(startGap) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(startGap >= 0)) {
      // Inline function 'io.ktor.utils.io.core.Buffer.reserveStartGap.<anonymous>' call
      var message = "startGap shouldn't be negative: " + startGap;
      throw IllegalArgumentException.g3(toString(message));
    }
    if (this.q1d_1 >= startGap) {
      this.s1d_1 = startGap;
      return Unit_instance;
    }
    if (this.q1d_1 === this.r1d_1) {
      if (startGap > this.t1d_1) {
        startGapReservationFailedDueToLimit(this, startGap);
      }
      this.r1d_1 = startGap;
      this.q1d_1 = startGap;
      this.s1d_1 = startGap;
      return Unit_instance;
    }
    startGapReservationFailed(this, startGap);
  }
  y1n(endGap) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(endGap >= 0)) {
      // Inline function 'io.ktor.utils.io.core.Buffer.reserveEndGap.<anonymous>' call
      var message = "endGap shouldn't be negative: " + endGap;
      throw IllegalArgumentException.g3(toString(message));
    }
    var newLimit = this.u1d_1 - endGap | 0;
    if (newLimit >= this.r1d_1) {
      this.t1d_1 = newLimit;
      return Unit_instance;
    }
    if (newLimit < 0) {
      endGapReservationFailedDueToCapacity(this, endGap);
    }
    if (newLimit < this.s1d_1) {
      endGapReservationFailedDueToStartGap(this, endGap);
    }
    if (this.q1d_1 === this.r1d_1) {
      this.t1d_1 = newLimit;
      this.q1d_1 = newLimit;
      this.r1d_1 = newLimit;
      return Unit_instance;
    }
    endGapReservationFailedDueToContent(this, endGap);
  }
  g1p() {
    this.s1d_1 = 0;
    this.q1d_1 = 0;
    var capacity = this.u1d_1;
    this.r1d_1 = capacity;
  }
  x1n() {
    this.h1p(this.u1d_1 - this.s1d_1 | 0);
  }
  h1p(limit) {
    var startGap = this.s1d_1;
    this.q1d_1 = startGap;
    this.r1d_1 = startGap;
    this.t1d_1 = limit;
  }
  i1p() {
    this.j1p(0);
    this.k1p();
  }
  k1p() {
    this.t1d_1 = this.u1d_1;
  }
  j1p(newReadPosition) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(newReadPosition >= 0)) {
      // Inline function 'io.ktor.utils.io.core.Buffer.releaseStartGap.<anonymous>' call
      var message = "newReadPosition shouldn't be negative: " + newReadPosition;
      throw IllegalArgumentException.g3(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(newReadPosition <= this.q1d_1)) {
      // Inline function 'io.ktor.utils.io.core.Buffer.releaseStartGap.<anonymous>' call
      var message_0 = "newReadPosition shouldn't be ahead of the read position: " + newReadPosition + ' > ' + this.q1d_1;
      throw IllegalArgumentException.g3(toString(message_0));
    }
    this.q1d_1 = newReadPosition;
    if (this.s1d_1 > newReadPosition) {
      this.s1d_1 = newReadPosition;
    }
  }
  l1p(copy) {
    copy.t1d_1 = this.t1d_1;
    copy.s1d_1 = this.s1d_1;
    copy.q1d_1 = this.q1d_1;
    copy.r1d_1 = this.r1d_1;
  }
  m1p() {
    var readPosition = this.q1d_1;
    if (readPosition === this.r1d_1) {
      throw EOFException.q1p('No readable bytes available.');
    }
    this.q1d_1 = readPosition + 1 | 0;
    // Inline function 'io.ktor.utils.io.bits.get' call
    // Inline function 'io.ktor.utils.io.bits.Memory.loadAt' call
    return this.p1d_1.z1o_1.getInt8(readPosition);
  }
  r1p(value) {
    var writePosition = this.r1d_1;
    if (writePosition === this.t1d_1) {
      throw InsufficientSpaceException.u1p('No free space in the buffer to write a byte');
    }
    // Inline function 'io.ktor.utils.io.bits.set' call
    this.p1d_1.z1o_1.setInt8(writePosition, value);
    this.r1d_1 = writePosition + 1 | 0;
  }
  v1p() {
    this.i1p();
    this.x1n();
  }
  toString() {
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    var tmp = this.r1d_1 - this.q1d_1 | 0;
    // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
    var tmp_0 = this.t1d_1 - this.r1d_1 | 0;
    var tmp_1 = this.s1d_1;
    // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
    return 'Buffer(' + tmp + ' used, ' + tmp_0 + ' free, ' + (tmp_1 + (this.u1d_1 - this.t1d_1 | 0) | 0) + ' reserved of ' + this.u1d_1 + ')';
  }
}
class InsufficientSpaceException extends Exception {
  static u1p(message) {
    message = message === VOID ? 'Not enough free space' : message;
    var $this = this.ja(message);
    captureStack($this, $this.t1p_1);
    return $this;
  }
  static w1p(name, size, availableSpace) {
    return this.u1p('Not enough free space to write ' + name + ' of ' + size + ' bytes, available ' + availableSpace + ' bytes.');
  }
}
class Closeable {}
class ObjectPool {}
function close() {
  this.nm();
}
class DefaultPool {
  constructor(capacity) {
    this.h1q_1 = capacity;
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var size = this.h1q_1;
    tmp.i1q_1 = fillArrayVal(Array(size), null);
    this.j1q_1 = 0;
  }
  k1q(instance) {
  }
  q1q(instance) {
    return instance;
  }
  n1q(instance) {
  }
  w1n() {
    if (this.j1q_1 === 0)
      return this.d1q();
    this.j1q_1 = this.j1q_1 - 1 | 0;
    var idx = this.j1q_1;
    var tmp = this.i1q_1[idx];
    var instance = !(tmp == null) ? tmp : THROW_CCE();
    this.i1q_1[idx] = null;
    return this.q1q(instance);
  }
  s1q(instance) {
    this.n1q(instance);
    if (this.j1q_1 === this.h1q_1) {
      this.k1q(instance);
    } else {
      var tmp1 = this.j1q_1;
      this.j1q_1 = tmp1 + 1 | 0;
      this.i1q_1[tmp1] = instance;
    }
  }
  nm() {
    var inductionVariable = 0;
    var last = this.j1q_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.i1q_1[i];
        var instance = !(tmp == null) ? tmp : THROW_CCE();
        this.i1q_1[i] = null;
        this.k1q(instance);
      }
       while (inductionVariable < last);
    this.j1q_1 = 0;
  }
}
class DefaultBufferPool extends DefaultPool {
  constructor(bufferSize, capacity, allocator) {
    bufferSize = bufferSize === VOID ? 4096 : bufferSize;
    capacity = capacity === VOID ? 1000 : capacity;
    allocator = allocator === VOID ? DefaultAllocator_instance : allocator;
    super(capacity);
    this.b1q_1 = bufferSize;
    this.c1q_1 = allocator;
  }
  d1q() {
    return new ChunkBuffer(this.c1q_1.e1q(this.b1q_1), null, this);
  }
  f1q(instance) {
    this.c1q_1.g1q(instance.p1d_1);
    super.k1q(instance);
    instance.l1q();
  }
  k1q(instance) {
    return this.f1q(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
  m1q(instance) {
    super.n1q(instance);
    // Inline function 'kotlin.check' call
    // Inline function 'io.ktor.utils.io.bits.Memory.size' call
    var this_0 = instance.p1d_1;
    // Inline function 'kotlin.contracts.contract' call
    if (!toLong(this_0.z1o_1.byteLength).equals(toLong(this.b1q_1))) {
      // Inline function 'io.ktor.utils.io.core.DefaultBufferPool.validateInstance.<anonymous>' call
      // Inline function 'io.ktor.utils.io.bits.Memory.size' call
      var this_1 = instance.p1d_1;
      var tmp$ret$1 = toLong(this_1.z1o_1.byteLength);
      var message = 'Buffer size mismatch. Expected: ' + this.b1q_1 + ', actual: ' + tmp$ret$1.toString();
      throw IllegalStateException.l1(toString(message));
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(instance === Companion_getInstance_4().a1e_1)) {
      // Inline function 'io.ktor.utils.io.core.DefaultBufferPool.validateInstance.<anonymous>' call
      var message_0 = "ChunkBuffer.Empty couldn't be recycled";
      throw IllegalStateException.l1(toString(message_0));
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(instance === Companion_instance.w1d())) {
      // Inline function 'io.ktor.utils.io.core.DefaultBufferPool.validateInstance.<anonymous>' call
      var message_1 = "Empty instance couldn't be recycled";
      throw IllegalStateException.l1(toString(message_1));
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(instance.o1q() === 0)) {
      // Inline function 'io.ktor.utils.io.core.DefaultBufferPool.validateInstance.<anonymous>' call
      var message_2 = 'Unable to clear buffer: it is still in use.';
      throw IllegalStateException.l1(toString(message_2));
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(instance.t12() == null)) {
      // Inline function 'io.ktor.utils.io.core.DefaultBufferPool.validateInstance.<anonymous>' call
      var message_3 = "Recycled instance shouldn't be a part of a chain.";
      throw IllegalStateException.l1(toString(message_3));
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(instance.s1o_1 == null)) {
      // Inline function 'io.ktor.utils.io.core.DefaultBufferPool.validateInstance.<anonymous>' call
      var message_4 = "Recycled instance shouldn't be a view or another buffer.";
      throw IllegalStateException.l1(toString(message_4));
    }
  }
  n1q(instance) {
    return this.m1q(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
  p1q(instance) {
    // Inline function 'kotlin.apply' call
    var this_0 = super.q1q(instance);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'io.ktor.utils.io.core.DefaultBufferPool.clearInstance.<anonymous>' call
    this_0.r1q();
    this_0.v1p();
    return this_0;
  }
  q1q(instance) {
    return this.p1q(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
}
class Output {
  constructor(pool) {
    this.l1c_1 = pool;
    this.m1c_1 = null;
    this.n1c_1 = null;
    this.o1c_1 = Companion_getInstance_6().d1s_1;
    this.p1c_1 = 0;
    this.q1c_1 = 0;
    this.r1c_1 = 0;
    this.s1c_1 = 0;
  }
  x1q() {
    return this.s1c_1 + (this.p1c_1 - this.r1c_1 | 0) | 0;
  }
  c1r() {
    var tmp0_elvis_lhs = this.m1c_1;
    return tmp0_elvis_lhs == null ? Companion_getInstance_4().a1e_1 : tmp0_elvis_lhs;
  }
  i8() {
    flushChain(this);
  }
  t1c() {
    var tmp0_elvis_lhs = this.m1c_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var head = tmp;
    var tmp1_safe_receiver = this.n1c_1;
    if (tmp1_safe_receiver == null)
      null;
    else
      tmp1_safe_receiver.c1p(this.p1c_1);
    this.m1c_1 = null;
    this.n1c_1 = null;
    this.p1c_1 = 0;
    this.q1c_1 = 0;
    this.r1c_1 = 0;
    this.s1c_1 = 0;
    this.o1c_1 = Companion_getInstance_6().d1s_1;
    return head;
  }
  d1r(buffer) {
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(buffer.t12() == null)) {
      // Inline function 'io.ktor.utils.io.core.Output.appendSingleChunk.<anonymous>' call
      var message = 'It should be a single buffer chunk.';
      throw IllegalStateException.l1(toString(message));
    }
    appendChainImpl(this, buffer, buffer, 0);
  }
  e1r(head) {
    var tail = findTail(head);
    // Inline function 'io.ktor.utils.io.core.internal.toIntOrFail' call
    // Inline function 'kotlin.Long.minus' call
    var this_0 = remainingAll(head);
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    var other = tail.r1d_1 - tail.q1d_1 | 0;
    var this_1 = this_0.fe(toLong(other));
    var name = 'total size increase';
    if (this_1.oc(toLong(IntCompanionObject_instance.MAX_VALUE)) >= 0) {
      failLongToIntConversion(this_1, name);
    }
    var chainedSizeDelta = this_1.qe();
    appendChainImpl(this, head, tail, chainedSizeDelta);
  }
  r1p(v) {
    var index = this.p1c_1;
    if (index < this.q1c_1) {
      this.p1c_1 = index + 1 | 0;
      // Inline function 'io.ktor.utils.io.bits.set' call
      this.o1c_1.z1o_1.setInt8(index, v);
      return Unit_instance;
    }
    return writeByteFallback(this, v);
  }
  t1q() {
    try {
      this.i8();
    }finally {
      this.y1q();
    }
  }
  j7(value) {
    var tailPosition = this.p1c_1;
    if ((this.q1c_1 - tailPosition | 0) >= 3) {
      // Inline function 'io.ktor.utils.io.core.internal.putUtf8Char' call
      var this_0 = this.o1c_1;
      // Inline function 'kotlin.code' call
      var v = Char__toInt_impl_vasixd(value);
      var tmp;
      if (0 <= v ? v <= 127 : false) {
        // Inline function 'io.ktor.utils.io.bits.Memory.storeAt' call
        var value_0 = toByte(v);
        this_0.z1o_1.setInt8(tailPosition, value_0);
        tmp = 1;
      } else if (128 <= v ? v <= 2047 : false) {
        // Inline function 'io.ktor.utils.io.bits.set' call
        var value_1 = toByte(192 | v >> 6 & 31);
        this_0.z1o_1.setInt8(tailPosition, value_1);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index = tailPosition + 1 | 0;
        var value_2 = toByte(128 | v & 63);
        this_0.z1o_1.setInt8(index, value_2);
        tmp = 2;
      } else if (2048 <= v ? v <= 65535 : false) {
        // Inline function 'io.ktor.utils.io.bits.set' call
        var value_3 = toByte(224 | v >> 12 & 15);
        this_0.z1o_1.setInt8(tailPosition, value_3);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_0 = tailPosition + 1 | 0;
        var value_4 = toByte(128 | v >> 6 & 63);
        this_0.z1o_1.setInt8(index_0, value_4);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_1 = tailPosition + 2 | 0;
        var value_5 = toByte(128 | v & 63);
        this_0.z1o_1.setInt8(index_1, value_5);
        tmp = 3;
      } else if (65536 <= v ? v <= 1114111 : false) {
        // Inline function 'io.ktor.utils.io.bits.set' call
        var value_6 = toByte(240 | v >> 18 & 7);
        this_0.z1o_1.setInt8(tailPosition, value_6);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_2 = tailPosition + 1 | 0;
        var value_7 = toByte(128 | v >> 12 & 63);
        this_0.z1o_1.setInt8(index_2, value_7);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_3 = tailPosition + 2 | 0;
        var value_8 = toByte(128 | v >> 6 & 63);
        this_0.z1o_1.setInt8(index_3, value_8);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_4 = tailPosition + 3 | 0;
        var value_9 = toByte(128 | v & 63);
        this_0.z1o_1.setInt8(index_4, value_9);
        tmp = 4;
      } else {
        malformedCodePoint(v);
      }
      var size = tmp;
      this.p1c_1 = tailPosition + size | 0;
      return this;
    }
    appendCharFallback(this, value);
    return this;
  }
  ra(value) {
    if (value == null) {
      this.sa('null', 0, 4);
    } else {
      this.sa(value, 0, charSequenceLength(value));
    }
    return this;
  }
  sa(value, startIndex, endIndex) {
    if (value == null) {
      return this.sa('null', startIndex, endIndex);
    }
    writeText(this, value, startIndex, endIndex, Charsets_getInstance().e1s_1);
    return this;
  }
  v1g(packet) {
    var foreignStolen = packet.t1c();
    if (foreignStolen == null) {
      packet.ww();
      return Unit_instance;
    }
    var tail = this.n1c_1;
    if (tail == null) {
      this.e1r(foreignStolen);
      return Unit_instance;
    }
    writePacketMerging(this, tail, foreignStolen, packet.n1g_1);
  }
  u1c(chunkBuffer) {
    var _tail = this.n1c_1;
    if (_tail == null) {
      this.e1r(chunkBuffer);
      return Unit_instance;
    }
    writePacketMerging(this, _tail, chunkBuffer, this.l1c_1);
  }
  j1i(p, n) {
    var remaining = n;
    $l$loop: while (remaining.oc(new Long(0, 0)) > 0) {
      // Inline function 'io.ktor.utils.io.core.Input.headRemaining' call
      var tmp$ret$0 = p.r1g_1 - p.q1g_1 | 0;
      var headRemaining = toLong(tmp$ret$0);
      if (headRemaining.oc(remaining) <= 0) {
        remaining = remaining.fe(headRemaining);
        var tmp0_elvis_lhs = p.n1r();
        var tmp;
        if (tmp0_elvis_lhs == null) {
          throw EOFException.q1p('Unexpected end of packet');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        this.d1r(tmp);
      } else {
        // Inline function 'io.ktor.utils.io.core.read' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp0_elvis_lhs_0 = p.x1r(1);
        var tmp_0;
        if (tmp0_elvis_lhs_0 == null) {
          prematureEndOfStream(1);
        } else {
          tmp_0 = tmp0_elvis_lhs_0;
        }
        var buffer = tmp_0;
        var positionBefore = buffer.q1d_1;
        try {
          // Inline function 'io.ktor.utils.io.core.Output.writePacket.<anonymous>' call
          writeFully_2(this, buffer, remaining.qe());
        }finally {
          var positionAfter = buffer.q1d_1;
          if (positionAfter < positionBefore) {
            throw IllegalStateException.l1("Buffer's position shouldn't be rewinded");
          }
          if (positionAfter === buffer.r1d_1) {
            p.v1r(buffer);
          } else {
            p.q1g_1 = positionAfter;
          }
        }
        break $l$loop;
      }
    }
  }
  ww() {
    this.t1q();
  }
  s1e(n) {
    // Inline function 'io.ktor.utils.io.core.Output.tailRemaining' call
    if ((this.q1c_1 - this.p1c_1 | 0) >= n) {
      var tmp0_safe_receiver = this.n1c_1;
      if (tmp0_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        tmp0_safe_receiver.c1p(this.p1c_1);
        return tmp0_safe_receiver;
      }
    }
    return appendNewChunk(this);
  }
  u1l() {
    var tmp0_safe_receiver = this.n1c_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      this.p1c_1 = tmp0_safe_receiver.r1d_1;
    }
  }
  f1r() {
    var head = this.c1r();
    if (!(head === Companion_getInstance_4().a1e_1)) {
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(head.t12() == null)) {
        // Inline function 'kotlin.check.<anonymous>' call
        var message = 'Check failed.';
        throw IllegalStateException.l1(toString(message));
      }
      head.x1n();
      head.y1n(8);
      this.p1c_1 = head.r1d_1;
      this.r1c_1 = this.p1c_1;
      this.q1c_1 = head.t1d_1;
    }
  }
}
class BytePacketBuilder extends Output {
  constructor(pool) {
    pool = pool === VOID ? Companion_getInstance_4().y1d_1 : pool;
    super(pool);
  }
  k() {
    return this.x1q();
  }
  k1c() {
    return this.x1q() === 0;
  }
  y1q() {
  }
  z1q(source, offset, length) {
  }
  j7(value) {
    var tmp = super.j7(value);
    return tmp instanceof BytePacketBuilder ? tmp : THROW_CCE();
  }
  ra(value) {
    var tmp = super.ra(value);
    return tmp instanceof BytePacketBuilder ? tmp : THROW_CCE();
  }
  sa(value, startIndex, endIndex) {
    var tmp = super.sa(value, startIndex, endIndex);
    return tmp instanceof BytePacketBuilder ? tmp : THROW_CCE();
  }
  k1i() {
    var size = this.k();
    var head = this.t1c();
    return head == null ? Companion_getInstance_2().b1r_1 : ByteReadPacket.a1r(head, toLong(size), this.l1c_1);
  }
  toString() {
    return 'BytePacketBuilder(' + this.k() + ' bytes written)';
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    this.b1r_1 = ByteReadPacket.a1r(Companion_getInstance_4().a1e_1, new Long(0, 0), Companion_getInstance_4().z1d_1);
  }
}
class Input {
  static g1r(head, remaining, pool) {
    head = head === VOID ? Companion_getInstance_4().a1e_1 : head;
    remaining = remaining === VOID ? remainingAll(head) : remaining;
    pool = pool === VOID ? Companion_getInstance_4().y1d_1 : pool;
    var $this = createThis(this);
    $this.n1g_1 = pool;
    $this.o1g_1 = head;
    $this.p1g_1 = head.p1d_1;
    $this.q1g_1 = head.q1d_1;
    $this.r1g_1 = head.r1d_1;
    var tmp = $this;
    // Inline function 'kotlin.Long.minus' call
    var other = $this.r1g_1 - $this.q1g_1 | 0;
    tmp.s1g_1 = remaining.fe(toLong(other));
    $this.t1g_1 = false;
    return $this;
  }
  u1j() {
    var tmp;
    var tmp_0;
    // Inline function 'io.ktor.utils.io.core.Input.headRemaining' call
    if ((this.r1g_1 - this.q1g_1 | 0) === 0) {
      tmp_0 = this.s1g_1.equals(new Long(0, 0));
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.t1g_1 ? true : doFill(this) == null;
    } else {
      tmp = false;
    }
    return tmp;
  }
  c1r() {
    // Inline function 'kotlin.also' call
    var this_0 = this.o1g_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'io.ktor.utils.io.core.Input.<get-head>.<anonymous>' call
    this_0.d1p(this.q1g_1);
    return this_0;
  }
  m1r(newValue) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(newValue.oc(new Long(0, 0)) >= 0)) {
      // Inline function 'io.ktor.utils.io.core.Input.<set-tailRemaining>.<anonymous>' call
      var message = "tailRemaining shouldn't be negative: " + newValue.toString();
      throw IllegalArgumentException.g3(toString(message));
    }
    this.s1g_1 = newValue;
  }
  u1g() {
    // Inline function 'io.ktor.utils.io.core.Input.headRemaining' call
    var tmp$ret$0 = this.r1g_1 - this.q1g_1 | 0;
    return toLong(tmp$ret$0).ee(this.s1g_1);
  }
  x1i() {
    return !(this.q1g_1 === this.r1g_1) ? true : !this.s1g_1.equals(new Long(0, 0));
  }
  ww() {
    var head = this.c1r();
    var empty = Companion_getInstance_4().a1e_1;
    if (!(head === empty)) {
      _set__head__b4pap2(this, empty);
      this.m1r(new Long(0, 0));
      releaseAll(head, this.n1g_1);
    }
  }
  t1q() {
    this.ww();
    if (!this.t1g_1) {
      this.t1g_1 = true;
    }
    this.l1r();
  }
  t1c() {
    var head = this.c1r();
    var empty = Companion_getInstance_4().a1e_1;
    if (head === empty)
      return null;
    _set__head__b4pap2(this, empty);
    this.m1r(new Long(0, 0));
    return head;
  }
  n1r() {
    var head = this.c1r();
    var next = head.t12();
    var empty = Companion_getInstance_4().a1e_1;
    if (head === empty)
      return null;
    if (next == null) {
      _set__head__b4pap2(this, empty);
      this.m1r(new Long(0, 0));
    } else {
      _set__head__b4pap2(this, next);
      // Inline function 'kotlin.Long.minus' call
      var this_0 = this.s1g_1;
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var other = next.r1d_1 - next.q1d_1 | 0;
      var tmp$ret$1 = this_0.fe(toLong(other));
      this.m1r(tmp$ret$1);
    }
    head.w1q(null);
    return head;
  }
  o1r(chain) {
    if (chain === Companion_getInstance_4().a1e_1)
      return Unit_instance;
    var size = remainingAll(chain);
    if (this.o1g_1 === Companion_getInstance_4().a1e_1) {
      _set__head__b4pap2(this, chain);
      // Inline function 'kotlin.Long.minus' call
      // Inline function 'io.ktor.utils.io.core.Input.headRemaining' call
      var other = this.r1g_1 - this.q1g_1 | 0;
      var tmp$ret$1 = size.fe(toLong(other));
      this.m1r(tmp$ret$1);
    } else {
      findTail(this.o1g_1).w1q(chain);
      this.m1r(this.s1g_1.ee(size));
    }
  }
  p1r(chain) {
    var tail = findTail(this.c1r());
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    var size = chain.r1d_1 - chain.q1d_1 | 0;
    var tmp;
    if (size === 0) {
      tmp = true;
    } else {
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      tmp = (tail.t1d_1 - tail.r1d_1 | 0) < size;
    }
    if (tmp)
      return false;
    writeBufferAppend(tail, chain, size);
    if (this.c1r() === tail) {
      this.r1g_1 = tail.r1d_1;
    } else {
      // Inline function 'kotlin.Long.plus' call
      var tmp$ret$2 = this.s1g_1.ee(toLong(size));
      this.m1r(tmp$ret$2);
    }
    return true;
  }
  q1r(n) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(n >= 0)) {
      // Inline function 'io.ktor.utils.io.core.Input.discard.<anonymous>' call
      var message = 'Negative discard is not allowed: ' + n;
      throw IllegalArgumentException.g3(toString(message));
    }
    return discardAsMuchAsPossible_0(this, n, 0);
  }
  a1p(n) {
    if (!(this.q1r(n) === n))
      throw EOFException.q1p('Unable to discard ' + n + ' bytes due to end of packet');
  }
  s1k(n) {
    if (n.oc(new Long(0, 0)) <= 0)
      return new Long(0, 0);
    return discardAsMuchAsPossible(this, n, new Long(0, 0));
  }
  r1r(min, max) {
    if (min === 0 ? max === 0 ? true : this.u1j() : false)
      return '';
    var remaining = this.u1g();
    if (remaining.oc(new Long(0, 0)) > 0 ? toLong(max).oc(remaining) >= 0 : false)
      return readTextExactBytes(this, remaining.qe());
    // Inline function 'kotlin.text.buildString' call
    var capacity = coerceAtMost(coerceAtLeast(min, 16), max);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.n7(capacity);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'io.ktor.utils.io.core.Input.readText.<anonymous>' call
    readASCII(this, this_0, min, max);
    return this_0.toString();
  }
  s1r(min, max, $super) {
    min = min === VOID ? 0 : min;
    max = max === VOID ? IntCompanionObject_instance.MAX_VALUE : max;
    return $super === VOID ? this.r1r(min, max) : $super.r1r.call(this, min, max);
  }
  t1r(minSize) {
    return prepareReadLoop(this, minSize, this.c1r());
  }
  u1r(current) {
    return this.v1r(current);
  }
  v1r(current) {
    return ensureNext(this, current, Companion_getInstance_4().a1e_1);
  }
  w1r(current) {
    var tmp0_elvis_lhs = current.t12();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return fixGapAfterReadFallback(this, current);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var next = tmp;
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    var remaining = current.r1d_1 - current.q1d_1 | 0;
    // Inline function 'kotlin.comparisons.minOf' call
    // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
    var b = 8 - (current.u1d_1 - current.t1d_1 | 0) | 0;
    var overrunSize = Math.min(remaining, b);
    if (next.s1d_1 < overrunSize) {
      return fixGapAfterReadFallback(this, current);
    }
    restoreStartGap(next, overrunSize);
    if (remaining > overrunSize) {
      current.k1p();
      this.r1g_1 = current.r1d_1;
      // Inline function 'kotlin.Long.plus' call
      var tmp$ret$3 = this.s1g_1.ee(toLong(overrunSize));
      this.m1r(tmp$ret$3);
    } else {
      _set__head__b4pap2(this, next);
      // Inline function 'kotlin.Long.minus' call
      var this_0 = this.s1g_1;
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var other = (next.r1d_1 - next.q1d_1 | 0) - overrunSize | 0;
      var tmp$ret$5 = this_0.fe(toLong(other));
      this.m1r(tmp$ret$5);
      current.v1q();
      current.t1o(this.n1g_1);
    }
  }
  j1r() {
    var buffer = this.n1g_1.w1n();
    try {
      buffer.y1n(8);
      var tmp = buffer.r1d_1;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var tmp$ret$0 = buffer.t1d_1 - buffer.r1d_1 | 0;
      var copied = this.k1r(buffer.p1d_1, tmp, tmp$ret$0);
      if (copied === 0) {
        this.t1g_1 = true;
        // Inline function 'io.ktor.utils.io.core.canRead' call
        if (!(buffer.r1d_1 > buffer.q1d_1)) {
          buffer.t1o(this.n1g_1);
          return null;
        }
      }
      buffer.b1p(copied);
      return buffer;
    } catch ($p) {
      if ($p instanceof Error) {
        var t = $p;
        buffer.t1o(this.n1g_1);
        throw t;
      } else {
        throw $p;
      }
    }
  }
  h1r() {
    if (!this.t1g_1) {
      this.t1g_1 = true;
    }
  }
  x1r(minSize) {
    var head = this.c1r();
    if ((this.r1g_1 - this.q1g_1 | 0) >= minSize)
      return head;
    return prepareReadLoop(this, minSize, head);
  }
  y1r(head) {
    var tmp0_elvis_lhs = head.v1q();
    var next = tmp0_elvis_lhs == null ? Companion_getInstance_4().a1e_1 : tmp0_elvis_lhs;
    _set__head__b4pap2(this, next);
    // Inline function 'kotlin.Long.minus' call
    var this_0 = this.s1g_1;
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    var other = next.r1d_1 - next.q1d_1 | 0;
    var tmp$ret$1 = this_0.fe(toLong(other));
    this.m1r(tmp$ret$1);
    head.t1o(this.n1g_1);
    return next;
  }
}
class ByteReadPacket extends Input {
  static a1r(head, remaining, pool) {
    Companion_getInstance_2();
    var $this = this.g1r(head, remaining, pool);
    $this.h1r();
    return $this;
  }
  static m1l(head, pool) {
    Companion_getInstance_2();
    return this.a1r(head, remainingAll(head), pool);
  }
  i1r() {
    return ByteReadPacket.a1r(copyAll(this.c1r()), this.u1g(), this.n1g_1);
  }
  j1r() {
    return null;
  }
  k1r(destination, offset, length) {
    return 0;
  }
  l1r() {
  }
  toString() {
    return 'ByteReadPacket(' + this.u1g().toString() + ' bytes remaining)';
  }
}
class Companion_1 {}
class ChunkBuffer$Companion$Pool$1 {
  w1n() {
    return get_DefaultChunkedBufferPool().w1n();
  }
  j1s(instance) {
    get_DefaultChunkedBufferPool().s1q(instance);
  }
  s1q(instance) {
    return this.j1s(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
  nm() {
    get_DefaultChunkedBufferPool().nm();
  }
}
class ChunkBuffer$Companion$EmptyPool$1 {
  w1n() {
    return Companion_getInstance_4().a1e_1;
  }
  j1s(instance) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(instance === Companion_getInstance_4().a1e_1)) {
      // Inline function 'io.ktor.utils.io.core.internal.<no name provided>.recycle.<anonymous>' call
      var message = 'Only ChunkBuffer.Empty instance could be recycled.';
      throw IllegalArgumentException.g3(toString(message));
    }
  }
  s1q(instance) {
    return this.j1s(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
  nm() {
  }
}
class NoPoolImpl {
  s1q(instance) {
  }
  nm() {
  }
}
class ChunkBuffer$Companion$NoPool$1 extends NoPoolImpl {
  w1n() {
    return new ChunkBuffer(DefaultAllocator_instance.e1q(get_DEFAULT_BUFFER_SIZE()), null, this);
  }
  j1s(instance) {
    DefaultAllocator_instance.g1q(instance.p1d_1);
  }
  s1q(instance) {
    return this.j1s(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
}
class ChunkBuffer$Companion$NoPoolManuallyManaged$1 extends NoPoolImpl {
  w1n() {
    throw UnsupportedOperationException.r5("This pool doesn't support borrow");
  }
  j1s(instance) {
  }
  s1q(instance) {
    return this.j1s(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
}
class Companion_2 {
  constructor() {
    Companion_instance_2 = this;
    var tmp = this;
    tmp.y1d_1 = new ChunkBuffer$Companion$Pool$1();
    var tmp_0 = this;
    tmp_0.z1d_1 = new ChunkBuffer$Companion$EmptyPool$1();
    this.a1e_1 = new ChunkBuffer(Companion_getInstance_6().d1s_1, null, this.z1d_1);
    var tmp_1 = this;
    tmp_1.b1e_1 = new ChunkBuffer$Companion$NoPool$1();
    var tmp_2 = this;
    tmp_2.c1e_1 = new ChunkBuffer$Companion$NoPoolManuallyManaged$1();
  }
}
class ChunkBuffer extends Buffer {
  constructor(memory, origin, parentPool) {
    Companion_getInstance_4();
    super(memory);
    this.p1o_1 = parentPool;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(origin === this)) {
      // Inline function 'io.ktor.utils.io.core.internal.ChunkBuffer.<anonymous>' call
      var message = "A chunk couldn't be a view of itself.";
      throw IllegalArgumentException.g3(toString(message));
    }
    this.q1o_1 = atomic$ref$1(null);
    this.r1o_1 = atomic$int$1(1);
    this.s1o_1 = origin;
  }
  w1q(newValue) {
    if (newValue == null) {
      this.v1q();
    } else {
      appendNext(this, newValue);
    }
  }
  t12() {
    return this.q1o_1.kotlinx$atomicfu$value;
  }
  o1q() {
    return this.r1o_1.kotlinx$atomicfu$value;
  }
  v1q() {
    return this.q1o_1.atomicfu$getAndSet(null);
  }
  u1q() {
    // Inline function 'kotlin.let' call
    var tmp0_elvis_lhs = this.s1o_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'io.ktor.utils.io.core.internal.ChunkBuffer.duplicate.<anonymous>' call
    var newOrigin = tmp0_elvis_lhs == null ? this : tmp0_elvis_lhs;
    newOrigin.k1s();
    // Inline function 'kotlin.also' call
    var this_0 = new ChunkBuffer(this.p1d_1, newOrigin, this.p1o_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'io.ktor.utils.io.core.internal.ChunkBuffer.duplicate.<anonymous>.<anonymous>' call
    this.l1p(this_0);
    return this_0;
  }
  t1o(pool) {
    if (this.l1s()) {
      var origin = this.s1o_1;
      if (!(origin == null)) {
        this.l1q();
        origin.t1o(pool);
      } else {
        var tmp0_elvis_lhs = this.p1o_1;
        var poolToUse = tmp0_elvis_lhs == null ? pool : tmp0_elvis_lhs;
        poolToUse.s1q(this);
      }
    }
  }
  l1q() {
    if (!this.r1o_1.atomicfu$compareAndSet(0, -1)) {
      throw IllegalStateException.l1('Unable to unlink: buffer is in use.');
    }
    this.v1q();
    this.s1o_1 = null;
  }
  k1s() {
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var this_0 = this.r1o_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'io.ktor.utils.io.core.internal.ChunkBuffer.acquire.<anonymous>' call
        if (cur <= 0)
          throw IllegalStateException.l1('Unable to acquire chunk: it is already released.');
        var upd = cur + 1 | 0;
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          break $l$block;
        }
      }
    }
  }
  r1q() {
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var this_0 = this.r1o_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'io.ktor.utils.io.core.internal.ChunkBuffer.unpark.<anonymous>' call
        if (cur < 0) {
          throw IllegalStateException.l1("This instance is already disposed and couldn't be borrowed.");
        }
        if (cur > 0) {
          throw IllegalStateException.l1('This instance is already in use but somehow appeared in the pool.');
        }
        var upd = 1;
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          break $l$block;
        }
      }
    }
  }
  l1s() {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.atomicfu.updateAndGet' call
      var this_0 = this.r1o_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'io.ktor.utils.io.core.internal.ChunkBuffer.release.<anonymous>' call
        if (cur <= 0)
          throw IllegalStateException.l1('Unable to release: it is already released.');
        var upd = cur - 1 | 0;
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          tmp$ret$1 = upd;
          break $l$block;
        }
      }
    }
    return tmp$ret$1 === 0;
  }
  v1p() {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.s1o_1 == null)) {
      // Inline function 'io.ktor.utils.io.core.internal.ChunkBuffer.reset.<anonymous>' call
      var message = 'Unable to reset buffer with origin';
      throw IllegalArgumentException.g3(toString(message));
    }
    super.v1p();
    this.q1o_1.kotlinx$atomicfu$value = null;
  }
}
class MalformedUTF8InputException extends Exception {
  static b1s(message) {
    var $this = this.ja(message);
    captureStack($this, $this.a1s_1);
    return $this;
  }
}
class $decodeUTF8LineLoopSuspendCOROUTINE$55 extends CoroutineImpl {
  constructor(out, limit, nextChunk, afterRead, resultContinuation) {
    super(resultContinuation);
    this.u1s_1 = out;
    this.v1s_1 = limit;
    this.w1s_1 = nextChunk;
    this.x1s_1 = afterRead;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            this.y1s_1 = 0;
            this.z1s_1 = 1;
            this.a1t_1 = false;
            this.b1t_1 = false;
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!(!this.b1t_1 ? !(this.z1s_1 === 0) : false)) {
              this.te_1 = 5;
              continue $sm;
            }

            this.te_1 = 2;
            suspendResult = this.w1s_1(this.z1s_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var tmp0_elvis_lhs = suspendResult;
            var WHEN_RESULT;
            if (tmp0_elvis_lhs == null) {
              this.te_1 = 5;
              continue $sm;
            } else {
              WHEN_RESULT = tmp0_elvis_lhs;
              this.te_1 = 3;
              continue $sm;
            }

          case 3:
            var chunk = WHEN_RESULT;
            var totalBytes = chunk.u1g();
            l$ret$1: do {
              var release = true;
              var tmp0_elvis_lhs_0 = prepareReadFirstHead(chunk, 1);
              var tmp_0;
              if (tmp0_elvis_lhs_0 == null) {
                break l$ret$1;
              } else {
                tmp_0 = tmp0_elvis_lhs_0;
              }
              var current = tmp_0;
              var size = 1;
              l$ret$15: do {
                try {
                  $l$loop_0: do {
                    var this_0 = current;
                    var before = this_0.r1d_1 - this_0.q1d_1 | 0;
                    var after;
                    if (before >= size) {
                      l$ret$13: do {
                        try {
                          var buffer = current;
                          var skip = 0;
                          var tmp_1 = this;
                          var tmp$ret$4;
                          l$ret$5: do {
                            var byteCount = 0;
                            var value = 0;
                            var lastByteCount = 0;
                            var memory = buffer.p1d_1;
                            var start = buffer.q1d_1;
                            var endExclusive = buffer.r1d_1;
                            var inductionVariable = start;
                            if (inductionVariable < endExclusive)
                              do {
                                var index = inductionVariable;
                                inductionVariable = inductionVariable + 1 | 0;
                                var v = memory.z1o_1.getInt8(index) & 255;
                                if ((v & 128) === 0) {
                                  if (!(byteCount === 0)) {
                                    malformedByteCount(byteCount);
                                  }
                                  var tmp$ret$2;
                                  l$ret$3: do {
                                    var ch = numberToChar(v);
                                    var tmp_2;
                                    if (ch === _Char___init__impl__6a9atx(13)) {
                                      if (this.a1t_1) {
                                        this.b1t_1 = true;
                                        tmp$ret$2 = false;
                                        break l$ret$3;
                                      }
                                      this.a1t_1 = true;
                                      tmp_2 = true;
                                    } else if (ch === _Char___init__impl__6a9atx(10)) {
                                      this.b1t_1 = true;
                                      skip = 1;
                                      tmp_2 = false;
                                    } else {
                                      if (this.a1t_1) {
                                        this.b1t_1 = true;
                                        tmp$ret$2 = false;
                                        break l$ret$3;
                                      }
                                      if (this.y1s_1 === this.v1s_1) {
                                        throw TooLongLineException.w1o('Too many characters in line: limit ' + this.v1s_1 + ' exceeded');
                                      }
                                      this.y1s_1 = this.y1s_1 + 1 | 0;
                                      this.u1s_1.j7(ch);
                                      tmp_2 = true;
                                    }
                                    tmp$ret$2 = tmp_2;
                                  }
                                   while (false);
                                  if (!tmp$ret$2) {
                                    buffer.a1p(index - start | 0);
                                    tmp$ret$4 = -1;
                                    break l$ret$5;
                                  }
                                } else if (byteCount === 0) {
                                  var mask = 128;
                                  value = v;
                                  var inductionVariable_0 = 1;
                                  if (inductionVariable_0 <= 6)
                                    $l$loop: do {
                                      var i = inductionVariable_0;
                                      inductionVariable_0 = inductionVariable_0 + 1 | 0;
                                      if (!((value & mask) === 0)) {
                                        value = value & ~mask;
                                        mask = mask >> 1;
                                        byteCount = byteCount + 1 | 0;
                                      } else {
                                        break $l$loop;
                                      }
                                    }
                                     while (inductionVariable_0 <= 6);
                                  lastByteCount = byteCount;
                                  byteCount = byteCount - 1 | 0;
                                  if (lastByteCount > (endExclusive - index | 0)) {
                                    buffer.a1p(index - start | 0);
                                    tmp$ret$4 = lastByteCount;
                                    break l$ret$5;
                                  }
                                } else {
                                  value = value << 6 | v & 127;
                                  byteCount = byteCount - 1 | 0;
                                  if (byteCount === 0) {
                                    if (isBmpCodePoint(value)) {
                                      var tmp$ret$6;
                                      l$ret$7: do {
                                        var ch_0 = numberToChar(value);
                                        var tmp_3;
                                        if (ch_0 === _Char___init__impl__6a9atx(13)) {
                                          if (this.a1t_1) {
                                            this.b1t_1 = true;
                                            tmp$ret$6 = false;
                                            break l$ret$7;
                                          }
                                          this.a1t_1 = true;
                                          tmp_3 = true;
                                        } else if (ch_0 === _Char___init__impl__6a9atx(10)) {
                                          this.b1t_1 = true;
                                          skip = 1;
                                          tmp_3 = false;
                                        } else {
                                          if (this.a1t_1) {
                                            this.b1t_1 = true;
                                            tmp$ret$6 = false;
                                            break l$ret$7;
                                          }
                                          if (this.y1s_1 === this.v1s_1) {
                                            throw TooLongLineException.w1o('Too many characters in line: limit ' + this.v1s_1 + ' exceeded');
                                          }
                                          this.y1s_1 = this.y1s_1 + 1 | 0;
                                          this.u1s_1.j7(ch_0);
                                          tmp_3 = true;
                                        }
                                        tmp$ret$6 = tmp_3;
                                      }
                                       while (false);
                                      if (!tmp$ret$6) {
                                        buffer.a1p(((index - start | 0) - lastByteCount | 0) + 1 | 0);
                                        tmp$ret$4 = -1;
                                        break l$ret$5;
                                      }
                                    } else if (!isValidCodePoint(value)) {
                                      malformedCodePoint(value);
                                    } else {
                                      var tmp_4;
                                      var tmp$ret$8;
                                      l$ret$9: do {
                                        var ch_1 = numberToChar(highSurrogate(value));
                                        var tmp_5;
                                        if (ch_1 === _Char___init__impl__6a9atx(13)) {
                                          if (this.a1t_1) {
                                            this.b1t_1 = true;
                                            tmp$ret$8 = false;
                                            break l$ret$9;
                                          }
                                          this.a1t_1 = true;
                                          tmp_5 = true;
                                        } else if (ch_1 === _Char___init__impl__6a9atx(10)) {
                                          this.b1t_1 = true;
                                          skip = 1;
                                          tmp_5 = false;
                                        } else {
                                          if (this.a1t_1) {
                                            this.b1t_1 = true;
                                            tmp$ret$8 = false;
                                            break l$ret$9;
                                          }
                                          if (this.y1s_1 === this.v1s_1) {
                                            throw TooLongLineException.w1o('Too many characters in line: limit ' + this.v1s_1 + ' exceeded');
                                          }
                                          this.y1s_1 = this.y1s_1 + 1 | 0;
                                          this.u1s_1.j7(ch_1);
                                          tmp_5 = true;
                                        }
                                        tmp$ret$8 = tmp_5;
                                      }
                                       while (false);
                                      if (!tmp$ret$8) {
                                        tmp_4 = true;
                                      } else {
                                        var tmp$ret$10;
                                        l$ret$11: do {
                                          var ch_2 = numberToChar(lowSurrogate(value));
                                          var tmp_6;
                                          if (ch_2 === _Char___init__impl__6a9atx(13)) {
                                            if (this.a1t_1) {
                                              this.b1t_1 = true;
                                              tmp$ret$10 = false;
                                              break l$ret$11;
                                            }
                                            this.a1t_1 = true;
                                            tmp_6 = true;
                                          } else if (ch_2 === _Char___init__impl__6a9atx(10)) {
                                            this.b1t_1 = true;
                                            skip = 1;
                                            tmp_6 = false;
                                          } else {
                                            if (this.a1t_1) {
                                              this.b1t_1 = true;
                                              tmp$ret$10 = false;
                                              break l$ret$11;
                                            }
                                            if (this.y1s_1 === this.v1s_1) {
                                              throw TooLongLineException.w1o('Too many characters in line: limit ' + this.v1s_1 + ' exceeded');
                                            }
                                            this.y1s_1 = this.y1s_1 + 1 | 0;
                                            this.u1s_1.j7(ch_2);
                                            tmp_6 = true;
                                          }
                                          tmp$ret$10 = tmp_6;
                                        }
                                         while (false);
                                        tmp_4 = !tmp$ret$10;
                                      }
                                      if (tmp_4) {
                                        buffer.a1p(((index - start | 0) - lastByteCount | 0) + 1 | 0);
                                        tmp$ret$4 = -1;
                                        break l$ret$5;
                                      }
                                    }
                                    value = 0;
                                  }
                                }
                              }
                               while (inductionVariable < endExclusive);
                            var rc = endExclusive - start | 0;
                            buffer.a1p(rc);
                            tmp$ret$4 = 0;
                          }
                           while (false);
                          tmp_1.z1s_1 = tmp$ret$4;
                          if (skip > 0) {
                            buffer.a1p(skip);
                          }
                          this.z1s_1 = this.b1t_1 ? 0 : coerceAtLeast(this.z1s_1, 1);
                          size = this.z1s_1;
                          break l$ret$13;
                        } catch ($p) {
                          var t = $p;
                          var this_1 = current;
                          after = this_1.r1d_1 - this_1.q1d_1 | 0;
                          throw t;
                        }
                      }
                       while (false);
                      var this_2 = current;
                      after = this_2.r1d_1 - this_2.q1d_1 | 0;
                    } else {
                      after = before;
                    }
                    release = false;
                    var tmp_7;
                    if (after === 0) {
                      tmp_7 = prepareReadNextHead(chunk, current);
                    } else {
                      var tmp_8;
                      if (after < size) {
                        tmp_8 = true;
                      } else {
                        var this_3 = current;
                        var tmp_9 = this_3.u1d_1 - this_3.t1d_1 | 0;
                        tmp_8 = tmp_9 < 8;
                      }
                      if (tmp_8) {
                        completeReadHead(chunk, current);
                        tmp_7 = prepareReadFirstHead(chunk, size);
                      } else {
                        tmp_7 = current;
                      }
                    }
                    var tmp1_elvis_lhs = tmp_7;
                    var tmp_10;
                    if (tmp1_elvis_lhs == null) {
                      break $l$loop_0;
                    } else {
                      tmp_10 = tmp1_elvis_lhs;
                    }
                    var next = tmp_10;
                    current = next;
                    release = true;
                  }
                   while (size > 0);
                  break l$ret$15;
                } catch ($p) {
                  var t_0 = $p;
                  if (release) {
                    completeReadHead(chunk, current);
                  }
                  throw t_0;
                }
              }
               while (false);
              if (release) {
                completeReadHead(chunk, current);
              }
            }
             while (false);
            this.x1s_1(totalBytes.fe(chunk.u1g()).qe());
            this.te_1 = 1;
            continue $sm;
          case 4:
            throw this.we_1;
          case 5:
            if (this.z1s_1 > 1) {
              prematureEndOfStreamUtf(this.z1s_1);
            }

            if (this.a1t_1) {
              this.b1t_1 = true;
            }

            return this.y1s_1 > 0 ? true : this.b1t_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $sleepCOROUTINE$56 extends CoroutineImpl {
  constructor(_this__u8e3s4, sleepCondition, resultContinuation) {
    super(resultContinuation);
    this.w1t_1 = _this__u8e3s4;
    this.x1t_1 = sleepCondition;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.te_1 = 1;
            suspendResult = trySuspend(this.w1t_1, this.x1t_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            if (suspendResult) {
              return Unit_instance;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 2:
            this.w1t_1.b1c();
            return Unit_instance;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $trySuspendCOROUTINE$57 extends CoroutineImpl {
  constructor(_this__u8e3s4, sleepCondition, resultContinuation) {
    super(resultContinuation);
    this.k1t_1 = _this__u8e3s4;
    this.l1t_1 = sleepCondition;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.m1t_1 = false;
            this.n1t_1 = Job_0();
            if (this.k1t_1.a1c_1.atomicfu$compareAndSet(null, this.n1t_1) ? this.l1t_1() : false) {
              this.m1t_1 = true;
              this.te_1 = 1;
              suspendResult = this.n1t_1.pw(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 1:
            this.te_1 = 2;
            continue $sm;
          case 2:
            return this.m1t_1;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class AwaitingSlot {
  constructor() {
    this.a1c_1 = atomic$ref$1(null);
  }
  s1f(sleepCondition, $completion) {
    var tmp = new $sleepCOROUTINE$56(this, sleepCondition, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  b1c() {
    var tmp0_safe_receiver = this.a1c_1.atomicfu$getAndSet(null);
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.ow();
  }
  d1m(cause) {
    var tmp0_elvis_lhs = this.a1c_1.atomicfu$getAndSet(null);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var continuation = tmp;
    if (!(cause == null)) {
      continuation.nw(cause);
    } else {
      continuation.ow();
    }
  }
}
class $copyToSequentialImplCOROUTINE$58 extends CoroutineImpl {
  constructor(_this__u8e3s4, dst, limit, resultContinuation) {
    super(resultContinuation);
    this.g1u_1 = _this__u8e3s4;
    this.h1u_1 = dst;
    this.i1u_1 = limit;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 10;
            if (!!(this.g1u_1 === this.h1u_1)) {
              var message = 'Failed requirement.';
              throw IllegalArgumentException.g3(toString(message));
            }

            if (!(this.g1u_1.v1c() == null)) {
              this.h1u_1.n15(this.g1u_1.v1c());
              return new Long(0, 0);
            }

            this.j1u_1 = this.i1u_1;
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!(this.j1u_1.oc(new Long(0, 0)) > 0)) {
              this.te_1 = 9;
              continue $sm;
            }

            this.te_1 = 2;
            suspendResult = this.g1u_1.a1m(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.k1u_1 = suspendResult;
            if (!this.k1u_1) {
              this.te_1 = 9;
              continue $sm;
            } else {
              this.te_1 = 3;
              continue $sm;
            }

          case 3:
            this.l1u_1 = this.g1u_1.e1m(this.h1u_1, this.j1u_1);
            if (this.l1u_1.equals(new Long(0, 0))) {
              this.te_1 = 6;
              suspendResult = copyToTail(this.g1u_1, this.h1u_1, this.j1u_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              if (this.h1u_1.y1b() === 0) {
                this.te_1 = 4;
                suspendResult = this.h1u_1.x1b(1, this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              } else {
                this.te_1 = 5;
                continue $sm;
              }
            }

          case 4:
            this.te_1 = 5;
            continue $sm;
          case 5:
            this.m1u_1 = this.l1u_1;
            this.te_1 = 8;
            continue $sm;
          case 6:
            var tail = suspendResult;
            if (tail.equals(new Long(0, 0))) {
              this.te_1 = 9;
              continue $sm;
            } else {
              this.te_1 = 7;
              continue $sm;
            }

          case 7:
            this.m1u_1 = tail;
            this.te_1 = 8;
            continue $sm;
          case 8:
            var copied = this.m1u_1;
            this.j1u_1 = this.j1u_1.fe(copied);
            if (copied.oc(new Long(0, 0)) > 0) {
              this.h1u_1.i8();
            }

            this.te_1 = 1;
            continue $sm;
          case 9:
            return this.i1u_1.fe(this.j1u_1);
          case 10:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 10) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $copyToTailCOROUTINE$59 extends CoroutineImpl {
  constructor(_this__u8e3s4, dst, limit, resultContinuation) {
    super(resultContinuation);
    this.v1u_1 = _this__u8e3s4;
    this.w1u_1 = dst;
    this.x1u_1 = limit;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 9;
            this.y1u_1 = Companion_getInstance_4().y1d_1.w1n();
            this.te_1 = 1;
            continue $sm;
          case 1:
            this.te_1 = 2;
            continue $sm;
          case 2:
            this.ue_1 = 8;
            this.y1u_1.h1p(coerceAtMost_0(this.x1u_1, toLong(this.y1u_1.u1d_1)).qe());
            this.te_1 = 3;
            suspendResult = this.v1u_1.x1l(this.y1u_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            this.a1v_1 = suspendResult;
            if (this.a1v_1 === -1) {
              this.y1u_1.t1o(Companion_getInstance_4().y1d_1);
              this.z1u_1 = new Long(0, 0);
              this.ue_1 = 9;
              this.te_1 = 6;
              continue $sm;
            } else {
              this.te_1 = 4;
              continue $sm;
            }

          case 4:
            this.te_1 = 5;
            suspendResult = this.w1u_1.q1l(this.y1u_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            this.z1u_1 = toLong(this.a1v_1);
            this.ue_1 = 9;
            this.te_1 = 6;
            var tmp_0 = this;
            continue $sm;
          case 6:
            var tmp_1 = this.z1u_1;
            this.ue_1 = 9;
            this.y1u_1.t1o(Companion_getInstance_4().y1d_1);
            return tmp_1;
          case 7:
            this.ue_1 = 9;
            this.y1u_1.t1o(Companion_getInstance_4().y1d_1);
            return Unit_instance;
          case 8:
            this.ue_1 = 9;
            var t = this.we_1;
            this.y1u_1.t1o(Companion_getInstance_4().y1d_1);
            throw t;
          case 9:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 9) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class ByteArrayPool$1 extends DefaultPool {
  constructor() {
    super(128);
  }
  d1q() {
    return new Int8Array(4096);
  }
}
class SingleInstancePool {
  constructor($box) {
    boxApply(this, $box);
    this.e1v_1 = atomic$int$1(0);
    this.f1v_1 = atomic$boolean$1(false);
    this.g1v_1 = atomic$ref$1(null);
  }
  w1n() {
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var this_0 = this.e1v_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'io.ktor.utils.io.pool.SingleInstancePool.borrow.<anonymous>' call
        if (!(cur === 0))
          throw IllegalStateException.l1('Instance is already consumed');
        var upd = 1;
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          break $l$block;
        }
      }
    }
    var instance = this.d1q();
    this.g1v_1.kotlinx$atomicfu$value = instance;
    return instance;
  }
  s1q(instance) {
    if (!(this.g1v_1.kotlinx$atomicfu$value === instance)) {
      if (this.g1v_1.kotlinx$atomicfu$value == null ? !(this.e1v_1.kotlinx$atomicfu$value === 0) : false) {
        throw IllegalStateException.l1('Already recycled or an irrelevant instance tried to be recycled');
      }
      throw IllegalStateException.l1('Unable to recycle irrelevant instance');
    }
    this.g1v_1.kotlinx$atomicfu$value = null;
    if (!this.f1v_1.atomicfu$compareAndSet(false, true)) {
      throw IllegalStateException.l1('An instance is already disposed');
    }
    this.k1q(instance);
  }
  nm() {
    if (this.f1v_1.atomicfu$compareAndSet(false, true)) {
      var tmp0_elvis_lhs = this.g1v_1.kotlinx$atomicfu$value;
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return Unit_instance;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var value = tmp;
      this.g1v_1.kotlinx$atomicfu$value = null;
      this.k1q(value);
    }
  }
}
class ByteChannelJS extends ByteChannelSequentialBase {
  constructor(initial, autoFlush) {
    super(initial, autoFlush);
    this.w1v_1 = null;
  }
  f1m(job) {
    var tmp0_safe_receiver = this.w1v_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.gq();
    }
    this.w1v_1 = job;
    job.bq(true, VOID, ByteChannelJS$attachJob$lambda(this));
  }
  toString() {
    return 'ByteChannel[' + this.w1v_1 + ', ' + hashCode(this) + ']';
  }
}
class Companion_3 {
  constructor() {
    Companion_instance_3 = this;
    var tmp = this;
    tmp.h1v_1 = lazy(ByteReadChannel$Companion$Empty$delegate$lambda);
  }
  w1d() {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.h1v_1;
    Empty$factory();
    return this_0.u2();
  }
}
class DefaultAllocator {
  e1q(size) {
    return new Memory(new DataView(new ArrayBuffer(size)));
  }
  g1q(instance) {
  }
}
class Companion_4 {
  constructor() {
    Companion_instance_4 = this;
    this.d1s_1 = new Memory(new DataView(new ArrayBuffer(0)));
  }
}
class Memory {
  constructor(view) {
    Companion_getInstance_6();
    this.z1o_1 = view;
  }
  x1v(offset, length) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(offset >= 0)) {
      // Inline function 'io.ktor.utils.io.bits.Memory.slice.<anonymous>' call
      var message = "offset shouldn't be negative: " + offset;
      throw IllegalArgumentException.g3(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(length >= 0)) {
      // Inline function 'io.ktor.utils.io.bits.Memory.slice.<anonymous>' call
      var message_0 = "length shouldn't be negative: " + length;
      throw IllegalArgumentException.g3(toString(message_0));
    }
    var tmp = toLong(offset + length | 0);
    // Inline function 'io.ktor.utils.io.bits.Memory.size' call
    var tmp$ret$2 = toLong(this.z1o_1.byteLength);
    if (tmp.oc(tmp$ret$2) > 0) {
      // Inline function 'io.ktor.utils.io.bits.Memory.size' call
      var tmp$ret$3 = toLong(this.z1o_1.byteLength);
      throw IndexOutOfBoundsException.qa('offset + length > size: ' + offset + ' + ' + length + ' > ' + tmp$ret$3.toString());
    }
    return new Memory(new DataView(this.z1o_1.buffer, this.z1o_1.byteOffset + offset | 0, length));
  }
  x1p(destination, offset, length, destinationOffset) {
    var src = new Int8Array(this.z1o_1.buffer, this.z1o_1.byteOffset + offset | 0, length);
    var dst = new Int8Array(destination.z1o_1.buffer, destination.z1o_1.byteOffset + destinationOffset | 0, length);
    dst.set(src);
  }
  c1s(destination, offset, length, destinationOffset) {
    // Inline function 'io.ktor.utils.io.core.internal.toIntOrFail' call
    if (offset.oc(toLong(IntCompanionObject_instance.MAX_VALUE)) >= 0) {
      failLongToIntConversion(offset, 'offset');
    }
    var tmp = offset.qe();
    // Inline function 'io.ktor.utils.io.core.internal.toIntOrFail' call
    if (length.oc(toLong(IntCompanionObject_instance.MAX_VALUE)) >= 0) {
      failLongToIntConversion(length, 'length');
    }
    var tmp_0 = length.qe();
    // Inline function 'io.ktor.utils.io.core.internal.toIntOrFail' call
    var name = 'destinationOffset';
    if (destinationOffset.oc(toLong(IntCompanionObject_instance.MAX_VALUE)) >= 0) {
      failLongToIntConversion(destinationOffset, name);
    }
    var tmp$ret$2 = destinationOffset.qe();
    this.x1p(destination, tmp, tmp_0, tmp$ret$2);
  }
}
class Companion_5 {
  y1v(name) {
    switch (name) {
      case 'UTF-8':
      case 'utf-8':
      case 'UTF8':
      case 'utf8':
        return Charsets_getInstance().e1s_1;
    }
    var tmp;
    var tmp_0;
    var tmp_1;
    switch (name) {
      case 'ISO-8859-1':
      case 'iso-8859-1':
        tmp_1 = true;
        break;
      default:
        // Inline function 'kotlin.let' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'io.ktor.utils.io.charsets.Companion.forName.<anonymous>' call

        var it = replace(name, _Char___init__impl__6a9atx(95), _Char___init__impl__6a9atx(45));
        var tmp_2;
        if (it === 'iso-8859-1') {
          tmp_2 = true;
        } else {
          // Inline function 'kotlin.text.lowercase' call
          // Inline function 'kotlin.js.asDynamic' call
          tmp_2 = it.toLowerCase() === 'iso-8859-1';
        }

        tmp_1 = tmp_2;
        break;
    }
    if (tmp_1) {
      tmp_0 = true;
    } else {
      tmp_0 = name === 'latin1';
    }
    if (tmp_0) {
      tmp = true;
    } else {
      tmp = name === 'Latin1';
    }
    if (tmp) {
      return Charsets_getInstance().f1s_1;
    }
    throw IllegalArgumentException.g3('Charset ' + name + ' is not supported');
  }
  z1v(charset) {
    var tmp;
    switch (charset) {
      case 'UTF-8':
      case 'utf-8':
      case 'UTF8':
      case 'utf8':
        tmp = true;
        break;
      default:
        var tmp_0;
        var tmp_1;
        switch (charset) {
          case 'ISO-8859-1':
          case 'iso-8859-1':
            tmp_1 = true;
            break;
          default:
            // Inline function 'kotlin.let' call

            // Inline function 'kotlin.contracts.contract' call

            // Inline function 'io.ktor.utils.io.charsets.Companion.isSupported.<anonymous>' call

            var it = replace(charset, _Char___init__impl__6a9atx(95), _Char___init__impl__6a9atx(45));
            var tmp_2;
            if (it === 'iso-8859-1') {
              tmp_2 = true;
            } else {
              // Inline function 'kotlin.text.lowercase' call
              // Inline function 'kotlin.js.asDynamic' call
              tmp_2 = it.toLowerCase() === 'iso-8859-1';
            }

            tmp_1 = tmp_2;
            break;
        }

        if (tmp_1) {
          tmp_0 = true;
        } else {
          tmp_0 = charset === 'latin1';
        }

        if (tmp_0) {
          tmp = true;
        } else {
          tmp = false;
        }

        break;
    }
    return tmp;
  }
}
class Charset {
  constructor(_name) {
    this.g1s_1 = _name;
  }
  equals(other) {
    if (this === other)
      return true;
    if (other == null ? true : !(this.constructor == other.constructor))
      return false;
    if (!(other instanceof Charset))
      THROW_CCE();
    if (!(this.g1s_1 === other.g1s_1))
      return false;
    return true;
  }
  hashCode() {
    return getStringHashCode(this.g1s_1);
  }
  toString() {
    return this.g1s_1;
  }
}
class Charsets {
  constructor() {
    Charsets_instance = this;
    this.e1s_1 = new CharsetImpl('UTF-8');
    this.f1s_1 = new CharsetImpl('ISO-8859-1');
  }
}
class CharsetDecoder {
  constructor(_charset) {
    this.a1w_1 = _charset;
  }
}
class CharsetEncoder {
  constructor(_charset) {
    this.b1w_1 = _charset;
  }
}
class CharsetImpl extends Charset {
  constructor(name) {
    super(name);
    this.d1w_1 = name;
  }
  i1s() {
    return new CharsetEncoderImpl(this);
  }
  h1s() {
    return new CharsetDecoderImpl(this);
  }
  toString() {
    return 'CharsetImpl(name=' + this.d1w_1 + ')';
  }
  hashCode() {
    return getStringHashCode(this.d1w_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CharsetImpl))
      return false;
    var tmp0_other_with_cast = other instanceof CharsetImpl ? other : THROW_CCE();
    if (!(this.d1w_1 === tmp0_other_with_cast.d1w_1))
      return false;
    return true;
  }
}
class CharsetEncoderImpl extends CharsetEncoder {
  constructor(charset) {
    super(charset);
    this.f1w_1 = charset;
  }
  toString() {
    return 'CharsetEncoderImpl(charset=' + this.f1w_1 + ')';
  }
  hashCode() {
    return this.f1w_1.hashCode();
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CharsetEncoderImpl))
      return false;
    var tmp0_other_with_cast = other instanceof CharsetEncoderImpl ? other : THROW_CCE();
    if (!this.f1w_1.equals(tmp0_other_with_cast.f1w_1))
      return false;
    return true;
  }
}
class CharsetDecoderImpl extends CharsetDecoder {
  constructor(charset) {
    super(charset);
    this.h1w_1 = charset;
  }
  toString() {
    return 'CharsetDecoderImpl(charset=' + this.h1w_1 + ')';
  }
  hashCode() {
    return this.h1w_1.hashCode();
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CharsetDecoderImpl))
      return false;
    var tmp0_other_with_cast = other instanceof CharsetDecoderImpl ? other : THROW_CCE();
    if (!this.h1w_1.equals(tmp0_other_with_cast.h1w_1))
      return false;
    return true;
  }
}
class DecodeBufferResult {
  constructor(charactersDecoded, bytesConsumed) {
    this.k1w_1 = charactersDecoded;
    this.l1w_1 = bytesConsumed;
  }
  toString() {
    return 'DecodeBufferResult(charactersDecoded=' + this.k1w_1 + ', bytesConsumed=' + this.l1w_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.k1w_1);
    result = imul(result, 31) + this.l1w_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof DecodeBufferResult))
      return false;
    var tmp0_other_with_cast = other instanceof DecodeBufferResult ? other : THROW_CCE();
    if (!(this.k1w_1 === tmp0_other_with_cast.k1w_1))
      return false;
    if (!(this.l1w_1 === tmp0_other_with_cast.l1w_1))
      return false;
    return true;
  }
}
class ByteReadPacket$pool$1 extends SingleInstancePool {
  constructor($sub, $block, $array, $box) {
    if ($box === VOID)
      $box = {};
    $box.q1w_1 = $sub;
    $box.r1w_1 = $block;
    $box.s1w_1 = $array;
    super($box);
  }
  d1q() {
    return new ChunkBuffer(of_1(Companion_getInstance_6(), this.q1w_1), null, this);
  }
  f1q(instance) {
    this.r1w_1(this.s1w_1);
  }
  k1q(instance) {
    return this.f1q(instance instanceof ChunkBuffer ? instance : THROW_CCE());
  }
}
class IOException extends Exception {
  static w1w(message, cause) {
    var $this = this.yf(message, cause);
    captureStack($this, $this.u1w_1);
    return $this;
  }
  static v1w(message) {
    return this.w1w(message, null);
  }
}
class EOFException extends IOException {
  static q1p(message) {
    var $this = this.v1w(message);
    captureStack($this, $this.p1p_1);
    return $this;
  }
}
class toKtor$1 {
  constructor($this_toKtor) {
    this.x1w_1 = $this_toKtor;
  }
  j1w() {
    return this.x1w_1.decode();
  }
  m1w(buffer) {
    return this.x1w_1.decode(buffer);
  }
  i1w(buffer, options) {
    return this.x1w_1.decode(buffer, options);
  }
}
class TextDecoderFallback {
  constructor(encoding, fatal) {
    this.y1w_1 = fatal;
    // Inline function 'kotlin.text.lowercase' call
    // Inline function 'kotlin.text.trim' call
    // Inline function 'kotlin.js.asDynamic' call
    var requestedEncoding = toString(trim(isCharSequence(encoding) ? encoding : THROW_CCE())).toLowerCase();
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!get_ENCODING_ALIASES().x(requestedEncoding)) {
      // Inline function 'io.ktor.utils.io.js.TextDecoderFallback.<anonymous>' call
      var message = encoding + ' is not supported.';
      throw IllegalStateException.l1(toString(message));
    }
  }
  j1w() {
    return '';
  }
  m1w(buffer) {
    var tmp$ret$3;
    $l$block: {
      // Inline function 'io.ktor.utils.io.core.buildPacket' call
      // Inline function 'kotlin.contracts.contract' call
      var builder = new BytePacketBuilder();
      try {
        // Inline function 'io.ktor.utils.io.js.TextDecoderFallback.decode.<anonymous>' call
        var bytes = buffer instanceof Int8Array ? buffer : THROW_CCE();
        var inductionVariable = 0;
        var last = bytes.length;
        if (inductionVariable < last)
          $l$loop: do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'org.khronos.webgl.get' call
            // Inline function 'kotlin.js.asDynamic' call
            var byte = bytes[index];
            var point = toCodePoint(byte);
            if (point < 0) {
              // Inline function 'kotlin.check' call
              // Inline function 'kotlin.contracts.contract' call
              if (!!this.y1w_1) {
                // Inline function 'io.ktor.utils.io.js.TextDecoderFallback.decode.<anonymous>.<anonymous>' call
                var message = 'Invalid character: ' + point;
                throw IllegalStateException.l1(toString(message));
              }
              writeFully_3(builder, get_REPLACEMENT());
              continue $l$loop;
            }
            if (point > 255) {
              builder.r1p(toByte(point >> 8));
            }
            builder.r1p(toByte(point & 255));
          }
           while (inductionVariable < last);
        tmp$ret$3 = builder.k1i();
        break $l$block;
      } catch ($p) {
        if ($p instanceof Error) {
          var t = $p;
          builder.ww();
          throw t;
        } else {
          throw $p;
        }
      }
    }
    return decodeToString(readBytes(tmp$ret$3));
  }
  i1w(buffer, options) {
    return this.m1w(buffer);
  }
}
//endregion
function ByteReadChannel_0(content) {
  return ByteReadChannel_1(content, 0, content.length);
}
function _get_isCancelled__nhbn6y($this) {
  var tmp0_safe_receiver = $this.p1b_1.kotlinx$atomicfu$value;
  return !((tmp0_safe_receiver == null ? null : tmp0_safe_receiver.z1b_1) == null);
}
function _set_lastReadAvailable__98ukjs($this, _set____db54di) {
  var this_0 = $this.s1b_1;
  lastReadAvailable$factory();
  this_0.kotlinx$atomicfu$value = _set____db54di;
  return Unit_instance;
}
function _get_lastReadAvailable__cgybqk($this) {
  // Inline function 'kotlinx.atomicfu.AtomicInt.getValue' call
  var this_0 = $this.s1b_1;
  lastReadAvailable$factory_0();
  return this_0.kotlinx$atomicfu$value;
}
function _set_lastReadView__2y3peu($this, _set____db54di) {
  var this_0 = $this.t1b_1;
  lastReadView$factory();
  this_0.kotlinx$atomicfu$value = _set____db54di;
  return Unit_instance;
}
function _get_lastReadView__ihufyy($this) {
  // Inline function 'kotlinx.atomicfu.AtomicRef.getValue' call
  var this_0 = $this.t1b_1;
  lastReadView$factory_0();
  return this_0.kotlinx$atomicfu$value;
}
function flushImpl($this) {
  if ($this.q1b_1.k1c()) {
    $this.u1b_1.b1c();
    return false;
  }
  flushWrittenBytes($this);
  $this.u1b_1.b1c();
  return true;
}
function flushWrittenBytes($this) {
  // Inline function 'kotlinx.atomicfu.locks.synchronized' call
  $this.v1b_1;
  // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.flushWrittenBytes.<anonymous>' call
  var size = $this.q1b_1.k();
  var buffer = ensureNotNull($this.q1b_1.t1c());
  $this.w1b_1.u1c(buffer);
  $this.n1b_1.atomicfu$addAndGet(size);
}
function ensureNotClosed($this) {
  if ($this.c1d()) {
    var tmp0_elvis_lhs = $this.v1c();
    throw tmp0_elvis_lhs == null ? ClosedWriteChannelException.b1d('Channel ' + $this + ' is already closed') : tmp0_elvis_lhs;
  }
}
function ensureNotFailed($this) {
  var tmp0_safe_receiver = $this.v1c();
  if (tmp0_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    throw tmp0_safe_receiver;
  }
}
function ensureNotFailed_0($this, closeable) {
  var tmp0_safe_receiver = $this.v1c();
  if (tmp0_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    closeable.ww();
    throw tmp0_safe_receiver;
  }
}
function readRemainingSuspend($this, builder, limit, $completion) {
  var tmp = new $readRemainingSuspendCOROUTINE$27($this, builder, limit, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function completeReading($this) {
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var this_0 = _get_lastReadView__ihufyy($this);
  var remaining = this_0.r1d_1 - this_0.q1d_1 | 0;
  var delta = _get_lastReadAvailable__cgybqk($this) - remaining | 0;
  if (!(_get_lastReadView__ihufyy($this) === Companion_instance.w1d())) {
    completeReadHead($this.r1b_1, _get_lastReadView__ihufyy($this));
  }
  if (delta > 0) {
    $this.x1d(delta);
  }
  _set_lastReadAvailable__98ukjs($this, 0);
  _set_lastReadView__2y3peu($this, Companion_getInstance_4().a1e_1);
}
function discardSuspend($this, max, discarded0, $completion) {
  var tmp = new $discardSuspendCOROUTINE$41($this, max, discarded0, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function addBytesRead($this, count) {
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(count >= 0)) {
    // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.addBytesRead.<anonymous>' call
    var message = "Can't read negative amount of bytes: " + count;
    throw IllegalArgumentException.g3(toString(message));
  }
  // Inline function 'kotlinx.atomicfu.AtomicInt.minusAssign' call
  $this.o1b_1.atomicfu$getAndAdd(-count | 0);
  $this.l1b_1.atomicfu$addAndGet$long(toLong(count));
  // Inline function 'kotlinx.atomicfu.AtomicInt.minusAssign' call
  $this.n1b_1.atomicfu$getAndAdd(-count | 0);
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  if (!($this.o1b_1.kotlinx$atomicfu$value >= 0)) {
    // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.addBytesRead.<anonymous>' call
    var message_0 = 'Readable bytes count is negative: ' + $this.p1e() + ', ' + count + ' in ' + $this;
    throw IllegalStateException.l1(toString(message_0));
  }
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  if (!($this.p1e() >= 0)) {
    // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.addBytesRead.<anonymous>' call
    var message_1 = 'Readable bytes count is negative: ' + $this.p1e() + ', ' + count + ' in ' + $this;
    throw IllegalStateException.l1(toString(message_1));
  }
}
function addBytesWritten($this, count) {
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(count >= 0)) {
    // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.addBytesWritten.<anonymous>' call
    var message = "Can't write negative amount of bytes: " + count;
    throw IllegalArgumentException.g3(toString(message));
  }
  // Inline function 'kotlinx.atomicfu.AtomicInt.plusAssign' call
  $this.o1b_1.atomicfu$getAndAdd(count);
  $this.m1b_1.atomicfu$addAndGet$long(toLong(count));
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  if (!($this.o1b_1.kotlinx$atomicfu$value >= 0)) {
    // Inline function 'io.ktor.utils.io.ByteChannelSequentialBase.addBytesWritten.<anonymous>' call
    var message_0 = 'Readable bytes count is negative: ' + $this.o1b_1.kotlinx$atomicfu$value + ', ' + count + ' in ' + $this;
    throw IllegalStateException.l1(toString(message_0));
  }
}
function ByteChannelSequentialBase$awaitAtLeastNBytesAvailableForWrite$lambda(this$0, $count) {
  return function () {
    return this$0.y1b() < $count ? !this$0.c1d() : false;
  };
}
function ByteChannelSequentialBase$awaitAtLeastNBytesAvailableForRead$lambda(this$0, $count) {
  return function () {
    return this$0.p1e() < $count ? !this$0.q1e() : false;
  };
}
function ByteChannelSequentialBase$readUTF8LineTo$slambda_0(this$0, resultContinuation) {
  var i = new ByteChannelSequentialBase$readUTF8LineTo$slambda(this$0, resultContinuation);
  var l = function (size, $completion) {
    return i.f1f(size, $completion);
  };
  l.$arity = 1;
  return l;
}
function ByteChannelSequentialBase$readUTF8LineTo$lambda(this$0) {
  return function (it) {
    this$0.x1d(it);
    return Unit_instance;
  };
}
function lastReadAvailable$factory() {
  return getPropertyCallableRef('lastReadAvailable', 1, KMutableProperty1, function (receiver) {
    return _get_lastReadAvailable__cgybqk(receiver);
  }, function (receiver, value) {
    return _set_lastReadAvailable__98ukjs(receiver, value);
  });
}
function lastReadAvailable$factory_0() {
  return getPropertyCallableRef('lastReadAvailable', 1, KMutableProperty1, function (receiver) {
    return _get_lastReadAvailable__cgybqk(receiver);
  }, function (receiver, value) {
    return _set_lastReadAvailable__98ukjs(receiver, value);
  });
}
function lastReadView$factory() {
  return getPropertyCallableRef('lastReadView', 1, KMutableProperty1, function (receiver) {
    return _get_lastReadView__ihufyy(receiver);
  }, function (receiver, value) {
    return _set_lastReadView__2y3peu(receiver, value);
  });
}
function lastReadView$factory_0() {
  return getPropertyCallableRef('lastReadView', 1, KMutableProperty1, function (receiver) {
    return _get_lastReadView__ihufyy(receiver);
  }, function (receiver, value) {
    return _set_lastReadView__2y3peu(receiver, value);
  });
}
function cancel(_this__u8e3s4) {
  return _this__u8e3s4.nv(null);
}
function readAvailable(_this__u8e3s4, dst, $completion) {
  return _this__u8e3s4.z1l(dst, 0, dst.length, $completion);
}
function readUTF8Line(_this__u8e3s4, $completion) {
  return _this__u8e3s4.c1m(IntCompanionObject_instance.MAX_VALUE, $completion);
}
function copyTo(_this__u8e3s4, dst, $completion) {
  Companion_getInstance();
  return copyTo_0(_this__u8e3s4, dst, new Long(-1, 2147483647), $completion);
}
function discard(_this__u8e3s4, $completion) {
  Companion_getInstance();
  return _this__u8e3s4.b1m(new Long(-1, 2147483647), $completion);
}
function writeFully(_this__u8e3s4, src, $completion) {
  return _this__u8e3s4.r1l(src, 0, src.length, $completion);
}
function close_0(_this__u8e3s4) {
  return _this__u8e3s4.n15(null);
}
function get_CLOSED_SUCCESS() {
  _init_properties_CloseElement_kt__5e72ik();
  return CLOSED_SUCCESS;
}
var CLOSED_SUCCESS;
var properties_initialized_CloseElement_kt_clkism;
function _init_properties_CloseElement_kt__5e72ik() {
  if (!properties_initialized_CloseElement_kt_clkism) {
    properties_initialized_CloseElement_kt_clkism = true;
    CLOSED_SUCCESS = new CloseElement(null);
  }
}
function writer(_this__u8e3s4, coroutineContext, autoFlush, block) {
  coroutineContext = coroutineContext === VOID ? EmptyCoroutineContext_getInstance() : coroutineContext;
  autoFlush = autoFlush === VOID ? false : autoFlush;
  return launchChannel(_this__u8e3s4, coroutineContext, ByteChannel(autoFlush), true, block);
}
function launchChannel(_this__u8e3s4, context, channel, attachJob, block) {
  var dispatcher = _this__u8e3s4.yo().bf(Key_getInstance());
  var job = launch(_this__u8e3s4, context, VOID, launchChannel$slambda_0(attachJob, channel, block, dispatcher, null));
  job.zp(launchChannel$lambda(channel));
  return new ChannelJob(job, channel);
}
function launchChannel$slambda_0($attachJob, $channel, $block, $dispatcher, resultContinuation) {
  var i = new launchChannel$slambda($attachJob, $channel, $block, $dispatcher, resultContinuation);
  var l = function ($this$launch, $completion) {
    return i.y1m($this$launch, $completion);
  };
  l.$arity = 1;
  return l;
}
function launchChannel$lambda($channel) {
  return function (cause) {
    $channel.n15(cause);
    return Unit_instance;
  };
}
function unwrapCancellationException(_this__u8e3s4) {
  var exception = _this__u8e3s4;
  $l$loop: while (exception instanceof CancellationException) {
    if (equals(exception, exception.cause)) {
      return _this__u8e3s4;
    }
    var tmp0_elvis_lhs = exception.cause;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return exception;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    exception = tmp;
  }
  return exception;
}
function requestWriteBuffer(_this__u8e3s4, desiredSpace, $completion) {
  var tmp = new $requestWriteBufferCOROUTINE$52(_this__u8e3s4, desiredSpace, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function completeWriting(_this__u8e3s4, buffer, written, $completion) {
  if (isInterface(_this__u8e3s4, HasWriteSession)) {
    _this__u8e3s4.t1l(written);
    return Unit_instance;
  }
  return completeWritingFallback(_this__u8e3s4, buffer, $completion);
}
function writeSessionFor(_this__u8e3s4) {
  var tmp;
  if (isInterface(_this__u8e3s4, HasWriteSession)) {
    tmp = _this__u8e3s4.s1l();
  } else {
    tmp = null;
  }
  return tmp;
}
function writeBufferSuspend(session, desiredSpace, $completion) {
  var tmp = new $writeBufferSuspendCOROUTINE$53(session, desiredSpace, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function writeBufferFallback() {
  // Inline function 'kotlin.also' call
  var this_0 = Companion_getInstance_4().y1d_1.w1n();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.writeBufferFallback.<anonymous>' call
  this_0.x1n();
  this_0.y1n(8);
  return this_0;
}
function completeWritingFallback(_this__u8e3s4, buffer, $completion) {
  var tmp = new $completeWritingFallbackCOROUTINE$54(_this__u8e3s4, buffer, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function decode(_this__u8e3s4, input, max) {
  max = max === VOID ? IntCompanionObject_instance.MAX_VALUE : max;
  // Inline function 'kotlin.text.buildString' call
  // Inline function 'kotlin.comparisons.minOf' call
  var a = toLong(max);
  var b = sizeEstimate(input);
  var capacity = (a.oc(b) <= 0 ? a : b).qe();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.n7(capacity);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.charsets.decode.<anonymous>' call
  decode_0(_this__u8e3s4, input, this_0, max);
  return this_0.toString();
}
function encodeToImpl(_this__u8e3s4, destination, input, fromIndex, toIndex) {
  var start = fromIndex;
  if (start >= toIndex)
    return 0;
  var bytesWritten = 0;
  // Inline function 'io.ktor.utils.io.core.writeWhileSize' call
  var tail = prepareWriteHead(destination, 1, null);
  try {
    var size;
    $l$loop: while (true) {
      // Inline function 'io.ktor.utils.io.charsets.encodeToImpl.<anonymous>' call
      var view = tail;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var before = view.t1d_1 - view.r1d_1 | 0;
      var rc = encodeImpl(_this__u8e3s4, input, start, toIndex, view);
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(rc >= 0)) {
        // Inline function 'kotlin.check.<anonymous>' call
        var message = 'Check failed.';
        throw IllegalStateException.l1(toString(message));
      }
      start = start + rc | 0;
      var tmp = bytesWritten;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      bytesWritten = tmp + (before - (view.t1d_1 - view.r1d_1 | 0) | 0) | 0;
      size = start >= toIndex ? 0 : rc === 0 ? 8 : 1;
      if (size <= 0)
        break $l$loop;
      tail = prepareWriteHead(destination, size, tail);
    }
  }finally {
    destination.u1l();
  }
  bytesWritten = bytesWritten + encodeCompleteImpl(_this__u8e3s4, destination) | 0;
  return bytesWritten;
}
function sizeEstimate(_this__u8e3s4) {
  var tmp;
  if (_this__u8e3s4 instanceof ByteReadPacket) {
    tmp = _this__u8e3s4.u1g();
  } else {
    // Inline function 'kotlin.comparisons.maxOf' call
    var a = _this__u8e3s4.u1g();
    var b = new Long(16, 0);
    tmp = a.oc(b) >= 0 ? a : b;
  }
  return tmp;
}
function encodeCompleteImpl(_this__u8e3s4, dst) {
  var size = 1;
  var bytesWritten = 0;
  // Inline function 'io.ktor.utils.io.core.writeWhile' call
  var tail = prepareWriteHead(dst, 1, null);
  try {
    $l$loop: while (true) {
      // Inline function 'io.ktor.utils.io.charsets.encodeCompleteImpl.<anonymous>' call
      var view = tail;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var before = view.t1d_1 - view.r1d_1 | 0;
      if (encodeComplete(_this__u8e3s4, view)) {
        size = 0;
      } else {
        size = size + 1 | 0;
      }
      var tmp = bytesWritten;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      bytesWritten = tmp + (before - (view.t1d_1 - view.r1d_1 | 0) | 0) | 0;
      if (!(size > 0))
        break $l$loop;
      tail = prepareWriteHead(dst, 1, tail);
    }
  }finally {
    dst.u1l();
  }
  return bytesWritten;
}
function encode(_this__u8e3s4, input, fromIndex, toIndex) {
  fromIndex = fromIndex === VOID ? 0 : fromIndex;
  toIndex = toIndex === VOID ? charSequenceLength(input) : toIndex;
  var tmp$ret$0;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.buildPacket' call
    // Inline function 'kotlin.contracts.contract' call
    var builder = new BytePacketBuilder();
    try {
      // Inline function 'io.ktor.utils.io.charsets.encode.<anonymous>' call
      encodeToImpl(_this__u8e3s4, builder, input, fromIndex, toIndex);
      tmp$ret$0 = builder.k1i();
      break $l$block;
    } catch ($p) {
      if ($p instanceof Error) {
        var t = $p;
        builder.ww();
        throw t;
      } else {
        throw $p;
      }
    }
  }
  return tmp$ret$0;
}
var Companion_instance;
function Companion_getInstance_1() {
  return Companion_instance;
}
function discardFailed(count, readRemaining) {
  throw EOFException.q1p('Unable to discard ' + count + ' bytes: only ' + readRemaining + ' available for reading');
}
function commitWrittenFailed(count, writeRemaining) {
  throw EOFException.q1p('Unable to discard ' + count + ' bytes: only ' + writeRemaining + ' available for writing');
}
function rewindFailed(count, rewindRemaining) {
  throw IllegalArgumentException.g3('Unable to rewind ' + count + ' bytes: only ' + rewindRemaining + ' could be rewinded');
}
function startGapReservationFailedDueToLimit(_this__u8e3s4, startGap) {
  if (startGap > _this__u8e3s4.u1d_1) {
    throw IllegalArgumentException.g3('Start gap ' + startGap + ' is bigger than the capacity ' + _this__u8e3s4.u1d_1);
  }
  // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
  var tmp$ret$0 = _this__u8e3s4.u1d_1 - _this__u8e3s4.t1d_1 | 0;
  throw IllegalStateException.l1('Unable to reserve ' + startGap + ' start gap: there are already ' + tmp$ret$0 + ' bytes reserved in the end');
}
function startGapReservationFailed(_this__u8e3s4, startGap) {
  var tmp = 'Unable to reserve ' + startGap + ' start gap: ';
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var tmp$ret$0 = _this__u8e3s4.r1d_1 - _this__u8e3s4.q1d_1 | 0;
  throw IllegalStateException.l1(tmp + ('there are already ' + tmp$ret$0 + ' content bytes starting at offset ' + _this__u8e3s4.q1d_1));
}
function endGapReservationFailedDueToCapacity(_this__u8e3s4, endGap) {
  throw IllegalArgumentException.g3('End gap ' + endGap + ' is too big: capacity is ' + _this__u8e3s4.u1d_1);
}
function endGapReservationFailedDueToStartGap(_this__u8e3s4, endGap) {
  throw IllegalArgumentException.g3('End gap ' + endGap + ' is too big: there are already ' + _this__u8e3s4.s1d_1 + ' bytes reserved in the beginning');
}
function endGapReservationFailedDueToContent(_this__u8e3s4, endGap) {
  var tmp = 'Unable to reserve end gap ' + endGap + ':';
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var tmp$ret$0 = _this__u8e3s4.r1d_1 - _this__u8e3s4.q1d_1 | 0;
  throw IllegalArgumentException.g3(tmp + (' there are already ' + tmp$ret$0 + ' content bytes at offset ' + _this__u8e3s4.q1d_1));
}
function restoreStartGap(_this__u8e3s4, size) {
  _this__u8e3s4.j1p(_this__u8e3s4.q1d_1 - size | 0);
}
function writeBufferAppend(_this__u8e3s4, other, maxSize) {
  // Inline function 'kotlin.comparisons.minOf' call
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var a = other.r1d_1 - other.q1d_1 | 0;
  var size = Math.min(a, maxSize);
  // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
  if ((_this__u8e3s4.t1d_1 - _this__u8e3s4.r1d_1 | 0) <= size) {
    writeBufferAppendUnreserve(_this__u8e3s4, size);
  }
  // Inline function 'io.ktor.utils.io.core.write' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.writeBufferAppend.<anonymous>' call
  var dst = _this__u8e3s4.p1d_1;
  var dstOffset = _this__u8e3s4.r1d_1;
  _this__u8e3s4.t1d_1;
  // Inline function 'io.ktor.utils.io.core.read' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.writeBufferAppend.<anonymous>.<anonymous>' call
  var src = other.p1d_1;
  var srcOffset = other.q1d_1;
  other.r1d_1;
  src.x1p(dst, srcOffset, size, dstOffset);
  var rc = size;
  other.a1p(rc);
  var rc_0 = rc;
  _this__u8e3s4.b1p(rc_0);
  return rc_0;
}
function writeBufferAppendUnreserve(_this__u8e3s4, writeSize) {
  // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
  var tmp = _this__u8e3s4.t1d_1 - _this__u8e3s4.r1d_1 | 0;
  // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
  if ((tmp + (_this__u8e3s4.u1d_1 - _this__u8e3s4.t1d_1 | 0) | 0) < writeSize) {
    throw IllegalArgumentException.g3("Can't append buffer: not enough free space at the end");
  }
  var newWritePosition = _this__u8e3s4.r1d_1 + writeSize | 0;
  var overrunSize = newWritePosition - _this__u8e3s4.t1d_1 | 0;
  if (overrunSize > 0) {
    _this__u8e3s4.k1p();
  }
}
function writeBufferPrepend(_this__u8e3s4, other) {
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var size = other.r1d_1 - other.q1d_1 | 0;
  var readPosition = _this__u8e3s4.q1d_1;
  if (readPosition < size) {
    throw IllegalArgumentException.g3('Not enough space in the beginning to prepend bytes');
  }
  var newReadPosition = readPosition - size | 0;
  other.p1d_1.x1p(_this__u8e3s4.p1d_1, other.q1d_1, size, newReadPosition);
  other.a1p(size);
  _this__u8e3s4.j1p(newReadPosition);
  return size;
}
function get_DefaultChunkedBufferPool() {
  _init_properties_BufferFactory_kt__uj6b48();
  return DefaultChunkedBufferPool;
}
var DefaultChunkedBufferPool;
function get_DEFAULT_BUFFER_SIZE() {
  return DEFAULT_BUFFER_SIZE;
}
var DEFAULT_BUFFER_SIZE;
var properties_initialized_BufferFactory_kt_q9tgbq;
function _init_properties_BufferFactory_kt__uj6b48() {
  if (!properties_initialized_BufferFactory_kt_q9tgbq) {
    properties_initialized_BufferFactory_kt_q9tgbq = true;
    DefaultChunkedBufferPool = new DefaultBufferPool();
  }
}
function writeFully_0(_this__u8e3s4, source, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? source.length - offset | 0 : length;
  // Inline function 'io.ktor.utils.io.core.writeExact' call
  var name = 'byte array';
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.write' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.writeExact.<anonymous>' call
  var memory = _this__u8e3s4.p1d_1;
  var start = _this__u8e3s4.r1d_1;
  var writeRemaining = _this__u8e3s4.t1d_1 - start | 0;
  if (writeRemaining < length) {
    throw InsufficientSpaceException.w1p(name, length, writeRemaining);
  }
  // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
  // Inline function 'io.ktor.utils.io.bits.storeByteArray' call
  // Inline function 'io.ktor.utils.io.bits.useMemory' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  of(Companion_getInstance_6(), source, offset, length).x1p(memory, 0, length, start);
  var rc = length;
  _this__u8e3s4.b1p(rc);
}
function writeFully_1(_this__u8e3s4, src, length) {
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(length >= 0)) {
    // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
    var message = "length shouldn't be negative: " + length;
    throw IllegalArgumentException.g3(toString(message));
  }
  // Inline function 'kotlin.require' call
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(length <= (src.r1d_1 - src.q1d_1 | 0))) {
    // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    var message_0 = "length shouldn't be greater than the source read remaining: " + length + ' > ' + (src.r1d_1 - src.q1d_1 | 0);
    throw IllegalArgumentException.g3(toString(message_0));
  }
  // Inline function 'kotlin.require' call
  // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(length <= (_this__u8e3s4.t1d_1 - _this__u8e3s4.r1d_1 | 0))) {
    // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
    // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
    var message_1 = "length shouldn't be greater than the destination write remaining space: " + length + ' > ' + (_this__u8e3s4.t1d_1 - _this__u8e3s4.r1d_1 | 0);
    throw IllegalArgumentException.g3(toString(message_1));
  }
  // Inline function 'io.ktor.utils.io.core.writeExact' call
  var name = 'buffer readable content';
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.write' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.writeExact.<anonymous>' call
  var memory = _this__u8e3s4.p1d_1;
  var start = _this__u8e3s4.r1d_1;
  var writeRemaining = _this__u8e3s4.t1d_1 - start | 0;
  if (writeRemaining < length) {
    throw InsufficientSpaceException.w1p(name, length, writeRemaining);
  }
  // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
  src.p1d_1.x1p(memory, src.q1d_1, length, start);
  src.a1p(length);
  var rc = length;
  _this__u8e3s4.b1p(rc);
}
function readFully(_this__u8e3s4, destination, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? destination.length - offset | 0 : length;
  // Inline function 'io.ktor.utils.io.core.readExact' call
  var name = 'byte array';
  // Inline function 'kotlin.contracts.contract' call
  var value;
  // Inline function 'io.ktor.utils.io.core.read' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.readExact.<anonymous>' call
  var memory = _this__u8e3s4.p1d_1;
  var start = _this__u8e3s4.q1d_1;
  if ((_this__u8e3s4.r1d_1 - start | 0) < length) {
    throw EOFException.q1p('Not enough bytes to read a ' + name + ' of size ' + length + '.');
  }
  copyTo_2(memory, destination, start, length, offset);
  value = Unit_instance;
  var rc = length;
  _this__u8e3s4.a1p(rc);
}
function readFully_0(_this__u8e3s4, dst, length) {
  var tmp;
  if (length === VOID) {
    // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
    tmp = dst.t1d_1 - dst.r1d_1 | 0;
  } else {
    tmp = length;
  }
  length = tmp;
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(length >= 0)) {
    // Inline function 'kotlin.require.<anonymous>' call
    var message = 'Failed requirement.';
    throw IllegalArgumentException.g3(toString(message));
  }
  // Inline function 'kotlin.require' call
  // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(length <= (dst.t1d_1 - dst.r1d_1 | 0))) {
    // Inline function 'kotlin.require.<anonymous>' call
    var message_0 = 'Failed requirement.';
    throw IllegalArgumentException.g3(toString(message_0));
  }
  // Inline function 'io.ktor.utils.io.core.readExact' call
  var name = 'buffer content';
  // Inline function 'kotlin.contracts.contract' call
  var value;
  // Inline function 'io.ktor.utils.io.core.read' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.readExact.<anonymous>' call
  var memory = _this__u8e3s4.p1d_1;
  var start = _this__u8e3s4.q1d_1;
  if ((_this__u8e3s4.r1d_1 - start | 0) < length) {
    throw EOFException.q1p('Not enough bytes to read a ' + name + ' of size ' + length + '.');
  }
  memory.x1p(dst.p1d_1, start, length, dst.r1d_1);
  dst.b1p(length);
  value = Unit_instance;
  var rc = length;
  _this__u8e3s4.a1p(rc);
  return length;
}
function writeShort(_this__u8e3s4, value) {
  var name = 'short integer';
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.write' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.writeExact.<anonymous>' call
  var memory = _this__u8e3s4.p1d_1;
  var start = _this__u8e3s4.r1d_1;
  var writeRemaining = _this__u8e3s4.t1d_1 - start | 0;
  if (writeRemaining < 2) {
    throw InsufficientSpaceException.w1p(name, 2, writeRemaining);
  }
  // Inline function 'io.ktor.utils.io.core.writeShort.<anonymous>' call
  // Inline function 'io.ktor.utils.io.bits.storeShortAt' call
  memory.z1o_1.setInt16(start, value, false);
  var rc = 2;
  _this__u8e3s4.b1p(rc);
  return Unit_instance;
}
function readShort(_this__u8e3s4) {
  // Inline function 'io.ktor.utils.io.core.readExact' call
  var name = 'short integer';
  // Inline function 'kotlin.contracts.contract' call
  var value;
  // Inline function 'io.ktor.utils.io.core.read' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.readExact.<anonymous>' call
  var memory = _this__u8e3s4.p1d_1;
  var start = _this__u8e3s4.q1d_1;
  if ((_this__u8e3s4.r1d_1 - start | 0) < 2) {
    throw EOFException.q1p('Not enough bytes to read a ' + name + ' of size ' + 2 + '.');
  }
  // Inline function 'io.ktor.utils.io.core.readShort.<anonymous>' call
  // Inline function 'io.ktor.utils.io.bits.loadShortAt' call
  value = memory.z1o_1.getInt16(start, false);
  var rc = 2;
  _this__u8e3s4.a1p(rc);
  return value;
}
function remainingAll(_this__u8e3s4) {
  return remainingAll_0(_this__u8e3s4, new Long(0, 0));
}
function copyAll(_this__u8e3s4) {
  var copied = _this__u8e3s4.u1q();
  var tmp0_elvis_lhs = _this__u8e3s4.t12();
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return copied;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var next = tmp;
  return copyAll_0(next, copied, copied);
}
function findTail(_this__u8e3s4) {
  var $this = _this__u8e3s4;
  $l$1: do {
    $l$0: do {
      var tmp0_elvis_lhs = $this.t12();
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return $this;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var next = tmp;
      $this = next;
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
function releaseAll(_this__u8e3s4, pool) {
  var current = _this__u8e3s4;
  while (!(current == null)) {
    var next = current.v1q();
    current.t1o(pool);
    current = next;
  }
}
function remainingAll_0(_this__u8e3s4, n) {
  var $this = _this__u8e3s4;
  var n_0 = n;
  $l$1: do {
    $l$0: do {
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var this_0 = $this;
      var tmp$ret$0 = this_0.r1d_1 - this_0.q1d_1 | 0;
      var rem = toLong(tmp$ret$0).ee(n_0);
      var tmp0_elvis_lhs = $this.t12();
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return rem;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var next = tmp;
      $this = next;
      n_0 = rem;
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
function copyAll_0(_this__u8e3s4, head, prev) {
  var $this = _this__u8e3s4;
  var head_0 = head;
  var prev_0 = prev;
  $l$1: do {
    $l$0: do {
      var copied = $this.u1q();
      prev_0.w1q(copied);
      var tmp0_elvis_lhs = $this.t12();
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return head_0;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var next = tmp;
      $this = next;
      head_0 = head_0;
      prev_0 = copied;
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
var Companion_instance_0;
function Companion_getInstance_2() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function _set__head__b4pap2($this, newHead) {
  $this.o1g_1 = newHead;
  $this.p1g_1 = newHead.p1d_1;
  $this.q1g_1 = newHead.q1d_1;
  $this.r1g_1 = newHead.r1d_1;
}
function readASCII($this, out, min, max) {
  if (max === 0 ? min === 0 : false)
    return 0;
  else if ($this.u1j())
    if (min === 0)
      return 0;
    else {
      atLeastMinCharactersRequire($this, min);
    }
   else if (max < min) {
    minShouldBeLess($this, min, max);
  }
  var copied = 0;
  var utf8 = false;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.takeWhile' call
    var release = true;
    var tmp0_elvis_lhs = prepareReadFirstHead($this, 1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      break $l$block;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var current = tmp;
    try {
      $l$loop_0: do {
        // Inline function 'io.ktor.utils.io.core.Input.readASCII.<anonymous>' call
        var buffer = current;
        var tmp$ret$4;
        $l$block_0: {
          // Inline function 'io.ktor.utils.io.core.internal.decodeASCII' call
          // Inline function 'io.ktor.utils.io.core.read' call
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'io.ktor.utils.io.core.internal.decodeASCII.<anonymous>' call
          var memory = buffer.p1d_1;
          var start = buffer.q1d_1;
          var endExclusive = buffer.r1d_1;
          var inductionVariable = start;
          if (inductionVariable < endExclusive)
            do {
              var index = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              // Inline function 'io.ktor.utils.io.bits.get' call
              // Inline function 'io.ktor.utils.io.bits.Memory.loadAt' call
              var codepoint = memory.z1o_1.getInt8(index) & 255;
              var tmp_0;
              if ((codepoint & 128) === 128) {
                tmp_0 = true;
              } else {
                // Inline function 'io.ktor.utils.io.core.Input.readASCII.<anonymous>.<anonymous>' call
                var it = numberToChar(codepoint);
                var tmp_1;
                if (copied === max) {
                  tmp_1 = false;
                } else {
                  out.j7(it);
                  copied = copied + 1 | 0;
                  tmp_1 = true;
                }
                tmp_0 = !tmp_1;
              }
              if (tmp_0) {
                buffer.a1p(index - start | 0);
                tmp$ret$4 = false;
                break $l$block_0;
              }
            }
             while (inductionVariable < endExclusive);
          var rc = endExclusive - start | 0;
          buffer.a1p(rc);
          tmp$ret$4 = true;
        }
        var rc_0 = tmp$ret$4;
        var tmp_2;
        if (rc_0) {
          tmp_2 = true;
        } else if (copied === max) {
          tmp_2 = false;
        } else {
          utf8 = true;
          tmp_2 = false;
        }
        if (!tmp_2) {
          break $l$loop_0;
        }
        release = false;
        var tmp1_elvis_lhs = prepareReadNextHead($this, current);
        var tmp_3;
        if (tmp1_elvis_lhs == null) {
          break $l$loop_0;
        } else {
          tmp_3 = tmp1_elvis_lhs;
        }
        var next = tmp_3;
        current = next;
        release = true;
      }
       while (true);
    }finally {
      if (release) {
        completeReadHead($this, current);
      }
    }
  }
  if (utf8) {
    return copied + readUtf8($this, out, min - copied | 0, max - copied | 0) | 0;
  }
  if (copied < min) {
    prematureEndOfStreamChars($this, min, copied);
  }
  return copied;
}
function atLeastMinCharactersRequire($this, min) {
  throw EOFException.q1p('at least ' + min + ' characters required but no bytes available');
}
function minShouldBeLess($this, min, max) {
  throw IllegalArgumentException.g3('min should be less or equal to max but min = ' + min + ', max = ' + max);
}
function prematureEndOfStreamChars($this, min, copied) {
  throw MalformedUTF8InputException.b1s('Premature end of stream: expected at least ' + min + ' chars but had only ' + copied);
}
function readUtf8($this, out, min, max) {
  var copied = 0;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.takeWhileSize' call
    var release = true;
    var tmp0_elvis_lhs = prepareReadFirstHead($this, 1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      break $l$block;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var current = tmp;
    var size = 1;
    try {
      $l$loop_0: do {
        // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
        var this_0 = current;
        var before = this_0.r1d_1 - this_0.q1d_1 | 0;
        var after;
        if (before >= size) {
          try {
            // Inline function 'io.ktor.utils.io.core.Input.readUtf8.<anonymous>' call
            var buffer = current;
            var tmp$ret$5;
            $l$block_3: {
              // Inline function 'io.ktor.utils.io.core.internal.decodeUTF8' call
              var byteCount = 0;
              var value = 0;
              var lastByteCount = 0;
              // Inline function 'io.ktor.utils.io.core.read' call
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'io.ktor.utils.io.core.internal.decodeUTF8.<anonymous>' call
              var memory = buffer.p1d_1;
              var start = buffer.q1d_1;
              var endExclusive = buffer.r1d_1;
              var inductionVariable = start;
              if (inductionVariable < endExclusive)
                do {
                  var index = inductionVariable;
                  inductionVariable = inductionVariable + 1 | 0;
                  // Inline function 'io.ktor.utils.io.bits.get' call
                  // Inline function 'io.ktor.utils.io.bits.Memory.loadAt' call
                  var v = memory.z1o_1.getInt8(index) & 255;
                  if ((v & 128) === 0) {
                    if (!(byteCount === 0)) {
                      malformedByteCount(byteCount);
                    }
                    // Inline function 'io.ktor.utils.io.core.Input.readUtf8.<anonymous>.<anonymous>' call
                    var it = numberToChar(v);
                    var tmp_0;
                    if (copied === max) {
                      tmp_0 = false;
                    } else {
                      out.j7(it);
                      copied = copied + 1 | 0;
                      tmp_0 = true;
                    }
                    if (!tmp_0) {
                      buffer.a1p(index - start | 0);
                      tmp$ret$5 = -1;
                      break $l$block_3;
                    }
                  } else if (byteCount === 0) {
                    var mask = 128;
                    value = v;
                    var inductionVariable_0 = 1;
                    if (inductionVariable_0 <= 6)
                      $l$loop: do {
                        var i = inductionVariable_0;
                        inductionVariable_0 = inductionVariable_0 + 1 | 0;
                        if (!((value & mask) === 0)) {
                          value = value & ~mask;
                          mask = mask >> 1;
                          byteCount = byteCount + 1 | 0;
                        } else {
                          break $l$loop;
                        }
                      }
                       while (inductionVariable_0 <= 6);
                    lastByteCount = byteCount;
                    byteCount = byteCount - 1 | 0;
                    if (lastByteCount > (endExclusive - index | 0)) {
                      buffer.a1p(index - start | 0);
                      tmp$ret$5 = lastByteCount;
                      break $l$block_3;
                    }
                  } else {
                    value = value << 6 | v & 127;
                    byteCount = byteCount - 1 | 0;
                    if (byteCount === 0) {
                      if (isBmpCodePoint(value)) {
                        // Inline function 'io.ktor.utils.io.core.Input.readUtf8.<anonymous>.<anonymous>' call
                        var it_0 = numberToChar(value);
                        var tmp_1;
                        if (copied === max) {
                          tmp_1 = false;
                        } else {
                          out.j7(it_0);
                          copied = copied + 1 | 0;
                          tmp_1 = true;
                        }
                        if (!tmp_1) {
                          buffer.a1p(((index - start | 0) - lastByteCount | 0) + 1 | 0);
                          tmp$ret$5 = -1;
                          break $l$block_3;
                        }
                      } else if (!isValidCodePoint(value)) {
                        malformedCodePoint(value);
                      } else {
                        var tmp_2;
                        // Inline function 'io.ktor.utils.io.core.Input.readUtf8.<anonymous>.<anonymous>' call
                        var it_1 = numberToChar(highSurrogate(value));
                        var tmp_3;
                        if (copied === max) {
                          tmp_3 = false;
                        } else {
                          out.j7(it_1);
                          copied = copied + 1 | 0;
                          tmp_3 = true;
                        }
                        if (!tmp_3) {
                          tmp_2 = true;
                        } else {
                          // Inline function 'io.ktor.utils.io.core.Input.readUtf8.<anonymous>.<anonymous>' call
                          var it_2 = numberToChar(lowSurrogate(value));
                          var tmp_4;
                          if (copied === max) {
                            tmp_4 = false;
                          } else {
                            out.j7(it_2);
                            copied = copied + 1 | 0;
                            tmp_4 = true;
                          }
                          tmp_2 = !tmp_4;
                        }
                        if (tmp_2) {
                          buffer.a1p(((index - start | 0) - lastByteCount | 0) + 1 | 0);
                          tmp$ret$5 = -1;
                          break $l$block_3;
                        }
                      }
                      value = 0;
                    }
                  }
                }
                 while (inductionVariable < endExclusive);
              var rc = endExclusive - start | 0;
              buffer.a1p(rc);
              tmp$ret$5 = 0;
            }
            var size_0 = tmp$ret$5;
            size = size_0 === 0 ? 1 : size_0 > 0 ? size_0 : 0;
          }finally {
            // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
            var this_1 = current;
            after = this_1.r1d_1 - this_1.q1d_1 | 0;
          }
        } else {
          after = before;
        }
        release = false;
        var tmp_5;
        if (after === 0) {
          tmp_5 = prepareReadNextHead($this, current);
        } else {
          var tmp_6;
          if (after < size) {
            tmp_6 = true;
          } else {
            // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
            var this_2 = current;
            var tmp_7 = this_2.u1d_1 - this_2.t1d_1 | 0;
            tmp_6 = tmp_7 < 8;
          }
          if (tmp_6) {
            completeReadHead($this, current);
            tmp_5 = prepareReadFirstHead($this, size);
          } else {
            tmp_5 = current;
          }
        }
        var tmp1_elvis_lhs = tmp_5;
        var tmp_8;
        if (tmp1_elvis_lhs == null) {
          break $l$loop_0;
        } else {
          tmp_8 = tmp1_elvis_lhs;
        }
        var next = tmp_8;
        current = next;
        release = true;
      }
       while (size > 0);
    }finally {
      if (release) {
        completeReadHead($this, current);
      }
    }
  }
  if (copied < min) {
    prematureEndOfStreamChars($this, min, copied);
  }
  return copied;
}
function discardAsMuchAsPossible($this, n, skipped) {
  var $this_0 = $this;
  var n_0 = n;
  var skipped_0 = skipped;
  $l$1: do {
    $l$0: do {
      if (n_0.equals(new Long(0, 0)))
        return skipped_0;
      var tmp0_elvis_lhs = $this_0.x1r(1);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return skipped_0;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var current = tmp;
      // Inline function 'kotlin.comparisons.minOf' call
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var tmp$ret$0 = current.r1d_1 - current.q1d_1 | 0;
      var a = toLong(tmp$ret$0);
      var b = n_0;
      var size = (a.oc(b) <= 0 ? a : b).qe();
      current.a1p(size);
      var tmp1_this = $this_0;
      tmp1_this.q1g_1 = tmp1_this.q1g_1 + size | 0;
      afterRead($this_0, current);
      // Inline function 'kotlin.Long.minus' call
      // Inline function 'kotlin.Long.plus' call
      $this_0 = $this_0;
      n_0 = n_0.fe(toLong(size));
      skipped_0 = skipped_0.ee(toLong(size));
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
function discardAsMuchAsPossible_0($this, n, skipped) {
  var currentCount = n;
  var currentSkipped = skipped;
  while (true) {
    if (currentCount === 0) {
      return currentSkipped;
    }
    var tmp0_elvis_lhs = $this.x1r(1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return currentSkipped;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var current = tmp;
    // Inline function 'kotlin.comparisons.minOf' call
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    var a = current.r1d_1 - current.q1d_1 | 0;
    var b = currentCount;
    var size = Math.min(a, b);
    current.a1p(size);
    $this.q1g_1 = $this.q1g_1 + size | 0;
    afterRead($this, current);
    currentCount = currentCount - size | 0;
    currentSkipped = currentSkipped + size | 0;
  }
}
function fixGapAfterReadFallback($this, current) {
  if ($this.t1g_1 ? current.t12() == null : false) {
    $this.q1g_1 = current.q1d_1;
    $this.r1g_1 = current.r1d_1;
    $this.m1r(new Long(0, 0));
    return Unit_instance;
  }
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var size = current.r1d_1 - current.q1d_1 | 0;
  // Inline function 'kotlin.comparisons.minOf' call
  // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
  var b = 8 - (current.u1d_1 - current.t1d_1 | 0) | 0;
  var overrun = Math.min(size, b);
  if (size > overrun) {
    fixGapAfterReadFallbackUnreserved($this, current, size, overrun);
  } else {
    var new_0 = $this.n1g_1.w1n();
    new_0.y1n(8);
    new_0.w1q(current.v1q());
    writeBufferAppend(new_0, current, size);
    _set__head__b4pap2($this, new_0);
  }
  current.t1o($this.n1g_1);
}
function fixGapAfterReadFallbackUnreserved($this, current, size, overrun) {
  var chunk1 = $this.n1g_1.w1n();
  var chunk2 = $this.n1g_1.w1n();
  chunk1.y1n(8);
  chunk2.y1n(8);
  chunk1.w1q(chunk2);
  chunk2.w1q(current.v1q());
  writeBufferAppend(chunk1, current, size - overrun | 0);
  writeBufferAppend(chunk2, current, overrun);
  _set__head__b4pap2($this, chunk1);
  $this.m1r(remainingAll(chunk2));
}
function ensureNext($this, current, empty) {
  var $this_0 = $this;
  var current_0 = current;
  var empty_0 = empty;
  $l$1: do {
    $l$0: do {
      if (current_0 === empty_0) {
        return doFill($this_0);
      }
      var next = current_0.v1q();
      current_0.t1o($this_0.n1g_1);
      var tmp;
      if (next == null) {
        _set__head__b4pap2($this_0, empty_0);
        $this_0.m1r(new Long(0, 0));
        $this_0 = $this_0;
        current_0 = empty_0;
        empty_0 = empty_0;
        continue $l$0;
      } else {
        // Inline function 'io.ktor.utils.io.core.canRead' call
        if (next.r1d_1 > next.q1d_1) {
          _set__head__b4pap2($this_0, next);
          var tmp0_this = $this_0;
          // Inline function 'kotlin.Long.minus' call
          var this_0 = tmp0_this.s1g_1;
          // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
          var other = next.r1d_1 - next.q1d_1 | 0;
          var tmp$ret$2 = this_0.fe(toLong(other));
          tmp0_this.m1r(tmp$ret$2);
          tmp = next;
        } else {
          $this_0 = $this_0;
          current_0 = next;
          empty_0 = empty_0;
          continue $l$0;
        }
      }
      return tmp;
    }
     while (false);
  }
   while (true);
}
function doFill($this) {
  if ($this.t1g_1)
    return null;
  var chunk = $this.j1r();
  if (chunk == null) {
    $this.t1g_1 = true;
    return null;
  }
  appendView($this, chunk);
  return chunk;
}
function appendView($this, chunk) {
  var tail = findTail($this.o1g_1);
  if (tail === Companion_getInstance_4().a1e_1) {
    _set__head__b4pap2($this, chunk);
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!$this.s1g_1.equals(new Long(0, 0))) {
      throw IllegalStateException.l1('It should be no tail remaining bytes if current tail is EmptyBuffer');
    }
    var tmp0_safe_receiver = chunk.t12();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : remainingAll(tmp0_safe_receiver);
    $this.m1r(tmp1_elvis_lhs == null ? new Long(0, 0) : tmp1_elvis_lhs);
  } else {
    tail.w1q(chunk);
    $this.m1r($this.s1g_1.ee(remainingAll(chunk)));
  }
}
function prepareReadLoop($this, minSize, head) {
  var $this_0 = $this;
  var minSize_0 = minSize;
  var head_0 = head;
  $l$1: do {
    $l$0: do {
      // Inline function 'io.ktor.utils.io.core.Input.headRemaining' call
      var this_0 = $this_0;
      var headSize = this_0.r1g_1 - this_0.q1g_1 | 0;
      if (headSize >= minSize_0)
        return head_0;
      var tmp0_elvis_lhs = head_0.t12();
      var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? doFill($this_0) : tmp0_elvis_lhs;
      var tmp;
      if (tmp1_elvis_lhs == null) {
        return null;
      } else {
        tmp = tmp1_elvis_lhs;
      }
      var next = tmp;
      if (headSize === 0) {
        if (!(head_0 === Companion_getInstance_4().a1e_1)) {
          $this_0.y1r(head_0);
        }
        $this_0 = $this_0;
        minSize_0 = minSize_0;
        head_0 = next;
        continue $l$0;
      } else {
        var desiredExtraBytes = minSize_0 - headSize | 0;
        var copied = writeBufferAppend(head_0, next, desiredExtraBytes);
        $this_0.r1g_1 = head_0.r1d_1;
        var tmp2_this = $this_0;
        // Inline function 'kotlin.Long.minus' call
        var tmp$ret$1 = tmp2_this.s1g_1.fe(toLong(copied));
        tmp2_this.m1r(tmp$ret$1);
        // Inline function 'io.ktor.utils.io.core.canRead' call
        if (!(next.r1d_1 > next.q1d_1)) {
          head_0.w1q(null);
          head_0.w1q(next.v1q());
          next.t1o($this_0.n1g_1);
        } else {
          next.f1p(copied);
        }
      }
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var this_1 = head_0;
      if ((this_1.r1d_1 - this_1.q1d_1 | 0) >= minSize_0)
        return head_0;
      var tmp_0 = minSize_0;
      if (tmp_0 > 8) {
        minSizeIsTooBig($this_0, minSize_0);
      }
      $this_0 = $this_0;
      minSize_0 = minSize_0;
      head_0 = head_0;
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
function minSizeIsTooBig($this, minSize) {
  throw IllegalStateException.l1('minSize of ' + minSize + ' is too big (should be less than ' + 8 + ')');
}
function afterRead($this, head) {
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  if ((head.r1d_1 - head.q1d_1 | 0) === 0) {
    $this.y1r(head);
  }
}
var Companion_instance_1;
function Companion_getInstance_3() {
  return Companion_instance_1;
}
function readFully_1(_this__u8e3s4, dst, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? dst.length - offset | 0 : length;
  // Inline function 'io.ktor.utils.io.core.requireNoRemaining' call
  // Inline function 'io.ktor.utils.io.core.readFullyBytesTemplate' call
  var remaining = length;
  var dstOffset = offset;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.takeWhile' call
    var release = true;
    var tmp0_elvis_lhs = prepareReadFirstHead(_this__u8e3s4, 1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      break $l$block;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var current = tmp;
    try {
      $l$loop_0: do {
        // Inline function 'io.ktor.utils.io.core.readFullyBytesTemplate.<anonymous>' call
        var buffer = current;
        // Inline function 'kotlin.comparisons.minOf' call
        var a = remaining;
        // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
        var b = buffer.r1d_1 - buffer.q1d_1 | 0;
        var count = Math.min(a, b);
        // Inline function 'io.ktor.utils.io.core.readFully.<anonymous>' call
        var dstOffset_0 = dstOffset;
        readFully(buffer, dst, dstOffset_0, count);
        remaining = remaining - count | 0;
        dstOffset = dstOffset + count | 0;
        if (!(remaining > 0)) {
          break $l$loop_0;
        }
        release = false;
        var tmp1_elvis_lhs = prepareReadNextHead(_this__u8e3s4, current);
        var tmp_0;
        if (tmp1_elvis_lhs == null) {
          break $l$loop_0;
        } else {
          tmp_0 = tmp1_elvis_lhs;
        }
        var next = tmp_0;
        current = next;
        release = true;
      }
       while (true);
    }finally {
      if (release) {
        completeReadHead(_this__u8e3s4, current);
      }
    }
  }
  var this_0 = remaining;
  if (this_0 > 0) {
    prematureEndOfStream(this_0);
  }
}
function readFully_2(_this__u8e3s4, dst, length) {
  var tmp;
  if (length === VOID) {
    // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
    tmp = dst.t1d_1 - dst.r1d_1 | 0;
  } else {
    tmp = length;
  }
  length = tmp;
  // Inline function 'io.ktor.utils.io.core.requireNoRemaining' call
  // Inline function 'io.ktor.utils.io.core.readFullyBytesTemplate' call
  var remaining = length;
  var dstOffset = 0;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.takeWhile' call
    var release = true;
    var tmp0_elvis_lhs = prepareReadFirstHead(_this__u8e3s4, 1);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      break $l$block;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var current = tmp_0;
    try {
      $l$loop_0: do {
        // Inline function 'io.ktor.utils.io.core.readFullyBytesTemplate.<anonymous>' call
        var buffer = current;
        // Inline function 'kotlin.comparisons.minOf' call
        var a = remaining;
        // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
        var b = buffer.r1d_1 - buffer.q1d_1 | 0;
        var count = Math.min(a, b);
        // Inline function 'io.ktor.utils.io.core.readFully.<anonymous>' call
        readFully_0(buffer, dst, count);
        remaining = remaining - count | 0;
        dstOffset = dstOffset + count | 0;
        if (!(remaining > 0)) {
          break $l$loop_0;
        }
        release = false;
        var tmp1_elvis_lhs = prepareReadNextHead(_this__u8e3s4, current);
        var tmp_1;
        if (tmp1_elvis_lhs == null) {
          break $l$loop_0;
        } else {
          tmp_1 = tmp1_elvis_lhs;
        }
        var next = tmp_1;
        current = next;
        release = true;
      }
       while (true);
    }finally {
      if (release) {
        completeReadHead(_this__u8e3s4, current);
      }
    }
  }
  var this_0 = remaining;
  if (this_0 > 0) {
    prematureEndOfStream(this_0);
  }
}
function readAvailable_0(_this__u8e3s4, destination, destinationOffset, length) {
  // Inline function 'io.ktor.utils.io.core.readFullyBytesTemplate' call
  var remaining = length;
  var dstOffset = destinationOffset;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.takeWhile' call
    var release = true;
    var tmp0_elvis_lhs = prepareReadFirstHead(_this__u8e3s4, 1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      break $l$block;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var current = tmp;
    try {
      $l$loop_0: do {
        // Inline function 'io.ktor.utils.io.core.readFullyBytesTemplate.<anonymous>' call
        var buffer = current;
        // Inline function 'kotlin.comparisons.minOf' call
        var a = remaining;
        // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
        var tmp$ret$1 = buffer.r1d_1 - buffer.q1d_1 | 0;
        var b = toLong(tmp$ret$1);
        var count = (a.oc(b) <= 0 ? a : b).qe();
        // Inline function 'io.ktor.utils.io.core.readAvailable.<anonymous>' call
        var src = buffer.p1d_1;
        var srcOffset = toLong(buffer.q1d_1);
        var dstOffset_0 = dstOffset;
        src.c1s(destination, srcOffset, toLong(count), dstOffset_0);
        buffer.a1p(count);
        // Inline function 'kotlin.Long.minus' call
        remaining = remaining.fe(toLong(count));
        // Inline function 'kotlin.Long.plus' call
        dstOffset = dstOffset.ee(toLong(count));
        if (!(remaining.oc(new Long(0, 0)) > 0)) {
          break $l$loop_0;
        }
        release = false;
        var tmp1_elvis_lhs = prepareReadNextHead(_this__u8e3s4, current);
        var tmp_0;
        if (tmp1_elvis_lhs == null) {
          break $l$loop_0;
        } else {
          tmp_0 = tmp1_elvis_lhs;
        }
        var next = tmp_0;
        current = next;
        release = true;
      }
       while (true);
    }finally {
      if (release) {
        completeReadHead(_this__u8e3s4, current);
      }
    }
  }
  var remaining_0 = remaining;
  var result = length.fe(remaining_0);
  return (result.equals(new Long(0, 0)) ? _this__u8e3s4.u1j() : false) ? new Long(-1, -1) : result;
}
function readShort_0(_this__u8e3s4) {
  var tmp$ret$3;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.readPrimitive' call
    // Inline function 'io.ktor.utils.io.core.Input.headRemaining' call
    if ((_this__u8e3s4.r1g_1 - _this__u8e3s4.q1g_1 | 0) > 2) {
      var index = _this__u8e3s4.q1g_1;
      _this__u8e3s4.q1g_1 = index + 2 | 0;
      // Inline function 'io.ktor.utils.io.core.readShort.<anonymous>' call
      // Inline function 'io.ktor.utils.io.bits.loadShortAt' call
      tmp$ret$3 = _this__u8e3s4.p1g_1.z1o_1.getInt16(index, false);
      break $l$block;
    }
    // Inline function 'io.ktor.utils.io.core.readShort.<anonymous>' call
    tmp$ret$3 = readShortFallback(_this__u8e3s4);
  }
  return tmp$ret$3;
}
function readShortFallback(_this__u8e3s4) {
  // Inline function 'io.ktor.utils.io.core.readPrimitiveFallback' call
  var tmp0_elvis_lhs = prepareReadFirstHead(_this__u8e3s4, 2);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    prematureEndOfStream(2);
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var head = tmp;
  // Inline function 'io.ktor.utils.io.core.readShortFallback.<anonymous>' call
  var value = readShort(head);
  completeReadHead(_this__u8e3s4, head);
  return value;
}
function flushChain($this) {
  var tmp0_elvis_lhs = $this.t1c();
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var oldTail = tmp;
  try {
    // Inline function 'io.ktor.utils.io.core.forEachChunk' call
    // Inline function 'kotlin.contracts.contract' call
    var current = oldTail;
    $l$loop: do {
      // Inline function 'io.ktor.utils.io.core.Output.flushChain.<anonymous>' call
      var chunk = current;
      var tmp_0 = chunk.q1d_1;
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var tmp$ret$0 = chunk.r1d_1 - chunk.q1d_1 | 0;
      $this.z1q(chunk.p1d_1, tmp_0, tmp$ret$0);
      var tmp0_elvis_lhs_0 = current.t12();
      var tmp_1;
      if (tmp0_elvis_lhs_0 == null) {
        break $l$loop;
      } else {
        tmp_1 = tmp0_elvis_lhs_0;
      }
      current = tmp_1;
    }
     while (true);
  }finally {
    releaseAll(oldTail, $this.l1c_1);
  }
}
function appendNewChunk($this) {
  var new_0 = $this.l1c_1.w1n();
  new_0.y1n(8);
  $this.d1r(new_0);
  return new_0;
}
function appendChainImpl($this, head, newTail, chainedSizeDelta) {
  var _tail = $this.n1c_1;
  if (_tail == null) {
    $this.m1c_1 = head;
    $this.s1c_1 = 0;
  } else {
    _tail.w1q(head);
    var tailPosition = $this.p1c_1;
    _tail.c1p(tailPosition);
    $this.s1c_1 = $this.s1c_1 + (tailPosition - $this.r1c_1 | 0) | 0;
  }
  $this.n1c_1 = newTail;
  $this.s1c_1 = $this.s1c_1 + chainedSizeDelta | 0;
  $this.o1c_1 = newTail.p1d_1;
  $this.p1c_1 = newTail.r1d_1;
  $this.r1c_1 = newTail.q1d_1;
  $this.q1c_1 = newTail.t1d_1;
}
function writeByteFallback($this, v) {
  appendNewChunk($this).r1p(v);
  $this.p1c_1 = $this.p1c_1 + 1 | 0;
}
function appendCharFallback($this, c) {
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.Output.write' call
    var buffer = $this.s1e(3);
    try {
      // Inline function 'io.ktor.utils.io.core.Output.appendCharFallback.<anonymous>' call
      // Inline function 'io.ktor.utils.io.core.internal.putUtf8Char' call
      var this_0 = buffer.p1d_1;
      var offset = buffer.r1d_1;
      // Inline function 'kotlin.code' call
      var v = Char__toInt_impl_vasixd(c);
      var tmp;
      if (0 <= v ? v <= 127 : false) {
        // Inline function 'io.ktor.utils.io.bits.Memory.storeAt' call
        var value = toByte(v);
        this_0.z1o_1.setInt8(offset, value);
        tmp = 1;
      } else if (128 <= v ? v <= 2047 : false) {
        // Inline function 'io.ktor.utils.io.bits.set' call
        var value_0 = toByte(192 | v >> 6 & 31);
        this_0.z1o_1.setInt8(offset, value_0);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index = offset + 1 | 0;
        var value_1 = toByte(128 | v & 63);
        this_0.z1o_1.setInt8(index, value_1);
        tmp = 2;
      } else if (2048 <= v ? v <= 65535 : false) {
        // Inline function 'io.ktor.utils.io.bits.set' call
        var value_2 = toByte(224 | v >> 12 & 15);
        this_0.z1o_1.setInt8(offset, value_2);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_0 = offset + 1 | 0;
        var value_3 = toByte(128 | v >> 6 & 63);
        this_0.z1o_1.setInt8(index_0, value_3);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_1 = offset + 2 | 0;
        var value_4 = toByte(128 | v & 63);
        this_0.z1o_1.setInt8(index_1, value_4);
        tmp = 3;
      } else if (65536 <= v ? v <= 1114111 : false) {
        // Inline function 'io.ktor.utils.io.bits.set' call
        var value_5 = toByte(240 | v >> 18 & 7);
        this_0.z1o_1.setInt8(offset, value_5);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_2 = offset + 1 | 0;
        var value_6 = toByte(128 | v >> 12 & 63);
        this_0.z1o_1.setInt8(index_2, value_6);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_3 = offset + 2 | 0;
        var value_7 = toByte(128 | v >> 6 & 63);
        this_0.z1o_1.setInt8(index_3, value_7);
        // Inline function 'io.ktor.utils.io.bits.set' call
        var index_4 = offset + 3 | 0;
        var value_8 = toByte(128 | v & 63);
        this_0.z1o_1.setInt8(index_4, value_8);
        tmp = 4;
      } else {
        malformedCodePoint(v);
      }
      var size = tmp;
      buffer.b1p(size);
      var result = size;
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(result >= 0)) {
        // Inline function 'io.ktor.utils.io.core.Output.write.<anonymous>' call
        var message = "The returned value shouldn't be negative";
        throw IllegalStateException.l1(toString(message));
      }
      break $l$block;
    }finally {
      $this.u1l();
    }
  }
}
function writePacketMerging($this, tail, foreignStolen, pool) {
  tail.c1p($this.p1c_1);
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var lastSize = tail.r1d_1 - tail.q1d_1 | 0;
  // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
  var nextSize = foreignStolen.r1d_1 - foreignStolen.q1d_1 | 0;
  var maxCopySize = get_PACKET_MAX_COPY_SIZE();
  var tmp;
  var tmp_0;
  if (nextSize < maxCopySize) {
    // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
    var tmp_1 = tail.u1d_1 - tail.t1d_1 | 0;
    // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
    tmp_0 = nextSize <= (tmp_1 + (tail.t1d_1 - tail.r1d_1 | 0) | 0);
  } else {
    tmp_0 = false;
  }
  if (tmp_0) {
    tmp = nextSize;
  } else {
    tmp = -1;
  }
  var appendSize = tmp;
  var tmp_2;
  if ((lastSize < maxCopySize ? lastSize <= foreignStolen.s1d_1 : false) ? isExclusivelyOwned(foreignStolen) : false) {
    tmp_2 = lastSize;
  } else {
    tmp_2 = -1;
  }
  var prependSize = tmp_2;
  if (appendSize === -1 ? prependSize === -1 : false) {
    $this.e1r(foreignStolen);
  } else if (prependSize === -1 ? true : appendSize <= prependSize) {
    // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
    var tmp_3 = tail.t1d_1 - tail.r1d_1 | 0;
    // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
    var tmp$ret$5 = tail.u1d_1 - tail.t1d_1 | 0;
    writeBufferAppend(tail, foreignStolen, tmp_3 + tmp$ret$5 | 0);
    $this.u1l();
    var tmp0_safe_receiver = foreignStolen.v1q();
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      $this.e1r(tmp0_safe_receiver);
    }
    foreignStolen.t1o(pool);
  } else if (appendSize === -1 ? true : prependSize < appendSize) {
    writePacketSlowPrepend($this, foreignStolen, tail);
  } else {
    throw IllegalStateException.l1('prep = ' + prependSize + ', app = ' + appendSize);
  }
}
function writePacketSlowPrepend($this, foreignStolen, tail) {
  writeBufferPrepend(foreignStolen, tail);
  var tmp0_elvis_lhs = $this.m1c_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var message = "head should't be null since it is already handled in the fast-path";
    throw IllegalStateException.l1(toString(message));
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var _head = tmp;
  if (_head === tail) {
    $this.m1c_1 = foreignStolen;
  } else {
    var pre = _head;
    $l$loop: while (true) {
      var next = ensureNotNull(pre.t12());
      if (next === tail)
        break $l$loop;
      pre = next;
    }
    pre.w1q(foreignStolen);
  }
  tail.t1o($this.l1c_1);
  $this.n1c_1 = findTail(foreignStolen);
}
function writeFully_2(_this__u8e3s4, src, length) {
  var tmp;
  if (length === VOID) {
    // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
    tmp = src.r1d_1 - src.q1d_1 | 0;
  } else {
    tmp = length;
  }
  length = tmp;
  // Inline function 'io.ktor.utils.io.core.writeFullyBytesTemplate' call
  var currentOffset = 0;
  var remaining = length;
  // Inline function 'io.ktor.utils.io.core.writeWhile' call
  var tail = prepareWriteHead(_this__u8e3s4, 1, null);
  try {
    $l$loop: while (true) {
      // Inline function 'io.ktor.utils.io.core.writeFullyBytesTemplate.<anonymous>' call
      var buffer = tail;
      // Inline function 'kotlin.comparisons.minOf' call
      var a = remaining;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var b = buffer.t1d_1 - buffer.r1d_1 | 0;
      var size = Math.min(a, b);
      // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
      writeFully_1(buffer, src, size);
      currentOffset = currentOffset + size | 0;
      remaining = remaining - size | 0;
      if (!(remaining > 0))
        break $l$loop;
      tail = prepareWriteHead(_this__u8e3s4, 1, tail);
    }
  }finally {
    _this__u8e3s4.u1l();
  }
}
function writeFully_3(_this__u8e3s4, src, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? src.length - offset | 0 : length;
  // Inline function 'io.ktor.utils.io.core.writeFullyBytesTemplate' call
  var currentOffset = offset;
  var remaining = length;
  // Inline function 'io.ktor.utils.io.core.writeWhile' call
  var tail = prepareWriteHead(_this__u8e3s4, 1, null);
  try {
    $l$loop: while (true) {
      // Inline function 'io.ktor.utils.io.core.writeFullyBytesTemplate.<anonymous>' call
      var buffer = tail;
      // Inline function 'kotlin.comparisons.minOf' call
      var a = remaining;
      // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
      var b = buffer.t1d_1 - buffer.r1d_1 | 0;
      var size = Math.min(a, b);
      // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
      var currentOffset_0 = currentOffset;
      writeFully_0(buffer, src, currentOffset_0, size);
      currentOffset = currentOffset + size | 0;
      remaining = remaining - size | 0;
      if (!(remaining > 0))
        break $l$loop;
      tail = prepareWriteHead(_this__u8e3s4, 1, tail);
    }
  }finally {
    _this__u8e3s4.u1l();
  }
}
function writeShort_0(_this__u8e3s4, value) {
  var tmp$ret$0;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.writePrimitiveTemplate' call
    var index = _this__u8e3s4.p1c_1;
    if ((_this__u8e3s4.q1c_1 - index | 0) > 2) {
      _this__u8e3s4.p1c_1 = index + 2 | 0;
      // Inline function 'io.ktor.utils.io.core.writeShort.<anonymous>' call
      // Inline function 'io.ktor.utils.io.bits.storeShortAt' call
      _this__u8e3s4.o1c_1.z1o_1.setInt16(index, value, false);
      tmp$ret$0 = true;
      break $l$block;
    }
    tmp$ret$0 = false;
  }
  if (!tmp$ret$0) {
    writeShortFallback(_this__u8e3s4, value);
  }
}
function writeShortFallback(_this__u8e3s4, value) {
  // Inline function 'io.ktor.utils.io.core.writePrimitiveFallbackTemplate' call
  var tail = _this__u8e3s4.s1e(2);
  // Inline function 'io.ktor.utils.io.core.writeShortFallback.<anonymous>' call
  writeShort(tail, value);
  _this__u8e3s4.u1l();
  if (!true) {
    // Inline function 'io.ktor.utils.io.bits.highByte' call
    var tmp$ret$1 = toByte(value >>> 8 | 0);
    _this__u8e3s4.r1p(tmp$ret$1);
    // Inline function 'io.ktor.utils.io.bits.lowByte' call
    var tmp$ret$2 = toByte(value & 255);
    _this__u8e3s4.r1p(tmp$ret$2);
  }
}
function readText(_this__u8e3s4, charset, max) {
  charset = charset === VOID ? Charsets_getInstance().e1s_1 : charset;
  max = max === VOID ? IntCompanionObject_instance.MAX_VALUE : max;
  return decode(charset.h1s(), _this__u8e3s4, max);
}
function readBytes(_this__u8e3s4, n) {
  var tmp;
  if (n === VOID) {
    // Inline function 'io.ktor.utils.io.core.coerceAtMostMaxIntOrFail' call
    var this_0 = _this__u8e3s4.u1g();
    var message = 'Unable to convert to a ByteArray: packet is too big';
    if (this_0.oc(toLong(IntCompanionObject_instance.MAX_VALUE)) > 0)
      throw IllegalArgumentException.g3(message);
    tmp = this_0.qe();
  } else {
    tmp = n;
  }
  n = tmp;
  var tmp_0;
  if (!(n === 0)) {
    // Inline function 'kotlin.also' call
    var this_1 = new Int8Array(n);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'io.ktor.utils.io.core.readBytes.<anonymous>' call
    readFully_1(_this__u8e3s4, this_1, 0, n);
    tmp_0 = this_1;
  } else {
    tmp_0 = get_EmptyByteArray();
  }
  return tmp_0;
}
function prematureEndOfStream(size) {
  throw EOFException.q1p('Premature end of stream: expected ' + size + ' bytes');
}
function readTextExactBytes(_this__u8e3s4, bytesCount, charset) {
  charset = charset === VOID ? Charsets_getInstance().e1s_1 : charset;
  return decodeExactBytes(charset.h1s(), _this__u8e3s4, bytesCount);
}
function writeText(_this__u8e3s4, text, fromIndex, toIndex, charset) {
  fromIndex = fromIndex === VOID ? 0 : fromIndex;
  toIndex = toIndex === VOID ? charSequenceLength(text) : toIndex;
  charset = charset === VOID ? Charsets_getInstance().e1s_1 : charset;
  if (charset === Charsets_getInstance().e1s_1) {
    return writeTextUtf8(_this__u8e3s4, text, fromIndex, toIndex);
  }
  encodeToImpl(charset.i1s(), _this__u8e3s4, text, fromIndex, toIndex);
}
function writeTextUtf8(_this__u8e3s4, text, fromIndex, toIndex) {
  var index = fromIndex;
  // Inline function 'io.ktor.utils.io.core.writeWhileSize' call
  var tail = prepareWriteHead(_this__u8e3s4, 1, null);
  try {
    var size;
    $l$loop: while (true) {
      // Inline function 'io.ktor.utils.io.core.writeTextUtf8.<anonymous>' call
      var buffer = tail;
      var memory = buffer.p1d_1;
      var dstOffset = buffer.r1d_1;
      var dstLimit = buffer.t1d_1;
      var tmp0_container = encodeUTF8(memory, text, index, toIndex, dstOffset, dstLimit);
      var characters = EncodeResult__component1_impl_36tlhi(tmp0_container);
      var bytes = EncodeResult__component2_impl_3nv7vp(tmp0_container);
      var tmp = index;
      // Inline function 'kotlin.UShort.toInt' call
      index = tmp + (_UShort___get_data__impl__g0245(characters) & 65535) | 0;
      // Inline function 'kotlin.UShort.toInt' call
      var tmp$ret$1 = _UShort___get_data__impl__g0245(bytes) & 65535;
      buffer.b1p(tmp$ret$1);
      var tmp_0;
      var tmp_1;
      // Inline function 'kotlin.UShort.toInt' call
      if ((_UShort___get_data__impl__g0245(characters) & 65535) === 0) {
        tmp_1 = index < toIndex;
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = 8;
      } else {
        if (index < toIndex) {
          tmp_0 = 1;
        } else {
          tmp_0 = 0;
        }
      }
      size = tmp_0;
      if (size <= 0)
        break $l$loop;
      tail = prepareWriteHead(_this__u8e3s4, size, tail);
    }
  }finally {
    _this__u8e3s4.u1l();
  }
}
function appendNext($this, chunk) {
  if (!$this.q1o_1.atomicfu$compareAndSet(null, chunk)) {
    throw IllegalStateException.l1('This chunk has already a next chunk.');
  }
}
var Companion_instance_2;
function Companion_getInstance_4() {
  if (Companion_instance_2 === VOID)
    new Companion_2();
  return Companion_instance_2;
}
function isExclusivelyOwned(_this__u8e3s4) {
  return _this__u8e3s4.o1q() === 1;
}
function _EncodeResult___init__impl__vkc0cy(value) {
  return value;
}
function _EncodeResult___get_value__impl__h0r466($this) {
  return $this;
}
function _EncodeResult___init__impl__vkc0cy_0(characters, bytes) {
  // Inline function 'kotlin.UShort.toInt' call
  var tmp = (_UShort___get_data__impl__g0245(characters) & 65535) << 16;
  // Inline function 'kotlin.UShort.toInt' call
  var tmp$ret$1 = _UShort___get_data__impl__g0245(bytes) & 65535;
  return _EncodeResult___init__impl__vkc0cy(tmp | tmp$ret$1);
}
function _EncodeResult___get_characters__impl__rrxzcv($this) {
  // Inline function 'kotlin.toUShort' call
  // Inline function 'io.ktor.utils.io.bits.highShort' call
  var this_0 = _EncodeResult___get_value__impl__h0r466($this);
  var this_1 = toShort(this_0 >>> 16 | 0);
  return _UShort___init__impl__jigrne(this_1);
}
function _EncodeResult___get_bytes__impl__bt0kq0($this) {
  // Inline function 'kotlin.toUShort' call
  // Inline function 'io.ktor.utils.io.bits.lowShort' call
  var this_0 = _EncodeResult___get_value__impl__h0r466($this);
  var this_1 = toShort(this_0 & 65535);
  return _UShort___init__impl__jigrne(this_1);
}
function EncodeResult__component1_impl_36tlhi($this) {
  return _EncodeResult___get_characters__impl__rrxzcv($this);
}
function EncodeResult__component2_impl_3nv7vp($this) {
  return _EncodeResult___get_bytes__impl__bt0kq0($this);
}
function failLongToIntConversion(value, name) {
  throw IllegalArgumentException.g3('Long value ' + value.toString() + ' of ' + name + " doesn't fit into 32-bit integer");
}
function decodeUTF8LineLoopSuspend(out, limit, nextChunk, afterRead, $completion) {
  var tmp = new $decodeUTF8LineLoopSuspendCOROUTINE$55(out, limit, nextChunk, afterRead, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function malformedByteCount(byteCount) {
  throw MalformedUTF8InputException.b1s('Expected ' + byteCount + ' more character bytes');
}
function isBmpCodePoint(cp) {
  return (cp >>> 16 | 0) === 0;
}
function isValidCodePoint(codePoint) {
  return codePoint <= 1114111;
}
function malformedCodePoint(value) {
  throw IllegalArgumentException.g3('Malformed code-point ' + value + ' found');
}
function highSurrogate(cp) {
  return (cp >>> 10 | 0) + 55232 | 0;
}
function lowSurrogate(cp) {
  return (cp & 1023) + 56320 | 0;
}
function prematureEndOfStreamUtf(size) {
  throw EOFException.q1p('Premature end of stream: expected ' + size + ' bytes to decode UTF-8 char');
}
function encodeUTF8(_this__u8e3s4, text, from, to, dstOffset, dstLimit) {
  // Inline function 'kotlin.comparisons.minOf' call
  // Inline function 'kotlin.UShort.toInt' call
  Companion_getInstance_0();
  var this_0 = _UShort___init__impl__jigrne(-1);
  var b = from + (_UShort___get_data__impl__g0245(this_0) & 65535) | 0;
  var lastCharIndex = Math.min(to, b);
  // Inline function 'kotlin.UShort.toInt' call
  Companion_getInstance_0();
  var this_1 = _UShort___init__impl__jigrne(-1);
  var tmp$ret$2 = _UShort___get_data__impl__g0245(this_1) & 65535;
  var resultLimit = coerceAtMost(dstLimit, tmp$ret$2);
  var resultPosition = dstOffset;
  var index = from;
  $l$loop: do {
    if (resultPosition >= resultLimit ? true : index >= lastCharIndex) {
      // Inline function 'kotlin.toUShort' call
      var this_2 = index - from | 0;
      var tmp = _UShort___init__impl__jigrne(toShort(this_2));
      // Inline function 'kotlin.toUShort' call
      var this_3 = resultPosition - dstOffset | 0;
      var tmp$ret$4 = _UShort___init__impl__jigrne(toShort(this_3));
      return _EncodeResult___init__impl__vkc0cy_0(tmp, tmp$ret$4);
    }
    // Inline function 'kotlin.code' call
    var tmp0 = index;
    index = tmp0 + 1 | 0;
    var this_4 = charSequenceGet(text, tmp0);
    var character = Char__toInt_impl_vasixd(this_4) & 65535;
    if ((character & 65408) === 0) {
      // Inline function 'io.ktor.utils.io.bits.Memory.storeAt' call
      var tmp1 = resultPosition;
      resultPosition = tmp1 + 1 | 0;
      var value = toByte(character);
      _this__u8e3s4.z1o_1.setInt8(tmp1, value);
    } else {
      break $l$loop;
    }
  }
   while (true);
  index = index - 1 | 0;
  return encodeUTF8Stage1(_this__u8e3s4, text, index, lastCharIndex, from, resultPosition, resultLimit, dstOffset);
}
function encodeUTF8Stage1(_this__u8e3s4, text, index1, lastCharIndex, from, resultPosition1, resultLimit, dstOffset) {
  var index = index1;
  var resultPosition = resultPosition1;
  var stage1Limit = resultLimit - 3 | 0;
  $l$loop: do {
    var freeSpace = stage1Limit - resultPosition | 0;
    if (freeSpace <= 0 ? true : index >= lastCharIndex) {
      break $l$loop;
    }
    var tmp0 = index;
    index = tmp0 + 1 | 0;
    var character = charSequenceGet(text, tmp0);
    var tmp;
    if (isHighSurrogate(character)) {
      var tmp_0;
      if (index === lastCharIndex ? true : !isLowSurrogate(charSequenceGet(text, index))) {
        tmp_0 = 63;
      } else {
        var tmp1 = index;
        index = tmp1 + 1 | 0;
        tmp_0 = codePoint(character, charSequenceGet(text, tmp1));
      }
      tmp = tmp_0;
    } else {
      // Inline function 'kotlin.code' call
      tmp = Char__toInt_impl_vasixd(character);
    }
    var codepoint = tmp;
    // Inline function 'io.ktor.utils.io.core.internal.putUtf8Char' call
    var offset = resultPosition;
    var tmp_1;
    if (0 <= codepoint ? codepoint <= 127 : false) {
      // Inline function 'io.ktor.utils.io.bits.Memory.storeAt' call
      var value = toByte(codepoint);
      _this__u8e3s4.z1o_1.setInt8(offset, value);
      tmp_1 = 1;
    } else if (128 <= codepoint ? codepoint <= 2047 : false) {
      // Inline function 'io.ktor.utils.io.bits.set' call
      var value_0 = toByte(192 | codepoint >> 6 & 31);
      _this__u8e3s4.z1o_1.setInt8(offset, value_0);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_0 = offset + 1 | 0;
      var value_1 = toByte(128 | codepoint & 63);
      _this__u8e3s4.z1o_1.setInt8(index_0, value_1);
      tmp_1 = 2;
    } else if (2048 <= codepoint ? codepoint <= 65535 : false) {
      // Inline function 'io.ktor.utils.io.bits.set' call
      var value_2 = toByte(224 | codepoint >> 12 & 15);
      _this__u8e3s4.z1o_1.setInt8(offset, value_2);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_1 = offset + 1 | 0;
      var value_3 = toByte(128 | codepoint >> 6 & 63);
      _this__u8e3s4.z1o_1.setInt8(index_1, value_3);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_2 = offset + 2 | 0;
      var value_4 = toByte(128 | codepoint & 63);
      _this__u8e3s4.z1o_1.setInt8(index_2, value_4);
      tmp_1 = 3;
    } else if (65536 <= codepoint ? codepoint <= 1114111 : false) {
      // Inline function 'io.ktor.utils.io.bits.set' call
      var value_5 = toByte(240 | codepoint >> 18 & 7);
      _this__u8e3s4.z1o_1.setInt8(offset, value_5);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_3 = offset + 1 | 0;
      var value_6 = toByte(128 | codepoint >> 12 & 63);
      _this__u8e3s4.z1o_1.setInt8(index_3, value_6);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_4 = offset + 2 | 0;
      var value_7 = toByte(128 | codepoint >> 6 & 63);
      _this__u8e3s4.z1o_1.setInt8(index_4, value_7);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_5 = offset + 3 | 0;
      var value_8 = toByte(128 | codepoint & 63);
      _this__u8e3s4.z1o_1.setInt8(index_5, value_8);
      tmp_1 = 4;
    } else {
      malformedCodePoint(codepoint);
    }
    var size = tmp_1;
    resultPosition = resultPosition + size | 0;
  }
   while (true);
  if (resultPosition === stage1Limit) {
    return encodeUTF8Stage2(_this__u8e3s4, text, index, lastCharIndex, from, resultPosition, resultLimit, dstOffset);
  }
  // Inline function 'kotlin.toUShort' call
  var this_0 = index - from | 0;
  var tmp_2 = _UShort___init__impl__jigrne(toShort(this_0));
  // Inline function 'kotlin.toUShort' call
  var this_1 = resultPosition - dstOffset | 0;
  var tmp$ret$12 = _UShort___init__impl__jigrne(toShort(this_1));
  return _EncodeResult___init__impl__vkc0cy_0(tmp_2, tmp$ret$12);
}
function codePoint(high, low) {
  // Inline function 'kotlin.code' call
  var highValue = Char__toInt_impl_vasixd(high) - 55232 | 0;
  // Inline function 'kotlin.code' call
  var lowValue = Char__toInt_impl_vasixd(low) - 56320 | 0;
  return highValue << 10 | lowValue;
}
function encodeUTF8Stage2(_this__u8e3s4, text, index1, lastCharIndex, from, resultPosition1, resultLimit, dstOffset) {
  var index = index1;
  var resultPosition = resultPosition1;
  $l$loop_0: do {
    var freeSpace = resultLimit - resultPosition | 0;
    if (freeSpace <= 0 ? true : index >= lastCharIndex) {
      break $l$loop_0;
    }
    var tmp0 = index;
    index = tmp0 + 1 | 0;
    var character = charSequenceGet(text, tmp0);
    var tmp;
    if (!isHighSurrogate(character)) {
      // Inline function 'kotlin.code' call
      tmp = Char__toInt_impl_vasixd(character);
    } else {
      var tmp_0;
      if (index === lastCharIndex ? true : !isLowSurrogate(charSequenceGet(text, index))) {
        tmp_0 = 63;
      } else {
        var tmp1 = index;
        index = tmp1 + 1 | 0;
        tmp_0 = codePoint(character, charSequenceGet(text, tmp1));
      }
      tmp = tmp_0;
    }
    var codepoint = tmp;
    // Inline function 'io.ktor.utils.io.core.internal.charactersSize' call
    var tmp_1;
    if (1 <= codepoint ? codepoint <= 127 : false) {
      tmp_1 = 1;
    } else if (128 <= codepoint ? codepoint <= 2047 : false) {
      tmp_1 = 2;
    } else if (2048 <= codepoint ? codepoint <= 65535 : false) {
      tmp_1 = 3;
    } else if (65536 <= codepoint ? codepoint <= 1114111 : false) {
      tmp_1 = 4;
    } else {
      malformedCodePoint(codepoint);
    }
    if (tmp_1 > freeSpace) {
      index = index - 1 | 0;
      break $l$loop_0;
    }
    // Inline function 'io.ktor.utils.io.core.internal.putUtf8Char' call
    var offset = resultPosition;
    var tmp_2;
    if (0 <= codepoint ? codepoint <= 127 : false) {
      // Inline function 'io.ktor.utils.io.bits.Memory.storeAt' call
      var value = toByte(codepoint);
      _this__u8e3s4.z1o_1.setInt8(offset, value);
      tmp_2 = 1;
    } else if (128 <= codepoint ? codepoint <= 2047 : false) {
      // Inline function 'io.ktor.utils.io.bits.set' call
      var value_0 = toByte(192 | codepoint >> 6 & 31);
      _this__u8e3s4.z1o_1.setInt8(offset, value_0);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_0 = offset + 1 | 0;
      var value_1 = toByte(128 | codepoint & 63);
      _this__u8e3s4.z1o_1.setInt8(index_0, value_1);
      tmp_2 = 2;
    } else if (2048 <= codepoint ? codepoint <= 65535 : false) {
      // Inline function 'io.ktor.utils.io.bits.set' call
      var value_2 = toByte(224 | codepoint >> 12 & 15);
      _this__u8e3s4.z1o_1.setInt8(offset, value_2);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_1 = offset + 1 | 0;
      var value_3 = toByte(128 | codepoint >> 6 & 63);
      _this__u8e3s4.z1o_1.setInt8(index_1, value_3);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_2 = offset + 2 | 0;
      var value_4 = toByte(128 | codepoint & 63);
      _this__u8e3s4.z1o_1.setInt8(index_2, value_4);
      tmp_2 = 3;
    } else if (65536 <= codepoint ? codepoint <= 1114111 : false) {
      // Inline function 'io.ktor.utils.io.bits.set' call
      var value_5 = toByte(240 | codepoint >> 18 & 7);
      _this__u8e3s4.z1o_1.setInt8(offset, value_5);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_3 = offset + 1 | 0;
      var value_6 = toByte(128 | codepoint >> 12 & 63);
      _this__u8e3s4.z1o_1.setInt8(index_3, value_6);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_4 = offset + 2 | 0;
      var value_7 = toByte(128 | codepoint >> 6 & 63);
      _this__u8e3s4.z1o_1.setInt8(index_4, value_7);
      // Inline function 'io.ktor.utils.io.bits.set' call
      var index_5 = offset + 3 | 0;
      var value_8 = toByte(128 | codepoint & 63);
      _this__u8e3s4.z1o_1.setInt8(index_5, value_8);
      tmp_2 = 4;
    } else {
      malformedCodePoint(codepoint);
    }
    var size = tmp_2;
    resultPosition = resultPosition + size | 0;
  }
   while (true);
  // Inline function 'kotlin.toUShort' call
  var this_0 = index - from | 0;
  var tmp_3 = _UShort___init__impl__jigrne(toShort(this_0));
  // Inline function 'kotlin.toUShort' call
  var this_1 = resultPosition - dstOffset | 0;
  var tmp$ret$13 = _UShort___init__impl__jigrne(toShort(this_1));
  return _EncodeResult___init__impl__vkc0cy_0(tmp_3, tmp$ret$13);
}
function get_EmptyByteArray() {
  _init_properties_Unsafe_kt__orlvcq();
  return EmptyByteArray;
}
var EmptyByteArray;
function completeReadHead(_this__u8e3s4, current) {
  _init_properties_Unsafe_kt__orlvcq();
  if (current === _this__u8e3s4)
    return Unit_instance;
  else {
    // Inline function 'io.ktor.utils.io.core.canRead' call
    if (!(current.r1d_1 > current.q1d_1)) {
      _this__u8e3s4.v1r(current);
    } else {
      // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
      var tmp = current.u1d_1 - current.t1d_1 | 0;
      if (tmp < 8) {
        _this__u8e3s4.w1r(current);
      } else {
        _this__u8e3s4.q1g_1 = current.q1d_1;
      }
    }
  }
}
function prepareReadFirstHead(_this__u8e3s4, minSize) {
  _init_properties_Unsafe_kt__orlvcq();
  return _this__u8e3s4.t1r(minSize);
}
function prepareReadNextHead(_this__u8e3s4, current) {
  _init_properties_Unsafe_kt__orlvcq();
  if (current === _this__u8e3s4) {
    return _this__u8e3s4.x1i() ? _this__u8e3s4 : null;
  }
  return _this__u8e3s4.u1r(current);
}
function unsafeAppend(_this__u8e3s4, builder) {
  _init_properties_Unsafe_kt__orlvcq();
  var builderSize = builder.k();
  var tmp0_elvis_lhs = builder.t1c();
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return 0;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var builderHead = tmp;
  if ((builderSize <= get_PACKET_MAX_COPY_SIZE() ? builderHead.t12() == null : false) ? _this__u8e3s4.p1r(builderHead) : false) {
    builder.f1r();
    return builderSize;
  }
  _this__u8e3s4.o1r(builderHead);
  return builderSize;
}
function prepareWriteHead(_this__u8e3s4, capacity, current) {
  _init_properties_Unsafe_kt__orlvcq();
  if (!(current == null)) {
    _this__u8e3s4.u1l();
  }
  return _this__u8e3s4.s1e(capacity);
}
var properties_initialized_Unsafe_kt_o5mw48;
function _init_properties_Unsafe_kt__orlvcq() {
  if (!properties_initialized_Unsafe_kt_o5mw48) {
    properties_initialized_Unsafe_kt_o5mw48 = true;
    EmptyByteArray = new Int8Array(0);
  }
}
function trySuspend($this, sleepCondition, $completion) {
  var tmp = new $trySuspendCOROUTINE$57($this, sleepCondition, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function copyToSequentialImpl(_this__u8e3s4, dst, limit, $completion) {
  var tmp = new $copyToSequentialImplCOROUTINE$58(_this__u8e3s4, dst, limit, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function copyToTail(_this__u8e3s4, dst, limit, $completion) {
  var tmp = new $copyToTailCOROUTINE$59(_this__u8e3s4, dst, limit, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function get_ByteArrayPool() {
  _init_properties_ByteArrayPool_kt__kfi3uj();
  return ByteArrayPool;
}
var ByteArrayPool;
var properties_initialized_ByteArrayPool_kt_td6pfh;
function _init_properties_ByteArrayPool_kt__kfi3uj() {
  if (!properties_initialized_ByteArrayPool_kt_td6pfh) {
    properties_initialized_ByteArrayPool_kt_td6pfh = true;
    ByteArrayPool = new ByteArrayPool$1();
  }
}
function ByteChannel(autoFlush) {
  autoFlush = autoFlush === VOID ? false : autoFlush;
  return new ByteChannelJS(Companion_getInstance_4().a1e_1, autoFlush);
}
function copyTo_0(_this__u8e3s4, dst, limit, $completion) {
  var tmp = _this__u8e3s4 instanceof ByteChannelSequentialBase ? _this__u8e3s4 : THROW_CCE();
  return copyToSequentialImpl(tmp, dst instanceof ByteChannelSequentialBase ? dst : THROW_CCE(), limit, $completion);
}
function ByteReadChannel_1(content, offset, length) {
  // Inline function 'kotlin.collections.isEmpty' call
  if (content.length === 0)
    return Companion_getInstance_5().w1d();
  var head = Companion_getInstance_4().y1d_1.w1n();
  var tail = head;
  var start = offset;
  var end = start + length | 0;
  $l$loop: while (true) {
    tail.y1n(8);
    // Inline function 'kotlin.comparisons.minOf' call
    var a = end - start | 0;
    // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
    var this_0 = tail;
    var b = this_0.t1d_1 - this_0.r1d_1 | 0;
    var size = Math.min(a, b);
    writeFully_0(tail instanceof Buffer ? tail : THROW_CCE(), content, start, size);
    start = start + size | 0;
    if (start === end)
      break $l$loop;
    var current = tail;
    tail = Companion_getInstance_4().y1d_1.w1n();
    current.w1q(tail);
  }
  // Inline function 'kotlin.apply' call
  var this_1 = new ByteChannelJS(head, false);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.ByteReadChannel.<anonymous>' call
  close_0(this_1);
  return this_1;
}
function ByteChannelJS$attachJob$lambda(this$0) {
  return function (cause) {
    this$0.w1v_1 = null;
    var tmp;
    if (!(cause == null)) {
      this$0.nv(unwrapCancellationException(cause));
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function ByteReadChannel$Companion$Empty$delegate$lambda() {
  // Inline function 'kotlin.apply' call
  var this_0 = new ByteChannelJS(Companion_getInstance_4().a1e_1, false);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.Companion.Empty$delegate.<anonymous>.<anonymous>' call
  this_0.n15(null);
  return this_0;
}
var Companion_instance_3;
function Companion_getInstance_5() {
  if (Companion_instance_3 === VOID)
    new Companion_3();
  return Companion_instance_3;
}
function Empty$factory() {
  return getPropertyCallableRef('Empty', 1, KProperty1, function (receiver) {
    return receiver.w1d();
  }, null);
}
var DefaultAllocator_instance;
function DefaultAllocator_getInstance() {
  return DefaultAllocator_instance;
}
function of(_this__u8e3s4, array, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? array.length - offset | 0 : length;
  // Inline function 'kotlin.js.asDynamic' call
  var typedArray = array;
  return of_0(Companion_getInstance_6(), typedArray, offset, length);
}
function of_0(_this__u8e3s4, view, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? view.byteLength : length;
  return of_1(Companion_getInstance_6(), view.buffer, view.byteOffset + offset | 0, length);
}
function of_1(_this__u8e3s4, buffer, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? buffer.byteLength - offset | 0 : length;
  return new Memory(new DataView(buffer, offset, length));
}
var Companion_instance_4;
function Companion_getInstance_6() {
  if (Companion_instance_4 === VOID)
    new Companion_4();
  return Companion_instance_4;
}
function copyTo_1(_this__u8e3s4, destination, offset, length, destinationOffset) {
  copyTo_3(_this__u8e3s4.buffer, destination, offset + _this__u8e3s4.byteOffset | 0, length, destinationOffset);
}
function copyTo_2(_this__u8e3s4, destination, offset, length, destinationOffset) {
  // Inline function 'kotlin.js.asDynamic' call
  var to = destination;
  var from = new Int8Array(_this__u8e3s4.z1o_1.buffer, _this__u8e3s4.z1o_1.byteOffset + offset | 0, length);
  to.set(from, destinationOffset);
}
function copyTo_3(_this__u8e3s4, destination, offset, length, destinationOffset) {
  var from = new Int8Array(_this__u8e3s4, offset, length);
  var to = new Int8Array(destination.z1o_1.buffer, destination.z1o_1.byteOffset + destinationOffset | 0, length);
  to.set(from, 0);
}
var Companion_instance_5;
function Companion_getInstance_7() {
  return Companion_instance_5;
}
function get_name(_this__u8e3s4) {
  return _this__u8e3s4.g1s_1;
}
var Charsets_instance;
function Charsets_getInstance() {
  if (Charsets_instance === VOID)
    new Charsets();
  return Charsets_instance;
}
function encodeToByteArray(_this__u8e3s4, input, fromIndex, toIndex) {
  fromIndex = fromIndex === VOID ? 0 : fromIndex;
  toIndex = toIndex === VOID ? charSequenceLength(input) : toIndex;
  return encodeToByteArrayImpl1(_this__u8e3s4, input, fromIndex, toIndex);
}
function encodeToByteArrayImpl1(_this__u8e3s4, input, fromIndex, toIndex) {
  fromIndex = fromIndex === VOID ? 0 : fromIndex;
  toIndex = toIndex === VOID ? charSequenceLength(input) : toIndex;
  var start = fromIndex;
  if (start >= toIndex)
    return get_EmptyByteArray();
  var single = Companion_getInstance_4().y1d_1.w1n();
  try {
    var rc = encodeImpl(_this__u8e3s4, input, start, toIndex, single);
    start = start + rc | 0;
    if (start === toIndex) {
      // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
      var tmp$ret$0 = single.r1d_1 - single.q1d_1 | 0;
      var result = new Int8Array(tmp$ret$0);
      // Inline function 'io.ktor.utils.io.core.readFully' call
      var length = result.length - 0 | 0;
      readFully(single instanceof Buffer ? single : THROW_CCE(), result, 0, length);
      return result;
    }
    var tmp$ret$1;
    $l$block: {
      // Inline function 'io.ktor.utils.io.core.buildPacket' call
      // Inline function 'kotlin.contracts.contract' call
      var builder = new BytePacketBuilder();
      try {
        // Inline function 'io.ktor.utils.io.charsets.encodeToByteArrayImpl1.<anonymous>' call
        builder.d1r(single.u1q());
        encodeToImpl(_this__u8e3s4, builder, input, start, toIndex);
        tmp$ret$1 = builder.k1i();
        break $l$block;
      } catch ($p) {
        if ($p instanceof Error) {
          var t = $p;
          builder.ww();
          throw t;
        } else {
          throw $p;
        }
      }
    }
    return readBytes(tmp$ret$1);
  }finally {
    single.t1o(Companion_getInstance_4().y1d_1);
  }
}
function encodeImpl(_this__u8e3s4, input, fromIndex, toIndex, dst) {
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(fromIndex <= toIndex)) {
    // Inline function 'kotlin.require.<anonymous>' call
    var message = 'Failed requirement.';
    throw IllegalArgumentException.g3(toString(message));
  }
  if (get_charset(_this__u8e3s4).equals(Charsets_getInstance().f1s_1)) {
    return encodeISO88591(input, fromIndex, toIndex, dst);
  }
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(get_charset(_this__u8e3s4) === Charsets_getInstance().e1s_1)) {
    // Inline function 'io.ktor.utils.io.charsets.encodeImpl.<anonymous>' call
    var message_0 = 'Only UTF-8 encoding is supported in JS';
    throw IllegalArgumentException.g3(toString(message_0));
  }
  var encoder = new TextEncoder();
  var start = fromIndex;
  // Inline function 'io.ktor.utils.io.core.Buffer.writeRemaining' call
  var dstRemaining = dst.t1d_1 - dst.r1d_1 | 0;
  $l$loop: while (start < toIndex ? dstRemaining > 0 : false) {
    // Inline function 'kotlin.comparisons.minOf' call
    var a = toIndex - start | 0;
    var b = dstRemaining / 6 | 0;
    var tmp$ret$3 = Math.min(a, b);
    var numChars = coerceAtLeast(tmp$ret$3, 1);
    var dropLastChar = isHighSurrogate(charSequenceGet(input, (start + numChars | 0) - 1 | 0));
    var endIndexExclusive = (dropLastChar ? numChars === 1 : false) ? start + 2 | 0 : dropLastChar ? (start + numChars | 0) - 1 | 0 : start + numChars | 0;
    // Inline function 'kotlin.text.substring' call
    var startIndex = start;
    var tmp$ret$4 = toString(charSequenceSubSequence(input, startIndex, endIndexExclusive));
    var array1 = encoder.encode(tmp$ret$4);
    if (array1.length > dstRemaining)
      break $l$loop;
    writeFully_4(dst, array1);
    start = endIndexExclusive;
    dstRemaining = dstRemaining - array1.length | 0;
  }
  return start - fromIndex | 0;
}
function get_charset(_this__u8e3s4) {
  return _this__u8e3s4.b1w_1;
}
function decode_0(_this__u8e3s4, input, dst, max) {
  var decoder = Decoder(get_name(get_charset_0(_this__u8e3s4)), true);
  var charactersCopied = 0;
  $l$block: {
    // Inline function 'io.ktor.utils.io.core.takeWhileSize' call
    var release = true;
    var tmp0_elvis_lhs = prepareReadFirstHead(input, 1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      break $l$block;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var current = tmp;
    var size = 1;
    try {
      $l$loop: do {
        // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
        var this_0 = current;
        var before = this_0.r1d_1 - this_0.q1d_1 | 0;
        var after;
        if (before >= size) {
          try {
            var tmp$ret$3;
            $l$block_0: {
              // Inline function 'io.ktor.utils.io.charsets.decode.<anonymous>' call
              var buffer = current;
              var rem = max - charactersCopied | 0;
              // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
              var bufferSize = buffer.r1d_1 - buffer.q1d_1 | 0;
              if (rem < bufferSize) {
                tmp$ret$3 = 0;
                break $l$block_0;
              }
              // Inline function 'io.ktor.utils.io.core.readDirectInt8Array' call
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'io.ktor.utils.io.core.read' call
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'io.ktor.utils.io.core.readDirectInt8Array.<anonymous>' call
              var memory = buffer.p1d_1;
              var start = buffer.q1d_1;
              var endExclusive = buffer.r1d_1;
              // Inline function 'io.ktor.utils.io.charsets.decode.<anonymous>.<anonymous>' call
              var view = new Int8Array(memory.z1o_1.buffer, memory.z1o_1.byteOffset + start | 0, endExclusive - start | 0);
              $l$block_2: {
                // Inline function 'io.ktor.utils.io.js.decodeWrap' call
                try {
                  // Inline function 'io.ktor.utils.io.charsets.decode.<anonymous>.<anonymous>.<anonymous>' call
                  var tmp$ret$4;
                  $l$block_1: {
                    // Inline function 'io.ktor.utils.io.js.decodeStream' call
                    // Inline function 'io.ktor.utils.io.js.decodeWrap' call
                    try {
                      tmp$ret$4 = decoder.i1w(view, decodeOptions(true));
                      break $l$block_1;
                    } catch ($p) {
                      if ($p instanceof Error) {
                        var t = $p;
                        var tmp0_elvis_lhs_0 = t.message;
                        throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs_0 == null ? 'no cause provided' : tmp0_elvis_lhs_0));
                      } else {
                        throw $p;
                      }
                    }
                  }
                  break $l$block_2;
                } catch ($p) {
                  if ($p instanceof Error) {
                    var t_0 = $p;
                    var tmp0_elvis_lhs_1 = t_0.message;
                    throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs_1 == null ? 'no cause provided' : tmp0_elvis_lhs_1));
                  } else {
                    throw $p;
                  }
                }
              }
              var decodedText = tmp$ret$4;
              dst.ra(decodedText);
              charactersCopied = charactersCopied + decodedText.length | 0;
              var rc = view.byteLength;
              buffer.a1p(rc);
              var tmp_0;
              if (charactersCopied === max) {
                var tmp_1;
                try {
                  tmp_1 = decoder.j1w();
                } catch ($p) {
                  var tmp_2;
                  var _ = $p;
                  tmp_2 = '';
                  tmp_1 = tmp_2;
                }
                var tail = tmp_1;
                // Inline function 'kotlin.text.isNotEmpty' call
                if (charSequenceLength(tail) > 0) {
                  buffer.e1p(bufferSize);
                }
                tmp_0 = 0;
              } else if (charactersCopied < max) {
                tmp_0 = get_MAX_CHARACTERS_SIZE_IN_BYTES();
              } else {
                tmp_0 = 0;
              }
              tmp$ret$3 = tmp_0;
            }
            size = tmp$ret$3;
          }finally {
            // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
            var this_1 = current;
            after = this_1.r1d_1 - this_1.q1d_1 | 0;
          }
        } else {
          after = before;
        }
        release = false;
        var tmp_3;
        if (after === 0) {
          tmp_3 = prepareReadNextHead(input, current);
        } else {
          var tmp_4;
          if (after < size) {
            tmp_4 = true;
          } else {
            // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
            var this_2 = current;
            var tmp_5 = this_2.u1d_1 - this_2.t1d_1 | 0;
            tmp_4 = tmp_5 < 8;
          }
          if (tmp_4) {
            completeReadHead(input, current);
            tmp_3 = prepareReadFirstHead(input, size);
          } else {
            tmp_3 = current;
          }
        }
        var tmp1_elvis_lhs = tmp_3;
        var tmp_6;
        if (tmp1_elvis_lhs == null) {
          break $l$loop;
        } else {
          tmp_6 = tmp1_elvis_lhs;
        }
        var next = tmp_6;
        current = next;
        release = true;
      }
       while (size > 0);
    }finally {
      if (release) {
        completeReadHead(input, current);
      }
    }
  }
  if (charactersCopied < max) {
    var size_0 = 1;
    $l$block_3: {
      // Inline function 'io.ktor.utils.io.core.takeWhileSize' call
      var release_0 = true;
      var tmp0_elvis_lhs_2 = prepareReadFirstHead(input, 1);
      var tmp_7;
      if (tmp0_elvis_lhs_2 == null) {
        break $l$block_3;
      } else {
        tmp_7 = tmp0_elvis_lhs_2;
      }
      var current_0 = tmp_7;
      var size_1 = 1;
      try {
        $l$loop_0: do {
          // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
          var this_3 = current_0;
          var before_0 = this_3.r1d_1 - this_3.q1d_1 | 0;
          var after_0;
          if (before_0 >= size_1) {
            try {
              // Inline function 'io.ktor.utils.io.charsets.decode.<anonymous>' call
              // Inline function 'io.ktor.utils.io.core.readDirectInt8Array' call
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'io.ktor.utils.io.core.read' call
              var this_4 = current_0;
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'io.ktor.utils.io.core.readDirectInt8Array.<anonymous>' call
              var memory_0 = this_4.p1d_1;
              var start_0 = this_4.q1d_1;
              var endExclusive_0 = this_4.r1d_1;
              // Inline function 'io.ktor.utils.io.charsets.decode.<anonymous>.<anonymous>' call
              var view_0 = new Int8Array(memory_0.z1o_1.buffer, memory_0.z1o_1.byteOffset + start_0 | 0, endExclusive_0 - start_0 | 0);
              var result = decodeBufferImpl(view_0, decoder, max - charactersCopied | 0);
              dst.ra(result.k1w_1);
              charactersCopied = charactersCopied + result.k1w_1.length | 0;
              var rc_0 = result.l1w_1;
              this_4.a1p(rc_0);
              var rc_1 = rc_0;
              if (rc_1 > 0)
                size_0 = 1;
              else if (size_0 === get_MAX_CHARACTERS_SIZE_IN_BYTES())
                size_0 = 0;
              else {
                size_0 = size_0 + 1 | 0;
              }
              size_1 = size_0;
            }finally {
              // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
              var this_5 = current_0;
              after_0 = this_5.r1d_1 - this_5.q1d_1 | 0;
            }
          } else {
            after_0 = before_0;
          }
          release_0 = false;
          var tmp_8;
          if (after_0 === 0) {
            tmp_8 = prepareReadNextHead(input, current_0);
          } else {
            var tmp_9;
            if (after_0 < size_1) {
              tmp_9 = true;
            } else {
              // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
              var this_6 = current_0;
              var tmp_10 = this_6.u1d_1 - this_6.t1d_1 | 0;
              tmp_9 = tmp_10 < 8;
            }
            if (tmp_9) {
              completeReadHead(input, current_0);
              tmp_8 = prepareReadFirstHead(input, size_1);
            } else {
              tmp_8 = current_0;
            }
          }
          var tmp1_elvis_lhs_0 = tmp_8;
          var tmp_11;
          if (tmp1_elvis_lhs_0 == null) {
            break $l$loop_0;
          } else {
            tmp_11 = tmp1_elvis_lhs_0;
          }
          var next_0 = tmp_11;
          current_0 = next_0;
          release_0 = true;
        }
         while (size_1 > 0);
      }finally {
        if (release_0) {
          completeReadHead(input, current_0);
        }
      }
    }
  }
  return charactersCopied;
}
function encodeComplete(_this__u8e3s4, dst) {
  return true;
}
function decodeExactBytes(_this__u8e3s4, input, inputLength) {
  if (inputLength === 0)
    return '';
  // Inline function 'io.ktor.utils.io.core.Input.headRemaining' call
  if ((input.r1g_1 - input.q1g_1 | 0) >= inputLength) {
    var decoder = Decoder(get_charset_0(_this__u8e3s4).g1s_1, true);
    var head = input.c1r();
    var view = input.p1g_1.z1o_1;
    var tmp$ret$2;
    $l$block: {
      // Inline function 'io.ktor.utils.io.js.decodeWrap' call
      try {
        // Inline function 'io.ktor.utils.io.charsets.decodeExactBytes.<anonymous>' call
        var subView = (head.q1d_1 === 0 ? inputLength === view.byteLength : false) ? view : new DataView(view.buffer, view.byteOffset + head.q1d_1 | 0, inputLength);
        tmp$ret$2 = decoder.m1w(subView);
        break $l$block;
      } catch ($p) {
        if ($p instanceof Error) {
          var t = $p;
          var tmp0_elvis_lhs = t.message;
          throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs == null ? 'no cause provided' : tmp0_elvis_lhs));
        } else {
          throw $p;
        }
      }
    }
    var text = tmp$ret$2;
    input.a1p(inputLength);
    return text;
  }
  return decodeExactBytesSlow(_this__u8e3s4, input, inputLength);
}
function get_charset_0(_this__u8e3s4) {
  return _this__u8e3s4.a1w_1;
}
function decodeExactBytesSlow(_this__u8e3s4, input, inputLength) {
  var decoder = Decoder(get_name(get_charset_0(_this__u8e3s4)), true);
  var inputRemaining = inputLength;
  var sb = StringBuilder.n7(inputLength);
  $l$block_4: {
    // Inline function 'io.ktor.utils.io.js.decodeWrap' call
    try {
      // Inline function 'io.ktor.utils.io.charsets.decodeExactBytesSlow.<anonymous>' call
      $l$block: {
        // Inline function 'io.ktor.utils.io.core.takeWhileSize' call
        var release = true;
        var tmp0_elvis_lhs = prepareReadFirstHead(input, 6);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          break $l$block;
        } else {
          tmp = tmp0_elvis_lhs;
        }
        var current = tmp;
        var size = 6;
        try {
          $l$loop: do {
            // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
            var this_0 = current;
            var before = this_0.r1d_1 - this_0.q1d_1 | 0;
            var after;
            if (before >= size) {
              try {
                // Inline function 'io.ktor.utils.io.charsets.decodeExactBytesSlow.<anonymous>.<anonymous>' call
                var buffer = current;
                // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
                var chunkSize = buffer.r1d_1 - buffer.q1d_1 | 0;
                // Inline function 'kotlin.comparisons.minOf' call
                var b = inputRemaining;
                var size_0 = Math.min(chunkSize, b);
                var tmp_0;
                if (buffer.q1d_1 === 0 ? buffer.p1d_1.z1o_1.byteLength === size_0 : false) {
                  var tmp$ret$4;
                  $l$block_0: {
                    // Inline function 'io.ktor.utils.io.js.decodeStream' call
                    var buffer_0 = buffer.p1d_1.z1o_1;
                    // Inline function 'io.ktor.utils.io.js.decodeWrap' call
                    try {
                      tmp$ret$4 = decoder.i1w(buffer_0, decodeOptions(true));
                      break $l$block_0;
                    } catch ($p) {
                      if ($p instanceof Error) {
                        var t = $p;
                        var tmp0_elvis_lhs_0 = t.message;
                        throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs_0 == null ? 'no cause provided' : tmp0_elvis_lhs_0));
                      } else {
                        throw $p;
                      }
                    }
                  }
                  tmp_0 = tmp$ret$4;
                } else {
                  var tmp$ret$6;
                  $l$block_1: {
                    // Inline function 'io.ktor.utils.io.js.decodeStream' call
                    var buffer_1 = new Int8Array(buffer.p1d_1.z1o_1.buffer, buffer.p1d_1.z1o_1.byteOffset + buffer.q1d_1 | 0, size_0);
                    // Inline function 'io.ktor.utils.io.js.decodeWrap' call
                    try {
                      tmp$ret$6 = decoder.i1w(buffer_1, decodeOptions(true));
                      break $l$block_1;
                    } catch ($p) {
                      if ($p instanceof Error) {
                        var t_0 = $p;
                        var tmp0_elvis_lhs_1 = t_0.message;
                        throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs_1 == null ? 'no cause provided' : tmp0_elvis_lhs_1));
                      } else {
                        throw $p;
                      }
                    }
                  }
                  tmp_0 = tmp$ret$6;
                }
                var text = tmp_0;
                sb.i7(text);
                buffer.a1p(size_0);
                inputRemaining = inputRemaining - size_0 | 0;
                size = inputRemaining > 0 ? 6 : 0;
              }finally {
                // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
                var this_1 = current;
                after = this_1.r1d_1 - this_1.q1d_1 | 0;
              }
            } else {
              after = before;
            }
            release = false;
            var tmp_1;
            if (after === 0) {
              tmp_1 = prepareReadNextHead(input, current);
            } else {
              var tmp_2;
              if (after < size) {
                tmp_2 = true;
              } else {
                // Inline function 'io.ktor.utils.io.core.Buffer.endGap' call
                var this_2 = current;
                var tmp_3 = this_2.u1d_1 - this_2.t1d_1 | 0;
                tmp_2 = tmp_3 < 8;
              }
              if (tmp_2) {
                completeReadHead(input, current);
                tmp_1 = prepareReadFirstHead(input, size);
              } else {
                tmp_1 = current;
              }
            }
            var tmp1_elvis_lhs = tmp_1;
            var tmp_4;
            if (tmp1_elvis_lhs == null) {
              break $l$loop;
            } else {
              tmp_4 = tmp1_elvis_lhs;
            }
            var next = tmp_4;
            current = next;
            release = true;
          }
           while (size > 0);
        }finally {
          if (release) {
            completeReadHead(input, current);
          }
        }
      }
      if (inputRemaining > 0) {
        $l$block_2: {
          // Inline function 'io.ktor.utils.io.core.takeWhile' call
          var release_0 = true;
          var tmp0_elvis_lhs_2 = prepareReadFirstHead(input, 1);
          var tmp_5;
          if (tmp0_elvis_lhs_2 == null) {
            break $l$block_2;
          } else {
            tmp_5 = tmp0_elvis_lhs_2;
          }
          var current_0 = tmp_5;
          try {
            $l$loop_1: do {
              // Inline function 'io.ktor.utils.io.charsets.decodeExactBytesSlow.<anonymous>.<anonymous>' call
              var buffer_2 = current_0;
              // Inline function 'io.ktor.utils.io.core.Buffer.readRemaining' call
              var chunkSize_0 = buffer_2.r1d_1 - buffer_2.q1d_1 | 0;
              // Inline function 'kotlin.comparisons.minOf' call
              var b_0 = inputRemaining;
              var size_1 = Math.min(chunkSize_0, b_0);
              var tmp_6;
              if (buffer_2.q1d_1 === 0 ? buffer_2.p1d_1.z1o_1.byteLength === size_1 : false) {
                tmp_6 = decoder.m1w(buffer_2.p1d_1.z1o_1);
              } else {
                var tmp$ret$14;
                $l$block_3: {
                  // Inline function 'io.ktor.utils.io.js.decodeStream' call
                  var buffer_3 = new Int8Array(buffer_2.p1d_1.z1o_1.buffer, buffer_2.p1d_1.z1o_1.byteOffset + buffer_2.q1d_1 | 0, size_1);
                  // Inline function 'io.ktor.utils.io.js.decodeWrap' call
                  try {
                    tmp$ret$14 = decoder.i1w(buffer_3, decodeOptions(true));
                    break $l$block_3;
                  } catch ($p) {
                    if ($p instanceof Error) {
                      var t_1 = $p;
                      var tmp0_elvis_lhs_3 = t_1.message;
                      throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs_3 == null ? 'no cause provided' : tmp0_elvis_lhs_3));
                    } else {
                      throw $p;
                    }
                  }
                }
                tmp_6 = tmp$ret$14;
              }
              var text_0 = tmp_6;
              sb.i7(text_0);
              buffer_2.a1p(size_1);
              inputRemaining = inputRemaining - size_1 | 0;
              if (!true) {
                break $l$loop_1;
              }
              release_0 = false;
              var tmp1_elvis_lhs_0 = prepareReadNextHead(input, current_0);
              var tmp_7;
              if (tmp1_elvis_lhs_0 == null) {
                break $l$loop_1;
              } else {
                tmp_7 = tmp1_elvis_lhs_0;
              }
              var next_0 = tmp_7;
              current_0 = next_0;
              release_0 = true;
            }
             while (true);
          }finally {
            if (release_0) {
              completeReadHead(input, current_0);
            }
          }
        }
      }
      sb.i7(decoder.j1w());
      break $l$block_4;
    } catch ($p) {
      if ($p instanceof Error) {
        var t_2 = $p;
        var tmp0_elvis_lhs_4 = t_2.message;
        throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs_4 == null ? 'no cause provided' : tmp0_elvis_lhs_4));
      } else {
        throw $p;
      }
    }
  }
  if (inputRemaining > 0) {
    throw EOFException.q1p('Not enough bytes available: had only ' + (inputLength - inputRemaining | 0) + ' instead of ' + inputLength);
  }
  return sb.toString();
}
function get_MAX_CHARACTERS_SIZE_IN_BYTES() {
  return MAX_CHARACTERS_SIZE_IN_BYTES;
}
var MAX_CHARACTERS_SIZE_IN_BYTES;
function decodeBufferImpl(_this__u8e3s4, nativeDecoder, maxCharacters) {
  if (maxCharacters === 0) {
    return new DecodeBufferResult('', 0);
  }
  try {
    var sizeInBytes = coerceAtMost(maxCharacters, _this__u8e3s4.byteLength);
    var text = nativeDecoder.m1w(_this__u8e3s4.subarray(0, sizeInBytes));
    if (text.length <= maxCharacters) {
      return new DecodeBufferResult(text, sizeInBytes);
    }
  } catch ($p) {
    var _ = $p;
  }
  return decodeBufferImplSlow(_this__u8e3s4, nativeDecoder, maxCharacters);
}
function decodeBufferImplSlow(_this__u8e3s4, nativeDecoder, maxCharacters) {
  var maxBytes = coerceAtMost(maxCharacters >= 268435455 ? IntCompanionObject_instance.MAX_VALUE : imul(maxCharacters, 8), _this__u8e3s4.byteLength);
  var sizeInBytes = maxBytes;
  while (sizeInBytes > 8) {
    try {
      var text = nativeDecoder.m1w(_this__u8e3s4.subarray(0, sizeInBytes));
      if (text.length <= maxCharacters) {
        return new DecodeBufferResult(text, sizeInBytes);
      }
    } catch ($p) {
      var _ = $p;
    }
    sizeInBytes = sizeInBytes / 2 | 0;
  }
  sizeInBytes = 8;
  while (sizeInBytes > 0) {
    try {
      var text_0 = nativeDecoder.m1w(_this__u8e3s4.subarray(0, sizeInBytes));
      if (text_0.length <= maxCharacters) {
        return new DecodeBufferResult(text_0, sizeInBytes);
      }
    } catch ($p) {
      var __0 = $p;
    }
    sizeInBytes = sizeInBytes - 1 | 0;
  }
  $l$block: {
    // Inline function 'io.ktor.utils.io.js.decodeWrap' call
    try {
      // Inline function 'io.ktor.utils.io.charsets.decodeBufferImplSlow.<anonymous>' call
      nativeDecoder.m1w(_this__u8e3s4);
      break $l$block;
    } catch ($p) {
      if ($p instanceof Error) {
        var t = $p;
        var tmp0_elvis_lhs = t.message;
        throw MalformedInputException.y1o('Failed to decode bytes: ' + (tmp0_elvis_lhs == null ? 'no cause provided' : tmp0_elvis_lhs));
      } else {
        throw $p;
      }
    }
  }
  throw MalformedInputException.y1o('Unable to decode buffer');
}
function encodeISO88591(input, fromIndex, toIndex, dst) {
  if (fromIndex >= toIndex)
    return 0;
  // Inline function 'io.ktor.utils.io.core.writeDirect' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.write' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.writeDirect.<anonymous>' call
  var memory = dst.p1d_1;
  var start = dst.r1d_1;
  var endExclusive = dst.t1d_1;
  // Inline function 'io.ktor.utils.io.charsets.encodeISO88591.<anonymous>' call
  var view = memory.x1v(start, endExclusive - start | 0).z1o_1;
  var i8 = new Int8Array(view.buffer, view.byteOffset, view.byteLength);
  var writeIndex = 0;
  var inductionVariable = fromIndex;
  if (inductionVariable < toIndex)
    do {
      var index = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.code' call
      var this_0 = charSequenceGet(input, index);
      var character = Char__toInt_impl_vasixd(this_0);
      if (character > 255) {
        failedToMapError(character);
      }
      // Inline function 'org.khronos.webgl.set' call
      var tmp1 = writeIndex;
      writeIndex = tmp1 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      i8[tmp1] = toByte(character);
    }
     while (inductionVariable < toIndex);
  var rc = writeIndex;
  dst.b1p(rc);
  return toIndex - fromIndex | 0;
}
function failedToMapError(ch) {
  throw MalformedInputException.y1o('The character with unicode point ' + ch + " couldn't be mapped to ISO-8859-1 character");
}
function writeFully_4(_this__u8e3s4, src, offset, length) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? src.byteLength - offset | 0 : length;
  // Inline function 'io.ktor.utils.io.core.write' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.writeFully.<anonymous>' call
  var memory = _this__u8e3s4.p1d_1;
  var dstOffset = _this__u8e3s4.r1d_1;
  if ((_this__u8e3s4.t1d_1 - dstOffset | 0) < length) {
    throw InsufficientSpaceException.u1p('Not enough free space to write ' + length + ' bytes');
  }
  copyTo_1(src, memory, offset, length, dstOffset);
  var rc = length;
  _this__u8e3s4.b1p(rc);
}
function ByteReadPacket_0(array, offset, length, block) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? array.length : length;
  // Inline function 'kotlin.js.asDynamic' call
  var tmp = array;
  var content = tmp instanceof Int8Array ? tmp : THROW_CCE();
  var sub = (offset === 0 ? length === array.length : false) ? content.buffer : content.buffer.slice(offset, offset + length | 0);
  var pool = new ByteReadPacket$pool$1(sub, block, array);
  // Inline function 'kotlin.apply' call
  var this_0 = pool.w1n();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.core.ByteReadPacket.<anonymous>' call
  this_0.g1p();
  return ByteReadPacket.m1l(this_0, pool);
}
function addSuppressedInternal(_this__u8e3s4, other) {
}
function get_PACKET_MAX_COPY_SIZE() {
  return PACKET_MAX_COPY_SIZE;
}
var PACKET_MAX_COPY_SIZE;
function String_0(bytes, offset, length, charset) {
  offset = offset === VOID ? 0 : offset;
  length = length === VOID ? bytes.length : length;
  charset = charset === VOID ? Charsets_getInstance().e1s_1 : charset;
  if ((offset < 0 ? true : length < 0) ? true : (offset + length | 0) > bytes.length) {
    checkIndices(offset, length, bytes);
  }
  // Inline function 'kotlin.js.asDynamic' call
  var i8 = bytes;
  var bufferOffset = i8.byteOffset + offset | 0;
  var buffer = i8.buffer.slice(bufferOffset, bufferOffset + length | 0);
  var view = new ChunkBuffer(of_1(Companion_getInstance_6(), buffer), null, Companion_getInstance_4().b1e_1);
  view.g1p();
  var packet = ByteReadPacket.m1l(view, Companion_getInstance_4().c1e_1);
  return decode(charset.h1s(), packet, IntCompanionObject_instance.MAX_VALUE);
}
function checkIndices(offset, length, bytes) {
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(offset >= 0)) {
    throw IndexOutOfBoundsException.qa('offset (' + offset + ") shouldn't be negative");
  }
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!(length >= 0)) {
    throw IndexOutOfBoundsException.qa('length (' + length + ") shouldn't be negative");
  }
  // Inline function 'kotlin.require' call
  // Inline function 'kotlin.contracts.contract' call
  if (!((offset + length | 0) <= bytes.length)) {
    throw IndexOutOfBoundsException.qa('offset (' + offset + ') + length (' + length + ') > bytes.size (' + bytes.length + ')');
  }
  throw IndexOutOfBoundsException.bg();
}
function Decoder(encoding, fatal) {
  fatal = fatal === VOID ? true : fatal;
  var tmp;
  try {
    tmp = toKtor(new TextDecoder(encoding, textDecoderOptions(fatal)));
  } catch ($p) {
    var tmp_0;
    if ($p instanceof Error) {
      var cause = $p;
      tmp_0 = new TextDecoderFallback(encoding, fatal);
    } else {
      throw $p;
    }
    tmp = tmp_0;
  }
  return tmp;
}
function decodeOptions(stream) {
  // Inline function 'kotlin.apply' call
  var this_0 = new Object();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.js.decodeOptions.<anonymous>' call
  // Inline function 'kotlin.with' call
  // Inline function 'kotlin.js.asDynamic' call
  // Inline function 'kotlin.contracts.contract' call
  this_0.stream = stream;
  return this_0;
}
function toKtor(_this__u8e3s4) {
  return new toKtor$1(_this__u8e3s4);
}
function textDecoderOptions(fatal) {
  fatal = fatal === VOID ? false : fatal;
  // Inline function 'kotlin.apply' call
  var this_0 = new Object();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.utils.io.js.textDecoderOptions.<anonymous>' call
  // Inline function 'kotlin.with' call
  // Inline function 'kotlin.js.asDynamic' call
  // Inline function 'kotlin.contracts.contract' call
  this_0.fatal = fatal;
  return this_0;
}
function get_ENCODING_ALIASES() {
  _init_properties_TextDecoderFallback_kt__nrrftl();
  return ENCODING_ALIASES;
}
var ENCODING_ALIASES;
function get_REPLACEMENT() {
  _init_properties_TextDecoderFallback_kt__nrrftl();
  return REPLACEMENT;
}
var REPLACEMENT;
function toCodePoint(_this__u8e3s4) {
  _init_properties_TextDecoderFallback_kt__nrrftl();
  var value = _this__u8e3s4 & 255;
  if (isASCII(value)) {
    return value;
  }
  return get_WIN1252_TABLE()[value - 128 | 0];
}
function isASCII(_this__u8e3s4) {
  _init_properties_TextDecoderFallback_kt__nrrftl();
  return 0 <= _this__u8e3s4 ? _this__u8e3s4 <= 127 : false;
}
var properties_initialized_TextDecoderFallback_kt_7y92ax;
function _init_properties_TextDecoderFallback_kt__nrrftl() {
  if (!properties_initialized_TextDecoderFallback_kt_7y92ax) {
    properties_initialized_TextDecoderFallback_kt_7y92ax = true;
    ENCODING_ALIASES = setOf(['ansi_x3.4-1968', 'ascii', 'cp1252', 'cp819', 'csisolatin1', 'ibm819', 'iso-8859-1', 'iso-ir-100', 'iso8859-1', 'iso88591', 'iso_8859-1', 'iso_8859-1:1987', 'l1', 'latin1', 'us-ascii', 'windows-1252', 'x-cp1252']);
    // Inline function 'kotlin.byteArrayOf' call
    REPLACEMENT = new Int8Array([-17, -65, -67]);
  }
}
function get_WIN1252_TABLE() {
  _init_properties_Win1252Table_kt__tl0v64();
  return WIN1252_TABLE;
}
var WIN1252_TABLE;
var properties_initialized_Win1252Table_kt_pkmjoq;
function _init_properties_Win1252Table_kt__tl0v64() {
  if (!properties_initialized_Win1252Table_kt_pkmjoq) {
    properties_initialized_Win1252Table_kt_pkmjoq = true;
    // Inline function 'kotlin.intArrayOf' call
    WIN1252_TABLE = new Int32Array([8364, -1, 8218, 402, 8222, 8230, 8224, 8225, 710, 8240, 352, 8249, 338, -1, 381, -1, -1, 8216, 8217, 8220, 8221, 8226, 8211, 8212, 732, 8482, 353, 8250, 339, -1, 382, 376, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255]);
  }
}
//region block: post-declaration
setMetadataFor($tryAwaitCOROUTINE$49, '$tryAwaitCOROUTINE$49', classMeta);
setMetadataFor(ByteChannelSequentialBase$beginWriteSession$1, VOID, classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ByteChannelSequentialBase$readUTF8LineTo$slambda, 'ByteChannelSequentialBase$readUTF8LineTo$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor($awaitAtLeastNBytesAvailableForWriteCOROUTINE$0, '$awaitAtLeastNBytesAvailableForWriteCOROUTINE$0', classMeta);
setMetadataFor($awaitAtLeastNBytesAvailableForReadCOROUTINE$1, '$awaitAtLeastNBytesAvailableForReadCOROUTINE$1', classMeta);
setMetadataFor($writePacketCOROUTINE$8, '$writePacketCOROUTINE$8', classMeta);
setMetadataFor($writeFullyCOROUTINE$9, '$writeFullyCOROUTINE$9', classMeta);
setMetadataFor($writeFullyCOROUTINE$10, '$writeFullyCOROUTINE$10', classMeta);
setMetadataFor($readRemainingCOROUTINE$26, '$readRemainingCOROUTINE$26', classMeta);
setMetadataFor($readRemainingSuspendCOROUTINE$27, '$readRemainingSuspendCOROUTINE$27', classMeta);
setMetadataFor($readAvailableCOROUTINE$30, '$readAvailableCOROUTINE$30', classMeta);
setMetadataFor($readAvailableCOROUTINE$33, '$readAvailableCOROUTINE$33', classMeta);
setMetadataFor($awaitInternalAtLeast1COROUTINE$38, '$awaitInternalAtLeast1COROUTINE$38', classMeta);
setMetadataFor($awaitSuspendCOROUTINE$39, '$awaitSuspendCOROUTINE$39', classMeta);
setMetadataFor($discardCOROUTINE$40, '$discardCOROUTINE$40', classMeta);
setMetadataFor($discardSuspendCOROUTINE$41, '$discardSuspendCOROUTINE$41', classMeta);
setMetadataFor($readUTF8LineCOROUTINE$43, '$readUTF8LineCOROUTINE$43', classMeta);
setMetadataFor(ByteReadChannel, 'ByteReadChannel', interfaceMeta, VOID, VOID, VOID, VOID, VOID, [3, 1, 2, 0, 5]);
setMetadataFor(HasWriteSession, 'HasWriteSession', interfaceMeta);
protoOf(ByteChannelSequentialBase).w1l = readRemaining$default;
setMetadataFor(ByteChannelSequentialBase, 'ByteChannelSequentialBase', classMeta, VOID, [ByteReadChannel, HasWriteSession], VOID, VOID, VOID, [1, 3, 0, 2, 5]);
setMetadataFor(ClosedWriteChannelException, 'ClosedWriteChannelException', classMeta);
setMetadataFor(CloseElement, 'CloseElement', classMeta);
setMetadataFor(WriterScope, 'WriterScope', interfaceMeta, VOID, [CoroutineScope]);
protoOf(ChannelJob).gq = cancel$default;
protoOf(ChannelJob).bq = invokeOnCompletion$default;
setMetadataFor(ChannelJob, 'ChannelJob', classMeta, VOID, [Job], VOID, VOID, VOID, [0]);
setMetadataFor(ChannelScope, 'ChannelScope', classMeta, VOID, [CoroutineScope, WriterScope]);
setMetadataFor(launchChannel$slambda, 'launchChannel$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor($requestWriteBufferCOROUTINE$52, '$requestWriteBufferCOROUTINE$52', classMeta);
setMetadataFor($writeBufferSuspendCOROUTINE$53, '$writeBufferSuspendCOROUTINE$53', classMeta);
setMetadataFor($completeWritingFallbackCOROUTINE$54, '$completeWritingFallbackCOROUTINE$54', classMeta);
setMetadataFor(MalformedInputException, 'MalformedInputException', classMeta);
setMetadataFor(TooLongLineException, 'TooLongLineException', classMeta);
setMetadataFor(Companion, 'Companion', objectMeta);
setMetadataFor(Buffer, 'Buffer', classMeta);
setMetadataFor(InsufficientSpaceException, 'InsufficientSpaceException', classMeta, VOID, VOID, InsufficientSpaceException.u1p);
setMetadataFor(Closeable, 'Closeable', interfaceMeta);
setMetadataFor(ObjectPool, 'ObjectPool', interfaceMeta, VOID, [Closeable]);
protoOf(DefaultPool).t1q = close;
setMetadataFor(DefaultPool, 'DefaultPool', classMeta, VOID, [ObjectPool]);
setMetadataFor(DefaultBufferPool, 'DefaultBufferPool', classMeta, VOID, VOID, DefaultBufferPool);
setMetadataFor(Output, 'Output', classMeta, VOID, [Closeable]);
setMetadataFor(BytePacketBuilder, 'BytePacketBuilder', classMeta, VOID, VOID, BytePacketBuilder);
setMetadataFor(Companion_0, 'Companion', objectMeta);
setMetadataFor(Input, 'Input', classMeta, VOID, [Closeable]);
setMetadataFor(ByteReadPacket, 'ByteReadPacket', classMeta);
setMetadataFor(Companion_1, 'Companion', objectMeta);
protoOf(ChunkBuffer$Companion$Pool$1).t1q = close;
setMetadataFor(ChunkBuffer$Companion$Pool$1, VOID, classMeta, VOID, [ObjectPool]);
protoOf(ChunkBuffer$Companion$EmptyPool$1).t1q = close;
setMetadataFor(ChunkBuffer$Companion$EmptyPool$1, VOID, classMeta, VOID, [ObjectPool]);
protoOf(NoPoolImpl).t1q = close;
setMetadataFor(NoPoolImpl, 'NoPoolImpl', classMeta, VOID, [ObjectPool]);
setMetadataFor(ChunkBuffer$Companion$NoPool$1, VOID, classMeta);
setMetadataFor(ChunkBuffer$Companion$NoPoolManuallyManaged$1, VOID, classMeta);
setMetadataFor(Companion_2, 'Companion', objectMeta);
setMetadataFor(ChunkBuffer, 'ChunkBuffer', classMeta);
setMetadataFor(MalformedUTF8InputException, 'MalformedUTF8InputException', classMeta);
setMetadataFor($decodeUTF8LineLoopSuspendCOROUTINE$55, '$decodeUTF8LineLoopSuspendCOROUTINE$55', classMeta);
setMetadataFor($sleepCOROUTINE$56, '$sleepCOROUTINE$56', classMeta);
setMetadataFor($trySuspendCOROUTINE$57, '$trySuspendCOROUTINE$57', classMeta);
setMetadataFor(AwaitingSlot, 'AwaitingSlot', classMeta, VOID, VOID, AwaitingSlot, VOID, VOID, [1]);
setMetadataFor($copyToSequentialImplCOROUTINE$58, '$copyToSequentialImplCOROUTINE$58', classMeta);
setMetadataFor($copyToTailCOROUTINE$59, '$copyToTailCOROUTINE$59', classMeta);
setMetadataFor(ByteArrayPool$1, VOID, classMeta);
protoOf(SingleInstancePool).t1q = close;
setMetadataFor(SingleInstancePool, 'SingleInstancePool', classMeta, VOID, [ObjectPool]);
setMetadataFor(ByteChannelJS, 'ByteChannelJS', classMeta, VOID, VOID, VOID, VOID, VOID, [3, 1, 0, 2, 5]);
setMetadataFor(Companion_3, 'Companion', objectMeta);
setMetadataFor(DefaultAllocator, 'DefaultAllocator', objectMeta);
setMetadataFor(Companion_4, 'Companion', objectMeta);
setMetadataFor(Memory, 'Memory', classMeta);
setMetadataFor(Companion_5, 'Companion', objectMeta);
setMetadataFor(Charset, 'Charset', classMeta);
setMetadataFor(Charsets, 'Charsets', objectMeta);
setMetadataFor(CharsetDecoder, 'CharsetDecoder', classMeta);
setMetadataFor(CharsetEncoder, 'CharsetEncoder', classMeta);
setMetadataFor(CharsetImpl, 'CharsetImpl', classMeta);
setMetadataFor(CharsetEncoderImpl, 'CharsetEncoderImpl', classMeta);
setMetadataFor(CharsetDecoderImpl, 'CharsetDecoderImpl', classMeta);
setMetadataFor(DecodeBufferResult, 'DecodeBufferResult', classMeta);
setMetadataFor(ByteReadPacket$pool$1, VOID, classMeta);
setMetadataFor(IOException, 'IOException', classMeta);
setMetadataFor(EOFException, 'EOFException', classMeta);
setMetadataFor(toKtor$1, VOID, classMeta);
setMetadataFor(TextDecoderFallback, 'TextDecoderFallback', classMeta);
//endregion
//region block: init
Companion_instance = new Companion();
DEFAULT_BUFFER_SIZE = 4096;
Companion_instance_1 = new Companion_1();
DefaultAllocator_instance = new DefaultAllocator();
Companion_instance_5 = new Companion_5();
MAX_CHARACTERS_SIZE_IN_BYTES = 8;
PACKET_MAX_COPY_SIZE = 200;
//endregion
//region block: exports
export {
  completeWriting as completeWriting3g8f9wga5rcsw,
  copyTo_0 as copyTo2vm7vz7rr51or,
  copyTo as copyTo32deudx0wxvex,
  discard as discard7plob4gm0ip3,
  readAvailable as readAvailable3hkjy1wf4lycw,
  readUTF8Line as readUTF8Line3qxxa7ehhfnmh,
  requestWriteBuffer as requestWriteBuffer2u1a5gf1tp4oz,
  writeFully as writeFullyuorxug1itxv3,
  Companion_instance_5 as Companion_instance3aiov6iqkz7e8,
  Charsets_getInstance as Charsets_getInstanceqs70pvl4noow,
  Companion_instance as Companion_instancer480xuxylkhv,
  Companion_getInstance_5 as Companion_getInstance2ai11rhpust2a,
  MalformedInputException as MalformedInputExceptionbvc6h5ij0ias,
  decode as decode1h5mw63lslzyq,
  encodeToByteArray as encodeToByteArrayomtvgs5lyogm,
  encode as encode35e4rpnc94tb5,
  get_name as get_name2f11g4r0d5pxp,
  completeReadHead as completeReadHeadmsbisaktufcz,
  prepareReadFirstHead as prepareReadFirstHead3bxvcvf0u196c,
  prepareReadNextHead as prepareReadNextHead165u1tlz2sd0o,
  BytePacketBuilder as BytePacketBuilder2d5pjgm948a6v,
  ByteReadPacket_0 as ByteReadPacket3ttlcmrt6n01e,
  ByteReadPacket as ByteReadPacket19qnebry5xxpy,
  Closeable as Closeableu07ioona9oji,
  Input as Input1claccncp4iy3,
  String_0 as String2althbpus3r3k,
  addSuppressedInternal as addSuppressedInternal1yubdpi5q21j8,
  readAvailable_0 as readAvailable1xaslycmuvdzc,
  readBytes as readBytes2b47ed7nra7rg,
  readShort_0 as readShort3oo850dud33ng,
  readText as readText3x4cv5p7hylp,
  writeFully_3 as writeFully3bzs0b6jknxck,
  writeShort_0 as writeShort2gpvs0skw1vt,
  writeText as writeText338krnmr85lul,
  IOException as IOExceptionktvzt3eudz3d,
  get_ByteArrayPool as get_ByteArrayPool3f7yrgvqxz9ct,
  ByteChannel as ByteChannelgfqke9q216t7,
  ByteReadChannel_0 as ByteReadChannel1dp09w1xstn8w,
  ByteReadChannel as ByteReadChannel2wzou76jce72d,
  WriterScope as WriterScope3b0bo1enaee6b,
  cancel as canceldn4b3cdqcfny,
  close_0 as closeqm43o3junf8o,
  writer as writer1eia5its2a1fh,
};
//endregion


