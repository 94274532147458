/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Directive, type OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isLoggedIn]',
})
export class IsLoggedInDirective implements OnInit {

  private hasView = false;

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef,
              private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.authService.userLoggedIn$.subscribe(loggedIn => {
      if (!this.hasView && loggedIn) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (this.hasView && !loggedIn) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }
}
