import { LinkedListNode3tts9l6uzdsny as LinkedListNode } from './kotlinx-coroutines-core.mjs';
import {
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  equals2au1ep9vhcato as equals,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  addSuppressedu5jwjfvsc039 as addSuppressed,
  Unit_instance1fbcbse1fwigr as Unit_instance,
  VOID7hggqo3abtya as VOID,
} from './kotlin-kotlin-stdlib.mjs';
import { CopyOnWriteHashMap2wz01l72sexe7 as CopyOnWriteHashMap } from './ktor-ktor-utils.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class HandlerRegistration extends LinkedListNode {}
class Events {
  constructor() {
    this.j2j_1 = new CopyOnWriteHashMap();
  }
  k2j(definition, value) {
    var exception = null;
    var tmp0_safe_receiver = this.j2j_1.q21(definition);
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlinx.coroutines.internal.LinkedListHead.forEach' call
      var cur = tmp0_safe_receiver.fs_1;
      while (!equals(cur, tmp0_safe_receiver)) {
        if (cur instanceof HandlerRegistration) {
          // Inline function 'io.ktor.events.Events.raise.<anonymous>' call
          var registration = cur;
          try {
            var tmp = registration.o2j_1;
            (typeof tmp === 'function' ? tmp : THROW_CCE())(value);
          } catch ($p) {
            if ($p instanceof Error) {
              var e = $p;
              var tmp0_safe_receiver_0 = exception;
              var tmp_0;
              if (tmp0_safe_receiver_0 == null) {
                tmp_0 = null;
              } else {
                addSuppressed(tmp0_safe_receiver_0, e);
                tmp_0 = Unit_instance;
              }
              if (tmp_0 == null) {
                // Inline function 'kotlin.run' call
                // Inline function 'kotlin.contracts.contract' call
                exception = e;
              }
            } else {
              throw $p;
            }
          }
        }
        cur = cur.fs_1;
      }
    }
    var tmp1_safe_receiver = exception;
    if (tmp1_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      throw tmp1_safe_receiver;
    }
  }
}
class EventDefinition {}
//endregion
//region block: post-declaration
setMetadataFor(HandlerRegistration, 'HandlerRegistration', classMeta);
setMetadataFor(Events, 'Events', classMeta, VOID, VOID, Events);
setMetadataFor(EventDefinition, 'EventDefinition', classMeta, VOID, VOID, EventDefinition);
//endregion
//region block: exports
export {
  EventDefinition as EventDefinition1fymk8xrdelhn,
  Events as Events63tfxre48w4z,
};
//endregion


