<!--
  ~ Castalytics GmbH (c) 2022-2024
  ~ Project: snipocc
  -->

<kendo-dropdownbutton
  fillMode="clear"
  rounded="full"
  buttonClass="round-button"
  #dropdown
  [data]="actions"
  [buttonAttributes]="{}"
>
  <ng-template kendoDropDownButtonItemTemplate let-dataItem>
    <div class="k-d-flex-row k-align-items-center k-justify-content-between" style="min-width: 100px;">
      @if (dataItem.svgIcon) {
        <kendo-svg-icon [icon]="dataItem.svgIcon" size="large" style="margin: 0.25rem"></kendo-svg-icon>
      }
      <span>{{dataItem.i18nKey ? (dataItem.i18nKey | translate) : dataItem.text ?? dataItem[dropdown.textField]}}</span>
    </div>
  </ng-template>
  @if (userInitials !== null) {
    <kendo-avatar
      [initials]="userInitials"
    >
    </kendo-avatar>
  } @else {
    <kendo-avatar
      [svgIcon]="avatarIcon"
    >
    </kendo-avatar>
  }
</kendo-dropdownbutton>