<!--
  ~ Castalytics GmbH (c) 2022-2024
  ~ Project: snipocc
  -->

<kendo-dropdownlist
  [data]="langs"
  [(ngModel)]="selectedLang"
  [valuePrimitive]="false"
  (valueChange)="langChange($event)"
  [tabindex]="-1"
  adaptiveMode="auto"
>
  <ng-template kendoDropDownListValueTemplate let-dataItem>
    @if (dataItem) {
      <span class="fl fl-{{dataItem.code}}"></span> {{dataItem.name}}
    }
  </ng-template>
  <ng-template kendoDropDownListItemTemplate let-dataItem>
    <span class="lang-item-wrapper">
      <span class="fl fl-{{dataItem.code}}"></span><span>{{dataItem.name}}</span>
    </span>
  </ng-template>
</kendo-dropdownlist>