import {
  Unit_instance1fbcbse1fwigr as Unit_instance,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  Continuation1aa2oekvx7jm7 as Continuation,
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  VOID7hggqo3abtya as VOID,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  ArrayList3it5z8td81qkl as ArrayList,
  Companion_instance2oawqq9qiaris as Companion_instance,
  _Result___init__impl__xyqfz81wclroc5pw7j2 as _Result___init__impl__xyqfz8,
  intercepted2ogpsikxxj4u0 as intercepted,
  fillArrayVali8eppxapiek4 as fillArrayVal,
  CoroutineImpl2sn3kjnwmfr10 as CoroutineImpl,
  copyToArray2j022khrow2yi as copyToArray,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  emptyList1g2z5xcrvp2zy as emptyList,
  EmptyCoroutineContext_getInstance31fow51ayy30t as EmptyCoroutineContext_getInstance,
  createCoroutineUnintercepted3gya308dmbbtg as createCoroutineUnintercepted,
  interfaceMeta1u1l5puptm1ve as interfaceMeta,
  UnsupportedOperationException2tkumpmhredt3 as UnsupportedOperationException,
  isInterface3d6p8outrmvmk as isInterface,
  IllegalStateExceptionkoljg5n0nrlr as IllegalStateException,
  toString1pkumu07cwy4m as toString,
  toString30pk9tzaqopn as toString_0,
  objectMeta213120oau977m as objectMeta,
  hashCodeq5arwsb9dgti as hashCode,
  equals2au1ep9vhcato as equals,
  CancellationException3b36o9qz53rgr as CancellationException,
  Result__exceptionOrNull_impl_p6xea9boty10p2dkn4 as Result__exceptionOrNull_impl_p6xea9,
  _Result___get_value__impl__bjfvqgo6z1uu11rodr as _Result___get_value__impl__bjfvqg,
  AbstractCoroutineContextKey9xr9r6wlj5bm as AbstractCoroutineContextKey,
  Key_instance17k9ki7fvysxq as Key_instance,
  AbstractCoroutineContextElement2rpehg0hv5szw as AbstractCoroutineContextElement,
  getxe4seun860fg as get,
  protoOf180f3jzyo7rfj as protoOf,
  minusKey2uxs00uz5ceqp as minusKey,
  ContinuationInterceptor2624y0vaqwxwf as ContinuationInterceptor,
  RuntimeException1r3t0zl97011n as RuntimeException,
  addSuppressedu5jwjfvsc039 as addSuppressed,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  Enum3alwj03lh1n41 as Enum,
  startCoroutine327fwvtqvedik as startCoroutine,
  noWhenBranchMatchedException2a6r7ubxgky5j as noWhenBranchMatchedException,
  Long2qws0ah9gnpki as Long,
  Companion_getInstance3gn12jgnf4xoo as Companion_getInstance,
  ArrayDeque2dzc9uld4xi7n as ArrayDeque,
  captureStack1fzi4aczwc4hg as captureStack,
  Error3ofk6owajcepa as Error_0,
  Element2gr7ezmxqaln7 as Element,
  StringBuildermazzzhj6kkai as StringBuilder,
  plusolev77jfy5r9 as plus,
  get6d5x931vk0s as get_0,
  fold36i9psb7d5v48 as fold,
  minusKeyyqanvso9aovh as minusKey_0,
  anyToString3ho3k49fc56mj as anyToString,
  createFailure8paxfkfa5dc7 as createFailure,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  toLongw1zpgk99d84b as toLong,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  listOf1jh22dvmctj1r as listOf,
  NoSuchElementException679xzhnp5bpj as NoSuchElementException,
  compareTo3ankvs086tmwq as compareTo,
  last2n4gf5az1lkn4 as last,
  _Char___init__impl__6a9atx281r2pd9o601g as _Char___init__impl__6a9atx,
  getKClass1s3j9wy1cofik as getKClass,
  Exceptiondt2hlxn7j7vw as Exception,
  IntCompanionObject_instance3tw56cgyd5vup as IntCompanionObject_instance,
  toLongOrNullutqivezb0wx1 as toLongOrNull,
  plus20p0vtfmu0596 as plus_0,
  List3hktaavzmj137 as List,
  listOfvhqybd2zx248 as listOf_0,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  HashSet2dzve9y63nf0v as HashSet,
  LinkedHashSet2tkztfx86kyx2 as LinkedHashSet,
  removeFirstOrNull15yg2tczrh8a7 as removeFirstOrNull,
  MutableList1beimitadwkna as MutableList,
  coerceIn302bduskdb54x as coerceIn,
  boxApply1qmzdb3dh90hg as boxApply,
} from './kotlin-kotlin-stdlib.mjs';
import {
  atomic$ref$130aurmcwdfdf1 as atomic$ref$1,
  atomic$int$11d5swdyn6j0pu as atomic$int$1,
  atomic$boolean$1iggki4z65a2h as atomic$boolean$1,
  atomicfu$AtomicRefArray$ofNulls2kz3j9ehigwa3 as atomicfu$AtomicRefArray$ofNulls,
  atomic$long$129k9zwo6n9ogd as atomic$long$1,
} from './kotlinx-atomicfu.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class Job {}
function cancel$default(cause, $super) {
  cause = cause === VOID ? null : cause;
  var tmp;
  if ($super === VOID) {
    this.fq(cause);
    tmp = Unit_instance;
  } else {
    tmp = $super.fq.call(this, cause);
  }
  return tmp;
}
function invokeOnCompletion$default(onCancelling, invokeImmediately, handler, $super) {
  onCancelling = onCancelling === VOID ? false : onCancelling;
  invokeImmediately = invokeImmediately === VOID ? true : invokeImmediately;
  return $super === VOID ? this.aq(onCancelling, invokeImmediately, handler) : $super.aq.call(this, onCancelling, invokeImmediately, handler);
}
class ParentJob {}
class JobSupport {
  constructor(active) {
    this.so_1 = atomic$ref$1(active ? get_EMPTY_ACTIVE() : get_EMPTY_NEW());
    this.to_1 = atomic$ref$1(null);
  }
  t2() {
    return Key_instance_3;
  }
  qp(value) {
    this.to_1.kotlinx$atomicfu$value = value;
  }
  rp() {
    return this.to_1.kotlinx$atomicfu$value;
  }
  uo(parent) {
    // Inline function 'kotlinx.coroutines.assert' call
    if (parent == null) {
      this.qp(NonDisposableHandle_instance);
      return Unit_instance;
    }
    parent.up();
    var handle = parent.mq(this);
    this.qp(handle);
    if (this.tp()) {
      handle.nm();
      this.qp(NonDisposableHandle_instance);
    }
  }
  sp() {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.so_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.<get-state>.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (!(state instanceof OpDescriptor))
        return state;
      state.vz(this);
    }
  }
  zo() {
    var state = this.sp();
    var tmp;
    if (!(state == null) ? isInterface(state, Incomplete) : false) {
      tmp = state.zo();
    } else {
      tmp = false;
    }
    return tmp;
  }
  tp() {
    var tmp = this.sp();
    return !(!(tmp == null) ? isInterface(tmp, Incomplete) : false);
  }
  up() {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.start.<anonymous>' call
      var state = this.sp();
      var tmp0_subject = startInternal(this, state);
      if (tmp0_subject === 0)
        return false;
      else if (tmp0_subject === 1)
        return true;
    }
  }
  vp() {
  }
  wp() {
    var state = this.sp();
    var tmp;
    if (state instanceof Finishing) {
      var tmp0_safe_receiver = state.yy();
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : this.xp(tmp0_safe_receiver, get_classSimpleName(this) + ' is cancelling');
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        var message = 'Job is still new or active: ' + this;
        throw IllegalStateException.l1(toString(message));
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      tmp = tmp_0;
    } else {
      if (!(state == null) ? isInterface(state, Incomplete) : false) {
        var message_0 = 'Job is still new or active: ' + this;
        throw IllegalStateException.l1(toString(message_0));
      } else {
        if (state instanceof CompletedExceptionally) {
          tmp = this.yp(state.ep_1);
        } else {
          tmp = JobCancellationException.ty(get_classSimpleName(this) + ' has completed normally', null, this);
        }
      }
    }
    return tmp;
  }
  xp(_this__u8e3s4, message) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof CancellationException ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      tmp = JobCancellationException.ty(message == null ? this.cp() : message, _this__u8e3s4, this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  yp(_this__u8e3s4, message, $super) {
    message = message === VOID ? null : message;
    return $super === VOID ? this.xp(_this__u8e3s4, message) : $super.xp.call(this, _this__u8e3s4, message);
  }
  zp(handler) {
    return this.cq(false, true, new UserSupplied_0(handler));
  }
  aq(onCancelling, invokeImmediately, handler) {
    return this.cq(onCancelling, invokeImmediately, new UserSupplied_0(handler));
  }
  cq(onCancelling, invokeImmediately, handler) {
    var node = makeNode(this, handler, onCancelling);
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.invokeOnCompletionInternal.<anonymous>' call
        var state = this.sp();
        if (state instanceof Empty) {
          if (state.zx_1) {
            if (this.so_1.atomicfu$compareAndSet(state, node))
              return node;
          } else {
            promoteEmptyToNodeList(this, state);
          }
        } else {
          if (!(state == null) ? isInterface(state, Incomplete) : false) {
            var list = state.es();
            if (list == null) {
              promoteSingleToNodeList(this, state instanceof JobNode ? state : THROW_CCE());
            } else {
              var rootCause = null;
              var handle = NonDisposableHandle_instance;
              var tmp;
              if (onCancelling) {
                tmp = state instanceof Finishing;
              } else {
                tmp = false;
              }
              if (tmp) {
                // Inline function 'kotlinx.coroutines.internal.synchronized' call
                // Inline function 'kotlin.contracts.contract' call
                // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
                rootCause = state.yy();
                var tmp_0;
                var tmp_1;
                if (rootCause == null) {
                  tmp_1 = true;
                } else {
                  var tmp_2;
                  if (handler instanceof ChildHandleNode) {
                    tmp_2 = !state.vy();
                  } else {
                    tmp_2 = false;
                  }
                  tmp_1 = tmp_2;
                }
                if (tmp_1) {
                  if (!addLastAtomic(this, state, list, node)) {
                    break $l$block;
                  }
                  if (rootCause == null)
                    return node;
                  handle = node;
                  tmp_0 = Unit_instance;
                }
              }
              if (!(rootCause == null)) {
                if (invokeImmediately) {
                  handler.rr(rootCause);
                }
                return handle;
              } else {
                if (addLastAtomic(this, state, list, node))
                  return node;
              }
            }
          } else {
            if (invokeImmediately) {
              var tmp1_safe_receiver = state instanceof CompletedExceptionally ? state : null;
              handler.rr(tmp1_safe_receiver == null ? null : tmp1_safe_receiver.ep_1);
            }
            return NonDisposableHandle_instance;
          }
        }
      }
    }
  }
  pw($completion) {
    if (!joinInternal(this)) {
      // Inline function 'kotlin.js.getCoroutineContext' call
      var tmp$ret$0 = $completion.af();
      ensureActive(tmp$ret$0);
      return Unit_instance;
    }
    return joinSuspend(this, $completion);
  }
  dq(node) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.removeNode.<anonymous>' call
      var state = this.sp();
      if (state instanceof JobNode) {
        if (!(state === node))
          return Unit_instance;
        if (this.so_1.atomicfu$compareAndSet(state, get_EMPTY_ACTIVE()))
          return Unit_instance;
      } else {
        if (!(state == null) ? isInterface(state, Incomplete) : false) {
          if (!(state.es() == null)) {
            node.js();
          }
          return Unit_instance;
        } else {
          return Unit_instance;
        }
      }
    }
  }
  eq() {
    return false;
  }
  fq(cause) {
    var tmp;
    if (cause == null) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      tmp = JobCancellationException.ty(null == null ? this.cp() : null, null, this);
    } else {
      tmp = cause;
    }
    this.hq(tmp);
  }
  cp() {
    return 'Job was cancelled';
  }
  hq(cause) {
    this.kq(cause);
  }
  iq(parentJob) {
    this.kq(parentJob);
  }
  jq(cause) {
    if (cause instanceof CancellationException)
      return true;
    return this.kq(cause) ? this.pq() : false;
  }
  kq(cause) {
    var finalState = get_COMPLETING_ALREADY();
    if (this.eq()) {
      finalState = cancelMakeCompleting(this, cause);
      if (finalState === get_COMPLETING_WAITING_CHILDREN())
        return true;
    }
    if (finalState === get_COMPLETING_ALREADY()) {
      finalState = makeCancelling(this, cause);
    }
    var tmp;
    if (finalState === get_COMPLETING_ALREADY()) {
      tmp = true;
    } else if (finalState === get_COMPLETING_WAITING_CHILDREN()) {
      tmp = true;
    } else if (finalState === get_TOO_LATE_TO_CANCEL()) {
      tmp = false;
    } else {
      this.jp(finalState);
      tmp = true;
    }
    return tmp;
  }
  lq() {
    var state = this.sp();
    var tmp;
    if (state instanceof Finishing) {
      tmp = state.yy();
    } else {
      if (state instanceof CompletedExceptionally) {
        tmp = state.ep_1;
      } else {
        if (!(state == null) ? isInterface(state, Incomplete) : false) {
          var message = 'Cannot be cancelling child in this state: ' + toString_0(state);
          throw IllegalStateException.l1(toString(message));
        } else {
          tmp = null;
        }
      }
    }
    var rootCause = tmp;
    var tmp1_elvis_lhs = rootCause instanceof CancellationException ? rootCause : null;
    return tmp1_elvis_lhs == null ? JobCancellationException.ty('Parent job is ' + stateString(this, state), rootCause, this) : tmp1_elvis_lhs;
  }
  mw(proposedUpdate) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.makeCompleting.<anonymous>' call
        var state = this.sp();
        var finalState = tryMakeCompleting(this, state, proposedUpdate);
        if (finalState === get_COMPLETING_ALREADY())
          return false;
        else if (finalState === get_COMPLETING_WAITING_CHILDREN())
          return true;
        else if (finalState === get_COMPLETING_RETRY()) {
          break $l$block;
        } else {
          this.jp(finalState);
          return true;
        }
      }
    }
  }
  hp(proposedUpdate) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.makeCompletingOnce.<anonymous>' call
        var state = this.sp();
        var finalState = tryMakeCompleting(this, state, proposedUpdate);
        if (finalState === get_COMPLETING_ALREADY())
          throw IllegalStateException.r8('Job ' + this + ' is already complete or completing, ' + ('but is being completed with ' + toString_0(proposedUpdate)), _get_exceptionOrNull__b3j7js(proposedUpdate, this));
        else if (finalState === get_COMPLETING_RETRY()) {
          break $l$block;
        } else
          return finalState;
      }
    }
  }
  mq(child) {
    var tmp = invokeOnCompletion(this, true, VOID, new ChildHandleNode(child));
    return isInterface(tmp, ChildHandle) ? tmp : THROW_CCE();
  }
  kp(exception) {
    throw exception;
  }
  nq(cause) {
  }
  oq() {
    return false;
  }
  pq() {
    return true;
  }
  qq(exception) {
    return false;
  }
  dp(state) {
  }
  jp(state) {
  }
  toString() {
    return this.rq() + '@' + get_hexAddress(this);
  }
  rq() {
    return this.lp() + '{' + stateString(this, this.sp()) + '}';
  }
  lp() {
    return get_classSimpleName(this);
  }
  sq() {
    var state = this.sp();
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(!(state == null) ? isInterface(state, Incomplete) : false)) {
      // Inline function 'kotlinx.coroutines.JobSupport.getCompletionExceptionOrNull.<anonymous>' call
      var message = 'This job has not completed yet';
      throw IllegalStateException.l1(toString(message));
    }
    return _get_exceptionOrNull__b3j7js(state, this);
  }
  tq() {
    var state = this.sp();
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(!(state == null) ? isInterface(state, Incomplete) : false)) {
      // Inline function 'kotlinx.coroutines.JobSupport.getCompletedInternal.<anonymous>' call
      var message = 'This job has not completed yet';
      throw IllegalStateException.l1(toString(message));
    }
    if (state instanceof CompletedExceptionally)
      throw state.ep_1;
    return unboxState(state);
  }
  uq($completion) {
    $l$loop: while (true) {
      var state = this.sp();
      if (!(!(state == null) ? isInterface(state, Incomplete) : false)) {
        if (state instanceof CompletedExceptionally) {
          // Inline function 'kotlinx.coroutines.internal.recoverAndThrow' call
          throw state.ep_1;
        }
        return unboxState(state);
      }
      if (startInternal(this, state) >= 0)
        break $l$loop;
    }
    return awaitSuspend(this, $completion);
  }
}
class CoroutineScope {}
class AbstractCoroutine extends JobSupport {
  constructor(parentContext, initParentJob, active) {
    super(active);
    if (initParentJob) {
      this.uo(parentContext.bf(Key_instance_3));
    }
    this.xo_1 = parentContext.oi(this);
  }
  af() {
    return this.xo_1;
  }
  yo() {
    return this.xo_1;
  }
  zo() {
    return super.zo();
  }
  ap(value) {
  }
  bp(cause, handled) {
  }
  cp() {
    return get_classSimpleName(this) + ' was cancelled';
  }
  dp(state) {
    if (state instanceof CompletedExceptionally) {
      this.bp(state.ep_1, state.gp());
    } else {
      this.ap((state == null ? true : !(state == null)) ? state : THROW_CCE());
    }
  }
  hf(result) {
    var state = this.hp(toState_0(result));
    if (state === get_COMPLETING_WAITING_CHILDREN())
      return Unit_instance;
    this.ip(state);
  }
  ip(state) {
    return this.jp(state);
  }
  kp(exception) {
    handleCoroutineException(this.xo_1, exception);
  }
  lp() {
    var tmp0_elvis_lhs = get_coroutineName(this.xo_1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return super.lp();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var coroutineName = tmp;
    return '"' + coroutineName + '":' + super.lp();
  }
  mp(start, receiver, block) {
    start.pp(block, receiver, this);
  }
}
class NotCompleted {}
class CancelHandler {}
class DisposeHandlersOnCancel {
  constructor($outer, nodes) {
    this.gr_1 = $outer;
    this.fr_1 = nodes;
  }
  hr() {
    // Inline function 'kotlin.collections.forEach' call
    var indexedObject = this.fr_1;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlinx.coroutines.DisposeHandlersOnCancel.disposeAll.<anonymous>' call
      element.qr().nm();
    }
  }
  rr(cause) {
    this.hr();
  }
  toString() {
    return 'DisposeHandlersOnCancel[' + this.fr_1 + ']';
  }
}
class LinkedListNode {
  constructor($box) {
    boxApply(this, $box);
    this.fs_1 = this;
    this.gs_1 = this;
    this.hs_1 = false;
  }
  is(node) {
    var prev = this.gs_1;
    node.fs_1 = this;
    node.gs_1 = prev;
    prev.fs_1 = node;
    this.gs_1 = node;
  }
  js() {
    return this.ks();
  }
  nm() {
    this.js();
  }
  ks() {
    if (this.hs_1)
      return false;
    var prev = this.gs_1;
    var next = this.fs_1;
    prev.fs_1 = next;
    next.gs_1 = prev;
    this.hs_1 = true;
    return true;
  }
  ls(node) {
    if (!(this.fs_1 === this))
      return false;
    this.is(node);
    return true;
  }
}
class Incomplete {}
class JobNode extends LinkedListNode {
  ds() {
    var tmp = this.cs_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('job');
    }
  }
  zo() {
    return true;
  }
  es() {
    return null;
  }
  nm() {
    return this.ds().dq(this);
  }
  toString() {
    return get_classSimpleName(this) + '@' + get_hexAddress(this) + '[job@' + get_hexAddress(this.ds()) + ']';
  }
}
class AwaitAllNode extends JobNode {
  constructor($outer, continuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.pr_1 = $outer;
    super($box);
    this.mr_1 = continuation;
    this.or_1 = atomic$ref$1(null);
  }
  qr() {
    var tmp = this.nr_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('handle');
    }
  }
  sr(value) {
    this.or_1.kotlinx$atomicfu$value = value;
  }
  tr() {
    return this.or_1.kotlinx$atomicfu$value;
  }
  rr(cause) {
    if (!(cause == null)) {
      var token = this.mr_1.xr(cause);
      if (!(token == null)) {
        this.mr_1.yr(token);
        var tmp0_safe_receiver = this.tr();
        if (tmp0_safe_receiver == null)
          null;
        else {
          tmp0_safe_receiver.hr();
        }
      }
    } else if (this.pr_1.vr_1.atomicfu$decrementAndGet() === 0) {
      // Inline function 'kotlin.coroutines.resume' call
      var this_0 = this.mr_1;
      // Inline function 'kotlin.collections.map' call
      var this_1 = this.pr_1.ur_1;
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList.k3(this_1.length);
      var inductionVariable = 0;
      var last = this_1.length;
      while (inductionVariable < last) {
        var item = this_1[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.coroutines.AwaitAllNode.invoke.<anonymous>' call
        var tmp$ret$0 = item.wr();
        destination.o(tmp$ret$0);
      }
      // Inline function 'kotlin.Companion.success' call
      var tmp$ret$3 = _Result___init__impl__xyqfz8(destination);
      this_0.hf(tmp$ret$3);
    }
  }
}
class AwaitAll {
  constructor(deferreds) {
    this.ur_1 = deferreds;
    this.vr_1 = atomic$int$1(this.ur_1.length);
  }
  ms($completion) {
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var cancellable = new CancellableContinuationImpl(intercepted($completion), get_MODE_CANCELLABLE());
    cancellable.ts();
    // Inline function 'kotlinx.coroutines.AwaitAll.await.<anonymous>' call
    var tmp = 0;
    var tmp_0 = this.ur_1.length;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(tmp_0), null);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var deferred = this.ur_1[tmp_2];
      deferred.up();
      // Inline function 'kotlin.apply' call
      var this_0 = new AwaitAllNode(this, cancellable);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.AwaitAll.await.<anonymous>.<anonymous>' call
      this_0.nr_1 = invokeOnCompletion(deferred, VOID, VOID, this_0);
      tmp_1[tmp_2] = this_0;
      tmp = tmp + 1 | 0;
    }
    var nodes = tmp_1;
    var disposer = new DisposeHandlersOnCancel(this, nodes);
    // Inline function 'kotlin.collections.forEach' call
    var inductionVariable = 0;
    var last = nodes.length;
    while (inductionVariable < last) {
      var element = nodes[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlinx.coroutines.AwaitAll.await.<anonymous>.<anonymous>' call
      element.sr(disposer);
    }
    if (cancellable.tp()) {
      disposer.hr();
    } else {
      invokeOnCancellation(cancellable, disposer);
    }
    return cancellable.us();
  }
}
class $awaitAllCOROUTINE$0 extends CoroutineImpl {
  constructor(_this__u8e3s4, resultContinuation) {
    super(resultContinuation);
    this.dr_1 = _this__u8e3s4;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            if (this.dr_1.z()) {
              this.er_1 = emptyList();
              this.te_1 = 2;
              continue $sm;
            } else {
              this.te_1 = 1;
              var this_0 = this.dr_1;
              suspendResult = (new AwaitAll(copyToArray(this_0))).ms(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

          case 1:
            this.er_1 = suspendResult;
            this.te_1 = 2;
            continue $sm;
          case 2:
            return this.er_1;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class StandaloneCoroutine extends AbstractCoroutine {
  constructor(parentContext, active) {
    super(parentContext, true, active);
  }
  qq(exception) {
    handleCoroutineException(this.xo_1, exception);
    return true;
  }
}
class LazyStandaloneCoroutine extends StandaloneCoroutine {
  constructor(parentContext, block) {
    super(parentContext, false);
    this.ct_1 = createCoroutineUnintercepted(block, this, this);
  }
  vp() {
    startCoroutineCancellable(this.ct_1, this);
  }
}
class $awaitCOROUTINE$1 extends CoroutineImpl {
  constructor(_this__u8e3s4, resultContinuation) {
    super(resultContinuation);
    this.lt_1 = _this__u8e3s4;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.lt_1.uq(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return (suspendResult == null ? true : !(suspendResult == null)) ? suspendResult : THROW_CCE();
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class DeferredCoroutine extends AbstractCoroutine {
  constructor(parentContext, active) {
    super(parentContext, true, active);
  }
  wr() {
    var tmp = this.tq();
    return (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
  }
  pt($completion) {
    var tmp = new $awaitCOROUTINE$1(this, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
}
class LazyDeferredCoroutine extends DeferredCoroutine {
  constructor(parentContext, block) {
    super(parentContext, false);
    this.tt_1 = createCoroutineUnintercepted(block, this, this);
  }
  vp() {
    startCoroutineCancellable(this.tt_1, this);
  }
}
class CancellableContinuation {}
class DisposeOnCancel {
  constructor(handle) {
    this.zt_1 = handle;
  }
  rr(cause) {
    return this.zt_1.nm();
  }
  toString() {
    return 'DisposeOnCancel[' + this.zt_1 + ']';
  }
}
class Runnable {}
class SchedulerTask {}
class DispatchedTask extends SchedulerTask {
  constructor(resumeMode) {
    super();
    this.ju_1 = resumeMode;
  }
  lv(takenState, cause) {
  }
  rv(state) {
    return (state == null ? true : !(state == null)) ? state : THROW_CCE();
  }
  vv(state) {
    var tmp0_safe_receiver = state instanceof CompletedExceptionally ? state : null;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ep_1;
  }
  wv() {
    // Inline function 'kotlinx.coroutines.assert' call
    var taskContext = get_taskContext(this);
    var fatalException = null;
    try {
      var tmp = this.jv();
      var delegate = tmp instanceof DispatchedContinuation ? tmp : THROW_CCE();
      var continuation = delegate.cu_1;
      // Inline function 'kotlinx.coroutines.withContinuationContext' call
      delegate.eu_1;
      var context = continuation.af();
      var state = this.kv();
      var exception = this.vv(state);
      var job = (exception == null ? get_isCancellableMode(this.ju_1) : false) ? context.bf(Key_instance_3) : null;
      var tmp_0;
      if (!(job == null) ? !job.zo() : false) {
        var cause = job.wp();
        this.lv(state, cause);
        // Inline function 'kotlin.Companion.failure' call
        var exception_0 = recoverStackTrace(cause, continuation);
        var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception_0));
        continuation.hf(tmp$ret$0);
        tmp_0 = Unit_instance;
      } else {
        var tmp_1;
        if (!(exception == null)) {
          // Inline function 'kotlin.coroutines.resumeWithException' call
          // Inline function 'kotlin.Companion.failure' call
          var tmp$ret$1 = _Result___init__impl__xyqfz8(createFailure(exception));
          continuation.hf(tmp$ret$1);
          tmp_1 = Unit_instance;
        } else {
          // Inline function 'kotlin.coroutines.resume' call
          // Inline function 'kotlin.Companion.success' call
          var value = this.rv(state);
          var tmp$ret$3 = _Result___init__impl__xyqfz8(value);
          continuation.hf(tmp$ret$3);
          tmp_1 = Unit_instance;
        }
        tmp_0 = tmp_1;
      }
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        fatalException = e;
      } else {
        throw $p;
      }
    }
    finally {
      // Inline function 'kotlin.runCatching' call
      var tmp_2;
      try {
        // Inline function 'kotlin.Companion.success' call
        // Inline function 'kotlinx.coroutines.DispatchedTask.run.<anonymous>' call
        // Inline function 'kotlinx.coroutines.afterTask' call
        tmp_2 = _Result___init__impl__xyqfz8(Unit_instance);
      } catch ($p) {
        var tmp_3;
        if ($p instanceof Error) {
          var e_0 = $p;
          // Inline function 'kotlin.Companion.failure' call
          tmp_3 = _Result___init__impl__xyqfz8(createFailure(e_0));
        } else {
          throw $p;
        }
        tmp_2 = tmp_3;
      }
      var result = tmp_2;
      this.xv(fatalException, Result__exceptionOrNull_impl_p6xea9(result));
    }
  }
  xv(exception, finallyException) {
    if (exception === null ? finallyException === null : false)
      return Unit_instance;
    if (!(exception === null) ? !(finallyException === null) : false) {
      addSuppressed(exception, finallyException);
    }
    var cause = exception == null ? finallyException : exception;
    var reason = CoroutinesInternalError.yx('Fatal exception in coroutines machinery for ' + this + '. ' + "Please read KDoc to 'handleFatalException' method and report this incident to maintainers", ensureNotNull(cause));
    handleCoroutineException(this.jv().af(), reason);
  }
}
class Waiter {}
class CancellableContinuationImpl extends DispatchedTask {
  constructor(delegate, resumeMode) {
    super(resumeMode);
    this.os_1 = delegate;
    // Inline function 'kotlinx.coroutines.assert' call
    this.ps_1 = this.os_1.af();
    var tmp = this;
    // Inline function 'kotlinx.coroutines.decisionAndIndex' call
    var tmp$ret$0 = (0 << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + 536870911 | 0;
    tmp.qs_1 = atomic$int$1(tmp$ret$0);
    this.rs_1 = atomic$ref$1(Active_instance);
    this.ss_1 = atomic$ref$1(null);
  }
  jv() {
    return this.os_1;
  }
  af() {
    return this.ps_1;
  }
  sp() {
    return this.rs_1.kotlinx$atomicfu$value;
  }
  tp() {
    var tmp = this.sp();
    return !(!(tmp == null) ? isInterface(tmp, NotCompleted) : false);
  }
  ut() {
    var tmp = this.sp();
    return tmp instanceof CancelledContinuation;
  }
  ts() {
    var tmp0_elvis_lhs = installParentHandle(this);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var handle = tmp;
    if (this.tp()) {
      handle.nm();
      this.ss_1.kotlinx$atomicfu$value = NonDisposableHandle_instance;
    }
  }
  hu() {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    var state = this.rs_1.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.assert' call
    var tmp;
    if (state instanceof CompletedContinuation) {
      tmp = !(state.xu_1 == null);
    } else {
      tmp = false;
    }
    if (tmp) {
      this.iv();
      return false;
    }
    var tmp_0 = this.qs_1;
    // Inline function 'kotlinx.coroutines.decisionAndIndex' call
    tmp_0.kotlinx$atomicfu$value = (0 << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + 536870911 | 0;
    this.rs_1.kotlinx$atomicfu$value = Active_instance;
    return true;
  }
  kv() {
    return this.sp();
  }
  lv(takenState, cause) {
    var this_0 = this.rs_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancelCompletedResult.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (!(state == null) ? isInterface(state, NotCompleted) : false) {
        // Inline function 'kotlin.error' call
        var message = 'Not completed';
        throw IllegalStateException.l1(toString(message));
      } else {
        if (state instanceof CompletedExceptionally)
          return Unit_instance;
        else {
          if (state instanceof CompletedContinuation) {
            // Inline function 'kotlin.check' call
            // Inline function 'kotlin.contracts.contract' call
            if (!!state.av()) {
              // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancelCompletedResult.<anonymous>.<anonymous>' call
              var message_0 = 'Must be called at most once';
              throw IllegalStateException.l1(toString(message_0));
            }
            var update = state.bv(VOID, VOID, VOID, VOID, cause);
            if (this.rs_1.atomicfu$compareAndSet(state, update)) {
              state.mv(this, cause);
              return Unit_instance;
            }
          } else {
            if (this.rs_1.atomicfu$compareAndSet(state, new CompletedContinuation(state, VOID, VOID, VOID, cause))) {
              return Unit_instance;
            }
          }
        }
      }
    }
    return Unit_instance;
  }
  nv(cause) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.rs_1;
    while (true) {
      $l$block: {
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancel.<anonymous>' call
        var state = this_0.kotlinx$atomicfu$value;
        if (!(!(state == null) ? isInterface(state, NotCompleted) : false))
          return false;
        var tmp;
        if (!(state == null) ? isInterface(state, CancelHandler) : false) {
          tmp = true;
        } else {
          tmp = state instanceof Segment;
        }
        var update = new CancelledContinuation(this, cause, tmp);
        if (!this.rs_1.atomicfu$compareAndSet(state, update)) {
          break $l$block;
        }
        if (isInterface(state, CancelHandler)) {
          this.zu(state, cause);
        } else {
          if (state instanceof Segment) {
            callSegmentOnCancellation(this, state, cause);
          }
        }
        detachChildIfNonResuable(this);
        dispatchResume(this, this.ju_1);
        return true;
      }
    }
  }
  ov(cause) {
    if (cancelLater(this, cause))
      return Unit_instance;
    this.nv(cause);
    detachChildIfNonResuable(this);
  }
  zu(handler, cause) {
    var tmp;
    try {
      handler.rr(cause);
      tmp = Unit_instance;
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var ex = $p;
        handleCoroutineException(this.af(), CompletionHandlerException.tu('Exception in invokeOnCancellation handler for ' + this, ex));
        tmp_0 = Unit_instance;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  dv(onCancellation, cause) {
    try {
      onCancellation(cause);
    } catch ($p) {
      if ($p instanceof Error) {
        var ex = $p;
        handleCoroutineException(this.af(), CompletionHandlerException.tu('Exception in resume onCancellation handler for ' + this, ex));
      } else {
        throw $p;
      }
    }
  }
  pv(parent) {
    return parent.wp();
  }
  us() {
    var isReusable_0 = isReusable(this);
    if (trySuspend(this)) {
      if (_get_parentHandle__f8dcex(this) == null) {
        installParentHandle(this);
      }
      if (isReusable_0) {
        this.qv();
      }
      return get_COROUTINE_SUSPENDED();
    }
    if (isReusable_0) {
      this.qv();
    }
    var state = this.sp();
    if (state instanceof CompletedExceptionally)
      throw recoverStackTrace(state.ep_1, this);
    if (get_isCancellableMode(this.ju_1)) {
      var job = this.af().bf(Key_instance_3);
      if (!(job == null) ? !job.zo() : false) {
        var cause = job.wp();
        this.lv(state, cause);
        throw recoverStackTrace(cause, this);
      }
    }
    return this.rv(state);
  }
  qv() {
    var tmp = this.os_1;
    var tmp0_safe_receiver = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.sv(this);
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var cancellationCause = tmp_0;
    this.iv();
    this.nv(cancellationCause);
  }
  hf(result) {
    return resumeImpl$default(this, toState(result, this), this.ju_1);
  }
  tv(value, onCancellation) {
    return resumeImpl(this, value, this.ju_1, onCancellation);
  }
  uv(segment, index) {
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var this_0 = this.qs_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellation.<anonymous>' call
        // Inline function 'kotlin.check' call
        // Inline function 'kotlinx.coroutines.index' call
        // Inline function 'kotlin.contracts.contract' call
        if (!((cur & 536870911) === 536870911)) {
          // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellation.<anonymous>.<anonymous>' call
          var message = 'invokeOnCancellation should be called at most once';
          throw IllegalStateException.l1(toString(message));
        }
        // Inline function 'kotlinx.coroutines.decisionAndIndex' call
        // Inline function 'kotlinx.coroutines.decision' call
        var upd = (cur >> _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i() << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + index | 0;
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          break $l$block;
        }
      }
    }
    invokeOnCancellationImpl(this, segment);
  }
  wt(handler) {
    return invokeOnCancellation(this, new UserSupplied(handler));
  }
  yt(handler) {
    return invokeOnCancellationImpl(this, handler);
  }
  iv() {
    var tmp0_elvis_lhs = _get_parentHandle__f8dcex(this);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var handle = tmp;
    handle.nm();
    this.ss_1.kotlinx$atomicfu$value = NonDisposableHandle_instance;
  }
  vt(value, idempotent, onCancellation) {
    return tryResumeImpl(this, value, idempotent, onCancellation);
  }
  xr(exception) {
    return tryResumeImpl(this, new CompletedExceptionally(exception), null, null);
  }
  yr(token) {
    // Inline function 'kotlinx.coroutines.assert' call
    dispatchResume(this, this.ju_1);
  }
  xt(_this__u8e3s4, value) {
    var tmp = this.os_1;
    var dc = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp_0;
    if ((dc == null ? null : dc.bu_1) === _this__u8e3s4) {
      tmp_0 = get_MODE_UNDISPATCHED();
    } else {
      tmp_0 = this.ju_1;
    }
    resumeImpl$default(this, value, tmp_0);
  }
  rv(state) {
    var tmp;
    if (state instanceof CompletedContinuation) {
      var tmp_0 = state.uu_1;
      tmp = (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
    } else {
      tmp = (state == null ? true : !(state == null)) ? state : THROW_CCE();
    }
    return tmp;
  }
  vv(state) {
    var tmp0_safe_receiver = super.vv(state);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.getExceptionalResult.<anonymous>' call
      tmp = recoverStackTrace(tmp0_safe_receiver, this.os_1);
    }
    return tmp;
  }
  toString() {
    return this.lp() + '(' + toDebugString(this.os_1) + '){' + _get_stateDebugRepresentation__bf18u4(this) + '}@' + get_hexAddress(this);
  }
  lp() {
    return 'CancellableContinuation';
  }
}
class UserSupplied {
  constructor(handler) {
    this.yv_1 = handler;
  }
  rr(cause) {
    this.yv_1(cause);
  }
  toString() {
    return 'CancelHandler.UserSupplied[' + get_classSimpleName(this.yv_1) + '@' + get_hexAddress(this) + ']';
  }
}
class Active {
  toString() {
    return 'Active';
  }
}
class CompletedContinuation {
  constructor(result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    cancelHandler = cancelHandler === VOID ? null : cancelHandler;
    onCancellation = onCancellation === VOID ? null : onCancellation;
    idempotentResume = idempotentResume === VOID ? null : idempotentResume;
    cancelCause = cancelCause === VOID ? null : cancelCause;
    this.uu_1 = result;
    this.vu_1 = cancelHandler;
    this.wu_1 = onCancellation;
    this.xu_1 = idempotentResume;
    this.yu_1 = cancelCause;
  }
  av() {
    return !(this.yu_1 == null);
  }
  mv(cont, cause) {
    var tmp0_safe_receiver = this.vu_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      cont.zu(tmp0_safe_receiver, cause);
    }
    var tmp1_safe_receiver = this.wu_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      cont.dv(tmp1_safe_receiver, cause);
    }
  }
  zv(result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    return new CompletedContinuation(result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  }
  bv(result, cancelHandler, onCancellation, idempotentResume, cancelCause, $super) {
    result = result === VOID ? this.uu_1 : result;
    cancelHandler = cancelHandler === VOID ? this.vu_1 : cancelHandler;
    onCancellation = onCancellation === VOID ? this.wu_1 : onCancellation;
    idempotentResume = idempotentResume === VOID ? this.xu_1 : idempotentResume;
    cancelCause = cancelCause === VOID ? this.yu_1 : cancelCause;
    return $super === VOID ? this.zv(result, cancelHandler, onCancellation, idempotentResume, cancelCause) : $super.zv.call(this, result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  }
  toString() {
    return 'CompletedContinuation(result=' + toString_0(this.uu_1) + ', cancelHandler=' + this.vu_1 + ', onCancellation=' + this.wu_1 + ', idempotentResume=' + toString_0(this.xu_1) + ', cancelCause=' + this.yu_1 + ')';
  }
  hashCode() {
    var result = this.uu_1 == null ? 0 : hashCode(this.uu_1);
    result = imul(result, 31) + (this.vu_1 == null ? 0 : hashCode(this.vu_1)) | 0;
    result = imul(result, 31) + (this.wu_1 == null ? 0 : hashCode(this.wu_1)) | 0;
    result = imul(result, 31) + (this.xu_1 == null ? 0 : hashCode(this.xu_1)) | 0;
    result = imul(result, 31) + (this.yu_1 == null ? 0 : hashCode(this.yu_1)) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedContinuation))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedContinuation ? other : THROW_CCE();
    if (!equals(this.uu_1, tmp0_other_with_cast.uu_1))
      return false;
    if (!equals(this.vu_1, tmp0_other_with_cast.vu_1))
      return false;
    if (!equals(this.wu_1, tmp0_other_with_cast.wu_1))
      return false;
    if (!equals(this.xu_1, tmp0_other_with_cast.xu_1))
      return false;
    if (!equals(this.yu_1, tmp0_other_with_cast.yu_1))
      return false;
    return true;
  }
}
class $awaitCOROUTINE$2 extends CoroutineImpl {
  constructor(_this__u8e3s4, resultContinuation) {
    super(resultContinuation);
    this.iw_1 = _this__u8e3s4;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.iw_1.uq(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return (suspendResult == null ? true : !(suspendResult == null)) ? suspendResult : THROW_CCE();
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class CompletableDeferredImpl extends JobSupport {
  constructor(parent) {
    super(true);
    this.uo(parent);
  }
  eq() {
    return true;
  }
  wr() {
    var tmp = this.tq();
    return (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
  }
  pt($completion) {
    var tmp = new $awaitCOROUTINE$2(this, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  lw(value) {
    return this.mw(value);
  }
  nw(exception) {
    return this.mw(new CompletedExceptionally(exception));
  }
}
class CompletableJob {}
class UserSupplied_0 {
  constructor(handler) {
    this.qw_1 = handler;
  }
  rr(cause) {
    this.qw_1(cause);
  }
  toString() {
    return 'InternalCompletionHandler.UserSupplied[' + get_classSimpleName(this.qw_1) + '@' + get_hexAddress(this) + ']';
  }
}
class CompletedExceptionally {
  constructor(cause, handled) {
    handled = handled === VOID ? false : handled;
    this.ep_1 = cause;
    this.fp_1 = atomic$boolean$1(handled);
  }
  gp() {
    return this.fp_1.kotlinx$atomicfu$value;
  }
  cv() {
    return this.fp_1.atomicfu$compareAndSet(false, true);
  }
  toString() {
    return get_classSimpleName(this) + '[' + this.ep_1 + ']';
  }
}
class CancelledContinuation extends CompletedExceptionally {
  constructor(continuation, cause, handled) {
    super(cause == null ? CancellationException.p8('Continuation ' + continuation + ' was cancelled normally') : cause, handled);
    this.gv_1 = atomic$boolean$1(false);
  }
  hv() {
    return this.gv_1.atomicfu$compareAndSet(false, true);
  }
}
class CompletedWithCancellation {
  constructor(result, onCancellation) {
    this.rw_1 = result;
    this.sw_1 = onCancellation;
  }
  toString() {
    return 'CompletedWithCancellation(result=' + toString_0(this.rw_1) + ', onCancellation=' + this.sw_1 + ')';
  }
  hashCode() {
    var result = this.rw_1 == null ? 0 : hashCode(this.rw_1);
    result = imul(result, 31) + hashCode(this.sw_1) | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedWithCancellation))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedWithCancellation ? other : THROW_CCE();
    if (!equals(this.rw_1, tmp0_other_with_cast.rw_1))
      return false;
    if (!equals(this.sw_1, tmp0_other_with_cast.sw_1))
      return false;
    return true;
  }
}
class Key extends AbstractCoroutineContextKey {
  constructor() {
    Key_instance_0 = null;
    var tmp = Key_instance;
    super(tmp, CoroutineDispatcher$Key$_init_$lambda_akl8b5);
    Key_instance_0 = this;
  }
}
class CoroutineDispatcher extends AbstractCoroutineContextElement {
  constructor() {
    Key_getInstance();
    super(Key_instance);
  }
  uw(context) {
    return true;
  }
  ef(continuation) {
    return new DispatchedContinuation(this, continuation);
  }
  cf(continuation) {
    var dispatched = continuation instanceof DispatchedContinuation ? continuation : THROW_CCE();
    dispatched.ww();
  }
  toString() {
    return get_classSimpleName(this) + '@' + get_hexAddress(this);
  }
}
class Key_0 {}
class Key_1 {}
class CoroutineName extends AbstractCoroutineContextElement {
  constructor(name) {
    super(Key_instance_2);
    this.zw_1 = name;
  }
  toString() {
    return 'CoroutineName(' + this.zw_1 + ')';
  }
  hashCode() {
    return getStringHashCode(this.zw_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof CoroutineName))
      return false;
    var tmp0_other_with_cast = other instanceof CoroutineName ? other : THROW_CCE();
    if (!(this.zw_1 === tmp0_other_with_cast.zw_1))
      return false;
    return true;
  }
}
class GlobalScope {
  yo() {
    return EmptyCoroutineContext_getInstance();
  }
}
class CoroutineStart extends Enum {
  pp(block, receiver, completion) {
    var tmp;
    switch (this.dd_1) {
      case 0:
        startCoroutineCancellable_0(block, receiver, completion);
        tmp = Unit_instance;
        break;
      case 2:
        startCoroutine(block, receiver, completion);
        tmp = Unit_instance;
        break;
      case 3:
        startCoroutineUndispatched(block, receiver, completion);
        tmp = Unit_instance;
        break;
      case 1:
        tmp = Unit_instance;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  vs() {
    return this === CoroutineStart_LAZY_getInstance();
  }
}
class Delay {}
class EventLoop extends CoroutineDispatcher {
  constructor() {
    super();
    this.hx_1 = new Long(0, 0);
    this.ix_1 = false;
    this.jx_1 = null;
  }
  kx() {
    var tmp0_elvis_lhs = this.jx_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    var tmp1_elvis_lhs = queue.nh();
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return false;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var task = tmp_0;
    task.wv();
    return true;
  }
  lx(task) {
    var tmp0_elvis_lhs = this.jx_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.also' call
      var this_0 = ArrayDeque.jh();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.EventLoop.dispatchUnconfined.<anonymous>' call
      this.jx_1 = this_0;
      tmp = this_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    queue.lh(task);
  }
  mx() {
    return this.hx_1.oc(delta(this, true)) >= 0;
  }
  nx() {
    var tmp0_safe_receiver = this.jx_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.z();
    return tmp1_elvis_lhs == null ? true : tmp1_elvis_lhs;
  }
  ox(unconfined) {
    this.hx_1 = this.hx_1.ee(delta(this, unconfined));
    if (!unconfined)
      this.ix_1 = true;
  }
  px(unconfined) {
    this.hx_1 = this.hx_1.fe(delta(this, unconfined));
    if (this.hx_1.oc(new Long(0, 0)) > 0)
      return Unit_instance;
    // Inline function 'kotlinx.coroutines.assert' call
    if (this.ix_1) {
      this.qx();
    }
  }
  qx() {
  }
}
class ThreadLocalEventLoop {
  constructor() {
    ThreadLocalEventLoop_instance = this;
    this.rx_1 = commonThreadLocal(new Symbol('ThreadLocalEventLoop'));
  }
  sx() {
    var tmp0_elvis_lhs = this.rx_1.ux();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.also' call
      var this_0 = createEventLoop();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.ThreadLocalEventLoop.<get-eventLoop>.<anonymous>' call
      ThreadLocalEventLoop_getInstance().rx_1.vx(this_0);
      tmp = this_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
}
class CompletionHandlerException extends RuntimeException {
  static tu(message, cause) {
    var $this = this.cg(message, cause);
    captureStack($this, $this.su_1);
    return $this;
  }
}
class CoroutinesInternalError extends Error_0 {
  static yx(message, cause) {
    var $this = this.gg(message, cause);
    captureStack($this, $this.xx_1);
    return $this;
  }
}
class Key_2 {}
class ChildHandle {}
class NonDisposableHandle {
  nm() {
  }
  jq(cause) {
    return false;
  }
  toString() {
    return 'NonDisposableHandle';
  }
}
class Empty {
  constructor(isActive) {
    this.zx_1 = isActive;
  }
  zo() {
    return this.zx_1;
  }
  es() {
    return null;
  }
  toString() {
    return 'Empty{' + (this.zx_1 ? 'Active' : 'New') + '}';
  }
}
class LinkedListHead extends LinkedListNode {
  js() {
    throw UnsupportedOperationException.v3();
  }
}
class NodeList extends LinkedListHead {
  zo() {
    return true;
  }
  es() {
    return this;
  }
  dy(state) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder.ma();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.NodeList.getString.<anonymous>' call
    this_0.i7('List{');
    this_0.i7(state);
    this_0.i7('}[');
    var first = true;
    // Inline function 'kotlinx.coroutines.internal.LinkedListHead.forEach' call
    var cur = this.fs_1;
    while (!equals(cur, this)) {
      if (cur instanceof JobNode) {
        // Inline function 'kotlinx.coroutines.NodeList.getString.<anonymous>.<anonymous>' call
        var node = cur;
        if (first)
          first = false;
        else {
          this_0.i7(', ');
        }
        this_0.h7(node);
      }
      cur = cur.fs_1;
    }
    this_0.i7(']');
    return this_0.toString();
  }
  toString() {
    return get_DEBUG() ? this.dy('Active') : super.toString();
  }
}
class SynchronizedObject {}
class Finishing extends SynchronizedObject {
  constructor(list, isCompleting, rootCause) {
    super();
    this.hy_1 = list;
    this.iy_1 = atomic$boolean$1(isCompleting);
    this.jy_1 = atomic$ref$1(rootCause);
    this.ky_1 = atomic$ref$1(null);
  }
  es() {
    return this.hy_1;
  }
  zy(value) {
    this.iy_1.kotlinx$atomicfu$value = value;
  }
  vy() {
    return this.iy_1.kotlinx$atomicfu$value;
  }
  fz(value) {
    this.jy_1.kotlinx$atomicfu$value = value;
  }
  yy() {
    return this.jy_1.kotlinx$atomicfu$value;
  }
  wy() {
    return _get_exceptionsHolder__nhszp(this) === get_SEALED();
  }
  ly() {
    return !(this.yy() == null);
  }
  zo() {
    return this.yy() == null;
  }
  my(proposedException) {
    var eh = _get_exceptionsHolder__nhszp(this);
    var tmp;
    if (eh == null) {
      tmp = allocateList(this);
    } else {
      if (eh instanceof Error) {
        // Inline function 'kotlin.also' call
        var this_0 = allocateList(this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.Finishing.sealLocked.<anonymous>' call
        this_0.o(eh);
        tmp = this_0;
      } else {
        if (eh instanceof ArrayList) {
          tmp = eh instanceof ArrayList ? eh : THROW_CCE();
        } else {
          var message = 'State is ' + toString_0(eh);
          throw IllegalStateException.l1(toString(message));
        }
      }
    }
    var list = tmp;
    var rootCause = this.yy();
    if (rootCause == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      list.u1(0, rootCause);
    }
    if (!(proposedException == null) ? !equals(proposedException, rootCause) : false) {
      list.o(proposedException);
    }
    _set_exceptionsHolder__tqm22h(this, get_SEALED());
    return list;
  }
  xy(exception) {
    var rootCause = this.yy();
    if (rootCause == null) {
      this.fz(exception);
      return Unit_instance;
    }
    if (exception === rootCause)
      return Unit_instance;
    var eh = _get_exceptionsHolder__nhszp(this);
    if (eh == null) {
      _set_exceptionsHolder__tqm22h(this, exception);
    } else {
      if (eh instanceof Error) {
        if (exception === eh)
          return Unit_instance;
        // Inline function 'kotlin.apply' call
        var this_0 = allocateList(this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.Finishing.addExceptionLocked.<anonymous>' call
        this_0.o(eh);
        this_0.o(exception);
        _set_exceptionsHolder__tqm22h(this, this_0);
      } else {
        if (eh instanceof ArrayList) {
          (eh instanceof ArrayList ? eh : THROW_CCE()).o(exception);
        } else {
          var message = 'State is ' + toString_0(eh);
          throw IllegalStateException.l1(toString(message));
        }
      }
    }
  }
  toString() {
    return 'Finishing[cancelling=' + this.ly() + ', completing=' + this.vy() + ', rootCause=' + this.yy() + ', exceptions=' + toString_0(_get_exceptionsHolder__nhszp(this)) + ', list=' + this.hy_1 + ']';
  }
}
class ChildCompletion extends JobNode {
  constructor(parent, state, child, proposedUpdate) {
    super();
    this.kz_1 = parent;
    this.lz_1 = state;
    this.mz_1 = child;
    this.nz_1 = proposedUpdate;
  }
  rr(cause) {
    continueCompleting(this.kz_1, this.lz_1, this.mz_1, this.nz_1);
  }
}
class AwaitContinuation extends CancellableContinuationImpl {
  constructor(delegate, job) {
    super(delegate, get_MODE_CANCELLABLE());
    this.uz_1 = job;
  }
  pv(parent) {
    var state = this.uz_1.sp();
    if (state instanceof Finishing) {
      var tmp0_safe_receiver = state.yy();
      if (tmp0_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        return tmp0_safe_receiver;
      }
    }
    if (state instanceof CompletedExceptionally)
      return state.ep_1;
    return parent.wp();
  }
  lp() {
    return 'AwaitContinuation';
  }
}
class JobCancellingNode extends JobNode {}
class InactiveNodeList {
  constructor(list) {
    this.uy_1 = list;
  }
  es() {
    return this.uy_1;
  }
  zo() {
    return false;
  }
  toString() {
    return get_DEBUG() ? this.uy_1.dy('New') : anyToString(this);
  }
}
class ChildHandleNode extends JobCancellingNode {
  constructor(childJob) {
    super();
    this.ez_1 = childJob;
  }
  rr(cause) {
    return this.ez_1.iq(this.ds());
  }
  jq(cause) {
    return this.ds().jq(cause);
  }
}
class InvokeOnCancelling extends JobCancellingNode {
  constructor(handler) {
    super();
    this.a10_1 = handler;
    this.b10_1 = atomic$int$1(0);
  }
  rr(cause) {
    if (this.b10_1.atomicfu$compareAndSet(0, 1)) {
      this.a10_1.rr(cause);
    }
  }
}
class InvokeOnCompletion extends JobNode {
  constructor(handler) {
    super();
    this.g10_1 = handler;
  }
  rr(cause) {
    return this.g10_1.rr(cause);
  }
}
class ResumeOnCompletion extends JobNode {
  constructor(continuation) {
    super();
    this.l10_1 = continuation;
  }
  rr(cause) {
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = this.l10_1;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    this_0.hf(tmp$ret$0);
    return Unit_instance;
  }
}
class ResumeAwaitOnCompletion extends JobNode {
  constructor(continuation) {
    super();
    this.r10_1 = continuation;
  }
  rr(cause) {
    var state = this.ds().sp();
    // Inline function 'kotlinx.coroutines.assert' call
    if (state instanceof CompletedExceptionally) {
      // Inline function 'kotlin.coroutines.resumeWithException' call
      var this_0 = this.r10_1;
      // Inline function 'kotlin.Companion.failure' call
      var exception = state.ep_1;
      var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception));
      this_0.hf(tmp$ret$0);
    } else {
      // Inline function 'kotlin.coroutines.resume' call
      var this_1 = this.r10_1;
      var tmp = unboxState(state);
      // Inline function 'kotlin.Companion.success' call
      var value = (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
      var tmp$ret$2 = _Result___init__impl__xyqfz8(value);
      this_1.hf(tmp$ret$2);
    }
  }
}
class IncompleteStateBox {
  constructor(state) {
    this.m10_1 = state;
  }
}
class ChildContinuation extends JobCancellingNode {
  constructor(child) {
    super();
    this.w10_1 = child;
  }
  rr(cause) {
    this.w10_1.ov(this.w10_1.pv(this.ds()));
  }
}
class JobImpl extends JobSupport {
  constructor(parent) {
    super(true);
    this.uo(parent);
    this.z10_1 = handlesException(this);
  }
  eq() {
    return true;
  }
  pq() {
    return this.z10_1;
  }
  ow() {
    return this.mw(Unit_instance);
  }
  nw(exception) {
    return this.mw(new CompletedExceptionally(exception));
  }
}
class MainCoroutineDispatcher extends CoroutineDispatcher {
  toString() {
    var tmp0_elvis_lhs = this.c11();
    return tmp0_elvis_lhs == null ? get_classSimpleName(this) + '@' + get_hexAddress(this) : tmp0_elvis_lhs;
  }
  c11() {
    var main = Dispatchers_getInstance().ex();
    if (this === main)
      return 'Dispatchers.Main';
    var tmp;
    try {
      tmp = main.b11();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof UnsupportedOperationException) {
        var e = $p;
        tmp_0 = null;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    var immediate = tmp;
    if (this === immediate)
      return 'Dispatchers.Main.immediate';
    return null;
  }
}
class SupervisorJobImpl extends JobImpl {
  jq(cause) {
    return false;
  }
}
class TimeoutCancellationException extends CancellationException {}
class Unconfined extends CoroutineDispatcher {
  constructor() {
    Unconfined_instance = null;
    super();
    Unconfined_instance = this;
  }
  uw(context) {
    return false;
  }
  vw(context, block) {
    var yieldContext = context.bf(Key_instance_4);
    if (!(yieldContext == null)) {
      yieldContext.i11_1 = true;
      return Unit_instance;
    }
    throw UnsupportedOperationException.r5('Dispatchers.Unconfined.dispatch function can only be used by the yield function. If you wrap Unconfined dispatcher in your code, make sure you properly delegate isDispatchNeeded and dispatch calls.');
  }
  toString() {
    return 'Dispatchers.Unconfined';
  }
}
class Key_3 {}
class BufferOverflow extends Enum {}
class ConcurrentLinkedListNode {
  constructor(prev) {
    this.r12_1 = atomic$ref$1(null);
    this.s12_1 = atomic$ref$1(prev);
  }
  t12() {
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
    var it = _get_nextOrClosed__w0gmuv(this);
    var tmp;
    if (it === get_CLOSED()) {
      return null;
    } else {
      tmp = (it == null ? true : it instanceof ConcurrentLinkedListNode) ? it : THROW_CCE();
    }
    return tmp;
  }
  u12(value) {
    return this.r12_1.atomicfu$compareAndSet(null, value);
  }
  v12() {
    return this.t12() == null;
  }
  w12() {
    return this.s12_1.kotlinx$atomicfu$value;
  }
  x12() {
    // Inline function 'kotlinx.atomicfu.AtomicRef.lazySet' call
    this.s12_1.kotlinx$atomicfu$value = null;
  }
  y12() {
    return this.r12_1.atomicfu$compareAndSet(null, get_CLOSED());
  }
  t() {
    // Inline function 'kotlinx.coroutines.assert' call
    if (this.v12())
      return Unit_instance;
    $l$loop_0: while (true) {
      var prev = _get_aliveSegmentLeft__mr4ndu(this);
      var next = _get_aliveSegmentRight__7ulr0b(this);
      $l$block: {
        // Inline function 'kotlinx.atomicfu.update' call
        var this_0 = next.s12_1;
        while (true) {
          var cur = this_0.kotlinx$atomicfu$value;
          // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.remove.<anonymous>' call
          var upd = cur === null ? null : prev;
          if (this_0.atomicfu$compareAndSet(cur, upd)) {
            break $l$block;
          }
        }
      }
      if (!(prev === null))
        prev.r12_1.kotlinx$atomicfu$value = next;
      if (next.o12() ? !next.v12() : false)
        continue $l$loop_0;
      if (!(prev === null) ? prev.o12() : false)
        continue $l$loop_0;
      return Unit_instance;
    }
  }
}
class Segment extends ConcurrentLinkedListNode {
  constructor(id, prev, pointers) {
    super(prev);
    this.nu_1 = id;
    this.ou_1 = atomic$int$1(pointers << 16);
  }
  o12() {
    return this.ou_1.kotlinx$atomicfu$value === this.q11() ? !this.v12() : false;
  }
  p12() {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.internal.addConditionally' call
      var this_0 = this.ou_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'kotlinx.coroutines.internal.Segment.tryIncPointers.<anonymous>' call
        if (!(!(cur === this.q11()) ? true : this.v12())) {
          tmp$ret$1 = false;
          break $l$block_0;
        }
        if (this_0.atomicfu$compareAndSet(cur, cur + 65536 | 0)) {
          tmp$ret$1 = true;
          break $l$block_0;
        }
      }
    }
    return tmp$ret$1;
  }
  q12() {
    return this.ou_1.atomicfu$addAndGet(-65536) === this.q11() ? !this.v12() : false;
  }
  n12() {
    if (this.ou_1.atomicfu$incrementAndGet() === this.q11()) {
      this.t();
    }
  }
}
class ChannelSegment extends Segment {
  constructor(id, prev, channel, pointers) {
    super(id, prev, pointers);
    this.n11_1 = channel;
    this.o11_1 = atomicfu$AtomicRefArray$ofNulls(imul(get_SEGMENT_SIZE(), 2));
  }
  p11() {
    return ensureNotNull(this.n11_1);
  }
  q11() {
    return get_SEGMENT_SIZE();
  }
  r11(index, element) {
    setElementLazy(this, index, element);
  }
  s11(index) {
    var tmp = this.o11_1.atomicfu$get(imul(index, 2)).kotlinx$atomicfu$value;
    return (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
  }
  t11(index) {
    // Inline function 'kotlin.also' call
    var this_0 = this.s11(index);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.channels.ChannelSegment.retrieveElement.<anonymous>' call
    this.u11(index);
    return this_0;
  }
  u11(index) {
    setElementLazy(this, index, null);
  }
  v11(index) {
    return this.o11_1.atomicfu$get(imul(index, 2) + 1 | 0).kotlinx$atomicfu$value;
  }
  w11(index, value) {
    this.o11_1.atomicfu$get(imul(index, 2) + 1 | 0).kotlinx$atomicfu$value = value;
  }
  x11(index, from, to) {
    return this.o11_1.atomicfu$get(imul(index, 2) + 1 | 0).atomicfu$compareAndSet(from, to);
  }
  y11(index, update) {
    return this.o11_1.atomicfu$get(imul(index, 2) + 1 | 0).atomicfu$getAndSet(update);
  }
  pu(index, cause, context) {
    var isSender = index >= get_SEGMENT_SIZE();
    var index_0 = isSender ? index - get_SEGMENT_SIZE() | 0 : index;
    var element = this.s11(index_0);
    $l$loop: while (true) {
      var cur = this.v11(index_0);
      var tmp;
      if (!(cur == null) ? isInterface(cur, Waiter) : false) {
        tmp = true;
      } else {
        tmp = cur instanceof WaiterEB;
      }
      if (tmp) {
        var update = isSender ? get_INTERRUPTED_SEND() : get_INTERRUPTED_RCV();
        if (this.x11(index_0, cur, update)) {
          this.u11(index_0);
          this.l12(index_0, !isSender);
          if (isSender) {
            var tmp0_safe_receiver = this.p11().a12_1;
            if (tmp0_safe_receiver == null)
              null;
            else {
              callUndeliveredElement(tmp0_safe_receiver, element, context);
            }
          }
          return Unit_instance;
        }
      } else {
        if (cur === get_INTERRUPTED_SEND() ? true : cur === get_INTERRUPTED_RCV()) {
          this.u11(index_0);
          if (isSender) {
            var tmp1_safe_receiver = this.p11().a12_1;
            if (tmp1_safe_receiver == null)
              null;
            else {
              callUndeliveredElement(tmp1_safe_receiver, element, context);
            }
          }
          return Unit_instance;
        } else {
          if (cur === get_RESUMING_BY_EB() ? true : cur === get_RESUMING_BY_RCV())
            continue $l$loop;
          else {
            if (cur === get_DONE_RCV() ? true : cur === get_BUFFERED())
              return Unit_instance;
            else {
              if (cur === get_CHANNEL_CLOSED())
                return Unit_instance;
              else {
                var message = 'unexpected state: ' + toString_0(cur);
                throw IllegalStateException.l1(toString(message));
              }
            }
          }
        }
      }
    }
  }
  l12(index, receiver) {
    if (receiver) {
      var tmp = this.p11();
      // Inline function 'kotlin.Long.plus' call
      // Inline function 'kotlin.Long.times' call
      var this_0 = this.nu_1;
      var other = get_SEGMENT_SIZE();
      var tmp$ret$1 = this_0.ge(toLong(other)).ee(toLong(index));
      tmp.m12(tmp$ret$1);
    }
    this.n12();
  }
}
class $hasNextCOROUTINE$6 extends CoroutineImpl {
  constructor(_this__u8e3s4, resultContinuation) {
    super(resultContinuation);
    this.n13_1 = _this__u8e3s4;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 8;
            this.te_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.p13_1 = this.n13_1.b13_1;
            var tmp_1 = this;
            tmp_1.q13_1 = null;
            this.r13_1 = this.p13_1.g12_1.kotlinx$atomicfu$value;
            this.te_1 = 2;
            continue $sm;
          case 2:
            if (!true) {
              this.te_1 = 9;
              continue $sm;
            }

            if (this.p13_1.e13()) {
              var tmp_2 = this;
              tmp_2.o13_1 = onClosedHasNext(this.n13_1);
              this.te_1 = 10;
              continue $sm;
            } else {
              this.te_1 = 3;
              continue $sm;
            }

          case 3:
            this.s13_1 = this.p13_1.c12_1.atomicfu$getAndIncrement$long();
            var tmp_3 = this;
            var this_0 = this.s13_1;
            var other = get_SEGMENT_SIZE();
            tmp_3.t13_1 = this_0.he(toLong(other));
            var tmp_4 = this;
            var this_1 = this.s13_1;
            var other_0 = get_SEGMENT_SIZE();
            tmp_4.u13_1 = this_1.ie(toLong(other_0)).qe();
            if (!this.r13_1.nu_1.equals(this.t13_1)) {
              this.v13_1 = findSegmentReceive(this.p13_1, this.t13_1, this.r13_1);
              if (this.v13_1 == null) {
                this.te_1 = 2;
                var tmp_5 = this;
                continue $sm;
              } else {
                this.w13_1 = this.v13_1;
                this.te_1 = 4;
                continue $sm;
              }
            } else {
              this.te_1 = 5;
              continue $sm;
            }

          case 4:
            this.r13_1 = this.w13_1;
            this.te_1 = 5;
            continue $sm;
          case 5:
            this.x13_1 = updateCellReceive(this.p13_1, this.r13_1, this.u13_1, this.s13_1, this.q13_1);
            if (this.x13_1 === _get_SUSPEND_$accessor$yt74tm_ccb8g1()) {
              var tmp_6 = this;
              var tmp_7 = this.q13_1;
              var tmp1_safe_receiver = (!(tmp_7 == null) ? isInterface(tmp_7, Waiter) : false) ? tmp_7 : null;
              if (tmp1_safe_receiver == null)
                null;
              else {
                prepareReceiverForSuspension(tmp1_safe_receiver, this.p13_1, this.r13_1, this.u13_1);
              }
              this.r13_1;
              this.u13_1;
              this.s13_1;
              var message = 'unreachable';
              throw IllegalStateException.l1(toString(message));
            } else {
              if (this.x13_1 === _get_FAILED_$accessor$yt74tm_h47uk8()) {
                if (this.s13_1.oc(this.p13_1.d13()) < 0) {
                  this.r13_1.x12();
                }
                this.te_1 = 2;
                var tmp_8 = this;
                continue $sm;
              } else {
                if (this.x13_1 === _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky()) {
                  var tmp_9 = this;
                  tmp_9.z13_1 = this.r13_1;
                  var tmp_10 = this;
                  tmp_10.a14_1 = this.u13_1;
                  var tmp_11 = this;
                  tmp_11.b14_1 = this.s13_1;
                  this.te_1 = 6;
                  suspendResult = hasNextOnNoWaiterSuspend(this.n13_1, this.z13_1, this.a14_1, this.b14_1, this);
                  if (suspendResult === get_COROUTINE_SUSPENDED()) {
                    return suspendResult;
                  }
                  continue $sm;
                } else {
                  var tmp_12 = this;
                  this.r13_1.x12();
                  var tmp_13 = this.x13_1;
                  var element = (tmp_13 == null ? true : !(tmp_13 == null)) ? tmp_13 : THROW_CCE();
                  this.n13_1.z12_1 = element;
                  tmp_12.y13_1 = true;
                  this.te_1 = 7;
                  continue $sm;
                }
              }
            }

          case 6:
            var tmp_14 = this;
            return suspendResult;
          case 7:
            this.o13_1 = this.y13_1;
            this.te_1 = 10;
            continue $sm;
          case 8:
            throw this.we_1;
          case 9:
            if (false) {
              this.te_1 = 1;
              continue $sm;
            }

            this.te_1 = 10;
            continue $sm;
          case 10:
            return this.o13_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 8) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class SendBroadcast {}
class BufferedChannelIterator {
  constructor($outer) {
    this.b13_1 = $outer;
    this.z12_1 = get_NO_RECEIVE_RESULT();
    this.a13_1 = null;
  }
  q14($completion) {
    var tmp = new $hasNextCOROUTINE$6(this, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  uv(segment, index) {
    var tmp0_safe_receiver = this.a13_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.uv(segment, index);
    }
  }
  u() {
    var result = this.z12_1;
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(result === get_NO_RECEIVE_RESULT())) {
      // Inline function 'kotlinx.coroutines.channels.BufferedChannelIterator.next.<anonymous>' call
      var message = '`hasNext()` has not been invoked';
      throw IllegalStateException.l1(toString(message));
    }
    this.z12_1 = get_NO_RECEIVE_RESULT();
    if (result === get_CHANNEL_CLOSED())
      throw recoverStackTrace_0(_get_receiveException__foorc1(this.b13_1));
    return (result == null ? true : !(result == null)) ? result : THROW_CCE();
  }
  f14(element) {
    var cont = ensureNotNull(this.a13_1);
    this.a13_1 = null;
    this.z12_1 = element;
    var tmp0_safe_receiver = this.b13_1.a12_1;
    return tryResume0(cont, true, tmp0_safe_receiver == null ? null : bindCancellationFun(tmp0_safe_receiver, element, cont.af()));
  }
  r14() {
    var cont = ensureNotNull(this.a13_1);
    this.a13_1 = null;
    this.z12_1 = get_CHANNEL_CLOSED();
    var cause = this.b13_1.c13();
    if (cause == null) {
      // Inline function 'kotlin.coroutines.resume' call
      // Inline function 'kotlin.Companion.success' call
      var tmp$ret$0 = _Result___init__impl__xyqfz8(false);
      cont.hf(tmp$ret$0);
    } else {
      // Inline function 'kotlin.coroutines.resumeWithException' call
      // Inline function 'kotlin.Companion.failure' call
      var exception = recoverStackTrace(cause, cont);
      var tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(exception));
      cont.hf(tmp$ret$2);
    }
  }
}
class SendChannel {}
function close$default(cause, $super) {
  cause = cause === VOID ? null : cause;
  return $super === VOID ? this.n15(cause) : $super.n15.call(this, cause);
}
class ReceiveChannel {}
function cancel$default_0(cause, $super) {
  cause = cause === VOID ? null : cause;
  var tmp;
  if ($super === VOID) {
    this.fq(cause);
    tmp = Unit_instance;
  } else {
    tmp = $super.fq.call(this, cause);
  }
  return tmp;
}
class BufferedChannel {
  constructor(capacity, onUndeliveredElement) {
    onUndeliveredElement = onUndeliveredElement === VOID ? null : onUndeliveredElement;
    this.z11_1 = capacity;
    this.a12_1 = onUndeliveredElement;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.z11_1 >= 0)) {
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.<anonymous>' call
      var message = 'Invalid channel capacity: ' + this.z11_1 + ', should be >=0';
      throw IllegalArgumentException.g3(toString(message));
    }
    this.b12_1 = atomic$long$1(new Long(0, 0));
    this.c12_1 = atomic$long$1(new Long(0, 0));
    this.d12_1 = atomic$long$1(initialBufferEnd(this.z11_1));
    this.e12_1 = atomic$long$1(_get_bufferEndCounter__2d4hee(this));
    var firstSegment = new ChannelSegment(new Long(0, 0), null, this, 3);
    this.f12_1 = atomic$ref$1(firstSegment);
    this.g12_1 = atomic$ref$1(firstSegment);
    var tmp = this;
    var tmp_0;
    if (_get_isRendezvousOrUnlimited__3mdufi(this)) {
      var tmp_1 = get_NULL_SEGMENT();
      tmp_0 = tmp_1 instanceof ChannelSegment ? tmp_1 : THROW_CCE();
    } else {
      tmp_0 = firstSegment;
    }
    tmp.h12_1 = atomic$ref$1(tmp_0);
    var tmp_2 = this;
    var tmp_3;
    if (this.a12_1 == null) {
      tmp_3 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.onUndeliveredElementReceiveCancellationConstructor.<anonymous>' call
      tmp_3 = BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda(this);
    }
    tmp_2.i12_1 = tmp_3;
    this.j12_1 = atomic$ref$1(get_NO_CLOSE_CAUSE());
    this.k12_1 = atomic$ref$1(null);
  }
  d13() {
    // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
    return this.b12_1.kotlinx$atomicfu$value.pe(new Long(-1, 268435455));
  }
  e14() {
    return this.c12_1.kotlinx$atomicfu$value;
  }
  e15(element) {
    if (shouldSendSuspend(this, this.b12_1.kotlinx$atomicfu$value))
      return Companion_getInstance_0().f15();
    var tmp$ret$4;
    $l$block_5: {
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl' call
      var waiter = get_INTERRUPTED_SEND();
      var segment = this.f12_1.kotlinx$atomicfu$value;
      $l$loop_0: while (true) {
        var sendersAndCloseStatusCur = this.b12_1.atomicfu$getAndIncrement$long();
        // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
        var s = sendersAndCloseStatusCur.pe(new Long(-1, 268435455));
        var closed = _get_isClosedForSend0__kxgf9m(sendersAndCloseStatusCur, this);
        // Inline function 'kotlin.Long.div' call
        var other = get_SEGMENT_SIZE();
        var id = s.he(toLong(other));
        // Inline function 'kotlin.Long.rem' call
        var other_0 = get_SEGMENT_SIZE();
        var i = s.ie(toLong(other_0)).qe();
        if (!segment.nu_1.equals(id)) {
          var tmp0_elvis_lhs = findSegmentSend(this, id, segment);
          var tmp;
          if (tmp0_elvis_lhs == null) {
            var tmp_0;
            if (closed) {
              // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
              tmp$ret$4 = Companion_getInstance_0().a15(this.b15());
              break $l$block_5;
            } else {
              continue $l$loop_0;
            }
          } else {
            tmp = tmp0_elvis_lhs;
          }
          segment = tmp;
        }
        var tmp1_subject = updateCellSend(this, segment, i, element, s, waiter, closed);
        if (tmp1_subject === _get_RESULT_RENDEZVOUS_$accessor$yt74tm_3irwt8()) {
          segment.x12();
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
          tmp$ret$4 = Companion_getInstance_0().h14(Unit_instance);
          break $l$block_5;
        } else if (tmp1_subject === _get_RESULT_BUFFERED_$accessor$yt74tm_quor5m()) {
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
          tmp$ret$4 = Companion_getInstance_0().h14(Unit_instance);
          break $l$block_5;
        } else if (tmp1_subject === _get_RESULT_SUSPEND_$accessor$yt74tm_cjypnf()) {
          if (closed) {
            segment.n12();
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
            tmp$ret$4 = Companion_getInstance_0().a15(this.b15());
            break $l$block_5;
          }
          var tmp2_safe_receiver = (!(waiter == null) ? isInterface(waiter, Waiter) : false) ? waiter : null;
          if (tmp2_safe_receiver == null)
            null;
          else {
            prepareSenderForSuspension(tmp2_safe_receiver, this, segment, i);
          }
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
          segment.n12();
          tmp$ret$4 = Companion_getInstance_0().f15();
          break $l$block_5;
        } else if (tmp1_subject === _get_RESULT_CLOSED_$accessor$yt74tm_10v48j()) {
          if (s.oc(this.e14()) < 0) {
            segment.x12();
          }
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.trySend.<anonymous>' call
          tmp$ret$4 = Companion_getInstance_0().a15(this.b15());
          break $l$block_5;
        } else if (tmp1_subject === _get_RESULT_FAILED_$accessor$yt74tm_vo1zj0()) {
          segment.x12();
          continue $l$loop_0;
        } else if (tmp1_subject === _get_RESULT_SUSPEND_NO_WAITER_$accessor$yt74tm_cvzv8m()) {
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl.<anonymous>' call
          var message = 'unexpected';
          throw IllegalStateException.l1(toString(message));
        }
      }
    }
    return tmp$ret$4;
  }
  g15(element) {
    var tmp$ret$3;
    $l$block: {
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl' call
      var waiter = get_BUFFERED();
      var segment = this.f12_1.kotlinx$atomicfu$value;
      $l$loop_0: while (true) {
        var sendersAndCloseStatusCur = this.b12_1.atomicfu$getAndIncrement$long();
        // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
        var s = sendersAndCloseStatusCur.pe(new Long(-1, 268435455));
        var closed = _get_isClosedForSend0__kxgf9m(sendersAndCloseStatusCur, this);
        // Inline function 'kotlin.Long.div' call
        var other = get_SEGMENT_SIZE();
        var id = s.he(toLong(other));
        // Inline function 'kotlin.Long.rem' call
        var other_0 = get_SEGMENT_SIZE();
        var i = s.ie(toLong(other_0)).qe();
        if (!segment.nu_1.equals(id)) {
          var tmp0_elvis_lhs = findSegmentSend(this, id, segment);
          var tmp;
          if (tmp0_elvis_lhs == null) {
            var tmp_0;
            if (closed) {
              return Companion_getInstance_0().a15(this.b15());
            } else {
              continue $l$loop_0;
            }
          } else {
            tmp = tmp0_elvis_lhs;
          }
          segment = tmp;
        }
        var tmp1_subject = updateCellSend(this, segment, i, element, s, waiter, closed);
        if (tmp1_subject === _get_RESULT_RENDEZVOUS_$accessor$yt74tm_3irwt8()) {
          segment.x12();
          return Companion_getInstance_0().h14(Unit_instance);
        } else if (tmp1_subject === _get_RESULT_BUFFERED_$accessor$yt74tm_quor5m()) {
          return Companion_getInstance_0().h14(Unit_instance);
        } else if (tmp1_subject === _get_RESULT_SUSPEND_$accessor$yt74tm_cjypnf()) {
          if (closed) {
            segment.n12();
            return Companion_getInstance_0().a15(this.b15());
          }
          var tmp2_safe_receiver = (!(waiter == null) ? isInterface(waiter, Waiter) : false) ? waiter : null;
          if (tmp2_safe_receiver == null)
            null;
          else {
            prepareSenderForSuspension(tmp2_safe_receiver, this, segment, i);
          }
          // Inline function 'kotlin.Long.plus' call
          // Inline function 'kotlin.Long.times' call
          var this_0 = segment.nu_1;
          var other_1 = get_SEGMENT_SIZE();
          var tmp$ret$5 = this_0.ge(toLong(other_1)).ee(toLong(i));
          this.y14(tmp$ret$5);
          return Companion_getInstance_0().h14(Unit_instance);
        } else if (tmp1_subject === _get_RESULT_CLOSED_$accessor$yt74tm_10v48j()) {
          if (s.oc(this.e14()) < 0) {
            segment.x12();
          }
          return Companion_getInstance_0().a15(this.b15());
        } else if (tmp1_subject === _get_RESULT_FAILED_$accessor$yt74tm_vo1zj0()) {
          segment.x12();
          continue $l$loop_0;
        } else if (tmp1_subject === _get_RESULT_SUSPEND_NO_WAITER_$accessor$yt74tm_cvzv8m()) {
          // Inline function 'kotlinx.coroutines.channels.BufferedChannel.sendImpl.<anonymous>' call
          var message = 'unexpected';
          throw IllegalStateException.l1(toString(message));
        }
      }
    }
    return tmp$ret$3;
  }
  k14() {
  }
  c14() {
  }
  y14(globalCellIndex) {
    // Inline function 'kotlinx.coroutines.assert' call
    var segment = this.g12_1.kotlinx$atomicfu$value;
    $l$loop_0: while (true) {
      var r = this.c12_1.kotlinx$atomicfu$value;
      // Inline function 'kotlin.math.max' call
      // Inline function 'kotlin.Long.plus' call
      var other = this.z11_1;
      var a = r.ee(toLong(other));
      var b = _get_bufferEndCounter__2d4hee(this);
      var tmp$ret$1 = a.oc(b) >= 0 ? a : b;
      if (globalCellIndex.oc(tmp$ret$1) < 0)
        return Unit_instance;
      // Inline function 'kotlin.Long.plus' call
      var tmp$ret$2 = r.ee(toLong(1));
      if (!this.c12_1.atomicfu$compareAndSet(r, tmp$ret$2))
        continue $l$loop_0;
      // Inline function 'kotlin.Long.div' call
      var other_0 = get_SEGMENT_SIZE();
      var id = r.he(toLong(other_0));
      // Inline function 'kotlin.Long.rem' call
      var other_1 = get_SEGMENT_SIZE();
      var i = r.ie(toLong(other_1)).qe();
      if (!segment.nu_1.equals(id)) {
        var tmp0_elvis_lhs = findSegmentReceive(this, id, segment);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          continue $l$loop_0;
        } else {
          tmp = tmp0_elvis_lhs;
        }
        segment = tmp;
      }
      var updCellResult = updateCellReceive(this, segment, i, r, null);
      if (updCellResult === _get_FAILED_$accessor$yt74tm_h47uk8()) {
        if (r.oc(this.d13()) < 0) {
          segment.x12();
        }
      } else {
        segment.x12();
        var tmp1_safe_receiver = this.a12_1;
        var tmp_0;
        if (tmp1_safe_receiver == null) {
          tmp_0 = null;
        } else {
          tmp_0 = callUndeliveredElementCatchingException(tmp1_safe_receiver, (updCellResult == null ? true : !(updCellResult == null)) ? updCellResult : THROW_CCE());
        }
        var tmp2_safe_receiver = tmp_0;
        if (tmp2_safe_receiver == null)
          null;
        else {
          // Inline function 'kotlin.let' call
          // Inline function 'kotlin.contracts.contract' call
          throw tmp2_safe_receiver;
        }
      }
    }
  }
  m12(globalIndex) {
    if (_get_isRendezvousOrUnlimited__3mdufi(this))
      return Unit_instance;
    while (_get_bufferEndCounter__2d4hee(this).oc(globalIndex) <= 0) {
    }
    // Inline function 'kotlin.repeat' call
    var times = get_EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS();
    // Inline function 'kotlin.contracts.contract' call
    var inductionVariable = 0;
    if (inductionVariable < times)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.waitExpandBufferCompletion.<anonymous>' call
        var b = _get_bufferEndCounter__2d4hee(this);
        // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
        var ebCompleted = this.e12_1.kotlinx$atomicfu$value.pe(new Long(-1, 1073741823));
        if (b.equals(ebCompleted) ? b.equals(_get_bufferEndCounter__2d4hee(this)) : false)
          return Unit_instance;
      }
       while (inductionVariable < times);
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var this_0 = this.e12_1;
      while (true) {
        var cur = this_0.kotlinx$atomicfu$value;
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.waitExpandBufferCompletion.<anonymous>' call
        // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
        var tmp$ret$1 = cur.pe(new Long(-1, 1073741823));
        var upd = constructEBCompletedAndPauseFlag(tmp$ret$1, true);
        if (this_0.atomicfu$compareAndSet(cur, upd)) {
          break $l$block;
        }
      }
    }
    while (true) {
      var b_0 = _get_bufferEndCounter__2d4hee(this);
      var ebCompletedAndBit = this.e12_1.kotlinx$atomicfu$value;
      // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
      var ebCompleted_0 = ebCompletedAndBit.pe(new Long(-1, 1073741823));
      // Inline function 'kotlinx.coroutines.channels.ebPauseExpandBuffers' call
      var pauseExpandBuffers = !ebCompletedAndBit.pe(new Long(0, 1073741824)).equals(new Long(0, 0));
      if (b_0.equals(ebCompleted_0) ? b_0.equals(_get_bufferEndCounter__2d4hee(this)) : false) {
        $l$block_0: {
          // Inline function 'kotlinx.atomicfu.update' call
          var this_1 = this.e12_1;
          while (true) {
            var cur_0 = this_1.kotlinx$atomicfu$value;
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.waitExpandBufferCompletion.<anonymous>' call
            // Inline function 'kotlinx.coroutines.channels.ebCompletedCounter' call
            var tmp$ret$6 = cur_0.pe(new Long(-1, 1073741823));
            var upd_0 = constructEBCompletedAndPauseFlag(tmp$ret$6, false);
            if (this_1.atomicfu$compareAndSet(cur_0, upd_0)) {
              break $l$block_0;
            }
          }
        }
        return Unit_instance;
      }
      if (!pauseExpandBuffers) {
        this.e12_1.atomicfu$compareAndSet(ebCompletedAndBit, constructEBCompletedAndPauseFlag(ebCompleted_0, true));
      }
    }
  }
  r() {
    return new BufferedChannelIterator(this);
  }
  c13() {
    var tmp = this.j12_1.kotlinx$atomicfu$value;
    return (tmp == null ? true : tmp instanceof Error) ? tmp : THROW_CCE();
  }
  b15() {
    var tmp0_elvis_lhs = this.c13();
    return tmp0_elvis_lhs == null ? ClosedSendChannelException.l15(get_DEFAULT_CLOSE_MESSAGE()) : tmp0_elvis_lhs;
  }
  m15() {
  }
  n15(cause) {
    return this.o15(cause, false);
  }
  fq(cause) {
    this.q15(cause);
  }
  q15(cause) {
    return this.o15(cause == null ? CancellationException.p8('Channel was cancelled') : cause, true);
  }
  o15(cause, cancel) {
    if (cancel) {
      markCancellationStarted(this);
    }
    var closedByThisOperation = this.j12_1.atomicfu$compareAndSet(get_NO_CLOSE_CAUSE(), cause);
    if (cancel) {
      markCancelled(this);
    } else {
      markClosed(this);
    }
    completeCloseOrCancel(this);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.closeOrCancelImpl.<anonymous>' call
    this.m15();
    if (closedByThisOperation) {
      invokeCloseHandler(this);
    }
    return closedByThisOperation;
  }
  z14() {
    return false;
  }
  x14() {
    return _get_isClosedForSend0__kxgf9m(this.b12_1.kotlinx$atomicfu$value, this);
  }
  e13() {
    return _get_isClosedForReceive0__f7qknl(this.b12_1.kotlinx$atomicfu$value, this);
  }
  c15() {
    $l$loop: while (true) {
      var segment = this.g12_1.kotlinx$atomicfu$value;
      var r = this.e14();
      var s = this.d13();
      if (s.oc(r) <= 0)
        return false;
      // Inline function 'kotlin.Long.div' call
      var other = get_SEGMENT_SIZE();
      var id = r.he(toLong(other));
      if (!segment.nu_1.equals(id)) {
        var tmp0_elvis_lhs = findSegmentReceive(this, id, segment);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          var tmp_0;
          if (this.g12_1.kotlinx$atomicfu$value.nu_1.oc(id) < 0) {
            return false;
          } else {
            continue $l$loop;
          }
        } else {
          tmp = tmp0_elvis_lhs;
        }
        segment = tmp;
      }
      segment.x12();
      // Inline function 'kotlin.Long.rem' call
      var other_0 = get_SEGMENT_SIZE();
      var i = r.ie(toLong(other_0)).qe();
      if (isCellNonEmpty(this, segment, i, r))
        return true;
      // Inline function 'kotlin.Long.plus' call
      var tmp$ret$2 = r.ee(toLong(1));
      this.c12_1.atomicfu$compareAndSet(r, tmp$ret$2);
    }
  }
  toString() {
    var sb = StringBuilder.ma();
    // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
    var tmp0_subject = this.b12_1.kotlinx$atomicfu$value.oe(60).qe();
    if (tmp0_subject === 2) {
      sb.i7('closed,');
    } else if (tmp0_subject === 3) {
      sb.i7('cancelled,');
    }
    sb.i7('capacity=' + this.z11_1 + ',');
    sb.i7('data=[');
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlin.collections.minBy' call
      // Inline function 'kotlin.collections.filter' call
      // Inline function 'kotlin.collections.filterTo' call
      var this_0 = listOf([this.g12_1.kotlinx$atomicfu$value, this.f12_1.kotlinx$atomicfu$value, this.h12_1.kotlinx$atomicfu$value]);
      var destination = ArrayList.o3();
      var tmp0_iterator = this_0.r();
      while (tmp0_iterator.s()) {
        var element = tmp0_iterator.u();
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.toString.<anonymous>' call
        if (!(element === get_NULL_SEGMENT())) {
          destination.o(element);
        }
      }
      var iterator = destination.r();
      if (!iterator.s())
        throw NoSuchElementException.g1();
      var minElem = iterator.u();
      if (!iterator.s()) {
        tmp$ret$4 = minElem;
        break $l$block;
      }
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.toString.<anonymous>' call
      var minValue = minElem.nu_1;
      do {
        var e = iterator.u();
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.toString.<anonymous>' call
        var v = e.nu_1;
        if (compareTo(minValue, v) > 0) {
          minElem = e;
          minValue = v;
        }
      }
       while (iterator.s());
      tmp$ret$4 = minElem;
    }
    var firstSegment = tmp$ret$4;
    var r = this.e14();
    var s = this.d13();
    var segment = firstSegment;
    append_elements: while (true) {
      var inductionVariable = 0;
      var last_0 = get_SEGMENT_SIZE();
      if (inductionVariable < last_0)
        process_cell: do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlin.Long.plus' call
          // Inline function 'kotlin.Long.times' call
          var this_1 = segment.nu_1;
          var other = get_SEGMENT_SIZE();
          var globalCellIndex = this_1.ge(toLong(other)).ee(toLong(i));
          if (globalCellIndex.oc(s) >= 0 ? globalCellIndex.oc(r) >= 0 : false)
            break append_elements;
          var cellState = segment.v11(i);
          var element_0 = segment.s11(i);
          var tmp;
          if (!(cellState == null) ? isInterface(cellState, CancellableContinuation) : false) {
            tmp = (globalCellIndex.oc(r) < 0 ? globalCellIndex.oc(s) >= 0 : false) ? 'receive' : (globalCellIndex.oc(s) < 0 ? globalCellIndex.oc(r) >= 0 : false) ? 'send' : 'cont';
          } else {
            if (!(cellState == null) ? isInterface(cellState, SelectInstance) : false) {
              tmp = (globalCellIndex.oc(r) < 0 ? globalCellIndex.oc(s) >= 0 : false) ? 'onReceive' : (globalCellIndex.oc(s) < 0 ? globalCellIndex.oc(r) >= 0 : false) ? 'onSend' : 'select';
            } else {
              if (cellState instanceof ReceiveCatching) {
                tmp = 'receiveCatching';
              } else {
                if (cellState instanceof SendBroadcast) {
                  tmp = 'sendBroadcast';
                } else {
                  if (cellState instanceof WaiterEB) {
                    tmp = 'EB(' + toString_0(cellState) + ')';
                  } else {
                    if (equals(cellState, get_RESUMING_BY_RCV()) ? true : equals(cellState, get_RESUMING_BY_EB())) {
                      tmp = 'resuming_sender';
                    } else {
                      if ((((((cellState == null ? true : equals(cellState, get_IN_BUFFER())) ? true : equals(cellState, get_DONE_RCV())) ? true : equals(cellState, get_POISONED())) ? true : equals(cellState, get_INTERRUPTED_RCV())) ? true : equals(cellState, get_INTERRUPTED_SEND())) ? true : equals(cellState, get_CHANNEL_CLOSED())) {
                        continue process_cell;
                      } else {
                        tmp = toString(cellState);
                      }
                    }
                  }
                }
              }
            }
          }
          var cellStateString = tmp;
          if (!(element_0 == null)) {
            sb.i7('(' + cellStateString + ',' + element_0 + '),');
          } else {
            sb.i7(cellStateString + ',');
          }
        }
         while (inductionVariable < last_0);
      var tmp3_elvis_lhs = segment.t12();
      var tmp_0;
      if (tmp3_elvis_lhs == null) {
        break append_elements;
      } else {
        tmp_0 = tmp3_elvis_lhs;
      }
      segment = tmp_0;
    }
    if (last(sb) === _Char___init__impl__6a9atx(44)) {
      sb.ua(sb.a() - 1 | 0);
    }
    sb.i7(']');
    return sb.toString();
  }
}
class WaiterEB {
  constructor(waiter) {
    this.d14_1 = waiter;
  }
  toString() {
    return 'WaiterEB(' + this.d14_1 + ')';
  }
}
class ReceiveCatching {}
class Factory {
  constructor() {
    Factory_instance = this;
    this.s15_1 = 2147483647;
    this.t15_1 = 0;
    this.u15_1 = -1;
    this.v15_1 = -2;
    this.w15_1 = -3;
    this.x15_1 = 'kotlinx.coroutines.channels.defaultBuffer';
    this.y15_1 = systemProp('kotlinx.coroutines.channels.defaultBuffer', 64, 1, 2147483646);
  }
}
class Failed {
  toString() {
    return 'Failed';
  }
}
class Closed extends Failed {
  constructor(cause) {
    super();
    this.z15_1 = cause;
  }
  equals(other) {
    var tmp;
    if (other instanceof Closed) {
      tmp = equals(this.z15_1, other.z15_1);
    } else {
      tmp = false;
    }
    return tmp;
  }
  hashCode() {
    // Inline function 'kotlin.hashCode' call
    var tmp0_safe_receiver = this.z15_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  }
  toString() {
    return 'Closed(' + this.z15_1 + ')';
  }
}
class Companion {
  constructor() {
    Companion_instance_0 = this;
    this.g14_1 = new Failed();
  }
  h14(value) {
    return _ChannelResult___init__impl__siwsuf(value);
  }
  f15() {
    return _ChannelResult___init__impl__siwsuf(this.g14_1);
  }
  a15(cause) {
    return _ChannelResult___init__impl__siwsuf(new Closed(cause));
  }
}
class ChannelResult {
  constructor(holder) {
    Companion_getInstance_0();
    this.a16_1 = holder;
  }
  toString() {
    return ChannelResult__toString_impl_rrcqu7(this.a16_1);
  }
  hashCode() {
    return ChannelResult__hashCode_impl_lilec2(this.a16_1);
  }
  equals(other) {
    return ChannelResult__equals_impl_f471ri(this.a16_1, other);
  }
}
class ClosedSendChannelException extends IllegalStateException {
  static l15(message) {
    var $this = this.l1(message);
    captureStack($this, $this.k15_1);
    return $this;
  }
}
class ClosedReceiveChannelException extends NoSuchElementException {
  static w14(message) {
    var $this = this.ic(message);
    captureStack($this, $this.v14_1);
    return $this;
  }
}
class ConflatedBufferedChannel extends BufferedChannel {
  constructor(capacity, onBufferOverflow, onUndeliveredElement) {
    onUndeliveredElement = onUndeliveredElement === VOID ? null : onUndeliveredElement;
    super(capacity, onUndeliveredElement);
    this.n16_1 = capacity;
    this.o16_1 = onBufferOverflow;
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!(this.o16_1 === BufferOverflow_SUSPEND_getInstance())) {
      // Inline function 'kotlinx.coroutines.channels.ConflatedBufferedChannel.<anonymous>' call
      var message = 'This implementation does not support suspension for senders, use ' + getKClass(BufferedChannel).u8() + ' instead';
      throw IllegalArgumentException.g3(toString(message));
    }
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.n16_1 >= 1)) {
      // Inline function 'kotlinx.coroutines.channels.ConflatedBufferedChannel.<anonymous>' call
      var message_0 = 'Buffered channel capacity must be at least 1, but ' + this.n16_1 + ' was specified';
      throw IllegalArgumentException.g3(toString(message_0));
    }
  }
  z14() {
    return this.o16_1.equals(BufferOverflow_DROP_OLDEST_getInstance());
  }
  e15(element) {
    return trySendImpl(this, element, false);
  }
}
class $collectCOROUTINE$10 extends CoroutineImpl {
  constructor(_this__u8e3s4, collector, resultContinuation) {
    super(resultContinuation);
    this.x16_1 = _this__u8e3s4;
    this.y16_1 = collector;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            var tmp_0 = this;
            tmp_0.z16_1 = this.y16_1;
            var tmp_1 = this;
            tmp_1.a17_1 = this.x16_1.e17_1;
            this.b17_1 = this.a17_1.r();
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!this.b17_1.s()) {
              this.te_1 = 3;
              continue $sm;
            }

            this.c17_1 = this.b17_1.u();
            var tmp_2 = this;
            tmp_2.d17_1 = this.c17_1;
            this.te_1 = 2;
            suspendResult = this.z16_1.f17(this.d17_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.te_1 = 1;
            continue $sm;
          case 3:
            return Unit_instance;
          case 4:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class _no_name_provided__qut3iv {
  constructor($this_asFlow) {
    this.e17_1 = $this_asFlow;
  }
  g17(collector, $completion) {
    var tmp = new $collectCOROUTINE$10(this, collector, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
}
class $emitCOROUTINE$16 extends CoroutineImpl {
  constructor(_this__u8e3s4, value, resultContinuation) {
    super(resultContinuation);
    this.i18_1 = _this__u8e3s4;
    this.j18_1 = value;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            var tmp_0 = this;
            tmp_0.k18_1 = this.j18_1;
            this.te_1 = 1;
            suspendResult = this.i18_1.m18_1(this.k18_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            if (suspendResult) {
              var tmp_1 = this;
              this.i18_1.n18_1._v = this.k18_1;
              tmp_1.l18_1 = false;
              this.te_1 = 2;
              continue $sm;
            } else {
              var tmp_2 = this;
              tmp_2.l18_1 = true;
              this.te_1 = 2;
              continue $sm;
            }

          case 2:
            var ARGUMENT = this.l18_1;
            if (!ARGUMENT) {
              throw AbortFlowException.o18(this.i18_1);
            } else {
              this.te_1 = 3;
              continue $sm;
            }

          case 3:
            return Unit_instance;
          case 4:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class _no_name_provided__qut3iv_0 {
  constructor($predicate, $result) {
    this.m18_1 = $predicate;
    this.n18_1 = $result;
  }
  f17(value, $completion) {
    var tmp = new $emitCOROUTINE$16(this, value, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
}
class $firstOrNullCOROUTINE$15 extends CoroutineImpl {
  constructor(_this__u8e3s4, predicate, resultContinuation) {
    super(resultContinuation);
    this.v17_1 = _this__u8e3s4;
    this.w17_1 = predicate;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            this.x17_1 = {_v: null};
            var tmp_0 = this;
            tmp_0.y17_1 = this.v17_1;
            var tmp_1 = this;
            tmp_1.z17_1 = new _no_name_provided__qut3iv_0(this.w17_1, this.x17_1);
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.y17_1.g17(this.z17_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.ue_1 = 4;
            this.te_1 = 3;
            continue $sm;
          case 2:
            this.ue_1 = 4;
            var tmp_2 = this.we_1;
            if (tmp_2 instanceof AbortFlowException) {
              var e = this.we_1;
              checkOwnership(e, this.z17_1);
              this.te_1 = 3;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 3:
            this.ue_1 = 4;
            return this.x17_1._v;
          case 4:
            throw this.we_1;
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.ue_1 === 4) {
          throw e_0;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e_0;
        }
      }
     while (true);
  }
}
class OpDescriptor {}
class SegmentOrClosed {
  constructor(value) {
    this.d15_1 = value;
  }
  toString() {
    return SegmentOrClosed__toString_impl_pzb2an(this.d15_1);
  }
  hashCode() {
    return SegmentOrClosed__hashCode_impl_4855hs(this.d15_1);
  }
  equals(other) {
    return SegmentOrClosed__equals_impl_6erq1g(this.d15_1, other);
  }
}
class ExceptionSuccessfullyProcessed extends Exception {}
class DispatchedContinuation extends DispatchedTask {
  constructor(dispatcher, continuation) {
    super(get_MODE_UNINITIALIZED());
    this.bu_1 = dispatcher;
    this.cu_1 = continuation;
    this.du_1 = get_UNDEFINED();
    this.eu_1 = threadContextElements(this.af());
    this.fu_1 = atomic$ref$1(null);
  }
  af() {
    return this.cu_1.af();
  }
  iu() {
    return !(this.fu_1.kotlinx$atomicfu$value == null);
  }
  t18() {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.fu_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.awaitReusability.<anonymous>' call
      if (!(this_0.kotlinx$atomicfu$value === get_REUSABLE_CLAIMED()))
        return Unit_instance;
    }
  }
  ww() {
    this.t18();
    var tmp0_safe_receiver = _get_reusableCancellableContinuation__9qex09(this);
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.iv();
    }
  }
  gu() {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.fu_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.claimReusableCancellableContinuation.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (state === null) {
        this.fu_1.kotlinx$atomicfu$value = get_REUSABLE_CLAIMED();
        return null;
      } else {
        if (state instanceof CancellableContinuationImpl) {
          if (this.fu_1.atomicfu$compareAndSet(state, get_REUSABLE_CLAIMED())) {
            return state instanceof CancellableContinuationImpl ? state : THROW_CCE();
          }
        } else {
          if (state !== get_REUSABLE_CLAIMED()) {
            if (!(state instanceof Error)) {
              var message = 'Inconsistent state ' + toString_0(state);
              throw IllegalStateException.l1(toString(message));
            }
          }
        }
      }
    }
  }
  sv(continuation) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.fu_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.tryReleaseClaimedContinuation.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (state === get_REUSABLE_CLAIMED()) {
        if (this.fu_1.atomicfu$compareAndSet(get_REUSABLE_CLAIMED(), continuation))
          return null;
      } else {
        if (state instanceof Error) {
          // Inline function 'kotlin.require' call
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'kotlin.require' call
          // Inline function 'kotlin.contracts.contract' call
          if (!this.fu_1.atomicfu$compareAndSet(state, null)) {
            // Inline function 'kotlin.require.<anonymous>' call
            var message = 'Failed requirement.';
            throw IllegalArgumentException.g3(toString(message));
          }
          return state;
        } else {
          var message_0 = 'Inconsistent state ' + toString_0(state);
          throw IllegalStateException.l1(toString(message_0));
        }
      }
    }
  }
  ku(cause) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var this_0 = this.fu_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.postponeCancellation.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (equals(state, get_REUSABLE_CLAIMED())) {
        if (this.fu_1.atomicfu$compareAndSet(get_REUSABLE_CLAIMED(), cause))
          return true;
      } else {
        if (state instanceof Error)
          return true;
        else {
          if (this.fu_1.atomicfu$compareAndSet(state, null))
            return false;
        }
      }
    }
  }
  kv() {
    var state = this.du_1;
    // Inline function 'kotlinx.coroutines.assert' call
    this.du_1 = get_UNDEFINED();
    return state;
  }
  jv() {
    return this;
  }
  hf(result) {
    var context = this.cu_1.af();
    var state = toState_0(result);
    if (this.bu_1.uw(context)) {
      this.du_1 = state;
      this.ju_1 = get_MODE_ATOMIC();
      this.bu_1.vw(context, this);
    } else {
      $l$block: {
        // Inline function 'kotlinx.coroutines.internal.executeUnconfined' call
        var mode = get_MODE_ATOMIC();
        // Inline function 'kotlinx.coroutines.assert' call
        var eventLoop = ThreadLocalEventLoop_getInstance().sx();
        if (false ? eventLoop.nx() : false) {
          break $l$block;
        }
        var tmp;
        if (eventLoop.mx()) {
          this.du_1 = state;
          this.ju_1 = mode;
          eventLoop.lx(this);
          tmp = true;
        } else {
          // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
          eventLoop.ox(true);
          try {
            // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeWith.<anonymous>' call
            // Inline function 'kotlinx.coroutines.withCoroutineContext' call
            this.af();
            this.eu_1;
            this.cu_1.hf(result);
            $l$loop: while (eventLoop.kx()) {
            }
          } catch ($p) {
            if ($p instanceof Error) {
              var e = $p;
              this.xv(e, null);
            } else {
              throw $p;
            }
          }
          finally {
            eventLoop.px(true);
          }
          tmp = false;
        }
      }
    }
  }
  lv(takenState, cause) {
    if (takenState instanceof CompletedWithCancellation) {
      takenState.sw_1(cause);
    }
  }
  toString() {
    return 'DispatchedContinuation[' + this.bu_1 + ', ' + toDebugString(this.cu_1) + ']';
  }
}
class UndeliveredElementException extends RuntimeException {
  static x18(message, cause) {
    var $this = this.cg(message, cause);
    captureStack($this, $this.w18_1);
    return $this;
  }
}
class ContextScope {
  constructor(context) {
    this.y18_1 = context;
  }
  yo() {
    return this.y18_1;
  }
  toString() {
    return 'CoroutineScope(coroutineContext=' + this.y18_1 + ')';
  }
}
class Symbol {
  constructor(symbol) {
    this.z18_1 = symbol;
  }
  toString() {
    return '<' + this.z18_1 + '>';
  }
}
class SelectInstance {}
class ClauseData {
  d19(select, internalResult) {
    var tmp0_safe_receiver = this.c19_1;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver(select, this.b19_1, internalResult);
  }
}
class SelectImplementation {
  p14(clauseObject, result) {
    return TrySelectDetailedResult_0(trySelectInternal(this, clauseObject, result));
  }
}
class TrySelectDetailedResult extends Enum {}
class SetTimeoutBasedDispatcher extends CoroutineDispatcher {
  constructor() {
    super();
    this.n19_1 = new ScheduledMessageQueue(this);
  }
  vw(context, block) {
    this.n19_1.c1a(block);
  }
  fx(timeMillis, continuation) {
    var handle = w3cSetTimeout(SetTimeoutBasedDispatcher$scheduleResumeAfterDelay$lambda(continuation, this), delayToInt(timeMillis));
    invokeOnCancellation(continuation, new ClearTimeout(handle));
  }
}
class NodeDispatcher extends SetTimeoutBasedDispatcher {
  constructor() {
    NodeDispatcher_instance = null;
    super();
    NodeDispatcher_instance = this;
  }
  g19() {
    process.nextTick(this.n19_1.l19_1);
  }
}
class MessageQueue {
  constructor() {
    this.o19_1 = ArrayDeque.jh();
    this.p19_1 = 16;
    this.q19_1 = false;
  }
  k() {
    return this.o19_1.gh_1;
  }
  v19(index, element) {
    this.o19_1.u1(index, element);
  }
  u1(index, element) {
    return this.v19(index, (!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  w19(element) {
    return this.o19_1.o(element);
  }
  o(element) {
    return this.w19((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  x19(elements) {
    return this.o19_1.v(elements);
  }
  v(elements) {
    return this.x19(elements);
  }
  w() {
    this.o19_1.w();
  }
  y19(element) {
    return this.o19_1.x(element);
  }
  x(element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return false;
    return this.y19((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  z19(elements) {
    return this.o19_1.y(elements);
  }
  y(elements) {
    return this.z19(elements);
  }
  h1(index) {
    return this.o19_1.h1(index);
  }
  z() {
    return this.o19_1.z();
  }
  r() {
    return this.o19_1.r();
  }
  x1(index) {
    return this.o19_1.x1(index);
  }
  a1a(element) {
    return this.o19_1.p(element);
  }
  p(element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return false;
    return this.a1a((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  n1(index) {
    return this.o19_1.n1(index);
  }
  b1a(index, element) {
    return this.o19_1.l(index, element);
  }
  l(index, element) {
    return this.b1a(index, (!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  }
  c1a(element) {
    this.w19(element);
    if (!this.q19_1) {
      this.q19_1 = true;
      this.s19();
    }
  }
  r19() {
    try {
      // Inline function 'kotlin.repeat' call
      var times = this.p19_1;
      // Inline function 'kotlin.contracts.contract' call
      var inductionVariable = 0;
      if (inductionVariable < times)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlinx.coroutines.MessageQueue.process.<anonymous>' call
          var tmp0_elvis_lhs = removeFirstOrNull(this);
          var tmp;
          if (tmp0_elvis_lhs == null) {
            return Unit_instance;
          } else {
            tmp = tmp0_elvis_lhs;
          }
          var element = tmp;
          element.wv();
        }
         while (inductionVariable < times);
    }finally {
      if (this.z()) {
        this.q19_1 = false;
      } else {
        this.t19();
      }
    }
  }
}
class ScheduledMessageQueue extends MessageQueue {
  constructor(dispatcher) {
    super();
    this.k19_1 = dispatcher;
    var tmp = this;
    tmp.l19_1 = ScheduledMessageQueue$processQueue$lambda(this);
  }
  s19() {
    this.k19_1.g19();
  }
  t19() {
    setTimeout(this.l19_1, 0);
  }
  u19(timeout) {
    setTimeout(this.l19_1, timeout);
  }
}
class WindowMessageQueue extends MessageQueue {
  constructor(window_0) {
    super();
    this.g1a_1 = window_0;
    this.h1a_1 = 'dispatchCoroutine';
    this.g1a_1.addEventListener('message', WindowMessageQueue$lambda(this), true);
  }
  s19() {
    var tmp = Promise.resolve(Unit_instance);
    tmp.then(WindowMessageQueue$schedule$lambda(this));
  }
  t19() {
    this.g1a_1.postMessage(this.h1a_1, '*');
  }
}
class Dispatchers {
  constructor() {
    Dispatchers_instance = this;
    this.ax_1 = createDefaultDispatcher();
    this.bx_1 = Unconfined_getInstance();
    this.cx_1 = new JsMainDispatcher(this.ax_1, false);
    this.dx_1 = null;
  }
  ex() {
    var tmp0_elvis_lhs = this.dx_1;
    return tmp0_elvis_lhs == null ? this.cx_1 : tmp0_elvis_lhs;
  }
}
class JsMainDispatcher extends MainCoroutineDispatcher {
  constructor(delegate, invokeImmediately) {
    super();
    this.j1a_1 = delegate;
    this.k1a_1 = invokeImmediately;
    this.l1a_1 = this.k1a_1 ? this : new JsMainDispatcher(this.j1a_1, true);
  }
  b11() {
    return this.l1a_1;
  }
  uw(context) {
    return !this.k1a_1;
  }
  vw(context, block) {
    return this.j1a_1.vw(context, block);
  }
  toString() {
    var tmp0_elvis_lhs = this.c11();
    return tmp0_elvis_lhs == null ? this.j1a_1.toString() : tmp0_elvis_lhs;
  }
}
class UnconfinedEventLoop extends EventLoop {
  vw(context, block) {
    unsupported();
  }
}
class JobCancellationException extends CancellationException {
  static ty(message, cause, job) {
    var $this = this.q8(message, cause);
    captureStack($this, $this.sy_1);
    $this.ry_1 = job;
    return $this;
  }
  toString() {
    return super.toString() + '; job=' + this.ry_1;
  }
  equals(other) {
    var tmp;
    if (other === this) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      var tmp_2;
      if (other instanceof JobCancellationException) {
        tmp_2 = other.message == this.message;
      } else {
        tmp_2 = false;
      }
      if (tmp_2) {
        tmp_1 = equals(other.ry_1, this.ry_1);
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = equals(other.cause, this.cause);
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  hashCode() {
    var tmp = imul(imul(getStringHashCode(ensureNotNull(this.message)), 31) + hashCode(this.ry_1) | 0, 31);
    var tmp0_safe_receiver = this.cause;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
  }
}
class TaskContext {}
class AbortFlowException extends CancellationException {
  static o18(owner) {
    var $this = this.p8('Flow was aborted, no more elements needed');
    captureStack($this, $this.m17_1);
    $this.l17_1 = owner;
    return $this;
  }
}
class DiagnosticCoroutineContextException extends RuntimeException {
  static s18(context) {
    var $this = this.c6(toString(context));
    captureStack($this, $this.r18_1);
    return $this;
  }
}
class SetTimeoutDispatcher extends SetTimeoutBasedDispatcher {
  constructor() {
    SetTimeoutDispatcher_instance = null;
    super();
    SetTimeoutDispatcher_instance = this;
  }
  g19() {
    this.n19_1.u19(0);
  }
}
class ClearTimeout {
  constructor(handle, $box) {
    boxApply(this, $box);
    this.x1a_1 = handle;
  }
  nm() {
    w3cClearTimeout_0(this.x1a_1);
  }
  rr(cause) {
    this.nm();
  }
  toString() {
    return 'ClearTimeout[' + this.x1a_1 + ']';
  }
}
class WindowClearTimeout extends ClearTimeout {
  constructor($outer, handle, $box) {
    if ($box === VOID)
      $box = {};
    $box.t1a_1 = $outer;
    super(handle, $box);
  }
  nm() {
    w3cClearTimeout(this.t1a_1.v1a_1, this.x1a_1);
  }
}
class WindowDispatcher extends CoroutineDispatcher {
  constructor(window_0) {
    super();
    this.v1a_1 = window_0;
    this.w1a_1 = new WindowMessageQueue(this.v1a_1);
  }
  vw(context, block) {
    return this.w1a_1.c1a(block);
  }
  fx(timeMillis, continuation) {
    var handle = w3cSetTimeout_0(this.v1a_1, WindowDispatcher$scheduleResumeAfterDelay$lambda(continuation, this), delayToInt(timeMillis));
    invokeOnCancellation(continuation, new WindowClearTimeout(this, handle));
  }
}
class CommonThreadLocal {
  constructor() {
    this.tx_1 = null;
  }
  ux() {
    var tmp = this.tx_1;
    return (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
  }
  vx(value) {
    this.tx_1 = value;
  }
}
//endregion
function awaitAll(_this__u8e3s4, $completion) {
  var tmp = new $awaitAllCOROUTINE$0(_this__u8e3s4, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function launch(_this__u8e3s4, context, start, block) {
  context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
  start = start === VOID ? CoroutineStart_DEFAULT_getInstance() : start;
  var newContext = newCoroutineContext(_this__u8e3s4, context);
  var coroutine = start.vs() ? new LazyStandaloneCoroutine(newContext, block) : new StandaloneCoroutine(newContext, true);
  coroutine.mp(start, coroutine, block);
  return coroutine;
}
function async(_this__u8e3s4, context, start, block) {
  context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
  start = start === VOID ? CoroutineStart_DEFAULT_getInstance() : start;
  var newContext = newCoroutineContext(_this__u8e3s4, context);
  var coroutine = start.vs() ? new LazyDeferredCoroutine(newContext, block) : new DeferredCoroutine(newContext, true);
  coroutine.mp(start, coroutine, block);
  return coroutine;
}
function disposeOnCancellation(_this__u8e3s4, handle) {
  return invokeOnCancellation(_this__u8e3s4, new DisposeOnCancel(handle));
}
function invokeOnCancellation(_this__u8e3s4, handler) {
  var tmp;
  if (_this__u8e3s4 instanceof CancellableContinuationImpl) {
    _this__u8e3s4.yt(handler);
    tmp = Unit_instance;
  } else {
    throw UnsupportedOperationException.r5('third-party implementation of CancellableContinuation is not supported');
  }
  return tmp;
}
function getOrCreateCancellableContinuation(delegate) {
  if (!(delegate instanceof DispatchedContinuation)) {
    return new CancellableContinuationImpl(delegate, get_MODE_CANCELLABLE());
  }
  var tmp0_safe_receiver = delegate.gu();
  var tmp;
  if (tmp0_safe_receiver == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.takeIf' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    // Inline function 'kotlinx.coroutines.getOrCreateCancellableContinuation.<anonymous>' call
    if (tmp0_safe_receiver.hu()) {
      tmp_0 = tmp0_safe_receiver;
    } else {
      tmp_0 = null;
    }
    tmp = tmp_0;
  }
  var tmp1_elvis_lhs = tmp;
  var tmp_1;
  if (tmp1_elvis_lhs == null) {
    return new CancellableContinuationImpl(delegate, get_MODE_CANCELLABLE_REUSABLE());
  } else {
    tmp_1 = tmp1_elvis_lhs;
  }
  return tmp_1;
}
function get_RESUME_TOKEN() {
  _init_properties_CancellableContinuationImpl_kt__6rrtdd();
  return RESUME_TOKEN;
}
var RESUME_TOKEN;
function _get_parentHandle__f8dcex($this) {
  return $this.ss_1.kotlinx$atomicfu$value;
}
function _get_stateDebugRepresentation__bf18u4($this) {
  var tmp0_subject = $this.sp();
  var tmp;
  if (!(tmp0_subject == null) ? isInterface(tmp0_subject, NotCompleted) : false) {
    tmp = 'Active';
  } else {
    if (tmp0_subject instanceof CancelledContinuation) {
      tmp = 'Cancelled';
    } else {
      tmp = 'Completed';
    }
  }
  return tmp;
}
function isReusable($this) {
  var tmp;
  if (get_isReusableMode($this.ju_1)) {
    var tmp_0 = $this.os_1;
    tmp = (tmp_0 instanceof DispatchedContinuation ? tmp_0 : THROW_CCE()).iu();
  } else {
    tmp = false;
  }
  return tmp;
}
function cancelLater($this, cause) {
  if (!isReusable($this))
    return false;
  var tmp = $this.os_1;
  var dispatched = tmp instanceof DispatchedContinuation ? tmp : THROW_CCE();
  return dispatched.ku(cause);
}
function callSegmentOnCancellation($this, segment, cause) {
  // Inline function 'kotlinx.coroutines.index' call
  var index = $this.qs_1.kotlinx$atomicfu$value & 536870911;
  // Inline function 'kotlin.check' call
  // Inline function 'kotlin.contracts.contract' call
  if (!!(index === 536870911)) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callSegmentOnCancellation.<anonymous>' call
    var message = 'The index for Segment.onCancellation(..) is broken';
    throw IllegalStateException.l1(toString(message));
  }
  // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callCancelHandlerSafely' call
  try {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callSegmentOnCancellation.<anonymous>' call
    segment.pu(index, cause, $this.af());
  } catch ($p) {
    if ($p instanceof Error) {
      var ex = $p;
      handleCoroutineException($this.af(), CompletionHandlerException.tu('Exception in invokeOnCancellation handler for ' + $this, ex));
    } else {
      throw $p;
    }
  }
}
function trySuspend($this) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.qs_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.trySuspend.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.decision' call
    switch (cur >> _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) {
      case 0:
        // Inline function 'kotlinx.coroutines.decisionAndIndex' call

        // Inline function 'kotlinx.coroutines.index' call

        var index = cur & 536870911;
        var tmp$ret$2 = (1 << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + index | 0;
        if ($this.qs_1.atomicfu$compareAndSet(cur, tmp$ret$2))
          return true;
        break;
      case 2:
        return false;
      default:
        // Inline function 'kotlin.error' call

        var message = 'Already suspended';
        throw IllegalStateException.l1(toString(message));
    }
  }
}
function tryResume($this) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.qs_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.tryResume.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.decision' call
    switch (cur >> _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) {
      case 0:
        // Inline function 'kotlinx.coroutines.decisionAndIndex' call

        // Inline function 'kotlinx.coroutines.index' call

        var index = cur & 536870911;
        var tmp$ret$2 = (2 << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + index | 0;
        if ($this.qs_1.atomicfu$compareAndSet(cur, tmp$ret$2))
          return true;
        break;
      case 1:
        return false;
      default:
        // Inline function 'kotlin.error' call

        var message = 'Already resumed';
        throw IllegalStateException.l1(toString(message));
    }
  }
}
function installParentHandle($this) {
  var tmp0_elvis_lhs = $this.af().bf(Key_instance_3);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var parent = tmp;
  var handle = invokeOnCompletion(parent, true, VOID, new ChildContinuation($this));
  $this.ss_1.atomicfu$compareAndSet(null, handle);
  return handle;
}
function invokeOnCancellationImpl($this, handler) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.rs_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellationImpl.<anonymous>' call
    var state = this_0.kotlinx$atomicfu$value;
    if (state instanceof Active) {
      if ($this.rs_1.atomicfu$compareAndSet(state, handler))
        return Unit_instance;
    } else {
      var tmp;
      if (!(state == null) ? isInterface(state, CancelHandler) : false) {
        tmp = true;
      } else {
        tmp = state instanceof Segment;
      }
      if (tmp) {
        multipleHandlersError($this, handler, state);
      } else {
        if (state instanceof CompletedExceptionally) {
          if (!state.cv()) {
            multipleHandlersError($this, handler, state);
          }
          if (state instanceof CancelledContinuation) {
            var tmp1_safe_receiver = state instanceof CompletedExceptionally ? state : null;
            var cause = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.ep_1;
            if (isInterface(handler, CancelHandler)) {
              $this.zu(handler, cause);
            } else {
              var segment = handler instanceof Segment ? handler : THROW_CCE();
              callSegmentOnCancellation($this, segment, cause);
            }
          }
          return Unit_instance;
        } else {
          if (state instanceof CompletedContinuation) {
            if (!(state.vu_1 == null)) {
              multipleHandlersError($this, handler, state);
            }
            if (handler instanceof Segment)
              return Unit_instance;
            if (!isInterface(handler, CancelHandler))
              THROW_CCE();
            if (state.av()) {
              $this.zu(handler, state.yu_1);
              return Unit_instance;
            }
            var update = state.bv(VOID, handler);
            if ($this.rs_1.atomicfu$compareAndSet(state, update))
              return Unit_instance;
          } else {
            if (handler instanceof Segment)
              return Unit_instance;
            if (!isInterface(handler, CancelHandler))
              THROW_CCE();
            var update_0 = new CompletedContinuation(state, handler);
            if ($this.rs_1.atomicfu$compareAndSet(state, update_0))
              return Unit_instance;
          }
        }
      }
    }
  }
}
function multipleHandlersError($this, handler, state) {
  // Inline function 'kotlin.error' call
  var message = "It's prohibited to register multiple handlers, tried to register " + toString(handler) + ', already has ' + toString_0(state);
  throw IllegalStateException.l1(toString(message));
}
function dispatchResume($this, mode) {
  if (tryResume($this))
    return Unit_instance;
  dispatch($this, mode);
}
function resumedState($this, state, proposedUpdate, resumeMode, onCancellation, idempotent) {
  var tmp;
  if (proposedUpdate instanceof CompletedExceptionally) {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    tmp = proposedUpdate;
  } else {
    if (!get_isCancellableMode(resumeMode) ? idempotent == null : false) {
      tmp = proposedUpdate;
    } else {
      var tmp_0;
      var tmp_1;
      if (!(onCancellation == null)) {
        tmp_1 = true;
      } else {
        tmp_1 = isInterface(state, CancelHandler);
      }
      if (tmp_1) {
        tmp_0 = true;
      } else {
        tmp_0 = !(idempotent == null);
      }
      if (tmp_0) {
        tmp = new CompletedContinuation(proposedUpdate, isInterface(state, CancelHandler) ? state : null, onCancellation, idempotent);
      } else {
        tmp = proposedUpdate;
      }
    }
  }
  return tmp;
}
function resumeImpl($this, proposedUpdate, resumeMode, onCancellation) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.rs_1;
  while (true) {
    $l$block: {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.resumeImpl.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (!(state == null) ? isInterface(state, NotCompleted) : false) {
        var update = resumedState($this, state, proposedUpdate, resumeMode, onCancellation, null);
        if (!$this.rs_1.atomicfu$compareAndSet(state, update)) {
          break $l$block;
        }
        detachChildIfNonResuable($this);
        dispatchResume($this, resumeMode);
        return Unit_instance;
      } else {
        if (state instanceof CancelledContinuation) {
          if (state.hv()) {
            if (onCancellation == null)
              null;
            else {
              // Inline function 'kotlin.let' call
              // Inline function 'kotlin.contracts.contract' call
              $this.dv(onCancellation, state.ep_1);
            }
            return Unit_instance;
          }
        }
      }
      alreadyResumedError($this, proposedUpdate);
    }
  }
}
function resumeImpl$default($this, proposedUpdate, resumeMode, onCancellation, $super) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  return resumeImpl($this, proposedUpdate, resumeMode, onCancellation);
}
function tryResumeImpl($this, proposedUpdate, idempotent, onCancellation) {
  // Inline function 'kotlinx.atomicfu.loop' call
  var this_0 = $this.rs_1;
  while (true) {
    $l$block: {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.tryResumeImpl.<anonymous>' call
      var state = this_0.kotlinx$atomicfu$value;
      if (!(state == null) ? isInterface(state, NotCompleted) : false) {
        var update = resumedState($this, state, proposedUpdate, $this.ju_1, onCancellation, idempotent);
        if (!$this.rs_1.atomicfu$compareAndSet(state, update)) {
          break $l$block;
        }
        detachChildIfNonResuable($this);
        return get_RESUME_TOKEN();
      } else {
        if (state instanceof CompletedContinuation) {
          var tmp;
          if (!(idempotent == null) ? state.xu_1 === idempotent : false) {
            // Inline function 'kotlinx.coroutines.assert' call
            tmp = get_RESUME_TOKEN();
          } else {
            tmp = null;
          }
          return tmp;
        } else {
          return null;
        }
      }
    }
  }
}
function alreadyResumedError($this, proposedUpdate) {
  // Inline function 'kotlin.error' call
  var message = 'Already resumed, but proposed with update ' + toString_0(proposedUpdate);
  throw IllegalStateException.l1(toString(message));
}
function detachChildIfNonResuable($this) {
  if (!isReusable($this)) {
    $this.iv();
  }
}
var Active_instance;
function Active_getInstance() {
  return Active_instance;
}
function _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i() {
  _init_properties_CancellableContinuationImpl_kt__6rrtdd();
  return 29;
}
var properties_initialized_CancellableContinuationImpl_kt_xtzb03;
function _init_properties_CancellableContinuationImpl_kt__6rrtdd() {
  if (!properties_initialized_CancellableContinuationImpl_kt_xtzb03) {
    properties_initialized_CancellableContinuationImpl_kt_xtzb03 = true;
    RESUME_TOKEN = new Symbol('RESUME_TOKEN');
  }
}
function CompletableDeferred(parent) {
  parent = parent === VOID ? null : parent;
  return new CompletableDeferredImpl(parent);
}
function toState(_this__u8e3s4, caller) {
  // Inline function 'kotlin.fold' call
  // Inline function 'kotlin.contracts.contract' call
  var exception = Result__exceptionOrNull_impl_p6xea9(_this__u8e3s4);
  var tmp;
  if (exception == null) {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    var tmp_0 = _Result___get_value__impl__bjfvqg(_this__u8e3s4);
    tmp = (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
  } else {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    tmp = new CompletedExceptionally(recoverStackTrace(exception, caller));
  }
  return tmp;
}
function toState_0(_this__u8e3s4, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  // Inline function 'kotlin.fold' call
  // Inline function 'kotlin.contracts.contract' call
  var exception = Result__exceptionOrNull_impl_p6xea9(_this__u8e3s4);
  var tmp;
  if (exception == null) {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    var tmp_0 = _Result___get_value__impl__bjfvqg(_this__u8e3s4);
    var it = (tmp_0 == null ? true : !(tmp_0 == null)) ? tmp_0 : THROW_CCE();
    tmp = !(onCancellation == null) ? new CompletedWithCancellation(it, onCancellation) : it;
  } else {
    // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
    tmp = new CompletedExceptionally(exception);
  }
  return tmp;
}
function CoroutineDispatcher$Key$_init_$lambda_akl8b5(it) {
  return it instanceof CoroutineDispatcher ? it : null;
}
var Key_instance_0;
function Key_getInstance() {
  if (Key_instance_0 === VOID)
    new Key();
  return Key_instance_0;
}
function handleCoroutineException(context, exception) {
  try {
    var tmp0_safe_receiver = context.bf(Key_instance_1);
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      tmp0_safe_receiver.xw(context, exception);
      return Unit_instance;
    }
  } catch ($p) {
    if ($p instanceof Error) {
      var t = $p;
      handleUncaughtCoroutineException(context, handlerException(exception, t));
      return Unit_instance;
    } else {
      throw $p;
    }
  }
  handleUncaughtCoroutineException(context, exception);
}
var Key_instance_1;
function Key_getInstance_0() {
  return Key_instance_1;
}
function handlerException(originalException, thrownException) {
  if (originalException === thrownException)
    return originalException;
  // Inline function 'kotlin.apply' call
  var this_0 = RuntimeException.cg('Exception while trying to handle coroutine exception', thrownException);
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.handlerException.<anonymous>' call
  addSuppressed(this_0, originalException);
  return this_0;
}
var Key_instance_2;
function Key_getInstance_1() {
  return Key_instance_2;
}
function cancel(_this__u8e3s4, cause) {
  cause = cause === VOID ? null : cause;
  var tmp0_elvis_lhs = _this__u8e3s4.yo().bf(Key_instance_3);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var message = 'Scope cannot be cancelled because it does not have a job: ' + _this__u8e3s4;
    throw IllegalStateException.l1(toString(message));
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var job = tmp;
  job.fq(cause);
}
function cancel_0(_this__u8e3s4, message, cause) {
  cause = cause === VOID ? null : cause;
  return cancel(_this__u8e3s4, CancellationException.q8(message, cause));
}
var GlobalScope_instance;
function GlobalScope_getInstance() {
  return GlobalScope_instance;
}
function MainScope() {
  return new ContextScope(SupervisorJob().oi(Dispatchers_getInstance().ex()));
}
function CoroutineScope_0(context) {
  return new ContextScope(!(context.bf(Key_instance_3) == null) ? context : context.oi(Job_0()));
}
var CoroutineStart_DEFAULT_instance;
var CoroutineStart_LAZY_instance;
var CoroutineStart_ATOMIC_instance;
var CoroutineStart_UNDISPATCHED_instance;
var CoroutineStart_entriesInitialized;
function CoroutineStart_initEntries() {
  if (CoroutineStart_entriesInitialized)
    return Unit_instance;
  CoroutineStart_entriesInitialized = true;
  CoroutineStart_DEFAULT_instance = new CoroutineStart('DEFAULT', 0);
  CoroutineStart_LAZY_instance = new CoroutineStart('LAZY', 1);
  CoroutineStart_ATOMIC_instance = new CoroutineStart('ATOMIC', 2);
  CoroutineStart_UNDISPATCHED_instance = new CoroutineStart('UNDISPATCHED', 3);
}
function CoroutineStart_DEFAULT_getInstance() {
  CoroutineStart_initEntries();
  return CoroutineStart_DEFAULT_instance;
}
function CoroutineStart_LAZY_getInstance() {
  CoroutineStart_initEntries();
  return CoroutineStart_LAZY_instance;
}
function get_delay(_this__u8e3s4) {
  var tmp = _this__u8e3s4.bf(Key_instance);
  var tmp0_elvis_lhs = (!(tmp == null) ? isInterface(tmp, Delay) : false) ? tmp : null;
  return tmp0_elvis_lhs == null ? get_DefaultDelay() : tmp0_elvis_lhs;
}
function delay(timeMillis, $completion) {
  if (timeMillis.oc(new Long(0, 0)) <= 0)
    return Unit_instance;
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), get_MODE_CANCELLABLE());
  cancellable.ts();
  // Inline function 'kotlinx.coroutines.delay.<anonymous>' call
  Companion_getInstance();
  if (timeMillis.oc(new Long(-1, 2147483647)) < 0) {
    get_delay(cancellable.af()).fx(timeMillis, cancellable);
  }
  return cancellable.us();
}
function delta($this, unconfined) {
  return unconfined ? new Long(0, 1) : new Long(1, 0);
}
var ThreadLocalEventLoop_instance;
function ThreadLocalEventLoop_getInstance() {
  if (ThreadLocalEventLoop_instance === VOID)
    new ThreadLocalEventLoop();
  return ThreadLocalEventLoop_instance;
}
var Key_instance_3;
function Key_getInstance_2() {
  return Key_instance_3;
}
var NonDisposableHandle_instance;
function NonDisposableHandle_getInstance() {
  return NonDisposableHandle_instance;
}
function ensureActive(_this__u8e3s4) {
  var tmp0_safe_receiver = _this__u8e3s4.bf(Key_instance_3);
  if (tmp0_safe_receiver == null)
    null;
  else {
    ensureActive_0(tmp0_safe_receiver);
  }
}
function invokeOnCompletion(_this__u8e3s4, onCancelling, invokeImmediately, handler) {
  onCancelling = onCancelling === VOID ? false : onCancelling;
  invokeImmediately = invokeImmediately === VOID ? true : invokeImmediately;
  var tmp;
  if (_this__u8e3s4 instanceof JobSupport) {
    tmp = _this__u8e3s4.cq(onCancelling, invokeImmediately, handler);
  } else {
    tmp = _this__u8e3s4.aq(onCancelling, invokeImmediately, InternalCompletionHandler$invoke$ref(handler));
  }
  return tmp;
}
function ensureActive_0(_this__u8e3s4) {
  if (!_this__u8e3s4.zo())
    throw _this__u8e3s4.wp();
}
function cancel_1(_this__u8e3s4, message, cause) {
  cause = cause === VOID ? null : cause;
  return _this__u8e3s4.fq(CancellationException.q8(message, cause));
}
function Job_0(parent) {
  parent = parent === VOID ? null : parent;
  return new JobImpl(parent);
}
function get_job(_this__u8e3s4) {
  var tmp0_elvis_lhs = _this__u8e3s4.bf(Key_instance_3);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    var message = "Current context doesn't contain Job in it: " + _this__u8e3s4;
    throw IllegalStateException.l1(toString(message));
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function cancel_2(_this__u8e3s4, cause) {
  cause = cause === VOID ? null : cause;
  var tmp0_safe_receiver = _this__u8e3s4.bf(Key_instance_3);
  if (tmp0_safe_receiver == null)
    null;
  else {
    tmp0_safe_receiver.fq(cause);
  }
}
function InternalCompletionHandler$invoke$ref($boundThis) {
  var l = function (p0) {
    $boundThis.rr(p0);
    return Unit_instance;
  };
  l.callableName = 'invoke';
  return l;
}
function get_COMPLETING_ALREADY() {
  _init_properties_JobSupport_kt__68f172();
  return COMPLETING_ALREADY;
}
var COMPLETING_ALREADY;
function get_COMPLETING_WAITING_CHILDREN() {
  _init_properties_JobSupport_kt__68f172();
  return COMPLETING_WAITING_CHILDREN;
}
var COMPLETING_WAITING_CHILDREN;
function get_COMPLETING_RETRY() {
  _init_properties_JobSupport_kt__68f172();
  return COMPLETING_RETRY;
}
var COMPLETING_RETRY;
function get_TOO_LATE_TO_CANCEL() {
  _init_properties_JobSupport_kt__68f172();
  return TOO_LATE_TO_CANCEL;
}
var TOO_LATE_TO_CANCEL;
function get_SEALED() {
  _init_properties_JobSupport_kt__68f172();
  return SEALED;
}
var SEALED;
function get_EMPTY_NEW() {
  _init_properties_JobSupport_kt__68f172();
  return EMPTY_NEW;
}
var EMPTY_NEW;
function get_EMPTY_ACTIVE() {
  _init_properties_JobSupport_kt__68f172();
  return EMPTY_ACTIVE;
}
var EMPTY_ACTIVE;
function _set_exceptionsHolder__tqm22h($this, value) {
  $this.ky_1.kotlinx$atomicfu$value = value;
}
function _get_exceptionsHolder__nhszp($this) {
  return $this.ky_1.kotlinx$atomicfu$value;
}
function allocateList($this) {
  return ArrayList.k3(4);
}
function finalizeFinishingState($this, state, proposedUpdate) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  var tmp0_safe_receiver = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
  var proposedException = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ep_1;
  var wasCancelling;
  // Inline function 'kotlinx.coroutines.internal.synchronized' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
  // Inline function 'kotlinx.coroutines.JobSupport.finalizeFinishingState.<anonymous>' call
  wasCancelling = state.ly();
  var exceptions = state.my(proposedException);
  var finalCause = getFinalRootCause($this, state, exceptions);
  if (!(finalCause == null)) {
    addSuppressedExceptions($this, finalCause, exceptions);
  }
  var finalException = finalCause;
  var finalState = finalException == null ? proposedUpdate : finalException === proposedException ? proposedUpdate : new CompletedExceptionally(finalException);
  if (!(finalException == null)) {
    var handled = cancelParent($this, finalException) ? true : $this.qq(finalException);
    if (handled) {
      (finalState instanceof CompletedExceptionally ? finalState : THROW_CCE()).cv();
    }
  }
  if (!wasCancelling) {
    $this.nq(finalException);
  }
  $this.dp(finalState);
  var casSuccess = $this.so_1.atomicfu$compareAndSet(state, boxIncomplete(finalState));
  // Inline function 'kotlinx.coroutines.assert' call
  completeStateFinalization($this, state, finalState);
  return finalState;
}
function getFinalRootCause($this, state, exceptions) {
  if (exceptions.z()) {
    if (state.ly()) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      return JobCancellationException.ty(null == null ? $this.cp() : null, null, $this);
    }
    return null;
  }
  var tmp$ret$2;
  $l$block: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var tmp0_iterator = exceptions.r();
    while (tmp0_iterator.s()) {
      var element = tmp0_iterator.u();
      // Inline function 'kotlinx.coroutines.JobSupport.getFinalRootCause.<anonymous>' call
      if (!(element instanceof CancellationException)) {
        tmp$ret$2 = element;
        break $l$block;
      }
    }
    tmp$ret$2 = null;
  }
  var firstNonCancellation = tmp$ret$2;
  if (!(firstNonCancellation == null))
    return firstNonCancellation;
  var first = exceptions.h1(0);
  if (first instanceof TimeoutCancellationException) {
    var tmp$ret$4;
    $l$block_0: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator_0 = exceptions.r();
      while (tmp0_iterator_0.s()) {
        var element_0 = tmp0_iterator_0.u();
        // Inline function 'kotlinx.coroutines.JobSupport.getFinalRootCause.<anonymous>' call
        var tmp;
        if (!(element_0 === first)) {
          tmp = element_0 instanceof TimeoutCancellationException;
        } else {
          tmp = false;
        }
        if (tmp) {
          tmp$ret$4 = element_0;
          break $l$block_0;
        }
      }
      tmp$ret$4 = null;
    }
    var detailedTimeoutException = tmp$ret$4;
    if (!(detailedTimeoutException == null))
      return detailedTimeoutException;
  }
  return first;
}
function addSuppressedExceptions($this, rootCause, exceptions) {
  if (exceptions.k() <= 1)
    return Unit_instance;
  var seenExceptions = identitySet(exceptions.k());
  var unwrappedCause = unwrap(rootCause);
  var tmp0_iterator = exceptions.r();
  while (tmp0_iterator.s()) {
    var exception = tmp0_iterator.u();
    var unwrapped = unwrap(exception);
    var tmp;
    var tmp_0;
    if (!(unwrapped === rootCause) ? !(unwrapped === unwrappedCause) : false) {
      tmp_0 = !(unwrapped instanceof CancellationException);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = seenExceptions.o(unwrapped);
    } else {
      tmp = false;
    }
    if (tmp) {
      addSuppressed(rootCause, unwrapped);
    }
  }
}
function tryFinalizeSimpleState($this, state, update) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  if (!$this.so_1.atomicfu$compareAndSet(state, boxIncomplete(update)))
    return false;
  $this.nq(null);
  $this.dp(update);
  completeStateFinalization($this, state, update);
  return true;
}
function completeStateFinalization($this, state, update) {
  var tmp0_safe_receiver = $this.rp();
  if (tmp0_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    tmp0_safe_receiver.nm();
    $this.qp(NonDisposableHandle_instance);
  }
  var tmp1_safe_receiver = update instanceof CompletedExceptionally ? update : null;
  var cause = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.ep_1;
  if (state instanceof JobNode) {
    try {
      state.rr(cause);
    } catch ($p) {
      if ($p instanceof Error) {
        var ex = $p;
        $this.kp(CompletionHandlerException.tu('Exception in completion handler ' + state + ' for ' + $this, ex));
      } else {
        throw $p;
      }
    }
  } else {
    var tmp2_safe_receiver = state.es();
    if (tmp2_safe_receiver == null)
      null;
    else {
      notifyCompletion(tmp2_safe_receiver, $this, cause);
    }
  }
}
function notifyCancelling($this, list, cause) {
  $this.nq(cause);
  // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers' call
  var exception = null;
  // Inline function 'kotlinx.coroutines.internal.LinkedListHead.forEach' call
  var cur = list.fs_1;
  while (!equals(cur, list)) {
    if (cur instanceof JobCancellingNode) {
      // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>' call
      var node = cur;
      try {
        node.rr(cause);
      } catch ($p) {
        if ($p instanceof Error) {
          var ex = $p;
          var tmp0_safe_receiver = exception;
          var tmp;
          if (tmp0_safe_receiver == null) {
            tmp = null;
          } else {
            // Inline function 'kotlin.apply' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>.<anonymous>' call
            addSuppressed(tmp0_safe_receiver, ex);
            tmp = tmp0_safe_receiver;
          }
          if (tmp == null) {
            // Inline function 'kotlin.run' call
            // Inline function 'kotlin.contracts.contract' call
            exception = CompletionHandlerException.tu('Exception in completion handler ' + node + ' for ' + $this, ex);
          }
        } else {
          throw $p;
        }
      }
    }
    cur = cur.fs_1;
  }
  var tmp0_safe_receiver_0 = exception;
  if (tmp0_safe_receiver_0 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    $this.kp(tmp0_safe_receiver_0);
  }
  cancelParent($this, cause);
}
function cancelParent($this, cause) {
  if ($this.oq())
    return true;
  var isCancellation = cause instanceof CancellationException;
  var parent = $this.rp();
  if (parent === null ? true : parent === NonDisposableHandle_instance) {
    return isCancellation;
  }
  return parent.jq(cause) ? true : isCancellation;
}
function notifyCompletion(_this__u8e3s4, $this, cause) {
  var exception = null;
  // Inline function 'kotlinx.coroutines.internal.LinkedListHead.forEach' call
  var cur = _this__u8e3s4.fs_1;
  while (!equals(cur, _this__u8e3s4)) {
    if (cur instanceof JobNode) {
      // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>' call
      var node = cur;
      try {
        node.rr(cause);
      } catch ($p) {
        if ($p instanceof Error) {
          var ex = $p;
          var tmp0_safe_receiver = exception;
          var tmp;
          if (tmp0_safe_receiver == null) {
            tmp = null;
          } else {
            // Inline function 'kotlin.apply' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>.<anonymous>' call
            addSuppressed(tmp0_safe_receiver, ex);
            tmp = tmp0_safe_receiver;
          }
          if (tmp == null) {
            // Inline function 'kotlin.run' call
            // Inline function 'kotlin.contracts.contract' call
            exception = CompletionHandlerException.tu('Exception in completion handler ' + node + ' for ' + $this, ex);
          }
        } else {
          throw $p;
        }
      }
    }
    cur = cur.fs_1;
  }
  var tmp0_safe_receiver_0 = exception;
  if (tmp0_safe_receiver_0 == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    $this.kp(tmp0_safe_receiver_0);
  }
  return Unit_instance;
}
function startInternal($this, state) {
  if (state instanceof Empty) {
    if (state.zx_1)
      return 0;
    if (!$this.so_1.atomicfu$compareAndSet(state, get_EMPTY_ACTIVE()))
      return -1;
    $this.vp();
    return 1;
  } else {
    if (state instanceof InactiveNodeList) {
      if (!$this.so_1.atomicfu$compareAndSet(state, state.uy_1))
        return -1;
      $this.vp();
      return 1;
    } else {
      return 0;
    }
  }
}
function makeNode($this, handler, onCancelling) {
  var tmp;
  if (onCancelling) {
    var tmp0_elvis_lhs = handler instanceof JobCancellingNode ? handler : null;
    tmp = tmp0_elvis_lhs == null ? new InvokeOnCancelling(handler) : tmp0_elvis_lhs;
  } else {
    var tmp1_safe_receiver = handler instanceof JobNode ? handler : null;
    var tmp_0;
    if (tmp1_safe_receiver == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.JobSupport.makeNode.<anonymous>' call
      // Inline function 'kotlinx.coroutines.assert' call
      tmp_0 = tmp1_safe_receiver;
    }
    var tmp2_elvis_lhs = tmp_0;
    tmp = tmp2_elvis_lhs == null ? new InvokeOnCompletion(handler) : tmp2_elvis_lhs;
  }
  var node = tmp;
  node.cs_1 = $this;
  return node;
}
function addLastAtomic($this, expect, list, node) {
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlinx.coroutines.internal.LinkedListNode.addLastIf' call
    // Inline function 'kotlinx.coroutines.JobSupport.addLastAtomic.<anonymous>' call
    if (!($this.sp() === expect)) {
      tmp$ret$1 = false;
      break $l$block;
    }
    list.is(node);
    tmp$ret$1 = true;
  }
  return tmp$ret$1;
}
function promoteEmptyToNodeList($this, state) {
  var list = new NodeList();
  var update = state.zx_1 ? list : new InactiveNodeList(list);
  $this.so_1.atomicfu$compareAndSet(state, update);
}
function promoteSingleToNodeList($this, state) {
  state.ls(new NodeList());
  // Inline function 'kotlinx.coroutines.internal.LinkedListNode.nextNode' call
  var list = state.fs_1;
  $this.so_1.atomicfu$compareAndSet(state, list);
}
function joinInternal($this) {
  // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
  while (true) {
    // Inline function 'kotlinx.coroutines.JobSupport.joinInternal.<anonymous>' call
    var state = $this.sp();
    if (!(!(state == null) ? isInterface(state, Incomplete) : false))
      return false;
    if (startInternal($this, state) >= 0)
      return true;
  }
}
function joinSuspend($this, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), get_MODE_CANCELLABLE());
  cancellable.ts();
  // Inline function 'kotlinx.coroutines.JobSupport.joinSuspend.<anonymous>' call
  disposeOnCancellation(cancellable, invokeOnCompletion($this, VOID, VOID, new ResumeOnCompletion(cancellable)));
  return cancellable.us();
}
function cancelMakeCompleting($this, cause) {
  // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
  while (true) {
    // Inline function 'kotlinx.coroutines.JobSupport.cancelMakeCompleting.<anonymous>' call
    var state = $this.sp();
    var tmp;
    if (!(!(state == null) ? isInterface(state, Incomplete) : false)) {
      tmp = true;
    } else {
      var tmp_0;
      if (state instanceof Finishing) {
        tmp_0 = state.vy();
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    if (tmp) {
      return get_COMPLETING_ALREADY();
    }
    var proposedUpdate = new CompletedExceptionally(createCauseException($this, cause));
    var finalState = tryMakeCompleting($this, state, proposedUpdate);
    if (!(finalState === get_COMPLETING_RETRY()))
      return finalState;
  }
}
function createCauseException($this, cause) {
  var tmp;
  if (cause == null ? true : cause instanceof Error) {
    var tmp_0;
    if (cause == null) {
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      tmp_0 = JobCancellationException.ty(null == null ? $this.cp() : null, null, $this);
    } else {
      tmp_0 = cause;
    }
    tmp = tmp_0;
  } else {
    tmp = ((!(cause == null) ? isInterface(cause, ParentJob) : false) ? cause : THROW_CCE()).lq();
  }
  return tmp;
}
function makeCancelling($this, cause) {
  var causeExceptionCache = null;
  // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
  while (true) {
    $l$block: {
      // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>' call
      var state = $this.sp();
      if (state instanceof Finishing) {
        // Inline function 'kotlinx.coroutines.internal.synchronized' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
        // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>' call
        if (state.wy())
          return get_TOO_LATE_TO_CANCEL();
        var wasCancelling = state.ly();
        if (!(cause == null) ? true : !wasCancelling) {
          var tmp0_elvis_lhs = causeExceptionCache;
          var tmp;
          if (tmp0_elvis_lhs == null) {
            // Inline function 'kotlin.also' call
            var this_0 = createCauseException($this, cause);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>.<anonymous>' call
            causeExceptionCache = this_0;
            tmp = this_0;
          } else {
            tmp = tmp0_elvis_lhs;
          }
          var causeException = tmp;
          state.xy(causeException);
        }
        // Inline function 'kotlin.takeIf' call
        var this_1 = state.yy();
        // Inline function 'kotlin.contracts.contract' call
        var tmp_0;
        // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>.<anonymous>' call
        if (!wasCancelling) {
          tmp_0 = this_1;
        } else {
          tmp_0 = null;
        }
        var notifyRootCause = tmp_0;
        if (notifyRootCause == null)
          null;
        else {
          // Inline function 'kotlin.let' call
          // Inline function 'kotlin.contracts.contract' call
          notifyCancelling($this, state.hy_1, notifyRootCause);
        }
        return get_COMPLETING_ALREADY();
      } else {
        if (!(state == null) ? isInterface(state, Incomplete) : false) {
          var tmp2_elvis_lhs = causeExceptionCache;
          var tmp_1;
          if (tmp2_elvis_lhs == null) {
            // Inline function 'kotlin.also' call
            var this_2 = createCauseException($this, cause);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>' call
            causeExceptionCache = this_2;
            tmp_1 = this_2;
          } else {
            tmp_1 = tmp2_elvis_lhs;
          }
          var causeException_0 = tmp_1;
          if (state.zo()) {
            if (tryMakeCancelling($this, state, causeException_0))
              return get_COMPLETING_ALREADY();
          } else {
            var finalState = tryMakeCompleting($this, state, new CompletedExceptionally(causeException_0));
            if (finalState === get_COMPLETING_ALREADY()) {
              // Inline function 'kotlin.error' call
              var message = 'Cannot happen in ' + toString_0(state);
              throw IllegalStateException.l1(toString(message));
            } else if (finalState === get_COMPLETING_RETRY()) {
              break $l$block;
            } else
              return finalState;
          }
        } else {
          return get_TOO_LATE_TO_CANCEL();
        }
      }
    }
  }
}
function getOrPromoteCancellingList($this, state) {
  var tmp1_elvis_lhs = state.es();
  var tmp;
  if (tmp1_elvis_lhs == null) {
    var tmp_0;
    if (state instanceof Empty) {
      tmp_0 = new NodeList();
    } else {
      if (state instanceof JobNode) {
        promoteSingleToNodeList($this, state);
        tmp_0 = null;
      } else {
        var message = 'State should have list: ' + state;
        throw IllegalStateException.l1(toString(message));
      }
    }
    tmp = tmp_0;
  } else {
    tmp = tmp1_elvis_lhs;
  }
  return tmp;
}
function tryMakeCancelling($this, state, rootCause) {
  // Inline function 'kotlinx.coroutines.assert' call
  // Inline function 'kotlinx.coroutines.assert' call
  var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return false;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var list = tmp;
  var cancelling = new Finishing(list, false, rootCause);
  if (!$this.so_1.atomicfu$compareAndSet(state, cancelling))
    return false;
  notifyCancelling($this, list, rootCause);
  return true;
}
function tryMakeCompleting($this, state, proposedUpdate) {
  if (!(!(state == null) ? isInterface(state, Incomplete) : false))
    return get_COMPLETING_ALREADY();
  var tmp;
  var tmp_0;
  var tmp_1;
  if (state instanceof Empty) {
    tmp_1 = true;
  } else {
    tmp_1 = state instanceof JobNode;
  }
  if (tmp_1) {
    tmp_0 = !(state instanceof ChildHandleNode);
  } else {
    tmp_0 = false;
  }
  if (tmp_0) {
    tmp = !(proposedUpdate instanceof CompletedExceptionally);
  } else {
    tmp = false;
  }
  if (tmp) {
    if (tryFinalizeSimpleState($this, state, proposedUpdate)) {
      return proposedUpdate;
    }
    return get_COMPLETING_RETRY();
  }
  return tryMakeCompletingSlowPath($this, state, proposedUpdate);
}
function tryMakeCompletingSlowPath($this, state, proposedUpdate) {
  var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return get_COMPLETING_RETRY();
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var list = tmp;
  var tmp1_elvis_lhs = state instanceof Finishing ? state : null;
  var finishing = tmp1_elvis_lhs == null ? new Finishing(list, false, null) : tmp1_elvis_lhs;
  var notifyRootCause = null;
  // Inline function 'kotlinx.coroutines.internal.synchronized' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
  if (finishing.vy())
    return get_COMPLETING_ALREADY();
  finishing.zy(true);
  if (!(finishing === state)) {
    if (!$this.so_1.atomicfu$compareAndSet(state, finishing))
      return get_COMPLETING_RETRY();
  }
  // Inline function 'kotlinx.coroutines.assert' call
  var wasCancelling = finishing.ly();
  var tmp0_safe_receiver = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
  if (tmp0_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    finishing.xy(tmp0_safe_receiver.ep_1);
  }
  // Inline function 'kotlin.takeIf' call
  var this_0 = finishing.yy();
  // Inline function 'kotlin.contracts.contract' call
  var tmp_0;
  // Inline function 'kotlinx.coroutines.JobSupport.tryMakeCompletingSlowPath.<anonymous>.<anonymous>' call
  if (!wasCancelling) {
    tmp_0 = this_0;
  } else {
    tmp_0 = null;
  }
  notifyRootCause = tmp_0;
  var tmp2_safe_receiver = notifyRootCause;
  if (tmp2_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    notifyCancelling($this, list, tmp2_safe_receiver);
  }
  var child = firstChild($this, state);
  if (!(child == null) ? tryWaitForChild($this, finishing, child, proposedUpdate) : false)
    return get_COMPLETING_WAITING_CHILDREN();
  return finalizeFinishingState($this, finishing, proposedUpdate);
}
function _get_exceptionOrNull__b3j7js(_this__u8e3s4, $this) {
  var tmp0_safe_receiver = _this__u8e3s4 instanceof CompletedExceptionally ? _this__u8e3s4 : null;
  return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ep_1;
}
function firstChild($this, state) {
  var tmp1_elvis_lhs = state instanceof ChildHandleNode ? state : null;
  var tmp;
  if (tmp1_elvis_lhs == null) {
    var tmp0_safe_receiver = state.es();
    tmp = tmp0_safe_receiver == null ? null : nextChild(tmp0_safe_receiver, $this);
  } else {
    tmp = tmp1_elvis_lhs;
  }
  return tmp;
}
function tryWaitForChild($this, state, child, proposedUpdate) {
  var $this_0 = $this;
  var state_0 = state;
  var child_0 = child;
  var proposedUpdate_0 = proposedUpdate;
  $l$1: do {
    $l$0: do {
      var handle = invokeOnCompletion(child_0.ez_1, VOID, false, new ChildCompletion($this_0, state_0, child_0, proposedUpdate_0));
      if (!(handle === NonDisposableHandle_instance))
        return true;
      var tmp0_elvis_lhs = nextChild(child_0, $this_0);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return false;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var nextChild_0 = tmp;
      $this_0 = $this_0;
      state_0 = state_0;
      child_0 = nextChild_0;
      proposedUpdate_0 = proposedUpdate_0;
      continue $l$0;
    }
     while (false);
  }
   while (true);
}
function continueCompleting($this, state, lastChild, proposedUpdate) {
  // Inline function 'kotlinx.coroutines.assert' call
  var waitChild = nextChild(lastChild, $this);
  if (!(waitChild == null) ? tryWaitForChild($this, state, waitChild, proposedUpdate) : false)
    return Unit_instance;
  var finalState = finalizeFinishingState($this, state, proposedUpdate);
  $this.jp(finalState);
}
function nextChild(_this__u8e3s4, $this) {
  var cur = _this__u8e3s4;
  $l$loop: while (true) {
    // Inline function 'kotlinx.coroutines.internal.LinkedListNode.isRemoved' call
    if (!cur.hs_1) {
      break $l$loop;
    }
    // Inline function 'kotlinx.coroutines.internal.LinkedListNode.prevNode' call
    cur = cur.gs_1;
  }
  $l$loop_0: while (true) {
    // Inline function 'kotlinx.coroutines.internal.LinkedListNode.nextNode' call
    cur = cur.fs_1;
    // Inline function 'kotlinx.coroutines.internal.LinkedListNode.isRemoved' call
    if (cur.hs_1)
      continue $l$loop_0;
    if (cur instanceof ChildHandleNode)
      return cur;
    if (cur instanceof NodeList)
      return null;
  }
}
function stateString($this, state) {
  var tmp;
  if (state instanceof Finishing) {
    tmp = state.ly() ? 'Cancelling' : state.vy() ? 'Completing' : 'Active';
  } else {
    if (!(state == null) ? isInterface(state, Incomplete) : false) {
      tmp = state.zo() ? 'Active' : 'New';
    } else {
      if (state instanceof CompletedExceptionally) {
        tmp = 'Cancelled';
      } else {
        tmp = 'Completed';
      }
    }
  }
  return tmp;
}
function awaitSuspend($this, $completion) {
  // Inline function 'kotlinx.coroutines.JobSupport.awaitSuspend.<anonymous>' call
  var cont = new AwaitContinuation(intercepted($completion), $this);
  cont.ts();
  disposeOnCancellation(cont, invokeOnCompletion($this, VOID, VOID, new ResumeAwaitOnCompletion(cont)));
  return cont.us();
}
function boxIncomplete(_this__u8e3s4) {
  _init_properties_JobSupport_kt__68f172();
  var tmp;
  if (!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Incomplete) : false) {
    tmp = new IncompleteStateBox(_this__u8e3s4);
  } else {
    tmp = _this__u8e3s4;
  }
  return tmp;
}
function unboxState(_this__u8e3s4) {
  _init_properties_JobSupport_kt__68f172();
  var tmp0_safe_receiver = _this__u8e3s4 instanceof IncompleteStateBox ? _this__u8e3s4 : null;
  var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.m10_1;
  return tmp1_elvis_lhs == null ? _this__u8e3s4 : tmp1_elvis_lhs;
}
function handlesException($this) {
  var tmp = $this.rp();
  var tmp0_safe_receiver = tmp instanceof ChildHandleNode ? tmp : null;
  var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ds();
  var tmp_0;
  if (tmp1_elvis_lhs == null) {
    return false;
  } else {
    tmp_0 = tmp1_elvis_lhs;
  }
  var parentJob = tmp_0;
  while (true) {
    if (parentJob.pq())
      return true;
    var tmp_1 = parentJob.rp();
    var tmp2_safe_receiver = tmp_1 instanceof ChildHandleNode ? tmp_1 : null;
    var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.ds();
    var tmp_2;
    if (tmp3_elvis_lhs == null) {
      return false;
    } else {
      tmp_2 = tmp3_elvis_lhs;
    }
    parentJob = tmp_2;
  }
}
var properties_initialized_JobSupport_kt_5iq8a4;
function _init_properties_JobSupport_kt__68f172() {
  if (!properties_initialized_JobSupport_kt_5iq8a4) {
    properties_initialized_JobSupport_kt_5iq8a4 = true;
    COMPLETING_ALREADY = new Symbol('COMPLETING_ALREADY');
    COMPLETING_WAITING_CHILDREN = new Symbol('COMPLETING_WAITING_CHILDREN');
    COMPLETING_RETRY = new Symbol('COMPLETING_RETRY');
    TOO_LATE_TO_CANCEL = new Symbol('TOO_LATE_TO_CANCEL');
    SEALED = new Symbol('SEALED');
    EMPTY_NEW = new Empty(false);
    EMPTY_ACTIVE = new Empty(true);
  }
}
function SupervisorJob(parent) {
  parent = parent === VOID ? null : parent;
  return new SupervisorJobImpl(parent);
}
var Unconfined_instance;
function Unconfined_getInstance() {
  if (Unconfined_instance === VOID)
    new Unconfined();
  return Unconfined_instance;
}
var Key_instance_4;
function Key_getInstance_3() {
  return Key_instance_4;
}
var BufferOverflow_SUSPEND_instance;
var BufferOverflow_DROP_OLDEST_instance;
var BufferOverflow_DROP_LATEST_instance;
var BufferOverflow_entriesInitialized;
function BufferOverflow_initEntries() {
  if (BufferOverflow_entriesInitialized)
    return Unit_instance;
  BufferOverflow_entriesInitialized = true;
  BufferOverflow_SUSPEND_instance = new BufferOverflow('SUSPEND', 0);
  BufferOverflow_DROP_OLDEST_instance = new BufferOverflow('DROP_OLDEST', 1);
  BufferOverflow_DROP_LATEST_instance = new BufferOverflow('DROP_LATEST', 2);
}
function BufferOverflow_SUSPEND_getInstance() {
  BufferOverflow_initEntries();
  return BufferOverflow_SUSPEND_instance;
}
function BufferOverflow_DROP_OLDEST_getInstance() {
  BufferOverflow_initEntries();
  return BufferOverflow_DROP_OLDEST_instance;
}
function BufferOverflow_DROP_LATEST_getInstance() {
  BufferOverflow_initEntries();
  return BufferOverflow_DROP_LATEST_instance;
}
function get_NULL_SEGMENT() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return NULL_SEGMENT;
}
var NULL_SEGMENT;
function get_SEGMENT_SIZE() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return SEGMENT_SIZE;
}
var SEGMENT_SIZE;
function get_EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS;
}
var EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS;
function get_BUFFERED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return BUFFERED;
}
var BUFFERED;
function get_IN_BUFFER() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return IN_BUFFER;
}
var IN_BUFFER;
function get_RESUMING_BY_RCV() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return RESUMING_BY_RCV;
}
var RESUMING_BY_RCV;
function get_RESUMING_BY_EB() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return RESUMING_BY_EB;
}
var RESUMING_BY_EB;
function get_POISONED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return POISONED;
}
var POISONED;
function get_DONE_RCV() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return DONE_RCV;
}
var DONE_RCV;
function get_INTERRUPTED_SEND() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return INTERRUPTED_SEND;
}
var INTERRUPTED_SEND;
function get_INTERRUPTED_RCV() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return INTERRUPTED_RCV;
}
var INTERRUPTED_RCV;
function get_CHANNEL_CLOSED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return CHANNEL_CLOSED;
}
var CHANNEL_CLOSED;
function get_SUSPEND() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return SUSPEND;
}
var SUSPEND;
function get_SUSPEND_NO_WAITER() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return SUSPEND_NO_WAITER;
}
var SUSPEND_NO_WAITER;
function get_FAILED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return FAILED;
}
var FAILED;
function get_NO_RECEIVE_RESULT() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return NO_RECEIVE_RESULT;
}
var NO_RECEIVE_RESULT;
function get_CLOSE_HANDLER_CLOSED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return CLOSE_HANDLER_CLOSED;
}
var CLOSE_HANDLER_CLOSED;
function get_CLOSE_HANDLER_INVOKED() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return CLOSE_HANDLER_INVOKED;
}
var CLOSE_HANDLER_INVOKED;
function get_NO_CLOSE_CAUSE() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return NO_CLOSE_CAUSE;
}
var NO_CLOSE_CAUSE;
function setElementLazy($this, index, value) {
  // Inline function 'kotlinx.atomicfu.AtomicRef.lazySet' call
  $this.o11_1.atomicfu$get(imul(index, 2)).kotlinx$atomicfu$value = value;
}
function onClosedHasNext($this) {
  $this.z12_1 = get_CHANNEL_CLOSED();
  var tmp0_elvis_lhs = $this.b13_1.c13();
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return false;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var cause = tmp;
  throw recoverStackTrace_0(cause);
}
function hasNextOnNoWaiterSuspend($this, segment, index, r, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutineReusable.<anonymous>' call
  var cancellable = getOrCreateCancellableContinuation(intercepted($completion));
  try {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannelIterator.hasNextOnNoWaiterSuspend.<anonymous>' call
    $this.a13_1 = cancellable;
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImplOnNoWaiter' call
    var this_0 = $this.b13_1;
    var updCellResult = updateCellReceive(this_0, segment, index, r, $this);
    if (updCellResult === _get_SUSPEND_$accessor$yt74tm_ccb8g1()) {
      prepareReceiverForSuspension($this, this_0, segment, index);
    } else if (updCellResult === _get_FAILED_$accessor$yt74tm_h47uk8()) {
      if (r.oc(this_0.d13()) < 0) {
        segment.x12();
      }
      $l$block_0: {
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImpl' call
        var segment_0 = this_0.g12_1.kotlinx$atomicfu$value;
        $l$loop_0: while (true) {
          if (this_0.e13()) {
            onClosedHasNextNoWaiterSuspend($this);
            break $l$block_0;
          }
          var r_0 = this_0.c12_1.atomicfu$getAndIncrement$long();
          // Inline function 'kotlin.Long.div' call
          var other = get_SEGMENT_SIZE();
          var id = r_0.he(toLong(other));
          // Inline function 'kotlin.Long.rem' call
          var other_0 = get_SEGMENT_SIZE();
          var i = r_0.ie(toLong(other_0)).qe();
          if (!segment_0.nu_1.equals(id)) {
            var tmp0_elvis_lhs = findSegmentReceive(this_0, id, segment_0);
            var tmp;
            if (tmp0_elvis_lhs == null) {
              continue $l$loop_0;
            } else {
              tmp = tmp0_elvis_lhs;
            }
            segment_0 = tmp;
          }
          var updCellResult_0 = updateCellReceive(this_0, segment_0, i, r_0, $this);
          var tmp_0;
          if (updCellResult_0 === _get_SUSPEND_$accessor$yt74tm_ccb8g1()) {
            var tmp1_safe_receiver = (!($this == null) ? isInterface($this, Waiter) : false) ? $this : null;
            if (tmp1_safe_receiver == null)
              null;
            else {
              prepareReceiverForSuspension(tmp1_safe_receiver, this_0, segment_0, i);
            }
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImplOnNoWaiter.<anonymous>' call
            tmp_0 = Unit_instance;
          } else if (updCellResult_0 === _get_FAILED_$accessor$yt74tm_h47uk8()) {
            if (r_0.oc(this_0.d13()) < 0) {
              segment_0.x12();
            }
            continue $l$loop_0;
          } else if (updCellResult_0 === _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky()) {
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.receiveImpl.<anonymous>' call
            var message = 'unexpected';
            throw IllegalStateException.l1(toString(message));
          } else {
            segment_0.x12();
            var element = (updCellResult_0 == null ? true : !(updCellResult_0 == null)) ? updCellResult_0 : THROW_CCE();
            $this.z12_1 = element;
            $this.a13_1 = null;
            var tmp0_safe_receiver = $this.b13_1.a12_1;
            cancellable.tv(true, tmp0_safe_receiver == null ? null : bindCancellationFun(tmp0_safe_receiver, element, cancellable.af()));
            tmp_0 = Unit_instance;
          }
          break $l$block_0;
        }
      }
    } else {
      segment.x12();
      // Inline function 'kotlinx.coroutines.channels.BufferedChannelIterator.hasNextOnNoWaiterSuspend.<anonymous>.<anonymous>' call
      var element_0 = (updCellResult == null ? true : !(updCellResult == null)) ? updCellResult : THROW_CCE();
      $this.z12_1 = element_0;
      $this.a13_1 = null;
      var tmp0_safe_receiver_0 = $this.b13_1.a12_1;
      cancellable.tv(true, tmp0_safe_receiver_0 == null ? null : bindCancellationFun(tmp0_safe_receiver_0, element_0, cancellable.af()));
    }
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      cancellable.qv();
      throw e;
    } else {
      throw $p;
    }
  }
  return cancellable.us();
}
function onClosedHasNextNoWaiterSuspend($this) {
  var cont = ensureNotNull($this.a13_1);
  $this.a13_1 = null;
  $this.z12_1 = get_CHANNEL_CLOSED();
  var cause = $this.b13_1.c13();
  if (cause == null) {
    // Inline function 'kotlin.coroutines.resume' call
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(false);
    cont.hf(tmp$ret$0);
  } else {
    // Inline function 'kotlin.coroutines.resumeWithException' call
    // Inline function 'kotlin.Companion.failure' call
    var exception = recoverStackTrace(cause, cont);
    var tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(exception));
    cont.hf(tmp$ret$2);
  }
}
function _get_bufferEndCounter__2d4hee($this) {
  return $this.d12_1.kotlinx$atomicfu$value;
}
function _get_isRendezvousOrUnlimited__3mdufi($this) {
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.<get-isRendezvousOrUnlimited>.<anonymous>' call
  var it = _get_bufferEndCounter__2d4hee($this);
  return it.equals(new Long(0, 0)) ? true : it.equals(new Long(-1, 2147483647));
}
function prepareSenderForSuspension(_this__u8e3s4, $this, segment, index) {
  _this__u8e3s4.uv(segment, index + get_SEGMENT_SIZE() | 0);
}
function updateCellSend($this, segment, index, element, s, waiter, closed) {
  segment.r11(index, element);
  if (closed)
    return updateCellSendSlow($this, segment, index, element, s, waiter, closed);
  var state = segment.v11(index);
  if (state === null) {
    if (bufferOrRendezvousSend($this, s)) {
      if (segment.x11(index, null, get_BUFFERED())) {
        return _get_RESULT_BUFFERED_$accessor$yt74tm_quor5m();
      }
    } else {
      if (waiter == null) {
        return _get_RESULT_SUSPEND_NO_WAITER_$accessor$yt74tm_cvzv8m();
      } else {
        if (segment.x11(index, null, waiter))
          return _get_RESULT_SUSPEND_$accessor$yt74tm_cjypnf();
      }
    }
  } else {
    if (!(state == null) ? isInterface(state, Waiter) : false) {
      segment.u11(index);
      var tmp;
      if (tryResumeReceiver(state, $this, element)) {
        segment.w11(index, get_DONE_RCV());
        $this.c14();
        tmp = _get_RESULT_RENDEZVOUS_$accessor$yt74tm_3irwt8();
      } else {
        if (!(segment.y11(index, get_INTERRUPTED_RCV()) === get_INTERRUPTED_RCV())) {
          segment.l12(index, true);
        }
        tmp = _get_RESULT_FAILED_$accessor$yt74tm_vo1zj0();
      }
      return tmp;
    }
  }
  return updateCellSendSlow($this, segment, index, element, s, waiter, closed);
}
function updateCellSendSlow($this, segment, index, element, s, waiter, closed) {
  while (true) {
    var state = segment.v11(index);
    if (state === null) {
      if (bufferOrRendezvousSend($this, s) ? !closed : false) {
        if (segment.x11(index, null, get_BUFFERED())) {
          return _get_RESULT_BUFFERED_$accessor$yt74tm_quor5m();
        }
      } else {
        if (closed) {
          if (segment.x11(index, null, get_INTERRUPTED_SEND())) {
            segment.l12(index, false);
            return _get_RESULT_CLOSED_$accessor$yt74tm_10v48j();
          }
        } else if (waiter == null)
          return _get_RESULT_SUSPEND_NO_WAITER_$accessor$yt74tm_cvzv8m();
        else if (segment.x11(index, null, waiter))
          return _get_RESULT_SUSPEND_$accessor$yt74tm_cjypnf();
      }
    } else if (state === get_IN_BUFFER()) {
      if (segment.x11(index, state, get_BUFFERED())) {
        return _get_RESULT_BUFFERED_$accessor$yt74tm_quor5m();
      }
    } else if (state === get_INTERRUPTED_RCV()) {
      segment.u11(index);
      return _get_RESULT_FAILED_$accessor$yt74tm_vo1zj0();
    } else if (state === get_POISONED()) {
      segment.u11(index);
      return _get_RESULT_FAILED_$accessor$yt74tm_vo1zj0();
    } else if (state === get_CHANNEL_CLOSED()) {
      segment.u11(index);
      completeCloseOrCancel($this);
      return _get_RESULT_CLOSED_$accessor$yt74tm_10v48j();
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      segment.u11(index);
      var tmp;
      if (state instanceof WaiterEB) {
        tmp = state.d14_1;
      } else {
        tmp = state;
      }
      var receiver = tmp;
      var tmp_0;
      if (tryResumeReceiver(receiver, $this, element)) {
        segment.w11(index, get_DONE_RCV());
        $this.c14();
        tmp_0 = _get_RESULT_RENDEZVOUS_$accessor$yt74tm_3irwt8();
      } else {
        if (!(segment.y11(index, get_INTERRUPTED_RCV()) === get_INTERRUPTED_RCV())) {
          segment.l12(index, true);
        }
        tmp_0 = _get_RESULT_FAILED_$accessor$yt74tm_vo1zj0();
      }
      return tmp_0;
    }
  }
}
function shouldSendSuspend($this, curSendersAndCloseStatus) {
  if (_get_isClosedForSend0__kxgf9m(curSendersAndCloseStatus, $this))
    return false;
  // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
  var tmp$ret$0 = curSendersAndCloseStatus.pe(new Long(-1, 268435455));
  return !bufferOrRendezvousSend($this, tmp$ret$0);
}
function bufferOrRendezvousSend($this, curSenders) {
  var tmp;
  if (curSenders.oc(_get_bufferEndCounter__2d4hee($this)) < 0) {
    tmp = true;
  } else {
    // Inline function 'kotlin.Long.plus' call
    var this_0 = $this.e14();
    var other = $this.z11_1;
    var tmp$ret$0 = this_0.ee(toLong(other));
    tmp = curSenders.oc(tmp$ret$0) < 0;
  }
  return tmp;
}
function tryResumeReceiver(_this__u8e3s4, $this, element) {
  var tmp;
  if (isInterface(_this__u8e3s4, SelectInstance)) {
    tmp = _this__u8e3s4.j14($this, element);
  } else {
    if (_this__u8e3s4 instanceof ReceiveCatching) {
      if (!(_this__u8e3s4 instanceof ReceiveCatching))
        THROW_CCE();
      var tmp_0 = Companion_getInstance_0().h14(element);
      var tmp1_safe_receiver = $this.a12_1;
      tmp = tryResume0(_this__u8e3s4.i14_1, new ChannelResult(tmp_0), tmp1_safe_receiver == null ? null : bindCancellationFun(tmp1_safe_receiver, element, _this__u8e3s4.i14_1.af()));
    } else {
      if (_this__u8e3s4 instanceof BufferedChannelIterator) {
        if (!(_this__u8e3s4 instanceof BufferedChannelIterator))
          THROW_CCE();
        tmp = _this__u8e3s4.f14(element);
      } else {
        if (isInterface(_this__u8e3s4, CancellableContinuation)) {
          if (!isInterface(_this__u8e3s4, CancellableContinuation))
            THROW_CCE();
          var tmp2_safe_receiver = $this.a12_1;
          tmp = tryResume0(_this__u8e3s4, element, tmp2_safe_receiver == null ? null : bindCancellationFun(tmp2_safe_receiver, element, _this__u8e3s4.af()));
        } else {
          var message = 'Unexpected receiver type: ' + toString(_this__u8e3s4);
          throw IllegalStateException.l1(toString(message));
        }
      }
    }
  }
  return tmp;
}
function prepareReceiverForSuspension(_this__u8e3s4, $this, segment, index) {
  $this.k14();
  _this__u8e3s4.uv(segment, index);
}
function updateCellReceive($this, segment, index, r, waiter) {
  var state = segment.v11(index);
  if (state === null) {
    // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
    var senders = $this.b12_1.kotlinx$atomicfu$value.pe(new Long(-1, 268435455));
    if (r.oc(senders) >= 0) {
      if (waiter === null) {
        return _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky();
      }
      if (segment.x11(index, state, waiter)) {
        expandBuffer($this);
        return _get_SUSPEND_$accessor$yt74tm_ccb8g1();
      }
    }
  } else if (state === get_BUFFERED())
    if (segment.x11(index, state, get_DONE_RCV())) {
      expandBuffer($this);
      return segment.t11(index);
    }
  return updateCellReceiveSlow($this, segment, index, r, waiter);
}
function updateCellReceiveSlow($this, segment, index, r, waiter) {
  $l$loop: while (true) {
    var state = segment.v11(index);
    if (state === null ? true : state === get_IN_BUFFER()) {
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
      var senders = $this.b12_1.kotlinx$atomicfu$value.pe(new Long(-1, 268435455));
      if (r.oc(senders) < 0) {
        if (segment.x11(index, state, get_POISONED())) {
          expandBuffer($this);
          return _get_FAILED_$accessor$yt74tm_h47uk8();
        }
      } else {
        if (waiter === null) {
          return _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky();
        }
        if (segment.x11(index, state, waiter)) {
          expandBuffer($this);
          return _get_SUSPEND_$accessor$yt74tm_ccb8g1();
        }
      }
    } else if (state === get_BUFFERED()) {
      if (segment.x11(index, state, get_DONE_RCV())) {
        expandBuffer($this);
        return segment.t11(index);
      }
    } else if (state === get_INTERRUPTED_SEND())
      return _get_FAILED_$accessor$yt74tm_h47uk8();
    else if (state === get_POISONED())
      return _get_FAILED_$accessor$yt74tm_h47uk8();
    else if (state === get_CHANNEL_CLOSED()) {
      expandBuffer($this);
      return _get_FAILED_$accessor$yt74tm_h47uk8();
    } else if (state === get_RESUMING_BY_EB())
      continue $l$loop;
    else {
      if (segment.x11(index, state, get_RESUMING_BY_RCV())) {
        var helpExpandBuffer = state instanceof WaiterEB;
        var tmp;
        if (state instanceof WaiterEB) {
          tmp = state.d14_1;
        } else {
          tmp = state;
        }
        var sender = tmp;
        var tmp_0;
        if (tryResumeSender(sender, $this, segment, index)) {
          segment.w11(index, get_DONE_RCV());
          expandBuffer($this);
          tmp_0 = segment.t11(index);
        } else {
          segment.w11(index, get_INTERRUPTED_SEND());
          segment.l12(index, false);
          if (helpExpandBuffer) {
            expandBuffer($this);
          }
          tmp_0 = _get_FAILED_$accessor$yt74tm_h47uk8();
        }
        return tmp_0;
      }
    }
  }
}
function tryResumeSender(_this__u8e3s4, $this, segment, index) {
  var tmp;
  if (isInterface(_this__u8e3s4, CancellableContinuation)) {
    if (!isInterface(_this__u8e3s4, CancellableContinuation))
      THROW_CCE();
    tmp = tryResume0(_this__u8e3s4, Unit_instance);
  } else {
    if (isInterface(_this__u8e3s4, SelectInstance)) {
      if (!(_this__u8e3s4 instanceof SelectImplementation))
        THROW_CCE();
      var trySelectResult = _this__u8e3s4.p14($this, Unit_instance);
      if (trySelectResult === TrySelectDetailedResult_REREGISTER_getInstance()) {
        segment.u11(index);
      }
      tmp = trySelectResult === TrySelectDetailedResult_SUCCESSFUL_getInstance();
    } else {
      if (_this__u8e3s4 instanceof SendBroadcast) {
        tmp = tryResume0(_this__u8e3s4.l14_1, true);
      } else {
        var message = 'Unexpected waiter: ' + toString(_this__u8e3s4);
        throw IllegalStateException.l1(toString(message));
      }
    }
  }
  return tmp;
}
function expandBuffer($this) {
  if (_get_isRendezvousOrUnlimited__3mdufi($this))
    return Unit_instance;
  var segment = $this.h12_1.kotlinx$atomicfu$value;
  try_again: while (true) {
    var b = $this.d12_1.atomicfu$getAndIncrement$long();
    // Inline function 'kotlin.Long.div' call
    var other = get_SEGMENT_SIZE();
    var id = b.he(toLong(other));
    var s = $this.d13();
    if (s.oc(b) <= 0) {
      if (segment.nu_1.oc(id) < 0 ? !(segment.t12() == null) : false) {
        moveSegmentBufferEndToSpecifiedOrLast($this, id, segment);
      }
      incCompletedExpandBufferAttempts$default($this);
      return Unit_instance;
    }
    if (!segment.nu_1.equals(id)) {
      var tmp0_elvis_lhs = findSegmentBufferEnd($this, id, segment, b);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        continue try_again;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      segment = tmp;
    }
    // Inline function 'kotlin.Long.rem' call
    var other_0 = get_SEGMENT_SIZE();
    var i = b.ie(toLong(other_0)).qe();
    if (updateCellExpandBuffer($this, segment, i, b)) {
      incCompletedExpandBufferAttempts$default($this);
      return Unit_instance;
    } else {
      incCompletedExpandBufferAttempts$default($this);
      continue try_again;
    }
  }
}
function updateCellExpandBuffer($this, segment, index, b) {
  var state = segment.v11(index);
  if (!(state == null) ? isInterface(state, Waiter) : false) {
    if (b.oc($this.c12_1.kotlinx$atomicfu$value) >= 0) {
      if (segment.x11(index, state, get_RESUMING_BY_EB())) {
        var tmp;
        if (tryResumeSender(state, $this, segment, index)) {
          segment.w11(index, get_BUFFERED());
          tmp = true;
        } else {
          segment.w11(index, get_INTERRUPTED_SEND());
          segment.l12(index, false);
          tmp = false;
        }
        return tmp;
      }
    }
  }
  return updateCellExpandBufferSlow($this, segment, index, b);
}
function updateCellExpandBufferSlow($this, segment, index, b) {
  $l$loop: while (true) {
    var state = segment.v11(index);
    if (!(state == null) ? isInterface(state, Waiter) : false) {
      if (b.oc($this.c12_1.kotlinx$atomicfu$value) < 0) {
        if (segment.x11(index, state, new WaiterEB(state)))
          return true;
      } else {
        if (segment.x11(index, state, get_RESUMING_BY_EB())) {
          var tmp;
          if (tryResumeSender(state, $this, segment, index)) {
            segment.w11(index, get_BUFFERED());
            tmp = true;
          } else {
            segment.w11(index, get_INTERRUPTED_SEND());
            segment.l12(index, false);
            tmp = false;
          }
          return tmp;
        }
      }
    } else {
      if (state === get_INTERRUPTED_SEND())
        return false;
      else {
        if (state === null) {
          if (segment.x11(index, state, get_IN_BUFFER()))
            return true;
        } else {
          if (state === get_BUFFERED())
            return true;
          else {
            if ((state === get_POISONED() ? true : state === get_DONE_RCV()) ? true : state === get_INTERRUPTED_RCV())
              return true;
            else {
              if (state === get_CHANNEL_CLOSED())
                return true;
              else {
                if (state === get_RESUMING_BY_RCV())
                  continue $l$loop;
                else {
                  var message = 'Unexpected cell state: ' + toString_0(state);
                  throw IllegalStateException.l1(toString(message));
                }
              }
            }
          }
        }
      }
    }
  }
}
function incCompletedExpandBufferAttempts($this, nAttempts) {
  // Inline function 'kotlin.also' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.incCompletedExpandBufferAttempts.<anonymous>' call
  // Inline function 'kotlinx.coroutines.channels.ebPauseExpandBuffers' call
  if (!$this.e12_1.atomicfu$addAndGet$long(nAttempts).pe(new Long(0, 1073741824)).equals(new Long(0, 0))) {
    $l$loop: while (true) {
      // Inline function 'kotlinx.coroutines.channels.ebPauseExpandBuffers' call
      if (!!$this.e12_1.kotlinx$atomicfu$value.pe(new Long(0, 1073741824)).equals(new Long(0, 0))) {
        break $l$loop;
      }
    }
  }
}
function incCompletedExpandBufferAttempts$default($this, nAttempts, $super) {
  nAttempts = nAttempts === VOID ? new Long(1, 0) : nAttempts;
  return incCompletedExpandBufferAttempts($this, nAttempts);
}
function _get_receiveException__foorc1($this) {
  var tmp0_elvis_lhs = $this.c13();
  return tmp0_elvis_lhs == null ? ClosedReceiveChannelException.w14(get_DEFAULT_CLOSE_MESSAGE()) : tmp0_elvis_lhs;
}
function invokeCloseHandler($this) {
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlinx.atomicfu.getAndUpdate' call
    var this_0 = $this.k12_1;
    while (true) {
      var cur = this_0.kotlinx$atomicfu$value;
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.invokeCloseHandler.<anonymous>' call
      var tmp;
      if (cur === null) {
        tmp = get_CLOSE_HANDLER_CLOSED();
      } else {
        tmp = get_CLOSE_HANDLER_INVOKED();
      }
      var upd = tmp;
      if (this_0.atomicfu$compareAndSet(cur, upd)) {
        tmp$ret$1 = cur;
        break $l$block;
      }
    }
  }
  var tmp0_elvis_lhs = tmp$ret$1;
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    return Unit_instance;
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  var closeHandler = tmp_0;
  if (typeof closeHandler !== 'function')
    THROW_CCE();
  closeHandler($this.c13());
}
function markClosed($this) {
  var tmp$ret$4;
  $l$block: {
    // Inline function 'kotlinx.atomicfu.update' call
    var this_0 = $this.b12_1;
    while (true) {
      var cur = this_0.kotlinx$atomicfu$value;
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.markClosed.<anonymous>' call
      // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
      var tmp;
      switch (cur.oe(60).qe()) {
        case 0:
          // Inline function 'kotlinx.coroutines.channels.sendersCounter' call

          var tmp$ret$1 = cur.pe(new Long(-1, 268435455));
          tmp = constructSendersAndCloseStatus(tmp$ret$1, 2);
          break;
        case 1:
          // Inline function 'kotlinx.coroutines.channels.sendersCounter' call

          var tmp$ret$2 = cur.pe(new Long(-1, 268435455));
          tmp = constructSendersAndCloseStatus(tmp$ret$2, 3);
          break;
        default:
          return Unit_instance;
      }
      var upd = tmp;
      if (this_0.atomicfu$compareAndSet(cur, upd)) {
        tmp$ret$4 = Unit_instance;
        break $l$block;
      }
    }
  }
  return tmp$ret$4;
}
function markCancelled($this) {
  var tmp$ret$2;
  $l$block: {
    // Inline function 'kotlinx.atomicfu.update' call
    var this_0 = $this.b12_1;
    while (true) {
      var cur = this_0.kotlinx$atomicfu$value;
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.markCancelled.<anonymous>' call
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
      var tmp$ret$0 = cur.pe(new Long(-1, 268435455));
      var upd = constructSendersAndCloseStatus(tmp$ret$0, 3);
      if (this_0.atomicfu$compareAndSet(cur, upd)) {
        tmp$ret$2 = Unit_instance;
        break $l$block;
      }
    }
  }
  return tmp$ret$2;
}
function markCancellationStarted($this) {
  var tmp$ret$3;
  $l$block: {
    // Inline function 'kotlinx.atomicfu.update' call
    var this_0 = $this.b12_1;
    while (true) {
      var cur = this_0.kotlinx$atomicfu$value;
      // Inline function 'kotlinx.coroutines.channels.BufferedChannel.markCancellationStarted.<anonymous>' call
      var tmp;
      // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
      if (cur.oe(60).qe() === 0) {
        // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
        var tmp$ret$1 = cur.pe(new Long(-1, 268435455));
        tmp = constructSendersAndCloseStatus(tmp$ret$1, 1);
      } else {
        return Unit_instance;
      }
      var upd = tmp;
      if (this_0.atomicfu$compareAndSet(cur, upd)) {
        tmp$ret$3 = Unit_instance;
        break $l$block;
      }
    }
  }
  return tmp$ret$3;
}
function completeCloseOrCancel($this) {
  $this.x14();
}
function completeClose($this, sendersCur) {
  var lastSegment = closeLinkedList($this);
  if ($this.z14()) {
    var lastBufferedCellGlobalIndex = markAllEmptyCellsAsClosed($this, lastSegment);
    if (!lastBufferedCellGlobalIndex.equals(new Long(-1, -1))) {
      $this.y14(lastBufferedCellGlobalIndex);
    }
  }
  cancelSuspendedReceiveRequests($this, lastSegment, sendersCur);
  return lastSegment;
}
function completeCancel($this, sendersCur) {
  var lastSegment = completeClose($this, sendersCur);
  removeUnprocessedElements($this, lastSegment);
}
function closeLinkedList($this) {
  var lastSegment = $this.h12_1.kotlinx$atomicfu$value;
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  var it = $this.f12_1.kotlinx$atomicfu$value;
  var tmp;
  if (it.nu_1.oc(lastSegment.nu_1) > 0) {
    lastSegment = it;
    tmp = Unit_instance;
  }
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  var it_0 = $this.g12_1.kotlinx$atomicfu$value;
  var tmp_0;
  if (it_0.nu_1.oc(lastSegment.nu_1) > 0) {
    lastSegment = it_0;
    tmp_0 = Unit_instance;
  }
  return close(lastSegment);
}
function markAllEmptyCellsAsClosed($this, lastSegment) {
  var segment = lastSegment;
  while (true) {
    var inductionVariable = get_SEGMENT_SIZE() - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        // Inline function 'kotlin.Long.plus' call
        // Inline function 'kotlin.Long.times' call
        var this_0 = segment.nu_1;
        var other = get_SEGMENT_SIZE();
        var globalIndex = this_0.ge(toLong(other)).ee(toLong(index));
        if (globalIndex.oc($this.e14()) < 0)
          return new Long(-1, -1);
        cell_update: while (true) {
          var state = segment.v11(index);
          if (state === null ? true : state === get_IN_BUFFER()) {
            if (segment.x11(index, state, get_CHANNEL_CLOSED())) {
              segment.n12();
              break cell_update;
            }
          } else if (state === get_BUFFERED())
            return globalIndex;
          else
            break cell_update;
        }
      }
       while (0 <= inductionVariable);
    var tmp1_elvis_lhs = segment.w12();
    var tmp;
    if (tmp1_elvis_lhs == null) {
      return new Long(-1, -1);
    } else {
      tmp = tmp1_elvis_lhs;
    }
    segment = tmp;
  }
}
function removeUnprocessedElements($this, lastSegment) {
  var onUndeliveredElement = $this.a12_1;
  var undeliveredElementException = null;
  var suspendedSenders = _InlineList___init__impl__z8n56();
  var segment = lastSegment;
  process_segments: while (true) {
    var inductionVariable = get_SEGMENT_SIZE() - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        // Inline function 'kotlin.Long.plus' call
        // Inline function 'kotlin.Long.times' call
        var this_0 = segment.nu_1;
        var other = get_SEGMENT_SIZE();
        var globalIndex = this_0.ge(toLong(other)).ee(toLong(index));
        update_cell: while (true) {
          var state = segment.v11(index);
          if (state === get_DONE_RCV())
            break process_segments;
          else {
            if (state === get_BUFFERED()) {
              if (globalIndex.oc($this.e14()) < 0)
                break process_segments;
              if (segment.x11(index, state, get_CHANNEL_CLOSED())) {
                if (!(onUndeliveredElement == null)) {
                  var element = segment.s11(index);
                  undeliveredElementException = callUndeliveredElementCatchingException(onUndeliveredElement, element, undeliveredElementException);
                }
                segment.u11(index);
                segment.n12();
                break update_cell;
              }
            } else {
              if (state === get_IN_BUFFER() ? true : state === null) {
                if (segment.x11(index, state, get_CHANNEL_CLOSED())) {
                  segment.n12();
                  break update_cell;
                }
              } else {
                var tmp;
                if (!(state == null) ? isInterface(state, Waiter) : false) {
                  tmp = true;
                } else {
                  tmp = state instanceof WaiterEB;
                }
                if (tmp) {
                  if (globalIndex.oc($this.e14()) < 0)
                    break process_segments;
                  var tmp_0;
                  if (state instanceof WaiterEB) {
                    tmp_0 = state.d14_1;
                  } else {
                    tmp_0 = (!(state == null) ? isInterface(state, Waiter) : false) ? state : THROW_CCE();
                  }
                  var sender = tmp_0;
                  if (segment.x11(index, state, get_CHANNEL_CLOSED())) {
                    if (!(onUndeliveredElement == null)) {
                      var element_0 = segment.s11(index);
                      undeliveredElementException = callUndeliveredElementCatchingException(onUndeliveredElement, element_0, undeliveredElementException);
                    }
                    suspendedSenders = InlineList__plus_impl_nuetvo(suspendedSenders, sender);
                    segment.u11(index);
                    segment.n12();
                    break update_cell;
                  }
                } else {
                  if (state === get_RESUMING_BY_EB() ? true : state === get_RESUMING_BY_RCV())
                    break process_segments;
                  else {
                    if (state === get_RESUMING_BY_EB())
                      continue update_cell;
                    else {
                      break update_cell;
                    }
                  }
                }
              }
            }
          }
        }
      }
       while (0 <= inductionVariable);
    var tmp1_elvis_lhs = segment.w12();
    var tmp_1;
    if (tmp1_elvis_lhs == null) {
      break process_segments;
    } else {
      tmp_1 = tmp1_elvis_lhs;
    }
    segment = tmp_1;
  }
  $l$block: {
    // Inline function 'kotlinx.coroutines.internal.InlineList.forEachReversed' call
    var this_1 = suspendedSenders;
    var tmp0_subject = _get_holder__f6h5pd(this_1);
    if (tmp0_subject == null) {
      break $l$block;
    } else {
      if (!(tmp0_subject instanceof ArrayList)) {
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.removeUnprocessedElements.<anonymous>' call
        var tmp_2 = _get_holder__f6h5pd(this_1);
        var it = (tmp_2 == null ? true : !(tmp_2 == null)) ? tmp_2 : THROW_CCE();
        resumeSenderOnCancelledChannel(it, $this);
      } else {
        var tmp_3 = _get_holder__f6h5pd(this_1);
        var list = tmp_3 instanceof ArrayList ? tmp_3 : THROW_CCE();
        var inductionVariable_0 = list.k() - 1 | 0;
        if (0 <= inductionVariable_0)
          do {
            var i = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + -1 | 0;
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.removeUnprocessedElements.<anonymous>' call
            var it_0 = list.h1(i);
            resumeSenderOnCancelledChannel(it_0, $this);
          }
           while (0 <= inductionVariable_0);
      }
    }
  }
  var tmp2_safe_receiver = undeliveredElementException;
  if (tmp2_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    throw tmp2_safe_receiver;
  }
}
function cancelSuspendedReceiveRequests($this, lastSegment, sendersCounter) {
  var suspendedReceivers = _InlineList___init__impl__z8n56();
  var segment = lastSegment;
  process_segments: while (!(segment == null)) {
    var inductionVariable = get_SEGMENT_SIZE() - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        // Inline function 'kotlin.Long.plus' call
        // Inline function 'kotlin.Long.times' call
        var this_0 = segment.nu_1;
        var other = get_SEGMENT_SIZE();
        if (this_0.ge(toLong(other)).ee(toLong(index)).oc(sendersCounter) < 0)
          break process_segments;
        cell_update: while (true) {
          var state = segment.v11(index);
          if (state === null ? true : state === get_IN_BUFFER()) {
            if (segment.x11(index, state, get_CHANNEL_CLOSED())) {
              segment.n12();
              break cell_update;
            }
          } else {
            if (state instanceof WaiterEB) {
              if (segment.x11(index, state, get_CHANNEL_CLOSED())) {
                suspendedReceivers = InlineList__plus_impl_nuetvo(suspendedReceivers, state.d14_1);
                segment.l12(index, true);
                break cell_update;
              }
            } else {
              if (!(state == null) ? isInterface(state, Waiter) : false) {
                if (segment.x11(index, state, get_CHANNEL_CLOSED())) {
                  suspendedReceivers = InlineList__plus_impl_nuetvo(suspendedReceivers, state);
                  segment.l12(index, true);
                  break cell_update;
                }
              } else {
                break cell_update;
              }
            }
          }
        }
      }
       while (0 <= inductionVariable);
    segment = segment.w12();
  }
  $l$block: {
    // Inline function 'kotlinx.coroutines.internal.InlineList.forEachReversed' call
    var this_1 = suspendedReceivers;
    var tmp0_subject = _get_holder__f6h5pd(this_1);
    if (tmp0_subject == null) {
      break $l$block;
    } else {
      if (!(tmp0_subject instanceof ArrayList)) {
        // Inline function 'kotlinx.coroutines.channels.BufferedChannel.cancelSuspendedReceiveRequests.<anonymous>' call
        var tmp = _get_holder__f6h5pd(this_1);
        var it = (tmp == null ? true : !(tmp == null)) ? tmp : THROW_CCE();
        resumeReceiverOnClosedChannel(it, $this);
      } else {
        var tmp_0 = _get_holder__f6h5pd(this_1);
        var list = tmp_0 instanceof ArrayList ? tmp_0 : THROW_CCE();
        var inductionVariable_0 = list.k() - 1 | 0;
        if (0 <= inductionVariable_0)
          do {
            var i = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + -1 | 0;
            // Inline function 'kotlinx.coroutines.channels.BufferedChannel.cancelSuspendedReceiveRequests.<anonymous>' call
            var it_0 = list.h1(i);
            resumeReceiverOnClosedChannel(it_0, $this);
          }
           while (0 <= inductionVariable_0);
      }
    }
  }
}
function resumeReceiverOnClosedChannel(_this__u8e3s4, $this) {
  return resumeWaiterOnClosedChannel(_this__u8e3s4, $this, true);
}
function resumeSenderOnCancelledChannel(_this__u8e3s4, $this) {
  return resumeWaiterOnClosedChannel(_this__u8e3s4, $this, false);
}
function resumeWaiterOnClosedChannel(_this__u8e3s4, $this, receiver) {
  if (_this__u8e3s4 instanceof SendBroadcast) {
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = _this__u8e3s4.l14_1;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(false);
    this_0.hf(tmp$ret$0);
  } else {
    if (isInterface(_this__u8e3s4, CancellableContinuation)) {
      // Inline function 'kotlin.coroutines.resumeWithException' call
      // Inline function 'kotlin.Companion.failure' call
      var exception = receiver ? _get_receiveException__foorc1($this) : $this.b15();
      var tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(exception));
      _this__u8e3s4.hf(tmp$ret$2);
    } else {
      if (_this__u8e3s4 instanceof ReceiveCatching) {
        // Inline function 'kotlin.coroutines.resume' call
        var this_1 = _this__u8e3s4.i14_1;
        // Inline function 'kotlin.Companion.success' call
        var value = new ChannelResult(Companion_getInstance_0().a15($this.c13()));
        var tmp$ret$4 = _Result___init__impl__xyqfz8(value);
        this_1.hf(tmp$ret$4);
      } else {
        if (_this__u8e3s4 instanceof BufferedChannelIterator) {
          _this__u8e3s4.r14();
        } else {
          if (isInterface(_this__u8e3s4, SelectInstance)) {
            _this__u8e3s4.j14($this, get_CHANNEL_CLOSED());
          } else {
            var message = 'Unexpected waiter: ' + _this__u8e3s4;
            throw IllegalStateException.l1(toString(message));
          }
        }
      }
    }
  }
}
function _get_isClosedForSend0__kxgf9m(_this__u8e3s4, $this) {
  return isClosed($this, _this__u8e3s4, false);
}
function _get_isClosedForReceive0__f7qknl(_this__u8e3s4, $this) {
  return isClosed($this, _this__u8e3s4, true);
}
function isClosed($this, sendersAndCloseStatusCur, isClosedForReceive) {
  // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
  var tmp;
  switch (sendersAndCloseStatusCur.oe(60).qe()) {
    case 0:
      tmp = false;
      break;
    case 1:
      tmp = false;
      break;
    case 2:
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call

      var tmp$ret$1 = sendersAndCloseStatusCur.pe(new Long(-1, 268435455));
      completeClose($this, tmp$ret$1);
      tmp = isClosedForReceive ? !$this.c15() : true;
      break;
    case 3:
      // Inline function 'kotlinx.coroutines.channels.sendersCounter' call

      var tmp$ret$2 = sendersAndCloseStatusCur.pe(new Long(-1, 268435455));
      completeCancel($this, tmp$ret$2);
      tmp = true;
      break;
    default:
      // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call

      var message = 'unexpected close status: ' + sendersAndCloseStatusCur.oe(60).qe();
      throw IllegalStateException.l1(toString(message));
  }
  return tmp;
}
function isCellNonEmpty($this, segment, index, globalIndex) {
  while (true) {
    var state = segment.v11(index);
    if (state === null ? true : state === get_IN_BUFFER()) {
      if (segment.x11(index, state, get_POISONED())) {
        expandBuffer($this);
        return false;
      }
    } else if (state === get_BUFFERED())
      return true;
    else if (state === get_INTERRUPTED_SEND())
      return false;
    else if (state === get_CHANNEL_CLOSED())
      return false;
    else if (state === get_DONE_RCV())
      return false;
    else if (state === get_POISONED())
      return false;
    else if (state === get_RESUMING_BY_EB())
      return true;
    else if (state === get_RESUMING_BY_RCV())
      return false;
    else
      return globalIndex.equals($this.e14());
  }
}
function findSegmentSend($this, id, startFrom) {
  // Inline function 'kotlin.let' call
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.f12_1;
    var createNewSegment = createSegmentFunction();
    while (true) {
      var s = findSegmentInternal(startFrom, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$0;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.nu_1.oc(to.nu_1) >= 0) {
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (!to.p12()) {
              tmp$ret$0 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.q12()) {
                cur.t();
              }
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (to.q12()) {
              to.t();
            }
          }
          tmp$ret$0 = Unit_instance;
        }
        tmp = tmp$ret$0;
      }
      if (tmp) {
        tmp$ret$1 = s;
        break $l$block_2;
      }
    }
  }
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.findSegmentSend.<anonymous>' call
  var it = tmp$ret$1;
  var tmp_0;
  if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(it)) {
    completeCloseOrCancel($this);
    // Inline function 'kotlin.Long.times' call
    var this_1 = startFrom.nu_1;
    var other = get_SEGMENT_SIZE();
    if (this_1.ge(toLong(other)).oc($this.e14()) < 0) {
      startFrom.x12();
    }
    tmp_0 = null;
  } else {
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(it);
    var tmp_1;
    if (segment.nu_1.oc(id) > 0) {
      // Inline function 'kotlin.Long.times' call
      var this_2 = segment.nu_1;
      var other_0 = get_SEGMENT_SIZE();
      var tmp$ret$3 = this_2.ge(toLong(other_0));
      updateSendersCounterIfLower($this, tmp$ret$3);
      // Inline function 'kotlin.Long.times' call
      var this_3 = segment.nu_1;
      var other_1 = get_SEGMENT_SIZE();
      if (this_3.ge(toLong(other_1)).oc($this.e14()) < 0) {
        segment.x12();
      }
      tmp_1 = null;
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      tmp_1 = segment;
    }
    tmp_0 = tmp_1;
  }
  return tmp_0;
}
function findSegmentReceive($this, id, startFrom) {
  // Inline function 'kotlin.let' call
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.g12_1;
    var createNewSegment = createSegmentFunction();
    while (true) {
      var s = findSegmentInternal(startFrom, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$0;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.nu_1.oc(to.nu_1) >= 0) {
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (!to.p12()) {
              tmp$ret$0 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.q12()) {
                cur.t();
              }
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (to.q12()) {
              to.t();
            }
          }
          tmp$ret$0 = Unit_instance;
        }
        tmp = tmp$ret$0;
      }
      if (tmp) {
        tmp$ret$1 = s;
        break $l$block_2;
      }
    }
  }
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.findSegmentReceive.<anonymous>' call
  var it = tmp$ret$1;
  var tmp_0;
  if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(it)) {
    completeCloseOrCancel($this);
    // Inline function 'kotlin.Long.times' call
    var this_1 = startFrom.nu_1;
    var other = get_SEGMENT_SIZE();
    if (this_1.ge(toLong(other)).oc($this.d13()) < 0) {
      startFrom.x12();
    }
    tmp_0 = null;
  } else {
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(it);
    var tmp_1;
    if (!_get_isRendezvousOrUnlimited__3mdufi($this)) {
      // Inline function 'kotlin.Long.div' call
      var this_2 = _get_bufferEndCounter__2d4hee($this);
      var other_0 = get_SEGMENT_SIZE();
      var tmp$ret$3 = this_2.he(toLong(other_0));
      tmp_1 = id.oc(tmp$ret$3) <= 0;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      $l$block_5: {
        // Inline function 'kotlinx.coroutines.internal.moveForward' call
        var this_3 = $this.h12_1;
        while (true) {
          // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
          var cur_0 = this_3.kotlinx$atomicfu$value;
          if (cur_0.nu_1.oc(segment.nu_1) >= 0) {
            break $l$block_5;
          }
          if (!segment.p12()) {
            break $l$block_5;
          }
          if (this_3.atomicfu$compareAndSet(cur_0, segment)) {
            if (cur_0.q12()) {
              cur_0.t();
            }
            break $l$block_5;
          }
          if (segment.q12()) {
            segment.t();
          }
        }
      }
    }
    var tmp_2;
    if (segment.nu_1.oc(id) > 0) {
      // Inline function 'kotlin.Long.times' call
      var this_4 = segment.nu_1;
      var other_1 = get_SEGMENT_SIZE();
      var tmp$ret$5 = this_4.ge(toLong(other_1));
      updateReceiversCounterIfLower($this, tmp$ret$5);
      // Inline function 'kotlin.Long.times' call
      var this_5 = segment.nu_1;
      var other_2 = get_SEGMENT_SIZE();
      if (this_5.ge(toLong(other_2)).oc($this.d13()) < 0) {
        segment.x12();
      }
      tmp_2 = null;
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      tmp_2 = segment;
    }
    tmp_0 = tmp_2;
  }
  return tmp_0;
}
function findSegmentBufferEnd($this, id, startFrom, currentBufferEndCounter) {
  // Inline function 'kotlin.let' call
  var tmp$ret$1;
  $l$block_2: {
    // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
    var this_0 = $this.h12_1;
    var createNewSegment = createSegmentFunction();
    while (true) {
      var s = findSegmentInternal(startFrom, id, createNewSegment);
      var tmp;
      if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
        tmp = true;
      } else {
        var tmp$ret$0;
        $l$block_1: {
          // Inline function 'kotlinx.coroutines.internal.moveForward' call
          var to = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
          while (true) {
            // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
            var cur = this_0.kotlinx$atomicfu$value;
            if (cur.nu_1.oc(to.nu_1) >= 0) {
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (!to.p12()) {
              tmp$ret$0 = false;
              break $l$block_1;
            }
            if (this_0.atomicfu$compareAndSet(cur, to)) {
              if (cur.q12()) {
                cur.t();
              }
              tmp$ret$0 = true;
              break $l$block_1;
            }
            if (to.q12()) {
              to.t();
            }
          }
          tmp$ret$0 = Unit_instance;
        }
        tmp = tmp$ret$0;
      }
      if (tmp) {
        tmp$ret$1 = s;
        break $l$block_2;
      }
    }
  }
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.BufferedChannel.findSegmentBufferEnd.<anonymous>' call
  var it = tmp$ret$1;
  var tmp_0;
  if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(it)) {
    completeCloseOrCancel($this);
    moveSegmentBufferEndToSpecifiedOrLast($this, id, startFrom);
    incCompletedExpandBufferAttempts$default($this);
    tmp_0 = null;
  } else {
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(it);
    var tmp_1;
    if (segment.nu_1.oc(id) > 0) {
      // Inline function 'kotlin.Long.plus' call
      var tmp_2 = currentBufferEndCounter.ee(toLong(1));
      // Inline function 'kotlin.Long.times' call
      var this_1 = segment.nu_1;
      var other = get_SEGMENT_SIZE();
      var tmp$ret$3 = this_1.ge(toLong(other));
      if ($this.d12_1.atomicfu$compareAndSet(tmp_2, tmp$ret$3)) {
        // Inline function 'kotlin.Long.times' call
        var this_2 = segment.nu_1;
        var other_0 = get_SEGMENT_SIZE();
        var tmp$ret$4 = this_2.ge(toLong(other_0));
        incCompletedExpandBufferAttempts($this, tmp$ret$4.fe(currentBufferEndCounter));
      } else {
        incCompletedExpandBufferAttempts$default($this);
      }
      tmp_1 = null;
    } else {
      // Inline function 'kotlinx.coroutines.assert' call
      tmp_1 = segment;
    }
    tmp_0 = tmp_1;
  }
  return tmp_0;
}
function moveSegmentBufferEndToSpecifiedOrLast($this, id, startFrom) {
  var segment = startFrom;
  $l$loop: while (segment.nu_1.oc(id) < 0) {
    var tmp0_elvis_lhs = segment.t12();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      break $l$loop;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    segment = tmp;
  }
  while (true) {
    $l$loop_0: while (segment.o12()) {
      var tmp1_elvis_lhs = segment.t12();
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        break $l$loop_0;
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      segment = tmp_0;
    }
    var tmp$ret$0;
    $l$block_1: {
      // Inline function 'kotlinx.coroutines.internal.moveForward' call
      var this_0 = $this.h12_1;
      var to = segment;
      while (true) {
        // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
        var cur = this_0.kotlinx$atomicfu$value;
        if (cur.nu_1.oc(to.nu_1) >= 0) {
          tmp$ret$0 = true;
          break $l$block_1;
        }
        if (!to.p12()) {
          tmp$ret$0 = false;
          break $l$block_1;
        }
        if (this_0.atomicfu$compareAndSet(cur, to)) {
          if (cur.q12()) {
            cur.t();
          }
          tmp$ret$0 = true;
          break $l$block_1;
        }
        if (to.q12()) {
          to.t();
        }
      }
      tmp$ret$0 = Unit_instance;
    }
    if (tmp$ret$0)
      return Unit_instance;
  }
}
function updateSendersCounterIfLower($this, value) {
  var this_0 = $this.b12_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.updateSendersCounterIfLower.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.channels.sendersCounter' call
    var curCounter = cur.pe(new Long(-1, 268435455));
    if (curCounter.oc(value) >= 0)
      return Unit_instance;
    // Inline function 'kotlinx.coroutines.channels.sendersCloseStatus' call
    var tmp$ret$1 = cur.oe(60).qe();
    var update = constructSendersAndCloseStatus(curCounter, tmp$ret$1);
    if ($this.b12_1.atomicfu$compareAndSet(cur, update))
      return Unit_instance;
  }
  return Unit_instance;
}
function updateReceiversCounterIfLower($this, value) {
  var this_0 = $this.c12_1;
  while (true) {
    // Inline function 'kotlinx.coroutines.channels.BufferedChannel.updateReceiversCounterIfLower.<anonymous>' call
    var cur = this_0.kotlinx$atomicfu$value;
    if (cur.oc(value) >= 0)
      return Unit_instance;
    if ($this.c12_1.atomicfu$compareAndSet(cur, value))
      return Unit_instance;
  }
  return Unit_instance;
}
function BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda$lambda($element, this$0, $select) {
  return function (it) {
    var tmp;
    if (!($element === get_CHANNEL_CLOSED())) {
      callUndeliveredElement(this$0.a12_1, ($element == null ? true : !($element == null)) ? $element : THROW_CCE(), $select.af());
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda(this$0) {
  return function (select, _anonymous_parameter_1__qggqgd, element) {
    return BufferedChannel$onUndeliveredElementReceiveCancellationConstructor$lambda$lambda(element, this$0, select);
  };
}
function initialBufferEnd(capacity) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  var tmp;
  Factory_getInstance();
  if (capacity === 0) {
    tmp = new Long(0, 0);
  } else {
    Factory_getInstance();
    if (capacity === 2147483647) {
      tmp = new Long(-1, 2147483647);
    } else {
      tmp = toLong(capacity);
    }
  }
  return tmp;
}
function tryResume0(_this__u8e3s4, value, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  _init_properties_BufferedChannel_kt__d6uc4y();
  // Inline function 'kotlin.let' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlinx.coroutines.channels.tryResume0.<anonymous>' call
  var token = _this__u8e3s4.vt(value, null, onCancellation);
  var tmp;
  if (!(token == null)) {
    _this__u8e3s4.yr(token);
    tmp = true;
  } else {
    tmp = false;
  }
  return tmp;
}
function constructEBCompletedAndPauseFlag(counter, pauseEB) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return (pauseEB ? new Long(0, 1073741824) : new Long(0, 0)).ee(counter);
}
function constructSendersAndCloseStatus(counter, closeStatus) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return toLong(closeStatus).ne(60).ee(counter);
}
function createSegmentFunction() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return createSegment$ref();
}
function createSegment(id, prev) {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return new ChannelSegment(id, prev, prev.p11(), 0);
}
function _get_RESULT_SUSPEND_NO_WAITER_$accessor$yt74tm_cvzv8m() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return 3;
}
function _get_RESULT_RENDEZVOUS_$accessor$yt74tm_3irwt8() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return 0;
}
function _get_RESULT_BUFFERED_$accessor$yt74tm_quor5m() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return 1;
}
function _get_RESULT_SUSPEND_$accessor$yt74tm_cjypnf() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return 2;
}
function _get_RESULT_CLOSED_$accessor$yt74tm_10v48j() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return 4;
}
function _get_RESULT_FAILED_$accessor$yt74tm_vo1zj0() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return 5;
}
function _get_SUSPEND_NO_WAITER_$accessor$yt74tm_n6n1ky() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return get_SUSPEND_NO_WAITER();
}
function _get_SUSPEND_$accessor$yt74tm_ccb8g1() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return get_SUSPEND();
}
function _get_FAILED_$accessor$yt74tm_h47uk8() {
  _init_properties_BufferedChannel_kt__d6uc4y();
  return get_FAILED();
}
function createSegment$ref() {
  var l = function (p0, p1) {
    return createSegment(p0, p1);
  };
  l.callableName = 'createSegment';
  return l;
}
var properties_initialized_BufferedChannel_kt_58tjvw;
function _init_properties_BufferedChannel_kt__d6uc4y() {
  if (!properties_initialized_BufferedChannel_kt_58tjvw) {
    properties_initialized_BufferedChannel_kt_58tjvw = true;
    NULL_SEGMENT = new ChannelSegment(new Long(-1, -1), null, null, 0);
    SEGMENT_SIZE = systemProp('kotlinx.coroutines.bufferedChannel.segmentSize', 32);
    EXPAND_BUFFER_COMPLETION_WAIT_ITERATIONS = systemProp('kotlinx.coroutines.bufferedChannel.expandBufferCompletionWaitIterations', 10000);
    BUFFERED = new Symbol('BUFFERED');
    IN_BUFFER = new Symbol('SHOULD_BUFFER');
    RESUMING_BY_RCV = new Symbol('S_RESUMING_BY_RCV');
    RESUMING_BY_EB = new Symbol('RESUMING_BY_EB');
    POISONED = new Symbol('POISONED');
    DONE_RCV = new Symbol('DONE_RCV');
    INTERRUPTED_SEND = new Symbol('INTERRUPTED_SEND');
    INTERRUPTED_RCV = new Symbol('INTERRUPTED_RCV');
    CHANNEL_CLOSED = new Symbol('CHANNEL_CLOSED');
    SUSPEND = new Symbol('SUSPEND');
    SUSPEND_NO_WAITER = new Symbol('SUSPEND_NO_WAITER');
    FAILED = new Symbol('FAILED');
    NO_RECEIVE_RESULT = new Symbol('NO_RECEIVE_RESULT');
    CLOSE_HANDLER_CLOSED = new Symbol('CLOSE_HANDLER_CLOSED');
    CLOSE_HANDLER_INVOKED = new Symbol('CLOSE_HANDLER_INVOKED');
    NO_CLOSE_CAUSE = new Symbol('NO_CLOSE_CAUSE');
  }
}
var Factory_instance;
function Factory_getInstance() {
  if (Factory_instance === VOID)
    new Factory();
  return Factory_instance;
}
function _ChannelResult___init__impl__siwsuf(holder) {
  return holder;
}
function _ChannelResult___get_holder__impl__pm9gzw($this) {
  return $this;
}
function _ChannelResult___get_isSuccess__impl__odq1z9($this) {
  var tmp = _ChannelResult___get_holder__impl__pm9gzw($this);
  return !(tmp instanceof Failed);
}
function _ChannelResult___get_isClosed__impl__mg7kuu($this) {
  var tmp = _ChannelResult___get_holder__impl__pm9gzw($this);
  return tmp instanceof Closed;
}
var Companion_instance_0;
function Companion_getInstance_0() {
  if (Companion_instance_0 === VOID)
    new Companion();
  return Companion_instance_0;
}
function ChannelResult__toString_impl_rrcqu7($this) {
  var tmp;
  if (_ChannelResult___get_holder__impl__pm9gzw($this) instanceof Closed) {
    tmp = toString(_ChannelResult___get_holder__impl__pm9gzw($this));
  } else {
    tmp = 'Value(' + toString_0(_ChannelResult___get_holder__impl__pm9gzw($this)) + ')';
  }
  return tmp;
}
function ChannelResult__hashCode_impl_lilec2($this) {
  return $this == null ? 0 : hashCode($this);
}
function ChannelResult__equals_impl_f471ri($this, other) {
  if (!(other instanceof ChannelResult))
    return false;
  var tmp0_other_with_cast = other instanceof ChannelResult ? other.a16_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function Channel(capacity, onBufferOverflow, onUndeliveredElement) {
  capacity = capacity === VOID ? 0 : capacity;
  onBufferOverflow = onBufferOverflow === VOID ? BufferOverflow_SUSPEND_getInstance() : onBufferOverflow;
  onUndeliveredElement = onUndeliveredElement === VOID ? null : onUndeliveredElement;
  var tmp;
  switch (capacity) {
    case 0:
      tmp = onBufferOverflow.equals(BufferOverflow_SUSPEND_getInstance()) ? new BufferedChannel(0, onUndeliveredElement) : new ConflatedBufferedChannel(1, onBufferOverflow, onUndeliveredElement);
      break;
    case -1:
      // Inline function 'kotlin.require' call

      // Inline function 'kotlin.contracts.contract' call

      if (!onBufferOverflow.equals(BufferOverflow_SUSPEND_getInstance())) {
        // Inline function 'kotlinx.coroutines.channels.Channel.<anonymous>' call
        var message = 'CONFLATED capacity cannot be used with non-default onBufferOverflow';
        throw IllegalArgumentException.g3(toString(message));
      }

      tmp = new ConflatedBufferedChannel(1, BufferOverflow_DROP_OLDEST_getInstance(), onUndeliveredElement);
      break;
    case 2147483647:
      tmp = new BufferedChannel(2147483647, onUndeliveredElement);
      break;
    case -2:
      tmp = onBufferOverflow.equals(BufferOverflow_SUSPEND_getInstance()) ? new BufferedChannel(Factory_getInstance().y15_1, onUndeliveredElement) : new ConflatedBufferedChannel(1, onBufferOverflow, onUndeliveredElement);
      break;
    default:
      tmp = onBufferOverflow === BufferOverflow_SUSPEND_getInstance() ? new BufferedChannel(capacity, onUndeliveredElement) : new ConflatedBufferedChannel(capacity, onBufferOverflow, onUndeliveredElement);
      break;
  }
  return tmp;
}
function get_DEFAULT_CLOSE_MESSAGE() {
  return DEFAULT_CLOSE_MESSAGE;
}
var DEFAULT_CLOSE_MESSAGE;
function cancelConsumed(_this__u8e3s4, cause) {
  var tmp;
  if (cause == null) {
    tmp = null;
  } else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.channels.cancelConsumed.<anonymous>' call
    var tmp0_elvis_lhs = cause instanceof CancellationException ? cause : null;
    tmp = tmp0_elvis_lhs == null ? CancellationException.q8('Channel was consumed, consumer had failed', cause) : tmp0_elvis_lhs;
  }
  _this__u8e3s4.fq(tmp);
}
function trySendImpl($this, element, isSendOp) {
  return $this.o16_1 === BufferOverflow_DROP_LATEST_getInstance() ? trySendDropLatest($this, element, isSendOp) : $this.g15(element);
}
function trySendDropLatest($this, element, isSendOp) {
  var result = protoOf(BufferedChannel).e15.call($this, element);
  if (_ChannelResult___get_isSuccess__impl__odq1z9(result) ? true : _ChannelResult___get_isClosed__impl__mg7kuu(result))
    return result;
  if (isSendOp) {
    var tmp0_safe_receiver = $this.a12_1;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : callUndeliveredElementCatchingException(tmp0_safe_receiver, element);
    if (tmp1_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      throw tmp1_safe_receiver;
    }
  }
  return Companion_getInstance_0().h14(Unit_instance);
}
function asFlow(_this__u8e3s4) {
  // Inline function 'kotlinx.coroutines.flow.internal.unsafeFlow' call
  return new _no_name_provided__qut3iv(_this__u8e3s4);
}
function checkOwnership(_this__u8e3s4, owner) {
  if (!(_this__u8e3s4.l17_1 === owner))
    throw _this__u8e3s4;
}
function firstOrNull(_this__u8e3s4, predicate, $completion) {
  var tmp = new $firstOrNullCOROUTINE$15(_this__u8e3s4, predicate, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function get_CLOSED() {
  _init_properties_ConcurrentLinkedList_kt__5gcgzy();
  return CLOSED;
}
var CLOSED;
function close(_this__u8e3s4) {
  _init_properties_ConcurrentLinkedList_kt__5gcgzy();
  var cur = _this__u8e3s4;
  while (true) {
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
    var this_0 = cur;
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
    var it = _get_nextOrClosed__w0gmuv(this_0);
    var tmp;
    if (it === get_CLOSED()) {
      return cur;
    } else {
      tmp = (it == null ? true : it instanceof ConcurrentLinkedListNode) ? it : THROW_CCE();
    }
    var next = tmp;
    if (next === null) {
      if (cur.y12())
        return cur;
    } else {
      cur = next;
    }
  }
}
function _SegmentOrClosed___init__impl__jnexvb(value) {
  return value;
}
function _get_value__a43j40($this) {
  return $this;
}
function _SegmentOrClosed___get_isClosed__impl__qmxmlo($this) {
  return _get_value__a43j40($this) === get_CLOSED();
}
function _SegmentOrClosed___get_segment__impl__jvcr9l($this) {
  var tmp;
  if (_get_value__a43j40($this) === get_CLOSED()) {
    var message = 'Does not contain segment';
    throw IllegalStateException.l1(toString(message));
  } else {
    var tmp_0 = _get_value__a43j40($this);
    tmp = tmp_0 instanceof Segment ? tmp_0 : THROW_CCE();
  }
  return tmp;
}
function SegmentOrClosed__toString_impl_pzb2an($this) {
  return 'SegmentOrClosed(value=' + toString_0($this) + ')';
}
function SegmentOrClosed__hashCode_impl_4855hs($this) {
  return $this == null ? 0 : hashCode($this);
}
function SegmentOrClosed__equals_impl_6erq1g($this, other) {
  if (!(other instanceof SegmentOrClosed))
    return false;
  var tmp0_other_with_cast = other instanceof SegmentOrClosed ? other.d15_1 : THROW_CCE();
  if (!equals($this, tmp0_other_with_cast))
    return false;
  return true;
}
function _get_nextOrClosed__w0gmuv($this) {
  return $this.r12_1.kotlinx$atomicfu$value;
}
function _get_aliveSegmentLeft__mr4ndu($this) {
  var cur = $this.w12();
  while (!(cur === null) ? cur.o12() : false)
    cur = cur.s12_1.kotlinx$atomicfu$value;
  return cur;
}
function _get_aliveSegmentRight__7ulr0b($this) {
  // Inline function 'kotlinx.coroutines.assert' call
  var cur = ensureNotNull($this.t12());
  while (cur.o12()) {
    var tmp0_elvis_lhs = cur.t12();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return cur;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    cur = tmp;
  }
  return cur;
}
function findSegmentInternal(_this__u8e3s4, id, createNewSegment) {
  _init_properties_ConcurrentLinkedList_kt__5gcgzy();
  var cur = _this__u8e3s4;
  $l$loop: while (cur.nu_1.oc(id) < 0 ? true : cur.o12()) {
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
    var this_0 = cur;
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
    var it = _get_nextOrClosed__w0gmuv(this_0);
    var tmp;
    if (it === get_CLOSED()) {
      return _SegmentOrClosed___init__impl__jnexvb(get_CLOSED());
    } else {
      tmp = (it == null ? true : it instanceof ConcurrentLinkedListNode) ? it : THROW_CCE();
    }
    var next = tmp;
    if (!(next == null)) {
      cur = next;
      continue $l$loop;
    }
    // Inline function 'kotlin.Long.plus' call
    var newTail = createNewSegment(cur.nu_1.ee(toLong(1)), cur);
    if (cur.u12(newTail)) {
      if (cur.o12()) {
        cur.t();
      }
      cur = newTail;
    }
  }
  return _SegmentOrClosed___init__impl__jnexvb(cur);
}
var properties_initialized_ConcurrentLinkedList_kt_kwt434;
function _init_properties_ConcurrentLinkedList_kt__5gcgzy() {
  if (!properties_initialized_ConcurrentLinkedList_kt_kwt434) {
    properties_initialized_ConcurrentLinkedList_kt_kwt434 = true;
    CLOSED = new Symbol('CLOSED');
  }
}
function handleUncaughtCoroutineException(context, exception) {
  var tmp0_iterator = get_platformExceptionHandlers().r();
  while (tmp0_iterator.s()) {
    var handler = tmp0_iterator.u();
    try {
      handler.xw(context, exception);
    } catch ($p) {
      if ($p instanceof ExceptionSuccessfullyProcessed) {
        var _ = $p;
        return Unit_instance;
      } else {
        if ($p instanceof Error) {
          var t = $p;
          propagateExceptionFinalResort(handlerException(exception, t));
        } else {
          throw $p;
        }
      }
    }
  }
  try {
    addSuppressed(exception, DiagnosticCoroutineContextException.s18(context));
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
    } else {
      throw $p;
    }
  }
  propagateExceptionFinalResort(exception);
}
function get_UNDEFINED() {
  _init_properties_DispatchedContinuation_kt__tnmqc0();
  return UNDEFINED;
}
var UNDEFINED;
function get_REUSABLE_CLAIMED() {
  _init_properties_DispatchedContinuation_kt__tnmqc0();
  return REUSABLE_CLAIMED;
}
var REUSABLE_CLAIMED;
function _get_reusableCancellableContinuation__9qex09($this) {
  var tmp = $this.fu_1.kotlinx$atomicfu$value;
  return tmp instanceof CancellableContinuationImpl ? tmp : null;
}
function resumeCancellableWith(_this__u8e3s4, result, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  _init_properties_DispatchedContinuation_kt__tnmqc0();
  var tmp;
  if (_this__u8e3s4 instanceof DispatchedContinuation) {
    var state = toState_0(result, onCancellation);
    var tmp_0;
    if (_this__u8e3s4.bu_1.uw(_this__u8e3s4.af())) {
      _this__u8e3s4.du_1 = state;
      _this__u8e3s4.ju_1 = get_MODE_CANCELLABLE();
      _this__u8e3s4.bu_1.vw(_this__u8e3s4.af(), _this__u8e3s4);
      tmp_0 = Unit_instance;
    } else {
      $l$block: {
        // Inline function 'kotlinx.coroutines.internal.executeUnconfined' call
        var mode = get_MODE_CANCELLABLE();
        // Inline function 'kotlinx.coroutines.assert' call
        var eventLoop = ThreadLocalEventLoop_getInstance().sx();
        if (false ? eventLoop.nx() : false) {
          break $l$block;
        }
        var tmp_1;
        if (eventLoop.mx()) {
          _this__u8e3s4.du_1 = state;
          _this__u8e3s4.ju_1 = mode;
          eventLoop.lx(_this__u8e3s4);
          tmp_1 = true;
        } else {
          // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
          eventLoop.ox(true);
          try {
            // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeCancellableWith.<anonymous>' call
            var tmp$ret$3;
            $l$block_0: {
              // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeCancelled' call
              var job = _this__u8e3s4.af().bf(Key_instance_3);
              if (!(job == null) ? !job.zo() : false) {
                var cause = job.wp();
                _this__u8e3s4.lv(state, cause);
                // Inline function 'kotlin.coroutines.resumeWithException' call
                // Inline function 'kotlin.Companion.failure' call
                var tmp$ret$1 = _Result___init__impl__xyqfz8(createFailure(cause));
                _this__u8e3s4.hf(tmp$ret$1);
                tmp$ret$3 = true;
                break $l$block_0;
              }
              tmp$ret$3 = false;
            }
            if (!tmp$ret$3) {
              // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeUndispatchedWith' call
              // Inline function 'kotlinx.coroutines.withContinuationContext' call
              _this__u8e3s4.cu_1;
              _this__u8e3s4.eu_1;
              _this__u8e3s4.cu_1.hf(result);
            }
            $l$loop: while (eventLoop.kx()) {
            }
          } catch ($p) {
            if ($p instanceof Error) {
              var e = $p;
              _this__u8e3s4.xv(e, null);
            } else {
              throw $p;
            }
          }
          finally {
            eventLoop.px(true);
          }
          tmp_1 = false;
        }
      }
      tmp_0 = Unit_instance;
    }
    tmp = tmp_0;
  } else {
    _this__u8e3s4.hf(result);
    tmp = Unit_instance;
  }
  return tmp;
}
var properties_initialized_DispatchedContinuation_kt_2siadq;
function _init_properties_DispatchedContinuation_kt__tnmqc0() {
  if (!properties_initialized_DispatchedContinuation_kt_2siadq) {
    properties_initialized_DispatchedContinuation_kt_2siadq = true;
    UNDEFINED = new Symbol('UNDEFINED');
    REUSABLE_CLAIMED = new Symbol('REUSABLE_CLAIMED');
  }
}
function get_MODE_CANCELLABLE() {
  return MODE_CANCELLABLE;
}
var MODE_CANCELLABLE;
function get_MODE_CANCELLABLE_REUSABLE() {
  return MODE_CANCELLABLE_REUSABLE;
}
var MODE_CANCELLABLE_REUSABLE;
function get_MODE_UNINITIALIZED() {
  return MODE_UNINITIALIZED;
}
var MODE_UNINITIALIZED;
function get_isReusableMode(_this__u8e3s4) {
  return _this__u8e3s4 === 2;
}
function get_isCancellableMode(_this__u8e3s4) {
  return _this__u8e3s4 === 1 ? true : _this__u8e3s4 === 2;
}
function dispatch(_this__u8e3s4, mode) {
  // Inline function 'kotlinx.coroutines.assert' call
  var delegate = _this__u8e3s4.jv();
  var undispatched = mode === 4;
  var tmp;
  var tmp_0;
  if (!undispatched) {
    tmp_0 = delegate instanceof DispatchedContinuation;
  } else {
    tmp_0 = false;
  }
  if (tmp_0) {
    tmp = get_isCancellableMode(mode) === get_isCancellableMode(_this__u8e3s4.ju_1);
  } else {
    tmp = false;
  }
  if (tmp) {
    var dispatcher = delegate.bu_1;
    var context = delegate.af();
    if (dispatcher.uw(context)) {
      dispatcher.vw(context, _this__u8e3s4);
    } else {
      resumeUnconfined(_this__u8e3s4);
    }
  } else {
    resume(_this__u8e3s4, delegate, undispatched);
  }
}
function get_MODE_UNDISPATCHED() {
  return MODE_UNDISPATCHED;
}
var MODE_UNDISPATCHED;
function resumeUnconfined(_this__u8e3s4) {
  var eventLoop = ThreadLocalEventLoop_getInstance().sx();
  if (eventLoop.mx()) {
    eventLoop.lx(_this__u8e3s4);
  } else {
    // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
    eventLoop.ox(true);
    try {
      // Inline function 'kotlinx.coroutines.resumeUnconfined.<anonymous>' call
      resume(_this__u8e3s4, _this__u8e3s4.jv(), true);
      $l$loop: while (eventLoop.kx()) {
      }
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        _this__u8e3s4.xv(e, null);
      } else {
        throw $p;
      }
    }
    finally {
      eventLoop.px(true);
    }
  }
}
function resume(_this__u8e3s4, delegate, undispatched) {
  var state = _this__u8e3s4.kv();
  var exception = _this__u8e3s4.vv(state);
  var tmp;
  if (!(exception == null)) {
    // Inline function 'kotlin.Companion.failure' call
    tmp = _Result___init__impl__xyqfz8(createFailure(exception));
  } else {
    // Inline function 'kotlin.Companion.success' call
    var value = _this__u8e3s4.rv(state);
    tmp = _Result___init__impl__xyqfz8(value);
  }
  var result = tmp;
  if (undispatched) {
    // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeUndispatchedWith' call
    var this_0 = delegate instanceof DispatchedContinuation ? delegate : THROW_CCE();
    // Inline function 'kotlinx.coroutines.withContinuationContext' call
    this_0.cu_1;
    this_0.eu_1;
    this_0.cu_1.hf(result);
  } else {
    delegate.hf(result);
  }
}
function get_MODE_ATOMIC() {
  return MODE_ATOMIC;
}
var MODE_ATOMIC;
function _InlineList___init__impl__z8n56(holder) {
  holder = holder === VOID ? null : holder;
  return holder;
}
function _get_holder__f6h5pd($this) {
  return $this;
}
function InlineList__plus_impl_nuetvo($this, element) {
  // Inline function 'kotlinx.coroutines.assert' call
  var tmp0_subject = _get_holder__f6h5pd($this);
  var tmp;
  if (tmp0_subject == null) {
    tmp = _InlineList___init__impl__z8n56(element);
  } else {
    if (tmp0_subject instanceof ArrayList) {
      var tmp_0 = _get_holder__f6h5pd($this);
      (tmp_0 instanceof ArrayList ? tmp_0 : THROW_CCE()).o(element);
      tmp = _InlineList___init__impl__z8n56(_get_holder__f6h5pd($this));
    } else {
      var list = ArrayList.k3(4);
      var tmp_1 = _get_holder__f6h5pd($this);
      list.o((tmp_1 == null ? true : !(tmp_1 == null)) ? tmp_1 : THROW_CCE());
      list.o(element);
      tmp = _InlineList___init__impl__z8n56(list);
    }
  }
  return tmp;
}
function callUndeliveredElement(_this__u8e3s4, element, context) {
  var tmp0_safe_receiver = callUndeliveredElementCatchingException(_this__u8e3s4, element, null);
  if (tmp0_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    handleCoroutineException(context, tmp0_safe_receiver);
  }
}
function callUndeliveredElementCatchingException(_this__u8e3s4, element, undeliveredElementException) {
  undeliveredElementException = undeliveredElementException === VOID ? null : undeliveredElementException;
  try {
    _this__u8e3s4(element);
  } catch ($p) {
    if ($p instanceof Error) {
      var ex = $p;
      if (!(undeliveredElementException == null) ? !(undeliveredElementException.cause === ex) : false) {
        addSuppressed(undeliveredElementException, ex);
      } else {
        return UndeliveredElementException.x18('Exception in undelivered element handler for ' + element, ex);
      }
    } else {
      throw $p;
    }
  }
  return undeliveredElementException;
}
function bindCancellationFun(_this__u8e3s4, element, context) {
  return bindCancellationFun$lambda(_this__u8e3s4, element, context);
}
function bindCancellationFun$lambda($this_bindCancellationFun, $element, $context) {
  return function (_anonymous_parameter_0__qggqh8) {
    callUndeliveredElement($this_bindCancellationFun, $element, $context);
    return Unit_instance;
  };
}
function systemProp(propertyName, defaultValue, minValue, maxValue) {
  minValue = minValue === VOID ? 1 : minValue;
  maxValue = maxValue === VOID ? IntCompanionObject_instance.MAX_VALUE : maxValue;
  return systemProp_0(propertyName, toLong(defaultValue), toLong(minValue), toLong(maxValue)).qe();
}
function systemProp_0(propertyName, defaultValue, minValue, maxValue) {
  minValue = minValue === VOID ? new Long(1, 0) : minValue;
  var tmp;
  if (maxValue === VOID) {
    Companion_getInstance();
    tmp = new Long(-1, 2147483647);
  } else {
    tmp = maxValue;
  }
  maxValue = tmp;
  var tmp0_elvis_lhs = systemProp_1(propertyName);
  var tmp_0;
  if (tmp0_elvis_lhs == null) {
    return defaultValue;
  } else {
    tmp_0 = tmp0_elvis_lhs;
  }
  var value = tmp_0;
  var tmp1_elvis_lhs = toLongOrNull(value);
  var tmp_1;
  if (tmp1_elvis_lhs == null) {
    var message = "System property '" + propertyName + "' has unrecognized value '" + value + "'";
    throw IllegalStateException.l1(toString(message));
  } else {
    tmp_1 = tmp1_elvis_lhs;
  }
  var parsed = tmp_1;
  if (!(minValue.oc(parsed) <= 0 ? parsed.oc(maxValue) <= 0 : false)) {
    // Inline function 'kotlin.error' call
    var message_0 = "System property '" + propertyName + "' should be in range " + minValue.toString() + '..' + maxValue.toString() + ", but is '" + parsed.toString() + "'";
    throw IllegalStateException.l1(toString(message_0));
  }
  return parsed;
}
function startCoroutineCancellable(_this__u8e3s4, fatalCompletion) {
  var tmp;
  try {
    var tmp_0 = intercepted(_this__u8e3s4);
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    resumeCancellableWith(tmp_0, tmp$ret$0);
    tmp = Unit_instance;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      dispatcherFailure$accessor$glj1hg(fatalCompletion, e);
      tmp_1 = Unit_instance;
    } else {
      throw $p;
    }
    tmp = tmp_1;
  }
  return tmp;
}
function startCoroutineCancellable_0(_this__u8e3s4, receiver, completion, onCancellation) {
  onCancellation = onCancellation === VOID ? null : onCancellation;
  var tmp;
  try {
    var tmp_0 = intercepted(createCoroutineUnintercepted(_this__u8e3s4, receiver, completion));
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_instance);
    resumeCancellableWith(tmp_0, tmp$ret$0, onCancellation);
    tmp = Unit_instance;
  } catch ($p) {
    var tmp_1;
    if ($p instanceof Error) {
      var e = $p;
      dispatcherFailure$accessor$glj1hg(completion, e);
      tmp_1 = Unit_instance;
    } else {
      throw $p;
    }
    tmp = tmp_1;
  }
  return tmp;
}
function dispatcherFailure(completion, e) {
  // Inline function 'kotlin.Companion.failure' call
  var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(e));
  completion.hf(tmp$ret$0);
  throw e;
}
function dispatcherFailure$accessor$glj1hg(completion, e) {
  return dispatcherFailure(completion, e);
}
function startCoroutineUndispatched(_this__u8e3s4, receiver, completion) {
  $l$block: {
    // Inline function 'kotlinx.coroutines.intrinsics.startDirect' call
    // Inline function 'kotlinx.coroutines.internal.probeCoroutineCreated' call
    var actualCompletion = completion;
    var tmp;
    try {
      // Inline function 'kotlinx.coroutines.intrinsics.startCoroutineUndispatched.<anonymous>' call
      // Inline function 'kotlinx.coroutines.withCoroutineContext' call
      completion.af();
      // Inline function 'kotlinx.coroutines.intrinsics.startCoroutineUndispatched.<anonymous>.<anonymous>' call
      // Inline function 'kotlin.coroutines.intrinsics.startCoroutineUninterceptedOrReturn' call
      // Inline function 'kotlin.js.asDynamic' call
      var a = _this__u8e3s4;
      tmp = typeof a === 'function' ? a(receiver, actualCompletion) : _this__u8e3s4.wf(receiver, actualCompletion);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        // Inline function 'kotlin.coroutines.resumeWithException' call
        // Inline function 'kotlin.Companion.failure' call
        var tmp$ret$6 = _Result___init__impl__xyqfz8(createFailure(e));
        actualCompletion.hf(tmp$ret$6);
        break $l$block;
      } else {
        throw $p;
      }
    }
    var value = tmp;
    if (!(value === get_COROUTINE_SUSPENDED())) {
      // Inline function 'kotlin.coroutines.resume' call
      // Inline function 'kotlin.Companion.success' call
      var value_0 = (value == null ? true : !(value == null)) ? value : THROW_CCE();
      var tmp$ret$9 = _Result___init__impl__xyqfz8(value_0);
      actualCompletion.hf(tmp$ret$9);
    }
  }
}
var DUMMY_PROCESS_RESULT_FUNCTION;
function get_STATE_REG() {
  _init_properties_Select_kt__zhm2jg();
  return STATE_REG;
}
var STATE_REG;
function get_STATE_COMPLETED() {
  _init_properties_Select_kt__zhm2jg();
  return STATE_COMPLETED;
}
var STATE_COMPLETED;
function get_STATE_CANCELLED() {
  _init_properties_Select_kt__zhm2jg();
  return STATE_CANCELLED;
}
var STATE_CANCELLED;
function get_NO_RESULT() {
  _init_properties_Select_kt__zhm2jg();
  return NO_RESULT;
}
var NO_RESULT;
var PARAM_CLAUSE_0;
function trySelectInternal($this, clauseObject, internalResult) {
  $l$loop: while (true) {
    var curState = $this.m14_1.kotlinx$atomicfu$value;
    if (isInterface(curState, CancellableContinuation)) {
      var tmp0_elvis_lhs = findClause($this, clauseObject);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        continue $l$loop;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var clause = tmp;
      var onCancellation = clause.d19($this, internalResult);
      if ($this.m14_1.atomicfu$compareAndSet(curState, clause)) {
        var cont = isInterface(curState, CancellableContinuation) ? curState : THROW_CCE();
        $this.o14_1 = internalResult;
        if (tryResume_0(cont, onCancellation))
          return 0;
        $this.o14_1 = get_NO_RESULT();
        return 2;
      }
    } else {
      var tmp_0;
      if (equals(curState, get_STATE_COMPLETED())) {
        tmp_0 = true;
      } else {
        tmp_0 = curState instanceof ClauseData;
      }
      if (tmp_0)
        return 3;
      else {
        if (equals(curState, get_STATE_CANCELLED()))
          return 2;
        else {
          if (equals(curState, get_STATE_REG())) {
            if ($this.m14_1.atomicfu$compareAndSet(curState, listOf_0(clauseObject)))
              return 1;
          } else {
            if (isInterface(curState, List)) {
              if ($this.m14_1.atomicfu$compareAndSet(curState, plus_0(curState, clauseObject)))
                return 1;
            } else {
              var message = 'Unexpected state: ' + toString(curState);
              throw IllegalStateException.l1(toString(message));
            }
          }
        }
      }
    }
  }
}
function findClause($this, clauseObject) {
  var tmp0_elvis_lhs = $this.n14_1;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return null;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var clauses = tmp;
  // Inline function 'kotlin.collections.find' call
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var tmp0_iterator = clauses.r();
    while (tmp0_iterator.s()) {
      var element = tmp0_iterator.u();
      // Inline function 'kotlinx.coroutines.selects.SelectImplementation.findClause.<anonymous>' call
      if (element.a19_1 === clauseObject) {
        tmp$ret$1 = element;
        break $l$block;
      }
    }
    tmp$ret$1 = null;
  }
  var tmp1_elvis_lhs = tmp$ret$1;
  var tmp_0;
  if (tmp1_elvis_lhs == null) {
    var message = 'Clause with object ' + toString(clauseObject) + ' is not found';
    throw IllegalStateException.l1(toString(message));
  } else {
    tmp_0 = tmp1_elvis_lhs;
  }
  return tmp_0;
}
var TrySelectDetailedResult_SUCCESSFUL_instance;
var TrySelectDetailedResult_REREGISTER_instance;
var TrySelectDetailedResult_CANCELLED_instance;
var TrySelectDetailedResult_ALREADY_SELECTED_instance;
var TrySelectDetailedResult_entriesInitialized;
function TrySelectDetailedResult_initEntries() {
  if (TrySelectDetailedResult_entriesInitialized)
    return Unit_instance;
  TrySelectDetailedResult_entriesInitialized = true;
  TrySelectDetailedResult_SUCCESSFUL_instance = new TrySelectDetailedResult('SUCCESSFUL', 0);
  TrySelectDetailedResult_REREGISTER_instance = new TrySelectDetailedResult('REREGISTER', 1);
  TrySelectDetailedResult_CANCELLED_instance = new TrySelectDetailedResult('CANCELLED', 2);
  TrySelectDetailedResult_ALREADY_SELECTED_instance = new TrySelectDetailedResult('ALREADY_SELECTED', 3);
}
function TrySelectDetailedResult_0(trySelectInternalResult) {
  _init_properties_Select_kt__zhm2jg();
  var tmp;
  switch (trySelectInternalResult) {
    case 0:
      tmp = TrySelectDetailedResult_SUCCESSFUL_getInstance();
      break;
    case 1:
      tmp = TrySelectDetailedResult_REREGISTER_getInstance();
      break;
    case 2:
      tmp = TrySelectDetailedResult_CANCELLED_getInstance();
      break;
    case 3:
      tmp = TrySelectDetailedResult_ALREADY_SELECTED_getInstance();
      break;
    default:
      var message = 'Unexpected internal result: ' + trySelectInternalResult;
      throw IllegalStateException.l1(toString(message));
  }
  return tmp;
}
function tryResume_0(_this__u8e3s4, onCancellation) {
  _init_properties_Select_kt__zhm2jg();
  var tmp0_elvis_lhs = _this__u8e3s4.vt(Unit_instance, null, onCancellation);
  var tmp;
  if (tmp0_elvis_lhs == null) {
    return false;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  var token = tmp;
  _this__u8e3s4.yr(token);
  return true;
}
function DUMMY_PROCESS_RESULT_FUNCTION$lambda(_anonymous_parameter_0__qggqh8, _anonymous_parameter_1__qggqgd, _anonymous_parameter_2__qggqfi) {
  _init_properties_Select_kt__zhm2jg();
  return null;
}
function TrySelectDetailedResult_SUCCESSFUL_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_SUCCESSFUL_instance;
}
function TrySelectDetailedResult_REREGISTER_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_REREGISTER_instance;
}
function TrySelectDetailedResult_CANCELLED_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_CANCELLED_instance;
}
function TrySelectDetailedResult_ALREADY_SELECTED_getInstance() {
  TrySelectDetailedResult_initEntries();
  return TrySelectDetailedResult_ALREADY_SELECTED_instance;
}
var properties_initialized_Select_kt_7rpl36;
function _init_properties_Select_kt__zhm2jg() {
  if (!properties_initialized_Select_kt_7rpl36) {
    properties_initialized_Select_kt_7rpl36 = true;
    DUMMY_PROCESS_RESULT_FUNCTION = DUMMY_PROCESS_RESULT_FUNCTION$lambda;
    STATE_REG = new Symbol('STATE_REG');
    STATE_COMPLETED = new Symbol('STATE_COMPLETED');
    STATE_CANCELLED = new Symbol('STATE_CANCELLED');
    NO_RESULT = new Symbol('NO_RESULT');
    PARAM_CLAUSE_0 = new Symbol('PARAM_CLAUSE_0');
  }
}
function toDebugString(_this__u8e3s4) {
  return toString(_this__u8e3s4);
}
function get_DefaultDelay() {
  var tmp = Dispatchers_getInstance().ax_1;
  return isInterface(tmp, Delay) ? tmp : THROW_CCE();
}
function createDefaultDispatcher() {
  var tmp;
  if (isJsdom()) {
    tmp = NodeDispatcher_getInstance();
  } else {
    var tmp_0;
    var tmp_1;
    if (!(typeof window === 'undefined')) {
      // Inline function 'kotlin.js.asDynamic' call
      tmp_1 = window != null;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      // Inline function 'kotlin.js.asDynamic' call
      tmp_0 = !(typeof window.addEventListener === 'undefined');
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = asCoroutineDispatcher(window);
    } else {
      if (typeof process === 'undefined' ? true : typeof process.nextTick === 'undefined') {
        tmp = SetTimeoutDispatcher_getInstance();
      } else {
        tmp = NodeDispatcher_getInstance();
      }
    }
  }
  return tmp;
}
function isJsdom() {
  return ((((!(typeof navigator === 'undefined') ? navigator != null : false) ? navigator.userAgent != null : false) ? !(typeof navigator.userAgent === 'undefined') : false) ? !(typeof navigator.userAgent.match === 'undefined') : false) ? navigator.userAgent.match('\\bjsdom\\b') : false;
}
function newCoroutineContext(_this__u8e3s4, context) {
  var combined = _this__u8e3s4.yo().oi(context);
  return (!(combined === Dispatchers_getInstance().ax_1) ? combined.bf(Key_instance) == null : false) ? combined.oi(Dispatchers_getInstance().ax_1) : combined;
}
function get_coroutineName(_this__u8e3s4) {
  return null;
}
var counter;
function get_DEBUG() {
  return DEBUG;
}
var DEBUG;
function get_classSimpleName(_this__u8e3s4) {
  var tmp0_elvis_lhs = getKClassFromExpression(_this__u8e3s4).u8();
  return tmp0_elvis_lhs == null ? 'Unknown' : tmp0_elvis_lhs;
}
function get_hexAddress(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  var result = _this__u8e3s4.__debug_counter;
  if (!(typeof result === 'number')) {
    counter = counter + 1 | 0;
    result = counter;
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.__debug_counter = result;
  }
  return ((!(result == null) ? typeof result === 'number' : false) ? result : THROW_CCE()).toString();
}
var NodeDispatcher_instance;
function NodeDispatcher_getInstance() {
  if (NodeDispatcher_instance === VOID)
    new NodeDispatcher();
  return NodeDispatcher_instance;
}
function ScheduledMessageQueue$processQueue$lambda(this$0) {
  return function () {
    this$0.r19();
    return Unit_instance;
  };
}
function w3cSetTimeout(handler, timeout) {
  return setTimeout(handler, timeout);
}
function WindowMessageQueue$lambda(this$0) {
  return function (event) {
    var tmp;
    if (event.source == this$0.g1a_1 ? event.data == this$0.h1a_1 : false) {
      event.stopPropagation();
      this$0.r19();
      tmp = Unit_instance;
    }
    return Unit_instance;
  };
}
function WindowMessageQueue$schedule$lambda(this$0) {
  return function (it) {
    this$0.r19();
    return Unit_instance;
  };
}
function w3cSetTimeout_0(window_0, handler, timeout) {
  return setTimeout_0(window_0, handler, timeout);
}
function w3cClearTimeout(window_0, handle) {
  return window_0.clearTimeout(handle);
}
function w3cClearTimeout_0(handle) {
  return clearTimeout(handle);
}
function setTimeout_0(window_0, handler, timeout) {
  return window_0.setTimeout(handler, timeout);
}
function await_0(_this__u8e3s4, $completion) {
  // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
  var cancellable = new CancellableContinuationImpl(intercepted($completion), get_MODE_CANCELLABLE());
  cancellable.ts();
  // Inline function 'kotlinx.coroutines.await.<anonymous>' call
  var tmp = await$lambda(cancellable);
  _this__u8e3s4.then(tmp, await$lambda_0(cancellable));
  return cancellable.us();
}
function promise(_this__u8e3s4, context, start, block) {
  context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
  start = start === VOID ? CoroutineStart_DEFAULT_getInstance() : start;
  return asPromise(async(_this__u8e3s4, context, start, block));
}
function asPromise(_this__u8e3s4) {
  var promise = new Promise(asPromise$lambda(_this__u8e3s4));
  // Inline function 'kotlin.js.asDynamic' call
  promise.deferred = _this__u8e3s4;
  return promise;
}
function await$lambda($cont) {
  return function (it) {
    // Inline function 'kotlin.coroutines.resume' call
    var this_0 = $cont;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(it);
    this_0.hf(tmp$ret$0);
    return Unit_instance;
  };
}
function await$lambda_0($cont) {
  return function (it) {
    // Inline function 'kotlin.coroutines.resumeWithException' call
    var this_0 = $cont;
    // Inline function 'kotlin.Companion.failure' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(it));
    this_0.hf(tmp$ret$0);
    return Unit_instance;
  };
}
function asPromise$lambda$lambda($this_asPromise, $reject, $resolve) {
  return function (it) {
    var e = $this_asPromise.sq();
    var tmp;
    if (!(e == null)) {
      tmp = $reject(e);
    } else {
      tmp = $resolve($this_asPromise.wr());
    }
    return Unit_instance;
  };
}
function asPromise$lambda($this_asPromise) {
  return function (resolve, reject) {
    $this_asPromise.zp(asPromise$lambda$lambda($this_asPromise, reject, resolve));
    return Unit_instance;
  };
}
function asCoroutineDispatcher(_this__u8e3s4) {
  // Inline function 'kotlin.js.asDynamic' call
  var tmp0_elvis_lhs = _this__u8e3s4.coroutineDispatcher;
  var tmp;
  if (tmp0_elvis_lhs == null) {
    // Inline function 'kotlin.also' call
    var this_0 = new WindowDispatcher(_this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.asCoroutineDispatcher.<anonymous>' call
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.coroutineDispatcher = this_0;
    tmp = this_0;
  } else {
    tmp = tmp0_elvis_lhs;
  }
  return tmp;
}
function propagateExceptionFinalResort(exception) {
  console.error(exception.toString());
}
var Dispatchers_instance;
function Dispatchers_getInstance() {
  if (Dispatchers_instance === VOID)
    new Dispatchers();
  return Dispatchers_instance;
}
function createEventLoop() {
  return new UnconfinedEventLoop();
}
function unsupported() {
  throw UnsupportedOperationException.r5('runBlocking event loop is not supported');
}
function get_taskContext(_this__u8e3s4) {
  return TaskContext_instance;
}
var TaskContext_instance;
function TaskContext_getInstance() {
  return TaskContext_instance;
}
function identitySet(expectedSize) {
  return HashSet.z5(expectedSize);
}
function get_platformExceptionHandlers_() {
  _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf();
  return platformExceptionHandlers_;
}
var platformExceptionHandlers_;
function get_platformExceptionHandlers() {
  _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf();
  return get_platformExceptionHandlers_();
}
var properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx;
function _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf() {
  if (!properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx) {
    properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx = true;
    // Inline function 'kotlin.collections.mutableSetOf' call
    platformExceptionHandlers_ = LinkedHashSet.y7();
  }
}
var SetTimeoutDispatcher_instance;
function SetTimeoutDispatcher_getInstance() {
  if (SetTimeoutDispatcher_instance === VOID)
    new SetTimeoutDispatcher();
  return SetTimeoutDispatcher_instance;
}
function SetTimeoutBasedDispatcher$scheduleResumeAfterDelay$lambda($continuation, this$0) {
  return function () {
    // Inline function 'kotlin.with' call
    // Inline function 'kotlin.contracts.contract' call
    $continuation.xt(this$0, Unit_instance);
    return Unit_instance;
  };
}
function WindowDispatcher$scheduleResumeAfterDelay$lambda($continuation, this$0) {
  return function () {
    // Inline function 'kotlin.with' call
    // Inline function 'kotlin.contracts.contract' call
    $continuation.xt(this$0, Unit_instance);
    return Unit_instance;
  };
}
function delayToInt(timeMillis) {
  return coerceIn(timeMillis, new Long(0, 0), new Long(2147483647, 0)).qe();
}
function unwrap(exception) {
  return exception;
}
function recoverStackTrace(exception, continuation) {
  return exception;
}
function recoverStackTrace_0(exception) {
  return exception;
}
function systemProp_1(propertyName) {
  return null;
}
function threadContextElements(context) {
  return 0;
}
function commonThreadLocal(name) {
  return new CommonThreadLocal();
}
//region block: post-declaration
setMetadataFor(Job, 'Job', interfaceMeta, VOID, [Element], VOID, VOID, VOID, [0]);
setMetadataFor(ParentJob, 'ParentJob', interfaceMeta, VOID, [Job], VOID, VOID, VOID, [0]);
protoOf(JobSupport).bq = invokeOnCompletion$default;
protoOf(JobSupport).gq = cancel$default;
protoOf(JobSupport).oi = plus;
protoOf(JobSupport).bf = get_0;
protoOf(JobSupport).ni = fold;
protoOf(JobSupport).mi = minusKey_0;
setMetadataFor(JobSupport, 'JobSupport', classMeta, VOID, [Job, ParentJob], VOID, VOID, VOID, [0]);
setMetadataFor(CoroutineScope, 'CoroutineScope', interfaceMeta);
setMetadataFor(AbstractCoroutine, 'AbstractCoroutine', classMeta, VOID, [JobSupport, Job, Continuation, CoroutineScope], VOID, VOID, VOID, [0]);
setMetadataFor(NotCompleted, 'NotCompleted', interfaceMeta);
setMetadataFor(CancelHandler, 'CancelHandler', interfaceMeta, VOID, [NotCompleted]);
setMetadataFor(DisposeHandlersOnCancel, 'DisposeHandlersOnCancel', classMeta, VOID, [CancelHandler]);
setMetadataFor(LinkedListNode, 'LinkedListNode', classMeta, VOID, VOID, LinkedListNode);
setMetadataFor(Incomplete, 'Incomplete', interfaceMeta);
setMetadataFor(JobNode, 'JobNode', classMeta, VOID, [LinkedListNode, Incomplete]);
setMetadataFor(AwaitAllNode, 'AwaitAllNode', classMeta);
setMetadataFor(AwaitAll, 'AwaitAll', classMeta, VOID, VOID, VOID, VOID, VOID, [0]);
setMetadataFor($awaitAllCOROUTINE$0, '$awaitAllCOROUTINE$0', classMeta);
setMetadataFor(StandaloneCoroutine, 'StandaloneCoroutine', classMeta, VOID, VOID, VOID, VOID, VOID, [0]);
setMetadataFor(LazyStandaloneCoroutine, 'LazyStandaloneCoroutine', classMeta, VOID, VOID, VOID, VOID, VOID, [0]);
setMetadataFor($awaitCOROUTINE$1, '$awaitCOROUTINE$1', classMeta);
setMetadataFor(DeferredCoroutine, 'DeferredCoroutine', classMeta, VOID, [AbstractCoroutine, Job], VOID, VOID, VOID, [0]);
setMetadataFor(LazyDeferredCoroutine, 'LazyDeferredCoroutine', classMeta, VOID, VOID, VOID, VOID, VOID, [0]);
setMetadataFor(CancellableContinuation, 'CancellableContinuation', interfaceMeta, VOID, [Continuation]);
setMetadataFor(DisposeOnCancel, 'DisposeOnCancel', classMeta, VOID, [CancelHandler]);
setMetadataFor(Runnable, 'Runnable', interfaceMeta);
setMetadataFor(SchedulerTask, 'SchedulerTask', classMeta, VOID, [Runnable]);
setMetadataFor(DispatchedTask, 'DispatchedTask', classMeta);
setMetadataFor(Waiter, 'Waiter', interfaceMeta);
setMetadataFor(CancellableContinuationImpl, 'CancellableContinuationImpl', classMeta, VOID, [DispatchedTask, CancellableContinuation, Waiter]);
setMetadataFor(UserSupplied, 'UserSupplied', classMeta, VOID, [CancelHandler]);
setMetadataFor(Active, 'Active', objectMeta, VOID, [NotCompleted]);
setMetadataFor(CompletedContinuation, 'CompletedContinuation', classMeta);
setMetadataFor($awaitCOROUTINE$2, '$awaitCOROUTINE$2', classMeta);
setMetadataFor(CompletableDeferredImpl, 'CompletableDeferredImpl', classMeta, VOID, [JobSupport, Job], VOID, VOID, VOID, [0]);
setMetadataFor(CompletableJob, 'CompletableJob', interfaceMeta, VOID, [Job], VOID, VOID, VOID, [0]);
setMetadataFor(UserSupplied_0, 'UserSupplied', classMeta);
setMetadataFor(CompletedExceptionally, 'CompletedExceptionally', classMeta);
setMetadataFor(CancelledContinuation, 'CancelledContinuation', classMeta);
setMetadataFor(CompletedWithCancellation, 'CompletedWithCancellation', classMeta);
setMetadataFor(Key, 'Key', objectMeta);
protoOf(CoroutineDispatcher).bf = get;
protoOf(CoroutineDispatcher).mi = minusKey;
setMetadataFor(CoroutineDispatcher, 'CoroutineDispatcher', classMeta, VOID, [AbstractCoroutineContextElement, ContinuationInterceptor]);
setMetadataFor(Key_0, 'Key', objectMeta);
setMetadataFor(Key_1, 'Key', objectMeta);
setMetadataFor(CoroutineName, 'CoroutineName', classMeta);
setMetadataFor(GlobalScope, 'GlobalScope', objectMeta, VOID, [CoroutineScope]);
setMetadataFor(CoroutineStart, 'CoroutineStart', classMeta);
setMetadataFor(Delay, 'Delay', interfaceMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(EventLoop, 'EventLoop', classMeta);
setMetadataFor(ThreadLocalEventLoop, 'ThreadLocalEventLoop', objectMeta);
setMetadataFor(CompletionHandlerException, 'CompletionHandlerException', classMeta);
setMetadataFor(CoroutinesInternalError, 'CoroutinesInternalError', classMeta);
setMetadataFor(Key_2, 'Key', objectMeta);
setMetadataFor(ChildHandle, 'ChildHandle', interfaceMeta);
setMetadataFor(NonDisposableHandle, 'NonDisposableHandle', objectMeta, VOID, [ChildHandle]);
setMetadataFor(Empty, 'Empty', classMeta, VOID, [Incomplete]);
setMetadataFor(LinkedListHead, 'LinkedListHead', classMeta, VOID, VOID, LinkedListHead);
setMetadataFor(NodeList, 'NodeList', classMeta, VOID, [LinkedListHead, Incomplete], NodeList);
setMetadataFor(SynchronizedObject, 'SynchronizedObject', classMeta, VOID, VOID, SynchronizedObject);
setMetadataFor(Finishing, 'Finishing', classMeta, VOID, [SynchronizedObject, Incomplete]);
setMetadataFor(ChildCompletion, 'ChildCompletion', classMeta);
setMetadataFor(AwaitContinuation, 'AwaitContinuation', classMeta);
setMetadataFor(JobCancellingNode, 'JobCancellingNode', classMeta);
setMetadataFor(InactiveNodeList, 'InactiveNodeList', classMeta, VOID, [Incomplete]);
setMetadataFor(ChildHandleNode, 'ChildHandleNode', classMeta, VOID, [JobCancellingNode, ChildHandle]);
setMetadataFor(InvokeOnCancelling, 'InvokeOnCancelling', classMeta);
setMetadataFor(InvokeOnCompletion, 'InvokeOnCompletion', classMeta);
setMetadataFor(ResumeOnCompletion, 'ResumeOnCompletion', classMeta);
setMetadataFor(ResumeAwaitOnCompletion, 'ResumeAwaitOnCompletion', classMeta);
setMetadataFor(IncompleteStateBox, 'IncompleteStateBox', classMeta);
setMetadataFor(ChildContinuation, 'ChildContinuation', classMeta);
setMetadataFor(JobImpl, 'JobImpl', classMeta, VOID, [JobSupport, CompletableJob], VOID, VOID, VOID, [0]);
setMetadataFor(MainCoroutineDispatcher, 'MainCoroutineDispatcher', classMeta);
setMetadataFor(SupervisorJobImpl, 'SupervisorJobImpl', classMeta, VOID, VOID, VOID, VOID, VOID, [0]);
setMetadataFor(TimeoutCancellationException, 'TimeoutCancellationException', classMeta);
setMetadataFor(Unconfined, 'Unconfined', objectMeta);
setMetadataFor(Key_3, 'Key', objectMeta);
setMetadataFor(BufferOverflow, 'BufferOverflow', classMeta);
setMetadataFor(ConcurrentLinkedListNode, 'ConcurrentLinkedListNode', classMeta);
setMetadataFor(Segment, 'Segment', classMeta, VOID, [ConcurrentLinkedListNode, NotCompleted]);
setMetadataFor(ChannelSegment, 'ChannelSegment', classMeta);
setMetadataFor($hasNextCOROUTINE$6, '$hasNextCOROUTINE$6', classMeta);
setMetadataFor(SendBroadcast, 'SendBroadcast', classMeta, VOID, [Waiter]);
setMetadataFor(BufferedChannelIterator, 'BufferedChannelIterator', classMeta, VOID, [Waiter], VOID, VOID, VOID, [0, 3]);
setMetadataFor(SendChannel, 'SendChannel', interfaceMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ReceiveChannel, 'ReceiveChannel', interfaceMeta, VOID, VOID, VOID, VOID, VOID, [0]);
protoOf(BufferedChannel).p15 = close$default;
protoOf(BufferedChannel).r15 = cancel$default_0;
setMetadataFor(BufferedChannel, 'BufferedChannel', classMeta, VOID, [SendChannel, ReceiveChannel], VOID, VOID, VOID, [1, 4, 0, 3]);
setMetadataFor(WaiterEB, 'WaiterEB', classMeta);
setMetadataFor(ReceiveCatching, 'ReceiveCatching', classMeta, VOID, [Waiter]);
setMetadataFor(Factory, 'Factory', objectMeta);
setMetadataFor(Failed, 'Failed', classMeta, VOID, VOID, Failed);
setMetadataFor(Closed, 'Closed', classMeta);
setMetadataFor(Companion, 'Companion', objectMeta);
setMetadataFor(ChannelResult, 'ChannelResult', classMeta);
setMetadataFor(ClosedSendChannelException, 'ClosedSendChannelException', classMeta);
setMetadataFor(ClosedReceiveChannelException, 'ClosedReceiveChannelException', classMeta);
setMetadataFor(ConflatedBufferedChannel, 'ConflatedBufferedChannel', classMeta, VOID, VOID, VOID, VOID, VOID, [1, 0]);
setMetadataFor($collectCOROUTINE$10, '$collectCOROUTINE$10', classMeta);
setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor($emitCOROUTINE$16, '$emitCOROUTINE$16', classMeta);
setMetadataFor(_no_name_provided__qut3iv_0, VOID, classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor($firstOrNullCOROUTINE$15, '$firstOrNullCOROUTINE$15', classMeta);
setMetadataFor(OpDescriptor, 'OpDescriptor', classMeta);
setMetadataFor(SegmentOrClosed, 'SegmentOrClosed', classMeta);
setMetadataFor(ExceptionSuccessfullyProcessed, 'ExceptionSuccessfullyProcessed', objectMeta);
setMetadataFor(DispatchedContinuation, 'DispatchedContinuation', classMeta, VOID, [DispatchedTask, Continuation]);
setMetadataFor(UndeliveredElementException, 'UndeliveredElementException', classMeta);
setMetadataFor(ContextScope, 'ContextScope', classMeta, VOID, [CoroutineScope]);
setMetadataFor(Symbol, 'Symbol', classMeta);
setMetadataFor(SelectInstance, 'SelectInstance', interfaceMeta);
setMetadataFor(ClauseData, 'ClauseData', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SelectImplementation, 'SelectImplementation', classMeta, VOID, [CancelHandler, Waiter, SelectInstance], VOID, VOID, VOID, [0, 2]);
setMetadataFor(TrySelectDetailedResult, 'TrySelectDetailedResult', classMeta);
setMetadataFor(SetTimeoutBasedDispatcher, 'SetTimeoutBasedDispatcher', classMeta, VOID, [CoroutineDispatcher, Delay], VOID, VOID, VOID, [1]);
setMetadataFor(NodeDispatcher, 'NodeDispatcher', objectMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(MessageQueue, 'MessageQueue', classMeta, VOID, [MutableList]);
setMetadataFor(ScheduledMessageQueue, 'ScheduledMessageQueue', classMeta);
setMetadataFor(WindowMessageQueue, 'WindowMessageQueue', classMeta);
setMetadataFor(Dispatchers, 'Dispatchers', objectMeta);
setMetadataFor(JsMainDispatcher, 'JsMainDispatcher', classMeta);
setMetadataFor(UnconfinedEventLoop, 'UnconfinedEventLoop', classMeta, VOID, VOID, UnconfinedEventLoop);
setMetadataFor(JobCancellationException, 'JobCancellationException', classMeta);
setMetadataFor(TaskContext, 'TaskContext', objectMeta);
setMetadataFor(AbortFlowException, 'AbortFlowException', classMeta);
setMetadataFor(DiagnosticCoroutineContextException, 'DiagnosticCoroutineContextException', classMeta);
setMetadataFor(SetTimeoutDispatcher, 'SetTimeoutDispatcher', objectMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ClearTimeout, 'ClearTimeout', classMeta, VOID, [CancelHandler]);
setMetadataFor(WindowClearTimeout, 'WindowClearTimeout', classMeta);
setMetadataFor(WindowDispatcher, 'WindowDispatcher', classMeta, VOID, [CoroutineDispatcher, Delay], VOID, VOID, VOID, [1]);
setMetadataFor(CommonThreadLocal, 'CommonThreadLocal', classMeta, VOID, VOID, CommonThreadLocal);
//endregion
//region block: init
Active_instance = new Active();
Key_instance_1 = new Key_0();
Key_instance_2 = new Key_1();
GlobalScope_instance = new GlobalScope();
Key_instance_3 = new Key_2();
NonDisposableHandle_instance = new NonDisposableHandle();
Key_instance_4 = new Key_3();
DEFAULT_CLOSE_MESSAGE = 'Channel was closed';
MODE_CANCELLABLE = 1;
MODE_CANCELLABLE_REUSABLE = 2;
MODE_UNINITIALIZED = -1;
MODE_UNDISPATCHED = 4;
MODE_ATOMIC = 0;
counter = 0;
DEBUG = false;
TaskContext_instance = new TaskContext();
//endregion
//region block: exports
export {
  firstOrNull as firstOrNull3jjcu7fygcopr,
  awaitAll as awaitAll3u8r91uegk3sa,
  await_0 as await20nhgj9iqzkt,
  delay as delayolwo40i9ucjz,
  cancel$default as cancel$default2y9qiqa2pd5sh,
  invokeOnCompletion$default as invokeOnCompletion$default32r7l1ykbkzvm,
  _ChannelResult___get_isSuccess__impl__odq1z9 as _ChannelResult___get_isSuccess__impl__odq1z912klyyef6kn9b,
  Factory_getInstance as Factory_getInstance11pxe45am9y7d,
  Key_getInstance as Key_getInstance2js7sv2agqob1,
  Key_instance_1 as Key_instance2ye2t23iqghop,
  Dispatchers_getInstance as Dispatchers_getInstanceitgtkvqfcnx3,
  GlobalScope_instance as GlobalScope_instance1sfulufhd2ijt,
  Key_instance_3 as Key_instance2tirv2rj82ml4,
  Channel as Channel3r72atmcithql,
  cancelConsumed as cancelConsumed2i0oizqhmljf6,
  asFlow as asFlow3ngsnn5xpz8pw,
  LinkedListNode as LinkedListNode3tts9l6uzdsny,
  recoverStackTrace as recoverStackTrace2i3si2i8nvw1k,
  CancellableContinuationImpl as CancellableContinuationImpl1cx201opicavg,
  CompletableDeferred as CompletableDeferred2lnqvsbvx74d3,
  CompletableJob as CompletableJob1w6swnu15iclo,
  CoroutineName as CoroutineName2g5zosw74tf0f,
  CoroutineScope_0 as CoroutineScopelux7s7zphw7e,
  CoroutineScope as CoroutineScopefcb5f5dwqcas,
  Job_0 as Job13y4jkazwjho0,
  Job as Job29shfjfygy86k,
  get_MODE_CANCELLABLE as get_MODE_CANCELLABLE3br9qqv7xeh9e,
  MainScope as MainScope1gi1r4abhrtmm,
  SupervisorJob as SupervisorJobythnfxkr3jxc,
  async as asyncz02dsa2nb2zt,
  cancel as cancel36mj9lv3a0whl,
  cancel_2 as cancel2en0dn4yvpufo,
  cancel_0 as cancel2ztysw4v4hav6,
  cancel_1 as cancel1xim2hrvjmwpn,
  get_job as get_job2zvlvce9o9a29,
  launch as launch1c91vkjzdi9sd,
  promise as promise1ky6tawqaxbt4,
};
//endregion


