<!--
  ~ Castalytics GmbH (c) 2022-2024
  ~ Project: snipocc
  -->

<kendo-dialog-titlebar>
  <span>{{'user.action.preferences' | translate}}</span>
</kendo-dialog-titlebar>

<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label [for]="input"></kendo-label>
    <kendo-textbox
      #input
      formControlName="input"
    >
    </kendo-textbox>
  </kendo-formfield>
</form>

<kendo-dialog-actions>
  <button kendoButton (click)="cancel()">Discard</button>
  <button kendoButton themeColor="primary" (click)="save()">Save</button>
</kendo-dialog-actions>
