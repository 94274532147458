/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { roleFromName, type Roles, type User } from '@snipocc/api';
import { EMPTY, expand, filter, type Observable, of, Subject, switchMap, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { isEmpty } from 'lodash-es';
import { notNull } from '@core/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private endpoint = `${environment.apiURL}/users`;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
  ) {
  }

  public isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  public hasAnyRole(...roles: Roles[]): Observable<boolean> {
    if (isEmpty(roles)) {
      return of(false);
    }
    return this.authService.getTokenAsJwt().pipe(map(jwt => {
      if (!jwt?.scp) return false;

      const scopes = jwt.scp.split(' ');
      for (const scope of scopes) {
        const role = roleFromName(scope);
        if (role && roles.includes(role)) {
          return true;
        }
      }
      return false;
    }));
  }

  public getCurrentUser(): Observable<User | null> {
    if (!this.isLoggedIn()) {
      return of(null);
    }
    return this.http.get<User>(`${this.endpoint}/self`);
  }

  public getUser(id: string): Observable<User | null> {
    return this.http.get<User>(`${this.endpoint}/${id}`);
  }

  public updateUser(id: string, user: User) {
    const u = {...user, roles: [user.roles[0].name]}
    return this.http.put(`${this.endpoint}/${id}`, u, {observe: 'response'})
  }

  public deleteUser(id: string) {
    return this.http.delete(`${this.endpoint}/${id}`)
  }

  public loadAll(usersFilter?: string): Observable<User> {
    const endpoint = usersFilter ? `${this.endpoint}?filter=${usersFilter}` : this.endpoint;
    const users = new Subject<User>();
    this.authService.getToken().pipe(
      take(1),
      filter(notNull),
      switchMap(token =>
        fetch(endpoint, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/x+ndjson' } }),
      ),
      map(r => r.body!.getReader()),
      expand(async (reader) => {
        const { done, value } = await reader.read();

        const decoder = new TextDecoder();
        const decoded = decoder.decode(value);
        const payload = decoded.split('\n');
        for (const json of payload) {
          if (json.trim().length === 0) break;
          const user = JSON.parse(json.trim()) as User;
          users.next(user);
        }

        if (done) {
          users.complete();
          throw Error;
        }

        return reader;
      }),
      catchError(() => EMPTY),
    ).subscribe();
    return users;
  }

}
