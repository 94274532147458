import {
  Long2qws0ah9gnpki as Long,
  VOID7hggqo3abtya as VOID,
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  boxApply1qmzdb3dh90hg as boxApply,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class AtomicInt {
  constructor(initialValue) {
    initialValue = initialValue === VOID ? 0 : initialValue;
    this.dm_1 = initialValue;
  }
  em(delta) {
    this.dm_1 = this.dm_1 + delta | 0;
    return this.dm_1;
  }
  fm(expectedValue, newValue) {
    var tmp;
    if (this.dm_1 === expectedValue) {
      this.dm_1 = newValue;
      tmp = true;
    } else {
      tmp = false;
    }
    return tmp;
  }
}
class AtomicReference {
  constructor(initialValue) {
    this.gm_1 = initialValue;
  }
}
class Lock {
  constructor($box) {
    boxApply(this, $box);
  }
}
//endregion
var currentThreadId;
var currentThreadName;
//region block: post-declaration
setMetadataFor(AtomicInt, 'AtomicInt', classMeta, VOID, VOID, AtomicInt);
setMetadataFor(AtomicReference, 'AtomicReference', classMeta);
setMetadataFor(Lock, 'Lock', classMeta, VOID, VOID, Lock);
//endregion
//region block: init
currentThreadId = new Long(0, 0);
currentThreadName = 'main';
//endregion
//region block: exports
export {
  AtomicInt as AtomicInt7cpb8evavny9,
  AtomicReference as AtomicReferencep9mj81mx4hlz,
  Lock as Lock1e4o67f97292w,
};
//endregion


