/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import * as npm from '../../package.json'

export const environment = {
  productions: false,
  backendURL: '',
  apiURL: '/api/v1',
  version: npm.version
};
