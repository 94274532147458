import {
  Unit_instance1fbcbse1fwigr as Unit_instance,
  interfaceMeta1u1l5puptm1ve as interfaceMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  VOID7hggqo3abtya as VOID,
  classMetawt99a3kyl3us as classMeta,
  objectMeta213120oau977m as objectMeta,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  equals2au1ep9vhcato as equals,
  ArrayList3it5z8td81qkl as ArrayList,
  hashCodeq5arwsb9dgti as hashCode,
  isInterface3d6p8outrmvmk as isInterface,
  printStackTrace18lnx7a39cni as printStackTrace,
  ArrayDeque2dzc9uld4xi7n as ArrayDeque,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  toString1pkumu07cwy4m as toString,
  RuntimeException1r3t0zl97011n as RuntimeException,
  captureStack1fzi4aczwc4hg as captureStack,
} from './kotlin-kotlin-stdlib.mjs';
import {
  AtomicReferencep9mj81mx4hlz as AtomicReference,
  Lock1e4o67f97292w as Lock,
  AtomicInt7cpb8evavny9 as AtomicInt,
} from './Reaktive-utils-internal.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class ObservableObserver {}
class subscribe$1 {
  constructor($serialDisposable, $onNext, $onComplete, $onError) {
    this.om_1 = $serialDisposable;
    this.pm_1 = $onNext;
    this.qm_1 = $onComplete;
    this.rm_1 = $onError;
  }
  sm(disposable) {
    this.om_1.tm(disposable);
  }
  jm(value) {
    // Inline function 'com.badoo.reaktive.disposable.doIfNotDisposed' call
    var this_0 = this.om_1;
    if (!this_0.um()) {
      try {
        // Inline function 'com.badoo.reaktive.observable.<no name provided>.onNext.<anonymous>' call
        try {
          var tmp0_safe_receiver = this.pm_1;
          if (tmp0_safe_receiver == null)
            null;
          else
            tmp0_safe_receiver(value);
        } catch ($p) {
          if ($p instanceof Error) {
            var e = $p;
            this.im(e);
          } else {
            throw $p;
          }
        }
      }finally {
        if (false) {
          this_0.nm();
        }
      }
    }
  }
  km() {
    // Inline function 'com.badoo.reaktive.disposable.doIfNotDisposed' call
    var this_0 = this.om_1;
    if (!this_0.um()) {
      try {
        // Inline function 'com.badoo.reaktive.observable.<no name provided>.onComplete.<anonymous>' call
        try {
          var tmp0_safe_receiver = this.qm_1;
          if (tmp0_safe_receiver == null)
            null;
          else
            tmp0_safe_receiver();
        } catch ($p) {
          if ($p instanceof Error) {
            var e = $p;
            handleReaktiveError(e);
          } else {
            throw $p;
          }
        }
      }finally {
        if (true) {
          this_0.nm();
        }
      }
    }
  }
  im(error) {
    // Inline function 'com.badoo.reaktive.disposable.doIfNotDisposed' call
    var this_0 = this.om_1;
    if (!this_0.um()) {
      try {
        // Inline function 'com.badoo.reaktive.observable.<no name provided>.onError.<anonymous>' call
        handleReaktiveError(error, this.rm_1);
      }finally {
        if (true) {
          this_0.nm();
        }
      }
    }
  }
}
class Event {}
class OnSubscribe extends Event {
  constructor(observer, disposable, token) {
    super();
    this.vm_1 = observer;
    this.wm_1 = disposable;
    this.xm_1 = token;
  }
}
class OnUnsubscribe extends Event {
  constructor(observer) {
    super();
    this.ym_1 = observer;
  }
}
class OnComplete extends Event {}
class OnError extends Event {
  constructor(error) {
    super();
    this.zm_1 = error;
  }
}
class AbstractSerializer extends Lock {
  constructor($box) {
    super($box);
    this.rn_1 = new AtomicInt();
  }
  sn(value) {
    if (this.rn_1.fm(0, 1)) {
      if (!this.nn(value)) {
        this.rn_1.dm_1 = -1;
        return Unit_instance;
      }
      if (this.rn_1.em(-1) === 0) {
        return Unit_instance;
      }
    } else {
      if (this.rn_1.dm_1 < 0) {
        return Unit_instance;
      }
      // Inline function 'com.badoo.reaktive.utils.lock.Lock.synchronized' call
      this.qn(value);
      // Inline function 'com.badoo.reaktive.utils.atomic.changeAndGet' call
      var this_0 = this.rn_1;
      var next;
      do {
        var prev = this_0.dm_1;
        // Inline function 'com.badoo.reaktive.utils.serializer.AbstractSerializer.accept.<anonymous>' call
        var it = prev;
        next = it >= 0 ? it + 1 | 0 : it;
      }
       while (!this_0.fm(prev, next));
      if (!(next === 1)) {
        return Unit_instance;
      }
    }
    drainLoop(this);
  }
}
class DefaultSerializer extends AbstractSerializer {
  constructor($box) {
    super($box);
    this.pn_1 = null;
  }
  qn(value) {
    var tmp0_elvis_lhs = this.pn_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.also' call
      var this_0 = ArrayDeque.jh();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.badoo.reaktive.utils.serializer.DefaultSerializer.addLast.<anonymous>' call
      this.pn_1 = this_0;
      tmp = this_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    queue.o(value);
  }
  z() {
    var tmp0_safe_receiver = this.pn_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.z();
    return tmp1_elvis_lhs == null ? true : tmp1_elvis_lhs;
  }
  mh() {
    // Inline function 'kotlin.requireNotNull' call
    var value = this.pn_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.requireNotNull' call
      // Inline function 'kotlin.contracts.contract' call
      if (value == null) {
        // Inline function 'kotlin.requireNotNull.<anonymous>' call
        var message = 'Required value was null.';
        throw IllegalArgumentException.g3(toString(message));
      } else {
        tmp$ret$1 = value;
        break $l$block;
      }
    }
    return tmp$ret$1.mh();
  }
}
class _no_name_provided__qut3iv extends DefaultSerializer {
  constructor(this$0, $box) {
    if ($box === VOID)
      $box = {};
    $box.ln_1 = this$0;
    super($box);
  }
  mn(value) {
    return onSerializedValue(this.ln_1, value);
  }
  nn(value) {
    return this.mn((value == null ? true : !(value == null)) ? value : THROW_CCE());
  }
}
class _no_name_provided__qut3iv_0 {
  constructor(this$0, $observer) {
    this.un_1 = this$0;
    this.vn_1 = $observer;
    this.tn_1 = false;
  }
  um() {
    return this.tn_1;
  }
  nm() {
    if (!this.tn_1) {
      this.tn_1 = true;
      // Inline function 'com.badoo.reaktive.subject.AbstractSubject.onSerializedSubscribe.<anonymous>' call
      this.un_1.bn_1.sn(new OnUnsubscribe(this.vn_1));
    }
  }
}
class AbstractSubject {
  constructor() {
    this.an_1 = ArrayList.o3();
    var tmp = this;
    // Inline function 'com.badoo.reaktive.utils.serializer.serializer' call
    tmp.bn_1 = new _no_name_provided__qut3iv(this);
    this.cn_1 = new AtomicReference(Active_getInstance());
  }
  in(value) {
    this.cn_1.gm_1 = value;
    this.wn(value);
  }
  dn() {
    return this.cn_1.gm_1;
  }
  jm(value) {
    this.bn_1.sn(value);
  }
  im(error) {
    this.bn_1.sn(new OnError(error));
  }
  xn(observer, disposable, token) {
    this.bn_1.sn(new OnSubscribe(observer, disposable, token));
  }
  yn(_this__u8e3s4) {
    var disposable = new SerialDisposable();
    _this__u8e3s4.sm(disposable);
    // Inline function 'kotlin.takeUnless' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    if (!disposable.um()) {
      tmp = disposable;
    } else {
      tmp = null;
    }
    return tmp;
  }
  fn(observer, token) {
  }
  gn(observer) {
  }
  hn(value) {
  }
  wn(status) {
  }
}
class Status {}
class Active extends Status {
  constructor() {
    Active_instance = null;
    super();
    Active_instance = this;
  }
}
class Completed extends Status {
  constructor() {
    Completed_instance = null;
    super();
    Completed_instance = this;
  }
}
class Error_0 extends Status {
  constructor(error) {
    super();
    this.en_1 = error;
  }
  toString() {
    return 'Error(error=' + this.en_1 + ')';
  }
  hashCode() {
    return hashCode(this.en_1);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof Error_0))
      return false;
    var tmp0_other_with_cast = other instanceof Error_0 ? other : THROW_CCE();
    if (!equals(this.en_1, tmp0_other_with_cast.en_1))
      return false;
    return true;
  }
}
class PublishSubject$1 extends AbstractSubject {
  co(observer) {
    var tmp0_elvis_lhs = this.yn(observer);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var disposable = tmp;
    this.xn(observer, disposable, null);
  }
  hm(observer) {
    return this.co(isInterface(observer, ObservableObserver) ? observer : THROW_CCE());
  }
}
class CompositeException extends RuntimeException {
  static io(cause1, cause2) {
    var $this = this.ag();
    captureStack($this, $this.ho_1);
    $this.fo_1 = cause1;
    $this.go_1 = cause2;
    return $this;
  }
}
class SerialDisposable {
  constructor() {
    this.lm_1 = false;
    this.mm_1 = null;
  }
  um() {
    return this.lm_1;
  }
  nm() {
    var tmp0_safe_receiver = this.jo();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.nm();
    }
  }
  jo() {
    // Inline function 'com.badoo.reaktive.utils.synchronizedCompat' call
    // Inline function 'com.badoo.reaktive.disposable.SerialDisposable.clearAndDispose.<anonymous>' call
    this.lm_1 = true;
    return swapDisposable(this, null);
  }
  tm(disposable) {
    var tmp0_safe_receiver = this.ko(disposable);
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.nm();
    }
  }
  ko(disposable) {
    var disposableToDispose = null;
    var oldDisposable = null;
    // Inline function 'com.badoo.reaktive.utils.synchronizedCompat' call
    var tmp;
    if (this.lm_1) {
      disposableToDispose = disposable;
      tmp = Unit_instance;
    } else {
      oldDisposable = swapDisposable(this, disposable);
      tmp = Unit_instance;
    }
    var tmp0_safe_receiver = disposableToDispose;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.nm();
    }
    return oldDisposable;
  }
}
//endregion
function subscribeSafe(_this__u8e3s4, observer) {
  try {
    _this__u8e3s4.hm(observer);
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      handleReaktiveError(e, ErrorCallback$onError$ref(observer));
    } else {
      throw $p;
    }
  }
}
function ErrorCallback$onError$ref($boundThis) {
  var l = function (p0) {
    $boundThis.im(p0);
    return Unit_instance;
  };
  l.callableName = 'onError';
  return l;
}
function subscribe(_this__u8e3s4, onSubscribe, onError, onComplete, onNext) {
  onSubscribe = onSubscribe === VOID ? null : onSubscribe;
  onError = onError === VOID ? null : onError;
  onComplete = onComplete === VOID ? null : onComplete;
  onNext = onNext === VOID ? null : onNext;
  var serialDisposable = new SerialDisposable();
  try {
    if (onSubscribe == null)
      null;
    else
      onSubscribe(serialDisposable);
  } catch ($p) {
    if ($p instanceof Error) {
      var e = $p;
      try {
        handleReaktiveError(e, onError);
      }finally {
        serialDisposable.nm();
      }
      return serialDisposable;
    } else {
      throw $p;
    }
  }
  subscribeSafe(_this__u8e3s4, new subscribe$1(serialDisposable, onNext, onComplete, onError));
  return serialDisposable;
}
var plugins;
function onSerializedValue($this, value) {
  if (value instanceof Event) {
    var event = value instanceof Event ? value : THROW_CCE();
    if (event instanceof OnSubscribe) {
      onSerializedSubscribe($this, event.vm_1, event.wm_1, event.xm_1);
    } else {
      if (event instanceof OnUnsubscribe) {
        onSerializedUnsubscribe($this, event.ym_1);
      } else {
        if (event instanceof OnComplete) {
          onSerializedComplete($this);
        } else {
          if (event instanceof OnError) {
            onSerializedError($this, event.zm_1);
          }
        }
      }
    }
  } else {
    onSerializedNext($this, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  }
  return true;
}
function onSerializedSubscribe($this, observer, disposable, token) {
  if (disposable.um()) {
    return Unit_instance;
  }
  // Inline function 'com.badoo.reaktive.disposable.Disposable' call
  var tmp$ret$0 = new _no_name_provided__qut3iv_0($this, observer);
  disposable.tm(tmp$ret$0);
  var status = $this.dn();
  if (equals(status, Active_getInstance())) {
    // Inline function 'kotlin.collections.plusAssign' call
    $this.an_1.o(observer);
    $this.fn(observer, token);
  } else {
    if (equals(status, Completed_getInstance())) {
      observer.km();
    } else {
      if (status instanceof Error_0) {
        observer.im(status.en_1);
      }
    }
  }
}
function onSerializedUnsubscribe($this, observer) {
  // Inline function 'kotlin.collections.minusAssign' call
  $this.an_1.p(observer);
  $this.gn(observer);
}
function onSerializedNext($this, value) {
  if (get_isActive($this)) {
    $this.hn(value);
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = $this.an_1.r();
    while (tmp0_iterator.s()) {
      var element = tmp0_iterator.u();
      // Inline function 'com.badoo.reaktive.subject.AbstractSubject.onSerializedNext.<anonymous>' call
      element.jm(value);
    }
  }
}
function onSerializedComplete($this) {
  if (get_isActive($this)) {
    $this.in(Completed_getInstance());
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = $this.an_1.r();
    while (tmp0_iterator.s()) {
      var element = tmp0_iterator.u();
      element.km();
    }
  }
}
function onSerializedError($this, error) {
  if (get_isActive($this)) {
    $this.in(new Error_0(error));
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = $this.an_1.r();
    while (tmp0_iterator.s()) {
      var element = tmp0_iterator.u();
      // Inline function 'com.badoo.reaktive.subject.AbstractSubject.onSerializedError.<anonymous>' call
      element.im(error);
    }
  }
}
var Active_instance;
function Active_getInstance() {
  if (Active_instance === VOID)
    new Active();
  return Active_instance;
}
var Completed_instance;
function Completed_getInstance() {
  if (Completed_instance === VOID)
    new Completed();
  return Completed_instance;
}
function get_isActive(_this__u8e3s4) {
  return get_isActive_0(_this__u8e3s4.dn());
}
function get_isActive_0(_this__u8e3s4) {
  return _this__u8e3s4 instanceof Active;
}
function PublishSubject() {
  return new PublishSubject$1();
}
function handleReaktiveError(error, onError) {
  onError = onError === VOID ? null : onError;
  if (onError == null) {
    handleError(error);
  } else {
    handleError_0(error, onError);
  }
}
function handleError(error) {
  try {
    get_reaktiveUncaughtErrorHandler()(error);
  } catch ($p) {
    if ($p instanceof Error) {
      var errorDeliveryException = $p;
      printErrors('Error delivering uncaught error', error, errorDeliveryException);
    } else {
      throw $p;
    }
  }
}
function handleError_0(error, onError) {
  try {
    onError(error);
  } catch ($p) {
    if ($p instanceof Error) {
      var errorHandlerException = $p;
      printErrors('onError callback failed', error, errorHandlerException);
      try {
        get_reaktiveUncaughtErrorHandler()(CompositeException.io(error, errorHandlerException));
      } catch ($p) {
        if ($p instanceof Error) {
          var errorDeliveryException = $p;
          printErrors('Error delivering uncaught error', error, errorDeliveryException);
        } else {
          throw $p;
        }
      }
    } else {
      throw $p;
    }
  }
}
function printErrors(message, outerError, innerError) {
  printError(message + ' (' + outerError + '): ' + innerError);
  printStackTrace(outerError);
  printStackTrace(innerError);
}
function get__reaktiveUncaughtErrorHandler() {
  _init_properties_UncaughtErrorHandler_kt__98v393();
  return _reaktiveUncaughtErrorHandler;
}
var _reaktiveUncaughtErrorHandler;
function get_reaktiveUncaughtErrorHandler() {
  _init_properties_UncaughtErrorHandler_kt__98v393();
  return get__reaktiveUncaughtErrorHandler().gm_1;
}
var properties_initialized_UncaughtErrorHandler_kt_ihx0zd;
function _init_properties_UncaughtErrorHandler_kt__98v393() {
  if (!properties_initialized_UncaughtErrorHandler_kt_ihx0zd) {
    properties_initialized_UncaughtErrorHandler_kt_ihx0zd = true;
    _reaktiveUncaughtErrorHandler = new AtomicReference(createDefaultUncaughtErrorHandler());
  }
}
function drainLoop($this) {
  var missed = 1;
  $l$loop_0: while (true) {
    $l$loop: while (true) {
      var isEmpty = false;
      var value = null;
      // Inline function 'com.badoo.reaktive.utils.lock.Lock.synchronized' call
      isEmpty = $this.z();
      var tmp;
      if (!isEmpty) {
        value = $this.mh();
        tmp = Unit_instance;
      }
      if (isEmpty) {
        break $l$loop;
      }
      if (!$this.nn((value == null ? true : !(value == null)) ? value : THROW_CCE())) {
        $this.rn_1.dm_1 = -1;
        return Unit_instance;
      }
    }
    missed = $this.rn_1.em(-missed | 0);
    if (missed === 0) {
      break $l$loop_0;
    }
  }
}
function createDefaultUncaughtErrorHandler() {
  return printError$ref();
}
function printError$ref() {
  var l = function (p0) {
    printError(p0);
    return Unit_instance;
  };
  l.callableName = 'printError';
  return l;
}
function printError(error) {
  console.error(error);
}
function swapDisposable($this, new_0) {
  // Inline function 'kotlin.also' call
  var this_0 = $this.mm_1;
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'com.badoo.reaktive.disposable.SerialDisposable.swapDisposable.<anonymous>' call
  $this.mm_1 = new_0;
  return this_0;
}
//region block: post-declaration
setMetadataFor(ObservableObserver, 'ObservableObserver', interfaceMeta);
setMetadataFor(subscribe$1, VOID, classMeta, VOID, [ObservableObserver]);
setMetadataFor(Event, 'Event', classMeta);
setMetadataFor(OnSubscribe, 'OnSubscribe', classMeta);
setMetadataFor(OnUnsubscribe, 'OnUnsubscribe', classMeta);
setMetadataFor(OnComplete, 'OnComplete', objectMeta);
setMetadataFor(OnError, 'OnError', classMeta);
setMetadataFor(AbstractSerializer, 'AbstractSerializer', classMeta);
setMetadataFor(DefaultSerializer, 'DefaultSerializer', classMeta);
setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta);
setMetadataFor(_no_name_provided__qut3iv_0, VOID, classMeta);
setMetadataFor(AbstractSubject, 'AbstractSubject', classMeta);
setMetadataFor(Status, 'Status', classMeta);
setMetadataFor(Active, 'Active', objectMeta);
setMetadataFor(Completed, 'Completed', objectMeta);
setMetadataFor(Error_0, 'Error', classMeta);
setMetadataFor(PublishSubject$1, VOID, classMeta);
setMetadataFor(CompositeException, 'CompositeException', classMeta);
setMetadataFor(SerialDisposable, 'SerialDisposable', classMeta, VOID, VOID, SerialDisposable);
//endregion
//region block: init
plugins = null;
//endregion
//region block: exports
export {
  subscribe as subscribe3pb2qdcdkj367,
  PublishSubject as PublishSubject2fg46ssyv4pyl,
};
//endregion


