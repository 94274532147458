/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const typographyFontSizeOptions = {
  'xs': 'k-fs-xs',
  'sm': 'k-fs-sm',
  'md': 'k-fs-md',
  'lg': 'k-fs-lg',
  'xl': 'k-fs-xl'
};

/**
 * @hidden
 */
const typographyFontWeightOptions = {
  'light': 'k-font-weight-light',
  'normal': 'k-font-weight-normal',
  'bold': 'k-font-weight-bold'
};

/**
 * Specifies the applied margin to the element.
 *
 * Passing a [TypographyMarginOption]({% slug api_typography_typographymarginoption %}) will set any of the built - in Kendo classes, which provide default margin in pixels.
 *
 * When using the Default or Material themes, passing a number will apply a margin of 4 times the passed number in pixels.
 * When using the Bootstrap theme the applied margin will be equal to the passed number divided by 4 in rem units.
 * The minimum number value is 0 and the maximum is 24.
 *
 * Passing a [Margin]({% slug api_typography_margin %}) object allows setting the margins for each side.
 */
/**
 * @hidden
 */
const typographyMarginOptions = {
  'xs': 'k-m-xs',
  'sm': 'k-m-sm',
  'md': 'k-m-md',
  'lg': 'k-m-lg',
  'xl': 'k-m-xl',
  'thin': 'k-m-thin',
  'hair': 'k-m-hair'
};
/**
 * @hidden
 */
const typographyMarginTopOptions = {
  'xs': 'k-mt-xs',
  'sm': 'k-mt-sm',
  'md': 'k-mt-md',
  'lg': 'k-mt-lg',
  'xl': 'k-mt-xl',
  'thin': 'k-mt-thin',
  'hair': 'k-mt-hair'
};
/**
 * @hidden
 */
const typographyMarginBottomOptions = {
  'xs': 'k-mb-xs',
  'sm': 'k-mb-sm',
  'md': 'k-mb-md',
  'lg': 'k-mb-lg',
  'xl': 'k-mb-xl',
  'thin': 'k-mb-thin',
  'hair': 'k-mb-hair'
};
/**
 * @hidden
 */
const typographyMarginLeftOptions = {
  'xs': 'k-ml-xs',
  'sm': 'k-ml-sm',
  'md': 'k-ml-md',
  'lg': 'k-ml-lg',
  'xl': 'k-ml-xl',
  'thin': 'k-ml-thin',
  'hair': 'k-ml-hair'
};
/**
 * @hidden
 */
const typographyMarginRightOptions = {
  'xs': 'k-mr-xs',
  'sm': 'k-mr-sm',
  'md': 'k-mr-md',
  'lg': 'k-mr-lg',
  'xl': 'k-mr-xl',
  'thin': 'k-mr-thin',
  'hair': 'k-mr-hair'
};

/**
 * @hidden
 */
const marginSides = {
  'top': 'k-mt-',
  'right': 'k-mr-',
  'bottom': 'k-mb-',
  'left': 'k-ml-'
};

/**
 * @hidden
 */
const typographyTextAlignOptions = {
  'left': 'k-text-left',
  'right': 'k-text-right',
  'center': 'k-text-center',
  'justify': 'k-text-justify'
};

/**
 * @hidden
 */
const typographyTextTransformOptions = {
  'lowercase': 'k-text-lowercase',
  'uppercase': 'k-text-uppercase',
  'capitalize': 'k-text-capitalize'
};

/**
 * @hidden
 */
const typographyVariantOptions = {
  'p': 'k-paragraph',
  'h1': 'k-h1',
  'h2': 'k-h2',
  'h3': 'k-h3',
  'h4': 'k-h4',
  'h5': 'k-h5',
  'h6': 'k-h6',
  'code': 'k-code',
  'pre': 'k-pre'
};

/**
 * @hidden
 */
const isPresent = value => value !== null && value !== undefined;

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-typography',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1710925022,
  version: '15.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * Represents the [Kendo UI Typography directive for Angular]({% slug overview_typography %}).
 * Used to represent element types in a uniform way by relying on the predefined Kendo theme classes.
 *
 * @example
 * ```ts-no-run
 * <div kendoTypography variant="k-h1"></div>
 * ```
 */
class TypographyDirective {
  constructor(elementWrapper, renderer, element) {
    this.elementWrapper = elementWrapper;
    this.renderer = renderer;
    this.element = element;
    this.typographyClasses = [];
    validatePackage(packageMetadata);
  }
  ngOnChanges() {
    this.setTypographyClasses();
  }
  variantClass() {
    return typographyVariantOptions[this.variant];
  }
  themeColorClass() {
    return isPresent(this.themeColor) ? `k-color-${this.themeColor}` : null;
  }
  textAlignClass() {
    return typographyTextAlignOptions[this.textAlign];
  }
  fontWeightClass() {
    return typographyFontWeightOptions[this.fontWeight];
  }
  fontSizeClass() {
    return typographyFontSizeOptions[this.fontSize];
  }
  textTransformClass() {
    return typographyTextTransformOptions[this.textTransform];
  }
  setMarginClassPerSide(marginSide, value) {
    return `${marginSides[marginSide]}${value}`;
  }
  marginClasses() {
    if (typeof this.margin === 'string') {
      return [typographyMarginOptions[this.margin]];
    }
    if (typeof this.margin === 'number') {
      return [`k-m-${this.margin}`];
    }
    if (typeof this.margin === 'object') {
      const marginClasses = [];
      Object.keys(this.margin).forEach(key => {
        if (isPresent(this.margin[key])) {
          marginClasses.push(this.setMarginClassPerSide(key, this.margin[key]));
        }
      });
      return marginClasses;
    }
  }
  setTypographyClasses() {
    const element = this.element.nativeElement;
    this.typographyClasses.forEach(className => {
      this.renderer.removeClass(element, className);
    });
    this.typographyClasses = [this.variantClass(), this.fontSizeClass(), this.fontWeightClass(), this.textTransformClass(), this.textAlignClass(), this.themeColorClass()];
    if (this.margin) {
      this.marginClasses().forEach(className => {
        this.typographyClasses.push(className);
      });
    }
    this.typographyClasses.forEach(className => {
      if (isPresent(className)) {
        this.renderer.addClass(element, className);
      }
    });
  }
}
TypographyDirective.ɵfac = function TypographyDirective_Factory(t) {
  return new (t || TypographyDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
TypographyDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TypographyDirective,
  selectors: [["", "kendoTypography", ""]],
  inputs: {
    variant: "variant",
    fontSize: "fontSize",
    fontWeight: "fontWeight",
    textAlign: "textAlign",
    textTransform: "textTransform",
    themeColor: "themeColor",
    margin: "margin"
  },
  exportAs: ["kendoTypography"],
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TypographyDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoTypography]',
      exportAs: 'kendoTypography'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    variant: [{
      type: Input
    }],
    fontSize: [{
      type: Input
    }],
    fontWeight: [{
      type: Input
    }],
    textAlign: [{
      type: Input
    }],
    textTransform: [{
      type: Input
    }],
    themeColor: [{
      type: Input
    }],
    margin: [{
      type: Input
    }]
  });
})();

/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Typography component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Typography module
 * import { TypographyModule } from '@progress/kendo-angular-typography';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, TypographyModule], // import Typography module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule { }
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class TypographyModule {}
TypographyModule.ɵfac = function TypographyModule_Factory(t) {
  return new (t || TypographyModule)();
};
TypographyModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TypographyModule
});
TypographyModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TypographyModule, [{
    type: NgModule,
    args: [{
      declarations: [TypographyDirective],
      exports: [TypographyDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TypographyDirective, TypographyModule };