/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Optional, Component, Input, ContentChild, NgModule } from '@angular/core';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const _c0 = ["*"];
const packageMetadata = {
  name: '@progress/kendo-angular-pdf-export',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1710925018,
  version: '15.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
class PDFExportTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
PDFExportTemplateDirective.ɵfac = function PDFExportTemplateDirective_Factory(t) {
  return new (t || PDFExportTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
PDFExportTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PDFExportTemplateDirective,
  selectors: [["", "kendoPDFTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPDFTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
const FIELDS = ['bottom', 'left', 'right', 'top'];
/**
 * Represents the Kendo UI PDFMargin component for Angular.
 */
class PDFExportMarginComponent {
  /**
   * @hidden
   */
  get options() {
    const options = {};
    for (let idx = 0; idx < FIELDS.length; idx++) {
      const field = FIELDS[idx];
      const value = this[field];
      if (typeof value !== 'undefined') {
        options[field] = value;
      }
    }
    return options;
  }
}
PDFExportMarginComponent.ɵfac = function PDFExportMarginComponent_Factory(t) {
  return new (t || PDFExportMarginComponent)();
};
PDFExportMarginComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PDFExportMarginComponent,
  selectors: [["kendo-pdf-export-margin"]],
  inputs: {
    left: "left",
    top: "top",
    right: "right",
    bottom: "bottom"
  },
  decls: 0,
  vars: 0,
  template: function PDFExportMarginComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportMarginComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-pdf-export-margin',
      template: ``
    }]
  }], null, {
    left: [{
      type: Input
    }],
    top: [{
      type: Input
    }],
    right: [{
      type: Input
    }],
    bottom: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
const compileTemplate = templateRef => {
  const context = {};
  let embeddedView = templateRef.createEmbeddedView(context);
  const result = data => {
    Object.assign(context, data);
    embeddedView.detectChanges();
    const templateWrap = document.createElement('span');
    embeddedView.rootNodes.forEach(rootNode => {
      templateWrap.appendChild(rootNode.cloneNode(true));
    });
    return templateWrap;
  };
  result.destroy = () => {
    embeddedView.destroy();
    embeddedView = null;
  };
  return result;
};

/**
 * Represents the [Kendo UI PDF Export component for Angular]({% slug overview_pdfexport %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *     <div class="example-config">
 *       <button kendoButton (click)="pdf.saveAs('document.pdf')">
 *         Save As PDF...
 *       </button>
 *     </div>
 *
 *     <kendo-pdf-export #pdf paperSize="A4" margin="2cm">
 *       Content goes here
 *     </kendo-pdf-export>
 *   `
 * })
 * export class AppComponent {
 * }
 * ```
 */
class PDFExportComponent {
  constructor(element) {
    this.element = element;
    /**
     * The creator of the PDF document.
     * @default "Kendo UI PDF Generator"
     */
    this.creator = 'Kendo UI PDF Generator';
    /**
     * Specifies the name of the exported PDF file.
     * @default "export.pdf"
     */
    this.fileName = 'export.pdf';
    validatePackage(packageMetadata);
  }
  get drawMargin() {
    const marginComponent = this.marginComponent;
    let margin = this.margin;
    if (marginComponent) {
      margin = Object.assign(margin || {}, marginComponent.options);
    }
    return margin;
  }
  /**
   * Saves the content as a PDF file with the specified name.
   * @param fileName - The name of the exported file.
   */
  saveAs(fileName = this.fileName) {
    this.save(this.element.nativeElement, fileName);
  }
  /**
   * Exports the content as a `Group` for further processing.
   *
   * @return The root group of the exported scene.
   */
  export() {
    return this.exportElement(this.element.nativeElement);
  }
  save(element, fileName) {
    this.exportElement(element).then(group => this.exportGroup(group, this.pdfOptions())).then(dataUri => this.saveDataUri(dataUri, fileName, this.saveOptions()));
  }
  exportElement(element) {
    const promise = this.drawElement(element, this.drawOptions());
    const cleanup = this.cleanup.bind(this);
    promise.then(cleanup, cleanup);
    return promise;
  }
  cleanup() {
    if (this.pageTemplate) {
      this.pageTemplate.destroy();
      delete this.pageTemplate;
    }
  }
  drawOptions() {
    if (this.pageTemplateDirective) {
      this.pageTemplate = compileTemplate(this.pageTemplateDirective.templateRef);
    }
    return {
      avoidLinks: this.avoidLinks,
      forcePageBreak: this.forcePageBreak,
      keepTogether: this.keepTogether,
      margin: this.drawMargin,
      paperSize: this.paperSize,
      landscape: this.landscape,
      repeatHeaders: this.repeatHeaders,
      scale: this.scale,
      template: this.pageTemplate
    };
  }
  pdfOptions() {
    return {
      autoPrint: this.autoPrint,
      author: this.author,
      creator: this.creator,
      date: this.date,
      imgDPI: this.imageResolution,
      keywords: this.keywords,
      landscape: this.landscape,
      margin: this.drawMargin,
      multiPage: true,
      paperSize: this.paperSize,
      producer: this.producer,
      subject: this.subject,
      title: this.title
    };
  }
  saveOptions() {
    return {
      forceProxy: this.forceProxy,
      proxyData: this.proxyData,
      proxyTarget: this.proxyTarget,
      proxyURL: this.proxyURL
    };
  }
  drawElement(element, options) {
    return drawDOM(element, options);
  }
  exportGroup(group, options) {
    return exportPDF(group, options);
  }
  saveDataUri(dataUri, fileName, options) {
    saveAs(dataUri, fileName, options);
  }
}
PDFExportComponent.ɵfac = function PDFExportComponent_Factory(t) {
  return new (t || PDFExportComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
PDFExportComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PDFExportComponent,
  selectors: [["kendo-pdf-export"]],
  contentQueries: function PDFExportComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PDFExportTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, PDFExportMarginComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.pageTemplateDirective = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.marginComponent = _t.first);
    }
  },
  inputs: {
    autoPrint: "autoPrint",
    author: "author",
    avoidLinks: "avoidLinks",
    forcePageBreak: "forcePageBreak",
    keepTogether: "keepTogether",
    creator: "creator",
    date: "date",
    imageResolution: "imageResolution",
    fileName: "fileName",
    forceProxy: "forceProxy",
    keywords: "keywords",
    landscape: "landscape",
    margin: "margin",
    paperSize: "paperSize",
    repeatHeaders: "repeatHeaders",
    scale: "scale",
    proxyData: "proxyData",
    proxyURL: "proxyURL",
    proxyTarget: "proxyTarget",
    producer: "producer",
    subject: "subject",
    title: "title"
  },
  ngContentSelectors: _c0,
  decls: 2,
  vars: 0,
  template: function PDFExportComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div");
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-pdf-export',
      template: `<div><ng-content></ng-content></div>`
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    autoPrint: [{
      type: Input
    }],
    author: [{
      type: Input
    }],
    avoidLinks: [{
      type: Input
    }],
    forcePageBreak: [{
      type: Input
    }],
    keepTogether: [{
      type: Input
    }],
    creator: [{
      type: Input
    }],
    date: [{
      type: Input
    }],
    imageResolution: [{
      type: Input
    }],
    fileName: [{
      type: Input
    }],
    forceProxy: [{
      type: Input
    }],
    keywords: [{
      type: Input
    }],
    landscape: [{
      type: Input
    }],
    margin: [{
      type: Input
    }],
    paperSize: [{
      type: Input
    }],
    repeatHeaders: [{
      type: Input
    }],
    scale: [{
      type: Input
    }],
    proxyData: [{
      type: Input
    }],
    proxyURL: [{
      type: Input
    }],
    proxyTarget: [{
      type: Input
    }],
    producer: [{
      type: Input
    }],
    subject: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    pageTemplateDirective: [{
      type: ContentChild,
      args: [PDFExportTemplateDirective, {
        static: false
      }]
    }],
    marginComponent: [{
      type: ContentChild,
      args: [PDFExportMarginComponent, {
        static: false
      }]
    }]
  });
})();
const COMPONENT_DIRECTIVES = [PDFExportComponent, PDFExportMarginComponent, PDFExportTemplateDirective];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi']) definition for the PDF Export directive.
 */
class PDFExportModule {}
PDFExportModule.ɵfac = function PDFExportModule_Factory(t) {
  return new (t || PDFExportModule)();
};
PDFExportModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PDFExportModule
});
PDFExportModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportModule, [{
    type: NgModule,
    args: [{
      declarations: [COMPONENT_DIRECTIVES],
      exports: [COMPONENT_DIRECTIVES]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PDFExportComponent, PDFExportMarginComponent, PDFExportModule, PDFExportTemplateDirective, compileTemplate };