import {
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  VOID7hggqo3abtya as VOID,
  CoroutineImpl2sn3kjnwmfr10 as CoroutineImpl,
  Unit_instance1fbcbse1fwigr as Unit_instance,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  plus1ogy4liedzq5j as plus,
  toMutableSetjdpdbr9jsqq8 as toMutableSet,
  ArrayList3it5z8td81qkl as ArrayList,
  protoOf180f3jzyo7rfj as protoOf,
  objectMeta213120oau977m as objectMeta,
  toString1pkumu07cwy4m as toString,
  Collection1k04j3hzsbod0 as Collection,
  isInterface3d6p8outrmvmk as isInterface,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  Unitkvevlwgzwiuc as Unit,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  equals2au1ep9vhcato as equals,
  joinToString1cxrrlmo0chqs as joinToString,
  collectionSizeOrDefault36dulx8yinfqm as collectionSizeOrDefault,
  Exceptiondt2hlxn7j7vw as Exception,
  captureStack1fzi4aczwc4hg as captureStack,
  PrimitiveClasses_getInstance2v63zn04dtq03 as PrimitiveClasses_getInstance,
  getKClass1s3j9wy1cofik as getKClass,
  setOf45ia9pnfhe90 as setOf,
  endsWith3cq61xxngobwh as endsWith,
  startsWith26w8qjqapeeq6 as startsWith,
  LinkedHashSet2tkztfx86kyx2 as LinkedHashSet,
} from './kotlin-kotlin-stdlib.mjs';
import {
  PipelineContext34fsb0mycu471 as PipelineContext,
  AttributeKey3aq8ytwgx54f7 as AttributeKey,
  KtorSimpleLogger1xdphsp5l4e48 as KtorSimpleLogger,
} from './ktor-ktor-utils.mjs';
import {
  HttpResponseContainer3r9yzy4mwwvc9 as HttpResponseContainer,
  Phases_getInstance3gwf7ca9zp4r6 as Phases_getInstance,
  Phases_getInstance3cv4l5wlctlnh as Phases_getInstance_0,
  HttpClientPlugin3rce8c1crrw1q as HttpClientPlugin,
  accept2gi3b7wj4jds9 as accept,
  EmptyContent_getInstance116ybdh9l8hek as EmptyContent_getInstance,
} from './ktor-ktor-client-core.mjs';
import {
  contentType317fn4f991q9a as contentType,
  Application_getInstanceq87g3bor693u as Application_getInstance,
  OutgoingContent3t2ohmyam9o76 as OutgoingContent,
  contentType2zzm38yxo3syt as contentType_0,
  HttpHeaders_getInstanceelogg8fjd54u as HttpHeaders_getInstance,
  charset1dribv3ku48b1 as charset,
  NullBody_instance2i6w0hfghwfg0 as NullBody_instance,
  HttpStatusCode3o1wkms10pg4k as HttpStatusCode,
} from './ktor-ktor-http.mjs';
import {
  suitableCharset1jgdcpdzbzgzn as suitableCharset,
  register$default1vru4l6f6i5dq as register$default,
  Configuration20xgygxdzhlk5 as Configuration,
  deserialize3kqe4vxpbxz1 as deserialize,
} from './ktor-ktor-serialization.mjs';
import {
  Charsets_getInstanceqs70pvl4noow as Charsets_getInstance,
  ByteReadChannel2wzou76jce72d as ByteReadChannel,
} from './ktor-ktor-io.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class ConverterRegistration {
  constructor(converter, contentTypeToSend, contentTypeMatcher) {
    this.j3u_1 = converter;
    this.k3u_1 = contentTypeToSend;
    this.l3u_1 = contentTypeMatcher;
  }
}
class ContentNegotiation$Config$defaultMatcher$1 {
  constructor($pattern) {
    this.m3u_1 = $pattern;
  }
  n3u(contentType) {
    return contentType.z27(this.m3u_1);
  }
}
class ContentNegotiation$Plugin$install$slambda extends CoroutineImpl {
  constructor($plugin, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.w3u_1 = $plugin;
    super(resultContinuation, $box);
  }
  h2p($this$intercept, it, $completion) {
    var tmp = this.i2p($this$intercept, it, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  j2p(p1, p2, $completion) {
    var tmp = p1 instanceof PipelineContext ? p1 : THROW_CCE();
    return this.h2p(tmp, !(p2 == null) ? p2 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.te_1 = 1;
            suspendResult = this.w3u_1.d3v(this.x3u_1.a24_1, this.x3u_1.b23(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.z3u_1 = suspendResult;
            var tmp_0 = this;
            var tmp_1;
            if (this.z3u_1 == null) {
              return Unit_instance;
            } else {
              tmp_1 = this.z3u_1;
            }

            tmp_0.a3v_1 = tmp_1;
            this.te_1 = 2;
            suspendResult = this.x3u_1.c23(this.a3v_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return Unit_instance;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  i2p($this$intercept, it, completion) {
    var i = new ContentNegotiation$Plugin$install$slambda(this.w3u_1, completion);
    i.x3u_1 = $this$intercept;
    i.y3u_1 = it;
    return i;
  }
}
class ContentNegotiation$Plugin$install$slambda_0 extends CoroutineImpl {
  constructor($plugin, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.m3v_1 = $plugin;
    super(resultContinuation, $box);
  }
  c2q($this$intercept, _name_for_destructuring_parameter_0__wldtmu, $completion) {
    var tmp = this.d2q($this$intercept, _name_for_destructuring_parameter_0__wldtmu, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  j2p(p1, p2, $completion) {
    var tmp = p1 instanceof PipelineContext ? p1 : THROW_CCE();
    return this.c2q(tmp, p2 instanceof HttpResponseContainer ? p2 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.p3v_1 = this.o3v_1.rk();
            this.q3v_1 = this.o3v_1.sk();
            var tmp_0 = this;
            var tmp0_elvis_lhs = contentType(this.n3v_1.a24_1.p2p());
            var tmp_1;
            if (tmp0_elvis_lhs == null) {
              this.n3v_1;
              get_LOGGER().d25('Response doesn\'t have "Content-Type" header, skipping ContentNegotiation plugin');
              return Unit_instance;
            } else {
              tmp_1 = tmp0_elvis_lhs;
            }

            tmp_0.r3v_1 = tmp_1;
            this.s3v_1 = suitableCharset(this.n3v_1.a24_1.f2t().w2c());
            this.te_1 = 1;
            suspendResult = this.m3v_1.w3v(this.n3v_1.a24_1.f2t().q2t(), this.p3v_1, this.q3v_1, this.r3v_1, this.s3v_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.t3v_1 = suspendResult;
            var tmp_2 = this;
            var tmp_3;
            if (this.t3v_1 == null) {
              return Unit_instance;
            } else {
              tmp_3 = this.t3v_1;
            }

            tmp_2.u3v_1 = tmp_3;
            this.v3v_1 = new HttpResponseContainer(this.p3v_1, this.u3v_1);
            this.te_1 = 2;
            suspendResult = this.n3v_1.c23(this.v3v_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return Unit_instance;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  d2q($this$intercept, _name_for_destructuring_parameter_0__wldtmu, completion) {
    var i = new ContentNegotiation$Plugin$install$slambda_0(this.m3v_1, completion);
    i.n3v_1 = $this$intercept;
    i.o3v_1 = _name_for_destructuring_parameter_0__wldtmu;
    return i;
  }
}
class Config {
  constructor() {
    this.x3v_1 = toMutableSet(plus(get_DefaultIgnoredTypes(), get_DefaultCommonIgnoredTypes()));
    var tmp = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp.y3v_1 = ArrayList.o3();
  }
  x2l(contentType, converter, configuration) {
    var matcher = contentType.equals(Application_getInstance().m26_1) ? JsonContentTypeMatcher_instance : defaultMatcher(this, contentType);
    this.z3v(contentType, converter, matcher, configuration);
  }
  z3v(contentTypeToSend, converter, contentTypeMatcher, configuration) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    configuration(converter);
    var registration = new ConverterRegistration(converter, contentTypeToSend, contentTypeMatcher);
    this.y3v_1.o(registration);
  }
}
class Plugin {
  constructor() {
    Plugin_instance = this;
    this.a3w_1 = new AttributeKey('ContentNegotiation');
  }
  t2() {
    return this.a3w_1;
  }
  b3w(block) {
    // Inline function 'kotlin.apply' call
    var this_0 = new Config();
    // Inline function 'kotlin.contracts.contract' call
    block(this_0);
    var config = this_0;
    return new ContentNegotiation(config.y3v_1, config.x3v_1);
  }
  u2r(block) {
    return this.b3w(block);
  }
  c3w(plugin, scope) {
    var tmp = Phases_getInstance().l2y_1;
    scope.n2o_1.g24(tmp, ContentNegotiation$Plugin$install$slambda_1(plugin, null));
    var tmp_0 = Phases_getInstance_0().o2r_1;
    scope.o2o_1.g24(tmp_0, ContentNegotiation$Plugin$install$slambda_2(plugin, null));
  }
  v2r(plugin, scope) {
    return this.c3w(plugin instanceof ContentNegotiation ? plugin : THROW_CCE(), scope);
  }
}
class $convertRequestCOROUTINE$0 extends CoroutineImpl {
  constructor(_this__u8e3s4, request, body, resultContinuation) {
    super(resultContinuation);
    this.l3w_1 = _this__u8e3s4;
    this.m3w_1 = request;
    this.n3w_1 = body;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 7;
            var tmp0_iterator = this.l3w_1.b3v_1.r();
            while (tmp0_iterator.s()) {
              var element = tmp0_iterator.u();
              get_LOGGER().d25('Adding Accept=' + element.k3u_1.d26_1 + ' header for ' + this.m3w_1.o2q_1);
              accept(this.m3w_1, element.k3u_1);
            }

            var tmp_0;
            var tmp_1 = this.n3w_1;
            if (tmp_1 instanceof OutgoingContent) {
              tmp_0 = true;
            } else {
              var tmp$ret$0;
              l$ret$1: do {
                var this_0 = this.l3w_1.c3v_1;
                var tmp_2;
                if (isInterface(this_0, Collection)) {
                  tmp_2 = this_0.z();
                } else {
                  tmp_2 = false;
                }
                if (tmp_2) {
                  tmp$ret$0 = false;
                  break l$ret$1;
                }
                var tmp0_iterator_0 = this_0.r();
                while (tmp0_iterator_0.s()) {
                  var element_0 = tmp0_iterator_0.u();
                  if (element_0.v8(this.n3w_1)) {
                    tmp$ret$0 = true;
                    break l$ret$1;
                  }
                }
                tmp$ret$0 = false;
              }
               while (false);
              tmp_0 = tmp$ret$0;
            }

            if (tmp_0) {
              get_LOGGER().d25('Body type ' + getKClassFromExpression(this.n3w_1) + ' is in ignored types. ' + ('Skipping ContentNegotiation for ' + this.m3w_1.o2q_1 + '.'));
              return null;
            }

            var tmp_3 = this;
            var tmp0_elvis_lhs = contentType_0(this.m3w_1);
            var tmp_4;
            if (tmp0_elvis_lhs == null) {
              this.l3w_1;
              get_LOGGER().d25("Request doesn't have Content-Type header. Skipping ContentNegotiation for " + this.m3w_1.o2q_1 + '.');
              return null;
            } else {
              tmp_4 = tmp0_elvis_lhs;
            }

            tmp_3.o3w_1 = tmp_4;
            var tmp_5 = this.n3w_1;
            if (tmp_5 instanceof Unit) {
              get_LOGGER().d25('Sending empty body for ' + this.m3w_1.o2q_1);
              this.m3w_1.q2q_1.m21(HttpHeaders_getInstance().v28_1);
              return EmptyContent_getInstance();
            }

            var tmp_6 = this;
            var this_1 = this.l3w_1.b3v_1;
            var destination = ArrayList.o3();
            var tmp0_iterator_1 = this_1.r();
            while (tmp0_iterator_1.s()) {
              var element_1 = tmp0_iterator_1.u();
              if (element_1.l3u_1.n3u(this.o3w_1)) {
                destination.o(element_1);
              }
            }

            var tmp_7;
            if (!destination.z()) {
              tmp_7 = destination;
            } else {
              tmp_7 = null;
            }

            var tmp1_elvis_lhs = tmp_7;
            var tmp_8;
            if (tmp1_elvis_lhs == null) {
              this.l3w_1;
              get_LOGGER().d25('None of the registered converters match request Content-Type=' + this.o3w_1 + '. ' + ('Skipping ContentNegotiation for ' + this.m3w_1.o2q_1 + '.'));
              return null;
            } else {
              tmp_8 = tmp1_elvis_lhs;
            }

            tmp_6.p3w_1 = tmp_8;
            if (this.m3w_1.p3h() == null) {
              get_LOGGER().d25('Request has unknown body type. Skipping ContentNegotiation for ' + this.m3w_1.o2q_1 + '.');
              return null;
            }

            this.m3w_1.q2q_1.m21(HttpHeaders_getInstance().v28_1);
            this.te_1 = 1;
            continue $sm;
          case 1:
            var tmp_9 = this;
            tmp_9.r3w_1 = this.p3w_1;
            this.s3w_1 = this.r3w_1.r();
            this.te_1 = 2;
            continue $sm;
          case 2:
            if (!this.s3w_1.s()) {
              this.te_1 = 5;
              continue $sm;
            }

            this.t3w_1 = this.s3w_1.u();
            var tmp_10 = this;
            tmp_10.u3w_1 = this.t3w_1;
            this.te_1 = 3;
            var tmp0_elvis_lhs_0 = charset(this.o3w_1);
            var tmp_11 = tmp0_elvis_lhs_0 == null ? Charsets_getInstance().e1s_1 : tmp0_elvis_lhs_0;
            var tmp_12 = ensureNotNull(this.m3w_1.p3h());
            var this_2 = this.n3w_1;
            var tmp_13;
            if (!equals(this_2, NullBody_instance)) {
              tmp_13 = this_2;
            } else {
              tmp_13 = null;
            }

            suspendResult = this.u3w_1.j3u_1.v2l(this.o3w_1, tmp_11, tmp_12, tmp_13, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            var result = suspendResult;
            if (!(result == null)) {
              get_LOGGER().d25('Converted request body using ' + this.u3w_1.j3u_1 + ' for ' + this.m3w_1.o2q_1);
            }

            var result_0 = result;
            if (!(result_0 == null)) {
              this.q3w_1 = result_0;
              this.te_1 = 6;
              continue $sm;
            } else {
              this.te_1 = 4;
              continue $sm;
            }

          case 4:
            this.te_1 = 2;
            continue $sm;
          case 5:
            this.q3w_1 = null;
            if (false) {
              this.te_1 = 1;
              continue $sm;
            }

            this.te_1 = 6;
            continue $sm;
          case 6:
            var tmp2_elvis_lhs = this.q3w_1;
            var tmp_14;
            if (tmp2_elvis_lhs == null) {
              var tmp_15 = "Can't convert " + toString(this.n3w_1) + ' with contentType ' + this.o3w_1 + ' using converters ';
              throw ContentConverterException.x3w(tmp_15 + joinToString(this.p3w_1, VOID, VOID, VOID, VOID, VOID, ContentNegotiation$convertRequest$lambda));
            } else {
              tmp_14 = tmp2_elvis_lhs;
            }

            var serializedContent = tmp_14;
            return serializedContent;
          case 7:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 7) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $convertResponseCOROUTINE$1 extends CoroutineImpl {
  constructor(_this__u8e3s4, requestUrl, info, body, responseContentType, charset, resultContinuation) {
    super(resultContinuation);
    this.g3x_1 = _this__u8e3s4;
    this.h3x_1 = requestUrl;
    this.i3x_1 = info;
    this.j3x_1 = body;
    this.k3x_1 = responseContentType;
    this.l3x_1 = charset;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            var tmp_0 = this.j3x_1;
            if (!isInterface(tmp_0, ByteReadChannel)) {
              get_LOGGER().d25('Response body is already transformed. Skipping ContentNegotiation for ' + this.h3x_1 + '.');
              return null;
            }

            if (this.g3x_1.c3v_1.x(this.i3x_1.t24_1)) {
              get_LOGGER().d25('Response body type ' + this.i3x_1.t24_1 + ' is in ignored types. ' + ('Skipping ContentNegotiation for ' + this.h3x_1 + '.'));
              return null;
            }

            var tmp_1 = this;
            var this_0 = this.g3x_1.b3v_1;
            var destination = ArrayList.o3();
            var tmp0_iterator = this_0.r();
            while (tmp0_iterator.s()) {
              var element = tmp0_iterator.u();
              if (element.l3u_1.n3u(this.k3x_1)) {
                destination.o(element);
              }
            }

            var destination_0 = ArrayList.k3(collectionSizeOrDefault(destination, 10));
            var tmp0_iterator_0 = destination.r();
            while (tmp0_iterator_0.s()) {
              var item = tmp0_iterator_0.u();
              destination_0.o(item.j3u_1);
            }

            var tmp_2;
            if (!destination_0.z()) {
              tmp_2 = destination_0;
            } else {
              tmp_2 = null;
            }

            var tmp0_elvis_lhs = tmp_2;
            var tmp_3;
            if (tmp0_elvis_lhs == null) {
              this.g3x_1;
              get_LOGGER().d25('None of the registered converters match response with Content-Type=' + this.k3x_1 + '. ' + ('Skipping ContentNegotiation for ' + this.h3x_1 + '.'));
              return null;
            } else {
              tmp_3 = tmp0_elvis_lhs;
            }

            tmp_1.m3x_1 = tmp_3;
            this.te_1 = 1;
            suspendResult = deserialize(this.m3x_1, this.j3x_1, this.i3x_1, this.l3x_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var result = suspendResult;
            if (!isInterface(result, ByteReadChannel)) {
              get_LOGGER().d25('Response body was converted to ' + getKClassFromExpression(result) + ' for ' + this.h3x_1 + '.');
            }

            return result;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class ContentNegotiation {
  constructor(registrations, ignoredTypes) {
    Plugin_getInstance();
    this.b3v_1 = registrations;
    this.c3v_1 = ignoredTypes;
  }
  d3v(request, body, $completion) {
    var tmp = new $convertRequestCOROUTINE$0(this, request, body, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  w3v(requestUrl, info, body, responseContentType, charset, $completion) {
    var tmp = new $convertResponseCOROUTINE$1(this, requestUrl, info, body, responseContentType, charset, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
}
class ContentConverterException extends Exception {
  static x3w(message) {
    var $this = this.ja(message);
    captureStack($this, $this.w3w_1);
    return $this;
  }
}
class JsonContentTypeMatcher {
  n3u(contentType) {
    if (contentType.z27(Application_getInstance().m26_1)) {
      return true;
    }
    var value = contentType.y27().toString();
    return startsWith(value, 'application/') ? endsWith(value, '+json') : false;
  }
}
//endregion
function get_LOGGER() {
  _init_properties_ContentNegotiation_kt__o183go();
  return LOGGER;
}
var LOGGER;
function get_DefaultCommonIgnoredTypes() {
  _init_properties_ContentNegotiation_kt__o183go();
  return DefaultCommonIgnoredTypes;
}
var DefaultCommonIgnoredTypes;
function defaultMatcher($this, pattern) {
  return new ContentNegotiation$Config$defaultMatcher$1(pattern);
}
function ContentNegotiation$Plugin$install$slambda_1($plugin, resultContinuation) {
  var i = new ContentNegotiation$Plugin$install$slambda($plugin, resultContinuation);
  var l = function ($this$intercept, it, $completion) {
    return i.h2p($this$intercept, it, $completion);
  };
  l.$arity = 2;
  return l;
}
function ContentNegotiation$Plugin$install$slambda_2($plugin, resultContinuation) {
  var i = new ContentNegotiation$Plugin$install$slambda_0($plugin, resultContinuation);
  var l = function ($this$intercept, _name_for_destructuring_parameter_0__wldtmu, $completion) {
    return i.c2q($this$intercept, _name_for_destructuring_parameter_0__wldtmu, $completion);
  };
  l.$arity = 2;
  return l;
}
var Plugin_instance;
function Plugin_getInstance() {
  if (Plugin_instance === VOID)
    new Plugin();
  return Plugin_instance;
}
function ContentNegotiation$convertRequest$lambda(it) {
  return toString(it.j3u_1);
}
var properties_initialized_ContentNegotiation_kt_1ayduy;
function _init_properties_ContentNegotiation_kt__o183go() {
  if (!properties_initialized_ContentNegotiation_kt_1ayduy) {
    properties_initialized_ContentNegotiation_kt_1ayduy = true;
    LOGGER = KtorSimpleLogger('io.ktor.client.plugins.contentnegotiation.ContentNegotiation');
    DefaultCommonIgnoredTypes = setOf([PrimitiveClasses_getInstance().z9(), PrimitiveClasses_getInstance().v9(), getKClass(HttpStatusCode), getKClass(ByteReadChannel), getKClass(OutgoingContent)]);
  }
}
var JsonContentTypeMatcher_instance;
function JsonContentTypeMatcher_getInstance() {
  return JsonContentTypeMatcher_instance;
}
function get_DefaultIgnoredTypes() {
  _init_properties_DefaultIgnoredTypesJs_kt__rjtdk1();
  return DefaultIgnoredTypes;
}
var DefaultIgnoredTypes;
var properties_initialized_DefaultIgnoredTypesJs_kt_65g2xt;
function _init_properties_DefaultIgnoredTypesJs_kt__rjtdk1() {
  if (!properties_initialized_DefaultIgnoredTypesJs_kt_65g2xt) {
    properties_initialized_DefaultIgnoredTypesJs_kt_65g2xt = true;
    // Inline function 'kotlin.collections.mutableSetOf' call
    DefaultIgnoredTypes = LinkedHashSet.y7();
  }
}
//region block: post-declaration
setMetadataFor(ConverterRegistration, 'ConverterRegistration', classMeta);
setMetadataFor(ContentNegotiation$Config$defaultMatcher$1, VOID, classMeta);
setMetadataFor(ContentNegotiation$Plugin$install$slambda, 'ContentNegotiation$Plugin$install$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [2]);
setMetadataFor(ContentNegotiation$Plugin$install$slambda_0, 'ContentNegotiation$Plugin$install$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [2]);
protoOf(Config).y2l = register$default;
setMetadataFor(Config, 'Config', classMeta, VOID, [Configuration], Config);
setMetadataFor(Plugin, 'Plugin', objectMeta, VOID, [HttpClientPlugin]);
setMetadataFor($convertRequestCOROUTINE$0, '$convertRequestCOROUTINE$0', classMeta);
setMetadataFor($convertResponseCOROUTINE$1, '$convertResponseCOROUTINE$1', classMeta);
setMetadataFor(ContentNegotiation, 'ContentNegotiation', classMeta, VOID, VOID, VOID, VOID, VOID, [2, 5]);
setMetadataFor(ContentConverterException, 'ContentConverterException', classMeta);
setMetadataFor(JsonContentTypeMatcher, 'JsonContentTypeMatcher', objectMeta);
//endregion
//region block: init
JsonContentTypeMatcher_instance = new JsonContentTypeMatcher();
//endregion
//region block: exports
export {
  Plugin_getInstance as Plugin_getInstance21w9qppp8svkg,
};
//endregion


