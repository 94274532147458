/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Component, type OnInit } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { filter } from 'rxjs';
import { AuthenticationService } from '@core/services/authentication.service';
import { chevronLeftIcon, infoCircleIcon, type SVGIcon, userIcon } from '@progress/kendo-svg-icons';
import { DialogService } from '@progress/kendo-angular-dialog';
import { UserPreferencesDialogComponent } from '@shared/components/user-preferences-dialog/user-preferences-dialog.component';
import { notNull } from '@core/util/helpers';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  protected userInitials: string | null = null;
  protected avatarIcon: SVGIcon = userIcon;

  protected actions = [
    {
      i18nKey: 'login.logout',
      svgIcon: chevronLeftIcon,
      click: () => {
        // Artificial delay to allow the dropdown to be removed from the DOM before routing
        setTimeout(
          () => this.authService.logout(true),
          10,
        );
      },
    },
    {
      i18nKey: 'user.action.about',
      svgIcon: infoCircleIcon,
      click: () => {
        this.showAboutDialog();
      },
    },
  ];

  constructor(private userService: UserService, private authService: AuthenticationService,
              private dialogService: DialogService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.userService.getCurrentUser().pipe(filter(notNull)).subscribe(u => {
      if (u.firstName.length > 0 && u.lastName.length > 0) {
        this.userInitials = `${u.firstName[0]}${u.lastName[0]}`.toUpperCase();
      }
    });
  }

  private showPreferencesDialog(): void {
    this.dialogService.open({
      content: UserPreferencesDialogComponent,
    });
  }

  private showAboutDialog() {
    this.dialogService.open({
      content: `Version: ${environment.version}`,
      actions: [
        {text: this.translate.instant('shared.action.close')}
      ]
    })
  }

}
