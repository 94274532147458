/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import * as i0 from '@angular/core';
import { EventEmitter, Injectable, Directive, Input, HostBinding, HostListener, Component, ViewChildren, forwardRef, Inject, ElementRef, ContentChild, ViewChild, Output, isDevMode, NgModule } from '@angular/core';
import { guid, Keys, isDocumentAvailable, KendoInput, isChanged } from '@progress/kendo-angular-common';
import { fileAudioIcon, fileVideoIcon, fileImageIcon, fileTxtIcon, filePresentationIcon, fileDataIcon, fileProgrammingIcon, filePdfIcon, fileConfigIcon, fileZipIcon, fileDiscImageIcon, arrowRotateCwSmallIcon, playSmIcon, pauseSmIcon, cancelIcon, xIcon, fileIcon, copyIcon, checkIcon, exclamationCircleIcon, uploadIcon } from '@progress/kendo-svg-icons';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i1$1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { fromEvent, merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { validatePackage } from '@progress/kendo-licensing';
import * as i5 from '@progress/kendo-angular-buttons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i3 from '@progress/kendo-angular-progressbar';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import * as i4 from '@progress/kendo-angular-icons';
import { IconsModule } from '@progress/kendo-angular-icons';
import * as i6 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * Lists the possible states of a file.
 */
function FileListItemActionButtonComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("", ctx_r0.progress, "%");
  }
}
const _c0 = a0 => ({
  "k-focus": a0
});
function FileListItemActionButtonComponent_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 5);
    i0.ɵɵlistener("focus", function FileListItemActionButtonComponent_button_2_Template_button_focus_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onFocus("retry"));
    })("blur", function FileListItemActionButtonComponent_button_2_Template_button_blur_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.onBlur("retry"));
    })("click", function FileListItemActionButtonComponent_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.onRetryClick());
    });
    i0.ɵɵelement(1, "kendo-icon-wrapper", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(6, _c0, ctx_r1.retryFocused));
    i0.ɵɵattribute("tabIndex", -1)("aria-hidden", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("svgIcon", ctx_r1.retrySVGIcon);
    i0.ɵɵattribute("aria-label", ctx_r1.retryButtonTitle)("title", ctx_r1.retryButtonTitle);
  }
}
function FileListItemActionButtonComponent_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 5);
    i0.ɵɵlistener("focus", function FileListItemActionButtonComponent_button_3_Template_button_focus_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.onFocus("pauseResume"));
    })("blur", function FileListItemActionButtonComponent_button_3_Template_button_blur_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.onBlur("pauseResume"));
    })("click", function FileListItemActionButtonComponent_button_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.onPauseResumeClick());
    });
    i0.ɵɵelement(1, "kendo-icon-wrapper", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c0, ctx_r2.pauseResumeFocused));
    i0.ɵɵattribute("tabIndex", -1)("aria-hidden", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("name", ctx_r2.isPaused ? "play-sm" : "pause-sm")("svgIcon", ctx_r2.isPaused ? ctx_r2.playSVGIcon : ctx_r2.pauseSVGIcon);
    i0.ɵɵattribute("aria-label", ctx_r2.pauseResumeButtonTitle)("title", ctx_r2.pauseResumeButtonTitle);
  }
}
function FileListItemActionButtonComponent_button_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 5);
    i0.ɵɵlistener("focus", function FileListItemActionButtonComponent_button_4_Template_button_focus_0_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.onFocus("action"));
    })("blur", function FileListItemActionButtonComponent_button_4_Template_button_blur_0_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.onBlur("action"));
    })("click", function FileListItemActionButtonComponent_button_4_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.onRemoveCancelClick($event));
    });
    i0.ɵɵelement(1, "kendo-icon-wrapper", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c0, ctx_r3.actionFocused));
    i0.ɵɵattribute("tabIndex", -1)("aria-hidden", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("name", ctx_r3.isUploading ? "cancel" : "x")("svgIcon", ctx_r3.isUploading ? ctx_r3.cancelSVGIcon : ctx_r3.deleteSVGIcon)("innerCssClass", !ctx_r3.isUploading ? "k-delete" : "");
    i0.ɵɵattribute("aria-label", ctx_r3.actionButtonTitle)("title", ctx_r3.actionButtonTitle);
  }
}
const _c1 = (a0, a1, a2) => ({
  "k-file-validation-message": a0,
  "k-file-size": a1,
  "k-file-summary": a2
});
function FileListSingleItemComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 7);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 8);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.file.name);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.file.name);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(5, _c1, ctx_r0.file.validationErrors, !ctx_r0.file.validationErrors && ctx_r0.isNotYetUploaded, ctx_r0.isUploadSuccessful || ctx_r0.isUploadFailed));
    i0.ɵɵattribute("aria-live", "polite");
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.fileStatusText);
  }
}
const _c2 = a0 => [a0];
const _c3 = (a0, a1, a2) => ({
  templateRef: a0,
  state: a1,
  $implicit: a2
});
function FileListSingleItemComponent_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 9);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.fileInfoTemplate.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction3(4, _c3, ctx_r1.fileInfoTemplate.templateRef, ctx_r1.file.state, i0.ɵɵpureFunction1(2, _c2, ctx_r1.file)));
  }
}
const _c4 = () => ({
  visible: false
});
const _c5 = (a0, a1) => ({
  "k-file-validation-message": a0,
  "k-file-size": a1
});
function FileListMultipleItemsComponent_ng_container_4_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 9)(1, "span", 10);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 11);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const file_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", file_r3.name);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", file_r3.name, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(5, _c5, file_r3.validationErrors, !file_r3.validationErrors));
    i0.ɵɵattribute("aria-live", "polite");
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r2.fileStatusText(file_r3));
  }
}
function FileListMultipleItemsComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FileListMultipleItemsComponent_ng_container_4_span_1_Template, 5, 8, "span", 7);
    i0.ɵɵelementStart(2, "span", 8);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.files);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.batchStatusText);
  }
}
function FileListMultipleItemsComponent_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 12);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.fileInfoTemplate.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction3(2, _c3, ctx_r1.fileInfoTemplate.templateRef, ctx_r1.files[0].state, ctx_r1.files));
  }
}
const _c6 = ["kendo-upload-file-list", ""];
function FileListComponent_ng_template_0_kendo_upload_file_list_single_item_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-upload-file-list-single-item", 5);
  }
  if (rf & 2) {
    const files_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r3.disabled)("file", files_r1[0])("fileInfoTemplate", ctx_r3.fileInfoTemplate);
  }
}
function FileListComponent_ng_template_0_kendo_upload_file_list_multiple_items_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-upload-file-list-multiple-items", 6);
  }
  if (rf & 2) {
    const files_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r4.disabled)("files", files_r1)("fileInfoTemplate", ctx_r4.fileInfoTemplate);
  }
}
function FileListComponent_ng_template_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 7);
  }
  if (rf & 2) {
    const files_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r5.fileTemplate.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction3(2, _c3, ctx_r5.fileTemplate.templateRef, files_r1[0].state, files_r1));
  }
}
function FileListComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 1);
    i0.ɵɵtemplate(1, FileListComponent_ng_template_0_kendo_upload_file_list_single_item_1_Template, 1, 3, "kendo-upload-file-list-single-item", 2)(2, FileListComponent_ng_template_0_kendo_upload_file_list_multiple_items_2_Template, 1, 3, "kendo-upload-file-list-multiple-items", 3)(3, FileListComponent_ng_template_0_ng_container_3_Template, 1, 6, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const files_r1 = ctx.$implicit;
    const index_r2 = ctx.index;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("files", files_r1)("index", index_r2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", files_r1.length === 1 && !ctx_r0.fileTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", files_r1.length > 1 && !ctx_r0.fileTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.fileTemplate);
  }
}
const _c7 = ["fileSelectInput"];
const _c8 = ["fileSelectButton"];
function FileSelectComponent_ul_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ul", 9);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r2.disabled)("fileList", ctx_r2.fileList.files)("fileTemplate", ctx_r2.fileTemplate)("fileInfoTemplate", ctx_r2.fileInfoTemplate);
  }
}
const _c21 = ["clearButton"];
const _c22 = ["uploadButton"];
function UploadComponent_kendo_upload_status_total_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-upload-status-total", 11);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("fileList", ctx_r2.fileList);
  }
}
function UploadComponent_ul_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ul", 12);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r3.disabled)("fileList", ctx_r3.fileList.files)("fileTemplate", ctx_r3.fileTemplate)("fileInfoTemplate", ctx_r3.fileInfoTemplate);
  }
}
function UploadComponent_kendo_upload_action_buttons_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-upload-action-buttons", 13);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r4.disabled)("actionsLayout", ctx_r4.actionsLayout);
  }
}
const _c65 = ["*"];
var FileState;
(function (FileState) {
  /**
   * The file upload process has failed.
   */
  FileState[FileState["Failed"] = 0] = "Failed";
  /**
   * An initially selected fake file without a set state.
   */
  FileState[FileState["Initial"] = 1] = "Initial";
  /**
   * The file is selected.
   */
  FileState[FileState["Selected"] = 2] = "Selected";
  /**
   * The file is successfully uploaded.
   */
  FileState[FileState["Uploaded"] = 3] = "Uploaded";
  /**
   * The file is in the process of uploading.
   */
  FileState[FileState["Uploading"] = 4] = "Uploading";
  /**
   * The file upload process has been paused.
   */
  FileState[FileState["Paused"] = 5] = "Paused";
})(FileState || (FileState = {}));

/**
 * @hidden
 */
class FileMap {
  constructor() {
    this._files = {};
  }
  add(file) {
    const uid = file.uid;
    if (this.has(uid)) {
      if (file.validationErrors && file.validationErrors.length > 0) {
        this._files[uid].unshift(file);
      } else {
        this._files[uid].push(file);
      }
    } else {
      this._files[uid] = [file];
    }
  }
  remove(uid) {
    if (this.has(uid)) {
      this._files[uid] = null;
      delete this._files[uid];
    }
  }
  clear() {
    const allFiles = this._files;
    for (const uid in allFiles) {
      if (allFiles.hasOwnProperty(uid)) {
        for (const file of allFiles[uid]) {
          if (file.httpSubscription) {
            file.httpSubscription.unsubscribe();
          }
        }
        allFiles[uid] = null;
        delete allFiles[uid];
      }
    }
  }
  has(uid) {
    return uid in this._files;
  }
  get(uid) {
    return this._files[uid];
  }
  setFilesState(files, state) {
    for (const file of files) {
      this.setFilesStateByUid(file.uid, state);
    }
  }
  setFilesStateByUid(uid, state) {
    this.get(uid).forEach(f => {
      f.state = state;
    });
  }
  get count() {
    return Object.getOwnPropertyNames(this._files).length;
  }
  get files() {
    const initial = this._files;
    const transformed = [];
    for (const uid in initial) {
      if (initial.hasOwnProperty(uid)) {
        transformed.push(initial[uid]);
      }
    }
    return transformed;
  }
  get filesFlat() {
    const initial = this._files;
    const transformed = [];
    for (const uid in initial) {
      if (initial.hasOwnProperty(uid)) {
        const current = initial[uid];
        current.forEach(file => {
          transformed.push(file);
        });
      }
    }
    return transformed;
  }
  get filesToUpload() {
    const files = this._files;
    const notUploaded = [];
    for (const uid in files) {
      if (files.hasOwnProperty(uid)) {
        const currentFiles = files[uid];
        let currentFilesValid = true;
        for (const file of currentFiles) {
          if (file.state !== FileState.Selected || file.validationErrors && file.validationErrors.length > 0) {
            currentFilesValid = false;
          }
        }
        if (currentFilesValid) {
          notUploaded.push(currentFiles);
        }
      }
    }
    return notUploaded;
  }
  get firstFileToUpload() {
    const files = this._files;
    for (const uid in files) {
      if (files.hasOwnProperty(uid)) {
        const currentFiles = files[uid];
        let currentFilesValid = true;
        for (const file of currentFiles) {
          if (file.state !== FileState.Selected || file.validationErrors && file.validationErrors.length > 0) {
            currentFilesValid = false;
          }
        }
        if (currentFilesValid) {
          return currentFiles;
        }
      }
    }
    return null;
  }
  getFilesWithState(state) {
    return this.filesFlat.filter(file => file.state === state);
  }
  hasFileWithState(fileStates) {
    const files = this._files;
    for (const uid in files) {
      if (files.hasOwnProperty(uid)) {
        const currentFiles = files[uid];
        for (const file of currentFiles) {
          if (fileStates.indexOf(file.state) >= 0) {
            return true;
          }
        }
      }
    }
    return false;
  }
}

/**
 * Arguments for the `cancel` event. The `cancel` event fires when
 * the user cancels the process of uploading a file or a batch of files.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <p>Click the <span class='k-icon k-font-icon k-i-cancel'></span> icon during upload to trigger the event</p>
 *    <kendo-upload
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (cancel)="cancelEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    cancelEventHandler(e: CancelEvent) {
 *      console.log('Canceling file upload', e.files);
 *    }
 *  }
 * ```
 */
class CancelEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `cancel` event.
   * @param files - The list of the files that were going to be uploaded.
   */
  constructor(files) {
    this.files = files;
  }
}

/**
 * @hidden
 */
class PreventableEvent {
  constructor() {
    this.prevented = false;
  }
  /**
   * Prevents the default action for a specified event.
   * In this way, the source component suppresses the built-in behavior that follows the event.
   */
  preventDefault() {
    this.prevented = true;
  }
  /**
   * If the event is prevented by any of its subscribers, returns `true`.
   *
   * @returns `true` if the default action was prevented. Otherwise, returns `false`.
   */
  isDefaultPrevented() {
    return this.prevented;
  }
}

/**
 * Arguments for the `clear` event. The `clear` event fires when
 * the **Clear** button is clicked. At this point, the selected files are about to be cleared.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [autoUpload]="false"
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (clear)="clearEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    clearEventHandler(e: ClearEvent) {
 *      console.log('Clearing the file upload');
 *    }
 *  }
 * ```
 */
class ClearEvent extends PreventableEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `clear` event.
   */
  constructor() {
    super();
  }
}

/**
 * Arguments for the `error` event. The `error` event fires when
 * an `upload` or `remove` operation fails.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (error)="errorEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    errorEventHandler(e: ErrorEvent) {
 *      console.log('An error occurred');
 *    }
 *  }
 * ```
 */
class ErrorEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `error` event.
   *
   * @param files - The list of the files that failed to be uploaded or removed.
   * @param operation - The operation type (`upload` or `remove`).
   * @param response - The response object returned by the server.
   */
  constructor(files, operation, response) {
    this.files = files;
    this.operation = operation;
    this.response = response;
  }
}

/**
 * Arguments for the `pause` event. The `pause` event fires when the user
 * pauses a file that is currently uploading.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [chunkable]="true"
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (pause)="pauseEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    pauseEventHandler(ev: PauseEvent) {
 *      console.log('File paused');
 *    }
 *  }
 * ```
 *
 */
class PauseEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `pause` event.
   * @param file - The file that is going to be paused.
   */
  constructor(file) {
    this.file = file;
  }
}

/**
 * Arguments for the `remove` event. The `remove` event fires when an uploaded
 * or selected file is about to be removed. If you cancel the event, the removal is prevented.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (remove)="removeEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    removeEventHandler(e: RemoveEvent) {
 *      console.log('Removing a file');
 *    }
 *  }
 * ```
 */
class RemoveEvent extends PreventableEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `remove` event.
   * @param files - The list of the files that will be removed.
   * @param headers - The headers of the request.
   */
  constructor(files, headers) {
    super();
    this.files = files;
    this.headers = headers;
  }
}

/**
 * Arguments for the `resume` event. The `resume` event fires when the user
 * resumes the upload of a file that has been previously paused.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [chunkable]="true"
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (resume)="resumeEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    resumeEventHandler(ev: ResumeEvent) {
 *      console.log('File resumed');
 *    }
 *  }
 * ```
 *
 */
class ResumeEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `resume` event.
   * @param file - The file that is going to be resumed.
   */
  constructor(file) {
    this.file = file;
  }
}

/**
 * Arguments for the `select` event. The `select` event fires when the user
 * selects a file or multiple files for upload. If you cancel the event, the selection is prevented.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (select)="selectEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    selectEventHandler(e: SelectEvent) {
 *      console.log('File selected');
 *    }
 *  }
 * ```
 */
class SelectEvent extends PreventableEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `select` event.
   * @param files - The list of the selected files.
   */
  constructor(files) {
    super();
    this.files = files;
  }
}

/**
 * Arguments for the `success` event. The `success` event fires when
 * the selected files are successfully uploaded or removed.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (success)="successEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    successEventHandler(e: SuccessEvent) {
 *      console.log('The ' + e.operation + ' was successful!');
 *    }
 *  }
 * ```
 */
class SuccessEvent extends PreventableEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `success` event.
   * @param files - The list of the files that were uploaded or removed.
   * @param operation - The operation type (`upload` or `remove`).
   * @param response - The response object returned by the server.
   */
  constructor(files, operation, response) {
    super();
    this.files = files;
    this.operation = operation;
    this.response = response;
  }
}

/**
 * Arguments for the `upload` event. The `upload` event fires when one or more files are about
 * to be uploaded. If you cancel the event, the upload is prevented. You can add headers to the request.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (upload)="uploadEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    uploadEventHandler(e: UploadEvent) {
 *      e.headers = e.headers.append('X-Foo', 'Bar');
 *    }
 *  }
 * ```
 */
class UploadEvent extends PreventableEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `upload` event.
   * @param files - The list of the files that will be uploaded.
   * @param headers - The headers of the request.
   */
  constructor(files, headers) {
    super();
    this.files = files;
    this.headers = headers;
  }
}

/**
 * Arguments for the `uploadprogress` event. The `uploadprogress` event
 * fires when the files are in the process of uploading.
 *
 * ```ts-no-run
 *  @Component({
 *    selector: 'my-upload',
 *    template: `
 *    <kendo-upload
 *      [saveUrl]="uploadSaveUrl"
 *      [removeUrl]="uploadRemoveUrl"
 *      (uploadProgress)="uploadProgressEventHandler($event)">
 *    </kendo-upload>
 *    `
 *  })
 *  export class UploadComponent {
 *    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
 *    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
 *
 *    uploadProgressEventHandler(e: UploadProgressEvent) {
 *      console.log(e.files[0].name + ' is ' + e.percentComplete + ' uploaded');
 *    }
 *  }
 * ```
 */
class UploadProgressEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `uploadprogress` event.
   * @param files - The list of files that are being uploaded.
   * @param percentComplete - The portion that has been uploaded.
   */
  constructor(files, percentComplete) {
    this.files = files;
    this.percentComplete = percentComplete;
  }
}

/**
 * @hidden
 */
const fileGroupMap = {
  audio: [".aif", ".iff", ".m3u", ".m4a", ".mid", ".mp3", ".mpa", ".wav", ".wma", ".ogg", ".wav", ".wma", ".wpl"],
  video: [".3g2", ".3gp", ".avi", ".asf", ".flv", ".m4u", ".rm", ".h264", ".m4v", ".mkv", ".mov", ".mp4", ".mpg", ".rm", ".swf", ".vob", ".wmv"],
  image: [".ai", ".dds", ".heic", ".jpe", "jfif", ".jif", ".jp2", ".jps", ".eps", ".bmp", ".gif", ".jpeg", ".jpg", ".png", ".ps", ".psd", ".svg", ".svgz", ".tif", ".tiff"],
  txt: [".doc", ".docx", ".log", ".pages", ".tex", ".wpd", ".wps", ".odt", ".rtf", ".text", ".txt", ".wks"],
  presentation: [".key", ".odp", ".pps", ".ppt", ".pptx"],
  data: [".xlr", ".xls", ".xlsx"],
  programming: [".tmp", ".bak", ".msi", ".cab", ".cpl", ".cur", ".dll", ".dmp", ".drv", ".icns", ".ico", ".link", ".sys", ".cfg", ".ini", ".asp", ".aspx", ".cer", ".csr", ".css", ".dcr", ".htm", ".html", ".js", ".php", ".rss", ".xhtml"],
  pdf: [".pdf"],
  config: [".apk", ".app", ".bat", ".cgi", ".com", ".exe", ".gadget", ".jar", ".wsf"],
  zip: [".7z", ".cbr", ".gz", ".sitx", ".arj", ".deb", ".pkg", ".rar", ".rpm", ".tar.gz", ".z", ".zip", ".zipx"],
  discImage: [".dmg", ".iso", ".toast", ".vcd", ".bin", ".cue", ".mdf"]
};
;
/**
 * @hidden
 */
const fileSVGGroupMap = {
  audio: fileAudioIcon,
  video: fileVideoIcon,
  image: fileImageIcon,
  txt: fileTxtIcon,
  presentation: filePresentationIcon,
  data: fileDataIcon,
  programming: fileProgrammingIcon,
  pdf: filePdfIcon,
  config: fileConfigIcon,
  zip: fileZipIcon,
  discImage: fileDiscImageIcon
};

/* eslint-disable  no-bitwise */
/**
 * @hidden
 */
const getTotalFilesSizeMessage = files => {
  let totalSize = 0;
  let i;
  if (typeof files[0].size === "number") {
    for (i = 0; i < files.length; i++) {
      if (files[i].size) {
        totalSize += files[i].size;
      }
    }
  } else {
    return "";
  }
  totalSize /= 1024;
  if (totalSize < 1024) {
    return totalSize.toFixed(2) + " KB";
  } else {
    return (totalSize / 1024).toFixed(2) + " MB";
  }
};
const stripPath = name => {
  const slashIndex = name.lastIndexOf("\\");
  return slashIndex !== -1 ? name.substr(slashIndex + 1) : name;
};
const getFileExtension = fileName => {
  const rFileExtension = /\.([^\.]+)$/;
  const matches = fileName.match(rFileExtension);
  return matches ? matches[0] : "";
};
/**
 * @hidden
 */
const validateInitialFileInfo = file => {
  if (file instanceof Object && file.hasOwnProperty("name")) {
    return true;
  }
  return false;
};
/**
 * @hidden
 */
const validateInitialFileSelectFile = file => {
  if (file instanceof File || validateInitialFileInfo(file)) {
    return true;
  }
  return false;
};
/**
 * @hidden
 */
const getInitialFileInfo = fakeFile => {
  fakeFile.extension = fakeFile.extension || getFileExtension(fakeFile.name);
  fakeFile.name = fakeFile.name; // eslint-disable-line no-self-assign
  fakeFile.size = fakeFile.size || 0;
  if (!fakeFile.hasOwnProperty("state")) {
    fakeFile.state = FileState.Initial;
  }
  if (!fakeFile.hasOwnProperty("uid")) {
    fakeFile.uid = guid();
  }
  return fakeFile;
};
/**
 * @hidden
 */
const convertFileToFileInfo = file => {
  const fileInfo = getFileInfo(file);
  fileInfo.uid = guid();
  // Used to differentiate initial FileInfo objects and actual Files
  fileInfo.state = FileState.Selected;
  return fileInfo;
};
const getFileInfo = rawFile => {
  const fileName = rawFile.name;
  const fileSize = rawFile.size;
  return {
    extension: getFileExtension(fileName),
    name: fileName,
    rawFile: rawFile,
    size: fileSize,
    state: FileState.Selected
  };
};
/**
 * @hidden
 */
const getAllFileInfo = rawFiles => {
  const allFileInfo = new Array();
  let i;
  for (i = 0; i < rawFiles.length; i++) {
    allFileInfo.push(getFileInfo(rawFiles[i]));
  }
  return allFileInfo;
};
/**
 * @hidden
 */
const fileHasValidationErrors = file => {
  if (file.validationErrors && file.validationErrors.length > 0) {
    return true;
  }
  return false;
};
/**
 * @hidden
 */
const filesHaveValidationErrors = files => {
  for (const file of files) {
    if (fileHasValidationErrors(file)) {
      return true;
    }
  }
  return false;
};
/**
 * @hidden
 */
const inputFiles = input => {
  if (input.files) {
    return getAllFileInfo(input.files);
  } else {
    //Required for testing
    const fileNames = input.value.split("|").map((file, index) => {
      const fileName = file.trim();
      return {
        extension: getFileExtension(fileName),
        name: stripPath(fileName),
        rawFile: null,
        size: (index + 1) * 1000,
        state: FileState.Selected
      };
    });
    return fileNames;
  }
};
/**
 * @hidden
 */
const assignGuidToFiles = (files, isUnique) => {
  const uid = guid();
  return files.map(file => {
    file.uid = isUnique ? guid() : uid;
    return file;
  });
};
/**
 * @hidden
 */
const supportsFormData = () => {
  return typeof FormData !== "undefined";
};
/**
 * @hidden
 */
const userAgent = () => {
  return navigator.userAgent;
};
const focusableRegex = /^(?:a|input|select|textarea|button|object)$/i;
/**
 * @hidden
 */
const IGNORE_TARGET_CLASSES = 'k-icon k-select k-input k-multiselect-wrap';
/**
 * @hidden
 */
const UPLOAD_CLASSES = 'k-upload-button k-clear-selected k-upload-selected k-upload-action';
const isVisible = element => {
  const rect = element.getBoundingClientRect();
  return !!(rect.width && rect.height) && window.getComputedStyle(element).visibility !== 'hidden';
};
const toClassList = classNames => String(classNames).trim().split(' ');
/**
 * @hidden
 */
const hasClasses = (element, classNames) => {
  const namesList = toClassList(classNames);
  return Boolean(toClassList(element.className).find(className => namesList.indexOf(className) >= 0));
};
/**
 * @hidden
 */
const isFocusable = (element, checkVisibility = true) => {
  if (element.tagName) {
    const tagName = element.tagName.toLowerCase();
    const tabIndex = element.getAttribute('tabIndex');
    const validTabIndex = tabIndex !== null && !isNaN(tabIndex) && tabIndex > -1;
    let focusable = false;
    if (focusableRegex.test(tagName)) {
      focusable = !element.disabled;
    } else {
      focusable = validTabIndex;
    }
    return focusable && (!checkVisibility || isVisible(element));
  }
  return false;
};
/**
 * @hidden
 */
const getFileGroupCssClass = fileExtension => {
  const initial = 'file';
  for (const group in fileGroupMap) {
    if (fileGroupMap[group].indexOf(fileExtension) >= 0) {
      if (group === 'discImage') {
        return `${initial}-disc-image`;
      }
      return `${initial}-${group}`;
    }
  }
  return initial;
};
/**
 * @hidden
 */
const isPresent = value => value !== null && value !== undefined;

/**
 * @hidden
 */
class ChunkMap {
  constructor() {
    this._files = {};
  }
  add(uid, totalChunks) {
    const initialChunkInfo = {
      index: 0,
      position: 0,
      retries: 0,
      totalChunks: totalChunks
    };
    this._files[uid] = initialChunkInfo;
    return initialChunkInfo;
  }
  remove(uid) {
    if (this.has(uid)) {
      this._files[uid] = null;
      delete this._files[uid];
    }
  }
  has(uid) {
    return uid in this._files;
  }
  get(uid) {
    return this._files[uid];
  }
}

/**
 * @hidden
 */
class UploadService {
  constructor(http) {
    this.http = http;
    this.cancelEvent = new EventEmitter();
    this.clearEvent = new EventEmitter();
    this.completeEvent = new EventEmitter();
    this.errorEvent = new EventEmitter();
    this.pauseEvent = new EventEmitter();
    this.removeEvent = new EventEmitter();
    this.resumeEvent = new EventEmitter();
    this.selectEvent = new EventEmitter();
    this.successEvent = new EventEmitter();
    this.uploadEvent = new EventEmitter();
    this.uploadProgressEvent = new EventEmitter();
    /**
     * Required for the `ControlValueAccessor` integration
     */
    this.changeEvent = new EventEmitter();
    /**
     * Default async settings
     */
    this.async = {
      autoUpload: true,
      batch: false,
      chunk: false,
      concurrent: true,
      removeField: "fileNames",
      removeHeaders: new HttpHeaders(),
      removeMethod: "POST",
      removeUrl: "",
      responseType: "json",
      saveField: "files",
      saveHeaders: new HttpHeaders(),
      saveMethod: "POST",
      saveUrl: "",
      withCredentials: true
    };
    /**
     * Default chunk settings
     */
    this.chunk = {
      autoRetryAfter: 100,
      size: 1024 * 1024,
      maxAutoRetries: 1,
      resumable: true
    };
    this.component = 'Upload';
    this.chunkMap = new ChunkMap();
    this.fileList = new FileMap();
  }
  get files() {
    return this.fileList;
  }
  setChunkSettings(settings) {
    if (settings !== false) {
      this.async.chunk = true;
      if (typeof settings === "object") {
        this.chunk = Object.assign({}, this.chunk, settings);
      }
    }
  }
  onChange() {
    const files = this.fileList.filesFlat.filter(file => {
      return file.state === FileState.Initial || file.state === FileState.Uploaded;
    });
    this.changeEvent.emit(files.length > 0 ? files : null);
  }
  addFiles(files) {
    const selectEventArgs = new SelectEvent(files);
    this.selectEvent.emit(selectEventArgs);
    if (!selectEventArgs.isDefaultPrevented()) {
      for (const file of files) {
        this.fileList.add(file);
      }
      if (this.async.autoUpload) {
        this.uploadFiles();
      }
    }
    if (this.component === 'FileSelect') {
      const flatFiles = this.fileList.filesFlat;
      this.changeEvent.emit(flatFiles.length > 0 ? flatFiles : null);
    }
  }
  addInitialFiles(initialFiles) {
    this.fileList.clear();
    initialFiles.forEach(file => {
      const fakeFile = getInitialFileInfo(file);
      this.fileList.add(fakeFile);
    });
  }
  addInitialFileSelectFiles(initialFiles) {
    this.fileList.clear();
    initialFiles.forEach(file => {
      if (file instanceof File) {
        this.fileList.add(convertFileToFileInfo(file));
      } else {
        this.fileList.add(getInitialFileInfo(file));
      }
    });
  }
  resumeFile(uid) {
    const fileToResume = this.fileList.get(uid);
    this.resumeEvent.emit(new ResumeEvent(fileToResume[0]));
    this.fileList.setFilesStateByUid(uid, FileState.Uploading);
    this._uploadFiles([fileToResume]);
  }
  pauseFile(uid) {
    const pausedFile = this.fileList.get(uid)[0];
    this.pauseEvent.emit(new PauseEvent(pausedFile));
    this.fileList.setFilesStateByUid(uid, FileState.Paused);
  }
  removeFiles(uid) {
    const removedFiles = this.fileList.get(uid);
    // Clone the Headers so that the default ones are not overridden
    const removeEventArgs = new RemoveEvent(removedFiles, this.cloneRequestHeaders(this.async.removeHeaders));
    this.removeEvent.emit(removeEventArgs);
    if (!removeEventArgs.isDefaultPrevented()) {
      if (this.component === 'Upload' && (removedFiles[0].state === FileState.Uploaded || removedFiles[0].state === FileState.Initial)) {
        this.performRemove(removedFiles, removeEventArgs);
      } else {
        this.fileList.remove(uid);
        if (this.component === 'FileSelect') {
          const flatFiles = this.fileList.filesFlat;
          this.changeEvent.emit(flatFiles.length > 0 ? flatFiles : null);
        }
      }
    }
  }
  cancelFiles(uid) {
    const canceledFiles = this.fileList.get(uid);
    const cancelEventArgs = new CancelEvent(canceledFiles);
    this.cancelEvent.emit(cancelEventArgs);
    for (const file of canceledFiles) {
      if (file.httpSubscription) {
        file.httpSubscription.unsubscribe();
      }
    }
    this.fileList.remove(uid);
    this.checkAllComplete();
  }
  clearFiles() {
    const clearEventArgs = new ClearEvent();
    this.clearEvent.emit(clearEventArgs);
    if (!clearEventArgs.isDefaultPrevented()) {
      const triggerChange = this.fileList.hasFileWithState([FileState.Initial, FileState.Uploaded]);
      this.fileList.clear();
      if (triggerChange) {
        this.onChange();
      }
    }
  }
  uploadFiles() {
    let filesToUpload = [];
    if (this.async.concurrent) {
      filesToUpload = this.fileList.filesToUpload;
    }
    if (!this.async.concurrent && !this.fileList.hasFileWithState([FileState.Uploading])) {
      filesToUpload = this.fileList.firstFileToUpload ? [this.fileList.firstFileToUpload] : [];
    }
    if (filesToUpload && filesToUpload.length > 0) {
      this._uploadFiles(filesToUpload);
    }
  }
  retryFiles(uid) {
    const filesToRetry = [this.fileList.get(uid)];
    if (filesToRetry) {
      this._uploadFiles(filesToRetry);
    }
  }
  _uploadFiles(allFiles) {
    for (const filesToUpload of allFiles) {
      if (filesToUpload[0].state === FileState.Paused) {
        return;
      }
      // Clone the Headers so that the default ones are not overridden
      const uploadEventArgs = new UploadEvent(filesToUpload, this.cloneRequestHeaders(this.async.saveHeaders));
      this.uploadEvent.emit(uploadEventArgs);
      if (!uploadEventArgs.isDefaultPrevented()) {
        this.fileList.setFilesState(filesToUpload, FileState.Uploading);
        const httpSubcription = this.performUpload(filesToUpload, uploadEventArgs);
        filesToUpload.forEach(file => {
          file.httpSubscription = httpSubcription;
        });
      } else {
        this.fileList.remove(filesToUpload[0].uid);
      }
    }
  }
  performRemove(files, removeEventArgs) {
    const async = this.async;
    const fileNames = files.map(file => {
      return file.name;
    });
    const formData = this.populateRemoveFormData(fileNames, removeEventArgs.data);
    const options = this.populateRequestOptions(removeEventArgs.headers, false);
    const removeRequest = new HttpRequest(async.removeMethod, async.removeUrl, formData, options);
    this.http.request(removeRequest).subscribe(success => {
      this.onSuccess(success, files, "remove");
    }, error => {
      this.onError(error, files, "remove");
    });
  }
  performUpload(files, uploadEventArgs) {
    const async = this.async;
    const formData = this.populateUploadFormData(files, uploadEventArgs.data);
    const options = this.populateRequestOptions(uploadEventArgs.headers);
    const uploadRequest = new HttpRequest(async.saveMethod, async.saveUrl, formData, options);
    const httpSubscription = this.http.request(uploadRequest).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress && !this.async.chunk) {
        this.onProgress(event, files);
      } else if (event instanceof HttpResponse) {
        this.onSuccess(event, files, "upload");
        this.checkAllComplete();
      }
    }, error => {
      this.onError(error, files, "upload");
      this.checkAllComplete();
    });
    return httpSubscription;
  }
  onSuccess(successResponse, files, operation) {
    if (operation === "upload" && this.async.chunk) {
      this.onChunkProgress(files);
      if (this.isChunkUploadComplete(files[0].uid)) {
        this.removeChunkInfo(files[0].uid);
      } else {
        this.updateChunkInfo(files[0].uid);
        this._uploadFiles([files]);
        return;
      }
    }
    const successArgs = new SuccessEvent(files, operation, successResponse);
    this.successEvent.emit(successArgs);
    if (operation === "upload") {
      this.fileList.setFilesState(files, successArgs.isDefaultPrevented() ? FileState.Failed : FileState.Uploaded);
    } else {
      if (!successArgs.isDefaultPrevented()) {
        this.fileList.remove(files[0].uid);
      }
    }
    if (!successArgs.isDefaultPrevented()) {
      this.onChange();
    }
  }
  onError(errorResponse, files, operation) {
    if (operation === "upload" && this.async.chunk) {
      const maxRetries = this.chunk.maxAutoRetries;
      const chunkInfo = this.chunkMap.get(files[0].uid);
      if (chunkInfo.retries < maxRetries) {
        chunkInfo.retries += 1;
        setTimeout(() => {
          this.retryFiles(files[0].uid);
        }, this.chunk.autoRetryAfter);
        return;
      }
    }
    const errorArgs = new ErrorEvent(files, operation, errorResponse);
    this.errorEvent.emit(errorArgs);
    if (operation === "upload") {
      this.fileList.setFilesState(files, FileState.Failed);
    }
  }
  onProgress(event, files) {
    const percentComplete = Math.round(100 * event.loaded / event.total);
    const progressArgs = new UploadProgressEvent(files, percentComplete < 100 ? percentComplete : 100);
    this.uploadProgressEvent.emit(progressArgs);
  }
  onChunkProgress(files) {
    const chunkInfo = this.chunkMap.get(files[0].uid);
    let percentComplete = 0;
    if (chunkInfo) {
      if (chunkInfo.index === chunkInfo.totalChunks - 1) {
        percentComplete = 100;
      } else {
        percentComplete = Math.round((chunkInfo.index + 1) / chunkInfo.totalChunks * 100);
      }
    }
    const progressArgs = new UploadProgressEvent(files, percentComplete < 100 ? percentComplete : 100);
    this.uploadProgressEvent.emit(progressArgs);
  }
  checkAllComplete() {
    if (!this.fileList.hasFileWithState([FileState.Uploading, FileState.Paused]) && this.areAllSelectedFilesHandled()) {
      this.completeEvent.emit();
    } else if (this.shouldUploadNextFile()) {
      this.uploadFiles();
    }
  }
  shouldUploadNextFile() {
    return !this.async.concurrent && this.fileList.hasFileWithState([FileState.Selected]) && !this.fileList.hasFileWithState([FileState.Uploading]);
  }
  areAllSelectedFilesHandled() {
    const validSelectedFiles = this.fileList.getFilesWithState(FileState.Selected).filter(file => !file.validationErrors);
    return validSelectedFiles.length === 0;
  }
  cloneRequestHeaders(headers) {
    const cloned = {};
    if (headers) {
      headers.keys().forEach(key => {
        cloned[key] = headers.get(key);
      });
    }
    return new HttpHeaders(cloned);
  }
  populateRequestOptions(headers, reportProgress = true) {
    return {
      headers: headers,
      reportProgress: reportProgress,
      responseType: this.async.responseType,
      withCredentials: this.async.withCredentials
    };
  }
  populateUploadFormData(files, clientData) {
    const saveField = this.async.saveField;
    const data = new FormData();
    this.populateClientFormData(data, clientData);
    if (this.async.chunk) {
      data.append(saveField, this.getNextChunk(files[0]));
      data.append("metadata", this.getChunkMetadata(files[0]));
    } else {
      for (const file of files) {
        data.append(saveField, file.rawFile);
      }
    }
    return data;
  }
  populateRemoveFormData(fileNames, clientData) {
    const data = new FormData();
    this.populateClientFormData(data, clientData);
    for (const fileName of fileNames) {
      data.append(this.async.removeField, fileName);
    }
    return data;
  }
  populateClientFormData(data, clientData) {
    for (const key in clientData) {
      if (clientData.hasOwnProperty(key)) {
        data.append(key, clientData[key]);
      }
    }
  }
  /* Chunking Helper Methods Section */
  getNextChunk(file) {
    const info = this.getChunkInfo(file);
    const newPosition = info.position + this.chunk.size;
    return file.rawFile.slice(info.position, newPosition);
  }
  getChunkInfo(file) {
    let chunkInfo = this.chunkMap.get(file.uid);
    if (!chunkInfo) {
      const totalChunks = file.size > 0 ? Math.ceil(file.size / this.chunk.size) : 1;
      chunkInfo = this.chunkMap.add(file.uid, totalChunks);
    }
    return chunkInfo;
  }
  updateChunkInfo(uid) {
    const chunkInfo = this.chunkMap.get(uid);
    if (chunkInfo.index < chunkInfo.totalChunks - 1) {
      chunkInfo.index += 1;
      chunkInfo.position += this.chunk.size;
      chunkInfo.retries = 0;
    }
  }
  removeChunkInfo(uid) {
    this.chunkMap.remove(uid);
  }
  getChunkMetadata(file) {
    const chunkInfo = this.chunkMap.get(file.uid);
    const chunkMetadata = {
      chunkIndex: chunkInfo.index,
      contentType: file.rawFile.type,
      fileName: file.name,
      fileSize: file.size,
      fileUid: file.uid,
      totalChunks: chunkInfo.totalChunks
    };
    return JSON.stringify(chunkMetadata);
  }
  isChunkUploadComplete(uid) {
    const chunkInfo = this.chunkMap.get(uid);
    if (chunkInfo) {
      return chunkInfo.index + 1 === chunkInfo.totalChunks;
    }
    return false;
  }
}
UploadService.ɵfac = function UploadService_Factory(t) {
  return new (t || UploadService)(i0.ɵɵinject(i1.HttpClient));
};
UploadService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UploadService,
  factory: UploadService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadService, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.HttpClient
    }];
  }, null);
})();

/**
 * @hidden
 */
class NavigationService {
  constructor(uploadService, zone) {
    this.uploadService = uploadService;
    this.zone = zone;
    this.onActionButtonFocus = new EventEmitter();
    this.onFileAction = new EventEmitter();
    this.onFileFocus = new EventEmitter();
    this.onTabOut = new EventEmitter();
    this.onWrapperFocus = new EventEmitter();
    this.onSelectButtonFocus = new EventEmitter();
    this.actionButtonsVisible = false;
    this.focused = false;
    this._focusedIndex = -1;
  }
  action(event) {
    const key = event.keyCode;
    return this.keyBindings[key];
  }
  process(event, component) {
    const handler = this.action(event);
    if (handler) {
      handler(event, component);
    }
  }
  computeKeys() {
    this.keyBindings = {
      [Keys.Space]: () => this.handleSpace(),
      [Keys.Enter]: () => this.handleEnter(),
      [Keys.Escape]: () => this.handleEscape(),
      [Keys.Delete]: () => this.handleDelete(),
      [Keys.Tab]: (event, component) => this.handleTab(event.shiftKey, component),
      [Keys.ArrowUp]: event => this.handleUp(event),
      [Keys.ArrowDown]: event => this.handleDown(event)
    };
  }
  focusSelectButton() {
    this.focused = true;
    this._focusedIndex = -1;
    this.onSelectButtonFocus.emit();
  }
  handleEnter() {
    if (this.lastIndex >= 0 && this.focusedIndex >= 0 && this.focusedIndex <= this.lastFileIndex) {
      this.zone.run(() => this.onFileAction.emit(Keys.Enter));
    }
  }
  handleSpace() {
    if (this.lastIndex >= 0 && this.focusedIndex >= 0 && this.focusedIndex <= this.lastFileIndex) {
      this.zone.run(() => this.onFileAction.emit(Keys.Space));
    }
  }
  handleDelete() {
    if (this.focusedIndex >= 0 && this.focusedIndex <= this.lastFileIndex) {
      this.zone.run(() => this.onFileAction.emit(Keys.Delete));
    }
  }
  handleEscape() {
    if (this.focusedIndex >= 0 && this.focusedIndex <= this.lastFileIndex) {
      this.zone.run(() => this.onFileAction.emit(Keys.Escape));
    }
  }
  handleTab(shifted, component) {
    /* Select Files button is focused */
    if (this.focusedIndex === -1 && this.actionButtonsVisible && !shifted) {
      this.focusedIndex = this.lastFileIndex + 1;
      return;
    }
    /* File in the list is focused */
    if (this.focusedIndex > -1 && this.focusedIndex <= this.lastFileIndex) {
      if (shifted) {
        this.focusedIndex = -1;
      } else if (component !== 'fileselect' && this.actionButtonsVisible) {
        this.focusedIndex = this.lastFileIndex + 1;
        return;
      }
    }
    /* Clear button is focused */
    if (this.focusedIndex === this.lastFileIndex + 1) {
      this.focusedIndex = shifted ? -1 : this.lastIndex;
      return;
    }
    /* Upload button is focused */
    if (this.focusedIndex === this.lastIndex && this.actionButtonsVisible && shifted) {
      this.focusedIndex -= 1;
      return;
    }
    this.onTabOut.emit();
  }
  handleDown(event) {
    if (this.lastIndex >= 0 && this.focusedIndex < this.lastIndex) {
      event.preventDefault();
      this.zone.run(() => {
        if (this.focusedIndex < this.lastFileIndex) {
          this.focusedIndex += 1;
          this.onFileFocus.emit(this.focusedIndex);
          return;
        }
      });
    }
  }
  handleUp(event) {
    if (this.lastIndex >= 0 && this.focusedIndex > -1) {
      event.preventDefault();
      this.zone.run(() => {
        this.focusedIndex -= 1;
        if (this.focusedIndex === -1) {
          this.onSelectButtonFocus.emit();
          return;
        }
        if (this.focusedIndex <= this.lastFileIndex) {
          this.onFileFocus.emit(this.focusedIndex);
          return;
        }
        if (this.actionButtonsVisible && this.focusedIndex <= this.lastIndex) {
          this.focusedIndex = this.lastFileIndex;
          this.onFileFocus.emit(this.focusedIndex);
        }
      });
    }
  }
  get focusedIndex() {
    return this._focusedIndex;
  }
  set focusedIndex(index) {
    if (!this.focused) {
      this.onWrapperFocus.emit();
    }
    this._focusedIndex = index;
    this.focused = true;
    if (this._focusedIndex >= 0 && this._focusedIndex <= this.lastFileIndex) {
      this.onFileFocus.emit(index);
    }
  }
  get lastFileIndex() {
    return this.actionButtonsVisible ? this.lastIndex - 2 : this.lastIndex;
  }
  get lastIndex() {
    const fileCount = this.uploadService.files.count;
    return this.actionButtonsVisible ? fileCount + 1 : fileCount - 1;
  }
}
NavigationService.ɵfac = function NavigationService_Factory(t) {
  return new (t || NavigationService)(i0.ɵɵinject(UploadService), i0.ɵɵinject(i0.NgZone));
};
NavigationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NavigationService,
  factory: NavigationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigationService, [{
    type: Injectable
  }], function () {
    return [{
      type: UploadService
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const components = {};
/**
 * @hidden
 */
class DropZoneService {
  addComponent(component, zoneId) {
    if (this.has(zoneId)) {
      components[zoneId].push(component);
    } else {
      components[zoneId] = [component];
    }
  }
  getComponents(zoneId) {
    return components[zoneId];
  }
  has(id) {
    return id in components;
  }
}
DropZoneService.ɵfac = function DropZoneService_Factory(t) {
  return new (t || DropZoneService)();
};
DropZoneService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DropZoneService,
  factory: DropZoneService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropZoneService, [{
    type: Injectable
  }], null, null);
})();

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-upload',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1710925202,
  version: '15.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * Used to customize the rendering of the files in the list ([see example]({% slug templates_upload %}#toc-file-template)).
 */
class FileTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
FileTemplateDirective.ɵfac = function FileTemplateDirective_Factory(t) {
  return new (t || FileTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
FileTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FileTemplateDirective,
  selectors: [["", "kendoUploadFileTemplate", ""], ["", "kendoFileSelectFileTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoUploadFileTemplate], [kendoFileSelectFileTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();

/**
 * Used to customize the rendering of the file info section in the list. All other elements of the default template, such as file icon, action buttons, upload progress etc. will be preserved in place. ([see example]({% slug templates_upload %}#toc-file-info-template)).
 */
class FileInfoTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
FileInfoTemplateDirective.ɵfac = function FileInfoTemplateDirective_Factory(t) {
  return new (t || FileInfoTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
FileInfoTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FileInfoTemplateDirective,
  selectors: [["", "kendoUploadFileInfoTemplate", ""], ["", "kendoFileSelectFileInfoTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileInfoTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoUploadFileInfoTemplate], [kendoFileSelectFileInfoTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();

/**
 * @hidden
 */
class FileListItemDirective {
  constructor(el, navigationService, uploadService) {
    this.navigationService = navigationService;
    this.uploadService = uploadService;
    this.fileClass = true;
    this.focused = false;
    this.element = el;
  }
  focus() {
    this.element.nativeElement.focus();
  }
  get uidAttribute() {
    return this.files[0].uid;
  }
  get tabIndex() {
    return "-1";
  }
  get kFileError() {
    return this.files[0].state === FileState.Failed;
  }
  get kFileInvalid() {
    return filesHaveValidationErrors(this.files);
  }
  get kFileProgress() {
    return this.files[0].state === FileState.Uploading || this.files[0].state === FileState.Paused;
  }
  get kFileSuccess() {
    if (this.uploadService.component === 'Upload') {
      return this.files[0].state === FileState.Uploaded || this.files[0].state === FileState.Initial;
    }
    return false;
  }
  get kStateFocused() {
    return this.focused;
  }
  onFocus() {
    this.focused = true;
  }
  onBlur() {
    this.focused = false;
  }
  onClick(event) {
    if (!isFocusable(event.target) && !hasClasses(event.target, IGNORE_TARGET_CLASSES)) {
      this.navigationService.focusedIndex = this.index;
    }
  }
}
FileListItemDirective.ɵfac = function FileListItemDirective_Factory(t) {
  return new (t || FileListItemDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(UploadService));
};
FileListItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FileListItemDirective,
  selectors: [["", "kendoUploadFileListItem", ""]],
  hostVars: 14,
  hostBindings: function FileListItemDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focus", function FileListItemDirective_focus_HostBindingHandler() {
        return ctx.onFocus();
      })("blur", function FileListItemDirective_blur_HostBindingHandler() {
        return ctx.onBlur();
      })("click", function FileListItemDirective_click_HostBindingHandler($event) {
        return ctx.onClick($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-uid", ctx.uidAttribute)("tabIndex", ctx.tabIndex);
      i0.ɵɵclassProp("k-file", ctx.fileClass)("k-file-error", ctx.kFileError)("k-file-invalid", ctx.kFileInvalid)("k-file-progress", ctx.kFileProgress)("k-file-success", ctx.kFileSuccess)("k-focus", ctx.kStateFocused);
    }
  },
  inputs: {
    files: "files",
    index: "index"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileListItemDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoUploadFileListItem]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: NavigationService
    }, {
      type: UploadService
    }];
  }, {
    files: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    fileClass: [{
      type: HostBinding,
      args: ['class.k-file']
    }],
    uidAttribute: [{
      type: HostBinding,
      args: ['attr.data-uid']
    }],
    tabIndex: [{
      type: HostBinding,
      args: ['attr.tabIndex']
    }],
    kFileError: [{
      type: HostBinding,
      args: ['class.k-file-error']
    }],
    kFileInvalid: [{
      type: HostBinding,
      args: ['class.k-file-invalid']
    }],
    kFileProgress: [{
      type: HostBinding,
      args: ['class.k-file-progress']
    }],
    kFileSuccess: [{
      type: HostBinding,
      args: ['class.k-file-success']
    }],
    kStateFocused: [{
      type: HostBinding,
      args: ['class.k-focus']
    }],
    onFocus: [{
      type: HostListener,
      args: ["focus"]
    }],
    onBlur: [{
      type: HostListener,
      args: ["blur"]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

/**
 * @hidden
 */
class FileListItemBase {
  constructor(uploadService) {
    this.uploadService = uploadService;
    this.progressComplete = 0;
  }
  subscribeUploadProgress(uploadProgressHandler) {
    this.uploadProgressSubscription = this.uploadService.uploadProgressEvent.subscribe(uploadProgressHandler);
  }
  fileHasValidationErrors(file) {
    return fileHasValidationErrors(file);
  }
  filesHaveValidationErrors(files) {
    return filesHaveValidationErrors(files);
  }
  ngOnDestroy() {
    this.uploadProgressSubscription.unsubscribe();
  }
  getFileValidationMessage(file) {
    let validationMessage;
    if (file.validationErrors && file.validationErrors.length > 0) {
      validationMessage = this.localization.get(file.validationErrors[0]);
    }
    return validationMessage;
  }
  getTotalFilesSizeMessage(files) {
    return getTotalFilesSizeMessage(files);
  }
  textFor(key) {
    return this.localization.get(key);
  }
}
FileListItemBase.ɵfac = function FileListItemBase_Factory(t) {
  return new (t || FileListItemBase)(i0.ɵɵdirectiveInject(UploadService));
};
FileListItemBase.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileListItemBase,
  selectors: [["ng-component"]],
  decls: 0,
  vars: 0,
  template: function FileListItemBase_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileListItemBase, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], function () {
    return [{
      type: UploadService
    }];
  }, null);
})();

/**
 * @hidden
 */
class FileListItemActionButtonComponent {
  constructor(uploadService, localization, navigation) {
    this.uploadService = uploadService;
    this.localization = localization;
    this.navigation = navigation;
    this.actionFocused = false;
    this.retryFocused = false;
    this.pauseResumeFocused = false;
    this.retrySVGIcon = arrowRotateCwSmallIcon;
    this.playSVGIcon = playSmIcon;
    this.pauseSVGIcon = pauseSmIcon;
    this.cancelSVGIcon = cancelIcon;
    this.deleteSVGIcon = xIcon;
  }
  onFocus(type) {
    if (type === 'action') {
      this.actionFocused = true;
    }
    if (type === 'retry') {
      this.retryFocused = true;
    }
    if (type === 'pauseResume') {
      this.pauseResumeFocused = true;
    }
  }
  onBlur(type) {
    if (type === 'retry') {
      this.retryFocused = false;
    }
    if (type === 'action') {
      this.actionFocused = false;
    }
    if (type === 'pauseResume') {
      this.pauseResumeFocused = false;
    }
  }
  onRetryClick() {
    if (this.disabled) {
      return;
    }
    this.uploadService.retryFiles(this.file.uid);
  }
  onRemoveCancelClick(event) {
    if (this.disabled) {
      return;
    }
    event.stopImmediatePropagation();
    const uid = this.file.uid;
    if (this.file.state === FileState.Uploading) {
      this.uploadService.cancelFiles(uid);
    } else {
      this.uploadService.removeFiles(uid);
    }
    this.navigation.focusSelectButton();
  }
  onPauseResumeClick() {
    if (this.disabled) {
      return;
    }
    const uid = this.file.uid;
    if (this.file.state === FileState.Paused) {
      this.uploadService.resumeFile(uid);
    } else {
      this.uploadService.pauseFile(uid);
    }
  }
  get actionButtonTitle() {
    if (this.file.state === FileState.Uploading) {
      return this.localization.get('cancel');
    }
    return this.localization.get('remove');
  }
  get retryButtonTitle() {
    return this.localization.get('retry');
  }
  get pauseResumeButtonTitle() {
    if (this.file.state === FileState.Uploading) {
      return this.localization.get('pause');
    }
    return this.localization.get('resume');
  }
  get isUploading() {
    return this.file.state === FileState.Uploading;
  }
  get isFailed() {
    return this.file.state === FileState.Failed;
  }
  get isPaused() {
    return this.file.state === FileState.Paused;
  }
  get isResumable() {
    const service = this.uploadService;
    const isResumable = service.async.chunk && service.chunk.resumable;
    const isUploading = this.file.state === FileState.Paused || this.file.state === FileState.Uploading;
    return isResumable && isUploading;
  }
  get isActionButtonVisible() {
    if ((this.file.state === FileState.Uploaded || this.file.state === FileState.Initial) && !this.uploadService.async.removeUrl && this.uploadService.component === 'Upload') {
      return false;
    }
    return true;
  }
}
FileListItemActionButtonComponent.ɵfac = function FileListItemActionButtonComponent_Factory(t) {
  return new (t || FileListItemActionButtonComponent)(i0.ɵɵdirectiveInject(UploadService), i0.ɵɵdirectiveInject(i1$1.LocalizationService), i0.ɵɵdirectiveInject(NavigationService));
};
FileListItemActionButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileListItemActionButtonComponent,
  selectors: [["kendo-upload-file-list-item-action-button"]],
  inputs: {
    file: "file",
    disabled: "disabled",
    progress: "progress"
  },
  decls: 5,
  vars: 4,
  consts: [[1, "k-upload-actions"], ["class", "k-upload-pct", 4, "ngIf"], ["type", "button", "class", "k-button k-icon-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-upload-action", 3, "ngClass", "focus", "blur", "click", 4, "ngIf"], ["class", "k-button k-icon-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-upload-action", "type", "button", 3, "ngClass", "focus", "blur", "click", 4, "ngIf"], [1, "k-upload-pct"], ["type", "button", 1, "k-button", "k-icon-button", "k-button-md", "k-rounded-md", "k-button-flat", "k-button-flat-base", "k-upload-action", 3, "ngClass", "focus", "blur", "click"], ["name", "refresh-sm", "innerCssClass", "k-retry", 3, "svgIcon"], [3, "name", "svgIcon"], [3, "name", "svgIcon", "innerCssClass"]],
  template: function FileListItemActionButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "span", 0);
      i0.ɵɵtemplate(1, FileListItemActionButtonComponent_span_1_Template, 2, 1, "span", 1)(2, FileListItemActionButtonComponent_button_2_Template, 2, 8, "button", 2)(3, FileListItemActionButtonComponent_button_3_Template, 2, 9, "button", 2)(4, FileListItemActionButtonComponent_button_4_Template, 2, 10, "button", 3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.isUploading || ctx.isPaused);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.isFailed);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.isResumable);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.isActionButtonVisible);
    }
  },
  dependencies: [i4.IconWrapperComponent, i6.NgIf, i6.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileListItemActionButtonComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-upload-file-list-item-action-button',
      template: `
    <span class="k-upload-actions">
        <span class="k-upload-pct" *ngIf="isUploading || isPaused">{{progress}}%</span>

        <button
            *ngIf="isFailed"
            type="button"
            class="k-button k-icon-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-upload-action"
            [ngClass]="{ 'k-focus': this.retryFocused }"
            [attr.tabIndex]="-1"
            [attr.aria-hidden]="true"
            (focus)="onFocus('retry')"
            (blur)="onBlur('retry')"
            (click)="onRetryClick()"
        >
            <kendo-icon-wrapper
                name="refresh-sm"
                [svgIcon]="retrySVGIcon"
                innerCssClass="k-retry"
                [attr.aria-label]="retryButtonTitle"
                [attr.title]="retryButtonTitle"
            ></kendo-icon-wrapper>
        </button>

        <button
            *ngIf="isResumable"
            type="button"
            class="k-button k-icon-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-upload-action"
            [ngClass]="{ 'k-focus': this.pauseResumeFocused }"
            [attr.tabIndex]="-1"
            [attr.aria-hidden]="true"
            (focus)="onFocus('pauseResume')"
            (blur)="onBlur('pauseResume')"
            (click)="onPauseResumeClick()"
        >
            <kendo-icon-wrapper
                [name]="isPaused ? 'play-sm' : 'pause-sm'"
                [svgIcon]="isPaused ? playSVGIcon : pauseSVGIcon"
                [attr.aria-label]='pauseResumeButtonTitle'
                [attr.title]='pauseResumeButtonTitle'
            ></kendo-icon-wrapper>
        </button>

        <button 
            *ngIf="isActionButtonVisible"
            class="k-button k-icon-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-upload-action"
            type="button"
            [attr.tabIndex]="-1"
            [attr.aria-hidden]="true"
            (focus)="onFocus('action')"
            (blur)="onBlur('action')"
            [ngClass]="{ 'k-focus': this.actionFocused }"
            (click)="onRemoveCancelClick($event)"
        >
            <kendo-icon-wrapper
                [name]="isUploading ? 'cancel' : 'x'"
                [svgIcon]="isUploading ? cancelSVGIcon : deleteSVGIcon"
                [innerCssClass]="!isUploading ? 'k-delete' : ''"
                [attr.aria-label]='actionButtonTitle'
                [attr.title]='actionButtonTitle'
            ></kendo-icon-wrapper>
        </button>
    </span>
    `
    }]
  }], function () {
    return [{
      type: UploadService
    }, {
      type: i1$1.LocalizationService
    }, {
      type: NavigationService
    }];
  }, {
    file: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    progress: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class FileListSingleItemComponent extends FileListItemBase {
  constructor(localization, uploadService) {
    super(uploadService);
    this.localization = localization;
    this.subscribeUploadProgress(args => {
      if (args.files[0].uid === this.file.uid) {
        this.progressComplete = args.percentComplete;
      }
    });
  }
  get fileStatusText() {
    const errors = this.file.validationErrors;
    if (this.file.state === FileState.Uploaded) {
      return `${this.textFor('fileStatusUploaded')}`;
    }
    if (this.file.state === FileState.Failed) {
      return `${this.textFor('fileStatusFailed')}`;
    }
    if (!isPresent(errors)) {
      return this.getTotalFilesSizeMessage([this.file]);
    }
    return this.getFileValidationMessage(this.file);
  }
  get showProgress() {
    const showProgress = this.file.state === FileState.Uploading || this.file.state === FileState.Paused;
    return showProgress ? 'active' : 'inactive';
  }
  get fileGroupClass() {
    return getFileGroupCssClass(this.file.extension ? this.file.extension : '');
  }
  get fileSVGGroupIcon() {
    const initial = fileIcon;
    if (this.file.extension) {
      for (const group in fileGroupMap) {
        if (fileGroupMap[group].indexOf(this.file.extension) >= 0) {
          return fileSVGGroupMap[group];
        }
      }
    }
    return initial;
  }
  get isUploadSuccessful() {
    return this.file.state === FileState.Uploaded;
  }
  get isUploadFailed() {
    return this.file.state === FileState.Failed;
  }
  get isNotYetUploaded() {
    return !this.isUploadFailed && !this.isUploadSuccessful;
  }
}
FileListSingleItemComponent.ɵfac = function FileListSingleItemComponent_Factory(t) {
  return new (t || FileListSingleItemComponent)(i0.ɵɵdirectiveInject(i1$1.LocalizationService), i0.ɵɵdirectiveInject(UploadService));
};
FileListSingleItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileListSingleItemComponent,
  selectors: [["kendo-upload-file-list-single-item"]],
  inputs: {
    disabled: "disabled",
    file: "file",
    fileInfoTemplate: "fileInfoTemplate"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 11,
  consts: [[3, "value", "label"], [1, "k-file-icon-wrapper"], ["size", "xxlarge", "innerCssClass", "k-file-icon", 3, "name", "svgIcon"], [1, "k-file-info"], [4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext", 4, "ngIf"], [3, "file", "disabled", "progress"], [1, "k-file-name", 3, "title"], [3, "ngClass"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function FileListSingleItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "kendo-progressbar", 0);
      i0.ɵɵelementStart(1, "span", 1);
      i0.ɵɵelement(2, "kendo-icon-wrapper", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵtemplate(4, FileListSingleItemComponent_ng_container_4_Template, 5, 9, "ng-container", 4)(5, FileListSingleItemComponent_ng_container_5_Template, 1, 8, "ng-container", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(6, "kendo-upload-file-list-item-action-button", 6);
    }
    if (rf & 2) {
      i0.ɵɵproperty("@progressState", ctx.showProgress)("value", ctx.progressComplete)("label", i0.ɵɵpureFunction0(10, _c4));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("name", ctx.fileGroupClass)("svgIcon", ctx.fileSVGGroupIcon);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.fileInfoTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.fileInfoTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("file", ctx.file)("disabled", ctx.disabled)("progress", ctx.progressComplete);
    }
  },
  dependencies: [i3.ProgressBarComponent, i4.IconWrapperComponent, FileListItemActionButtonComponent, i6.NgIf, i6.NgClass, i6.NgTemplateOutlet],
  encapsulation: 2,
  data: {
    animation: [trigger('progressState', [state('active', style({
      opacity: 1
    })), state('inactive', style({
      opacity: 0
    })), transition('void => active', style({
      opacity: 0
    })), transition('inactive => active', style({
      opacity: 1
    })), transition('active => inactive', animate('1s 2s ease-out'))])]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileListSingleItemComponent, [{
    type: Component,
    args: [{
      animations: [trigger('progressState', [state('active', style({
        opacity: 1
      })), state('inactive', style({
        opacity: 0
      })), transition('void => active', style({
        opacity: 0
      })), transition('inactive => active', style({
        opacity: 1
      })), transition('active => inactive', animate('1s 2s ease-out'))])],
      selector: 'kendo-upload-file-list-single-item',
      template: `
        <kendo-progressbar
            [@progressState]="showProgress"
            [value]="progressComplete"
            [label]="{ visible: false }"
        >
        </kendo-progressbar>
        <span class="k-file-icon-wrapper">
            <kendo-icon-wrapper
                size="xxlarge"
                [name]="fileGroupClass"
                [svgIcon]="fileSVGGroupIcon"
                innerCssClass="k-file-icon"
            >
            </kendo-icon-wrapper>
        </span>
        <span class="k-file-info">
            <ng-container *ngIf="!fileInfoTemplate">
                <span class="k-file-name" [title]="file.name">{{ file.name }}</span>
                <span [attr.aria-live]="'polite'" [ngClass]="{
                    'k-file-validation-message': file.validationErrors,
                    'k-file-size': !file.validationErrors && isNotYetUploaded,
                    'k-file-summary': isUploadSuccessful || isUploadFailed
                    }"
                >{{fileStatusText}}</span>
            </ng-container>
            <ng-container *ngIf="fileInfoTemplate" [ngTemplateOutlet]="fileInfoTemplate.templateRef" [ngTemplateOutletContext]="{
                    templateRef: fileInfoTemplate.templateRef,
                    state: file.state,
                    $implicit: [file]
                }"></ng-container>
        </span>
        <kendo-upload-file-list-item-action-button
            [file]='file'
            [disabled]='disabled'
            [progress]='progressComplete'>
        </kendo-upload-file-list-item-action-button>
    `
    }]
  }], function () {
    return [{
      type: i1$1.LocalizationService
    }, {
      type: UploadService
    }];
  }, {
    disabled: [{
      type: Input
    }],
    file: [{
      type: Input
    }],
    fileInfoTemplate: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class FileListMultipleItemsComponent extends FileListItemBase {
  constructor(localization, uploadService) {
    super(uploadService);
    this.localization = localization;
    this.copySVGIcon = copyIcon;
    this.subscribeUploadProgress(args => {
      if (args.files[0].uid === this.files[0].uid) {
        this.progressComplete = args.percentComplete;
      }
    });
  }
  get showProgress() {
    const showProgress = this.files[0].state === FileState.Uploading || this.files[0].state === FileState.Paused;
    return showProgress ? 'active' : 'inactive';
  }
  ngOnInit() {
    this.filesHaveErrors = super.filesHaveValidationErrors(this.files);
  }
  fileStatusText(file) {
    const errors = file.validationErrors;
    if (!isPresent(errors)) {
      return this.getTotalFilesSizeMessage([file]);
    }
    return this.getFileValidationMessage(file);
  }
  get batchStatusText() {
    const state = this.files[0].state;
    const fileCount = this.files.length;
    if (state === FileState.Uploaded) {
      return `${fileCount} ${this.textFor('filesBatchStatusUploaded')}`;
    }
    if (state === FileState.Failed) {
      return `${fileCount} ${this.textFor('filesBatchStatusFailed')}`;
    }
    return `${fileCount} ${this.textFor('filesBatchStatus')}`;
  }
  get isUploadSuccessful() {
    return this.files[0].state === FileState.Uploaded;
  }
  get isUploadFailed() {
    return this.files[0].state === FileState.Failed;
  }
}
FileListMultipleItemsComponent.ɵfac = function FileListMultipleItemsComponent_Factory(t) {
  return new (t || FileListMultipleItemsComponent)(i0.ɵɵdirectiveInject(i1$1.LocalizationService), i0.ɵɵdirectiveInject(UploadService));
};
FileListMultipleItemsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileListMultipleItemsComponent,
  selectors: [["kendo-upload-file-list-multiple-items"]],
  inputs: {
    disabled: "disabled",
    files: "files",
    fileInfoTemplate: "fileInfoTemplate"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 10,
  consts: [[3, "value", "label"], [1, "k-file-icon-wrapper"], ["name", "copy", "size", "xxlarge", "innerCssClass", "k-file-icon", 3, "svgIcon"], [1, "k-multiple-files-wrapper"], [4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext", 4, "ngIf"], [3, "file", "disabled", "progress"], ["class", "k-file-info", 4, "ngFor", "ngForOf"], [1, "k-file-summary"], [1, "k-file-info"], [1, "k-file-name", 3, "title"], [3, "ngClass"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function FileListMultipleItemsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "kendo-progressbar", 0);
      i0.ɵɵelementStart(1, "span", 1);
      i0.ɵɵelement(2, "kendo-icon-wrapper", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵtemplate(4, FileListMultipleItemsComponent_ng_container_4_Template, 4, 2, "ng-container", 4)(5, FileListMultipleItemsComponent_ng_container_5_Template, 1, 6, "ng-container", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(6, "kendo-upload-file-list-item-action-button", 6);
    }
    if (rf & 2) {
      i0.ɵɵproperty("@progressState", ctx.showProgress)("value", ctx.progressComplete)("label", i0.ɵɵpureFunction0(9, _c4));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("svgIcon", ctx.copySVGIcon);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.fileInfoTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.fileInfoTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("file", ctx.files[0])("disabled", ctx.disabled)("progress", ctx.progressComplete);
    }
  },
  dependencies: [i3.ProgressBarComponent, i4.IconWrapperComponent, FileListItemActionButtonComponent, i6.NgIf, i6.NgForOf, i6.NgClass, i6.NgTemplateOutlet],
  encapsulation: 2,
  data: {
    animation: [trigger('progressState', [state('active', style({
      opacity: 1
    })), state('inactive', style({
      opacity: 0
    })), transition('void => active', style({
      opacity: 0
    })), transition('inactive => active', style({
      opacity: 1
    })), transition('active => inactive', animate('1s 2s ease-out'))])]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileListMultipleItemsComponent, [{
    type: Component,
    args: [{
      animations: [trigger('progressState', [state('active', style({
        opacity: 1
      })), state('inactive', style({
        opacity: 0
      })), transition('void => active', style({
        opacity: 0
      })), transition('inactive => active', style({
        opacity: 1
      })), transition('active => inactive', animate('1s 2s ease-out'))])],
      selector: 'kendo-upload-file-list-multiple-items',
      template: `
        <kendo-progressbar
            [@progressState]="showProgress"
            [value]="progressComplete"
            [label]="{ visible: false }"
        >
        </kendo-progressbar>
        <span class="k-file-icon-wrapper">
            <kendo-icon-wrapper
                name="copy"
                size="xxlarge"
                [svgIcon]="copySVGIcon"
                innerCssClass="k-file-icon"
            >
            </kendo-icon-wrapper>
        </span>
        <span class="k-multiple-files-wrapper">
            <ng-container *ngIf="!fileInfoTemplate">
                <span *ngFor="let file of files" class="k-file-info">
                    <span [title]="file.name" class="k-file-name">
                        {{file.name}}
                    </span>
                    <span [attr.aria-live]="'polite'" [ngClass]="{
                        'k-file-validation-message': file.validationErrors,
                        'k-file-size': !file.validationErrors
                        }"
                    >{{fileStatusText(file)}}</span>
                </span>
                <span class="k-file-summary"
                >{{batchStatusText}}</span>
            </ng-container>
            <ng-container
                *ngIf="fileInfoTemplate"
                [ngTemplateOutlet]="fileInfoTemplate.templateRef"
                [ngTemplateOutletContext]="{
                    templateRef: fileInfoTemplate.templateRef,
                    state: files[0].state,
                    $implicit: files
                }"></ng-container>
        </span>
        <kendo-upload-file-list-item-action-button
            [file]='files[0]'
            [disabled]='disabled'
            [progress]='progressComplete'>
        </kendo-upload-file-list-item-action-button>
    `
    }]
  }], function () {
    return [{
      type: i1$1.LocalizationService
    }, {
      type: UploadService
    }];
  }, {
    disabled: [{
      type: Input
    }],
    files: [{
      type: Input
    }],
    fileInfoTemplate: [{
      type: Input
    }]
  });
})();

/* eslint-disable @angular-eslint/component-selector */
/**
 * @hidden
 */
class FileListComponent {
  constructor(uploadService, navigation) {
    this.uploadService = uploadService;
    this.navigation = navigation;
    this.onItemFocus();
    this.onItemAction();
  }
  onItemFocus() {
    this.focusSubscription = this.navigation.onFileFocus.subscribe(index => {
      this.fileListItems.toArray()[index].focus();
    });
  }
  onItemAction() {
    this.actionSubscription = this.navigation.onFileAction.subscribe(key => {
      this.itemActionHandler(key);
    });
  }
  itemActionHandler(key) {
    const index = this.navigation.focusedIndex;
    const item = this.fileListItems.toArray()[index];
    const uid = item.uidAttribute;
    const files = this.uploadService.files.get(uid);
    if (key === Keys.Escape && files[0].state === FileState.Uploading) {
      this.uploadService.cancelFiles(uid);
      this.navigation.focusSelectButton();
      return;
    }
    if (key === Keys.Enter && files[0].state === FileState.Failed) {
      this.uploadService.retryFiles(uid);
      return;
    }
    if (key === Keys.Delete) {
      if (files[0].state === FileState.Uploading) {
        this.uploadService.cancelFiles(uid);
      } else if (this.hasDelete(item)) {
        this.uploadService.removeFiles(uid);
      }
      this.navigation.focusSelectButton();
    }
    const isUploadChunk = this.uploadService.async.chunk;
    const canTogglePauseResume = key === Keys.Space && files[0].state !== FileState.Uploaded;
    if (canTogglePauseResume && isUploadChunk) {
      if (files[0].state === FileState.Paused) {
        this.uploadService.resumeFile(uid);
      } else {
        this.uploadService.pauseFile(uid);
      }
    }
  }
  hasDelete(item) {
    return item.element.nativeElement.getElementsByClassName('k-delete').length > 0;
  }
  ngOnDestroy() {
    this.focusSubscription.unsubscribe();
    this.actionSubscription.unsubscribe();
  }
}
FileListComponent.ɵfac = function FileListComponent_Factory(t) {
  return new (t || FileListComponent)(i0.ɵɵdirectiveInject(UploadService), i0.ɵɵdirectiveInject(NavigationService));
};
FileListComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileListComponent,
  selectors: [["", "kendo-upload-file-list", ""]],
  viewQuery: function FileListComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(FileListItemDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileListItems = _t);
    }
  },
  inputs: {
    disabled: "disabled",
    fileList: "fileList",
    fileTemplate: "fileTemplate",
    fileInfoTemplate: "fileInfoTemplate"
  },
  attrs: _c6,
  decls: 1,
  vars: 1,
  consts: [["ngFor", "", 3, "ngForOf"], ["kendoUploadFileListItem", "", 3, "files", "index"], ["class", "k-file-single", 3, "disabled", "file", "fileInfoTemplate", 4, "ngIf"], ["class", "k-file-multiple", 3, "disabled", "files", "fileInfoTemplate", 4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext", 4, "ngIf"], [1, "k-file-single", 3, "disabled", "file", "fileInfoTemplate"], [1, "k-file-multiple", 3, "disabled", "files", "fileInfoTemplate"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function FileListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FileListComponent_ng_template_0_Template, 4, 5, "ng-template", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", ctx.fileList);
    }
  },
  dependencies: [FileListSingleItemComponent, FileListMultipleItemsComponent, i6.NgForOf, FileListItemDirective, i6.NgIf, i6.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileListComponent, [{
    type: Component,
    args: [{
      selector: '[kendo-upload-file-list]',
      template: `
        <ng-template ngFor
            [ngForOf]="fileList"
            let-files
            let-index="index">
            <li kendoUploadFileListItem [files]='files' [index]='index'>
                <kendo-upload-file-list-single-item
                    class='k-file-single'
                    *ngIf='files.length === 1 && !fileTemplate'
                    [disabled]='disabled'
                    [file]='files[0]'
                    [fileInfoTemplate]="fileInfoTemplate"></kendo-upload-file-list-single-item>
                <kendo-upload-file-list-multiple-items
                    class='k-file-multiple'
                    *ngIf='files.length > 1 && !fileTemplate'
                    [disabled]='disabled'
                    [files]='files'
                    [fileInfoTemplate]="fileInfoTemplate"></kendo-upload-file-list-multiple-items>
                <ng-container
                    *ngIf="fileTemplate" [ngTemplateOutlet]="fileTemplate.templateRef" [ngTemplateOutletContext]="{
                            templateRef: fileTemplate.templateRef,
                            state: files[0].state,
                            $implicit: files
                        }"></ng-container>
            </li>
        </ng-template>
    `
    }]
  }], function () {
    return [{
      type: UploadService
    }, {
      type: NavigationService
    }];
  }, {
    disabled: [{
      type: Input
    }],
    fileList: [{
      type: Input
    }],
    fileTemplate: [{
      type: Input
    }],
    fileInfoTemplate: [{
      type: Input
    }],
    fileListItems: [{
      type: ViewChildren,
      args: [FileListItemDirective]
    }]
  });
})();

/**
 * @hidden
 */
class Messages extends ComponentMessages {}
Messages.ɵfac = /* @__PURE__ */(() => {
  let ɵMessages_BaseFactory;
  return function Messages_Factory(t) {
    return (ɵMessages_BaseFactory || (ɵMessages_BaseFactory = i0.ɵɵgetInheritedFactory(Messages)))(t || Messages);
  };
})();
Messages.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Messages,
  inputs: {
    cancel: "cancel",
    clearSelectedFiles: "clearSelectedFiles",
    dropFilesHere: "dropFilesHere",
    externalDropFilesHere: "externalDropFilesHere",
    filesBatchStatus: "filesBatchStatus",
    filesBatchStatusFailed: "filesBatchStatusFailed",
    filesBatchStatusUploaded: "filesBatchStatusUploaded",
    fileStatusFailed: "fileStatusFailed",
    fileStatusUploaded: "fileStatusUploaded",
    headerStatusPaused: "headerStatusPaused",
    headerStatusUploaded: "headerStatusUploaded",
    headerStatusUploading: "headerStatusUploading",
    invalidFileExtension: "invalidFileExtension",
    invalidMaxFileSize: "invalidMaxFileSize",
    invalidMinFileSize: "invalidMinFileSize",
    pause: "pause",
    remove: "remove",
    resume: "resume",
    retry: "retry",
    select: "select",
    uploadSelectedFiles: "uploadSelectedFiles"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Messages, [{
    type: Directive
  }], null, {
    cancel: [{
      type: Input
    }],
    clearSelectedFiles: [{
      type: Input
    }],
    dropFilesHere: [{
      type: Input
    }],
    externalDropFilesHere: [{
      type: Input
    }],
    filesBatchStatus: [{
      type: Input
    }],
    filesBatchStatusFailed: [{
      type: Input
    }],
    filesBatchStatusUploaded: [{
      type: Input
    }],
    fileStatusFailed: [{
      type: Input
    }],
    fileStatusUploaded: [{
      type: Input
    }],
    headerStatusPaused: [{
      type: Input
    }],
    headerStatusUploaded: [{
      type: Input
    }],
    headerStatusUploading: [{
      type: Input
    }],
    invalidFileExtension: [{
      type: Input
    }],
    invalidMaxFileSize: [{
      type: Input
    }],
    invalidMinFileSize: [{
      type: Input
    }],
    pause: [{
      type: Input
    }],
    remove: [{
      type: Input
    }],
    resume: [{
      type: Input
    }],
    retry: [{
      type: Input
    }],
    select: [{
      type: Input
    }],
    uploadSelectedFiles: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class LocalizedMessagesDirective extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
}
LocalizedMessagesDirective.ɵfac = function LocalizedMessagesDirective_Factory(t) {
  return new (t || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1$1.LocalizationService));
};
LocalizedMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalizedMessagesDirective,
  selectors: [["", "kendoUploadLocalizedMessages", ""], ["", "kendoFileSelectLocalizedMessages", ""], ["", "kendoUploadDropZoneLocalizedMessages", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => LocalizedMessagesDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: `
    [kendoUploadLocalizedMessages],
    [kendoFileSelectLocalizedMessages],
    [kendoUploadDropZoneLocalizedMessages]
  `
    }]
  }], function () {
    return [{
      type: i1$1.LocalizationService
    }];
  }, null);
})();

/**
 * @hidden
 */
const INVALIDMAXFILESIZE = "invalidMaxFileSize";
/**
 * @hidden
 */
const INVALIDMINFILESIZE = "invalidMinFileSize";
/**
 * @hidden
 */
const INVALIDFILEEXTENSION = "invalidFileExtension";
const validateFileExtension = (file, allowedExtensions) => {
  if (allowedExtensions.length > 0) {
    if (allowedExtensions.indexOf(file.extension.toLowerCase()) < 0) {
      file.validationErrors = file.validationErrors || [];
      if (file.validationErrors.indexOf(INVALIDFILEEXTENSION) < 0) {
        file.validationErrors.push(INVALIDFILEEXTENSION);
      }
    }
  }
};
const validateFileSize = (file, minFileSize, maxFileSize) => {
  if (minFileSize !== 0 && file.size < minFileSize) {
    file.validationErrors = file.validationErrors || [];
    if (file.validationErrors.indexOf(INVALIDMINFILESIZE) < 0) {
      file.validationErrors.push(INVALIDMINFILESIZE);
    }
  }
  if (maxFileSize !== 0 && file.size > maxFileSize) {
    file.validationErrors = file.validationErrors || [];
    if (file.validationErrors.indexOf(INVALIDMAXFILESIZE) < 0) {
      file.validationErrors.push(INVALIDMAXFILESIZE);
    }
  }
};
const parseAllowedExtensions = extensions => {
  const allowedExtensions = extensions.map(ext => {
    const parsedExt = ext.substring(0, 1) === "." ? ext : "." + ext;
    return parsedExt.toLowerCase();
  });
  return allowedExtensions;
};
/**
 * @hidden
 */
const validateFiles = (files, restrictionInfo) => {
  const allowedExtensions = parseAllowedExtensions(restrictionInfo.allowedExtensions);
  const maxFileSize = restrictionInfo.maxFileSize;
  const minFileSize = restrictionInfo.minFileSize;
  let i;
  for (i = 0; i < files.length; i++) {
    validateFileExtension(files[i], allowedExtensions);
    validateFileSize(files[i], minFileSize, maxFileSize);
  }
};

/**
 * @hidden
 */
class DropZoneBase {
  constructor(element, renderer, cssClass) {
    this.element = element;
    this.renderer = renderer;
    this.hideIntervalElement = null;
    this.hoverClass = cssClass;
  }
  /**
   * @hidden
   */
  onElementDragEnterListener() {
    this.addClass(this.hoverClass);
    this.lastDragElement = new Date();
    if (!this.hideIntervalElement) {
      this.hideIntervalElement = setInterval(() => {
        if (this.calculateTimeDiff(this.lastDragElement) < 100) {
          return;
        }
        this.removeClass(this.hoverClass);
        clearInterval(this.hideIntervalElement);
        this.hideIntervalElement = null;
      }, 100);
    }
    return false;
  }
  /**
   * @hidden
   */
  onElementDragOverListener() {
    this.lastDragElement = new Date();
    return false;
  }
  calculateTimeDiff(prevEvent) {
    return new Date().getTime() - prevEvent.getTime();
  }
  addClass(className) {
    this.renderer.addClass(this.element.nativeElement, className);
  }
  removeClass(className) {
    this.renderer.removeClass(this.element.nativeElement, className);
  }
}
DropZoneBase.ɵfac = function DropZoneBase_Factory(t) {
  return new (t || DropZoneBase)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject('hoverClass'));
};
DropZoneBase.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DropZoneBase,
  hostBindings: function DropZoneBase_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("dragenter", function DropZoneBase_dragenter_HostBindingHandler() {
        return ctx.onElementDragEnterListener();
      })("dragover", function DropZoneBase_dragover_HostBindingHandler() {
        return ctx.onElementDragOverListener();
      });
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropZoneBase, [{
    type: Directive
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: ['hoverClass']
      }]
    }];
  }, {
    onElementDragEnterListener: [{
      type: HostListener,
      args: ['dragenter']
    }],
    onElementDragOverListener: [{
      type: HostListener,
      args: ['dragover']
    }]
  });
})();

/**
 * @hidden
 */
class DropZoneInternalDirective extends DropZoneBase {
  constructor(element, renderer, ngZone, uploadService) {
    super(element, renderer, 'k-hover');
    this.ngZone = ngZone;
    this.uploadService = uploadService;
    this.initialClassName = true;
    this.hideIntervalDocument = null;
    this.activeClass = 'k-dropzone-active';
    this.ngZone.runOutsideAngular(() => {
      this.unsubscribeDocumentDragEnter = this.renderer.listen('document', 'dragenter', () => this.onDocumentDragEnter());
      this.unsubscribeDocumentDragOver = this.renderer.listen('document', 'dragover', () => this.onDocumentDragOver());
    });
  }
  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      if (this.unsubscribeDocumentDragEnter) {
        this.unsubscribeDocumentDragEnter();
      }
      if (this.unsubscribeDocumentDragOver) {
        this.unsubscribeDocumentDragOver();
      }
    });
  }
  onDocumentDragEnter() {
    this.addClass(this.activeClass);
    this.lastDragDocument = new Date();
    if (!this.hideIntervalDocument) {
      this.hideIntervalDocument = setInterval(() => {
        if (this.calculateTimeDiff(this.lastDragDocument) < 100) {
          return;
        }
        this.removeClass(this.activeClass);
        clearInterval(this.hideIntervalDocument);
        this.hideIntervalDocument = null;
      }, 100);
    }
    return false;
  }
  /**
   * @hidden
   */
  onDocumentDragOver() {
    this.lastDragDocument = new Date();
    return false;
  }
  onDropListener(event) {
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0 && !this.disabled) {
      let files = getAllFileInfo(droppedFiles);
      files = assignGuidToFiles(files, !this.uploadService.async.batch);
      if (!this.multiple) {
        files.splice(1, files.length - 1);
        this.uploadService.clearFiles();
      }
      validateFiles(files, this.restrictions);
      this.uploadService.addFiles(files);
    }
    return false;
  }
}
DropZoneInternalDirective.ɵfac = function DropZoneInternalDirective_Factory(t) {
  return new (t || DropZoneInternalDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(UploadService));
};
DropZoneInternalDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DropZoneInternalDirective,
  selectors: [["", "kendoUploadInternalDropZone", ""], ["", "kendoFileSelectInternalDropZone", ""]],
  hostVars: 4,
  hostBindings: function DropZoneInternalDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("drop", function DropZoneInternalDirective_drop_HostBindingHandler($event) {
        return ctx.onDropListener($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("k-dropzone", ctx.initialClassName)("k-upload-dropzone", ctx.initialClassName);
    }
  },
  inputs: {
    disabled: "disabled",
    multiple: "multiple",
    restrictions: "restrictions"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropZoneInternalDirective, [{
    type: Directive,
    args: [{
      selector: `
      [kendoUploadInternalDropZone],
      [kendoFileSelectInternalDropZone]
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }, {
      type: UploadService
    }];
  }, {
    disabled: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    restrictions: [{
      type: Input
    }],
    initialClassName: [{
      type: HostBinding,
      args: ['class.k-dropzone']
    }, {
      type: HostBinding,
      args: ['class.k-upload-dropzone']
    }],
    onDropListener: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  });
})();

/**
 * @hidden
 */
class FileSelectDirective {
  constructor(uploadService, navigation, el) {
    this.uploadService = uploadService;
    this.navigation = navigation;
    this.type = "file";
    this.autocomplete = "off";
    this.tabIndex = -1;
    this.ariaHidden = true;
    this.classNames = true;
    this.element = el;
  }
  get nameAttribute() {
    return this.uploadService.async.saveField;
  }
  get multipleAttribute() {
    return this.multiple ? "multiple" : null;
  }
  get dirAttribute() {
    return this.dir;
  }
  get disabledAttribute() {
    return this.disabled ? "true" : null;
  }
  get acceptAttribute() {
    return this.accept ? this.accept : null;
  }
  onInputChange(event) {
    const ua = navigator.userAgent;
    const webkit = /(webkit)[ \/]([\w.]+)/i;
    const ie = /(windows)[ \/]([\w.]+)/i;
    let selectedFiles = inputFiles(event.target);
    selectedFiles = assignGuidToFiles(selectedFiles, !this.uploadService.async.batch);
    validateFiles(selectedFiles, this.restrictions);
    if (!this.multiple) {
      this.uploadService.clearFiles();
    }
    this.uploadService.addFiles(selectedFiles);
    /*
    Chrome, IE, Edge and Safari do not trigger a `change` event
    when a file with the same name is selected a number of consecutive times.
    As a workaround, clear the input value after handling the file.
    */
    const native = this.element.nativeElement;
    if (ua.match(webkit) || ua.match(ie)) {
      native.type = "";
      native.type = "file";
    }
    setTimeout(() => {
      this.navigation.focusedIndex = -1;
    });
  }
}
FileSelectDirective.ɵfac = function FileSelectDirective_Factory(t) {
  return new (t || FileSelectDirective)(i0.ɵɵdirectiveInject(UploadService), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(i0.ElementRef));
};
FileSelectDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FileSelectDirective,
  selectors: [["", "kendoFileSelect", ""]],
  hostVars: 11,
  hostBindings: function FileSelectDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function FileSelectDirective_change_HostBindingHandler($event) {
        return ctx.onInputChange($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("type", ctx.type)("autocomplete", ctx.autocomplete)("tabindex", ctx.tabIndex)("aria-hidden", ctx.ariaHidden)("name", ctx.nameAttribute)("multiple", ctx.multipleAttribute)("dir", ctx.dirAttribute)("disabled", ctx.disabledAttribute)("accept", ctx.acceptAttribute);
      i0.ɵɵclassProp("k-hidden", ctx.classNames);
    }
  },
  inputs: {
    dir: "dir",
    disabled: "disabled",
    multiple: "multiple",
    restrictions: "restrictions",
    accept: "accept"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSelectDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoFileSelect]'
    }]
  }], function () {
    return [{
      type: UploadService
    }, {
      type: NavigationService
    }, {
      type: i0.ElementRef
    }];
  }, {
    dir: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    restrictions: [{
      type: Input
    }],
    accept: [{
      type: Input
    }],
    type: [{
      type: HostBinding,
      args: ["attr.type"]
    }],
    autocomplete: [{
      type: HostBinding,
      args: ["attr.autocomplete"]
    }],
    tabIndex: [{
      type: HostBinding,
      args: ["attr.tabindex"]
    }],
    ariaHidden: [{
      type: HostBinding,
      args: ["attr.aria-hidden"]
    }],
    classNames: [{
      type: HostBinding,
      args: ["class.k-hidden"]
    }],
    nameAttribute: [{
      type: HostBinding,
      args: ["attr.name"]
    }],
    multipleAttribute: [{
      type: HostBinding,
      args: ["attr.multiple"]
    }],
    dirAttribute: [{
      type: HostBinding,
      args: ["attr.dir"]
    }],
    disabledAttribute: [{
      type: HostBinding,
      args: ["attr.disabled"]
    }],
    acceptAttribute: [{
      type: HostBinding,
      args: ["attr.accept"]
    }],
    onInputChange: [{
      type: HostListener,
      args: ["change", ["$event"]]
    }]
  });
})();

/**
 * @hidden
 */
const FILESELECT_VALUE_ACCESSOR = {
  multi: true,
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileSelectComponent)
};
let idx$1 = 0;
class FileSelectComponent {
  constructor(uploadService, localization, navigation, dropZoneService, ngZone, renderer, cdr, wrapper) {
    this.uploadService = uploadService;
    this.localization = localization;
    this.navigation = navigation;
    this.dropZoneService = dropZoneService;
    this.ngZone = ngZone;
    this.renderer = renderer;
    this.cdr = cdr;
    /**
     * Disables the FileSelect.
     * To learn how to disable the component in reactive forms, refer to the article on [Forms Support](slug:formssupport_fileselect#toc-managing-the-fileselect-disabled-state-in-reactive-forms).
     * @default false
     */
    this.disabled = false;
    /**
     * Enables the selection of multiple files
     * ([see example]({% slug fileprocessing_upload %}#toc-upload-of-sinlge-or-multiple-files)).
     * If set to `false`, only one file can be selected at a time.
     * @default true
     */
    this.multiple = true;
    /**
     * Toggles the visibility of the file list.
     * @default true
     */
    this.showFileList = true;
    /**
     * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the FileSelect.
     * @default 0
     */
    this.tabindex = 0;
    /**
     * @hidden
     */
    this.focusableId = `k-${guid()}`;
    /**
     * Fires when the user navigates outside the component.
     */
    this.onBlur = new EventEmitter();
    /**
     * Fires when the component is focused.
     */
    this.onFocus = new EventEmitter();
    /**
     * Fires when files are selected. If prevented, the selected files will not be added to the list.
     */
    this.select = new EventEmitter();
    /**
     * Fires when a file is about to be removed. If prevented, the file will remain in the list.
     */
    this.remove = new EventEmitter();
    /**
     * Fires when the value of the component has changed as a result of a successful `select` or `remove` operation.
     */
    this.valueChange = new EventEmitter();
    this.hostDefaultClasses = true;
    /**
     * @hidden
     */
    this._restrictions = {
      allowedExtensions: [],
      maxFileSize: 0,
      minFileSize: 0
    };
    this.onTouchedCallback = _ => {};
    this.onChangeCallback = _ => {};
    validatePackage(packageMetadata);
    this.wrapper = wrapper.nativeElement;
    this.direction = localization.rtl ? 'rtl' : 'ltr';
    this.navigation.computeKeys();
    this.fileList = this.uploadService.files;
    this.localizationChangeSubscription = localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    });
    this.subscribeBlur();
    this.subscribeFocus();
    this.attachEventHandlers();
    this.setDefaultSettings();
  }
  /**
   * Sets the `name` attribute of the `input` element of the FileSelect.
   */
  set name(name) {
    this.uploadService.async.saveField = name;
  }
  get name() {
    return this.uploadService.async.saveField;
  }
  /**
   * Sets the restrictions for selected files.
   */
  set restrictions(restrictions) {
    const parsedRestrictions = Object.assign({}, this._restrictions, restrictions);
    this._restrictions = parsedRestrictions;
  }
  get restrictions() {
    return this._restrictions;
  }
  get hostDisabledClass() {
    return this.disabled;
  }
  get dir() {
    return this.direction;
  }
  get hostRole() {
    return 'application';
  }
  ngOnInit() {
    const {
      buttonId,
      inputId
    } = this.getIds();
    this.focusableId = buttonId;
    this.inputElementId = inputId;
    if (this.zoneId) {
      this.dropZoneService.addComponent(this, this.zoneId);
    }
    this.subs.add(this.renderer.listen(this.fileSelectInput.nativeElement, 'mouseenter', () => {
      this.renderer.addClass(this.fileSelectButton.nativeElement, 'k-hover');
    }));
    this.subs.add(this.renderer.listen(this.fileSelectInput.nativeElement, 'mouseleave', () => {
      this.renderer.removeClass(this.fileSelectButton.nativeElement, 'k-hover');
    }));
    this.ngZone.runOutsideAngular(() => {
      this.subs.add(this.renderer.listen(this.wrapper, 'keydown', event => this.handleKeydown(event)));
    });
  }
  /**
   * @hidden
   */
  textFor(key) {
    return this.localization.get(key);
  }
  /**
   * Focuses the underlying input element.
   */
  focus() {
    setTimeout(() => {
      this.fileSelectButton.nativeElement.focus();
      this.navigation.focused = true;
      this.onFocus.emit();
    });
  }
  ngOnDestroy() {
    this.fileList.clear();
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
    }
    if (this.wrapperFocusSubscription) {
      this.wrapperFocusSubscription.unsubscribe();
    }
    if (this.selectButtonFocusSubscription) {
      this.selectButtonFocusSubscription.unsubscribe();
    }
    if (this.localizationChangeSubscription) {
      this.localizationChangeSubscription.unsubscribe();
    }
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
  /**
   * @hidden
   */
  writeValue(newValue) {
    let isValid = true;
    if (newValue instanceof Array) {
      newValue.forEach(file => {
        if (!validateInitialFileSelectFile(file)) {
          isValid = false;
        }
      });
      if (isValid) {
        this.uploadService.addInitialFileSelectFiles(newValue);
      }
    }
    if (newValue === null) {
      this.fileList.clear();
    }
    this.cdr.markForCheck();
  }
  /**
   * @hidden
   */
  registerOnChange(fn) {
    this.onChangeCallback = fn;
  }
  /**
   * @hidden
   */
  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }
  /**
   * @hidden
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  /**
   * @hidden
   */
  onFileSelectButtonFocus() {
    if (!this.navigation.focused) {
      this.navigation.focusedIndex = -1;
    }
  }
  /**
   * Removes specific file from the file list.
   */
  removeFileByUid(uid) {
    this.uploadService.removeFiles(uid);
  }
  /**
   * Visually clears all files from the UI.
   */
  clearFiles() {
    this.uploadService.clearFiles();
  }
  /**
   * @hidden
   * Used to determine if the component is empty.
   */
  isEmpty() {
    return false;
  }
  /**
   * @hidden
   * Used by the external dropzone to add files to the FileSelect
   */
  addFiles(files) {
    this.uploadService.addFiles(files);
  }
  /**
   * @hidden
   */
  get selectButtonTabIndex() {
    return this.disabled ? undefined : this.tabindex;
  }
  /**
   * @hidden
   */
  getIds() {
    const id = ++idx$1;
    const buttonId = `k-fileselect-button-${id}`;
    const inputId = `k-fileselect-input-${id}`;
    return {
      buttonId,
      inputId
    };
  }
  subscribeBlur() {
    if (!isDocumentAvailable()) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.documentClick = fromEvent(document, 'click').pipe(filter(event => {
        return !(this.wrapper !== event.target && this.wrapper.contains(event.target));
      }));
      this.blurSubscription = merge(this.documentClick, this.navigation.onTabOut).subscribe(() => {
        if (this.navigation.focused) {
          this.ngZone.run(() => {
            this.navigation.focused = false;
            this.onTouchedCallback();
            this.onBlur.emit();
          });
        }
      });
    });
  }
  subscribeFocus() {
    this.wrapperFocusSubscription = this.navigation.onWrapperFocus.subscribe(() => {
      this.onFocus.emit();
    });
    this.selectButtonFocusSubscription = this.navigation.onSelectButtonFocus.subscribe(() => {
      this.fileSelectButton.nativeElement.focus();
    });
  }
  handleKeydown(event) {
    if (this.disabled) {
      return;
    }
    if (event.target === this.fileSelectButton.nativeElement && (event.keyCode === Keys.Enter || event.keyCode === Keys.Space)) {
      event.preventDefault();
      this.fileSelectInput.nativeElement.click();
      return;
    }
    if (hasClasses(event.target, UPLOAD_CLASSES) || !isFocusable(event.target) && !hasClasses(event.target, IGNORE_TARGET_CLASSES)) {
      this.navigation.process(event, 'fileselect');
    }
  }
  attachEventHandlers() {
    this.subs = this.uploadService.changeEvent.subscribe(files => {
      let model = [];
      if (files !== null) {
        files.forEach(file => {
          if (file.state === FileState.Initial) {
            model.push(file);
          }
          if (file.state === FileState.Selected && file.rawFile && !file.validationErrors) {
            model.push(file.rawFile);
          }
        });
      }
      if (model.length === 0) {
        model = null;
      }
      this.onChangeCallback(model);
      this.valueChange.emit(model);
    });
    this.subs.add(this.uploadService.removeEvent.subscribe(args => {
      this.remove.emit(args);
    }));
    this.subs.add(this.uploadService.selectEvent.subscribe(args => {
      this.select.emit(args);
    }));
  }
  setDefaultSettings() {
    this.uploadService.async.autoUpload = false;
    this.uploadService.component = 'FileSelect';
  }
}
FileSelectComponent.ɵfac = function FileSelectComponent_Factory(t) {
  return new (t || FileSelectComponent)(i0.ɵɵdirectiveInject(UploadService), i0.ɵɵdirectiveInject(i1$1.LocalizationService), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(DropZoneService), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef));
};
FileSelectComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileSelectComponent,
  selectors: [["kendo-fileselect"]],
  contentQueries: function FileSelectComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, FileTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, FileInfoTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileInfoTemplate = _t.first);
    }
  },
  viewQuery: function FileSelectComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c7, 7);
      i0.ɵɵviewQuery(_c8, 7, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileSelectInput = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileSelectButton = _t.first);
    }
  },
  hostVars: 6,
  hostBindings: function FileSelectComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.dir)("role", ctx.hostRole);
      i0.ɵɵclassProp("k-upload", ctx.hostDefaultClasses)("k-disabled", ctx.hostDisabledClass);
    }
  },
  inputs: {
    accept: "accept",
    disabled: "disabled",
    multiple: "multiple",
    name: "name",
    showFileList: "showFileList",
    tabindex: "tabindex",
    restrictions: "restrictions",
    zoneId: "zoneId",
    focusableId: "focusableId"
  },
  outputs: {
    onBlur: "blur",
    onFocus: "focus",
    select: "select",
    remove: "remove",
    valueChange: "valueChange"
  },
  exportAs: ["kendoFileSelect"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, NavigationService, UploadService, DropZoneService, FILESELECT_VALUE_ACCESSOR, {
    provide: L10N_PREFIX,
    useValue: 'kendo.fileselect'
  }, {
    provide: KendoInput,
    useExisting: forwardRef(() => FileSelectComponent)
  }])],
  decls: 11,
  vars: 16,
  consts: () => {
    let i18n_9;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The drop zone hint
       * @meaning kendo.fileselect.dropFilesHere
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_10 = goog.getMsg("Drop files here to select");
      i18n_9 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_10;
    } else {
      i18n_9 = $localize`:kendo.fileselect.dropFilesHere|The drop zone hint:Drop files here to select`;
    }
    let i18n_11;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the invalid allowed extensions restriction message
       * @meaning kendo.fileselect.invalidFileExtension
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_12 = goog.getMsg("File type not allowed.");
      i18n_11 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_12;
    } else {
      i18n_11 = $localize`:kendo.fileselect.invalidFileExtension|The text for the invalid allowed extensions restriction message:File type not allowed.`;
    }
    let i18n_13;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the invalid max file size restriction message
       * @meaning kendo.fileselect.invalidMaxFileSize
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_14 = goog.getMsg("File size too large.");
      i18n_13 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_14;
    } else {
      i18n_13 = $localize`:kendo.fileselect.invalidMaxFileSize|The text for the invalid max file size restriction message:File size too large.`;
    }
    let i18n_15;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the invalid min file size restriction message
       * @meaning kendo.fileselect.invalidMinFileSize
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_16 = goog.getMsg("File size too small.");
      i18n_15 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_16;
    } else {
      i18n_15 = $localize`:kendo.fileselect.invalidMinFileSize|The text for the invalid min file size restriction message:File size too small.`;
    }
    let i18n_17;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Remove button
       * @meaning kendo.fileselect.remove
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_18 = goog.getMsg("Remove");
      i18n_17 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_18;
    } else {
      i18n_17 = $localize`:kendo.fileselect.remove|The text for the Remove button:Remove`;
    }
    let i18n_19;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Select button
       * @meaning kendo.fileselect.select
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_20 = goog.getMsg("Select files...");
      i18n_19 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_20;
    } else {
      i18n_19 = $localize`:kendo.fileselect.select|The text for the Select button:Select files...`;
    }
    return [["kendoFileSelectLocalizedMessages", "", "dropFilesHere", i18n_9, "invalidFileExtension", i18n_11, "invalidMaxFileSize", i18n_13, "invalidMinFileSize", i18n_15, "remove", i18n_17, "select", i18n_19], ["kendoFileSelectInternalDropZone", "", 3, "restrictions", "multiple", "disabled"], [1, "k-upload-button-wrap"], ["kendoButton", "", "type", "button", "role", "button", 1, "k-upload-button", 3, "id", "click", "focus"], ["fileSelectButton", ""], ["kendoFileSelect", "", 3, "id", "dir", "accept", "restrictions", "multiple", "disabled"], ["fileSelectInput", ""], [1, "k-dropzone-hint"], ["kendo-upload-file-list", "", "class", "k-upload-files k-reset", 3, "disabled", "fileList", "fileTemplate", "fileInfoTemplate", 4, "ngIf"], ["kendo-upload-file-list", "", 1, "k-upload-files", "k-reset", 3, "disabled", "fileList", "fileTemplate", "fileInfoTemplate"]];
  },
  template: function FileSelectComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r3 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "div", 1)(2, "div", 2)(3, "button", 3, 4);
      i0.ɵɵlistener("click", function FileSelectComponent_Template_button_click_3_listener() {
        i0.ɵɵrestoreView(_r3);
        const _r1 = i0.ɵɵreference(7);
        return i0.ɵɵresetView(_r1.click());
      })("focus", function FileSelectComponent_Template_button_focus_3_listener() {
        return ctx.onFileSelectButtonFocus();
      });
      i0.ɵɵtext(5);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(6, "input", 5, 6);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(8, "div", 7);
      i0.ɵɵtext(9);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(10, FileSelectComponent_ul_10_Template, 1, 4, "ul", 8);
    }
    if (rf & 2) {
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("restrictions", ctx.restrictions)("multiple", ctx.multiple)("disabled", ctx.disabled);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("id", ctx.focusableId);
      i0.ɵɵattribute("aria-label", ctx.textFor("select"))("tabindex", ctx.tabindex)("aria-controls", ctx.inputElementId);
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate1(" ", ctx.textFor("select"), " ");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("id", ctx.inputElementId)("dir", ctx.direction)("accept", ctx.accept)("restrictions", ctx.restrictions)("multiple", ctx.multiple)("disabled", ctx.disabled);
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate(ctx.textFor("dropFilesHere"));
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.showFileList && ctx.fileList.count > 0);
    }
  },
  dependencies: [i5.ButtonComponent, FileListComponent, LocalizedMessagesDirective, DropZoneInternalDirective, FileSelectDirective, i6.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSelectComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoFileSelect',
      providers: [LocalizationService, NavigationService, UploadService, DropZoneService, FILESELECT_VALUE_ACCESSOR, {
        provide: L10N_PREFIX,
        useValue: 'kendo.fileselect'
      }, {
        provide: KendoInput,
        useExisting: forwardRef(() => FileSelectComponent)
      }],
      selector: 'kendo-fileselect',
      template: `
        <ng-container kendoFileSelectLocalizedMessages
            i18n-dropFilesHere="kendo.fileselect.dropFilesHere|The drop zone hint"
            dropFilesHere="Drop files here to select"

            i18n-invalidFileExtension="kendo.fileselect.invalidFileExtension|The text for the invalid allowed extensions restriction message"
            invalidFileExtension="File type not allowed."

            i18n-invalidMaxFileSize="kendo.fileselect.invalidMaxFileSize|The text for the invalid max file size restriction message"
            invalidMaxFileSize="File size too large."

            i18n-invalidMinFileSize="kendo.fileselect.invalidMinFileSize|The text for the invalid min file size restriction message"
            invalidMinFileSize="File size too small."

            i18n-remove="kendo.fileselect.remove|The text for the Remove button"
            remove="Remove"

            i18n-select="kendo.fileselect.select|The text for the Select button"
            select="Select files..."
        >
        </ng-container>
        <div kendoFileSelectInternalDropZone
            [restrictions]="restrictions"
            [multiple]="multiple"
            [disabled]="disabled">
            <div class="k-upload-button-wrap">
            <button 
                kendoButton
                #fileSelectButton
                class="k-upload-button"
                type="button"
                role="button"
                (click)="fileSelectInput.click()"
                (focus)="onFileSelectButtonFocus()"
                [id]="focusableId"
                [attr.aria-label]="textFor('select')"
                [attr.tabindex]="tabindex"
                [attr.aria-controls]="inputElementId"
            >
                {{textFor('select')}}
            </button>
            <input kendoFileSelect #fileSelectInput
                [id]="inputElementId"
                [dir]="direction"
                [accept]="accept"
                [restrictions]="restrictions"
                [multiple]="multiple"
                [disabled]="disabled"
            />
        </div>
            <div class="k-dropzone-hint">{{textFor('dropFilesHere')}}</div>
        </div>
        <ul kendo-upload-file-list
            class="k-upload-files k-reset"
            *ngIf="showFileList && fileList.count > 0"
            [disabled]="disabled"
            [fileList]="fileList.files"
            [fileTemplate]="fileTemplate"
            [fileInfoTemplate]="fileInfoTemplate">
        </ul>
    `
    }]
  }], function () {
    return [{
      type: UploadService
    }, {
      type: i1$1.LocalizationService
    }, {
      type: NavigationService
    }, {
      type: DropZoneService
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }];
  }, {
    accept: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    showFileList: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    restrictions: [{
      type: Input
    }],
    zoneId: [{
      type: Input
    }],
    focusableId: [{
      type: Input
    }],
    fileTemplate: [{
      type: ContentChild,
      args: [FileTemplateDirective]
    }],
    fileInfoTemplate: [{
      type: ContentChild,
      args: [FileInfoTemplateDirective]
    }],
    fileSelectInput: [{
      type: ViewChild,
      args: ['fileSelectInput', {
        static: true
      }]
    }],
    fileSelectButton: [{
      type: ViewChild,
      args: ['fileSelectButton', {
        static: true,
        read: ElementRef
      }]
    }],
    onBlur: [{
      type: Output,
      args: ['blur']
    }],
    onFocus: [{
      type: Output,
      args: ['focus']
    }],
    select: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    valueChange: [{
      type: Output
    }],
    hostDefaultClasses: [{
      type: HostBinding,
      args: ['class.k-upload']
    }],
    hostDisabledClass: [{
      type: HostBinding,
      args: ['class.k-disabled']
    }],
    dir: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    hostRole: [{
      type: HostBinding,
      args: ['attr.role']
    }]
  });
})();

/**
 * Custom component messages override default component messages ([more information and example]({% slug globalization_upload %})).
 *
 * @example
 * ```html-no-run
 * <kendo-fileselect>
 *     <kendo-fileselect-messages
 *         dropFilesHere="Drop your file here"
 *         select="Upload file">
 *     </kendo-fileselect-messages>
 * </kendo-fileselect>
 * ```
 */
class CustomMessagesComponent extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
  get override() {
    return true;
  }
}
CustomMessagesComponent.ɵfac = function CustomMessagesComponent_Factory(t) {
  return new (t || CustomMessagesComponent)(i0.ɵɵdirectiveInject(i1$1.LocalizationService));
};
CustomMessagesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CustomMessagesComponent,
  selectors: [["kendo-upload-messages"], ["kendo-fileselect-messages"], ["kendo-uploaddropzone-messages"]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => CustomMessagesComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CustomMessagesComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomMessagesComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => CustomMessagesComponent)
      }],
      selector: 'kendo-upload-messages, kendo-fileselect-messages, kendo-uploaddropzone-messages',
      template: ``
    }]
  }], function () {
    return [{
      type: i1$1.LocalizationService
    }];
  }, null);
})();

/**
 * @hidden
 */
class UploadStatusTotalComponent {
  constructor(localization) {
    this.localization = localization;
    this.checkmarkIcon = checkIcon;
    this.exceptionSVGIcon = exclamationCircleIcon;
    this.uploadSVGIcon = uploadIcon;
    this.pauseSVGIcon = pauseSmIcon;
  }
  get iconClass() {
    if (!this.isUploading && !this.isFailed) {
      return 'checkmark';
    }
    if (!this.isUploading && this.isFailed) {
      return 'exception';
    }
    if (this.isUploading) {
      return 'upload';
    }
    if (this.isPaused) {
      return 'pause-sm';
    }
  }
  get SVGIconClass() {
    if (!this.isUploading && !this.isFailed) {
      return this.checkmarkIcon;
    }
    if (!this.isUploading && this.isFailed) {
      return this.exceptionSVGIcon;
    }
    if (this.isUploading) {
      return this.uploadSVGIcon;
    }
    if (this.isPaused) {
      return this.pauseSVGIcon;
    }
  }
  ngDoCheck() {
    this.isPaused = this.fileList.hasFileWithState([FileState.Paused]);
    this.isFailed = this.fileList.hasFileWithState([FileState.Failed]);
    this.isUploading = this.fileList.hasFileWithState([FileState.Uploading]);
    if (this.isPaused && !this.isUploading) {
      this.statusText = this.localization.get('headerStatusPaused');
    } else {
      this.statusText = this.isUploading ? this.localization.get('headerStatusUploading') : this.localization.get('headerStatusUploaded');
    }
  }
}
UploadStatusTotalComponent.ɵfac = function UploadStatusTotalComponent_Factory(t) {
  return new (t || UploadStatusTotalComponent)(i0.ɵɵdirectiveInject(i1$1.LocalizationService));
};
UploadStatusTotalComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UploadStatusTotalComponent,
  selectors: [["kendo-upload-status-total"]],
  inputs: {
    fileList: "fileList"
  },
  decls: 2,
  vars: 3,
  consts: [[3, "name", "svgIcon"]],
  template: function UploadStatusTotalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "kendo-icon-wrapper", 0);
      i0.ɵɵtext(1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("name", ctx.iconClass)("svgIcon", ctx.SVGIconClass);
      i0.ɵɵadvance(1);
      i0.ɵɵtextInterpolate1(" ", ctx.statusText, " ");
    }
  },
  dependencies: [i4.IconWrapperComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadStatusTotalComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-upload-status-total',
      template: `
        <kendo-icon-wrapper
            [name]="iconClass"
            [svgIcon]="SVGIconClass"
        >
        </kendo-icon-wrapper>
        {{statusText}}
    `
    }]
  }], function () {
    return [{
      type: i1$1.LocalizationService
    }];
  }, {
    fileList: [{
      type: Input
    }]
  });
})();

/* eslint-disable no-debugger */
/**
 * @hidden
 */
class UploadActionButtonsComponent {
  constructor(uploadService, localization, navigation) {
    this.uploadService = uploadService;
    this.localization = localization;
    this.navigation = navigation;
    this.hostDefaultClass = true;
  }
  get actionButtonsEndClassName() {
    return this.actionsLayout === 'end';
  }
  get actionButtonsStretchedClassName() {
    return this.actionsLayout === 'stretched';
  }
  get actionButtonsStartClassName() {
    return this.actionsLayout === 'start';
  }
  get actionButtonsCenterClassName() {
    return this.actionsLayout === 'center';
  }
  onUploadButtonFocus() {
    if (!this.navigation.focused) {
      this.navigation.focusedIndex = this.navigation.lastIndex;
    }
  }
  onUploadButtonClick(event) {
    event.stopImmediatePropagation();
    this.performUpload();
  }
  performUpload() {
    if (!this.disabled) {
      this.uploadService.uploadFiles();
      this.navigation.focusSelectButton();
    }
  }
  onClearButtonClick(event) {
    event.stopImmediatePropagation();
    this.clearFiles();
  }
  clearFiles() {
    if (!this.disabled) {
      this.uploadService.clearFiles();
      this.navigation.focusSelectButton();
    }
  }
  textFor(key) {
    return this.localization.get(key);
  }
}
UploadActionButtonsComponent.ɵfac = function UploadActionButtonsComponent_Factory(t) {
  return new (t || UploadActionButtonsComponent)(i0.ɵɵdirectiveInject(UploadService), i0.ɵɵdirectiveInject(i1$1.LocalizationService), i0.ɵɵdirectiveInject(NavigationService));
};
UploadActionButtonsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UploadActionButtonsComponent,
  selectors: [["kendo-upload-action-buttons"]],
  viewQuery: function UploadActionButtonsComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c21, 7);
      i0.ɵɵviewQuery(_c22, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.clearButton = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.uploadButton = _t.first);
    }
  },
  hostVars: 10,
  hostBindings: function UploadActionButtonsComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-actions", ctx.hostDefaultClass)("k-actions-end", ctx.actionButtonsEndClassName)("k-actions-stretched", ctx.actionButtonsStretchedClassName)("k-actions-start", ctx.actionButtonsStartClassName)("k-actions-center", ctx.actionButtonsCenterClassName);
    }
  },
  inputs: {
    disabled: "disabled",
    actionsLayout: "actionsLayout"
  },
  decls: 6,
  vars: 2,
  consts: [["role", "button", 1, "k-button", "k-button-md", "k-rounded-md", "k-button-solid", "k-button-solid-base", "k-clear-selected", 3, "click"], ["clearButton", ""], ["role", "button", 1, "k-button", "k-button-md", "k-rounded-md", "k-button-solid", "k-button-solid-primary", "k-upload-selected", 3, "focus", "click"], ["uploadButton", ""]],
  template: function UploadActionButtonsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "button", 0, 1);
      i0.ɵɵlistener("click", function UploadActionButtonsComponent_Template_button_click_0_listener($event) {
        return ctx.onClearButtonClick($event);
      });
      i0.ɵɵtext(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "button", 2, 3);
      i0.ɵɵlistener("focus", function UploadActionButtonsComponent_Template_button_focus_3_listener() {
        return ctx.onUploadButtonFocus();
      })("click", function UploadActionButtonsComponent_Template_button_click_3_listener($event) {
        return ctx.onUploadButtonClick($event);
      });
      i0.ɵɵtext(5);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate1(" ", ctx.textFor("clearSelectedFiles"), " ");
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate1(" ", ctx.textFor("uploadSelectedFiles"), " ");
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadActionButtonsComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-upload-action-buttons',
      template: `
        <button #clearButton role="button" class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-clear-selected"
            (click)="onClearButtonClick($event)">
                {{textFor('clearSelectedFiles')}}
        </button>
        <button #uploadButton role="button" class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-upload-selected"
            (focus)="onUploadButtonFocus()"
            (click)="onUploadButtonClick($event)">
                {{textFor('uploadSelectedFiles')}}
        </button>
    `
    }]
  }], function () {
    return [{
      type: UploadService
    }, {
      type: i1$1.LocalizationService
    }, {
      type: NavigationService
    }];
  }, {
    disabled: [{
      type: Input
    }],
    actionsLayout: [{
      type: Input
    }],
    clearButton: [{
      type: ViewChild,
      args: ['clearButton', {
        static: true
      }]
    }],
    uploadButton: [{
      type: ViewChild,
      args: ['uploadButton', {
        static: true
      }]
    }],
    hostDefaultClass: [{
      type: HostBinding,
      args: ['class.k-actions']
    }],
    actionButtonsEndClassName: [{
      type: HostBinding,
      args: ['class.k-actions-end']
    }],
    actionButtonsStretchedClassName: [{
      type: HostBinding,
      args: ['class.k-actions-stretched']
    }],
    actionButtonsStartClassName: [{
      type: HostBinding,
      args: ['class.k-actions-start']
    }],
    actionButtonsCenterClassName: [{
      type: HostBinding,
      args: ['class.k-actions-center']
    }]
  });
})();

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @hidden
 */
const UPLOAD_VALUE_ACCESSOR = {
  multi: true,
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UploadComponent)
};
let idx = 0;
/**
 * Represents the [Kendo UI Upload component for Angular]({% slug overview_upload %}).
 */
class UploadComponent {
  constructor(uploadService, localization, navigation, dropZoneService, zone, renderer, cdr, wrapper) {
    this.uploadService = uploadService;
    this.localization = localization;
    this.navigation = navigation;
    this.dropZoneService = dropZoneService;
    this.zone = zone;
    this.renderer = renderer;
    this.cdr = cdr;
    /**
     * Enables the chunk functionality of the Upload.
     *
     * @default false
     */
    this.chunkable = false;
    /**
     * Enables the selection of multiple files
     * ([see example]({% slug fileprocessing_upload %}#toc-upload-of-sinlge-or-multiple-files)).
     * If set to `false`, only one file can be selected at a time.
     * @default true
     */
    this.multiple = true;
    /**
     * Disables the Upload ([see example]({% slug disabledstate_upload %})).
     * To learn how to disable the component in reactive forms, refer to the article on [Forms Support](slug:formssupport_upload#toc-managing-the-upload-disabled-state-in-reactive-forms).
     * @default false
     */
    this.disabled = false;
    /**
     * Toggles the visibility of the file list.
     * @default true
     */
    this.showFileList = true;
    /**
     * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
     * @default 0
     */
    this.tabindex = 0;
    /**
     * @hidden
     */
    this.focusableId = `k-${guid()}`;
    /**
     * Specifies the possible layout of the action buttons.
     */
    this.actionsLayout = 'end';
    /**
     * Fires when the user navigates outside the component.
     */
    this.onBlur = new EventEmitter();
    /**
     * Fires when the upload is canceled while in progress.
     */
    this.cancel = new EventEmitter();
    /**
     * Fires when the file list is about to be cleared. If prevented, the files will not be cleared.
     */
    this.clear = new EventEmitter();
    /**
     * Fires when all active uploads are completed either successfully or with errors.
     */
    this.complete = new EventEmitter();
    /**
     * Fires when an `upload` or `remove` operation has failed.
     */
    this.error = new EventEmitter();
    /**
     * Fires when the component is focused.
     */
    this.onFocus = new EventEmitter();
    /**
     * Fires when the upload of a file has been paused.
     */
    this.pause = new EventEmitter();
    /**
     * Fires when an uploaded file is about to be removed. If prevented, the files will remain in the list.
     */
    this.remove = new EventEmitter();
    /**
     * Fires when the upload of a file has been resumed.
     */
    this.resume = new EventEmitter();
    /**
     * Fires when files are selected. If prevented, the selected files will not be added to the list.
     */
    this.select = new EventEmitter();
    /**
     * Fires when an `upload` or `remove` operation is successfully completed.
     */
    this.success = new EventEmitter();
    /**
     * Fires when one or more files are about to be uploaded. If prevented, the files will neither be uploaded, nor added to the file list.
     */
    this.upload = new EventEmitter();
    /**
     * Fires when one or more files are being uploaded.
     */
    this.uploadProgress = new EventEmitter();
    /**
     * Fires when the value of the component has changed as a result of a successful `upload`, `remove` or `clear` operation.
     */
    this.valueChange = new EventEmitter();
    this.hostDefaultClasses = true;
    /**
     * @hidden
     */
    this._restrictions = {
      allowedExtensions: [],
      maxFileSize: 0,
      minFileSize: 0
    };
    this.onTouchedCallback = _ => {};
    this.onChangeCallback = _ => {};
    validatePackage(packageMetadata);
    this.fileList = this.uploadService.files;
    this.localizationChangeSubscription = localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    });
    this.direction = localization.rtl ? 'rtl' : 'ltr';
    this.navigation.computeKeys();
    this.wrapper = wrapper.nativeElement;
    this.subscribeBlur();
    this.subscribeFocus();
    this.attachEventHandlers();
  }
  /**
   * By default, the selected files are immediately uploaded
   * ([see example]({% slug fileprocessing_upload %}#toc-automatic-upload-of-files)).
   * To change this behavior, set `autoUpload` to `false`.
   */
  set autoUpload(autoUpload) {
    this.uploadService.async.autoUpload = autoUpload;
  }
  get autoUpload() {
    return this.uploadService.async.autoUpload;
  }
  /**
   * When enabled, all files in the selection are uploaded in one request
   * ([see example]({% slug fileprocessing_upload %}#toc-upload-of-batches-of-files)).
   * Any files that are selected one after the other are uploaded in separate requests.
   */
  set batch(batch) {
    this.uploadService.async.batch = batch;
  }
  get batch() {
    return this.uploadService.async.batch;
  }
  /**
   * Configures whether credentials (cookies, headers) will be sent for cross-site requests
   * ([see example]({% slug credentials_upload %}#toc-attaching-credentials-to-requests)).
   * The default values is `true`. Setting `withCredentials` has no effect on same-site requests.
   * To add credentials to the request, use the `saveHeaders` or `removeHeaders` property,
   * or the [`upload`]({% slug api_upload_uploadevent %}) event.
   */
  set withCredentials(withCredentials) {
    this.uploadService.async.withCredentials = withCredentials;
  }
  get withCredentials() {
    return this.uploadService.async.withCredentials;
  }
  /**
   * Sets the [`FormData`](https://developer.mozilla.org/en-US/docs/Web/API/FormData) key which contains the files submitted to `saveUrl`.
   * The default value is `files`.
   */
  set saveField(saveField) {
    this.uploadService.async.saveField = saveField;
  }
  get saveField() {
    return this.uploadService.async.saveField;
  }
  /**
   * Configures the [`HttpHeaders`](https://angular.io/api/common/http/HttpHeaders)
   * that are attached to each upload request.
   */
  set saveHeaders(saveHeaders) {
    this.uploadService.async.saveHeaders = saveHeaders;
  }
  get saveHeaders() {
    return this.uploadService.async.saveHeaders;
  }
  /**
   * Sets the [`RequestMethod`](https://angular.io/api/http/RequestMethod) of the upload request.
   * The default value is `POST`.
   */
  set saveMethod(saveMethod) {
    this.uploadService.async.saveMethod = saveMethod;
  }
  get saveMethod() {
    return this.uploadService.async.saveMethod;
  }
  /**
   * Sets the URL of the endpoint for the upload request.
   * The request [`FormData`](https://developer.mozilla.org/en-US/docs/Web/API/FormData) key is named after the `saveField` property.
   * It contains the list of files to be uploaded.
   */
  set saveUrl(saveUrl) {
    this.uploadService.async.saveUrl = saveUrl;
  }
  get saveUrl() {
    return this.uploadService.async.saveUrl;
  }
  /**
   * Sets the expected [`response type`](https://angular.io/api/common/http/HttpRequest#responseType) of the server.
   * It is used to parse the response appropriately.
   * @default 'json'
   */
  set responseType(responseType) {
    this.uploadService.async.responseType = responseType;
  }
  get responseType() {
    return this.uploadService.async.responseType;
  }
  /**
   * Sets the [`FormData`](https://developer.mozilla.org/en-US/docs/Web/API/FormData) key
   * which contains the list of file names that are submitted to `removeUrl`.
   * The default value is `fileNames`.
   */
  set removeField(removeField) {
    this.uploadService.async.removeField = removeField;
  }
  get removeField() {
    return this.uploadService.async.removeField;
  }
  /**
   * Configures the [`HttpHeaders`](https://angular.io/api/common/http/HttpHeaders)
   * that are attached to each `remove` request.
   */
  set removeHeaders(removeHeaders) {
    this.uploadService.async.removeHeaders = removeHeaders;
  }
  get removeHeaders() {
    return this.uploadService.async.removeHeaders;
  }
  /**
   * Sets the [`RequestMethod`](https://angular.io/api/http/RequestMethod) of the `remove` request.
   * The default value is `POST`.
   */
  set removeMethod(removeMethod) {
    this.uploadService.async.removeMethod = removeMethod;
  }
  get removeMethod() {
    return this.uploadService.async.removeMethod;
  }
  /**
   * Sets the URL of the endpoint for the `remove` request.
   * The [`FormData`](https://developer.mozilla.org/en-US/docs/Web/API/FormData) request key is named after the `removeField` property.
   * It contains the list of file names which will be removed.
   */
  set removeUrl(removeUrl) {
    this.uploadService.async.removeUrl = removeUrl;
  }
  get removeUrl() {
    return this.uploadService.async.removeUrl;
  }
  /**
   * Specifies if the selected files are uploaded simultaneously or one by one.
   *
   * @default true
   */
  set concurrent(concurrent) {
    this.uploadService.async.concurrent = concurrent;
  }
  get concurrent() {
    return this.uploadService.async.concurrent;
  }
  /**
   * @hidden
   */
  set tabIndex(tabIndex) {
    this.tabindex = tabIndex;
  }
  get tabIndex() {
    return this.tabindex;
  }
  /**
   * Sets the restrictions for selected files ([see example]({% slug api_upload_filerestrictions %})).
   */
  set restrictions(restrictions) {
    const parsedRestrictions = Object.assign({}, this._restrictions, restrictions);
    this._restrictions = parsedRestrictions;
  }
  get restrictions() {
    return this._restrictions;
  }
  get hostRole() {
    return 'application';
  }
  get hostDisabledClass() {
    return this.disabled;
  }
  get dir() {
    return this.direction;
  }
  ngOnInit() {
    this.verifySettings();
    const {
      buttonId
    } = this.getIds();
    this.focusableId = buttonId;
    this.uploadService.setChunkSettings(this.chunkable);
    if (this.zoneId) {
      this.dropZoneService.addComponent(this, this.zoneId);
    }
    this.zone.runOutsideAngular(() => {
      this.subs.add(this.renderer.listen(this.wrapper, 'keydown', event => this.handleKeydown(event)));
    });
  }
  ngOnChanges(changes) {
    if (isChanged("chunkable", changes)) {
      const newChunkable = changes.chunkable.currentValue;
      if (typeof newChunkable === 'boolean') {
        this.uploadService.async.chunk = newChunkable;
      }
      if (typeof newChunkable === "object" && newChunkable !== null) {
        this.uploadService.async.chunk = true;
        this.uploadService.chunk = Object.assign({}, this.uploadService.chunk, newChunkable);
      }
    }
  }
  ngOnDestroy() {
    this.fileList.clear();
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
    }
    if (this.wrapperFocusSubscription) {
      this.wrapperFocusSubscription.unsubscribe();
    }
    if (this.selectButtonFocusSubscription) {
      this.selectButtonFocusSubscription.unsubscribe();
    }
    if (this.localizationChangeSubscription) {
      this.localizationChangeSubscription.unsubscribe();
    }
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
  /**
   * @hidden
   */
  writeValue(newValue) {
    let isValid = true;
    if (newValue instanceof Array) {
      newValue.forEach(file => {
        if (!validateInitialFileInfo(file)) {
          isValid = false;
        }
      });
      if (isValid) {
        this.uploadService.addInitialFiles(newValue);
      }
    }
    if (newValue === null) {
      this.fileList.clear();
    }
    this.cdr.markForCheck();
  }
  /**
   * @hidden
   */
  registerOnChange(fn) {
    this.onChangeCallback = fn;
  }
  /**
   * @hidden
   */
  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }
  /**
   * @hidden
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  /**
   * @hidden
   */
  onFileSelectButtonFocus() {
    if (!this.navigation.focused) {
      this.navigation.focusedIndex = -1;
    }
  }
  /**
   * @hidden
   */
  get showActionButtons() {
    let areVisible = false;
    if (!this.autoUpload) {
      const hasFilesToUpload = this.fileList.filesToUpload.length > 0;
      const uploadingFiles = this.fileList.hasFileWithState([FileState.Uploading]);
      if (this.concurrent && hasFilesToUpload) {
        areVisible = true;
      }
      if (!this.concurrent && hasFilesToUpload && !uploadingFiles) {
        areVisible = true;
      }
    }
    this.navigation.actionButtonsVisible = areVisible;
    return areVisible;
  }
  /**
   * @hidden
   */
  get showTotalStatus() {
    const states = [FileState.Uploaded, FileState.Uploading, FileState.Failed, FileState.Paused];
    if (this.fileList.hasFileWithState(states)) {
      return true;
    }
    return false;
  }
  /**
   * @hidden
   */
  textFor(key) {
    return this.localization.get(key);
  }
  /**
   * @hidden
   */
  getIds() {
    const id = ++idx;
    const buttonId = `k-upload-button-${id}`;
    const inputId = `k-upload-input-${id}`;
    return {
      buttonId,
      inputId
    };
  }
  /**
   * Focuses the underlying input element.
   */
  focus() {
    setTimeout(() => {
      this.fileSelectButton.nativeElement.focus();
      this.navigation.focused = true;
      this.onFocus.emit();
    });
  }
  /**
   * @hidden
   * @deprecated
   */
  focusComponent() {
    this.focus();
  }
  /**
   * Blurs the Upload if it was previously focused.
   */
  blur() {
    if (this.navigation.focused) {
      this.navigation.focused = false;
      document.activeElement.blur();
      this.onBlur.emit();
    }
  }
  /**
   * @hidden
   * @deprecated
   */
  blurComponent() {
    this.blur();
  }
  /**
   * Pauses the upload process of a file that is currently uploading.
   * The `pauseFileByUid` method requires the `chunkable` option of the Upload to be enabled.
   *
   * @param uid - The `uid` of the file that will be paused.
   */
  pauseFileByUid(uid) {
    this.uploadService.pauseFile(uid);
  }
  /**
   * Resumes the upload process for a file that has been previously paused.
   * The `resumeFileByUid` method requires the `chunkable` option of the Upload to be enabled.
   *
   * @param uid - The `uid` of the file that will be resumed.
   */
  resumeFileByUid(uid) {
    this.uploadService.resumeFile(uid);
  }
  /**
   * Triggers the removal of a file or a batch of files.
   * @param uid - The `uid` of the file or a batch of files that will be removed.
   */
  removeFilesByUid(uid) {
    this.uploadService.removeFiles(uid);
  }
  /**
   * Triggers another upload attempt of an unsuccessfully uploaded file or a batch of files.
   * @param uid - The `uid` of the file or a batch of files to be retried.
   */
  retryUploadByUid(uid) {
    this.uploadService.retryFiles(uid);
  }
  /**
   * Cancels the upload of a file or a batch of files.
   * @param uid - The `uid` of the file or a batch of files that will be canceled.
   */
  cancelUploadByUid(uid) {
    this.uploadService.cancelFiles(uid);
  }
  /**
   * Uploads the currently selected files which pass the set restrictions.
   */
  uploadFiles() {
    if (this.fileList.filesToUpload.length) {
      this.uploadService.uploadFiles();
    }
  }
  /**
   * Visually clears all files from the UI without issuing requests to the remove handler.
   */
  clearFiles() {
    this.uploadService.clearFiles();
  }
  /**
   * @hidden
   * Used by the external dropzone to add files to the Upload
   */
  addFiles(files) {
    this.uploadService.addFiles(files);
  }
  /**
   * @hidden
   * Used to determine if the component is empty.
   */
  isEmpty() {
    return false;
  }
  verifySettings() {
    if (isDevMode()) {
      if (this.batch && this.chunkable !== false) {
        throw new Error('The file chunking functionality requires the batch setting to be disabled.');
      }
    }
  }
  subscribeBlur() {
    if (!isDocumentAvailable()) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.documentClick = fromEvent(document, 'click').pipe(filter(event => {
        return !(this.wrapper !== event.target && this.wrapper.contains(event.target));
      }));
      this.blurSubscription = merge(this.documentClick, this.navigation.onTabOut).subscribe(() => {
        if (this.navigation.focused) {
          this.zone.run(() => {
            this.navigation.focused = false;
            this.onTouchedCallback();
            this.onBlur.emit();
          });
        }
      });
    });
  }
  handleKeydown(event) {
    if (this.disabled) {
      return;
    }
    if (event.target === this.fileSelectButton.nativeElement && (event.keyCode === Keys.Enter || event.keyCode === Keys.Space)) {
      event.preventDefault();
      this.fileSelectInput.nativeElement.click();
      return;
    }
    if (hasClasses(event.target, UPLOAD_CLASSES) || !isFocusable(event.target) && !hasClasses(event.target, IGNORE_TARGET_CLASSES)) {
      this.navigation.process(event);
    }
  }
  subscribeFocus() {
    this.wrapperFocusSubscription = this.navigation.onWrapperFocus.subscribe(() => {
      this.onFocus.emit();
    });
    this.selectButtonFocusSubscription = this.navigation.onSelectButtonFocus.subscribe(() => {
      this.fileSelectButton.nativeElement.focus();
    });
  }
  attachEventHandlers() {
    this.subs = this.uploadService.cancelEvent.subscribe(args => {
      this.cancel.emit(args);
    });
    this.subs.add(this.uploadService.changeEvent.subscribe(files => {
      this.onChangeCallback(files);
      this.valueChange.emit(files);
    }));
    this.subs.add(this.uploadService.clearEvent.subscribe(args => {
      this.clear.emit(args);
    }));
    this.subs.add(this.uploadService.completeEvent.subscribe(() => {
      this.complete.emit();
    }));
    this.subs.add(this.uploadService.errorEvent.subscribe(args => {
      this.error.emit(args);
    }));
    this.subs.add(this.uploadService.pauseEvent.subscribe(args => {
      this.pause.emit(args);
    }));
    this.subs.add(this.uploadService.removeEvent.subscribe(args => {
      this.remove.emit(args);
    }));
    this.subs.add(this.uploadService.resumeEvent.subscribe(args => {
      this.resume.emit(args);
    }));
    this.subs.add(this.uploadService.selectEvent.subscribe(args => {
      this.select.emit(args);
    }));
    this.subs.add(this.uploadService.successEvent.subscribe(args => {
      this.success.emit(args);
    }));
    this.subs.add(this.uploadService.uploadEvent.subscribe(args => {
      this.upload.emit(args);
    }));
    this.subs.add(this.uploadService.uploadProgressEvent.subscribe(args => {
      this.uploadProgress.emit(args);
    }));
  }
}
UploadComponent.ɵfac = function UploadComponent_Factory(t) {
  return new (t || UploadComponent)(i0.ɵɵdirectiveInject(UploadService), i0.ɵɵdirectiveInject(i1$1.LocalizationService), i0.ɵɵdirectiveInject(NavigationService), i0.ɵɵdirectiveInject(DropZoneService), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef));
};
UploadComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UploadComponent,
  selectors: [["kendo-upload"]],
  contentQueries: function UploadComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, FileTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, FileInfoTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileInfoTemplate = _t.first);
    }
  },
  viewQuery: function UploadComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c7, 7);
      i0.ɵɵviewQuery(_c8, 7, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileSelectInput = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileSelectButton = _t.first);
    }
  },
  hostVars: 6,
  hostBindings: function UploadComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.hostRole)("dir", ctx.dir);
      i0.ɵɵclassProp("k-upload", ctx.hostDefaultClasses)("k-disabled", ctx.hostDisabledClass);
    }
  },
  inputs: {
    autoUpload: "autoUpload",
    batch: "batch",
    withCredentials: "withCredentials",
    saveField: "saveField",
    saveHeaders: "saveHeaders",
    saveMethod: "saveMethod",
    saveUrl: "saveUrl",
    responseType: "responseType",
    removeField: "removeField",
    removeHeaders: "removeHeaders",
    removeMethod: "removeMethod",
    removeUrl: "removeUrl",
    chunkable: "chunkable",
    concurrent: "concurrent",
    multiple: "multiple",
    disabled: "disabled",
    showFileList: "showFileList",
    tabindex: "tabindex",
    zoneId: "zoneId",
    tabIndex: "tabIndex",
    accept: "accept",
    restrictions: "restrictions",
    focusableId: "focusableId",
    actionsLayout: "actionsLayout"
  },
  outputs: {
    onBlur: "blur",
    cancel: "cancel",
    clear: "clear",
    complete: "complete",
    error: "error",
    onFocus: "focus",
    pause: "pause",
    remove: "remove",
    resume: "resume",
    select: "select",
    success: "success",
    upload: "upload",
    uploadProgress: "uploadProgress",
    valueChange: "valueChange"
  },
  exportAs: ["kendoUpload"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, NavigationService, UploadService, DropZoneService, UPLOAD_VALUE_ACCESSOR, {
    provide: L10N_PREFIX,
    useValue: 'kendo.upload'
  }, {
    provide: KendoInput,
    useExisting: forwardRef(() => UploadComponent)
  }]), i0.ɵɵNgOnChangesFeature],
  decls: 13,
  vars: 16,
  consts: () => {
    let i18n_23;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Cancel button
       * @meaning kendo.upload.cancel
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_24 = goog.getMsg("Cancel");
      i18n_23 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_24;
    } else {
      i18n_23 = $localize`:kendo.upload.cancel|The text for the Cancel button:Cancel`;
    }
    let i18n_25;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Clear button
       * @meaning kendo.upload.clearSelectedFiles
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_26 = goog.getMsg("Clear");
      i18n_25 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_26;
    } else {
      i18n_25 = $localize`:kendo.upload.clearSelectedFiles|The text for the Clear button:Clear`;
    }
    let i18n_27;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The drop zone hint
       * @meaning kendo.upload.dropFilesHere
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_28 = goog.getMsg("Drop files here to upload");
      i18n_27 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_28;
    } else {
      i18n_27 = $localize`:kendo.upload.dropFilesHere|The drop zone hint:Drop files here to upload`;
    }
    let i18n_29;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The status message for a batch of files
       * @meaning kendo.upload.filesBatchStatus
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_30 = goog.getMsg("files");
      i18n_29 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_30;
    } else {
      i18n_29 = $localize`:kendo.upload.filesBatchStatus|The status message for a batch of files:files`;
    }
    let i18n_31;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The status message for a batch of files after failed upload
       * @meaning kendo.upload.filesBatchStatusFailed
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_32 = goog.getMsg("files failed to upload.");
      i18n_31 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_32;
    } else {
      i18n_31 = $localize`:kendo.upload.filesBatchStatusFailed|The status message for a batch of files after failed upload:files failed to upload.`;
    }
    let i18n_33;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The status message for a batch of files after successful upload
       * @meaning kendo.upload.filesBatchStatusUploaded
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_34 = goog.getMsg("files successfully uploaded.");
      i18n_33 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_34;
    } else {
      i18n_33 = $localize`:kendo.upload.filesBatchStatusUploaded|The status message for a batch of files after successful upload:files successfully uploaded.`;
    }
    let i18n_35;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The file status message after failed upload
       * @meaning kendo.upload.fileStatusFailed
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_36 = goog.getMsg("File failed to upload.");
      i18n_35 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_36;
    } else {
      i18n_35 = $localize`:kendo.upload.fileStatusFailed|The file status message after failed upload:File failed to upload.`;
    }
    let i18n_37;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The file status message after successful upload
       * @meaning kendo.upload.fileStatusUploaded
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_38 = goog.getMsg("File successfully uploaded.");
      i18n_37 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_38;
    } else {
      i18n_37 = $localize`:kendo.upload.fileStatusUploaded|The file status message after successful upload:File successfully uploaded.`;
    }
    let i18n_39;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The header status message when the file upload is paused
       * @meaning kendo.upload.headerStatusPaused
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_40 = goog.getMsg("Paused");
      i18n_39 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_40;
    } else {
      i18n_39 = $localize`:kendo.upload.headerStatusPaused|The header status message when the file upload is paused:Paused`;
    }
    let i18n_41;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The header status message after file upload completion
       * @meaning kendo.upload.headerStatusUploaded
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_42 = goog.getMsg("Done");
      i18n_41 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_42;
    } else {
      i18n_41 = $localize`:kendo.upload.headerStatusUploaded|The header status message after file upload completion:Done`;
    }
    let i18n_43;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The header status message during file upload
       * @meaning kendo.upload.headerStatusUploading
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_44 = goog.getMsg("Uploading...");
      i18n_43 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_44;
    } else {
      i18n_43 = $localize`:kendo.upload.headerStatusUploading|The header status message during file upload:Uploading...`;
    }
    let i18n_45;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the invalid allowed extensions restriction message
       * @meaning kendo.upload.invalidFileExtension
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_46 = goog.getMsg("File type not allowed.");
      i18n_45 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_46;
    } else {
      i18n_45 = $localize`:kendo.upload.invalidFileExtension|The text for the invalid allowed extensions restriction message:File type not allowed.`;
    }
    let i18n_47;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the invalid max file size restriction message
       * @meaning kendo.upload.invalidMaxFileSize
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_48 = goog.getMsg("File size too large.");
      i18n_47 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_48;
    } else {
      i18n_47 = $localize`:kendo.upload.invalidMaxFileSize|The text for the invalid max file size restriction message:File size too large.`;
    }
    let i18n_49;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the invalid min file size restriction message
       * @meaning kendo.upload.invalidMinFileSize
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_50 = goog.getMsg("File size too small.");
      i18n_49 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_50;
    } else {
      i18n_49 = $localize`:kendo.upload.invalidMinFileSize|The text for the invalid min file size restriction message:File size too small.`;
    }
    let i18n_51;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Pause button
       * @meaning kendo.upload.pause
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_52 = goog.getMsg("Pause");
      i18n_51 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_52;
    } else {
      i18n_51 = $localize`:kendo.upload.pause|The text for the Pause button:Pause`;
    }
    let i18n_53;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Remove button
       * @meaning kendo.upload.remove
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_54 = goog.getMsg("Remove");
      i18n_53 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_54;
    } else {
      i18n_53 = $localize`:kendo.upload.remove|The text for the Remove button:Remove`;
    }
    let i18n_55;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Resume button
       * @meaning kendo.upload.resume
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_56 = goog.getMsg("Resume");
      i18n_55 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_56;
    } else {
      i18n_55 = $localize`:kendo.upload.resume|The text for the Resume button:Resume`;
    }
    let i18n_57;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Retry button
       * @meaning kendo.upload.retry
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_58 = goog.getMsg("Retry");
      i18n_57 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_58;
    } else {
      i18n_57 = $localize`:kendo.upload.retry|The text for the Retry button:Retry`;
    }
    let i18n_59;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Select button
       * @meaning kendo.upload.select
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_60 = goog.getMsg("Select files...");
      i18n_59 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_60;
    } else {
      i18n_59 = $localize`:kendo.upload.select|The text for the Select button:Select files...`;
    }
    let i18n_61;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the Upload files button
       * @meaning kendo.upload.uploadSelectedFiles
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_62 = goog.getMsg("Upload");
      i18n_61 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_62;
    } else {
      i18n_61 = $localize`:kendo.upload.uploadSelectedFiles|The text for the Upload files button:Upload`;
    }
    return [["kendoUploadLocalizedMessages", "", "cancel", i18n_23, "clearSelectedFiles", i18n_25, "dropFilesHere", i18n_27, "filesBatchStatus", i18n_29, "filesBatchStatusFailed", i18n_31, "filesBatchStatusUploaded", i18n_33, "fileStatusFailed", i18n_35, "fileStatusUploaded", i18n_37, "headerStatusPaused", i18n_39, "headerStatusUploaded", i18n_41, "headerStatusUploading", i18n_43, "invalidFileExtension", i18n_45, "invalidMaxFileSize", i18n_47, "invalidMinFileSize", i18n_49, "pause", i18n_51, "remove", i18n_53, "resume", i18n_55, "retry", i18n_57, "select", i18n_59, "uploadSelectedFiles", i18n_61], ["kendoUploadInternalDropZone", "", 3, "restrictions", "multiple", "disabled"], [1, "k-upload-button-wrap"], ["kendoButton", "", "type", "button", "role", "button", 1, "k-upload-button", 3, "id", "click", "focus"], ["fileSelectButton", ""], ["kendoFileSelect", "", 3, "dir", "accept", "restrictions", "multiple", "disabled"], ["fileSelectInput", ""], ["class", "k-upload-status", 3, "fileList", 4, "ngIf"], [1, "k-dropzone-hint"], ["kendo-upload-file-list", "", "class", "k-upload-files k-reset", 3, "disabled", "fileList", "fileTemplate", "fileInfoTemplate", 4, "ngIf"], [3, "disabled", "actionsLayout", 4, "ngIf"], [1, "k-upload-status", 3, "fileList"], ["kendo-upload-file-list", "", 1, "k-upload-files", "k-reset", 3, "disabled", "fileList", "fileTemplate", "fileInfoTemplate"], [3, "disabled", "actionsLayout"]];
  },
  template: function UploadComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r5 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "div", 1)(2, "div", 2)(3, "button", 3, 4);
      i0.ɵɵlistener("click", function UploadComponent_Template_button_click_3_listener() {
        i0.ɵɵrestoreView(_r5);
        const _r1 = i0.ɵɵreference(7);
        return i0.ɵɵresetView(_r1.click());
      })("focus", function UploadComponent_Template_button_focus_3_listener() {
        return ctx.onFileSelectButtonFocus();
      });
      i0.ɵɵtext(5);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(6, "input", 5, 6);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(8, UploadComponent_kendo_upload_status_total_8_Template, 1, 1, "kendo-upload-status-total", 7);
      i0.ɵɵelementStart(9, "div", 8);
      i0.ɵɵtext(10);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(11, UploadComponent_ul_11_Template, 1, 4, "ul", 9)(12, UploadComponent_kendo_upload_action_buttons_12_Template, 1, 2, "kendo-upload-action-buttons", 10);
    }
    if (rf & 2) {
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("restrictions", ctx.restrictions)("multiple", ctx.multiple)("disabled", ctx.disabled);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("id", ctx.focusableId);
      i0.ɵɵattribute("aria-label", ctx.textFor("select"))("tabindex", ctx.tabindex);
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate1(" ", ctx.textFor("select"), " ");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("dir", ctx.direction)("accept", ctx.accept)("restrictions", ctx.restrictions)("multiple", ctx.multiple)("disabled", ctx.disabled);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.showTotalStatus);
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.textFor("dropFilesHere"));
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.showFileList && ctx.fileList.count > 0);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.showActionButtons);
    }
  },
  dependencies: [i5.ButtonComponent, UploadStatusTotalComponent, FileListComponent, UploadActionButtonsComponent, LocalizedMessagesDirective, DropZoneInternalDirective, FileSelectDirective, i6.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoUpload',
      providers: [LocalizationService, NavigationService, UploadService, DropZoneService, UPLOAD_VALUE_ACCESSOR, {
        provide: L10N_PREFIX,
        useValue: 'kendo.upload'
      }, {
        provide: KendoInput,
        useExisting: forwardRef(() => UploadComponent)
      }],
      selector: 'kendo-upload',
      template: `
    <ng-container kendoUploadLocalizedMessages
        i18n-cancel="kendo.upload.cancel|The text for the Cancel button"
        cancel="Cancel"

        i18n-clearSelectedFiles="kendo.upload.clearSelectedFiles|The text for the Clear button"
        clearSelectedFiles="Clear"

        i18n-dropFilesHere="kendo.upload.dropFilesHere|The drop zone hint"
        dropFilesHere="Drop files here to upload"

        i18n-filesBatchStatus="kendo.upload.filesBatchStatus|The status message for a batch of files"
        filesBatchStatus="files"

        i18n-filesBatchStatusFailed="kendo.upload.filesBatchStatusFailed|The status message for a batch of files after failed upload"
        filesBatchStatusFailed="files failed to upload."

        i18n-filesBatchStatusUploaded="kendo.upload.filesBatchStatusUploaded|The status message for a batch of files after successful upload"
        filesBatchStatusUploaded="files successfully uploaded."

        i18n-fileStatusFailed="kendo.upload.fileStatusFailed|The file status message after failed upload"
        fileStatusFailed="File failed to upload."

        i18n-fileStatusUploaded="kendo.upload.fileStatusUploaded|The file status message after successful upload"
        fileStatusUploaded="File successfully uploaded."

        i18n-headerStatusPaused="kendo.upload.headerStatusPaused|The header status message when the file upload is paused"
        headerStatusPaused="Paused"

        i18n-headerStatusUploaded="kendo.upload.headerStatusUploaded|The header status message after file upload completion"
        headerStatusUploaded="Done"

        i18n-headerStatusUploading="kendo.upload.headerStatusUploading|The header status message during file upload"
        headerStatusUploading="Uploading..."

        i18n-invalidFileExtension="kendo.upload.invalidFileExtension|The text for the invalid allowed extensions restriction message"
        invalidFileExtension="File type not allowed."

        i18n-invalidMaxFileSize="kendo.upload.invalidMaxFileSize|The text for the invalid max file size restriction message"
        invalidMaxFileSize="File size too large."

        i18n-invalidMinFileSize="kendo.upload.invalidMinFileSize|The text for the invalid min file size restriction message"
        invalidMinFileSize="File size too small."

        i18n-pause="kendo.upload.pause|The text for the Pause button"
        pause="Pause"

        i18n-remove="kendo.upload.remove|The text for the Remove button"
        remove="Remove"

        i18n-resume="kendo.upload.resume|The text for the Resume button"
        resume="Resume"

        i18n-retry="kendo.upload.retry|The text for the Retry button"
        retry="Retry"

        i18n-select="kendo.upload.select|The text for the Select button"
        select="Select files..."

        i18n-uploadSelectedFiles="kendo.upload.uploadSelectedFiles|The text for the Upload files button"
        uploadSelectedFiles="Upload"
    >
    </ng-container>
    <div kendoUploadInternalDropZone
        [restrictions]="restrictions"
        [multiple]="multiple"
        [disabled]="disabled"
    >
        <div class="k-upload-button-wrap">
            <button 
                kendoButton
                #fileSelectButton
                class="k-upload-button"
                type="button"
                role="button"
                (click)="fileSelectInput.click()"
                (focus)="onFileSelectButtonFocus()"
                [id]="focusableId"
                [attr.aria-label]="textFor('select')"
                [attr.tabindex]="tabindex"
            >
                {{textFor('select')}}
            </button>
            <input kendoFileSelect #fileSelectInput
                [dir]="direction"
                [accept]="accept"
                [restrictions]="restrictions"
                [multiple]="multiple"
                [disabled]="disabled" />
        </div>
        <kendo-upload-status-total *ngIf="showTotalStatus"
            class="k-upload-status"
            [fileList]="fileList">
        </kendo-upload-status-total>
        <div class="k-dropzone-hint">{{textFor('dropFilesHere')}}</div>
    </div>
    <ul kendo-upload-file-list *ngIf="showFileList && fileList.count > 0"
        class="k-upload-files k-reset"
        [disabled]="disabled"
        [fileList]="fileList.files"
        [fileTemplate]="fileTemplate"
        [fileInfoTemplate]="fileInfoTemplate">
    </ul>
    <kendo-upload-action-buttons
        *ngIf="showActionButtons"
        [disabled]="disabled"
        [actionsLayout]="actionsLayout">
    </kendo-upload-action-buttons>
    `
    }]
  }], function () {
    return [{
      type: UploadService
    }, {
      type: i1$1.LocalizationService
    }, {
      type: NavigationService
    }, {
      type: DropZoneService
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }];
  }, {
    autoUpload: [{
      type: Input
    }],
    batch: [{
      type: Input
    }],
    withCredentials: [{
      type: Input
    }],
    saveField: [{
      type: Input
    }],
    saveHeaders: [{
      type: Input
    }],
    saveMethod: [{
      type: Input
    }],
    saveUrl: [{
      type: Input
    }],
    responseType: [{
      type: Input
    }],
    removeField: [{
      type: Input
    }],
    removeHeaders: [{
      type: Input
    }],
    removeMethod: [{
      type: Input
    }],
    removeUrl: [{
      type: Input
    }],
    chunkable: [{
      type: Input
    }],
    concurrent: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    showFileList: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    zoneId: [{
      type: Input
    }],
    tabIndex: [{
      type: Input,
      args: ['tabIndex']
    }],
    accept: [{
      type: Input
    }],
    restrictions: [{
      type: Input
    }],
    focusableId: [{
      type: Input
    }],
    actionsLayout: [{
      type: Input
    }],
    fileTemplate: [{
      type: ContentChild,
      args: [FileTemplateDirective]
    }],
    fileInfoTemplate: [{
      type: ContentChild,
      args: [FileInfoTemplateDirective]
    }],
    fileSelectInput: [{
      type: ViewChild,
      args: ['fileSelectInput', {
        static: true
      }]
    }],
    fileSelectButton: [{
      type: ViewChild,
      args: ['fileSelectButton', {
        static: true,
        read: ElementRef
      }]
    }],
    onBlur: [{
      type: Output,
      args: ['blur']
    }],
    cancel: [{
      type: Output
    }],
    clear: [{
      type: Output
    }],
    complete: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    onFocus: [{
      type: Output,
      args: ['focus']
    }],
    pause: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    resume: [{
      type: Output
    }],
    select: [{
      type: Output
    }],
    success: [{
      type: Output
    }],
    upload: [{
      type: Output
    }],
    uploadProgress: [{
      type: Output
    }],
    valueChange: [{
      type: Output
    }],
    hostDefaultClasses: [{
      type: HostBinding,
      args: ['class.k-upload']
    }],
    hostRole: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    hostDisabledClass: [{
      type: HostBinding,
      args: ['class.k-disabled']
    }],
    dir: [{
      type: HostBinding,
      args: ['attr.dir']
    }]
  });
})();
class UploadDropZoneDirective {
  constructor(dropZoneService) {
    this.dropZoneService = dropZoneService;
  }
  /**
   * @hidden
   */
  onElementDragEnter() {
    return false;
  }
  /**
   * @hidden
   */
  onElementDragOver() {
    return false;
  }
  /**
   * @hidden
   */
  onDropListener(event) {
    const components = this.componentInstance;
    if (!isPresent(components)) {
      return;
    }
    components.forEach(component => {
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length > 0 && !component.disabled) {
        let files = getAllFileInfo(droppedFiles);
        if (component instanceof UploadComponent) {
          files = assignGuidToFiles(files, !component.batch);
        } else {
          files = assignGuidToFiles(files, true);
        }
        if (!component.multiple) {
          files.splice(1, files.length - 1);
          component.clearFiles();
        }
        validateFiles(files, component.restrictions);
        component.addFiles(files);
      }
    });
    return false;
  }
  /**
   * @hidden
   */
  get componentInstance() {
    return this.dropZoneService.getComponents(this.zoneId);
  }
}
UploadDropZoneDirective.ɵfac = function UploadDropZoneDirective_Factory(t) {
  return new (t || UploadDropZoneDirective)(i0.ɵɵdirectiveInject(DropZoneService));
};
UploadDropZoneDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: UploadDropZoneDirective,
  selectors: [["", "kendoUploadDropZone", ""], ["", "kendoFileSelectDropZone", ""]],
  hostBindings: function UploadDropZoneDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("dragenter", function UploadDropZoneDirective_dragenter_HostBindingHandler() {
        return ctx.onElementDragEnter();
      })("dragover", function UploadDropZoneDirective_dragover_HostBindingHandler() {
        return ctx.onElementDragOver();
      })("drop", function UploadDropZoneDirective_drop_HostBindingHandler($event) {
        return ctx.onDropListener($event);
      });
    }
  },
  inputs: {
    zoneId: ["kendoUploadDropZone", "zoneId"]
  },
  features: [i0.ɵɵProvidersFeature([DropZoneService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadDropZoneDirective, [{
    type: Directive,
    args: [{
      providers: [DropZoneService],
      selector: '[kendoUploadDropZone], [kendoFileSelectDropZone]'
    }]
  }], function () {
    return [{
      type: DropZoneService
    }];
  }, {
    zoneId: [{
      type: Input,
      args: ['kendoUploadDropZone']
    }],
    onElementDragEnter: [{
      type: HostListener,
      args: ['dragenter']
    }],
    onElementDragOver: [{
      type: HostListener,
      args: ['dragover']
    }],
    onDropListener: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  });
})();

/**
 * Represents the [Kendo UI UploadDropZone component for Angular]({% slug overview_upload %}).
 */
class UploadDropZoneComponent extends DropZoneBase {
  constructor(element, renderer, localization) {
    super(element, renderer, 'k-external-dropzone-hover');
    this.localization = localization;
    this.hostClass = true;
    this._svgIcon = uploadIcon;
    this.localizationChangeSubscription = this.localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    });
  }
  get dirAttribute() {
    return this.direction;
  }
  /**
   * Defines an SVGIcon to be rendered inside the DropZone.
   * The input can take either an [existing Kendo SVG icon](slug:svgicon_list) or a custom one.
   */
  set svgIcon(icon) {
    if (isDevMode() && icon && this.icon && this.iconClass) {
      throw new Error('Setting both icon/svgIcon and iconClass options at the same time is not supported.');
    }
    this._svgIcon = icon;
  }
  get svgIcon() {
    return this._svgIcon;
  }
  /**
   * @hidden
   */
  textFor(key) {
    return this.localization.get(key);
  }
  /**
   * @hidden
   */
  get iconClasses() {
    if (this.icon) {
      return `${this.icon}`;
    }
    if (!this.icon && !this.iconClass) {
      return 'upload';
    }
  }
  ngOnDestroy() {
    if (this.localizationChangeSubscription) {
      this.localizationChangeSubscription.unsubscribe();
    }
  }
}
UploadDropZoneComponent.ɵfac = function UploadDropZoneComponent_Factory(t) {
  return new (t || UploadDropZoneComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1$1.LocalizationService));
};
UploadDropZoneComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UploadDropZoneComponent,
  selectors: [["kendo-uploaddropzone"]],
  hostVars: 3,
  hostBindings: function UploadDropZoneComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.dirAttribute);
      i0.ɵɵclassProp("k-external-dropzone", ctx.hostClass);
    }
  },
  inputs: {
    zoneId: "zoneId",
    icon: "icon",
    iconClass: "iconClass",
    svgIcon: "svgIcon"
  },
  exportAs: ["kendoUploadDropZone"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.uploaddropzone'
  }]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c65,
  decls: 7,
  vars: 5,
  consts: () => {
    let i18n_63;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc Sets the external drop-zone hint
       * @meaning kendo.uploaddropzone.externalDropFilesHere
       */
      const MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_64 = goog.getMsg("Drag and drop files here to upload");
      i18n_63 = MSG__BUILDS_CASTALYTICS_FUE_SNIPOCC_SNIPOCC_UI_NODE_MODULES__PROGRESS_KENDO_ANGULAR_UPLOAD_FESM2020_PROGRESS_KENDO_ANGULAR_UPLOAD_MJS_64;
    } else {
      i18n_63 = $localize`:kendo.uploaddropzone.externalDropFilesHere|Sets the external drop-zone hint:Drag and drop files here to upload`;
    }
    return [["kendoUploadDropZoneLocalizedMessages", "", "externalDropFilesHere", i18n_63], [1, "k-dropzone-inner", 3, "kendoUploadDropZone"], ["size", "xxxlarge", "innerCssClass", "k-dropzone-icon", 3, "name", "customFontClass", "svgIcon"], [1, "k-dropzone-hint"], [1, "k-dropzone-note"]];
  },
  template: function UploadDropZoneComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵelement(2, "kendo-icon-wrapper", 2);
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵtext(4);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "span", 4);
      i0.ɵɵprojection(6);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("kendoUploadDropZone", ctx.zoneId);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("name", ctx.iconClasses)("customFontClass", ctx.iconClass)("svgIcon", ctx.svgIcon);
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.textFor("externalDropFilesHere"));
    }
  },
  dependencies: [i4.IconWrapperComponent, LocalizedMessagesDirective, UploadDropZoneDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadDropZoneComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoUploadDropZone',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.uploaddropzone'
      }],
      selector: 'kendo-uploaddropzone',
      template: `
        <ng-container kendoUploadDropZoneLocalizedMessages
            i18n-externalDropFilesHere='kendo.uploaddropzone.externalDropFilesHere|Sets the external drop-zone hint'
            externalDropFilesHere='Drag and drop files here to upload'>
        </ng-container>
        <div class='k-dropzone-inner' [kendoUploadDropZone]="zoneId">
            <kendo-icon-wrapper
                size="xxxlarge"
                innerCssClass="k-dropzone-icon"
                [name]="iconClasses"
                [customFontClass]="iconClass"
                [svgIcon]="svgIcon"
            ></kendo-icon-wrapper>
            <span class="k-dropzone-hint">{{ textFor('externalDropFilesHere') }}</span>
            <span class="k-dropzone-note">
                <ng-content></ng-content>
            </span>
        </div>
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i1$1.LocalizationService
    }];
  }, {
    hostClass: [{
      type: HostBinding,
      args: ['class.k-external-dropzone']
    }],
    dirAttribute: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    zoneId: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    iconClass: [{
      type: Input
    }],
    svgIcon: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
const SHARED_DECLARATIONS = [DropZoneInternalDirective, FileListComponent, FileListItemDirective, FileListItemActionButtonComponent, FileListMultipleItemsComponent, FileListSingleItemComponent, FileSelectDirective, LocalizedMessagesDirective];
/**
 * @hidden
 */
const PUBLIC_DIRECTIVES = [FileTemplateDirective, FileInfoTemplateDirective, CustomMessagesComponent, UploadDropZoneDirective, UploadDropZoneComponent];
/**
 * @hidden
 */
class SharedModule {}
SharedModule.ɵfac = function SharedModule_Factory(t) {
  return new (t || SharedModule)();
};
SharedModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SharedModule
});
SharedModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ButtonsModule, ProgressBarModule, IconsModule], CommonModule, ButtonsModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      declarations: [PUBLIC_DIRECTIVES, SHARED_DECLARATIONS],
      exports: [PUBLIC_DIRECTIVES, SHARED_DECLARATIONS, CommonModule, ButtonsModule],
      imports: [CommonModule, ButtonsModule, ProgressBarModule, IconsModule]
    }]
  }], null, null);
})();
const FILESELECT_DECLARATIONS = [FileSelectComponent];
/**
 * Represents the [NgModule](https://angular.io/api/core/NgModule) definition for the FileSelect component.
 */
class FileSelectModule {}
FileSelectModule.ɵfac = function FileSelectModule_Factory(t) {
  return new (t || FileSelectModule)();
};
FileSelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FileSelectModule
});
FileSelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[SharedModule, IconsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSelectModule, [{
    type: NgModule,
    args: [{
      declarations: [FILESELECT_DECLARATIONS],
      exports: [PUBLIC_DIRECTIVES, FILESELECT_DECLARATIONS],
      imports: [SharedModule, IconsModule]
    }]
  }], null, null);
})();
const UPLOAD_DECLARATIONS = [UploadComponent, UploadActionButtonsComponent, UploadStatusTotalComponent];
/**
 * Represents the [NgModule](https://angular.io/api/core/NgModule) definition for the Upload component.
 */
class UploadModule {}
UploadModule.ɵfac = function UploadModule_Factory(t) {
  return new (t || UploadModule)();
};
UploadModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UploadModule
});
UploadModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[SharedModule, IconsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadModule, [{
    type: NgModule,
    args: [{
      declarations: [UPLOAD_DECLARATIONS],
      exports: [PUBLIC_DIRECTIVES, UPLOAD_DECLARATIONS],
      imports: [SharedModule, IconsModule]
    }]
  }], null, null);
})();

/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Uploads components.
 *
 * @example
 *
 * ```ts-no-run
 * import { UploadsModule } from '@progress/kendo-angular-upload';
 *
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { NgModule } from '@angular/core';
 *
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, UploadsModule],
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class UploadsModule {}
UploadsModule.ɵfac = function UploadsModule_Factory(t) {
  return new (t || UploadsModule)();
};
UploadsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UploadsModule
});
UploadsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [FileSelectModule, UploadModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadsModule, [{
    type: NgModule,
    args: [{
      exports: [FileSelectModule, UploadModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CancelEvent, ClearEvent, CustomMessagesComponent, DropZoneService, ErrorEvent, FileInfoTemplateDirective, FileListComponent, FileListItemActionButtonComponent, FileListItemBase, FileListMultipleItemsComponent, FileListSingleItemComponent, FileMap, FileSelectComponent, FileSelectDirective, FileSelectModule, FileState, FileTemplateDirective, NavigationService, PauseEvent, RemoveEvent, ResumeEvent, SelectEvent, SuccessEvent, UPLOAD_VALUE_ACCESSOR, UploadActionButtonsComponent, UploadComponent, UploadDropZoneComponent, UploadDropZoneDirective, UploadEvent, UploadModule, UploadProgressEvent, UploadService, UploadStatusTotalComponent, UploadsModule, validateFiles };