/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, NgModule } from '@angular/core';
import { register } from '@progress/kendo-ripple';
import { isDocumentAvailable } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-ripple',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1710925035,
  version: '15.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * Represents the Ripple container component.
 *
 * You can apply this directive to any container element.
 * The ripple effect will show on the following elements:
 * - Buttons
 * - Checkboxes
 * - Radio buttons
 *
 * @example
 * ```ts-no-run
 * <div kendoRippleContainer>
 *     <button kendoButton>Default Button</button>
 *
 *     <button kendoButton [primary]="true">Primary Button</button>
 * </div>
 * ```
 */
class RippleContainerDirective {
  constructor(renderer, element, ngZone) {
    this.renderer = renderer;
    this.element = element;
    this.ngZone = ngZone;
    this.isDisabled = false;
    this.removeListeners = () => {};
    validatePackage(packageMetadata);
  }
  /**
   * Provides an option to disable the ripple effect of the `kendoRippleContainer` element.
   * By default, `disabled` is set to `false`.
   */
  set disabled(disabled) {
    this.isDisabled = disabled;
    if (this.isDisabled) {
      this.removeListeners();
    } else {
      this.registerListeners();
    }
    this.renderer.setProperty(this.element.nativeElement, 'disabled', disabled);
  }
  get containerClass() {
    return true;
  }
  ngOnDestroy() {
    this.removeListeners();
  }
  ngAfterViewInit() {
    if (!this.isDisabled) {
      this.ngZone.runOutsideAngular(() => {
        this.registerListeners();
      });
    }
  }
  registerListeners() {
    if (!isDocumentAvailable()) {
      return;
    }
    this.removeListeners();
    const callback = register(this.element.nativeElement, [{
      selector: ".k-button:not(li)"
    }, {
      selector: ".k-list>.k-item",
      options: {
        global: true
      }
    }, {
      selector: ".k-checkbox,.k-radio",
      options: {
        events: ["focusin", "animationend", "click"]
      }
    }]);
    this.removeListeners = callback;
  }
}
RippleContainerDirective.ɵfac = function RippleContainerDirective_Factory(t) {
  return new (t || RippleContainerDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
RippleContainerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RippleContainerDirective,
  selectors: [["", "kendoRippleContainer", ""]],
  hostVars: 2,
  hostBindings: function RippleContainerDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-ripple-container", ctx.containerClass);
    }
  },
  inputs: {
    disabled: "disabled"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RippleContainerDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoRippleContainer]'
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    disabled: [{
      type: Input
    }],
    containerClass: [{
      type: HostBinding,
      args: ['class.k-ripple-container']
    }]
  });
})();
const COMPONENT_DIRECTIVES = [RippleContainerDirective];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Ripple directive.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Ripple module
 * import { RippleModule } from '@progress/kendo-angular-ripple';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, RippleModule], // import Ripple module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class RippleModule {}
RippleModule.ɵfac = function RippleModule_Factory(t) {
  return new (t || RippleModule)();
};
RippleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RippleModule
});
RippleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RippleModule, [{
    type: NgModule,
    args: [{
      declarations: [COMPONENT_DIRECTIVES],
      exports: [COMPONENT_DIRECTIVES]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RippleContainerDirective, RippleModule };