{
  "name": "snipocc-ui",
  "version": "0.0.0-dev.202405231013220200",
  "scripts": {
    "ng": "ng",
    "activate": "npx kendo-ui-license activate",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng serve --watch --configuration development",
    "test": "ng test",
    "check": "npm run lint && npm run test",
    "ci-check": "npm run lint && npm run ci-test",
    "ci-test": "ng test --no-watch --no-progress",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "packageManager": "npm@9.6.7",
  "dependencies": {
    "@angular/animations": "^17.0.6",
    "@angular/common": "^17.0.6",
    "@angular/compiler": "^17.0.6",
    "@angular/core": "^17.0.6",
    "@angular/forms": "^17.0.6",
    "@angular/localize": "^17.0.6",
    "@angular/platform-browser": "^17.0.6",
    "@angular/platform-browser-dynamic": "^17.0.6",
    "@angular/router": "^17.0.6",
    "@larscom/ngx-translate-module-loader": "^3.1.2",
    "@progress/kendo-angular-buttons": "~15.3.0",
    "@progress/kendo-angular-common": "~15.3.0",
    "@progress/kendo-angular-dateinputs": "~15.3.0",
    "@progress/kendo-angular-dropdowns": "~15.3.0",
    "@progress/kendo-angular-grid": "~15.3.0",
    "@progress/kendo-angular-icons": "~15.3.0",
    "@progress/kendo-angular-inputs": "~15.3.0",
    "@progress/kendo-angular-l10n": "~15.3.0",
    "@progress/kendo-angular-label": "~15.3.0",
    "@progress/kendo-angular-layout": "~15.3.0",
    "@progress/kendo-angular-listview": "~15.3.0",
    "@progress/kendo-angular-indicators": "~15.3.0",
    "@progress/kendo-angular-navigation": "~15.3.0",
    "@progress/kendo-angular-notification": "~15.3.0",
    "@progress/kendo-angular-popup": "~15.3.0",
    "@progress/kendo-angular-ripple": "~15.3.0",
    "@progress/kendo-angular-tooltip": "~15.3.0",
    "@progress/kendo-angular-typography": "~15.3.0",
    "@progress/kendo-licensing": "^1.3.5",
    "@progress/kendo-svg-icons": "^2.2.0",
    "@progress/kendo-theme-bootstrap": "^7.2.1",
    "@snipocc/api": "file:./libs/snipocc-api",
    "date-fns": "^2.30.0",
    "jose": "^5.1.3",
    "jwt-decode": "^4.0.0",
    "lodash-es": "^4.17.21",
    "rxjs": "~7.8.1",
    "utility-types": "^3.10.0",
    "tslib": "^2.3.0",
    "vlq": "^2.0.4",
    "yaml": "^2.3.4",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.6",
    "@angular-eslint/builder": "^17.1.1",
    "@angular-eslint/eslint-plugin": "^17.1.1",
    "@angular-eslint/eslint-plugin-template": "^17.1.1",
    "@angular-eslint/schematics": "^17.1.1",
    "@angular-eslint/template-parser": "^17.1.1",
    "@angular/cli": "~17.0.6",
    "@angular/compiler-cli": "^17.0.6",
    "@types/jasmine": "~4.3.0",
    "@types/lodash-es": "^4.17.12",
    "@typescript-eslint/eslint-plugin": "^6.14.0",
    "@typescript-eslint/parser": "^6.14.0",
    "eslint": "^8.55.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-firefox-launcher": "^2.1.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
