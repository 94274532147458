import {
  platformLogWritersobyk8c3gam2 as platformLogWriter,
  mutableLoggerConfigInit3a48t6gios6eh as mutableLoggerConfigInit,
  BaseLogger15vxnko5xe4r1 as BaseLogger,
} from './Kermit-kermit-core.mjs';
import {
  listOfvhqybd2zx248 as listOf,
  objectMeta213120oau977m as objectMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  VOID7hggqo3abtya as VOID,
  classMetawt99a3kyl3us as classMeta,
} from './kotlin-kotlin-stdlib.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class Logger extends BaseLogger {
  constructor(config, tag) {
    Companion_getInstance();
    tag = tag === VOID ? '' : tag;
    super(config);
    this.cm_1 = tag;
  }
  am() {
    return this.cm_1;
  }
}
class Companion extends Logger {
  constructor() {
    Companion_instance = null;
    super(mutableLoggerConfigInit(listOf(platformLogWriter())), '');
    Companion_instance = this;
  }
  am() {
    return get_defaultTag();
  }
}
//endregion
var Companion_instance;
function Companion_getInstance() {
  if (Companion_instance === VOID)
    new Companion();
  return Companion_instance;
}
function get_defaultTag() {
  return defaultTag;
}
var defaultTag;
//region block: post-declaration
setMetadataFor(Logger, 'Logger', classMeta);
setMetadataFor(Companion, 'Companion', objectMeta);
//endregion
//region block: init
defaultTag = '';
//endregion
//region block: exports
export {
  Companion_getInstance as Companion_getInstance2tq9586fmqdq3,
};
//endregion


