/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ButtonGroupModule, ButtonModule, DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LangSelectorComponent } from 'src/app/shared/components/lang-selector/lang-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { HasAnyRoleDirective } from '@shared/directives/has-any-role.directive';
import { UserActionsComponent } from './components/user-dropdown/user-actions.component';
import { IsLoggedInDirective } from './directives/is-logged-in.directive';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { UserPreferencesDialogComponent } from './components/user-preferences-dialog/user-preferences-dialog.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators'
import { UploadModule } from '@progress/kendo-angular-upload';


@NgModule({
  declarations: [
    LangSelectorComponent,
    HasAnyRoleDirective,
    UserActionsComponent,
    IsLoggedInDirective,
    UserPreferencesDialogComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    SVGIconModule,
    DropDownsModule,
    DropDownButtonModule,
    InputsModule,
    FormsModule,
    DialogsModule,
    ReactiveFormsModule,
    LayoutModule,
    PopupModule,
    LabelModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    LangSelectorComponent,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    SVGIconModule,
    ButtonModule,
    LabelModule,
    ButtonGroupModule,
    InputsModule,
    DialogsModule,
    GridModule,
    PopupModule,
    ListViewModule,
    TypographyModule,
    RippleModule,
    HasAnyRoleDirective,
    DropDownsModule,
    TooltipModule,
    DropDownButtonModule,
    UserActionsComponent,
    IsLoggedInDirective,
    IndicatorsModule,
    UploadModule
  ],
})
export class SharedModule {
}
