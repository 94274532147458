import {
  Exceptiondt2hlxn7j7vw as Exception,
  VOID7hggqo3abtya as VOID,
  captureStack1fzi4aczwc4hg as captureStack,
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  interfaceMeta1u1l5puptm1ve as interfaceMeta,
  Unit_instance1fbcbse1fwigr as Unit_instance,
  CoroutineImpl2sn3kjnwmfr10 as CoroutineImpl,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isInterface3d6p8outrmvmk as isInterface,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Charsets_getInstanceqs70pvl4noow as Charsets_getInstance,
  Companion_instance3aiov6iqkz7e8 as Companion_instance,
} from './ktor-ktor-io.mjs';
import {
  HttpHeaders_getInstanceelogg8fjd54u as HttpHeaders_getInstance,
  parseAndSortHeader33xgq5fx7y6j1 as parseAndSortHeader,
  NullBody_instance2i6w0hfghwfg0 as NullBody_instance,
} from './ktor-ktor-http.mjs';
import {
  asFlow3ngsnn5xpz8pw as asFlow,
  firstOrNull3jjcu7fygcopr as firstOrNull,
} from './kotlinx-coroutines-core.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class ContentConvertException extends Exception {
  static t2l(message, cause) {
    cause = cause === VOID ? null : cause;
    var $this = this.yf(message, cause);
    captureStack($this, $this.s2l_1);
    return $this;
  }
}
class ContentConverter {}
function serialize(contentType, charset, typeInfo, value, $completion) {
  return this.v2l(contentType, charset, typeInfo, value, $completion);
}
function serializeNullable(contentType, charset, typeInfo, value, $completion) {
  return this.u2l(contentType, charset, typeInfo, ensureNotNull(value), $completion);
}
class Configuration {}
function register$default(contentType, converter, configuration, $super) {
  var tmp;
  if (configuration === VOID) {
    tmp = Configuration$register$lambda;
  } else {
    tmp = configuration;
  }
  configuration = tmp;
  var tmp_0;
  if ($super === VOID) {
    this.x2l(contentType, converter, configuration);
    tmp_0 = Unit_instance;
  } else {
    tmp_0 = $super.x2l.call(this, contentType, converter, configuration);
  }
  return tmp_0;
}
class sam$kotlinx_coroutines_flow_FlowCollector$0 {
  constructor(function_0) {
    this.l2m_1 = function_0;
  }
  f17(value, $completion) {
    return this.l2m_1(value, $completion);
  }
}
class deserialize$o$collect$slambda extends CoroutineImpl {
  constructor($$this$unsafeFlow, $charset, $typeInfo, $body, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.u2m_1 = $$this$unsafeFlow;
    $box.v2m_1 = $charset;
    $box.w2m_1 = $typeInfo;
    $box.x2m_1 = $body;
    super(resultContinuation, $box);
  }
  d2n(value, $completion) {
    var tmp = this.e2n(value, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.d2n((!(p1 == null) ? isInterface(p1, ContentConverter) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            var tmp_0 = this;
            tmp_0.z2m_1 = this.u2m_1;
            var tmp_1 = this;
            tmp_1.a2n_1 = this.y2m_1;
            var tmp_2 = this;
            tmp_2.b2n_1 = this.a2n_1;
            this.te_1 = 1;
            suspendResult = this.b2n_1.w2l(this.v2m_1, this.w2m_1, this.x2m_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.c2n_1 = suspendResult;
            this.te_1 = 2;
            suspendResult = this.z2m_1.f17(this.c2n_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return Unit_instance;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  e2n(value, completion) {
    var i = new deserialize$o$collect$slambda(this.u2m_1, this.v2m_1, this.w2m_1, this.x2m_1, completion);
    i.y2m_1 = value;
    return i;
  }
}
class $collectCOROUTINE$1 extends CoroutineImpl {
  constructor(_this__u8e3s4, collector, resultContinuation) {
    super(resultContinuation);
    this.n2n_1 = _this__u8e3s4;
    this.o2n_1 = collector;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            var tmp_0 = this;
            tmp_0.p2n_1 = this.o2n_1;
            this.te_1 = 1;
            var tmp_1 = deserialize$o$collect$slambda_0(this.p2n_1, this.n2n_1.r2n_1, this.n2n_1.s2n_1, this.n2n_1.t2n_1, null);
            suspendResult = this.n2n_1.q2n_1.g17(new sam$kotlinx_coroutines_flow_FlowCollector$0(tmp_1), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_instance;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class _no_name_provided__qut3iv {
  constructor($this, $charset, $typeInfo, $body) {
    this.q2n_1 = $this;
    this.r2n_1 = $charset;
    this.s2n_1 = $typeInfo;
    this.t2n_1 = $body;
  }
  u2n(collector, $completion) {
    var tmp = new $collectCOROUTINE$1(this, collector, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  g17(collector, $completion) {
    return this.u2n(collector, $completion);
  }
}
class deserialize$slambda extends CoroutineImpl {
  constructor($body, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.d2o_1 = $body;
    super(resultContinuation, $box);
  }
  f2o(it, $completion) {
    var tmp = this.g2o(it, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.f2o((p1 == null ? true : !(p1 == null)) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          return !(this.e2o_1 == null) ? true : this.d2o_1.q1e();
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  g2o(it, completion) {
    var i = new deserialize$slambda(this.d2o_1, completion);
    i.e2o_1 = it;
    return i;
  }
}
class $deserializeCOROUTINE$0 extends CoroutineImpl {
  constructor(_this__u8e3s4, body, typeInfo, charset, resultContinuation) {
    super(resultContinuation);
    this.h2m_1 = _this__u8e3s4;
    this.i2m_1 = body;
    this.j2m_1 = typeInfo;
    this.k2m_1 = charset;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            var this_0 = asFlow(this.h2m_1);
            var tmp_0 = new _no_name_provided__qut3iv(this_0, this.k2m_1, this.j2m_1, this.i2m_1);
            suspendResult = firstOrNull(tmp_0, deserialize$slambda_0(this.i2m_1, null), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var result = suspendResult;
            var tmp_1;
            if (!(result == null)) {
              tmp_1 = result;
            } else {
              if (!this.i2m_1.q1e()) {
                tmp_1 = this.i2m_1;
              } else {
                var tmp0_safe_receiver = this.j2m_1.v24_1;
                if ((tmp0_safe_receiver == null ? null : tmp0_safe_receiver.k9()) === true) {
                  tmp_1 = NullBody_instance;
                } else {
                  throw ContentConvertException.t2l('No suitable converter found for ' + this.j2m_1);
                }
              }
            }

            return tmp_1;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
//endregion
function Configuration$register$lambda($this$null) {
  return Unit_instance;
}
function deserialize(_this__u8e3s4, body, typeInfo, charset, $completion) {
  var tmp = new $deserializeCOROUTINE$0(_this__u8e3s4, body, typeInfo, charset, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function suitableCharset(_this__u8e3s4, defaultCharset) {
  defaultCharset = defaultCharset === VOID ? Charsets_getInstance().e1s_1 : defaultCharset;
  var tmp0_elvis_lhs = suitableCharsetOrNull(_this__u8e3s4, defaultCharset);
  return tmp0_elvis_lhs == null ? defaultCharset : tmp0_elvis_lhs;
}
function suitableCharsetOrNull(_this__u8e3s4, defaultCharset) {
  defaultCharset = defaultCharset === VOID ? Charsets_getInstance().e1s_1 : defaultCharset;
  var tmp0_iterator = parseAndSortHeader(_this__u8e3s4.z1z(HttpHeaders_getInstance().e28_1)).r();
  while (tmp0_iterator.s()) {
    var charset = tmp0_iterator.u().rk();
    if (charset === '*')
      return defaultCharset;
    else if (Companion_instance.z1v(charset))
      return Companion_instance.y1v(charset);
  }
  return null;
}
function deserialize$o$collect$slambda_0($$this$unsafeFlow, $charset, $typeInfo, $body, resultContinuation) {
  var i = new deserialize$o$collect$slambda($$this$unsafeFlow, $charset, $typeInfo, $body, resultContinuation);
  var l = function (value, $completion) {
    return i.d2n(value, $completion);
  };
  l.$arity = 1;
  return l;
}
function deserialize$slambda_0($body, resultContinuation) {
  var i = new deserialize$slambda($body, resultContinuation);
  var l = function (it, $completion) {
    return i.f2o(it, $completion);
  };
  l.$arity = 1;
  return l;
}
//region block: post-declaration
setMetadataFor(ContentConvertException, 'ContentConvertException', classMeta);
setMetadataFor(ContentConverter, 'ContentConverter', interfaceMeta, VOID, VOID, VOID, VOID, VOID, [4, 3]);
setMetadataFor(Configuration, 'Configuration', interfaceMeta);
setMetadataFor(sam$kotlinx_coroutines_flow_FlowCollector$0, 'sam$kotlinx_coroutines_flow_FlowCollector$0', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(deserialize$o$collect$slambda, 'deserialize$o$collect$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor($collectCOROUTINE$1, '$collectCOROUTINE$1', classMeta);
setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(deserialize$slambda, 'deserialize$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor($deserializeCOROUTINE$0, '$deserializeCOROUTINE$0', classMeta);
//endregion
//region block: exports
export {
  serialize as serialize2bxscnfh96hli,
  deserialize as deserialize3kqe4vxpbxz1,
  register$default as register$default1vru4l6f6i5dq,
  Configuration as Configuration20xgygxdzhlk5,
  ContentConverter as ContentConverteryzo4k0ursexh,
  suitableCharset as suitableCharset1jgdcpdzbzgzn,
};
//endregion


