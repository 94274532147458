import {
  interfaceMeta1u1l5puptm1ve as interfaceMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  VOID7hggqo3abtya as VOID,
  getStringHashCode26igk1bx568vk as getStringHashCode,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  classMetawt99a3kyl3us as classMeta,
  THROW_IAE23kobfj9wdoxr as THROW_IAE,
  endsWith3cq61xxngobwh as endsWith,
  listOf1jh22dvmctj1r as listOf,
  Collection1k04j3hzsbod0 as Collection,
  isInterface3d6p8outrmvmk as isInterface,
  Unit_instance1fbcbse1fwigr as Unit_instance,
  Enum3alwj03lh1n41 as Enum,
  equals2v6cggk171b6e as equals,
  defineProp3hxgpk2knu2px as defineProp,
  prependIndentkl9wuvcodvmu as prependIndent,
  createThis2j2avj17cvnv2 as createThis,
  getKClassFromExpression3vpejubogshaw as getKClassFromExpression,
  ensureNotNull1e947j3ixpazm as ensureNotNull,
  isBlank1dvkhjjvox3p0 as isBlank,
  protoOf180f3jzyo7rfj as protoOf,
  objectMeta213120oau977m as objectMeta,
  throwUninitializedPropertyAccessExceptionyynx7gkm73wd as throwUninitializedPropertyAccessException,
  CoroutineImpl2sn3kjnwmfr10 as CoroutineImpl,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  getKClass1s3j9wy1cofik as getKClass,
  arrayOf1akklvh2at202 as arrayOf,
  createKType1lgox3mzhchp5 as createKType,
  CancellationException3b36o9qz53rgr as CancellationException,
  newThrowablezl37abp36p5f as newThrowable,
  toLongw1zpgk99d84b as toLong,
  Exceptiondt2hlxn7j7vw as Exception,
  captureStack1fzi4aczwc4hg as captureStack,
  safeCast3kjwtqnqhm5pg as safeCast,
  encodeToByteArray1onwao0uakjfh as encodeToByteArray,
  numberToLong1a4cndvg6c52s as numberToLong,
  Companion_instance2oawqq9qiaris as Companion_instance,
  _Result___init__impl__xyqfz81wclroc5pw7j2 as _Result___init__impl__xyqfz8,
  createFailure8paxfkfa5dc7 as createFailure,
  intercepted2ogpsikxxj4u0 as intercepted,
  returnIfSuspendedqak8u4r448cu as returnIfSuspended,
  EmptyCoroutineContext_getInstance31fow51ayy30t as EmptyCoroutineContext_getInstance,
  contains3ue2qo8xhmpf1 as contains,
  toInt2q8uldh7sc951 as toInt,
  Regexxgw0gjiagf4z as Regex,
  KProperty02ce7r476m8633 as KProperty0,
  getPropertyCallableRef1ajb9in178r5r as getPropertyCallableRef,
  lazy2hsh8ze7j6ikd as lazy,
  ArrayList3it5z8td81qkl as ArrayList,
  IllegalArgumentException2asla15b5jaob as IllegalArgumentException,
  toString1pkumu07cwy4m as toString,
  List3hktaavzmj137 as List,
  PrimitiveClasses_getInstance2v63zn04dtq03 as PrimitiveClasses_getInstance,
  createInvariantKTypeProjection3sfd0u0y62ozd as createInvariantKTypeProjection,
  to2cs3ny02qtbcb as to,
} from './kotlin-kotlin-stdlib.mjs';
import {
  CoroutineScopefcb5f5dwqcas as CoroutineScope,
  await20nhgj9iqzkt as await_0,
  MainScope1gi1r4abhrtmm as MainScope,
  promise1ky6tawqaxbt4 as promise,
  get_MODE_CANCELLABLE3br9qqv7xeh9e as get_MODE_CANCELLABLE,
  CancellableContinuationImpl1cx201opicavg as CancellableContinuationImpl,
  Dispatchers_getInstanceitgtkvqfcnx3 as Dispatchers_getInstance,
} from './kotlinx-coroutines-core.mjs';
import {
  JsType_instance744k4z46bwev as JsType_instance,
  typeInfoImpl3ju54ew51hieg as typeInfoImpl,
  toMap1agg4casweo9p as toMap,
} from './ktor-ktor-utils.mjs';
import {
  HttpResponse1532ob1hsse1y as HttpResponse,
  complete3saw0t5k33pyg as complete,
  unwrapCancellationException1zvbmufui4i9c as unwrapCancellationException,
  bodyAsChannel19pdptkp8oxbs as bodyAsChannel,
  get_DEFAULT_HTTP_BUFFER_SIZE29p7dxlmmendg as get_DEFAULT_HTTP_BUFFER_SIZE,
  ResponseException2ofl6x4wye9sn as ResponseException,
  ChannelProvidereiny599tk35h as ChannelProvider,
  Sender4pyqqer9k7up as Sender,
  HttpRequestBuilder15f2nnx9sjuv1 as HttpRequestBuilder,
  headers24pki7ce1busg as headers,
  Plugin_getInstanceqq5mcce07h6c as Plugin_getInstance,
  plugin3vxt624hnu3pv as plugin,
  Js_instance1k6sy4isx8lk as Js_instance,
  HttpClient2x7qc5z8fmeal as HttpClient,
  HttpResponseValidator2mt2o9hlm87gn as HttpResponseValidator,
  HttpRequest3fsc4149kgwfg as HttpRequest,
  bodyAsText1is16t8kuttw9 as bodyAsText,
  url2l6iw5ri21nxb as url,
  HttpStatement3zxb33q8lku as HttpStatement,
  accept2gi3b7wj4jds9 as accept,
  formData2a6dpfa77m3ga as formData,
  MultiPartFormDataContent67pixgga9hu4 as MultiPartFormDataContent,
  parametera70jh5jb44wd as parameter,
} from './ktor-ktor-client-core.mjs';
import {
  contentType317fn4f991q9a as contentType,
  Companion_getInstancecf9b3ybko8sp as Companion_getInstance,
  Text_getInstance1qa6l8g2r3h9g as Text_getInstance,
  ByteArrayContent9zol65b22hp0 as ByteArrayContent,
  Application_getInstanceq87g3bor693u as Application_getInstance,
  headers1dh5cg56ach6i as headers_0,
  ReadChannelContentz1amb4hnpqp4 as ReadChannelContent,
  HttpHeaders_getInstanceelogg8fjd54u as HttpHeaders_getInstance,
  escapeIfNeeded3advl4reehtml as escapeIfNeeded,
  append2z8giuo6jcxrf as append,
  appendPathSegments3o1ebam9yrnq5 as appendPathSegments,
  contentType1lwcfjsjo0z8g as contentType_0,
  OutgoingContent3t2ohmyam9o76 as OutgoingContent,
  NullBody_instance2i6w0hfghwfg0 as NullBody_instance,
  Companion_getInstance1p3cpld7r1jz3 as Companion_getInstance_0,
  MultiPart_getInstance1zzge3g94afj6 as MultiPart_getInstance,
} from './ktor-ktor-http.mjs';
import { Companion_getInstance2tq9586fmqdq3 as Companion_getInstance_1 } from './Kermit-kermit.mjs';
import {
  Severity_Info_getInstance1vh392miohgvt as Severity_Info_getInstance,
  Severity_Error_getInstancebf6hy57pmkme as Severity_Error_getInstance,
  Severity_Debug_getInstance18zvrvco9dvwq as Severity_Debug_getInstance,
  Severity_Warn_getInstance8tpow653hun6 as Severity_Warn_getInstance,
} from './Kermit-kermit-core.mjs';
import {
  readUTF8Line3qxxa7ehhfnmh as readUTF8Line,
  readBytes2b47ed7nra7rg as readBytes,
  WriterScope3b0bo1enaee6b as WriterScope,
  writeFullyuorxug1itxv3 as writeFully,
  writer1eia5its2a1fh as writer,
} from './ktor-ktor-io.mjs';
import {
  serialize2bxscnfh96hli as serialize,
  ContentConverteryzo4k0ursexh as ContentConverter,
} from './ktor-ktor-serialization.mjs';
import {
  PublishSubject2fg46ssyv4pyl as PublishSubject,
  subscribe3pb2qdcdkj367 as subscribe,
} from './Reaktive-reaktive.mjs';
import {
  Companion_getInstance1jrxni30zx3hj as Companion_getInstance_2,
  LogLevel_INFO_getInstance1vzce5sdbg6wx as LogLevel_INFO_getInstance,
} from './ktor-ktor-client-logging.mjs';
import { Plugin_getInstance21w9qppp8svkg as Plugin_getInstance_0 } from './ktor-ktor-client-content-negotiation.mjs';
//region block: imports
var imul = Math.imul;
//endregion
//region block: pre-declaration
class CommonApi {}
function bar() {
  return 'bar';
}
function quz() {
  return 5;
}
class HelloAngular {
  constructor(name) {
    this.name = name;
  }
  ed() {
    return this.name;
  }
  rk() {
    return this.name;
  }
  p44(name) {
    return new HelloAngular(name);
  }
  copy(name, $super) {
    name = name === VOID ? this.name : name;
    return this.p44(name);
  }
  toString() {
    return 'HelloAngular(name=' + this.name + ')';
  }
  hashCode() {
    return getStringHashCode(this.name);
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof HelloAngular))
      return false;
    var tmp0_other_with_cast = other instanceof HelloAngular ? other : THROW_CCE();
    if (!(this.name === tmp0_other_with_cast.name))
      return false;
    return true;
  }
}
class AiLabel {}
class AuthRequest {}
class AuthResult {}
class _AuthResult {
  constructor(accessToken, expiresIn) {
    this.v44_1 = accessToken;
    this.w44_1 = expiresIn;
  }
  t44() {
    return this.v44_1;
  }
  u44() {
    return this.w44_1;
  }
  toString() {
    return 'AuthResult(accessToken=' + this.v44_1 + ', expiresIn=' + this.w44_1 + ')';
  }
  hashCode() {
    var result = getStringHashCode(this.v44_1);
    result = imul(result, 31) + this.w44_1 | 0;
    return result;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof _AuthResult))
      return false;
    var tmp0_other_with_cast = other instanceof _AuthResult ? other : THROW_CCE();
    if (!(this.v44_1 === tmp0_other_with_cast.v44_1))
      return false;
    if (!(this.w44_1 === tmp0_other_with_cast.w44_1))
      return false;
    return true;
  }
  get accessToken() {
    return this.t44();
  }
  get expiresIn() {
    return this.u44();
  }
}
class TestFile {}
class IFileLabeled {}
class FileType extends Enum {
  constructor(name, ordinal, couldMatchP) {
    super(name, ordinal);
    this.h45_1 = couldMatchP;
    this.withPrefix = 'FILETYPE_' + this.cd_1;
  }
  i45() {
    return this.withPrefix;
  }
  matchFilename(filename) {
    return this.h45_1(filename);
  }
  get name() {
    return this.ed();
  }
  get ordinal() {
    return this.fd();
  }
}
class ImagePoCTest {}
class PointOfCareTest {}
class ProblemDetail {}
class Project {}
class IReaderDataLabeled {}
class Roles extends Enum {
  constructor(name, ordinal, inherits) {
    super(name, ordinal);
    this.inherits = inherits;
    this.withPrefix = prependIndent(this.cd_1, 'ROLE_');
  }
  w45() {
    return this.inherits;
  }
  i45() {
    return this.withPrefix;
  }
  get name() {
    return this.ed();
  }
  get ordinal() {
    return this.fd();
  }
}
class TestType {}
class User {}
class UserRegistration {}
class I18NKey {
  constructor() {
    return new.target.d46();
  }
  static d46() {
    return createThis(this);
  }
  f46() {
    return '.';
  }
  toString() {
    return this.e46();
  }
  get sep() {
    return this.f46();
  }
}
class I18NKeyNode extends I18NKey {
  constructor(prefix, suffix) {
    return new.target.i46(prefix, suffix);
  }
  static i46(prefix, suffix) {
    prefix = prefix === VOID ? I18NSelfNode_getInstance() : prefix;
    suffix = suffix === VOID ? Companion_instance_0.j46() : suffix;
    var $this = this.d46();
    $this.g46_1 = prefix;
    $this.h46_1 = suffix;
    var tmp = $this.g46_1;
    if (tmp instanceof I18NSelfNode) {
      var tmp_0 = $this;
      tmp_0.g46_1 = I18NKeyLeaf.l46(I18NKeyNode$lambda($this));
    }
    return $this;
  }
  m46() {
    return this.h46_1;
  }
  static fromSuffix(prefix, suffix) {
    return this.i46(prefix, I18NKeyLeaf.l46(I18NKeyNode$_init_$lambda_r11c2g(suffix)));
  }
  static fromPrefix(prefix) {
    return this.i46(I18NKeyLeaf.l46(I18NKeyNode$_init_$lambda_r11c2g_0(prefix)));
  }
  e46() {
    var tmp;
    // Inline function 'kotlin.text.isNotBlank' call
    var this_0 = this.g46_1.e46();
    if (!isBlank(this_0)) {
      var tmp_0;
      // Inline function 'kotlin.text.isNotBlank' call
      var this_1 = this.suffix.e46();
      if (!isBlank(this_1)) {
        tmp_0 = this.g46_1.e46() + this.sep + this.suffix.e46();
      } else {
        tmp_0 = this.g46_1.e46();
      }
      tmp = tmp_0;
    } else {
      tmp = this.suffix.e46();
    }
    return tmp;
  }
  get suffix() {
    return this.m46();
  }
}
class I18NEnum$fullKey$node$1 extends I18NKeyNode {
  static p46(this$0) {
    var tmp = this$0.parent;
    // Inline function 'kotlin.text.lowercase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = ensureNotNull(getKClassFromExpression(this$0).u8()).toLowerCase();
    return this.fromSuffix(prefixKey(tmp, tmp$ret$1), this$0.key);
  }
}
class I18NEnum {}
function fullKey() {
  var node = I18NEnum$fullKey$node$1.p46(this);
  return node.toString();
}
class Auth extends Enum {
  constructor(name, ordinal, key, parent) {
    parent = parent === VOID ? Error_getInstance() : parent;
    super(name, ordinal);
    this.t46_1 = key;
    this.u46_1 = parent;
  }
  t2() {
    return this.t46_1;
  }
  q46() {
    return this.u46_1;
  }
  get key() {
    return this.t2();
  }
  get parent() {
    return this.q46();
  }
  get name() {
    return this.ed();
  }
  get ordinal() {
    return this.fd();
  }
}
class Error_0 extends I18NKeyNode {
  static x46() {
    Error_instance = null;
    var $this = this.i46();
    Error_instance = $this;
    return $this;
  }
}
class Keys {}
class I18NSelfNode extends I18NKey {
  static y46() {
    I18NSelfNode_instance = null;
    var $this = this.d46();
    I18NSelfNode_instance = $this;
    return $this;
  }
  e46() {
    return '';
  }
  toString() {
    return 'I18NSelfNode';
  }
  hashCode() {
    return 484452852;
  }
  equals(other) {
    if (this === other)
      return true;
    if (!(other instanceof I18NSelfNode))
      return false;
    other instanceof I18NSelfNode || THROW_CCE();
    return true;
  }
}
class Companion {
  j46() {
    return I18NKeyLeaf.l46(I18NKeyLeaf$Companion$default$lambda);
  }
}
class I18NKeyLeaf extends I18NKey {
  static l46(key) {
    var $this = this.d46();
    $this.k46_1 = key;
    return $this;
  }
  e46() {
    return this.k46_1();
  }
}
class prefixKey$1 extends I18NKeyNode {
  static b47($prefix, $suffix) {
    return this.fromSuffix($prefix, $suffix);
  }
}
class ApiResponseWithBody$stream$lambda$lambda$o$init$slambda extends CoroutineImpl {
  constructor(this$0, this$1, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.q47_1 = this$0;
    $box.r47_1 = this$1;
    super(resultContinuation, $box);
  }
  y1m($this$scope, $completion) {
    var tmp = this.z1m($this$scope, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.y1m((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 14;
            this.te_1 = 1;
            continue $sm;
          case 1:
            this.te_1 = 2;
            suspendResult = this.r47_1.d48_1(this.s47_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.u47_1 = suspendResult;
            this.te_1 = 3;
            continue $sm;
          case 3:
            this.ue_1 = 9;
            this.te_1 = 4;
            suspendResult = this.u47_1.w3n(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            this.w47_1 = suspendResult;
            this.te_1 = 5;
            continue $sm;
          case 5:
            this.ue_1 = 8;
            var tmp_0 = this;
            tmp_0.y47_1 = this.w47_1;
            this.te_1 = 6;
            var tmp_1 = this.y47_1.k2u();
            var tmp_2 = JsType_instance;
            var tmp_3 = getKClass(HttpResponse);
            var tmp_4;
            try {
              tmp_4 = createKType(getKClass(HttpResponse), arrayOf([]), false);
            } catch ($p) {
              var tmp_5;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_5 = null;
              } else {
                throw $p;
              }
              tmp_4 = tmp_5;
            }

            suspendResult = tmp_1.p2t(typeInfoImpl(tmp_2, tmp_3, tmp_4), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 6:
            var tmp_6 = this;
            tmp_6.x47_1 = suspendResult instanceof HttpResponse ? suspendResult : THROW_CCE();
            this.te_1 = 7;
            continue $sm;
          case 7:
            this.z47_1 = this.x47_1;
            this.ue_1 = 9;
            complete(this.w47_1);
            this.t47_1 = this.z47_1;
            this.ue_1 = 14;
            this.te_1 = 12;
            var tmp_7 = this;
            continue $sm;
          case 8:
            this.ue_1 = 9;
            this.a48_1 = this.we_1;
            complete(this.w47_1);
            throw this.a48_1;
          case 9:
            this.ue_1 = 14;
            var tmp_8 = this.we_1;
            if (tmp_8 instanceof CancellationException) {
              this.b48_1 = this.we_1;
              throw unwrapCancellationException(this.b48_1);
            } else {
              throw this.we_1;
            }

          case 10:
            this.ue_1 = 14;
            if (false) {
              this.te_1 = 3;
              continue $sm;
            }

            this.te_1 = 11;
            continue $sm;
          case 11:
            this.t47_1 = this.v47_1;
            if (false) {
              this.te_1 = 1;
              continue $sm;
            }

            this.te_1 = 12;
            continue $sm;
          case 12:
            this.c48_1 = this.t47_1;
            this.q47_1.d47_1 = this.c48_1;
            this.te_1 = 13;
            suspendResult = bodyAsChannel(_get_response__7wqlp2(this.q47_1), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 13:
            var ARGUMENT = suspendResult;
            this.q47_1.c47_1 = ARGUMENT;
            var tmp_9 = this.q47_1;
            var tmp0_elvis_lhs = contentType(_get_response__7wqlp2(this.q47_1));
            tmp_9.e47_1 = tmp0_elvis_lhs == null ? Companion_getInstance().a26_1 : tmp0_elvis_lhs;
            var this_0 = Companion_getInstance_1();
            var tag = this_0.am();
            var severity = Severity_Info_getInstance();
            if (this_0.dl().xl().gd(severity) <= 0) {
              this_0.el(severity, tag, null, 'Stream ContentType: ' + _get_contentType__u2ypgi(this.q47_1).d26_1 + '/' + _get_contentType__u2ypgi(this.q47_1).e26_1);
            }

            this.q47_1.f47_1 = true;
            return Unit_instance;
          case 14:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 14) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  z1m($this$scope, completion) {
    var i = new ApiResponseWithBody$stream$lambda$lambda$o$init$slambda(this.q47_1, this.r47_1, completion);
    i.s47_1 = $this$scope;
    return i;
  }
}
class ApiResponseWithBody$stream$1$1$1$done$1 {
  constructor() {
    this.done = true;
    this.value = undefined;
  }
  e48() {
    return this.done;
  }
  u2() {
    return this.value;
  }
}
class ApiResponseWithBody$stream$1$1$1$resolve$1 {
  constructor($value) {
    this.done = false;
    this.value = $value;
  }
  e48() {
    return this.done;
  }
  u2() {
    return this.value;
  }
}
class ApiResponseWithBody$stream$lambda$lambda$o$read$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.n48_1 = this$0;
    super(resultContinuation, $box);
  }
  r48($this$scope, $completion) {
    var tmp = this.z1m($this$scope, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.r48((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 9;
            if (!this.n48_1.f47_1) {
              this.te_1 = 1;
              suspendResult = await_0(init(this.n48_1), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 2;
              continue $sm;
            }

          case 1:
            this.te_1 = 2;
            continue $sm;
          case 2:
            if (_get_channel__c6e3yq(this.n48_1).q1e())
              return this.n48_1.g47_1;
            this.p48_1 = _get_contentType__u2ypgi(this.n48_1);
            if (this.p48_1.equals(Companion_getInstance().o25('application/x-ndjson'))) {
              this.te_1 = 5;
              suspendResult = readUTF8Line(_get_channel__c6e3yq(this.n48_1), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              if (this.p48_1.equals(Text_getInstance().m27_1)) {
                this.te_1 = 4;
                suspendResult = _get_channel__c6e3yq(this.n48_1).v1l(toLong(get_DEFAULT_HTTP_BUFFER_SIZE()), this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              } else {
                this.te_1 = 3;
                suspendResult = _get_channel__c6e3yq(this.n48_1).v1l(toLong(get_DEFAULT_HTTP_BUFFER_SIZE()), this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              }
            }

          case 3:
            var packet = suspendResult;
            if (packet.u1j())
              return this.n48_1.g47_1;
            var bytes = readBytes(packet);
            this.q48_1 = resolve(this.n48_1, bytes);
            this.te_1 = 8;
            continue $sm;
          case 4:
            var packet_0 = suspendResult;
            if (packet_0.u1j())
              return this.n48_1.g47_1;
            var text = packet_0.s1r();
            this.q48_1 = resolve(this.n48_1, text);
            this.te_1 = 8;
            continue $sm;
          case 5:
            var chunk = suspendResult;
            if (chunk == null ? true : isBlank(chunk))
              return this.n48_1.g47_1;
            var TRY_RESULT;
            this.ue_1 = 6;
            var parsed = JSON.parse(chunk);
            TRY_RESULT = resolve(this.n48_1, parsed);
            this.ue_1 = 9;
            this.te_1 = 7;
            continue $sm;
          case 6:
            this.ue_1 = 9;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof Exception) {
              var e = this.we_1;
              throw newThrowable(VOID, e);
            } else {
              throw this.we_1;
            }

          case 7:
            this.ue_1 = 9;
            this.q48_1 = TRY_RESULT;
            this.te_1 = 8;
            continue $sm;
          case 8:
            return this.q48_1;
          case 9:
            throw this.we_1;
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.ue_1 === 9) {
          throw e_0;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e_0;
        }
      }
     while (true);
  }
  z1m($this$scope, completion) {
    var i = new ApiResponseWithBody$stream$lambda$lambda$o$read$slambda(this.n48_1, completion);
    i.o48_1 = $this$scope;
    return i;
  }
}
class ApiResponseWithBody$stream$1$1$1 {
  constructor(this$0) {
    this.h47_1 = this$0;
    this.f47_1 = false;
    var tmp = this;
    tmp.closed = new Promise(ApiResponseWithBody$stream$lambda$lambda$o$closed$lambda(this));
    var tmp_0 = this;
    tmp_0.g47_1 = new ApiResponseWithBody$stream$1$1$1$done$1();
  }
  s48(_set____db54di) {
    this.closed = _set____db54di;
  }
  c1d() {
    return this.closed;
  }
  cancel(reason) {
    return new Promise(ApiResponseWithBody$stream$lambda$lambda$o$cancel$lambda(this, reason));
  }
  read() {
    return scope(ApiResponseWithBody$stream$lambda$lambda$o$read$slambda_0(this, null));
  }
  releaseLock() {
  }
}
class ApiResponseWithBody$response$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.b49_1 = this$0;
    super(resultContinuation, $box);
  }
  g49($this$scope, $completion) {
    var tmp = this.z1m($this$scope, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.g49((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 5;
            this.ue_1 = 4;
            this.te_1 = 1;
            suspendResult = this.b49_1.d48_1(this.c49_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.e49_1 = suspendResult;
            this.te_1 = 2;
            suspendResult = this.e49_1.y3o(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.f49_1 = suspendResult;
            this.te_1 = 3;
            suspendResult = bodyAsType(this.f49_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            var ARGUMENT = suspendResult;
            this.d49_1 = new Body(this.f49_1, ARGUMENT);
            this.ue_1 = 5;
            this.te_1 = 6;
            continue $sm;
          case 4:
            this.ue_1 = 5;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof ProblemDetailException) {
              var e = this.we_1;
              var tmp_1 = this;
              throw new HttpError(e.x2z_1, e.m49_1);
            } else {
              var tmp_2 = this.we_1;
              if (tmp_2 instanceof Exception) {
                var e_0 = this.we_1;
                var tmp_3 = this;
                throw new NetworkError();
              } else {
                throw this.we_1;
              }
            }

          case 5:
            throw this.we_1;
          case 6:
            this.ue_1 = 5;
            return this.d49_1;
        }
      } catch ($p) {
        var e_1 = $p;
        if (this.ue_1 === 5) {
          throw e_1;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e_1;
        }
      }
     while (true);
  }
  z1m($this$scope, completion) {
    var i = new ApiResponseWithBody$response$slambda(this.b49_1, completion);
    i.c49_1 = $this$scope;
    return i;
  }
}
class ApiResponseWithBody {
  constructor(statement) {
    this.d48_1 = statement;
  }
  body() {
    var tmp = this.response();
    return tmp.then(ApiResponseWithBody$body$lambda);
  }
  stream() {
    return fromDynamic(ApiResponseWithBody$stream$lambda(this));
  }
  response() {
    return scope(ApiResponseWithBody$response$slambda_0(this, null));
  }
}
class ApiResponse$response$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.w49_1 = this$0;
    super(resultContinuation, $box);
  }
  a4a($this$scope, $completion) {
    var tmp = this.z1m($this$scope, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.a4a((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            this.ue_1 = 3;
            this.te_1 = 1;
            suspendResult = this.w49_1.b4a_1(this.x49_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.z49_1 = suspendResult;
            this.te_1 = 2;
            suspendResult = this.z49_1.y3o(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var response = suspendResult;
            this.y49_1 = new Empty(response);
            this.ue_1 = 4;
            this.te_1 = 5;
            continue $sm;
          case 3:
            this.ue_1 = 4;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof ProblemDetailException) {
              var e = this.we_1;
              var tmp_1 = this;
              throw new HttpError(e.x2z_1, e.m49_1);
            } else {
              var tmp_2 = this.we_1;
              if (tmp_2 instanceof Exception) {
                var e_0 = this.we_1;
                var tmp_3 = this;
                throw new NetworkError();
              } else {
                throw this.we_1;
              }
            }

          case 4:
            throw this.we_1;
          case 5:
            this.ue_1 = 4;
            return this.y49_1;
        }
      } catch ($p) {
        var e_1 = $p;
        if (this.ue_1 === 4) {
          throw e_1;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e_1;
        }
      }
     while (true);
  }
  z1m($this$scope, completion) {
    var i = new ApiResponse$response$slambda(this.w49_1, completion);
    i.x49_1 = $this$scope;
    return i;
  }
}
class ApiResponse {
  constructor(statement) {
    this.b4a_1 = statement;
  }
  response() {
    return scope(ApiResponse$response$slambda_0(this, null));
  }
}
class ProblemDetailException extends ResponseException {
  static c4a(response, detail) {
    var $this = this.z2z(response, detail.detail);
    captureStack($this, $this.n49_1);
    $this.m49_1 = detail;
    return $this;
  }
}
class HttpStatus {
  constructor(code, description) {
    this.code = code;
    this.description = description;
  }
  d4a() {
    return this.code;
  }
  r44() {
    return this.description;
  }
}
class HttpVersion {
  constructor(major, minor) {
    this.major = major;
    this.minor = minor;
  }
  e4a() {
    return this.major;
  }
  f4a() {
    return this.minor;
  }
}
class HttpResponse_0 {
  constructor(originalResponse) {
    var tmp = this;
    var tmp1_safe_receiver = originalResponse == null ? null : originalResponse.w2c();
    tmp.headers = tmp1_safe_receiver == null ? null : toMap(tmp1_safe_receiver);
    var tmp_0 = this;
    var tmp1_safe_receiver_0 = originalResponse == null ? null : originalResponse.dn();
    var tmp_1;
    if (tmp1_safe_receiver_0 == null) {
      tmp_1 = null;
    } else {
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'de.castalytics.snipocc.HttpResponse.status.<anonymous>' call
      tmp_1 = new HttpStatus(tmp1_safe_receiver_0.r2f_1, tmp1_safe_receiver_0.s2f_1);
    }
    tmp_0.status = tmp_1;
    var tmp_2 = this;
    var tmp1_safe_receiver_1 = originalResponse == null ? null : originalResponse.v2u();
    var tmp_3;
    if (tmp1_safe_receiver_1 == null) {
      tmp_3 = null;
    } else {
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'de.castalytics.snipocc.HttpResponse.version.<anonymous>' call
      tmp_3 = new HttpVersion(tmp1_safe_receiver_1.m2d_1, tmp1_safe_receiver_1.n2d_1);
    }
    tmp_2.version = tmp_3;
  }
  w2c() {
    return this.headers;
  }
  dn() {
    return this.status;
  }
  v2u() {
    return this.version;
  }
}
class Success extends HttpResponse_0 {}
class Body extends Success {
  constructor(originalResponse, body) {
    super(originalResponse);
    this.body = body;
  }
  g4a() {
    return this.body;
  }
}
class Empty extends Success {}
class Error_1 extends HttpResponse_0 {}
class HttpError extends Error_1 {
  constructor(originalResponse, error) {
    super(originalResponse);
    this.error = error;
  }
  h4a() {
    return this.error;
  }
}
class NetworkError extends Error_1 {
  constructor() {
    super(null);
  }
}
class $deserializeCOROUTINE$0 extends CoroutineImpl {
  constructor(_this__u8e3s4, charset, typeInfo, content, resultContinuation) {
    super(resultContinuation);
    this.q4a_1 = _this__u8e3s4;
    this.r4a_1 = charset;
    this.s4a_1 = typeInfo;
    this.t4a_1 = content;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            this.te_1 = 1;
            suspendResult = this.t4a_1.w1l(VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var packet = suspendResult;
            var stringContent = packet.s1r();
            var TRY_RESULT;
            this.ue_1 = 2;
            TRY_RESULT = safeCast(this.s4a_1.t24_1, JSON.parse(stringContent));
            this.ue_1 = 4;
            this.te_1 = 3;
            continue $sm;
          case 2:
            this.ue_1 = 4;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof SyntaxError) {
              var _ = this.we_1;
              TRY_RESULT = null;
              this.te_1 = 3;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 3:
            this.ue_1 = 4;
            return TRY_RESULT;
          case 4:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class JsonConverter {
  v2l(contentType, charset, typeInfo, value, $completion) {
    return contentType.equals(Application_getInstance().m26_1) ? new ByteArrayContent(encodeToByteArray(JSON.stringify(value)), contentType) : null;
  }
  w2l(charset, typeInfo, content, $completion) {
    var tmp = new $deserializeCOROUTINE$0(this, charset, typeInfo, content, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
}
class ReadableStreamContent$readFrom$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.e4b_1 = this$0;
    super(resultContinuation, $box);
  }
  n2v($this$writer, $completion) {
    var tmp = this.o2v($this$writer, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.n2v((!(p1 == null) ? isInterface(p1, WriterScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 6;
            this.g4b_1 = this.e4b_1.v4a_1.getReader();
            this.te_1 = 1;
            continue $sm;
          case 1:
            if (!true) {
              this.te_1 = 8;
              continue $sm;
            }

            this.ue_1 = 5;
            this.te_1 = 2;
            var cancellable = new CancellableContinuationImpl(intercepted(this), get_MODE_CANCELLABLE());
            cancellable.ts();
            var tmp_0 = this.g4b_1.read();
            var tmp_1 = tmp_0.then(ReadableStreamContent$readFrom$slambda$lambda(cancellable));
            tmp_1.catch(ReadableStreamContent$readFrom$slambda$lambda_0(cancellable));
            suspendResult = returnIfSuspended(cancellable.us(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.h4b_1 = suspendResult;
            this.i4b_1 = this.h4b_1;
            if (this.i4b_1 == null) {
              this.ue_1 = 6;
              this.te_1 = 8;
              var tmp_2 = this;
              continue $sm;
            } else {
              this.j4b_1 = this.i4b_1;
              this.te_1 = 3;
              continue $sm;
            }

          case 3:
            this.te_1 = 4;
            var tmp_3 = this.f4b_1.p11();
            var this_0 = new Int8Array(this.h4b_1.buffer, this.h4b_1.byteOffset, this.h4b_1.length);
            suspendResult = writeFully(tmp_3, this_0, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            this.f4b_1.p11().i8();
            this.ue_1 = 6;
            this.te_1 = 7;
            continue $sm;
          case 5:
            this.ue_1 = 6;
            var tmp_4 = this.we_1;
            if (tmp_4 instanceof Error) {
              var cause = this.we_1;
              this.g4b_1.cancel(cause);
              throw cause;
            } else {
              throw this.we_1;
            }

          case 6:
            throw this.we_1;
          case 7:
            this.ue_1 = 6;
            this.te_1 = 1;
            continue $sm;
          case 8:
            return Unit_instance;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 6) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  o2v($this$writer, completion) {
    var i = new ReadableStreamContent$readFrom$slambda(this.e4b_1, completion);
    i.f4b_1 = $this$writer;
    return i;
  }
}
class ReadableStreamContent extends ReadChannelContent {
  constructor(stream) {
    super();
    this.v4a_1 = stream;
  }
  a2j() {
    return writer(this, VOID, VOID, ReadableStreamContent$readFrom$slambda_0(this, null)).p11();
  }
  yo() {
    return EmptyCoroutineContext_getInstance();
  }
}
class SnipoccSDK$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.v4b_1 = this$0;
    super(resultContinuation, $box);
  }
  q35($this$intercept, request, $completion) {
    var tmp = this.r35($this$intercept, request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  j2p(p1, p2, $completion) {
    var tmp = (!(p1 == null) ? isInterface(p1, Sender) : false) ? p1 : THROW_CCE();
    return this.q35(tmp, p2 instanceof HttpRequestBuilder ? p2 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            var tmp0_safe_receiver = this.v4b_1.k4b_1;
            if (tmp0_safe_receiver == null)
              null;
            else {
              headers(this.x4b_1, SnipoccSDK$slambda$lambda(tmp0_safe_receiver));
            }

            this.te_1 = 1;
            suspendResult = this.w4b_1.s2r(this.x4b_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var call = suspendResult;
            var tmp1_safe_receiver = resolveRefreshToken(this.v4b_1, call.p2p());
            if (tmp1_safe_receiver == null)
              null;
            else {
              this.v4b_1.l4b_1.jm(tmp1_safe_receiver);
            }

            return call;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  r35($this$intercept, request, completion) {
    var i = new SnipoccSDK$slambda(this.v4b_1, completion);
    i.w4b_1 = $this$intercept;
    i.x4b_1 = request;
    return i;
  }
}
class SnipoccSDK {
  constructor(apiVersion) {
    apiVersion = apiVersion === VOID ? ApiVersion_V1_getInstance() : apiVersion;
    this.apiVersion = apiVersion;
    this.k4b_1 = null;
    this.l4b_1 = PublishSubject();
    var tmp = plugin(get_client(), Plugin_getInstance());
    tmp.b37(SnipoccSDK$slambda_0(this, null));
    this.m4b_1 = Regex.gb('token=(<token>?) expiresIn=(<expires>?)');
  }
  y4b() {
    return this.apiVersion;
  }
  tokenRefreshCallback(block) {
    subscribe(this.l4b_1, VOID, VOID, VOID, SnipoccSDK$tokenRefreshCallback$lambda(block));
  }
  addProblemHandler(handler) {
    var tmp = get_problemHandlers();
    tmp.o(SnipoccSDK$addProblemHandler$lambda(handler));
  }
  addExceptionHandler(handler) {
    var tmp = get_exceptionHandlers();
    tmp.o(SnipoccSDK$addExceptionHandler$lambda(handler));
  }
  z4b(version) {
    return new TestTypeService(version);
  }
  testTypeService(version, $super) {
    version = version === VOID ? this.apiVersion : version;
    return this.z4b(version);
  }
  a4c(version) {
    return new ProjectService(version);
  }
  projectService(version, $super) {
    version = version === VOID ? this.apiVersion : version;
    return this.a4c(version);
  }
  b4c(version) {
    return new PointOfCareTestService(version);
  }
  poctService(version, $super) {
    version = version === VOID ? this.apiVersion : version;
    return this.b4c(version);
  }
  setToken(token) {
    this.k4b_1 = token;
  }
}
class ApiVersion extends Enum {
  constructor(name, ordinal, endpoint) {
    super(name, ordinal);
    this.endpoint = endpoint;
  }
  e4c() {
    return this.endpoint;
  }
  get name() {
    return this.ed();
  }
  get ordinal() {
    return this.fd();
  }
}
class client$2$1$1$1 {
  ul(message) {
    // Inline function 'co.touchlab.kermit.Logger.d' call
    var this_0 = Companion_getInstance_1();
    var tag = this_0.am();
    // Inline function 'co.touchlab.kermit.BaseLogger.logBlock' call
    var severity = Severity_Debug_getInstance();
    if (this_0.dl().xl().gd(severity) <= 0) {
      // Inline function 'de.castalytics.snipocc.<no name provided>.log.<anonymous>' call
      this_0.el(severity, tag, null, message);
    }
  }
}
class client$delegate$lambda$lambda$lambda$slambda extends CoroutineImpl {
  r4c(cause, _anonymous_parameter_1__qggqgd, $completion) {
    var tmp = this.s4c(cause, _anonymous_parameter_1__qggqgd, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  j2p(p1, p2, $completion) {
    var tmp = p1 instanceof Error ? p1 : THROW_CCE();
    return this.r4c(tmp, (!(p2 == null) ? isInterface(p2, HttpRequest) : false) ? p2 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            var this_0 = Companion_getInstance_1();
            var tag = this_0.am();
            var severity = Severity_Warn_getInstance();
            if (this_0.dl().xl().gd(severity) <= 0) {
              this_0.el(severity, tag, null, 'Handling response exception: ' + this.n4c_1);
            }

            var tmp_0 = this;
            var tmp_1 = this.n4c_1;
            var tmp0_elvis_lhs = tmp_1 instanceof ResponseException ? tmp_1 : null;
            var tmp_2;
            if (tmp0_elvis_lhs == null) {
              return Unit_instance;
            } else {
              tmp_2 = tmp0_elvis_lhs;
            }

            tmp_0.p4c_1 = tmp_2;
            this.q4c_1 = this.p4c_1.x2z_1;
            this.te_1 = 1;
            suspendResult = bodyAsText(this.q4c_1, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var errorBody = suspendResult;
            var tmp_3;
            try {
              tmp_3 = JSON.parse(errorBody);
            } catch ($p) {
              var tmp_4;
              if ($p instanceof SyntaxError) {
                var se = $p;
                tmp_4 = errorBody;
              } else {
                throw $p;
              }
              tmp_3 = tmp_4;
            }

            var errorBodyParsed = tmp_3;
            if (errorBodyParsed.detail !== undefined) {
              var detail = errorBodyParsed;
              var tmp0_iterator = get_problemHandlers().r();
              while (tmp0_iterator.s()) {
                var element = tmp0_iterator.u();
                var this_1 = Companion_getInstance_1();
                var tag_0 = this_1.am();
                var severity_0 = Severity_Debug_getInstance();
                if (this_1.dl().xl().gd(severity_0) <= 0) {
                  this_1.el(severity_0, tag_0, null, 'calling problem handler');
                }
                element(detail);
              }
              throw ProblemDetailException.c4a(this.q4c_1, detail);
            } else {
              var tmp0_iterator_0 = get_exceptionHandlers().r();
              while (tmp0_iterator_0.s()) {
                var element_0 = tmp0_iterator_0.u();
                var this_2 = Companion_getInstance_1();
                var tag_1 = this_2.am();
                var severity_1 = Severity_Debug_getInstance();
                if (this_2.dl().xl().gd(severity_1) <= 0) {
                  this_2.el(severity_1, tag_1, null, 'calling exception handler');
                }
                element_0(errorBodyParsed);
              }
              throw this.p4c_1;
            }

          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  s4c(cause, _anonymous_parameter_1__qggqgd, completion) {
    var i = new client$delegate$lambda$lambda$lambda$slambda(completion);
    i.n4c_1 = cause;
    i.o4c_1 = _anonymous_parameter_1__qggqgd;
    return i;
  }
}
class PointOfCareTestService$create$slambda extends CoroutineImpl {
  constructor($baseUrl, $poct, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.b4d_1 = $baseUrl;
    $box.c4d_1 = $poct;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.b4d_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, Application_getInstance().m26_1);
          var body = this.c4d_1;
          if (body == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(PointOfCareTest);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(PointOfCareTest), arrayOf([]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (body instanceof OutgoingContent) {
              this_1.r2q_1 = body;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = body;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(PointOfCareTest);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(PointOfCareTest), arrayOf([]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new PointOfCareTestService$create$slambda(this.b4d_1, this.c4d_1, completion);
    i.d4d_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase {
  constructor(version) {
    this.version = version;
    this.apiUrl = '/api/' + this.version.endpoint;
    this.h4d_1 = this.apiUrl;
  }
  v2u() {
    return this.version;
  }
  i4d() {
    return this.apiUrl;
  }
  e4c() {
    return this.h4d_1;
  }
  j4d(baseUrl, block) {
    return request(SnipoccServiceBase$post$slambda_1(baseUrl, block, null));
  }
  k4d(baseUrl, block) {
    return request_0(SnipoccServiceBase$post$slambda_2(baseUrl, block, null));
  }
  l4d(baseUrl, block) {
    return request(SnipoccServiceBase$put$slambda_1(baseUrl, block, null));
  }
  m4d(baseUrl, block) {
    return request_0(SnipoccServiceBase$put$slambda_2(baseUrl, block, null));
  }
  n4d(baseUrl, block) {
    return request(SnipoccServiceBase$get$slambda_1(baseUrl, block, null));
  }
  o4d(baseUrl, block) {
    return request_0(SnipoccServiceBase$get$slambda_2(baseUrl, block, null));
  }
  p4d(baseUrl, block) {
    return request(SnipoccServiceBase$delete$slambda_1(baseUrl, block, null));
  }
  q4d(baseUrl, block) {
    return request_0(SnipoccServiceBase$delete$slambda_2(baseUrl, block, null));
  }
  r4d(baseUrl, block) {
    return request(SnipoccServiceBase$patch$slambda_1(baseUrl, block, null));
  }
  s4d(baseUrl, block) {
    return request_0(SnipoccServiceBase$patch$slambda_2(baseUrl, block, null));
  }
  get endpoint() {
    return this.e4c();
  }
}
class PointOfCareTestService extends SnipoccServiceBase {
  constructor(version) {
    super(version);
    this.g4d_1 = this.apiUrl + '/poct';
  }
  e4c() {
    return this.g4d_1;
  }
  create(poct) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.post' call
    var baseUrl = this.endpoint;
    return request(PointOfCareTestService$create$slambda_0(baseUrl, poct, null));
  }
}
class ProjectService$create$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.b4e_1 = $baseUrl;
    $box.c4e_1 = $project;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.b4e_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, Application_getInstance().m26_1);
          var body = this.c4e_1;
          if (body == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(Project);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(Project), arrayOf([]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (body instanceof OutgoingContent) {
              this_1.r2q_1 = body;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = body;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(Project);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(Project), arrayOf([]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$create$slambda(this.b4e_1, this.c4e_1, completion);
    i.d4e_1 = $this$request;
    return i;
  }
}
class ProjectService$update$slambda extends CoroutineImpl {
  constructor($baseUrl, $id, $project, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.m4e_1 = $baseUrl;
    $box.n4e_1 = $id;
    $box.o4e_1 = $project;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.m4e_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, Application_getInstance().m26_1);
          pathSegments(this_1, [this.n4e_1]);
          var body = this.o4e_1;
          if (body == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(Project);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(Project), arrayOf([]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (body instanceof OutgoingContent) {
              this_1.r2q_1 = body;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = body;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(Project);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(Project), arrayOf([]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          this_1.p2q_1 = Companion_getInstance_0().z2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$update$slambda(this.m4e_1, this.n4e_1, this.o4e_1, completion);
    i.p4e_1 = $this$request;
    return i;
  }
}
class ProjectService$addUsers$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, $users, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.y4e_1 = $baseUrl;
    $box.z4e_1 = $project;
    $box.a4f_1 = $users;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.y4e_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, Application_getInstance().m26_1);
          pathSegments(this_1, [this.z4e_1.id, 'users']);
          var this_2 = this.a4f_1;
          var destination = ArrayList.k3(this_2.length);
          var inductionVariable = 0;
          var last = this_2.length;
          while (inductionVariable < last) {
            var item = this_2[inductionVariable];
            inductionVariable = inductionVariable + 1 | 0;
            destination.o(item.email);
          }
          if (destination == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(List);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(List), arrayOf([createInvariantKTypeProjection(createKType(PrimitiveClasses_getInstance().v9(), arrayOf([]), false))]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (destination instanceof OutgoingContent) {
              this_1.r2q_1 = destination;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = destination;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(List);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(List), arrayOf([createInvariantKTypeProjection(createKType(PrimitiveClasses_getInstance().v9(), arrayOf([]), false))]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$addUsers$slambda(this.y4e_1, this.z4e_1, this.a4f_1, completion);
    i.b4f_1 = $this$request;
    return i;
  }
}
class ProjectService$getUsers$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.k4f_1 = $baseUrl;
    $box.l4f_1 = $project;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.k4f_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.l4f_1.id, 'users']);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$getUsers$slambda(this.k4f_1, this.l4f_1, completion);
    i.m4f_1 = $this$request;
    return i;
  }
}
class ProjectService$getTestTypes$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.v4f_1 = $baseUrl;
    $box.w4f_1 = $project;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.v4f_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.w4f_1.id, 'test-types']);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$getTestTypes$slambda(this.v4f_1, this.w4f_1, completion);
    i.x4f_1 = $this$request;
    return i;
  }
}
class ProjectService$addTestTypes$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, $testTypes, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.g4g_1 = $baseUrl;
    $box.h4g_1 = $project;
    $box.i4g_1 = $testTypes;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.g4g_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, Application_getInstance().m26_1);
          pathSegments(this_1, [this.h4g_1.id, 'test-types']);
          var this_2 = this.i4g_1;
          var destination = ArrayList.k3(this_2.length);
          var inductionVariable = 0;
          var last = this_2.length;
          while (inductionVariable < last) {
            var item = this_2[inductionVariable];
            inductionVariable = inductionVariable + 1 | 0;
            destination.o(item.id);
          }
          if (destination == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(List);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(List), arrayOf([createInvariantKTypeProjection(createKType(PrimitiveClasses_getInstance().v9(), arrayOf([]), true))]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (destination instanceof OutgoingContent) {
              this_1.r2q_1 = destination;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = destination;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(List);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(List), arrayOf([createInvariantKTypeProjection(createKType(PrimitiveClasses_getInstance().v9(), arrayOf([]), true))]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$addTestTypes$slambda(this.g4g_1, this.h4g_1, this.i4g_1, completion);
    i.j4g_1 = $this$request;
    return i;
  }
}
class ProjectService$removeTestType$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, $testType, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.s4g_1 = $baseUrl;
    $box.t4g_1 = $project;
    $box.u4g_1 = $testType;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.s4g_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.t4g_1.id, 'test-types', this.u4g_1.id]);
          this_1.p2q_1 = Companion_getInstance_0().b2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$removeTestType$slambda(this.s4g_1, this.t4g_1, this.u4g_1, completion);
    i.v4g_1 = $this$request;
    return i;
  }
}
class ProjectService$upload$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, $label, $testType, $file, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.e4h_1 = $baseUrl;
    $box.f4h_1 = $project;
    $box.g4h_1 = $label;
    $box.h4h_1 = $testType;
    $box.i4h_1 = $file;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.e4h_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, MultiPart_getInstance().i27_1);
          accept(this_1, Text_getInstance().m27_1);
          var data = formData(ProjectService$upload$slambda$lambda(this.i4h_1));
          var body = new MultiPartFormDataContent(data);
          if (body == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(MultiPartFormDataContent);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(MultiPartFormDataContent), arrayOf([]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (body instanceof OutgoingContent) {
              this_1.r2q_1 = body;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = body;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(MultiPartFormDataContent);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(MultiPartFormDataContent), arrayOf([]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          pathSegments(this_1, [this.f4h_1.id]);
          var params = ArrayList.o3();
          params.o(to('ailabel', this.g4h_1.name));
          params.o(to('testtype', this.h4h_1.id));
          var tmp0_iterator = params.r();
          while (tmp0_iterator.s()) {
            var element = tmp0_iterator.u();
            var key = element.rk();
            var value = element.sk();
            parameter(this_1, key, value);
          }
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$upload$slambda(this.e4h_1, this.f4h_1, this.g4h_1, this.h4h_1, this.i4h_1, completion);
    i.j4h_1 = $this$request;
    return i;
  }
}
class ProjectService$removeUser$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, $user, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.s4h_1 = $baseUrl;
    $box.t4h_1 = $project;
    $box.u4h_1 = $user;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.s4h_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.t4h_1.id, 'users', this.u4h_1.id]);
          this_1.p2q_1 = Companion_getInstance_0().b2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$removeUser$slambda(this.s4h_1, this.t4h_1, this.u4h_1, completion);
    i.v4h_1 = $this$request;
    return i;
  }
}
class ProjectService$getCurrentUserProjects$slambda extends CoroutineImpl {
  constructor($baseUrl, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.e4i_1 = $baseUrl;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.e4i_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, ['users', 'projects']);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$getCurrentUserProjects$slambda(this.e4i_1, completion);
    i.f4i_1 = $this$request;
    return i;
  }
}
class ProjectService$get$slambda extends CoroutineImpl {
  constructor($baseUrl, $projectId, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.o4i_1 = $baseUrl;
    $box.p4i_1 = $projectId;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.o4i_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.p4i_1]);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$get$slambda(this.o4i_1, this.p4i_1, completion);
    i.q4i_1 = $this$request;
    return i;
  }
}
class ProjectService$getAll$slambda extends CoroutineImpl {
  constructor($baseUrl, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.z4i_1 = $baseUrl;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.z4i_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$getAll$slambda(this.z4i_1, completion);
    i.a4j_1 = $this$request;
    return i;
  }
}
class ProjectService$delete$slambda extends CoroutineImpl {
  constructor($baseUrl, $project, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.j4j_1 = $baseUrl;
    $box.k4j_1 = $project;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.j4j_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.k4j_1.id]);
          this_1.p2q_1 = Companion_getInstance_0().b2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new ProjectService$delete$slambda(this.j4j_1, this.k4j_1, completion);
    i.l4j_1 = $this$request;
    return i;
  }
}
class ProjectService extends SnipoccServiceBase {
  constructor(version) {
    super(version);
    this.n4j_1 = this.apiUrl + '/projects';
  }
  e4c() {
    return this.n4j_1;
  }
  create(project) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.post' call
    var baseUrl = this.endpoint;
    return request(ProjectService$create$slambda_0(baseUrl, project, null));
  }
  update(project, id) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.put' call
    var baseUrl = this.endpoint;
    return request(ProjectService$update$slambda_0(baseUrl, id, project, null));
  }
  addUsers(project, users) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.post' call
    var baseUrl = this.endpoint;
    return request_0(ProjectService$addUsers$slambda_0(baseUrl, project, users, null));
  }
  getUsers(project) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.endpoint;
    return request(ProjectService$getUsers$slambda_0(baseUrl, project, null));
  }
  getTestTypes(project) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.endpoint;
    return request(ProjectService$getTestTypes$slambda_0(baseUrl, project, null));
  }
  addTestTypes(project, testTypes) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.post' call
    var baseUrl = this.endpoint;
    return request_0(ProjectService$addTestTypes$slambda_0(baseUrl, project, testTypes, null));
  }
  removeTestType(project, testType) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.delete' call
    var baseUrl = this.endpoint;
    return request_0(ProjectService$removeTestType$slambda_0(baseUrl, project, testType, null));
  }
  upload(project, testType, label, file) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.post' call
    var baseUrl = this.endpoint;
    return request(ProjectService$upload$slambda_0(baseUrl, project, label, testType, file, null));
  }
  removeUser(project, user) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.delete' call
    var baseUrl = this.endpoint;
    return request_0(ProjectService$removeUser$slambda_0(baseUrl, project, user, null));
  }
  getCurrentUserProjects() {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.apiUrl;
    return request(ProjectService$getCurrentUserProjects$slambda_0(baseUrl, null));
  }
  get(projectId) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.endpoint;
    return request(ProjectService$get$slambda_0(baseUrl, projectId, null));
  }
  getAll() {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.endpoint;
    return request(ProjectService$getAll$slambda_0(baseUrl, null));
  }
  delete(project) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.delete' call
    var baseUrl = this.endpoint;
    return request_0(ProjectService$delete$slambda_0(baseUrl, project, null));
  }
}
class SnipoccServiceBase$post$slambda extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.w4j_1 = $baseUrl;
    $box.x4j_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.w4j_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.x4j_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$post$slambda(this.w4j_1, this.x4j_1, completion);
    i.y4j_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$post$slambda_0 extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.h4k_1 = $baseUrl;
    $box.i4k_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.h4k_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.i4k_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$post$slambda_0(this.h4k_1, this.i4k_1, completion);
    i.j4k_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$put$slambda extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.s4k_1 = $baseUrl;
    $box.t4k_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.s4k_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.t4k_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().z2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$put$slambda(this.s4k_1, this.t4k_1, completion);
    i.u4k_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$put$slambda_0 extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.d4l_1 = $baseUrl;
    $box.e4l_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.d4l_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.e4l_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().z2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$put$slambda_0(this.d4l_1, this.e4l_1, completion);
    i.f4l_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$get$slambda extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.o4l_1 = $baseUrl;
    $box.p4l_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.o4l_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.p4l_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$get$slambda(this.o4l_1, this.p4l_1, completion);
    i.q4l_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$get$slambda_0 extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.z4l_1 = $baseUrl;
    $box.a4m_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.z4l_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.a4m_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$get$slambda_0(this.z4l_1, this.a4m_1, completion);
    i.b4m_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$delete$slambda extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.k4m_1 = $baseUrl;
    $box.l4m_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.k4m_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.l4m_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().b2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$delete$slambda(this.k4m_1, this.l4m_1, completion);
    i.m4m_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$delete$slambda_0 extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.v4m_1 = $baseUrl;
    $box.w4m_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.v4m_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.w4m_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().b2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$delete$slambda_0(this.v4m_1, this.w4m_1, completion);
    i.x4m_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$patch$slambda extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.g4n_1 = $baseUrl;
    $box.h4n_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.g4n_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.h4n_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().a2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$patch$slambda(this.g4n_1, this.h4n_1, completion);
    i.i4n_1 = $this$request;
    return i;
  }
}
class SnipoccServiceBase$patch$slambda_0 extends CoroutineImpl {
  constructor($baseUrl, $block, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.r4n_1 = $baseUrl;
    $box.s4n_1 = $block;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.r4n_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          this.s4n_1(this_1);
          this_1.p2q_1 = Companion_getInstance_0().a2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new SnipoccServiceBase$patch$slambda_0(this.r4n_1, this.s4n_1, completion);
    i.t4n_1 = $this$request;
    return i;
  }
}
class TestTypeService$create$slambda extends CoroutineImpl {
  constructor($baseUrl, $testType, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.c4o_1 = $baseUrl;
    $box.d4o_1 = $testType;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.c4o_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, Application_getInstance().m26_1);
          var body = this.d4o_1;
          if (body == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(TestType);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(TestType), arrayOf([]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (body instanceof OutgoingContent) {
              this_1.r2q_1 = body;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = body;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(TestType);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(TestType), arrayOf([]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          this_1.p2q_1 = Companion_getInstance_0().y2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new TestTypeService$create$slambda(this.c4o_1, this.d4o_1, completion);
    i.e4o_1 = $this$request;
    return i;
  }
}
class TestTypeService$update$slambda extends CoroutineImpl {
  constructor($baseUrl, $id, $testType, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.n4o_1 = $baseUrl;
    $box.o4o_1 = $id;
    $box.p4o_1 = $testType;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.n4o_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          contentType_0(this_1, Application_getInstance().m26_1);
          pathSegments(this_1, [this.o4o_1]);
          var body = this.p4o_1;
          if (body == null) {
            this_1.r2q_1 = NullBody_instance;
            var tmp_0 = JsType_instance;
            var tmp_1 = getKClass(TestType);
            var tmp_2;
            try {
              tmp_2 = createKType(getKClass(TestType), arrayOf([]), false);
            } catch ($p) {
              var tmp_3;
              if ($p instanceof Error) {
                var cause = $p;
                tmp_3 = null;
              } else {
                throw $p;
              }
              tmp_2 = tmp_3;
            }
            this_1.g2x(typeInfoImpl(tmp_0, tmp_1, tmp_2));
          } else {
            if (body instanceof OutgoingContent) {
              this_1.r2q_1 = body;
              this_1.g2x(null);
            } else {
              this_1.r2q_1 = body;
              var tmp_4 = JsType_instance;
              var tmp_5 = getKClass(TestType);
              var tmp_6;
              try {
                tmp_6 = createKType(getKClass(TestType), arrayOf([]), false);
              } catch ($p) {
                var tmp_7;
                if ($p instanceof Error) {
                  var cause_0 = $p;
                  tmp_7 = null;
                } else {
                  throw $p;
                }
                tmp_6 = tmp_7;
              }
              this_1.g2x(typeInfoImpl(tmp_4, tmp_5, tmp_6));
            }
          }
          this_1.p2q_1 = Companion_getInstance_0().z2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new TestTypeService$update$slambda(this.n4o_1, this.o4o_1, this.p4o_1, completion);
    i.q4o_1 = $this$request;
    return i;
  }
}
class TestTypeService$getTestType$slambda extends CoroutineImpl {
  constructor($baseUrl, $id, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.z4o_1 = $baseUrl;
    $box.a4p_1 = $id;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.z4o_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.a4p_1]);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new TestTypeService$getTestType$slambda(this.z4o_1, this.a4p_1, completion);
    i.b4p_1 = $this$request;
    return i;
  }
}
class TestTypeService$getProjects$slambda extends CoroutineImpl {
  constructor($baseUrl, $id, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.k4p_1 = $baseUrl;
    $box.l4p_1 = $id;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.k4p_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.l4p_1, 'projects']);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new TestTypeService$getProjects$slambda(this.k4p_1, this.l4p_1, completion);
    i.m4p_1 = $this$request;
    return i;
  }
}
class TestTypeService$deleteTestType$slambda extends CoroutineImpl {
  constructor($baseUrl, $id, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.v4p_1 = $baseUrl;
    $box.w4p_1 = $id;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.v4p_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          pathSegments(this_1, [this.w4p_1]);
          this_1.p2q_1 = Companion_getInstance_0().b2d_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new TestTypeService$deleteTestType$slambda(this.v4p_1, this.w4p_1, completion);
    i.x4p_1 = $this$request;
    return i;
  }
}
class TestTypeService$loadAll$slambda extends CoroutineImpl {
  constructor($baseUrl, $filter, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.g4q_1 = $baseUrl;
    $box.h4q_1 = $filter;
    super(resultContinuation, $box);
  }
  e4d($this$request, $completion) {
    var tmp = this.z1m($this$request, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.e4d((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        if (tmp === 0) {
          this.ue_1 = 1;
          var this_0 = get_client();
          var urlString = this.g4q_1;
          var this_1 = new HttpRequestBuilder();
          url(this_1, urlString);
          accept(this_1, Companion_getInstance().o25('application/x-ndjson'));
          parameter(this_1, 'filter', this.h4q_1);
          this_1.p2q_1 = Companion_getInstance_0().x2c_1;
          return new HttpStatement(this_1, this_0);
        } else if (tmp === 1) {
          throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  }
  z1m($this$request, completion) {
    var i = new TestTypeService$loadAll$slambda(this.g4q_1, this.h4q_1, completion);
    i.i4q_1 = $this$request;
    return i;
  }
}
class TestTypeService extends SnipoccServiceBase {
  constructor(version) {
    super(version);
    this.k4q_1 = this.apiUrl + '/test-types';
  }
  e4c() {
    return this.k4q_1;
  }
  create(testType) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.post' call
    var baseUrl = this.endpoint;
    return request(TestTypeService$create$slambda_0(baseUrl, testType, null));
  }
  update(testType, id) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.put' call
    var baseUrl = this.endpoint;
    return request(TestTypeService$update$slambda_0(baseUrl, id, testType, null));
  }
  getTestType(id) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.endpoint;
    return request(TestTypeService$getTestType$slambda_0(baseUrl, id, null));
  }
  getProjects(id) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.endpoint;
    return request(TestTypeService$getProjects$slambda_0(baseUrl, id, null));
  }
  deleteTestType(id) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.delete' call
    var baseUrl = this.endpoint;
    return request_0(TestTypeService$deleteTestType$slambda_0(baseUrl, id, null));
  }
  l4q(filter) {
    // Inline function 'de.castalytics.snipocc.services.SnipoccServiceBase.get' call
    var baseUrl = this.endpoint;
    return request(TestTypeService$loadAll$slambda_0(baseUrl, filter, null));
  }
  loadAll(filter, $super) {
    filter = filter === VOID ? null : filter;
    return this.l4q(filter);
  }
}
class $bodyAsTypeCOROUTINE$1 extends CoroutineImpl {
  constructor(_this__u8e3s4, resultContinuation) {
    super(resultContinuation);
    this.u4q_1 = _this__u8e3s4;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            this.te_1 = 1;
            suspendResult = bodyAsText(this.u4q_1, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var body = suspendResult;
            var TRY_RESULT;
            this.ue_1 = 2;
            TRY_RESULT = JSON.parse(body);
            this.ue_1 = 4;
            this.te_1 = 3;
            continue $sm;
          case 2:
            this.ue_1 = 4;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof SyntaxError) {
              var _ = this.we_1;
              TRY_RESULT = body;
              this.te_1 = 3;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 3:
            this.ue_1 = 4;
            return TRY_RESULT;
          case 4:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
//endregion
var FileType_FISENSE_ASCII_instance;
var FileType_FISENSE_BINARY_instance;
var FileType_NVM_instance;
var FileType_IMAGE_instance;
function values() {
  return [FileType_FISENSE_ASCII_getInstance(), FileType_FISENSE_BINARY_getInstance(), FileType_NVM_getInstance(), FileType_IMAGE_getInstance()];
}
function valueOf(value) {
  switch (value) {
    case 'FISENSE_ASCII':
      return FileType_FISENSE_ASCII_getInstance();
    case 'FISENSE_BINARY':
      return FileType_FISENSE_BINARY_getInstance();
    case 'NVM':
      return FileType_NVM_getInstance();
    case 'IMAGE':
      return FileType_IMAGE_getInstance();
    default:
      FileType_initEntries();
      THROW_IAE('No enum constant value.');
      break;
  }
}
function FileType$lambda(it) {
  // Inline function 'kotlin.text.lowercase' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$1 = it.toLowerCase();
  return endsWith(tmp$ret$1, '.txt');
}
function FileType$lambda_0(it) {
  // Inline function 'kotlin.text.lowercase' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$1 = it.toLowerCase();
  return endsWith(tmp$ret$1, '.bin');
}
function FileType$lambda_1(it) {
  // Inline function 'kotlin.text.lowercase' call
  // Inline function 'kotlin.js.asDynamic' call
  var tmp$ret$1 = it.toLowerCase();
  return endsWith(tmp$ret$1, '.tsv');
}
function FileType$lambda_2(it) {
  var tmp$ret$0;
  $l$block_0: {
    // Inline function 'kotlin.collections.any' call
    var this_0 = listOf(['.png', '.jpg', '.jpeg']);
    var tmp;
    if (isInterface(this_0, Collection)) {
      tmp = this_0.z();
    } else {
      tmp = false;
    }
    if (tmp) {
      tmp$ret$0 = false;
      break $l$block_0;
    }
    var tmp0_iterator = this_0.r();
    while (tmp0_iterator.s()) {
      var element = tmp0_iterator.u();
      // Inline function 'de.castalytics.snipocc.domain.FileType.<anonymous>.<anonymous>' call
      // Inline function 'kotlin.text.lowercase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$2 = it.toLowerCase();
      if (endsWith(tmp$ret$2, element)) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
    }
    tmp$ret$0 = false;
  }
  return tmp$ret$0;
}
var FileType_entriesInitialized;
function FileType_initEntries() {
  if (FileType_entriesInitialized)
    return Unit_instance;
  FileType_entriesInitialized = true;
  FileType_FISENSE_ASCII_instance = new FileType('FISENSE_ASCII', 0, FileType$lambda);
  FileType_FISENSE_BINARY_instance = new FileType('FISENSE_BINARY', 1, FileType$lambda_0);
  FileType_NVM_instance = new FileType('NVM', 2, FileType$lambda_1);
  FileType_IMAGE_instance = new FileType('IMAGE', 3, FileType$lambda_2);
}
function fileTypeFromName(name) {
  // Inline function 'kotlin.collections.find' call
  var this_0 = values();
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'de.castalytics.snipocc.domain.fileTypeFromName.<anonymous>' call
      if (equals(element.cd_1, name, true) ? true : equals(element.withPrefix, name, true)) {
        tmp$ret$1 = element;
        break $l$block;
      }
    }
    tmp$ret$1 = null;
  }
  return tmp$ret$1;
}
function FileType_FISENSE_ASCII_getInstance() {
  FileType_initEntries();
  return FileType_FISENSE_ASCII_instance;
}
function FileType_FISENSE_BINARY_getInstance() {
  FileType_initEntries();
  return FileType_FISENSE_BINARY_instance;
}
function FileType_NVM_getInstance() {
  FileType_initEntries();
  return FileType_NVM_instance;
}
function FileType_IMAGE_getInstance() {
  FileType_initEntries();
  return FileType_IMAGE_instance;
}
var Roles_USER_instance;
var Roles_PROJECT_ADMIN_instance;
var Roles_ADMIN_instance;
function values_0() {
  return [Roles_USER_getInstance(), Roles_PROJECT_ADMIN_getInstance(), Roles_ADMIN_getInstance()];
}
function valueOf_0(value) {
  switch (value) {
    case 'USER':
      return Roles_USER_getInstance();
    case 'PROJECT_ADMIN':
      return Roles_PROJECT_ADMIN_getInstance();
    case 'ADMIN':
      return Roles_ADMIN_getInstance();
    default:
      Roles_initEntries();
      THROW_IAE('No enum constant value.');
      break;
  }
}
var Roles_entriesInitialized;
function Roles_initEntries() {
  if (Roles_entriesInitialized)
    return Unit_instance;
  Roles_entriesInitialized = true;
  Roles_USER_instance = new Roles('USER', 0, null);
  Roles_PROJECT_ADMIN_instance = new Roles('PROJECT_ADMIN', 1, Roles_USER_getInstance());
  Roles_ADMIN_instance = new Roles('ADMIN', 2, Roles_PROJECT_ADMIN_getInstance());
}
function roleFromName(name) {
  // Inline function 'kotlin.collections.find' call
  var this_0 = values_0();
  var tmp$ret$1;
  $l$block: {
    // Inline function 'kotlin.collections.firstOrNull' call
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'de.castalytics.snipocc.domain.roleFromName.<anonymous>' call
      if (equals(element.cd_1, name, true) ? true : equals(element.withPrefix, name, true)) {
        tmp$ret$1 = element;
        break $l$block;
      }
    }
    tmp$ret$1 = null;
  }
  return tmp$ret$1;
}
function Roles_USER_getInstance() {
  Roles_initEntries();
  return Roles_USER_instance;
}
function Roles_PROJECT_ADMIN_getInstance() {
  Roles_initEntries();
  return Roles_PROJECT_ADMIN_instance;
}
function Roles_ADMIN_getInstance() {
  Roles_initEntries();
  return Roles_ADMIN_instance;
}
function I18NKeyNode$_init_$lambda_r11c2g($suffix) {
  return function () {
    return $suffix;
  };
}
function I18NKeyNode$_init_$lambda_r11c2g_0($prefix) {
  return function () {
    return $prefix;
  };
}
function I18NKeyNode$lambda(this$0) {
  return function () {
    // Inline function 'kotlin.text.lowercase' call
    // Inline function 'kotlin.js.asDynamic' call
    return ensureNotNull(getKClassFromExpression(this$0).u8()).toLowerCase();
  };
}
var Auth_BAD_CREDENTIALS_instance;
var Auth_NOT_ACTIVATED_instance;
var Auth_INSUFFICIENT_SCOPE_instance;
var Auth_GENERIC_instance;
function values_1() {
  return [Auth_BAD_CREDENTIALS_getInstance(), Auth_NOT_ACTIVATED_getInstance(), Auth_INSUFFICIENT_SCOPE_getInstance(), Auth_GENERIC_getInstance()];
}
function valueOf_1(value) {
  switch (value) {
    case 'BAD_CREDENTIALS':
      return Auth_BAD_CREDENTIALS_getInstance();
    case 'NOT_ACTIVATED':
      return Auth_NOT_ACTIVATED_getInstance();
    case 'INSUFFICIENT_SCOPE':
      return Auth_INSUFFICIENT_SCOPE_getInstance();
    case 'GENERIC':
      return Auth_GENERIC_getInstance();
    default:
      Auth_initEntries();
      THROW_IAE('No enum constant value.');
      break;
  }
}
var Auth_entriesInitialized;
function Auth_initEntries() {
  if (Auth_entriesInitialized)
    return Unit_instance;
  Auth_entriesInitialized = true;
  Auth_BAD_CREDENTIALS_instance = new Auth('BAD_CREDENTIALS', 0, 'bad-credentials');
  Auth_NOT_ACTIVATED_instance = new Auth('NOT_ACTIVATED', 1, 'not-activated');
  Auth_INSUFFICIENT_SCOPE_instance = new Auth('INSUFFICIENT_SCOPE', 2, 'insufficient-scope');
  Auth_GENERIC_instance = new Auth('GENERIC', 3, 'generic');
}
function Auth_BAD_CREDENTIALS_getInstance() {
  Auth_initEntries();
  return Auth_BAD_CREDENTIALS_instance;
}
function Auth_NOT_ACTIVATED_getInstance() {
  Auth_initEntries();
  return Auth_NOT_ACTIVATED_instance;
}
function Auth_INSUFFICIENT_SCOPE_getInstance() {
  Auth_initEntries();
  return Auth_INSUFFICIENT_SCOPE_instance;
}
function Auth_GENERIC_getInstance() {
  Auth_initEntries();
  return Auth_GENERIC_instance;
}
var Error_instance;
function Error_getInstance() {
  if (Error_instance === VOID)
    Error_0.x46();
  return Error_instance;
}
var I18NSelfNode_instance;
function I18NSelfNode_getInstance() {
  if (I18NSelfNode_instance === VOID)
    I18NSelfNode.y46();
  return I18NSelfNode_instance;
}
function prefixKey(prefix, suffix) {
  return prefixKey$1.b47(prefix, suffix);
}
function I18NKeyLeaf$Companion$default$lambda() {
  return '';
}
var Companion_instance_0;
function Companion_getInstance_3() {
  return Companion_instance_0;
}
function _get_channel__c6e3yq($this) {
  var tmp = $this.c47_1;
  if (!(tmp == null))
    return tmp;
  else {
    throwUninitializedPropertyAccessException('channel');
  }
}
function _get_response__7wqlp2($this) {
  var tmp = $this.d47_1;
  if (!(tmp == null))
    return tmp;
  else {
    throwUninitializedPropertyAccessException('response');
  }
}
function _get_contentType__u2ypgi($this) {
  var tmp = $this.e47_1;
  if (!(tmp == null))
    return tmp;
  else {
    throwUninitializedPropertyAccessException('contentType');
  }
}
function init($this) {
  return scope(ApiResponseWithBody$stream$lambda$lambda$o$init$slambda_0($this, $this.h47_1, null));
}
function resolve($this, value) {
  return new ApiResponseWithBody$stream$1$1$1$resolve$1(value);
}
function ApiResponseWithBody$stream$lambda$lambda$o$init$slambda_0(this$0, this$1, resultContinuation) {
  var i = new ApiResponseWithBody$stream$lambda$lambda$o$init$slambda(this$0, this$1, resultContinuation);
  var l = function ($this$scope, $completion) {
    return i.y1m($this$scope, $completion);
  };
  l.$arity = 1;
  return l;
}
function ApiResponseWithBody$stream$lambda$lambda$o$closed$lambda(this$0) {
  return function (resolve, _anonymous_parameter_1__qggqgd) {
    var tmp;
    if (this$0.f47_1 ? _get_channel__c6e3yq(this$0).q1e() : false) {
      tmp = resolve(undefined);
    }
    return Unit_instance;
  };
}
function ApiResponseWithBody$stream$lambda$lambda$o$cancel$lambda(this$0, $reason) {
  return function (resolve, reject) {
    var tmp;
    if (this$0.f47_1 ? _get_channel__c6e3yq(this$0).nv($reason) : false) {
      tmp = resolve($reason);
    } else {
      tmp = reject(newThrowable('Could not cancel channel'));
    }
    return Unit_instance;
  };
}
function ApiResponseWithBody$stream$lambda$lambda$o$read$slambda_0(this$0, resultContinuation) {
  var i = new ApiResponseWithBody$stream$lambda$lambda$o$read$slambda(this$0, resultContinuation);
  var l = function ($this$scope, $completion) {
    return i.r48($this$scope, $completion);
  };
  l.$arity = 1;
  return l;
}
function ApiResponseWithBody$body$lambda(it) {
  return it.body;
}
function ApiResponseWithBody$stream$lambda$lambda(this$0) {
  return function () {
    return new ApiResponseWithBody$stream$1$1$1(this$0);
  };
}
function ApiResponseWithBody$stream$lambda(this$0) {
  return function ($this$fromDynamic) {
    $this$fromDynamic.getReader = ApiResponseWithBody$stream$lambda$lambda(this$0);
    return Unit_instance;
  };
}
function ApiResponseWithBody$response$slambda_0(this$0, resultContinuation) {
  var i = new ApiResponseWithBody$response$slambda(this$0, resultContinuation);
  var l = function ($this$scope, $completion) {
    return i.g49($this$scope, $completion);
  };
  l.$arity = 1;
  return l;
}
function ApiResponse$response$slambda_0(this$0, resultContinuation) {
  var i = new ApiResponse$response$slambda(this$0, resultContinuation);
  var l = function ($this$scope, $completion) {
    return i.a4a($this$scope, $completion);
  };
  l.$arity = 1;
  return l;
}
function scope(block) {
  return promise(MainScope(), get_ApplicationDispatcher(), VOID, block);
}
function request(statement) {
  return new ApiResponseWithBody(statement);
}
function request_0(statement) {
  return new ApiResponse(statement);
}
var JsonConverter_instance;
function JsonConverter_getInstance() {
  return JsonConverter_instance;
}
function asByteChannel(_this__u8e3s4) {
  return (new ReadableStreamContent(_this__u8e3s4)).a2j();
}
function toChannelProvider(_this__u8e3s4, size) {
  return new ChannelProvider(size, toChannelProvider$lambda(_this__u8e3s4));
}
function stream(_this__u8e3s4) {
  // Inline function 'kotlin.js.unsafeCast' call
  // Inline function 'kotlin.js.asDynamic' call
  return _this__u8e3s4.stream();
}
function ChannelProvider_0(file) {
  return toChannelProvider(stream(file), numberToLong(file.size));
}
function appendFile(_this__u8e3s4, key, file) {
  var headers = headers_0(appendFile$lambda(file));
  _this__u8e3s4.t3k(key, ChannelProvider_0(file), headers);
}
function ReadableStreamContent$readFrom$slambda$lambda($cont) {
  return function (it) {
    var chunk = it.value;
    var result = it.done ? null : chunk;
    // Inline function 'kotlin.Companion.success' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(result);
    $cont.hf(tmp$ret$0);
    return Unit_instance;
  };
}
function ReadableStreamContent$readFrom$slambda$lambda_0($cont) {
  return function (it) {
    // Inline function 'kotlin.coroutines.resumeWithException' call
    var this_0 = $cont;
    // Inline function 'kotlin.Companion.failure' call
    var tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(it));
    this_0.hf(tmp$ret$0);
    return Unit_instance;
  };
}
function ReadableStreamContent$readFrom$slambda_0(this$0, resultContinuation) {
  var i = new ReadableStreamContent$readFrom$slambda(this$0, resultContinuation);
  var l = function ($this$writer, $completion) {
    return i.n2v($this$writer, $completion);
  };
  l.$arity = 1;
  return l;
}
function toChannelProvider$lambda($this_toChannelProvider) {
  return function () {
    return asByteChannel($this_toChannelProvider);
  };
}
function appendFile$lambda($file) {
  return function ($this$headers) {
    $this$headers.k21(HttpHeaders_getInstance().p28_1, 'filename=' + escapeIfNeeded($file.name));
    append($this$headers, HttpHeaders_getInstance().v28_1, Application_getInstance().p26_1);
    return Unit_instance;
  };
}
function get_ApplicationDispatcher() {
  _init_properties_SnipoccSDK_kt__q346kt();
  return ApplicationDispatcher;
}
var ApplicationDispatcher;
function get_client() {
  _init_properties_SnipoccSDK_kt__q346kt();
  // Inline function 'kotlin.getValue' call
  var this_0 = client$delegate;
  client$factory();
  return this_0.u2();
}
var client$delegate;
function get_problemHandlers() {
  _init_properties_SnipoccSDK_kt__q346kt();
  return problemHandlers;
}
var problemHandlers;
function get_exceptionHandlers() {
  _init_properties_SnipoccSDK_kt__q346kt();
  return exceptionHandlers;
}
var exceptionHandlers;
function SnipoccSDK$slambda$lambda($it) {
  return function ($this$headers) {
    $this$headers.i21(HttpHeaders_getInstance().m28_1, 'Bearer ' + $it);
    return Unit_instance;
  };
}
function resolveRefreshToken($this, response) {
  var tmp0_safe_receiver = response.w2c().z1z(HttpHeaders_getInstance().f2b_1);
  if (tmp0_safe_receiver == null)
    null;
  else {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'de.castalytics.snipocc.SnipoccSDK.resolveRefreshToken.<anonymous>' call
    if (!contains(tmp0_safe_receiver, 'token') ? true : !contains(tmp0_safe_receiver, 'expiresIn')) {
      return null;
    }
    var tmp0_safe_receiver_0 = $this.m4b_1.hb(tmp0_safe_receiver);
    var tmp;
    if (tmp0_safe_receiver_0 == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp0_container = tmp0_safe_receiver_0.ac();
      // Inline function 'kotlin.text.Destructured.component1' call
      var token = tmp0_container.tk_1.zb().h1(1);
      // Inline function 'kotlin.text.Destructured.component2' call
      var expires = tmp0_container.tk_1.zb().h1(2);
      return new _AuthResult(token, toInt(expires));
    }
  }
  return null;
}
function SnipoccSDK$tokenRefreshCallback$lambda($block) {
  return function (it) {
    $block(it);
    return Unit_instance;
  };
}
function SnipoccSDK$slambda_0(this$0, resultContinuation) {
  var i = new SnipoccSDK$slambda(this$0, resultContinuation);
  var l = function ($this$intercept, request, $completion) {
    return i.q35($this$intercept, request, $completion);
  };
  l.$arity = 2;
  return l;
}
function SnipoccSDK$addProblemHandler$lambda($handler) {
  return function (it) {
    var tmp;
    try {
      tmp = $handler(it);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Exception) {
        var e = $p;
        var this_0 = Companion_getInstance_1();
        var tag = this_0.am();
        var severity = Severity_Error_getInstance();
        var tmp_1;
        if (this_0.dl().xl().gd(severity) <= 0) {
          // Inline function 'de.castalytics.snipocc.SnipoccSDK.addProblemHandler.<anonymous>.<anonymous>' call
          var tmp$ret$0 = 'Problem handler threw exception: ' + e.message;
          this_0.el(severity, tag, null, tmp$ret$0);
          tmp_1 = Unit_instance;
        }
        tmp_0 = tmp_1;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return Unit_instance;
  };
}
function SnipoccSDK$addExceptionHandler$lambda($handler) {
  return function (it) {
    var tmp;
    try {
      tmp = $handler(it);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Exception) {
        var e = $p;
        var this_0 = Companion_getInstance_1();
        var tag = this_0.am();
        var severity = Severity_Error_getInstance();
        var tmp_1;
        if (this_0.dl().xl().gd(severity) <= 0) {
          // Inline function 'de.castalytics.snipocc.SnipoccSDK.addExceptionHandler.<anonymous>.<anonymous>' call
          var tmp$ret$0 = 'Exception handler threw exception: ' + e.message;
          this_0.el(severity, tag, null, tmp$ret$0);
          tmp_1 = Unit_instance;
        }
        tmp_0 = tmp_1;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return Unit_instance;
  };
}
var ApiVersion_V1_instance;
function values_2() {
  return [ApiVersion_V1_getInstance()];
}
function valueOf_2(value) {
  if (value === 'V1')
    return ApiVersion_V1_getInstance();
  else {
    ApiVersion_initEntries();
    THROW_IAE('No enum constant value.');
  }
}
var ApiVersion_entriesInitialized;
function ApiVersion_initEntries() {
  if (ApiVersion_entriesInitialized)
    return Unit_instance;
  ApiVersion_entriesInitialized = true;
  ApiVersion_V1_instance = new ApiVersion('V1', 0, 'v1');
}
function client$delegate$lambda() {
  _init_properties_SnipoccSDK_kt__q346kt();
  var tmp = Js_instance;
  return HttpClient(tmp, client$delegate$lambda$lambda);
}
function client$delegate$lambda$lambda($this$HttpClient) {
  _init_properties_SnipoccSDK_kt__q346kt();
  $this$HttpClient.b2r_1 = true;
  var tmp = Companion_getInstance_2();
  $this$HttpClient.w2r(tmp, client$delegate$lambda$lambda$lambda);
  var tmp_0 = Plugin_getInstance_0();
  $this$HttpClient.w2r(tmp_0, client$delegate$lambda$lambda$lambda_0);
  HttpResponseValidator($this$HttpClient, client$delegate$lambda$lambda$lambda_1);
  return Unit_instance;
}
function client$delegate$lambda$lambda$lambda($this$install) {
  _init_properties_SnipoccSDK_kt__q346kt();
  $this$install.t3z(new client$2$1$1$1());
  $this$install.s3z_1 = LogLevel_INFO_getInstance();
  return Unit_instance;
}
function client$delegate$lambda$lambda$lambda_0($this$install) {
  _init_properties_SnipoccSDK_kt__q346kt();
  $this$install.y2l(Application_getInstance().m26_1, JsonConverter_instance);
  return Unit_instance;
}
function client$delegate$lambda$lambda$lambda_1($this$HttpResponseValidator) {
  _init_properties_SnipoccSDK_kt__q346kt();
  $this$HttpResponseValidator.v36(client$delegate$lambda$lambda$lambda$slambda_0(null));
  return Unit_instance;
}
function client$delegate$lambda$lambda$lambda$slambda_0(resultContinuation) {
  var i = new client$delegate$lambda$lambda$lambda$slambda(resultContinuation);
  var l = function (cause, _anonymous_parameter_1__qggqgd, $completion) {
    return i.r4c(cause, _anonymous_parameter_1__qggqgd, $completion);
  };
  l.$arity = 2;
  return l;
}
function ApiVersion_V1_getInstance() {
  ApiVersion_initEntries();
  return ApiVersion_V1_instance;
}
function client$factory() {
  return getPropertyCallableRef('client', 0, KProperty0, function () {
    return get_client();
  }, null);
}
var properties_initialized_SnipoccSDK_kt_we0x73;
function _init_properties_SnipoccSDK_kt__q346kt() {
  if (!properties_initialized_SnipoccSDK_kt_we0x73) {
    properties_initialized_SnipoccSDK_kt_we0x73 = true;
    ApplicationDispatcher = Dispatchers_getInstance().ax_1;
    client$delegate = lazy(client$delegate$lambda);
    // Inline function 'kotlin.collections.mutableListOf' call
    problemHandlers = ArrayList.o3();
    // Inline function 'kotlin.collections.mutableListOf' call
    exceptionHandlers = ArrayList.o3();
  }
}
function pathSegments(_this__u8e3s4, segments) {
  // Inline function 'kotlin.collections.forEachIndexed' call
  var index = 0;
  var inductionVariable = 0;
  var last = segments.length;
  while (inductionVariable < last) {
    var item = segments[inductionVariable];
    inductionVariable = inductionVariable + 1 | 0;
    // Inline function 'de.castalytics.snipocc.pathSegments.<anonymous>' call
    var tmp1 = index;
    index = tmp1 + 1 | 0;
    $l$block: {
      // Inline function 'kotlin.requireNotNull' call
      // Inline function 'kotlin.contracts.contract' call
      if (item == null) {
        // Inline function 'de.castalytics.snipocc.pathSegments.<anonymous>.<anonymous>' call
        var message = 'Path segments must not be null. Segment at index ' + tmp1;
        throw IllegalArgumentException.g3(toString(message));
      } else {
        break $l$block;
      }
    }
    appendPathSegments(_this__u8e3s4.o2q_1, [item]);
  }
}
function PointOfCareTestService$create$slambda_0($baseUrl, $poct, resultContinuation) {
  var i = new PointOfCareTestService$create$slambda($baseUrl, $poct, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$upload$slambda$lambda($file) {
  return function ($this$formData) {
    appendFile($this$formData, 'file', $file);
    return Unit_instance;
  };
}
function ProjectService$create$slambda_0($baseUrl, $project, resultContinuation) {
  var i = new ProjectService$create$slambda($baseUrl, $project, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$update$slambda_0($baseUrl, $id, $project, resultContinuation) {
  var i = new ProjectService$update$slambda($baseUrl, $id, $project, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$addUsers$slambda_0($baseUrl, $project, $users, resultContinuation) {
  var i = new ProjectService$addUsers$slambda($baseUrl, $project, $users, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$getUsers$slambda_0($baseUrl, $project, resultContinuation) {
  var i = new ProjectService$getUsers$slambda($baseUrl, $project, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$getTestTypes$slambda_0($baseUrl, $project, resultContinuation) {
  var i = new ProjectService$getTestTypes$slambda($baseUrl, $project, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$addTestTypes$slambda_0($baseUrl, $project, $testTypes, resultContinuation) {
  var i = new ProjectService$addTestTypes$slambda($baseUrl, $project, $testTypes, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$removeTestType$slambda_0($baseUrl, $project, $testType, resultContinuation) {
  var i = new ProjectService$removeTestType$slambda($baseUrl, $project, $testType, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$upload$slambda_0($baseUrl, $project, $label, $testType, $file, resultContinuation) {
  var i = new ProjectService$upload$slambda($baseUrl, $project, $label, $testType, $file, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$removeUser$slambda_0($baseUrl, $project, $user, resultContinuation) {
  var i = new ProjectService$removeUser$slambda($baseUrl, $project, $user, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$getCurrentUserProjects$slambda_0($baseUrl, resultContinuation) {
  var i = new ProjectService$getCurrentUserProjects$slambda($baseUrl, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$get$slambda_0($baseUrl, $projectId, resultContinuation) {
  var i = new ProjectService$get$slambda($baseUrl, $projectId, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$getAll$slambda_0($baseUrl, resultContinuation) {
  var i = new ProjectService$getAll$slambda($baseUrl, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function ProjectService$delete$slambda_0($baseUrl, $project, resultContinuation) {
  var i = new ProjectService$delete$slambda($baseUrl, $project, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$post$slambda_1($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$post$slambda($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$post$slambda_2($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$post$slambda_0($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$put$slambda_1($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$put$slambda($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$put$slambda_2($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$put$slambda_0($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$get$slambda_1($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$get$slambda($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$get$slambda_2($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$get$slambda_0($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$delete$slambda_1($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$delete$slambda($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$delete$slambda_2($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$delete$slambda_0($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$patch$slambda_1($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$patch$slambda($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function SnipoccServiceBase$patch$slambda_2($baseUrl, $block, resultContinuation) {
  var i = new SnipoccServiceBase$patch$slambda_0($baseUrl, $block, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function TestTypeService$create$slambda_0($baseUrl, $testType, resultContinuation) {
  var i = new TestTypeService$create$slambda($baseUrl, $testType, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function TestTypeService$update$slambda_0($baseUrl, $id, $testType, resultContinuation) {
  var i = new TestTypeService$update$slambda($baseUrl, $id, $testType, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function TestTypeService$getTestType$slambda_0($baseUrl, $id, resultContinuation) {
  var i = new TestTypeService$getTestType$slambda($baseUrl, $id, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function TestTypeService$getProjects$slambda_0($baseUrl, $id, resultContinuation) {
  var i = new TestTypeService$getProjects$slambda($baseUrl, $id, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function TestTypeService$deleteTestType$slambda_0($baseUrl, $id, resultContinuation) {
  var i = new TestTypeService$deleteTestType$slambda($baseUrl, $id, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function TestTypeService$loadAll$slambda_0($baseUrl, $filter, resultContinuation) {
  var i = new TestTypeService$loadAll$slambda($baseUrl, $filter, resultContinuation);
  var l = function ($this$request, $completion) {
    return i.e4d($this$request, $completion);
  };
  l.$arity = 1;
  return l;
}
function bodyAsType(_this__u8e3s4, $completion) {
  var tmp = new $bodyAsTypeCOROUTINE$1(_this__u8e3s4, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function fromDynamic(configure) {
  // Inline function 'js.core.jso' call
  var t = {};
  configure(t);
  return t;
}
//region block: post-declaration
setMetadataFor(CommonApi, 'CommonApi', interfaceMeta);
setMetadataFor(HelloAngular, 'HelloAngular', classMeta);
setMetadataFor(AiLabel, 'IAiLabel', interfaceMeta);
setMetadataFor(AuthRequest, 'IAuthRequest', interfaceMeta);
setMetadataFor(AuthResult, 'IAuthResult', interfaceMeta);
setMetadataFor(_AuthResult, 'AuthResult', classMeta, VOID, [AuthResult]);
setMetadataFor(TestFile, 'ITestFile', interfaceMeta);
setMetadataFor(IFileLabeled, 'IFileLabeled', interfaceMeta, VOID, [TestFile]);
setMetadataFor(FileType, 'FileType', classMeta);
setMetadataFor(ImagePoCTest, 'IImagePoCTest', interfaceMeta, VOID, [TestFile]);
setMetadataFor(PointOfCareTest, 'IPointOfCareTest', interfaceMeta);
setMetadataFor(ProblemDetail, 'ProblemDetail', interfaceMeta);
setMetadataFor(Project, 'IProject', interfaceMeta);
setMetadataFor(IReaderDataLabeled, 'IReaderDataLabeled', interfaceMeta);
setMetadataFor(Roles, 'Roles', classMeta);
setMetadataFor(TestType, 'ITestType', interfaceMeta);
setMetadataFor(User, 'IUser', interfaceMeta);
setMetadataFor(UserRegistration, 'IUserRegistration', interfaceMeta);
setMetadataFor(I18NKey, 'I18NKey', classMeta);
setMetadataFor(I18NKeyNode, 'I18NKeyNode', classMeta);
setMetadataFor(I18NEnum$fullKey$node$1, VOID, classMeta);
setMetadataFor(I18NEnum, 'I18NEnum', interfaceMeta);
protoOf(Auth).fullKey = fullKey;
setMetadataFor(Auth, 'Auth', classMeta, VOID, [Enum, I18NEnum]);
setMetadataFor(Error_0, 'Error', objectMeta);
setMetadataFor(Keys, 'Keys', classMeta, VOID, VOID, Keys);
setMetadataFor(I18NSelfNode, 'I18NSelfNode', objectMeta);
setMetadataFor(Companion, 'Companion', objectMeta);
setMetadataFor(I18NKeyLeaf, 'I18NKeyLeaf', classMeta);
setMetadataFor(prefixKey$1, VOID, classMeta);
setMetadataFor(ApiResponseWithBody$stream$lambda$lambda$o$init$slambda, 'ApiResponseWithBody$stream$lambda$lambda$o$init$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ApiResponseWithBody$stream$1$1$1$done$1, VOID, classMeta);
setMetadataFor(ApiResponseWithBody$stream$1$1$1$resolve$1, VOID, classMeta);
setMetadataFor(ApiResponseWithBody$stream$lambda$lambda$o$read$slambda, 'ApiResponseWithBody$stream$lambda$lambda$o$read$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ApiResponseWithBody$stream$1$1$1, VOID, classMeta);
setMetadataFor(ApiResponseWithBody$response$slambda, 'ApiResponseWithBody$response$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ApiResponseWithBody, 'ApiResponseWithBody', classMeta);
setMetadataFor(ApiResponse$response$slambda, 'ApiResponse$response$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ApiResponse, 'ApiResponse', classMeta);
setMetadataFor(ProblemDetailException, 'ProblemDetailException', classMeta);
setMetadataFor(HttpStatus, 'HttpStatus', classMeta);
setMetadataFor(HttpVersion, 'HttpVersion', classMeta);
setMetadataFor(HttpResponse_0, 'HttpResponse', classMeta);
setMetadataFor(Success, 'Success', classMeta);
setMetadataFor(Body, 'Body', classMeta);
setMetadataFor(Empty, 'Empty', classMeta);
setMetadataFor(Error_1, 'Error', classMeta);
setMetadataFor(HttpError, 'HttpError', classMeta);
setMetadataFor(NetworkError, 'NetworkError', classMeta, VOID, VOID, NetworkError);
setMetadataFor($deserializeCOROUTINE$0, '$deserializeCOROUTINE$0', classMeta);
protoOf(JsonConverter).u2l = serialize;
setMetadataFor(JsonConverter, 'JsonConverter', objectMeta, VOID, [ContentConverter], VOID, VOID, VOID, [4, 3]);
setMetadataFor(ReadableStreamContent$readFrom$slambda, 'ReadableStreamContent$readFrom$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ReadableStreamContent, 'ReadableStreamContent', classMeta, VOID, [CoroutineScope, ReadChannelContent]);
setMetadataFor(SnipoccSDK$slambda, 'SnipoccSDK$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [2]);
setMetadataFor(SnipoccSDK, 'SnipoccSDK', classMeta, VOID, VOID, SnipoccSDK);
setMetadataFor(ApiVersion, 'ApiVersion', classMeta);
setMetadataFor(client$2$1$1$1, VOID, classMeta);
setMetadataFor(client$delegate$lambda$lambda$lambda$slambda, 'client$delegate$lambda$lambda$lambda$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [2]);
setMetadataFor(PointOfCareTestService$create$slambda, 'PointOfCareTestService$create$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase, 'SnipoccServiceBase', classMeta);
setMetadataFor(PointOfCareTestService, 'PointOfCareTestService', classMeta);
setMetadataFor(ProjectService$create$slambda, 'ProjectService$create$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$update$slambda, 'ProjectService$update$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$addUsers$slambda, 'ProjectService$addUsers$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$getUsers$slambda, 'ProjectService$getUsers$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$getTestTypes$slambda, 'ProjectService$getTestTypes$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$addTestTypes$slambda, 'ProjectService$addTestTypes$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$removeTestType$slambda, 'ProjectService$removeTestType$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$upload$slambda, 'ProjectService$upload$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$removeUser$slambda, 'ProjectService$removeUser$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$getCurrentUserProjects$slambda, 'ProjectService$getCurrentUserProjects$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$get$slambda, 'ProjectService$get$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$getAll$slambda, 'ProjectService$getAll$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService$delete$slambda, 'ProjectService$delete$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(ProjectService, 'ProjectService', classMeta);
setMetadataFor(SnipoccServiceBase$post$slambda, 'SnipoccServiceBase$post$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$post$slambda_0, 'SnipoccServiceBase$post$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$put$slambda, 'SnipoccServiceBase$put$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$put$slambda_0, 'SnipoccServiceBase$put$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$get$slambda, 'SnipoccServiceBase$get$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$get$slambda_0, 'SnipoccServiceBase$get$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$delete$slambda, 'SnipoccServiceBase$delete$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$delete$slambda_0, 'SnipoccServiceBase$delete$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$patch$slambda, 'SnipoccServiceBase$patch$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(SnipoccServiceBase$patch$slambda_0, 'SnipoccServiceBase$patch$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(TestTypeService$create$slambda, 'TestTypeService$create$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(TestTypeService$update$slambda, 'TestTypeService$update$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(TestTypeService$getTestType$slambda, 'TestTypeService$getTestType$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(TestTypeService$getProjects$slambda, 'TestTypeService$getProjects$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(TestTypeService$deleteTestType$slambda, 'TestTypeService$deleteTestType$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(TestTypeService$loadAll$slambda, 'TestTypeService$loadAll$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(TestTypeService, 'TestTypeService', classMeta);
setMetadataFor($bodyAsTypeCOROUTINE$1, '$bodyAsTypeCOROUTINE$1', classMeta);
//endregion
//region block: init
Companion_instance_0 = new Companion();
JsonConverter_instance = new JsonConverter();
//endregion
//region block: exports
FileType.values = values;
FileType.valueOf = valueOf;
defineProp(FileType, 'FISENSE_ASCII', FileType_FISENSE_ASCII_getInstance);
defineProp(FileType, 'FISENSE_BINARY', FileType_FISENSE_BINARY_getInstance);
defineProp(FileType, 'NVM', FileType_NVM_getInstance);
defineProp(FileType, 'IMAGE', FileType_IMAGE_getInstance);
Roles.values = values_0;
Roles.valueOf = valueOf_0;
defineProp(Roles, 'USER', Roles_USER_getInstance);
defineProp(Roles, 'PROJECT_ADMIN', Roles_PROJECT_ADMIN_getInstance);
defineProp(Roles, 'ADMIN', Roles_ADMIN_getInstance);
defineProp(Keys, 'Error', Error_getInstance);
Error_0.prototype.Auth = Auth;
Error_0.prototype.Auth.values = values_1;
Error_0.prototype.Auth.valueOf = valueOf_1;
defineProp(Error_0.prototype.Auth, 'BAD_CREDENTIALS', Auth_BAD_CREDENTIALS_getInstance);
defineProp(Error_0.prototype.Auth, 'NOT_ACTIVATED', Auth_NOT_ACTIVATED_getInstance);
defineProp(Error_0.prototype.Auth, 'INSUFFICIENT_SCOPE', Auth_INSUFFICIENT_SCOPE_getInstance);
defineProp(Error_0.prototype.Auth, 'GENERIC', Auth_GENERIC_getInstance);
HttpResponse_0.Success = Success;
HttpResponse_0.Success.Body = Body;
HttpResponse_0.Success.Empty = Empty;
HttpResponse_0.Error = Error_1;
HttpResponse_0.Error.HttpError = HttpError;
HttpResponse_0.Error.NetworkError = NetworkError;
ApiVersion.values = values_2;
ApiVersion.valueOf = valueOf_2;
defineProp(ApiVersion, 'V1', ApiVersion_V1_getInstance);
export {
  HelloAngular as HelloAngular,
  FileType as FileType,
  fileTypeFromName as fileTypeFromName,
  Roles as Roles,
  roleFromName as roleFromName,
  I18NKey as I18NKey,
  I18NKeyNode as I18NKeyNode,
  Keys as Keys,
  ApiResponseWithBody as ApiResponseWithBody,
  ApiResponse as ApiResponse,
  HttpStatus as HttpStatus,
  HttpVersion as HttpVersion,
  HttpResponse_0 as HttpResponse,
  SnipoccSDK as SnipoccSDK,
  ApiVersion as ApiVersion,
  PointOfCareTestService as PointOfCareTestService,
  ProjectService as ProjectService,
  SnipoccServiceBase as SnipoccServiceBase,
  TestTypeService as TestTypeService,
};
//endregion


