/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Component, type OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';
import { langData, type LangEntry } from '@core/models/locale';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent implements OnInit {


  protected get langs(): LangEntry[] {
    return sortBy([...langData.values()], ['name'])
  }

  protected selectedLang: LangEntry | undefined
  constructor(private translateService: TranslateService) {}

  protected langChange(value: LangEntry) {
    console.debug("changing language to", value)
    this.translateService.use(value.lang).subscribe()
  }

  ngOnInit(): void {
    const defaultLang = this.translateService.getDefaultLang()
    this.selectedLang = langData.get(defaultLang)

    this.translateService.onLangChange.subscribe(event => {
      this.selectedLang = langData.get(event.lang)
    })
  }
}
