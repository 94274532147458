/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { roleFromName, Roles } from '@snipocc/api';
import { UserService } from '@core/services/user.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasAnyRole]',
})
export class HasAnyRoleDirective {

  @Input()
  public hasAnyRoleElse: TemplateRef<unknown> | undefined;
  private hasView = false;

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef,
              private userService: UserService) {
  }

  @Input() set hasAnyRole(roles: (string | Roles)[]) {
    this.userService.hasAnyRole(
      ...roles
        .map(r => r instanceof Roles ? r : roleFromName(r))
        .filter((r): r is Roles => r !== null),
    ).subscribe(hasRoles => {
      if (!this.hasView && hasRoles) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (this.hasView && !hasRoles) {
        this.viewContainer.clear();
        this.hasView = false;
      } else {
        this.viewContainer.clear();
        if (this.hasAnyRoleElse) {
          this.viewContainer.createEmbeddedView(this.hasAnyRoleElse);
        }
      }
    });

  }

}
