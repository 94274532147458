import {
  CoroutineImpl2sn3kjnwmfr10 as CoroutineImpl,
  get_COROUTINE_SUSPENDED3ujt3p13qm4iy as get_COROUTINE_SUSPENDED,
  THROW_CCE2g6jy02ryeudk as THROW_CCE,
  isCharSequence1ju9jr1w86plq as isCharSequence,
  trim11nh7r46at6sx as trim,
  toString1pkumu07cwy4m as toString,
  Unit_instance1fbcbse1fwigr as Unit_instance,
  classMetawt99a3kyl3us as classMeta,
  setMetadataForzkg9su7xd76l as setMetadataFor,
  charSequenceLength3278n89t01tmv as charSequenceLength,
  StringBuildermazzzhj6kkai as StringBuilder,
  _Char___init__impl__6a9atx281r2pd9o601g as _Char___init__impl__6a9atx,
  VOID7hggqo3abtya as VOID,
  Enum3alwj03lh1n41 as Enum,
  objectMeta213120oau977m as objectMeta,
  println2shhhgwwt4c61 as println,
  ArrayList3it5z8td81qkl as ArrayList,
  Collection1k04j3hzsbod0 as Collection,
  isInterface3d6p8outrmvmk as isInterface,
  emptyList1g2z5xcrvp2zy as emptyList,
  toList3jhuyej2anx2q as toList,
  sortedWith2csnbbb21k0lg as sortedWith,
  joinToString1cxrrlmo0chqs as joinToString,
  compareValues1n2ayl87ihzfk as compareValues,
} from './kotlin-kotlin-stdlib.mjs';
import {
  Job13y4jkazwjho0 as Job,
  GlobalScope_instance1sfulufhd2ijt as GlobalScope_instance,
  Dispatchers_getInstanceitgtkvqfcnx3 as Dispatchers_getInstance,
  launch1c91vkjzdi9sd as launch,
  CoroutineScopefcb5f5dwqcas as CoroutineScope,
} from './kotlinx-coroutines-core.mjs';
import { atomic$boolean$1iggki4z65a2h as atomic$boolean$1 } from './kotlinx-atomicfu.mjs';
import {
  ReadChannelContentz1amb4hnpqp4 as ReadChannelContent,
  OutgoingContent3t2ohmyam9o76 as OutgoingContent,
  Url2829xxbhyjpua as Url,
  HttpHeaders_getInstanceelogg8fjd54u as HttpHeaders_getInstance,
  charset1dribv3ku48b1 as charset,
  contentType317fn4f991q9a as contentType,
  WriteChannelContent1d7f40hsfcaxg as WriteChannelContent,
  ByteArrayContent2n0wb43y6ugs1 as ByteArrayContent,
} from './ktor-ktor-http.mjs';
import {
  Phases_getInstance18vqybk3y2ism as Phases_getInstance,
  Phases_getInstance2gb8yk5kt1qdy as Phases_getInstance_0,
  Phases_getInstance3cv4l5wlctlnh as Phases_getInstance_1,
  Plugin_getInstance3r34ly3r3uhxc as Plugin_getInstance,
  ResponseObserver69e7bll10ek6 as ResponseObserver,
  HttpClientPlugin3rce8c1crrw1q as HttpClientPlugin,
  HttpResponse1532ob1hsse1y as HttpResponse,
  HttpResponseContainer3r9yzy4mwwvc9 as HttpResponseContainer,
} from './ktor-ktor-client-core.mjs';
import {
  Charsets_getInstanceqs70pvl4noow as Charsets_getInstance,
  ByteChannelgfqke9q216t7 as ByteChannel,
  readText3x4cv5p7hylp as readText,
  writer1eia5its2a1fh as writer,
  WriterScope3b0bo1enaee6b as WriterScope,
  closeqm43o3junf8o as close,
  writeFullyuorxug1itxv3 as writeFully,
} from './ktor-ktor-io.mjs';
import {
  AttributeKey3aq8ytwgx54f7 as AttributeKey,
  PipelineContext34fsb0mycu471 as PipelineContext,
  copyToBoth3ldmovxh3mg5n as copyToBoth,
} from './ktor-ktor-utils.mjs';
//region block: imports
//endregion
//region block: pre-declaration
class $logResponseExceptionCOROUTINE$0 extends CoroutineImpl {
  constructor(_this__u8e3s4, message, resultContinuation) {
    super(resultContinuation);
    this.v3x_1 = _this__u8e3s4;
    this.w3x_1 = message;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.v3x_1.a3y_1.pw(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var this_0 = this.w3x_1;
            this.v3x_1.x3x_1.ul(toString(trim(isCharSequence(this_0) ? this_0 : THROW_CCE())));
            return Unit_instance;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $logResponseBodyCOROUTINE$1 extends CoroutineImpl {
  constructor(_this__u8e3s4, message, resultContinuation) {
    super(resultContinuation);
    this.m3y_1 = _this__u8e3s4;
    this.n3y_1 = message;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.m3y_1.b3y_1.pw(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.m3y_1.z3x_1.i7(this.n3y_1);
            return Unit_instance;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class $closeResponseLogCOROUTINE$2 extends CoroutineImpl {
  constructor(_this__u8e3s4, resultContinuation) {
    super(resultContinuation);
    this.w3y_1 = _this__u8e3s4;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            if (!this.w3y_1.d3y_1.atomicfu$compareAndSet(false, true))
              return Unit_instance;
            this.te_1 = 1;
            suspendResult = this.w3y_1.a3y_1.pw(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var message = toString(trim(this.w3y_1.z3x_1));
            if (charSequenceLength(message) > 0) {
              this.w3y_1.x3x_1.ul(message);
            }

            return Unit_instance;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class HttpClientCallLogger {
  constructor(logger) {
    this.x3x_1 = logger;
    this.y3x_1 = StringBuilder.ma();
    this.z3x_1 = StringBuilder.ma();
    this.a3y_1 = Job();
    this.b3y_1 = Job();
    this.c3y_1 = atomic$boolean$1(false);
    this.d3y_1 = atomic$boolean$1(false);
  }
  x3y(message) {
    // Inline function 'kotlin.text.appendLine' call
    var this_0 = this.y3x_1;
    // Inline function 'kotlin.text.trim' call
    var value = toString(trim(isCharSequence(message) ? message : THROW_CCE()));
    // Inline function 'kotlin.text.appendLine' call
    this_0.i7(value).j7(_Char___init__impl__6a9atx(10));
  }
  y3y(message) {
    // Inline function 'kotlin.text.appendLine' call
    var this_0 = this.z3x_1;
    // Inline function 'kotlin.text.trim' call
    var value = toString(trim(isCharSequence(message) ? message : THROW_CCE()));
    // Inline function 'kotlin.text.appendLine' call
    this_0.i7(value).j7(_Char___init__impl__6a9atx(10));
    this.b3y_1.ow();
  }
  z3y(message, $completion) {
    var tmp = new $logResponseExceptionCOROUTINE$0(this, message, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  a3z(message, $completion) {
    var tmp = new $logResponseBodyCOROUTINE$1(this, message, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  b3z() {
    if (!this.c3y_1.atomicfu$compareAndSet(false, true))
      return Unit_instance;
    try {
      var message = toString(trim(this.y3x_1));
      // Inline function 'kotlin.text.isNotEmpty' call
      if (charSequenceLength(message) > 0) {
        this.x3x_1.ul(message);
      }
    }finally {
      this.a3y_1.ow();
    }
  }
  c3z($completion) {
    var tmp = new $closeResponseLogCOROUTINE$2(this, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
}
class LogLevel extends Enum {
  constructor(name, ordinal, info, headers, body) {
    super(name, ordinal);
    this.f3z_1 = info;
    this.g3z_1 = headers;
    this.h3z_1 = body;
  }
}
class LoggedContent extends ReadChannelContent {
  constructor(originalContent, channel) {
    super();
    this.j3z_1 = originalContent;
    this.k3z_1 = channel;
    this.l3z_1 = this.j3z_1.c2i();
    this.m3z_1 = this.j3z_1.d2i();
    this.n3z_1 = this.j3z_1.dn();
    this.o3z_1 = this.j3z_1.w2c();
  }
  c2i() {
    return this.l3z_1;
  }
  d2i() {
    return this.m3z_1;
  }
  dn() {
    return this.n3z_1;
  }
  w2c() {
    return this.o3z_1;
  }
  a2j() {
    return this.k3z_1;
  }
}
class Companion {}
class SimpleLogger {
  ul(message) {
    println('HttpClient: ' + message);
  }
}
class Config {
  constructor() {
    var tmp = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp.p3z_1 = ArrayList.o3();
    var tmp_0 = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp_0.q3z_1 = ArrayList.o3();
    this.r3z_1 = null;
    this.s3z_1 = LogLevel_HEADERS_getInstance();
  }
  t3z(value) {
    this.r3z_1 = value;
  }
  u3z() {
    var tmp0_elvis_lhs = this.r3z_1;
    return tmp0_elvis_lhs == null ? get_DEFAULT(Companion_instance) : tmp0_elvis_lhs;
  }
}
class Companion_0 {
  constructor() {
    Companion_instance_0 = this;
    this.b40_1 = new AttributeKey('ClientLogging');
  }
  t2() {
    return this.b40_1;
  }
  c40(block) {
    // Inline function 'kotlin.apply' call
    var this_0 = new Config();
    // Inline function 'kotlin.contracts.contract' call
    block(this_0);
    var config = this_0;
    return new Logging(config.u3z(), config.s3z_1, config.p3z_1, config.q3z_1);
  }
  u2r(block) {
    return this.c40(block);
  }
  d40(plugin, scope) {
    setupRequestLogging(plugin, scope);
    setupResponseLogging(plugin, scope);
  }
  v2r(plugin, scope) {
    return this.d40(plugin instanceof Logging ? plugin : THROW_CCE(), scope);
  }
}
class Logging$setupRequestLogging$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.m40_1 = this$0;
    super(resultContinuation, $box);
  }
  h2p($this$intercept, it, $completion) {
    var tmp = this.i2p($this$intercept, it, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  j2p(p1, p2, $completion) {
    var tmp = p1 instanceof PipelineContext ? p1 : THROW_CCE();
    return this.h2p(tmp, !(p2 == null) ? p2 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 8;
            if (!shouldBeLogged(this.m40_1, this.n40_1.a24_1)) {
              this.n40_1.a24_1.t2q_1.d1x(get_DisableLogging(), Unit_instance);
              return Unit_instance;
            }

            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = logRequest(this.m40_1, this.n40_1.a24_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.p40_1 = suspendResult;
            this.ue_1 = 8;
            this.te_1 = 3;
            continue $sm;
          case 2:
            this.ue_1 = 8;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof Error) {
              this.q40_1 = this.we_1;
              var tmp_1 = this;
              tmp_1.p40_1 = null;
              this.te_1 = 3;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 3:
            this.ue_1 = 8;
            this.r40_1 = this.p40_1;
            this.te_1 = 4;
            continue $sm;
          case 4:
            this.ue_1 = 7;
            this.ue_1 = 6;
            this.te_1 = 5;
            var tmp0_elvis_lhs = this.r40_1;
            suspendResult = this.n40_1.c23(tmp0_elvis_lhs == null ? this.n40_1.b23() : tmp0_elvis_lhs, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            var tmp_2 = this;
            tmp_2.s40_1 = Unit_instance;
            this.ue_1 = 8;
            this.te_1 = 9;
            continue $sm;
          case 6:
            this.ue_1 = 7;
            var tmp_3 = this.we_1;
            if (tmp_3 instanceof Error) {
              var cause = this.we_1;
              var tmp_4 = this;
              logRequestException(this.m40_1, this.n40_1.a24_1, cause);
              throw cause;
            } else {
              throw this.we_1;
            }

          case 7:
            this.ue_1 = 8;
            var t = this.we_1;
            throw t;
          case 8:
            throw this.we_1;
          case 9:
            this.ue_1 = 8;
            return Unit_instance;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 8) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  i2p($this$intercept, it, completion) {
    var i = new Logging$setupRequestLogging$slambda(this.m40_1, completion);
    i.n40_1 = $this$intercept;
    i.o40_1 = it;
    return i;
  }
}
class Logging$logRequestBody$slambda extends CoroutineImpl {
  constructor($channel, $charset, $requestLog, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.b41_1 = $channel;
    $box.c41_1 = $charset;
    $box.d41_1 = $requestLog;
    super(resultContinuation, $box);
  }
  y1m($this$launch, $completion) {
    var tmp = this.z1m($this$launch, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.y1m((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            var tmp_0 = this;
            tmp_0.f41_1 = this.b41_1;
            var tmp_1 = this;
            tmp_1.g41_1 = this.c41_1;
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.f41_1.w1l(VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            this.h41_1 = readText(ARGUMENT, this.g41_1);
            this.ue_1 = 3;
            this.te_1 = 4;
            continue $sm;
          case 2:
            this.ue_1 = 3;
            var tmp_2 = this.we_1;
            if (tmp_2 instanceof Error) {
              var cause = this.we_1;
              var tmp_3 = this;
              tmp_3.h41_1 = null;
              this.te_1 = 4;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 3:
            throw this.we_1;
          case 4:
            this.ue_1 = 3;
            var tmp0_elvis_lhs = this.h41_1;
            var text = tmp0_elvis_lhs == null ? '[request body omitted]' : tmp0_elvis_lhs;
            var this_0 = this.d41_1;
            var value = 'BODY START';
            this_0.i7(value).j7(_Char___init__impl__6a9atx(10));
            this.d41_1.i7(text).j7(_Char___init__impl__6a9atx(10));
            this.d41_1.i7('BODY END');
            return Unit_instance;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  z1m($this$launch, completion) {
    var i = new Logging$logRequestBody$slambda(this.b41_1, this.c41_1, this.d41_1, completion);
    i.e41_1 = $this$launch;
    return i;
  }
}
class Logging$setupResponseLogging$slambda extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.q41_1 = this$0;
    super(resultContinuation, $box);
  }
  s2z($this$intercept, response, $completion) {
    var tmp = this.t2z($this$intercept, response, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  j2p(p1, p2, $completion) {
    var tmp = p1 instanceof PipelineContext ? p1 : THROW_CCE();
    return this.s2z(tmp, p2 instanceof HttpResponse ? p2 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 10;
            if (this.q41_1.w3z_1.equals(LogLevel_NONE_getInstance()) ? true : this.s41_1.k2u().u2s().c1x(get_DisableLogging()))
              return Unit_instance;
            this.t41_1 = this.s41_1.k2u().u2s().a1x(get_ClientCallLogger());
            this.u41_1 = StringBuilder.ma();
            this.v41_1 = false;
            this.te_1 = 1;
            continue $sm;
          case 1:
            this.ue_1 = 4;
            this.ue_1 = 3;
            logResponseHeader(this.u41_1, this.s41_1.k2u().p2p(), this.q41_1.w3z_1, this.q41_1.y3z_1);
            this.te_1 = 2;
            suspendResult = this.r41_1.c23(this.r41_1.b23(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var tmp_0 = this;
            tmp_0.w41_1 = Unit_instance;
            this.ue_1 = 10;
            this.te_1 = 7;
            continue $sm;
          case 3:
            this.ue_1 = 4;
            var tmp_1 = this.we_1;
            if (tmp_1 instanceof Error) {
              this.x41_1 = this.we_1;
              var tmp_2 = this;
              logResponseException(this.q41_1, this.u41_1, this.s41_1.k2u().f2t(), this.x41_1);
              this.v41_1 = true;
              throw this.x41_1;
            } else {
              throw this.we_1;
            }

          case 4:
            this.ue_1 = 10;
            this.y41_1 = this.we_1;
            this.t41_1.y3y(this.u41_1.toString());
            if (this.v41_1 ? true : !this.q41_1.w3z_1.h3z_1) {
              this.te_1 = 5;
              suspendResult = this.t41_1.c3z(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 6;
              continue $sm;
            }

          case 5:
            this.te_1 = 6;
            continue $sm;
          case 6:
            throw this.y41_1;
          case 7:
            this.ue_1 = 10;
            this.t41_1.y3y(this.u41_1.toString());
            if (this.v41_1 ? true : !this.q41_1.w3z_1.h3z_1) {
              this.te_1 = 8;
              suspendResult = this.t41_1.c3z(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.te_1 = 9;
              continue $sm;
            }

          case 8:
            this.te_1 = 9;
            continue $sm;
          case 9:
            return Unit_instance;
          case 10:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 10) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  t2z($this$intercept, response, completion) {
    var i = new Logging$setupResponseLogging$slambda(this.q41_1, completion);
    i.r41_1 = $this$intercept;
    i.s41_1 = response;
    return i;
  }
}
class Logging$setupResponseLogging$slambda_0 extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.h42_1 = this$0;
    super(resultContinuation, $box);
  }
  c2q($this$intercept, it, $completion) {
    var tmp = this.d2q($this$intercept, it, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  j2p(p1, p2, $completion) {
    var tmp = p1 instanceof PipelineContext ? p1 : THROW_CCE();
    return this.c2q(tmp, p2 instanceof HttpResponseContainer ? p2 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 6;
            if (this.h42_1.w3z_1.equals(LogLevel_NONE_getInstance()) ? true : this.i42_1.a24_1.u2s().c1x(get_DisableLogging())) {
              return Unit_instance;
            }

            this.ue_1 = 3;
            this.te_1 = 1;
            suspendResult = this.i42_1.d23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.ue_1 = 6;
            this.te_1 = 2;
            continue $sm;
          case 2:
            this.ue_1 = 6;
            return Unit_instance;
          case 3:
            this.ue_1 = 6;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof Error) {
              this.k42_1 = this.we_1;
              this.l42_1 = StringBuilder.ma();
              this.m42_1 = this.i42_1.a24_1.u2s().a1x(get_ClientCallLogger());
              logResponseException(this.h42_1, this.l42_1, this.i42_1.a24_1.f2t(), this.k42_1);
              this.te_1 = 4;
              suspendResult = this.m42_1.z3y(this.l42_1.toString(), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 4:
            this.te_1 = 5;
            suspendResult = this.m42_1.c3z(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            throw this.k42_1;
          case 6:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 6) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  d2q($this$intercept, it, completion) {
    var i = new Logging$setupResponseLogging$slambda_0(this.h42_1, completion);
    i.i42_1 = $this$intercept;
    i.j42_1 = it;
    return i;
  }
}
class Logging$setupResponseLogging$slambda_1 extends CoroutineImpl {
  constructor(this$0, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.v42_1 = this$0;
    super(resultContinuation, $box);
  }
  o31(it, $completion) {
    var tmp = this.p31(it, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.o31(p1 instanceof HttpResponse ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 10;
            if (this.v42_1.w3z_1.equals(LogLevel_NONE_getInstance()) ? true : this.w42_1.k2u().u2s().c1x(get_DisableLogging())) {
              return Unit_instance;
            }

            this.x42_1 = this.w42_1.k2u().u2s().a1x(get_ClientCallLogger());
            this.y42_1 = StringBuilder.ma();
            this.te_1 = 1;
            continue $sm;
          case 1:
            this.ue_1 = 4;
            this.ue_1 = 3;
            this.te_1 = 2;
            suspendResult = logResponseBody(this.y42_1, contentType(this.w42_1), this.w42_1.o2t(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.z42_1 = suspendResult;
            this.ue_1 = 10;
            this.te_1 = 7;
            continue $sm;
          case 3:
            this.ue_1 = 4;
            var tmp_0 = this.we_1;
            if (tmp_0 instanceof Error) {
              this.a43_1 = this.we_1;
              var tmp_1 = this;
              tmp_1.z42_1 = Unit_instance;
              this.ue_1 = 10;
              this.te_1 = 7;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 4:
            this.ue_1 = 10;
            this.b43_1 = this.we_1;
            this.te_1 = 5;
            var this_0 = this.y42_1.toString();
            suspendResult = this.x42_1.a3z(toString(trim(isCharSequence(this_0) ? this_0 : THROW_CCE())), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            this.te_1 = 6;
            suspendResult = this.x42_1.c3z(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 6:
            throw this.b43_1;
          case 7:
            this.ue_1 = 10;
            this.te_1 = 8;
            var this_1 = this.y42_1.toString();
            suspendResult = this.x42_1.a3z(toString(trim(isCharSequence(this_1) ? this_1 : THROW_CCE())), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 8:
            this.te_1 = 9;
            suspendResult = this.x42_1.c3z(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 9:
            return Unit_instance;
          case 10:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 10) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  p31(it, completion) {
    var i = new Logging$setupResponseLogging$slambda_1(this.v42_1, completion);
    i.w42_1 = it;
    return i;
  }
}
class Logging {
  constructor(logger, level, filters, sanitizedHeaders) {
    Companion_getInstance_0();
    filters = filters === VOID ? emptyList() : filters;
    this.v3z_1 = logger;
    this.w3z_1 = level;
    this.x3z_1 = filters;
    this.y3z_1 = sanitizedHeaders;
  }
}
class sam$kotlin_Comparator$0 {
  constructor(function_0) {
    this.s43_1 = function_0;
  }
  cc(a, b) {
    return this.s43_1(a, b);
  }
  compare(a, b) {
    return this.cc(a, b);
  }
}
class $logResponseBodyCOROUTINE$4 extends CoroutineImpl {
  constructor(log, contentType, content, resultContinuation) {
    super(resultContinuation);
    this.k43_1 = log;
    this.l43_1 = contentType;
    this.m43_1 = content;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 4;
            var tmp_0 = this;
            tmp_0.n43_1 = this.k43_1;
            var tmp_1 = this;
            tmp_1.o43_1 = this.n43_1;
            var this_0 = this.o43_1;
            var value = 'BODY Content-Type: ' + this.l43_1;
            this_0.i7(value).j7(_Char___init__impl__6a9atx(10));
            var this_1 = this.o43_1;
            var value_0 = 'BODY START';
            this_1.i7(value_0).j7(_Char___init__impl__6a9atx(10));
            var tmp_2 = this;
            tmp_2.p43_1 = this.m43_1;
            var tmp_3 = this;
            var tmp0_safe_receiver = this.l43_1;
            var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : charset(tmp0_safe_receiver);
            tmp_3.q43_1 = tmp1_elvis_lhs == null ? Charsets_getInstance().e1s_1 : tmp1_elvis_lhs;
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.p43_1.w1l(VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            this.r43_1 = readText(ARGUMENT, this.q43_1);
            this.ue_1 = 4;
            this.te_1 = 3;
            continue $sm;
          case 2:
            this.ue_1 = 4;
            var tmp_4 = this.we_1;
            if (tmp_4 instanceof Error) {
              var cause = this.we_1;
              var tmp_5 = this;
              tmp_5.r43_1 = null;
              this.te_1 = 3;
              continue $sm;
            } else {
              throw this.we_1;
            }

          case 3:
            this.ue_1 = 4;
            var tmp2_elvis_lhs = this.r43_1;
            var message = tmp2_elvis_lhs == null ? '[response body omitted]' : tmp2_elvis_lhs;
            this.o43_1.i7(message).j7(_Char___init__impl__6a9atx(10));
            this.o43_1.i7('BODY END');
            return Unit_instance;
          case 4:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 4) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
class toReadChannel$slambda extends CoroutineImpl {
  constructor($this_toReadChannel, resultContinuation, $box) {
    if ($box === VOID)
      $box = {};
    $box.n44_1 = $this_toReadChannel;
    super(resultContinuation, $box);
  }
  n2v($this$writer, $completion) {
    var tmp = this.o2v($this$writer, $completion);
    tmp.ve_1 = Unit_instance;
    tmp.we_1 = null;
    return tmp.gf();
  }
  wf(p1, $completion) {
    return this.n2v((!(p1 == null) ? isInterface(p1, WriterScope) : false) ? p1 : THROW_CCE(), $completion);
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 2;
            this.te_1 = 1;
            suspendResult = this.n44_1.c2j(this.o44_1.p11(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_instance;
          case 2:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 2) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
  o2v($this$writer, completion) {
    var i = new toReadChannel$slambda(this.n44_1, completion);
    i.o44_1 = $this$writer;
    return i;
  }
}
class $observeCOROUTINE$5 extends CoroutineImpl {
  constructor(_this__u8e3s4, log, resultContinuation) {
    super(resultContinuation);
    this.b44_1 = _this__u8e3s4;
    this.c44_1 = log;
  }
  gf() {
    var suspendResult = this.ve_1;
    $sm: do
      try {
        var tmp = this.te_1;
        switch (tmp) {
          case 0:
            this.ue_1 = 3;
            this.d44_1 = this.b44_1;
            var tmp_0 = this.d44_1;
            if (tmp_0 instanceof ByteArrayContent) {
              this.te_1 = 1;
              suspendResult = writeFully(this.c44_1, this.b44_1.e2i(), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              var tmp_1 = this.d44_1;
              if (tmp_1 instanceof ReadChannelContent) {
                var tmp_2 = this;
                var responseChannel = ByteChannel();
                var content = this.b44_1.a2j();
                copyToBoth(content, this.c44_1, responseChannel);
                tmp_2.e44_1 = new LoggedContent(this.b44_1, responseChannel);
                this.te_1 = 2;
                continue $sm;
              } else {
                var tmp_3 = this.d44_1;
                if (tmp_3 instanceof WriteChannelContent) {
                  var tmp_4 = this;
                  var responseChannel_0 = ByteChannel();
                  var content_0 = toReadChannel(this.b44_1);
                  copyToBoth(content_0, this.c44_1, responseChannel_0);
                  tmp_4.e44_1 = new LoggedContent(this.b44_1, responseChannel_0);
                  this.te_1 = 2;
                  continue $sm;
                } else {
                  var tmp_5 = this;
                  close(this.c44_1);
                  tmp_5.e44_1 = this.b44_1;
                  this.te_1 = 2;
                  continue $sm;
                }
              }
            }

          case 1:
            close(this.c44_1);
            this.e44_1 = this.b44_1;
            this.te_1 = 2;
            continue $sm;
          case 2:
            return this.e44_1;
          case 3:
            throw this.we_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.ue_1 === 3) {
          throw e;
        } else {
          this.te_1 = this.ue_1;
          this.we_1 = e;
        }
      }
     while (true);
  }
}
//endregion
var LogLevel_ALL_instance;
var LogLevel_HEADERS_instance;
var LogLevel_BODY_instance;
var LogLevel_INFO_instance;
var LogLevel_NONE_instance;
var LogLevel_entriesInitialized;
function LogLevel_initEntries() {
  if (LogLevel_entriesInitialized)
    return Unit_instance;
  LogLevel_entriesInitialized = true;
  LogLevel_ALL_instance = new LogLevel('ALL', 0, true, true, true);
  LogLevel_HEADERS_instance = new LogLevel('HEADERS', 1, true, true, false);
  LogLevel_BODY_instance = new LogLevel('BODY', 2, true, false, true);
  LogLevel_INFO_instance = new LogLevel('INFO', 3, true, false, false);
  LogLevel_NONE_instance = new LogLevel('NONE', 4, false, false, false);
}
function LogLevel_HEADERS_getInstance() {
  LogLevel_initEntries();
  return LogLevel_HEADERS_instance;
}
function LogLevel_INFO_getInstance() {
  LogLevel_initEntries();
  return LogLevel_INFO_instance;
}
function LogLevel_NONE_getInstance() {
  LogLevel_initEntries();
  return LogLevel_NONE_instance;
}
var Companion_instance;
function Companion_getInstance() {
  return Companion_instance;
}
function get_SIMPLE(_this__u8e3s4) {
  return new SimpleLogger();
}
function get_ClientCallLogger() {
  _init_properties_Logging_kt__66pui5();
  return ClientCallLogger;
}
var ClientCallLogger;
function get_DisableLogging() {
  _init_properties_Logging_kt__66pui5();
  return DisableLogging;
}
var DisableLogging;
function setupRequestLogging($this, client) {
  var tmp = Phases_getInstance().g2r_1;
  client.p2o_1.g24(tmp, Logging$setupRequestLogging$slambda_0($this, null));
}
function logRequest($this, request, $completion) {
  var tmp = request.r2q_1;
  var content = tmp instanceof OutgoingContent ? tmp : THROW_CCE();
  var logger = new HttpClientCallLogger($this.v3z_1);
  request.t2q_1.d1x(get_ClientCallLogger(), logger);
  // Inline function 'kotlin.text.buildString' call
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'kotlin.apply' call
  var this_0 = StringBuilder.ma();
  // Inline function 'kotlin.contracts.contract' call
  // Inline function 'io.ktor.client.plugins.logging.Logging.logRequest.<anonymous>' call
  if ($this.w3z_1.f3z_1) {
    // Inline function 'kotlin.text.appendLine' call
    var value = 'REQUEST: ' + Url(request.o2q_1);
    // Inline function 'kotlin.text.appendLine' call
    this_0.i7(value).j7(_Char___init__impl__6a9atx(10));
    // Inline function 'kotlin.text.appendLine' call
    var value_0 = 'METHOD: ' + request.p2q_1;
    // Inline function 'kotlin.text.appendLine' call
    this_0.i7(value_0).j7(_Char___init__impl__6a9atx(10));
  }
  if ($this.w3z_1.g3z_1) {
    // Inline function 'kotlin.text.appendLine' call
    var value_1 = 'COMMON HEADERS';
    // Inline function 'kotlin.text.appendLine' call
    this_0.i7(value_1).j7(_Char___init__impl__6a9atx(10));
    logHeaders(this_0, request.q2q_1.c21(), $this.y3z_1);
    // Inline function 'kotlin.text.appendLine' call
    var value_2 = 'CONTENT HEADERS';
    // Inline function 'kotlin.text.appendLine' call
    this_0.i7(value_2).j7(_Char___init__impl__6a9atx(10));
    var tmp$ret$9;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator = $this.y3z_1.r();
      while (tmp0_iterator.s()) {
        var element = tmp0_iterator.u();
        // Inline function 'io.ktor.client.plugins.logging.Logging.logRequest.<anonymous>.<anonymous>' call
        if (element.a40_1(HttpHeaders_getInstance().s28_1)) {
          tmp$ret$9 = element;
          break $l$block;
        }
      }
      tmp$ret$9 = null;
    }
    var tmp0_safe_receiver = tmp$ret$9;
    var contentLengthPlaceholder = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.z3z_1;
    var tmp$ret$11;
    $l$block_0: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator_0 = $this.y3z_1.r();
      while (tmp0_iterator_0.s()) {
        var element_0 = tmp0_iterator_0.u();
        // Inline function 'io.ktor.client.plugins.logging.Logging.logRequest.<anonymous>.<anonymous>' call
        if (element_0.a40_1(HttpHeaders_getInstance().v28_1)) {
          tmp$ret$11 = element_0;
          break $l$block_0;
        }
      }
      tmp$ret$11 = null;
    }
    var tmp1_safe_receiver = tmp$ret$11;
    var contentTypePlaceholder = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.z3z_1;
    var tmp2_safe_receiver = content.d2i();
    if (tmp2_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp_0 = HttpHeaders_getInstance().s28_1;
      logHeader(this_0, tmp_0, contentLengthPlaceholder == null ? tmp2_safe_receiver.toString() : contentLengthPlaceholder);
    }
    var tmp3_safe_receiver = content.c2i();
    if (tmp3_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp_1 = HttpHeaders_getInstance().v28_1;
      logHeader(this_0, tmp_1, contentTypePlaceholder == null ? tmp3_safe_receiver.toString() : contentTypePlaceholder);
    }
    logHeaders(this_0, content.w2c().c21(), $this.y3z_1);
  }
  var message = this_0.toString();
  // Inline function 'kotlin.text.isNotEmpty' call
  if (charSequenceLength(message) > 0) {
    logger.x3y(message);
  }
  var tmp_2;
  // Inline function 'kotlin.text.isEmpty' call
  if (charSequenceLength(message) === 0) {
    tmp_2 = true;
  } else {
    tmp_2 = !$this.w3z_1.h3z_1;
  }
  if (tmp_2) {
    logger.b3z();
    return null;
  }
  return logRequestBody($this, content, logger, $completion);
}
function logRequestBody($this, content, logger, $completion) {
  var requestLog = StringBuilder.ma();
  // Inline function 'kotlin.text.appendLine' call
  var value = 'BODY Content-Type: ' + content.c2i();
  // Inline function 'kotlin.text.appendLine' call
  requestLog.i7(value).j7(_Char___init__impl__6a9atx(10));
  var tmp0_safe_receiver = content.c2i();
  var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : charset(tmp0_safe_receiver);
  var charset_0 = tmp1_elvis_lhs == null ? Charsets_getInstance().e1s_1 : tmp1_elvis_lhs;
  var channel = ByteChannel();
  var tmp = GlobalScope_instance;
  var tmp_0 = Dispatchers_getInstance().bx_1;
  var tmp_1 = launch(tmp, tmp_0, VOID, Logging$logRequestBody$slambda_0(channel, charset_0, requestLog, null));
  tmp_1.zp(Logging$logRequestBody$lambda(logger, requestLog));
  return observe(content, channel, $completion);
}
function logRequestException($this, context, cause) {
  if ($this.w3z_1.f3z_1) {
    $this.v3z_1.ul('REQUEST ' + Url(context.o2q_1) + ' failed with exception: ' + cause);
  }
}
function setupResponseLogging($this, client) {
  var tmp = Phases_getInstance_0().p2y_1;
  client.q2o_1.g24(tmp, Logging$setupResponseLogging$slambda_2($this, null));
  var tmp_0 = Phases_getInstance_1().m2r_1;
  client.o2o_1.g24(tmp_0, Logging$setupResponseLogging$slambda_3($this, null));
  if (!$this.w3z_1.h3z_1)
    return Unit_instance;
  var observer = Logging$setupResponseLogging$slambda_4($this, null);
  Plugin_getInstance().c3h(new ResponseObserver(observer), client);
}
function logResponseException($this, log, request, cause) {
  if (!$this.w3z_1.f3z_1)
    return Unit_instance;
  log.i7('RESPONSE ' + request.q2t() + ' failed with exception: ' + cause);
}
var Companion_instance_0;
function Companion_getInstance_0() {
  if (Companion_instance_0 === VOID)
    new Companion_0();
  return Companion_instance_0;
}
function shouldBeLogged($this, request) {
  var tmp;
  if ($this.x3z_1.z()) {
    tmp = true;
  } else {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = $this.x3z_1;
      var tmp_0;
      if (isInterface(this_0, Collection)) {
        tmp_0 = this_0.z();
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.r();
      while (tmp0_iterator.s()) {
        var element = tmp0_iterator.u();
        // Inline function 'io.ktor.client.plugins.logging.Logging.shouldBeLogged.<anonymous>' call
        if (element(request)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    tmp = tmp$ret$0;
  }
  return tmp;
}
function Logging$setupRequestLogging$slambda_0(this$0, resultContinuation) {
  var i = new Logging$setupRequestLogging$slambda(this$0, resultContinuation);
  var l = function ($this$intercept, it, $completion) {
    return i.h2p($this$intercept, it, $completion);
  };
  l.$arity = 2;
  return l;
}
function Logging$logRequestBody$slambda_0($channel, $charset, $requestLog, resultContinuation) {
  var i = new Logging$logRequestBody$slambda($channel, $charset, $requestLog, resultContinuation);
  var l = function ($this$launch, $completion) {
    return i.y1m($this$launch, $completion);
  };
  l.$arity = 1;
  return l;
}
function Logging$logRequestBody$lambda($logger, $requestLog) {
  return function (it) {
    $logger.x3y($requestLog.toString());
    $logger.b3z();
    return Unit_instance;
  };
}
function Logging$setupResponseLogging$slambda_2(this$0, resultContinuation) {
  var i = new Logging$setupResponseLogging$slambda(this$0, resultContinuation);
  var l = function ($this$intercept, response, $completion) {
    return i.s2z($this$intercept, response, $completion);
  };
  l.$arity = 2;
  return l;
}
function Logging$setupResponseLogging$slambda_3(this$0, resultContinuation) {
  var i = new Logging$setupResponseLogging$slambda_0(this$0, resultContinuation);
  var l = function ($this$intercept, it, $completion) {
    return i.c2q($this$intercept, it, $completion);
  };
  l.$arity = 2;
  return l;
}
function Logging$setupResponseLogging$slambda_4(this$0, resultContinuation) {
  var i = new Logging$setupResponseLogging$slambda_1(this$0, resultContinuation);
  var l = function (it, $completion) {
    return i.o31(it, $completion);
  };
  l.$arity = 1;
  return l;
}
var properties_initialized_Logging_kt_588vu7;
function _init_properties_Logging_kt__66pui5() {
  if (!properties_initialized_Logging_kt_588vu7) {
    properties_initialized_Logging_kt_588vu7 = true;
    ClientCallLogger = new AttributeKey('CallLogger');
    DisableLogging = new AttributeKey('DisableLogging');
  }
}
function logHeaders(_this__u8e3s4, headers, sanitizedHeaders) {
  // Inline function 'kotlin.collections.sortedBy' call
  var this_0 = toList(headers);
  // Inline function 'kotlin.comparisons.compareBy' call
  var tmp = logHeaders$lambda;
  var tmp$ret$0 = new sam$kotlin_Comparator$0(tmp);
  var sortedHeaders = sortedWith(this_0, tmp$ret$0);
  // Inline function 'kotlin.collections.forEach' call
  var tmp0_iterator = sortedHeaders.r();
  while (tmp0_iterator.s()) {
    var element = tmp0_iterator.u();
    // Inline function 'io.ktor.client.plugins.logging.logHeaders.<anonymous>' call
    // Inline function 'kotlin.collections.component1' call
    var key = element.t2();
    // Inline function 'kotlin.collections.component2' call
    var values = element.u2();
    var tmp$ret$5;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator_0 = sanitizedHeaders.r();
      while (tmp0_iterator_0.s()) {
        var element_0 = tmp0_iterator_0.u();
        // Inline function 'io.ktor.client.plugins.logging.logHeaders.<anonymous>.<anonymous>' call
        if (element_0.a40_1(key)) {
          tmp$ret$5 = element_0;
          break $l$block;
        }
      }
      tmp$ret$5 = null;
    }
    var tmp0_safe_receiver = tmp$ret$5;
    var placeholder = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.z3z_1;
    logHeader(_this__u8e3s4, key, placeholder == null ? joinToString(values, '; ') : placeholder);
  }
}
function logHeader(_this__u8e3s4, key, value) {
  // Inline function 'kotlin.text.appendLine' call
  var value_0 = '-> ' + key + ': ' + value;
  // Inline function 'kotlin.text.appendLine' call
  _this__u8e3s4.ra(value_0).j7(_Char___init__impl__6a9atx(10));
}
function logResponseHeader(log, response, level, sanitizedHeaders) {
  // Inline function 'kotlin.with' call
  // Inline function 'kotlin.contracts.contract' call
  if (level.f3z_1) {
    // Inline function 'kotlin.text.appendLine' call
    var value = 'RESPONSE: ' + response.dn();
    // Inline function 'kotlin.text.appendLine' call
    log.i7(value).j7(_Char___init__impl__6a9atx(10));
    // Inline function 'kotlin.text.appendLine' call
    var value_0 = 'METHOD: ' + response.k2u().f2t().l2u();
    // Inline function 'kotlin.text.appendLine' call
    log.i7(value_0).j7(_Char___init__impl__6a9atx(10));
    // Inline function 'kotlin.text.appendLine' call
    var value_1 = 'FROM: ' + response.k2u().f2t().q2t();
    // Inline function 'kotlin.text.appendLine' call
    log.i7(value_1).j7(_Char___init__impl__6a9atx(10));
  }
  var tmp;
  if (level.g3z_1) {
    // Inline function 'kotlin.text.appendLine' call
    var value_2 = 'COMMON HEADERS';
    // Inline function 'kotlin.text.appendLine' call
    log.i7(value_2).j7(_Char___init__impl__6a9atx(10));
    logHeaders(log, response.w2c().c21(), sanitizedHeaders);
    tmp = Unit_instance;
  }
}
function logResponseBody(log, contentType, content, $completion) {
  var tmp = new $logResponseBodyCOROUTINE$4(log, contentType, content, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function logHeaders$lambda(a, b) {
  // Inline function 'kotlin.comparisons.compareValuesBy' call
  // Inline function 'io.ktor.client.plugins.logging.logHeaders.<anonymous>' call
  var tmp = a.t2();
  // Inline function 'io.ktor.client.plugins.logging.logHeaders.<anonymous>' call
  var tmp$ret$1 = b.t2();
  return compareValues(tmp, tmp$ret$1);
}
function observe(_this__u8e3s4, log, $completion) {
  var tmp = new $observeCOROUTINE$5(_this__u8e3s4, log, $completion);
  tmp.ve_1 = Unit_instance;
  tmp.we_1 = null;
  return tmp.gf();
}
function toReadChannel(_this__u8e3s4) {
  var tmp = GlobalScope_instance;
  var tmp_0 = Dispatchers_getInstance().ax_1;
  return writer(tmp, tmp_0, VOID, toReadChannel$slambda_0(_this__u8e3s4, null)).p11();
}
function toReadChannel$slambda_0($this_toReadChannel, resultContinuation) {
  var i = new toReadChannel$slambda($this_toReadChannel, resultContinuation);
  var l = function ($this$writer, $completion) {
    return i.n2v($this$writer, $completion);
  };
  l.$arity = 1;
  return l;
}
function get_DEFAULT(_this__u8e3s4) {
  return get_SIMPLE(_this__u8e3s4);
}
//region block: post-declaration
setMetadataFor($logResponseExceptionCOROUTINE$0, '$logResponseExceptionCOROUTINE$0', classMeta);
setMetadataFor($logResponseBodyCOROUTINE$1, '$logResponseBodyCOROUTINE$1', classMeta);
setMetadataFor($closeResponseLogCOROUTINE$2, '$closeResponseLogCOROUTINE$2', classMeta);
setMetadataFor(HttpClientCallLogger, 'HttpClientCallLogger', classMeta, VOID, VOID, VOID, VOID, VOID, [1, 0]);
setMetadataFor(LogLevel, 'LogLevel', classMeta);
setMetadataFor(LoggedContent, 'LoggedContent', classMeta);
setMetadataFor(Companion, 'Companion', objectMeta);
setMetadataFor(SimpleLogger, 'SimpleLogger', classMeta, VOID, VOID, SimpleLogger);
setMetadataFor(Config, 'Config', classMeta, VOID, VOID, Config);
setMetadataFor(Companion_0, 'Companion', objectMeta, VOID, [HttpClientPlugin]);
setMetadataFor(Logging$setupRequestLogging$slambda, 'Logging$setupRequestLogging$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [2]);
setMetadataFor(Logging$logRequestBody$slambda, 'Logging$logRequestBody$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(Logging$setupResponseLogging$slambda, 'Logging$setupResponseLogging$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [2]);
setMetadataFor(Logging$setupResponseLogging$slambda_0, 'Logging$setupResponseLogging$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [2]);
setMetadataFor(Logging$setupResponseLogging$slambda_1, 'Logging$setupResponseLogging$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor(Logging, 'Logging', classMeta, VOID, VOID, VOID, VOID, VOID, [1, 2]);
setMetadataFor(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', classMeta);
setMetadataFor($logResponseBodyCOROUTINE$4, '$logResponseBodyCOROUTINE$4', classMeta);
setMetadataFor(toReadChannel$slambda, 'toReadChannel$slambda', classMeta, VOID, VOID, VOID, VOID, VOID, [1]);
setMetadataFor($observeCOROUTINE$5, '$observeCOROUTINE$5', classMeta);
//endregion
//region block: init
Companion_instance = new Companion();
//endregion
//region block: exports
export {
  LogLevel_INFO_getInstance as LogLevel_INFO_getInstance1vzce5sdbg6wx,
  Companion_getInstance_0 as Companion_getInstance1jrxni30zx3hj,
};
//endregion


