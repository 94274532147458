/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Injectable, Directive, Input, Optional, EventEmitter, Component, ChangeDetectionStrategy, HostBinding, ContentChild, ViewChild, ViewChildren, Output, HostListener, NgModule } from '@angular/core';
import * as i5 from '@progress/kendo-angular-common';
import { isDocumentAvailable, Keys, isChanged, hasObservers, EventsModule } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';
import * as i2 from '@progress/kendo-angular-l10n';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { switchMap, take } from 'rxjs/operators';
import * as i3 from '@progress/kendo-angular-pager';
import { PageSizeChangeEvent as PageSizeChangeEvent$1, PagerModule } from '@progress/kendo-angular-pager';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import { Button } from '@progress/kendo-angular-buttons';
import * as i3$1 from '@progress/kendo-angular-icons';
import { IconsModule } from '@progress/kendo-angular-icons';

/**
 * @hidden
 */
const _c0 = ["contentContainer"];
function ListViewComponent_0_ng_template_0_Template(rf, ctx) {}
const _c1 = () => ({
  pagerClass: "k-listview-pager k-listview-pager-top"
});
function ListViewComponent_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ListViewComponent_0_ng_template_0_Template, 0, 0, "ng-template", 9);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r10 = i0.ɵɵreference(11);
    i0.ɵɵproperty("ngTemplateOutlet", _r10)("ngTemplateOutletContext", i0.ɵɵpureFunction0(2, _c1));
  }
}
function ListViewComponent_div_1_ng_template_1_Template(rf, ctx) {}
function ListViewComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵtemplate(1, ListViewComponent_div_1_ng_template_1_Template, 0, 0, "ng-template", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.headerTemplate == null ? null : ctx_r1.headerTemplate.templateRef);
  }
}
function ListViewComponent_div_4_1_ng_template_0_Template(rf, ctx) {}
function ListViewComponent_div_4_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ListViewComponent_div_4_1_ng_template_0_Template, 0, 0, "ng-template", 9);
  }
  if (rf & 2) {
    const ctx_r13 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r13.editTemplate == null ? null : ctx_r13.editTemplate.templateRef)("ngTemplateOutletContext", ctx_r13.editTemplateContext(-1));
  }
}
function ListViewComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtemplate(1, ListViewComponent_div_4_1_Template, 1, 2, null, 0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("index", -1)("ngClass", ctx_r3.itemClass)("ngStyle", ctx_r3.itemStyle);
    i0.ɵɵattribute("role", ctx_r3.listItemRole)("data-kendo-listview-item-index", -1);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.editTemplate);
  }
}
function ListViewComponent_div_5_ng_template_1_Template(rf, ctx) {}
function ListViewComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtemplate(1, ListViewComponent_div_5_ng_template_1_Template, 0, 0, "ng-template", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const index_r16 = ctx.index;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("index", index_r16)("ngClass", ctx_r4.itemClass)("ngStyle", ctx_r4.itemStyle);
    i0.ɵɵattribute("role", ctx_r4.listItemRole)("aria-posinset", ctx_r4.itemPosInSet(index_r16))("aria-setsize", ctx_r4.total)("data-kendo-listview-item-index", index_r16);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.isEdited(index_r16) ? ctx_r4.editTemplate == null ? null : ctx_r4.editTemplate.templateRef : ctx_r4.itemTemplate == null ? null : ctx_r4.itemTemplate.templateRef)("ngTemplateOutletContext", ctx_r4.isEdited(index_r16) ? ctx_r4.editTemplateContext(index_r16) : ctx_r4.templateContext(index_r16));
  }
}
function ListViewComponent_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13)(1, "span", 14);
    i0.ɵɵtext(2, "Loading");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "div", 15)(4, "div", 16);
    i0.ɵɵelementEnd();
  }
}
function ListViewComponent_7_ng_template_0_Template(rf, ctx) {}
function ListViewComponent_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ListViewComponent_7_ng_template_0_Template, 0, 0, "ng-template", 11);
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r6.loaderTemplate.templateRef);
  }
}
function ListViewComponent_div_8_ng_template_1_Template(rf, ctx) {}
function ListViewComponent_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵtemplate(1, ListViewComponent_div_8_ng_template_1_Template, 0, 0, "ng-template", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r7.footerTemplate == null ? null : ctx_r7.footerTemplate.templateRef);
  }
}
function ListViewComponent_9_ng_template_0_Template(rf, ctx) {}
const _c2 = () => ({
  pagerClass: "k-listview-pager"
});
function ListViewComponent_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ListViewComponent_9_ng_template_0_Template, 0, 0, "ng-template", 9);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r10 = i0.ɵɵreference(11);
    i0.ɵɵproperty("ngTemplateOutlet", _r10)("ngTemplateOutletContext", i0.ɵɵpureFunction0(2, _c2));
  }
}
function ListViewComponent_ng_template_10_kendo_datapager_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r26 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "kendo-datapager", 19);
    i0.ɵɵlistener("pageChange", function ListViewComponent_ng_template_10_kendo_datapager_0_Template_kendo_datapager_pageChange_0_listener($event) {
      i0.ɵɵrestoreView(_r26);
      const ctx_r25 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r25.handlePageChange($event));
    })("pageSizeChange", function ListViewComponent_ng_template_10_kendo_datapager_0_Template_kendo_datapager_pageSizeChange_0_listener($event) {
      i0.ɵɵrestoreView(_r26);
      const ctx_r27 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r27.pageSizeChange.emit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const pagerClass_r23 = i0.ɵɵnextContext().pagerClass;
    const ctx_r24 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(pagerClass_r23);
    i0.ɵɵproperty("total", ctx_r24.total)("pageSize", ctx_r24.pageSize)("skip", ctx_r24.skip)("buttonCount", ctx_r24.pagerSettings.buttonCount)("info", ctx_r24.pagerSettings.info)("previousNext", ctx_r24.pagerSettings.previousNext)("type", ctx_r24.pagerSettings.type)("pageSizeValues", ctx_r24.pagerSettings.pageSizeValues);
  }
}
function ListViewComponent_ng_template_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ListViewComponent_ng_template_10_kendo_datapager_0_Template, 1, 10, "kendo-datapager", 18);
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r9.pageable);
  }
}
const _c3 = a0 => ({
  scroll: a0
});
const _c4 = ["kendoListViewEditCommand", ""];
function EditCommandDirective_kendo_icon_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.icon)("svgIcon", ctx_r0.svgIcon);
  }
}
function EditCommandDirective_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵelement(1, "img", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", ctx_r1.imageUrl, i0.ɵɵsanitizeUrl);
  }
}
function EditCommandDirective_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 7);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r2.iconClass);
  }
}
const _c5 = ["*"];
const _c6 = ["kendoListViewAddCommand", ""];
function AddCommandDirective_kendo_icon_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.icon)("svgIcon", ctx_r0.svgIcon);
  }
}
function AddCommandDirective_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵelement(1, "img", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", ctx_r1.imageUrl, i0.ɵɵsanitizeUrl);
  }
}
function AddCommandDirective_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 7);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r2.iconClass);
  }
}
const _c7 = ["kendoListViewSaveCommand", ""];
function SaveCommandDirective_kendo_icon_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.icon)("svgIcon", ctx_r0.svgIcon);
  }
}
function SaveCommandDirective_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵelement(1, "img", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", ctx_r1.imageUrl, i0.ɵɵsanitizeUrl);
  }
}
function SaveCommandDirective_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 7);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r2.iconClass);
  }
}
const _c8 = ["kendoListViewCancelCommand", ""];
function CancelCommandDirective_kendo_icon_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.icon)("svgIcon", ctx_r0.svgIcon);
  }
}
function CancelCommandDirective_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵelement(1, "img", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", ctx_r1.imageUrl, i0.ɵɵsanitizeUrl);
  }
}
function CancelCommandDirective_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 7);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r2.iconClass);
  }
}
const _c9 = ["kendoListViewRemoveCommand", ""];
function RemoveCommandDirective_kendo_icon_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.icon)("svgIcon", ctx_r0.svgIcon);
  }
}
function RemoveCommandDirective_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵelement(1, "img", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("src", ctx_r1.imageUrl, i0.ɵɵsanitizeUrl);
  }
}
function RemoveCommandDirective_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 7);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r2.iconClass);
  }
}
const packageMetadata = {
  name: '@progress/kendo-angular-listview',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1710925411,
  version: '15.3.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
const LISTVIEW_ITEM_SELECTOR = '.k-listview-item';
/**
 * @hidden
 */
const isPresent = item => item !== null && item !== undefined;
/**
 * @hidden
 */
const isObject = item => isPresent(item) && typeof item === 'object';
/**
 * @hidden
 *
 * Polyfill for `Element.matches`.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 */
const match = (element, selector) => {
  const matcher = element.matches || element.msMatchesSelector || element.webkitMatchesSelector;
  if (!isPresent(matcher)) {
    return false;
  }
  return matcher.call(element, selector);
};
/**
 * @hidden
 *
 * Checks if a target element has the `.k-listview-item` CSS class.
 */
const isListViewItem = element => {
  if (!isPresent(element)) {
    return false;
  }
  return match(element, LISTVIEW_ITEM_SELECTOR);
};
/**
 * @hidden
 *
 * Extracts and parses to a number the `data-kendo-listview-item-index` attribute value from the targeted element.
 */
const getListItemIndex = item => {
  if (!isPresent(item)) {
    return null;
  }
  return Number(item.getAttribute('data-kendo-listview-item-index'));
};
/**
 * @hidden
 *
 * Gets the new focus target from a blur event.
 * Queries both event.relatedTarget and document.activeElement for compatibility with IE.
 */
const relatedTarget = event => {
  if (!isPresent(event.relatedTarget) || !isDocumentAvailable()) {
    return null;
  }
  return event.relatedTarget || document.activeElement;
};
/**
 * @hidden
 *
 * If the given contender number is not defined or lower than the specified min - returns min, if its above the specified max - returns max.
 * If the number is in the given bounds, it is returned.
 */
const fitIntoRange = (contender, min, max) => {
  if (!isPresent(contender) || contender <= min) {
    return min;
  } else if (contender >= max) {
    return max;
  } else {
    return contender;
  }
};
/**
 * @hidden
 */
const closestWithMatch = (element, selector) => {
  let parent = element;
  while (parent !== null && parent.nodeType === 1) {
    if (match(parent, selector)) {
      return parent;
    }
    parent = parent.parentElement || parent.parentNode;
  }
  return null;
};
/**
 * @hidden
 *
 * Extracts and parses to a number the `data-kendo-listview-item-index` attribute value from the targeted element.
 */
const getClosestListItemIndex = element => {
  if (!isPresent(element)) {
    return null;
  }
  const closestListViewItem = closestWithMatch(element, LISTVIEW_ITEM_SELECTOR);
  return getListItemIndex(closestListViewItem);
};

/**
 * @hidden
 *
 * Provided per ListView instance. Keeps the availability, active index and focused state of the current ListView.
 * Emits `changes` when any of the aforementioned states changes.
 */
class NavigationService {
  constructor() {
    /**
     * Emits every time a change in active index/focus/blur/navigation availability occurs.
     */
    this.changes = new Subject();
    /**
     * Specifies if a ListView item currently holds focus.
     */
    this.isFocused = false;
    /**
     * Keeps track of the index of the items that should be the current focus target (tabindex="0").
     * When set to `null`/`undefined`, the navigation is disabled and the items should not render a tabindex.
     */
    this.activeIndex = null;
  }
  /**
   * Sets or gets if the navigation is enabled.
   * When no activeIndex is present, the navigation is inferred as disabled.
   * Toggling the service availability clears the current active index or activates the first one.
   */
  get isEnabled() {
    return isPresent(this.activeIndex);
  }
  set isEnabled(enabled) {
    if (enabled) {
      this.activeIndex = 0;
    } else {
      this.activeIndex = null;
    }
    this.changes.next();
  }
  /**
   * Shows if the checked index should be the current available focus target (tabindex="0").
   */
  isActive(index) {
    return index === this.activeIndex;
  }
  handleKeyDown(event, totalItemsCount) {
    const {
      keyCode
    } = event;
    switch (keyCode) {
      case Keys.ArrowLeft:
      case Keys.ArrowUp:
        this.navigateToPrevious();
        break;
      case Keys.ArrowRight:
      case Keys.ArrowDown:
        this.navigateToNext(totalItemsCount);
        break;
      case Keys.Home:
        {
          const firstIndex = 0;
          this.navigateTo(firstIndex);
          break;
        }
      case Keys.End:
        {
          const lastIndex = totalItemsCount - 1;
          this.navigateTo(lastIndex);
          break;
        }
      default:
        return;
    }
    // the following line is executed only if the pressed key matches one of the listview hotkeys -
    // they `break`, while the `default` case `return`s
    event.preventDefault();
  }
  handleFocusIn(event) {
    const target = event.target;
    if (!isListViewItem(target)) {
      const listViewItemSelector = '.k-listview-item';
      const closestListViewItem = closestWithMatch(target, listViewItemSelector);
      const isListViewItemChild = isPresent(closestListViewItem);
      if (isListViewItemChild) {
        const itemIndex = getListItemIndex(closestListViewItem);
        this.setActiveIndex(itemIndex);
      }
      return;
    }
    const targetIndex = getListItemIndex(target);
    // don't emit if the no change in focused state has occurred and the targeted index is the same as the current activeIndex
    if (this.isFocused && targetIndex === this.activeIndex) {
      return;
    }
    this.activeIndex = targetIndex;
    this.isFocused = true;
    this.changes.next();
  }
  handleFocusOut(event) {
    // don't emit if the blurred item is not a listview item or if the new focus target is another listview item
    if (!isListViewItem(event.target) || isListViewItem(relatedTarget(event))) {
      return;
    }
    this.isFocused = false;
    this.changes.next();
  }
  /**
   * Sets the `activeIndex` and triggers changes without focusing the corresponding ListView item.
   */
  setActiveIndex(index) {
    if (!this.isEnabled) {
      return;
    }
    if (index === this.activeIndex) {
      return;
    }
    this.activeIndex = index;
    this.changes.next();
  }
  /**
   * Focuses item at the targeted index. If no index is passed, the current `activeIndex` is used.
   * The passed target index is normalized to fit the min/max available indices bounds.
   */
  focusIndex(index, totalItemsCount) {
    if (!this.isEnabled) {
      return;
    }
    const parsed = parseInt(index, 10);
    const firstIndex = 0;
    const lastIndex = totalItemsCount - 1;
    const targetIndex = isNaN(parsed) ? this.activeIndex : fitIntoRange(parsed, firstIndex, lastIndex);
    this.navigateTo(targetIndex);
  }
  navigateTo(index) {
    if (this.isFocused && this.activeIndex === index) {
      return;
    }
    this.isFocused = true;
    this.activeIndex = index;
    this.changes.next();
  }
  navigateToPrevious() {
    const previousIndex = Math.max(this.activeIndex - 1, 0);
    this.navigateTo(previousIndex);
  }
  navigateToNext(totalItemsCount) {
    const lastAvailableIndex = totalItemsCount - 1;
    const nextIndex = Math.min(this.activeIndex + 1, lastAvailableIndex);
    this.navigateTo(nextIndex);
  }
}
NavigationService.ɵfac = function NavigationService_Factory(t) {
  return new (t || NavigationService)();
};
NavigationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NavigationService,
  factory: NavigationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigationService, [{
    type: Injectable
  }], null, null);
})();

/**
 * @hidden
 */
class ListViewNavigableItemDirective {
  constructor(hostElement, renderer, navigationService) {
    this.hostElement = hostElement;
    this.renderer = renderer;
    this.navigationService = navigationService;
  }
  ngOnChanges() {
    this.updateNavigationState();
  }
  ngOnInit() {
    this.navigationSubscription = this.navigationService.changes.subscribe(this.updateNavigationState.bind(this));
  }
  ngOnDestroy() {
    if (isPresent(this.navigationSubscription)) {
      this.navigationSubscription.unsubscribe();
    }
  }
  updateNavigationState() {
    this.updateTabIndex();
    this.updateFocusedState();
  }
  updateFocusedState() {
    const shouldFocus = this.navigationService.isActive(this.index) && this.navigationService.isFocused;
    const focusedCssClass = 'k-focus';
    if (shouldFocus) {
      this.renderer.addClass(this.hostElement.nativeElement, focusedCssClass);
      this.hostElement.nativeElement.focus();
    } else {
      this.renderer.removeClass(this.hostElement.nativeElement, focusedCssClass);
    }
  }
  updateTabIndex() {
    if (!this.navigationService.isEnabled) {
      this.renderer.removeAttribute(this.hostElement.nativeElement, 'tabindex');
    } else if (this.navigationService.isActive(this.index)) {
      this.renderer.setAttribute(this.hostElement.nativeElement, 'tabindex', '0');
    } else {
      this.renderer.setAttribute(this.hostElement.nativeElement, 'tabindex', '-1');
    }
  }
}
ListViewNavigableItemDirective.ɵfac = function ListViewNavigableItemDirective_Factory(t) {
  return new (t || ListViewNavigableItemDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(NavigationService));
};
ListViewNavigableItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ListViewNavigableItemDirective,
  selectors: [["", "kendoListViewNavigableItem", ""]],
  inputs: {
    index: "index"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListViewNavigableItemDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoListViewNavigableItem]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: NavigationService
    }];
  }, {
    index: [{
      type: Input
    }]
  });
})();

/**
 * Renders the list item content. To define an item template, nest an `<ng-template>` tag
 * with the `kendoListViewItemTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-item-template).
 *
 * The following values are available as context variables:
 * - `let-dataItem="dataItem"` (`any`) - The current data item. Also available as implicit context variable.
 * - `let-index="index"` (`number`) - The current item index.
 * - `let-isFirst="isFirst"` (`boolean`) - Indicates whether the current data item will be rendered as the first item on the list.
 * - `let-isLast="isLast"` (`boolean`)- Indicates whether the current data item will be rendered as the last item on the list.
 */
class ItemTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ItemTemplateDirective.ɵfac = function ItemTemplateDirective_Factory(t) {
  return new (t || ItemTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
ItemTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ItemTemplateDirective,
  selectors: [["", "kendoListViewItemTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ItemTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoListViewItemTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();

/**
 * Renders the header content of the ListView. To define a header template, nest an `<ng-template>` tag
 * with the `kendoListViewHeaderTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-header-template).
 */
class HeaderTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
HeaderTemplateDirective.ɵfac = function HeaderTemplateDirective_Factory(t) {
  return new (t || HeaderTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
HeaderTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: HeaderTemplateDirective,
  selectors: [["", "kendoListViewHeaderTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoListViewHeaderTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();

/**
 * Renders the footer content of the ListView. To define a footer template, nest an `<ng-template>` tag
 * with the `kendoListViewFooterTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-footer-template).
 */
class FooterTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
FooterTemplateDirective.ɵfac = function FooterTemplateDirective_Factory(t) {
  return new (t || FooterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
FooterTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FooterTemplateDirective,
  selectors: [["", "kendoListViewFooterTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoListViewFooterTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();

/**
 * Overrides the default loader content of the ListView. To define a loader template, nest an `<ng-template>` tag
 * with the `kendoListViewLoaderTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-loader-template).
 */
class LoaderTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
LoaderTemplateDirective.ɵfac = function LoaderTemplateDirective_Factory(t) {
  return new (t || LoaderTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
LoaderTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LoaderTemplateDirective,
  selectors: [["", "kendoListViewLoaderTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoaderTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoListViewLoaderTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();

/**
 * Represents the edit template of the ListView ([see example]({% slug editing_template_forms_listview %})).
 * Helps to customize the content of the edited items. To define the template, nest an `<ng-template>`
 * tag with the `kendoListViewEditTemplate` directive inside a `<kendo-listview>` tag.
 *
 * The template context contains the following fields:
 * - `formGroup`&mdash;The current [`FormGroup`](link:site.data.urls.angular['formgroupapi']).
 * If you use the ListView inside [Template-Driven Forms](link:site.data.urls.angular['forms']), it will be `undefined`.
 * - `itemIndex`&mdash;The current item index. If inside a new item, `itemIndex` is `-1`.
 * - `dataItem`&mdash;The current data item.
 * - `isNew`&mdash;The state of the current item.
 */
class EditTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
EditTemplateDirective.ɵfac = function EditTemplateDirective_Factory(t) {
  return new (t || EditTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
EditTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EditTemplateDirective,
  selectors: [["", "kendoListViewEditTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoListViewEditTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * @hidden
 */
const isEqual = index => item => item.index === index;
/**
 * @hidden
 */
const isNotEqual = index => item => item.index !== index;
/**
 * @hidden
 */
const isNewItem = index => index === -1 || index === undefined;
/**
 * @hidden
 */
class EditService {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this.changes = new EventEmitter();
    this.editedIndices = [];
    this.changedSource = new Subject();
    this.changed = this.changedSource.asObservable().pipe(switchMap(() => this.ngZone.onStable.asObservable().pipe(take(1))));
  }
  editItem(index, group = undefined) {
    this.editedIndices.push({
      index,
      group
    });
    this.onChanged();
  }
  addItem(group) {
    this.newItem = {
      group
    };
    this.onChanged();
  }
  isEditing() {
    return this.editedIndices.length > 0;
  }
  get hasNewItem() {
    return isPresent(this.newItem);
  }
  get newDataItem() {
    if (this.hasNewItem) {
      return this.newItem.group.value;
    }
    return {};
  }
  get newItemGroup() {
    if (this.hasNewItem) {
      return this.newItem.group;
    }
    return new FormGroup({});
  }
  editGroup(index) {
    return this.context(index).group;
  }
  close(index) {
    if (isNewItem(index)) {
      this.newItem = undefined;
      return;
    }
    this.editedIndices = this.editedIndices.filter(isNotEqual(index));
    this.onChanged();
  }
  context(index) {
    if (isNewItem(index)) {
      return this.newItem;
    }
    return this.findByIndex(index);
  }
  isEdited(index) {
    if (isNewItem(index) && isPresent(this.newItem)) {
      return true;
    }
    return isPresent(this.findByIndex(index));
  }
  hasEdited(index) {
    return isPresent(this.context(index));
  }
  beginEdit(itemIndex) {
    this.changes.emit({
      action: 'edit',
      itemIndex
    });
  }
  beginAdd() {
    this.changes.emit({
      action: 'add'
    });
  }
  endEdit(itemIndex) {
    const {
      group: formGroup
    } = this.context(itemIndex);
    this.changes.emit({
      action: 'cancel',
      itemIndex,
      formGroup,
      isNew: isNewItem(itemIndex)
    });
  }
  save(itemIndex) {
    const {
      group: formGroup
    } = this.context(itemIndex);
    this.changes.emit({
      action: 'save',
      itemIndex,
      formGroup,
      isNew: isNewItem(itemIndex)
    });
  }
  remove(itemIndex) {
    this.changes.emit({
      action: 'remove',
      itemIndex
    });
  }
  findByIndex(index) {
    return this.editedIndices.find(isEqual(index));
  }
  onChanged() {
    this.ngZone.runOutsideAngular(() => {
      this.changedSource.next();
    });
  }
}
EditService.ɵfac = function EditService_Factory(t) {
  return new (t || EditService)(i0.ɵɵinject(i0.NgZone));
};
EditService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: EditService,
  factory: EditService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
const DEFAULT_PAGER_SETTINGS = {
  position: 'bottom',
  buttonCount: 5,
  info: true,
  previousNext: true,
  type: 'numeric',
  pageSizeValues: [5, 10, 20]
};
const createControl = source => (acc, key) => {
  acc[key] = new FormControl(source[key]);
  return acc;
};
/**
 * Represents the Kendo UI ListView component for Angular.
 */
class ListViewComponent {
  constructor(ngZone, element, renderer, changeDetectorRef, editService, navigationService) {
    this.ngZone = ngZone;
    this.element = element;
    this.renderer = renderer;
    this.changeDetectorRef = changeDetectorRef;
    this.editService = editService;
    this.navigationService = navigationService;
    /**
     * @hidden
     */
    this.className = true;
    /**
     * Specifies if a border should be rendered around the listview element.
     *
     * @default true
     */
    this.bordered = true;
    /**
     * Specifies if the loading indicator of the ListView will be displayed
     * ([see example]({% slug paging_listview %}#toc-remote-binding)).
     */
    this.loading = false;
    /**
     * Specifies the content container `role` attribute
     * ([more details]({% slug accessibility_listview %}#toc-wai-aria-support)).
     * By default, the container `role` is set to `list`.
     */
    this.containerRole = 'list';
    /**
     * Specifies the list item `role` attribute
     * ([more details]({% slug accessibility_listview %}#toc-wai-aria-support)).
     * By default, the list item `role` is set to `listitem`.
     */
    this.listItemRole = 'listitem';
    /**
     * Fires when the user scrolls to the last record on the page
     * ([see endless scrolling example]({% slug scrollmodes_listview %}#toc-endless-scrolling)).
     */
    this.scrollBottom = new EventEmitter();
    /**
     * Fires when the page or the page size of the ListView is changed
     * ([see example]({% slug paging_listview %}#toc-remote-binding)).
     * You have to handle the event yourself and page the data.
     */
    this.pageChange = new EventEmitter();
    /**
     * Fires when the page size of the ListView is changed. This event can be prevented (`$event.preventDefault()`).
     * If not prevented, the `pageChange` event will be fired subsequently.
     */
    this.pageSizeChange = new EventEmitter();
    /**
     * Fires when the user clicks the **Edit** command button to edit an item
     * ([see example]({% slug editing_template_forms_listview %}#toc-editing-records)).
     */
    this.edit = new EventEmitter();
    /**
     * Fires when the user clicks the **Cancel** command button to close an item
     * ([see example]({% slug editing_template_forms_listview %}#toc-cancelling-editing)).
     */
    this.cancel = new EventEmitter();
    /**
     * Fires when the user clicks the **Save** command button to save changes in an item
     * ([see example]({% slug editing_template_forms_listview %}#toc-saving-records)).
     */
    this.save = new EventEmitter();
    /**
     * Fires when the user clicks the **Remove** command button to remove an item
     * ([see example]({% slug editing_template_forms_listview %}#toc-removing-records)).
     */
    this.remove = new EventEmitter();
    /**
     * Fires when the user clicks the **Add** command button to add a new item
     * ([see example]({% slug editing_template_forms_listview %}#toc-adding-records)).
     */
    this.add = new EventEmitter();
    this._skip = 0;
    this._navigable = false;
    validatePackage(packageMetadata);
    this.attachEditHandlers();
  }
  /**
   * Specifies whether the keyboard navigation is enabled
   * ([see example]({% slug keyboard_navigation_listview %})).
   * By default, the navigation is disabled.
   */
  set navigable(navigable) {
    if (!navigable && isPresent(this.removeNavigationListeners)) {
      this.removeNavigationListeners();
      this.removeNavigationListeners = null;
      this.navigationService.isEnabled = false;
    } else if (navigable && !isPresent(this.removeNavigationListeners)) {
      this.addNavigationListeners();
      this.navigationService.isEnabled = true;
    }
    this._navigable = navigable;
  }
  get navigable() {
    return this._navigable;
  }
  /**
   * Defines the number of records to be skipped by the pager
   * ([more details]({% slug paging_listview %})).
   */
  set skip(skip) {
    const parsed = parseInt(skip, 10);
    const defaultSkipValue = 0;
    this._skip = !isNaN(parsed) ? parsed : defaultSkipValue;
  }
  get skip() {
    return this._skip;
  }
  /**
   * Configures whether the ListView will render a pager
   * ([more details]({% slug paging_listview %})).
   * Providing a boolean value will render a pager with the default settings.
   */
  set pageable(pageable) {
    this._pageable = pageable;
    this.pagerSettings = pageable ? Object.assign({}, DEFAULT_PAGER_SETTINGS, pageable) : null;
  }
  get pageable() {
    return this._pageable;
  }
  /**
   * @hidden
   *
   * Gets the data items passed to the ListView.
   * If a ListViewDataResult is passed, the data value is used. If an array is passed - it's directly used.
   */
  get items() {
    if (!isPresent(this.data)) {
      return [];
    }
    return Array.isArray(this.data) ? this.data : this.data.data;
  }
  /**
   * @hidden
   *
   * Gets the total number of records passed to the ListView.
   * If a ListViewDataResult is passed, the total value is used. If an array is passed - its length is used.
   */
  get total() {
    if (!this.pageable) {
      return;
    }
    if (!isPresent(this.data)) {
      return 0;
    }
    return Array.isArray(this.data) ? this.data.length : this.data.total;
  }
  /**
   * @hidden
   */
  get containerTabindex() {
    // workaround for FF, where a scrollable container is focusable even without a tabindex and creates an unwanted tab stop
    // https://bugzilla.mozilla.org/show_bug.cgi?id=616594
    return this.navigable ? -1 : null;
  }
  /**
   * Gets the current active item index
   * ([see example]({% slug keyboard_navigation_listview %}#toc-controlling-the-focus)).
   * Returns `null` when the keyboard navigation is disabled.
   */
  get activeIndex() {
    return this.navigationService.activeIndex;
  }
  ngOnChanges(changes) {
    if (isChanged('height', changes, false)) {
      this.renderer.setStyle(this.element.nativeElement, 'height', `${this.height}px`);
    }
  }
  ngOnDestroy() {
    if (isPresent(this.editServiceSubscription)) {
      this.editServiceSubscription.unsubscribe();
    }
  }
  /**
   * @hidden
   */
  templateContext(index) {
    return {
      "$implicit": this.items[index],
      "isLast": index === this.items.length - 1,
      "isFirst": index === 0,
      "dataItem": this.items[index],
      "index": index
    };
  }
  /**
   * @hidden
   */
  editTemplateContext(index) {
    const isNew = index === -1;
    const group = isNew ? this.editService.newItemGroup : this.editService.editGroup(index);
    return {
      "$implicit": group,
      "formGroup": group,
      "dataItem": isNew ? this.editService.newDataItem : this.items[index],
      "isNew": isNew,
      "index": index
    };
  }
  /**
   * Focuses the item at the specified index ([see example]({% slug keyboard_navigation_listview %}#toc-controlling-the-focus)):
   * - If no index is specified, the current active index will be focused.
   * - If the passed value is below `0`, the first item receives focus.
   * - If the passed value is above the last available index, the last item receives focus.
   *
   * > The index param is based on the logical structure of the ListView and does not correspond to the data item index -
   * > i.e. the index `0` corresponds to the first rendered list item. Paging is not taken into account.
   * > Also, for the focusing to work, the `navigable` prop must first be set to `true`.
   */
  focus(index) {
    const totalRenderedItems = this.listViewItems.length;
    this.navigationService.focusIndex(index, totalRenderedItems);
  }
  /**
   * Creates a new item editor ([see example]({% slug editing_template_forms_listview %}#toc-adding-records)).
   *
   * @param {FormGroup} group - The [`FormGroup`](link:site.data.urls.angular['formgroupapi']) that describes
   * the edit form. If called with a data item, it will build the `FormGroup` from the data item fields.
   */
  addItem(group) {
    const isFormGroup = group instanceof FormGroup;
    if (!isFormGroup) {
      const fields = Object.keys(group).reduce(createControl(group), {});
      group = new FormGroup(fields);
    }
    this.editService.addItem(group);
  }
  /**
   * Switches the specified item to edit mode ([see example]({% slug editing_template_forms_listview %}#toc-editing-records)).
   *
   * @param index - The item index that will be switched to edit mode.
   * @param group - The [`FormGroup`](link:site.data.urls.angular['formgroupapi'])
   * that describes the edit form.
   */
  editItem(index, group) {
    this.editService.editItem(index, group);
    this.changeDetectorRef.markForCheck();
  }
  /**
   * Closes the editor for a given item ([see example]({% slug editing_template_forms_listview %}#toc-cancelling-editing)).
   *
   * @param {number} index - The item index that will be switched out of the edit mode. If no index is provided, it is assumed
   * that the new item editor will be closed.
   */
  closeItem(index) {
    this.editService.close(index);
    this.changeDetectorRef.markForCheck();
  }
  /**
   * @hidden
   */
  isEdited(index) {
    return this.editService.isEdited(index);
  }
  /**
   * @hidden
   */
  handlePageChange(event) {
    this.scrollToContainerTop();
    const firstIndex = 0;
    this.navigationService.setActiveIndex(firstIndex);
    this.pageChange.emit(event);
  }
  /**
   * @hidden
   */
  handleContentScroll() {
    if (!hasObservers(this.scrollBottom)) {
      return;
    }
    const THRESHOLD = 2;
    const {
      scrollHeight,
      scrollTop,
      clientHeight
    } = this.contentContainer.nativeElement;
    const atBottom = scrollHeight - clientHeight - scrollTop <= THRESHOLD;
    if (atBottom) {
      this.ngZone.run(() => {
        const event = {
          sender: this
        };
        this.scrollBottom.emit(event);
      });
    }
  }
  /**
   * @hidden
   */
  itemPosInSet(index) {
    if (!this.pageable) {
      return;
    }
    // adds 1 as the aria-posinset is not zero-based and the counting starts from 1
    return this.skip + index + 1;
  }
  scrollToContainerTop() {
    const container = this.contentContainer.nativeElement;
    container.scrollTop = 0;
    container.scrollLeft = 0;
  }
  addNavigationListeners() {
    this.ngZone.runOutsideAngular(() => {
      const removeKeydownListener = this.renderer.listen(this.contentContainer.nativeElement, 'keydown', event => this.navigationService.handleKeyDown(event, this.listViewItems.length));
      const removeFocusInListener = this.renderer.listen(this.contentContainer.nativeElement, 'focusin', event => this.navigationService.handleFocusIn(event));
      const removeFocusOutListener = this.renderer.listen(this.contentContainer.nativeElement, 'focusout', event => this.navigationService.handleFocusOut(event));
      this.removeNavigationListeners = () => {
        removeKeydownListener();
        removeFocusInListener();
        removeFocusOutListener();
      };
    });
  }
  attachEditHandlers() {
    if (!isPresent(this.editService)) {
      return;
    }
    this.editServiceSubscription = this.editService.changes.subscribe(this.emitCRUDEvent.bind(this));
  }
  emitCRUDEvent(args) {
    const {
      action,
      itemIndex,
      formGroup
    } = args;
    let dataItem = this.items[itemIndex];
    if (action !== 'add' && formGroup) {
      dataItem = formGroup.value;
    }
    Object.assign(args, {
      dataItem: dataItem,
      sender: this
    });
    this[action].emit(args);
  }
}
ListViewComponent.ɵfac = function ListViewComponent_Factory(t) {
  return new (t || ListViewComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(EditService), i0.ɵɵdirectiveInject(NavigationService));
};
ListViewComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ListViewComponent,
  selectors: [["kendo-listview"]],
  contentQueries: function ListViewComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ItemTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, HeaderTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, FooterTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, LoaderTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, EditTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.loaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editTemplate = _t.first);
    }
  },
  viewQuery: function ListViewComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
      i0.ɵɵviewQuery(ListViewNavigableItemDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentContainer = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listViewItems = _t);
    }
  },
  hostVars: 6,
  hostBindings: function ListViewComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-listview", ctx.className)("k-d-flex", ctx.className)("k-listview-bordered", ctx.bordered);
    }
  },
  inputs: {
    bordered: "bordered",
    data: "data",
    loading: "loading",
    containerStyle: "containerStyle",
    itemStyle: "itemStyle",
    containerClass: "containerClass",
    itemClass: "itemClass",
    containerLabel: "containerLabel",
    containerRole: "containerRole",
    listItemRole: "listItemRole",
    navigable: "navigable",
    pageSize: "pageSize",
    skip: "skip",
    pageable: "pageable",
    height: "height"
  },
  outputs: {
    scrollBottom: "scrollBottom",
    pageChange: "pageChange",
    pageSizeChange: "pageSizeChange",
    edit: "edit",
    cancel: "cancel",
    save: "save",
    remove: "remove",
    add: "add"
  },
  exportAs: ["kendoListView"],
  features: [i0.ɵɵProvidersFeature([EditService, NavigationService, LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.listview'
  }]), i0.ɵɵNgOnChangesFeature],
  decls: 12,
  vars: 17,
  consts: [[4, "ngIf"], ["class", "k-listview-header", 4, "ngIf"], [1, "k-listview-content", 3, "ngClass", "ngStyle", "kendoEventsOutsideAngular", "scope"], ["contentContainer", ""], ["class", "k-listview-item", "kendoListViewNavigableItem", "", 3, "index", "ngClass", "ngStyle", 4, "ngIf"], ["class", "k-listview-item", "kendoListViewNavigableItem", "", 3, "index", "ngClass", "ngStyle", 4, "ngFor", "ngForOf"], ["class", "k-loading-mask", 4, "ngIf"], ["class", "k-listview-footer", 4, "ngIf"], ["pagerTemplate", ""], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "k-listview-header"], [3, "ngTemplateOutlet"], ["kendoListViewNavigableItem", "", 1, "k-listview-item", 3, "index", "ngClass", "ngStyle"], [1, "k-loading-mask"], [1, "k-loading-text"], [1, "k-loading-image"], [1, "k-loading-color"], [1, "k-listview-footer"], [3, "class", "total", "pageSize", "skip", "buttonCount", "info", "previousNext", "type", "pageSizeValues", "pageChange", "pageSizeChange", 4, "ngIf"], [3, "total", "pageSize", "skip", "buttonCount", "info", "previousNext", "type", "pageSizeValues", "pageChange", "pageSizeChange"]],
  template: function ListViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ListViewComponent_0_Template, 1, 3, null, 0)(1, ListViewComponent_div_1_Template, 2, 1, "div", 1);
      i0.ɵɵelementStart(2, "div", 2, 3);
      i0.ɵɵtemplate(4, ListViewComponent_div_4_Template, 2, 6, "div", 4)(5, ListViewComponent_div_5_Template, 2, 9, "div", 5)(6, ListViewComponent_div_6_Template, 5, 0, "div", 6)(7, ListViewComponent_7_Template, 1, 1, null, 0);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(8, ListViewComponent_div_8_Template, 2, 1, "div", 7)(9, ListViewComponent_9_Template, 1, 3, null, 0)(10, ListViewComponent_ng_template_10_Template, 1, 1, "ng-template", null, 8, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", (ctx.pagerSettings == null ? null : ctx.pagerSettings.position) !== "bottom");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.headerTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngClass", ctx.containerClass)("ngStyle", ctx.containerStyle)("kendoEventsOutsideAngular", i0.ɵɵpureFunction1(15, _c3, ctx.handleContentScroll))("scope", ctx);
      i0.ɵɵattribute("tabindex", ctx.containerTabindex)("role", ctx.containerRole)("aria-label", ctx.containerLabel);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.editService.hasNewItem);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngForOf", ctx.items);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.loading && !ctx.loaderTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.loading && ctx.loaderTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.footerTemplate);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", (ctx.pagerSettings == null ? null : ctx.pagerSettings.position) !== "top");
    }
  },
  dependencies: [i3.PagerComponent, i4.NgIf, i4.NgTemplateOutlet, i4.NgClass, i4.NgStyle, i5.EventsOutsideAngularDirective, ListViewNavigableItemDirective, i4.NgForOf],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListViewComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'kendoListView',
      selector: 'kendo-listview',
      providers: [EditService, NavigationService, LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.listview'
      }],
      template: `
        <!-- top pager -->
        <ng-template
            *ngIf="pagerSettings?.position !== 'bottom'"
            [ngTemplateOutlet]="pagerTemplate"
            [ngTemplateOutletContext]="{ pagerClass: 'k-listview-pager k-listview-pager-top' }"
        >
        </ng-template>

        <!-- header -->
        <div
            *ngIf="headerTemplate"
            class="k-listview-header"
        >
            <ng-template
                [ngTemplateOutlet]="headerTemplate?.templateRef"
            >
            </ng-template>
        </div>

        <!-- content -->
        <div
            #contentContainer
            [attr.tabindex]="containerTabindex"
            class="k-listview-content"
            [ngClass]="containerClass"
            [ngStyle]="containerStyle"
            [kendoEventsOutsideAngular]="{
                scroll: handleContentScroll
            }"
            [scope]="this"
            [attr.role]="containerRole"
            [attr.aria-label]="containerLabel"
        >
            <!-- new item edit template -->
            <div
                *ngIf="editService.hasNewItem"
                class="k-listview-item"
                [attr.role]="listItemRole"
                kendoListViewNavigableItem
                [index]="-1"
                [attr.data-kendo-listview-item-index]="-1"
                [ngClass]="itemClass"
                [ngStyle]="itemStyle"
            >
                <ng-template
                    *ngIf="editTemplate"
                    [ngTemplateOutlet]="editTemplate?.templateRef"
                    [ngTemplateOutletContext]="editTemplateContext(-1)"
                >
                </ng-template>
            </div>

            <!-- items -->
            <div
                *ngFor="let dataItem of items; let index = index; let first = first; let last = last;"
                class="k-listview-item"
                [attr.role]="listItemRole"
                [attr.aria-posinset]="itemPosInSet(index)"
                [attr.aria-setsize]="total"
                kendoListViewNavigableItem
                [index]="index"
                [attr.data-kendo-listview-item-index]="index"
                [ngClass]="itemClass"
                [ngStyle]="itemStyle"
            >
                <ng-template
                    [ngTemplateOutlet]="isEdited(index) ? editTemplate?.templateRef : itemTemplate?.templateRef"
                    [ngTemplateOutletContext]="isEdited(index) ? editTemplateContext(index) : templateContext(index)"
                >
                </ng-template>
            </div>

            <!-- loading indicator -->
            <div
                *ngIf="loading && !loaderTemplate"
                class="k-loading-mask"
            >
                <!-- TODO: the k-loading-text is hidden with css but read by readers - review when implementing accessibility + possible localization case -->
                <span class="k-loading-text">Loading</span>
                <div class="k-loading-image"></div>
                <div class="k-loading-color"></div>
            </div>
            <ng-template
                *ngIf="loading && loaderTemplate"
                [ngTemplateOutlet]="loaderTemplate.templateRef"
            >
            </ng-template>
        </div>

        <!-- footer -->
        <div
            *ngIf="footerTemplate"
            class="k-listview-footer"
        >
            <ng-template
                [ngTemplateOutlet]="footerTemplate?.templateRef"
            >
            </ng-template>
        </div>

        <!-- bottom pager -->
        <ng-template
            *ngIf="pagerSettings?.position !== 'top'"
            [ngTemplateOutlet]="pagerTemplate"
            [ngTemplateOutletContext]="{ pagerClass: 'k-listview-pager' }"
        >
        </ng-template>

        <!-- pager template -->
        <ng-template #pagerTemplate let-pagerClass="pagerClass">
            <kendo-datapager
                *ngIf="pageable"
                [class]="pagerClass"
                [total]="total"
                [pageSize]="pageSize"
                [skip]="skip"
                [buttonCount]="pagerSettings.buttonCount"
                [info]="pagerSettings.info"
                [previousNext]="pagerSettings.previousNext"
                [type]="pagerSettings.type"
                [pageSizeValues]="pagerSettings.pageSizeValues"
                (pageChange)="handlePageChange($event)"
                (pageSizeChange)="pageSizeChange.emit($event)"
            >
            </kendo-datapager>
        </ng-template>
    `
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: EditService
    }, {
      type: NavigationService
    }];
  }, {
    className: [{
      type: HostBinding,
      args: ['class.k-listview']
    }, {
      type: HostBinding,
      args: ['class.k-d-flex']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: [ItemTemplateDirective, {
        static: false
      }]
    }],
    headerTemplate: [{
      type: ContentChild,
      args: [HeaderTemplateDirective, {
        static: false
      }]
    }],
    footerTemplate: [{
      type: ContentChild,
      args: [FooterTemplateDirective, {
        static: false
      }]
    }],
    loaderTemplate: [{
      type: ContentChild,
      args: [LoaderTemplateDirective, {
        static: false
      }]
    }],
    contentContainer: [{
      type: ViewChild,
      args: ['contentContainer', {
        static: true
      }]
    }],
    editTemplate: [{
      type: ContentChild,
      args: [EditTemplateDirective, {
        static: false
      }]
    }],
    listViewItems: [{
      type: ViewChildren,
      args: [ListViewNavigableItemDirective]
    }],
    bordered: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['class.k-listview-bordered']
    }],
    data: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    containerStyle: [{
      type: Input
    }],
    itemStyle: [{
      type: Input
    }],
    containerClass: [{
      type: Input
    }],
    itemClass: [{
      type: Input
    }],
    containerLabel: [{
      type: Input
    }],
    containerRole: [{
      type: Input
    }],
    listItemRole: [{
      type: Input
    }],
    navigable: [{
      type: Input
    }],
    pageSize: [{
      type: Input
    }],
    skip: [{
      type: Input
    }],
    pageable: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    scrollBottom: [{
      type: Output
    }],
    pageChange: [{
      type: Output
    }],
    pageSizeChange: [{
      type: Output
    }],
    edit: [{
      type: Output
    }],
    cancel: [{
      type: Output
    }],
    save: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    add: [{
      type: Output
    }]
  });
})();

/**
 * A directive that encapsulates the in-memory handling of paging
 * ([see example]({% slug paging_listview %}#toc-binding-directive)).
 */
class DataBindingDirective {
  constructor(listView) {
    this.listView = listView;
    this.subscriptions = new Subscription();
  }
  /**
   * The array of data which will be used to populate the ListView.
   */
  set data(data) {
    this._data = data || [];
    this.updateListViewData();
  }
  get data() {
    return this._data;
  }
  ngOnInit() {
    this.subscriptions.add(this.listView.pageChange.subscribe(this.handlePageChange.bind(this)));
    this.subscriptions.add(this.listView.pageSizeChange.subscribe(this.handlePageSizeChange.bind(this)));
    this.updateListViewData();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  handlePageChange(event) {
    this.listView.skip = event.skip;
    this.listView.pageSize = event.take;
    this.updateListViewData();
  }
  handlePageSizeChange(event) {
    this.listView.pageSize = Number(event.newPageSize);
  }
  updateListViewData() {
    const from = this.listView.skip || 0;
    const to = from + (this.listView.pageSize || this.data.length);
    this.listView.data = {
      data: this.data.slice(from, to),
      total: this.data.length
    };
  }
}
DataBindingDirective.ɵfac = function DataBindingDirective_Factory(t) {
  return new (t || DataBindingDirective)(i0.ɵɵdirectiveInject(ListViewComponent));
};
DataBindingDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DataBindingDirective,
  selectors: [["", "kendoListViewBinding", ""]],
  inputs: {
    data: ["kendoListViewBinding", "data"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataBindingDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoListViewBinding]'
    }]
  }], function () {
    return [{
      type: ListViewComponent
    }];
  }, {
    data: [{
      type: Input,
      args: ['kendoListViewBinding']
    }]
  });
})();

/**
 * The arguments of the [`pageSizeChange`]({% slug api_listview_listviewcomponent %}#toc-pagesizechange) event of the ListView
 * ([more details]({% slug paging_listview %}).
 */
class PageSizeChangeEvent extends PageSizeChangeEvent$1 {}

/**
 * Represents the `edit` command of the ListView. You can apply this directive to any `button`
 * element inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`edit`]({% slug api_listview_listviewcomponent %}#toc-edit) event
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewEditCommand class="k-primary">Edit</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 *
 */
class EditCommandDirective extends Button {
  constructor(editService, element, renderer, localization, ngZone) {
    super(element, renderer, null, localization, ngZone);
    this.editService = editService;
    this.elementRef = element;
  }
  /**
   * @hidden
   */
  clickHandler(e) {
    e.preventDefault();
    const index = getClosestListItemIndex(this.elementRef.nativeElement);
    this.editService.beginEdit(index);
  }
}
EditCommandDirective.ɵfac = function EditCommandDirective_Factory(t) {
  return new (t || EditCommandDirective)(i0.ɵɵdirectiveInject(EditService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i2.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
};
EditCommandDirective.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: EditCommandDirective,
  selectors: [["", "kendoListViewEditCommand", ""]],
  hostBindings: function EditCommandDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function EditCommandDirective_click_HostBindingHandler($event) {
        return ctx.clickHandler($event);
      });
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c4,
  ngContentSelectors: _c5,
  decls: 5,
  vars: 3,
  consts: [["innerCssClass", "k-button-icon", 3, "name", "svgIcon", 4, "ngIf"], ["class", "k-button-icon k-icon", 4, "ngIf"], ["class", "k-button-icon", 3, "ngClass", 4, "ngIf"], [1, "k-button-text"], ["innerCssClass", "k-button-icon", 3, "name", "svgIcon"], [1, "k-button-icon", "k-icon"], ["role", "presentation", 1, "k-image", 3, "src"], [1, "k-button-icon", 3, "ngClass"]],
  template: function EditCommandDirective_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, EditCommandDirective_kendo_icon_wrapper_0_Template, 1, 2, "kendo-icon-wrapper", 0)(1, EditCommandDirective_span_1_Template, 2, 1, "span", 1)(2, EditCommandDirective_span_2_Template, 1, 1, "span", 2);
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵprojection(4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.icon || ctx.svgIcon);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.imageUrl);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.iconClass);
    }
  },
  dependencies: [i3$1.IconWrapperComponent, i4.NgIf, i4.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditCommandDirective, [{
    type: Component,
    args: [{
      selector: '[kendoListViewEditCommand]',
      template: `
        <kendo-icon-wrapper
            *ngIf="icon || svgIcon"
            innerCssClass="k-button-icon"
            [name]="icon"
            [svgIcon]="svgIcon"></kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
    }]
  }], function () {
    return [{
      type: EditService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i2.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    clickHandler: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

/**
 * Represents the command for adding a new item to the ListView. You can apply this directive to any
 * `button` element inside a [`HeaderTemplate`]({% slug api_listview_headertemplatedirective %}).
 * When an associated button with the directive is clicked, the
 * [`add`]({% slug api_listview_listviewcomponent %}#toc-add) event is triggered
 * ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *    <ng-template kendoListViewHeaderTemplate>
 *       <button kendoListViewAddCommand>Add new</button>
 *    </ng-template>
 * </kendo-listview>
 * ```
 */
class AddCommandDirective extends Button {
  constructor(editService, element, renderer, localization, ngZone) {
    super(element, renderer, null, localization, ngZone);
    this.editService = editService;
  }
  /**
   * @hidden
   */
  clickHandler(e) {
    e.preventDefault();
    this.editService.beginAdd();
  }
}
AddCommandDirective.ɵfac = function AddCommandDirective_Factory(t) {
  return new (t || AddCommandDirective)(i0.ɵɵdirectiveInject(EditService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i2.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
};
AddCommandDirective.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AddCommandDirective,
  selectors: [["", "kendoListViewAddCommand", ""]],
  hostBindings: function AddCommandDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function AddCommandDirective_click_HostBindingHandler($event) {
        return ctx.clickHandler($event);
      });
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c6,
  ngContentSelectors: _c5,
  decls: 5,
  vars: 3,
  consts: [["innerCssClass", "k-button-icon", 3, "name", "svgIcon", 4, "ngIf"], ["class", "k-button-icon k-icon", 4, "ngIf"], ["class", "k-button-icon", 3, "ngClass", 4, "ngIf"], [1, "k-button-text"], ["innerCssClass", "k-button-icon", 3, "name", "svgIcon"], [1, "k-button-icon", "k-icon"], ["role", "presentation", 1, "k-image", 3, "src"], [1, "k-button-icon", 3, "ngClass"]],
  template: function AddCommandDirective_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, AddCommandDirective_kendo_icon_wrapper_0_Template, 1, 2, "kendo-icon-wrapper", 0)(1, AddCommandDirective_span_1_Template, 2, 1, "span", 1)(2, AddCommandDirective_span_2_Template, 1, 1, "span", 2);
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵprojection(4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.icon || ctx.svgIcon);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.imageUrl);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.iconClass);
    }
  },
  dependencies: [i3$1.IconWrapperComponent, i4.NgIf, i4.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AddCommandDirective, [{
    type: Component,
    args: [{
      selector: '[kendoListViewAddCommand]',
      template: `
        <kendo-icon-wrapper
            *ngIf="icon || svgIcon"
            innerCssClass="k-button-icon"
            [name]="icon"
            [svgIcon]="svgIcon"></kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
    }]
  }], function () {
    return [{
      type: EditService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i2.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    clickHandler: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

/**
 * Represents the `save` command of the ListView. You can apply this directive to any `button`
 * element inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`save`]({% slug api_listview_listviewcomponent %}#toc-save) event
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewSaveCommand>Save changes</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 *
 * You can control the content of the button based on the state of the item.
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate let-isNew="isNew">
 *     <button kendoListViewSaveCommand>{{isNew ? 'Add' : 'Update'}}</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 */
class SaveCommandDirective extends Button {
  constructor(editService, element, renderer, localization, ngZone) {
    super(element, renderer, null, localization, ngZone);
    this.editService = editService;
    this.elementRef = element;
  }
  /**
   * @hidden
   */
  clickHandler(e) {
    e.preventDefault();
    const index = getClosestListItemIndex(this.elementRef.nativeElement);
    if (this.editService.isEdited(index)) {
      this.editService.save(index);
    }
  }
}
SaveCommandDirective.ɵfac = function SaveCommandDirective_Factory(t) {
  return new (t || SaveCommandDirective)(i0.ɵɵdirectiveInject(EditService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i2.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
};
SaveCommandDirective.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SaveCommandDirective,
  selectors: [["", "kendoListViewSaveCommand", ""]],
  hostBindings: function SaveCommandDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function SaveCommandDirective_click_HostBindingHandler($event) {
        return ctx.clickHandler($event);
      });
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c7,
  ngContentSelectors: _c5,
  decls: 5,
  vars: 3,
  consts: [["innerCssClass", "k-button-icon", 3, "name", "svgIcon", 4, "ngIf"], ["class", "k-button-icon k-icon", 4, "ngIf"], ["class", "k-button-icon", 3, "ngClass", 4, "ngIf"], [1, "k-button-text"], ["innerCssClass", "k-button-icon", 3, "name", "svgIcon"], [1, "k-button-icon", "k-icon"], ["role", "presentation", 1, "k-image", 3, "src"], [1, "k-button-icon", 3, "ngClass"]],
  template: function SaveCommandDirective_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, SaveCommandDirective_kendo_icon_wrapper_0_Template, 1, 2, "kendo-icon-wrapper", 0)(1, SaveCommandDirective_span_1_Template, 2, 1, "span", 1)(2, SaveCommandDirective_span_2_Template, 1, 1, "span", 2);
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵprojection(4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.icon || ctx.svgIcon);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.imageUrl);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.iconClass);
    }
  },
  dependencies: [i3$1.IconWrapperComponent, i4.NgIf, i4.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SaveCommandDirective, [{
    type: Component,
    args: [{
      selector: '[kendoListViewSaveCommand]',
      template: `
        <kendo-icon-wrapper
            *ngIf="icon || svgIcon"
            innerCssClass="k-button-icon"
            [name]="icon"
            [svgIcon]="svgIcon"></kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
    }]
  }], function () {
    return [{
      type: EditService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i2.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    clickHandler: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

/**
 * Represents the `cancel` command of the ListView. You can apply this directive to any `button`
 * element inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`cancel`]({% slug api_listview_listviewcomponent %}#toc-cancel) event
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewCancelCommand>Cancel changes</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 *
 * You can control the content of the button based on the state of the item.
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate let-isNew="isNew">
 *     <button kendoListViewCancelCommand>{{isNew ? 'Discard' : 'Cancel changes'}}</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 */
class CancelCommandDirective extends Button {
  constructor(editService, element, renderer, localization, ngZone) {
    super(element, renderer, null, localization, ngZone);
    this.editService = editService;
    this.elementRef = element;
  }
  /**
   * @hidden
   */
  clickHandler(e) {
    e.preventDefault();
    const index = getClosestListItemIndex(this.elementRef.nativeElement);
    if (this.editService.isEdited(index)) {
      this.editService.endEdit(index);
    }
  }
}
CancelCommandDirective.ɵfac = function CancelCommandDirective_Factory(t) {
  return new (t || CancelCommandDirective)(i0.ɵɵdirectiveInject(EditService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i2.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
};
CancelCommandDirective.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CancelCommandDirective,
  selectors: [["", "kendoListViewCancelCommand", ""]],
  hostBindings: function CancelCommandDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function CancelCommandDirective_click_HostBindingHandler($event) {
        return ctx.clickHandler($event);
      });
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c8,
  ngContentSelectors: _c5,
  decls: 5,
  vars: 3,
  consts: [["innerCssClass", "k-button-icon", 3, "name", "svgIcon", 4, "ngIf"], ["class", "k-button-icon k-icon", 4, "ngIf"], ["class", "k-button-icon", 3, "ngClass", 4, "ngIf"], [1, "k-button-text"], ["innerCssClass", "k-button-icon", 3, "name", "svgIcon"], [1, "k-button-icon", "k-icon"], ["role", "presentation", 1, "k-image", 3, "src"], [1, "k-button-icon", 3, "ngClass"]],
  template: function CancelCommandDirective_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, CancelCommandDirective_kendo_icon_wrapper_0_Template, 1, 2, "kendo-icon-wrapper", 0)(1, CancelCommandDirective_span_1_Template, 2, 1, "span", 1)(2, CancelCommandDirective_span_2_Template, 1, 1, "span", 2);
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵprojection(4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.icon || ctx.svgIcon);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.imageUrl);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.iconClass);
    }
  },
  dependencies: [i3$1.IconWrapperComponent, i4.NgIf, i4.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CancelCommandDirective, [{
    type: Component,
    args: [{
      selector: '[kendoListViewCancelCommand]',
      template: `
        <kendo-icon-wrapper
            *ngIf="icon || svgIcon"
            innerCssClass="k-button-icon"
            [name]="icon"
            [svgIcon]="svgIcon"></kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
    }]
  }], function () {
    return [{
      type: EditService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i2.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    clickHandler: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

/**
 * Represents the `remove` command of the ListView. You can apply this directive to any `button` element
 * inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`remove` event]({% slug api_listview_listviewcomponent %}#toc-remove)
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewRemoveCommand>Remove item</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 */
class RemoveCommandDirective extends Button {
  constructor(editService, element, renderer, localization, ngZone) {
    super(element, renderer, null, localization, ngZone);
    this.editService = editService;
    this.elementRef = element;
  }
  /**
   * @hidden
   */
  clickHandler(e) {
    e.preventDefault();
    const index = getClosestListItemIndex(this.elementRef.nativeElement);
    this.editService.remove(index);
  }
}
RemoveCommandDirective.ɵfac = function RemoveCommandDirective_Factory(t) {
  return new (t || RemoveCommandDirective)(i0.ɵɵdirectiveInject(EditService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i2.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
};
RemoveCommandDirective.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RemoveCommandDirective,
  selectors: [["", "kendoListViewRemoveCommand", ""]],
  hostBindings: function RemoveCommandDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function RemoveCommandDirective_click_HostBindingHandler($event) {
        return ctx.clickHandler($event);
      });
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c9,
  ngContentSelectors: _c5,
  decls: 5,
  vars: 3,
  consts: [["innerCssClass", "k-button-icon", 3, "name", "svgIcon", 4, "ngIf"], ["class", "k-button-icon k-icon", 4, "ngIf"], ["class", "k-button-icon", 3, "ngClass", 4, "ngIf"], [1, "k-button-text"], ["innerCssClass", "k-button-icon", 3, "name", "svgIcon"], [1, "k-button-icon", "k-icon"], ["role", "presentation", 1, "k-image", 3, "src"], [1, "k-button-icon", 3, "ngClass"]],
  template: function RemoveCommandDirective_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, RemoveCommandDirective_kendo_icon_wrapper_0_Template, 1, 2, "kendo-icon-wrapper", 0)(1, RemoveCommandDirective_span_1_Template, 2, 1, "span", 1)(2, RemoveCommandDirective_span_2_Template, 1, 1, "span", 2);
      i0.ɵɵelementStart(3, "span", 3);
      i0.ɵɵprojection(4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.icon || ctx.svgIcon);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.imageUrl);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.iconClass);
    }
  },
  dependencies: [i3$1.IconWrapperComponent, i4.NgIf, i4.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RemoveCommandDirective, [{
    type: Component,
    args: [{
      selector: '[kendoListViewRemoveCommand]',
      template: `
        <kendo-icon-wrapper
            *ngIf="icon || svgIcon"
            innerCssClass="k-button-icon"
            [name]="icon"
            [svgIcon]="svgIcon"></kendo-icon-wrapper>
        <span *ngIf="imageUrl" class="k-button-icon k-icon">
            <img [src]="imageUrl" class="k-image" role="presentation" />
        </span>
        <span *ngIf="iconClass" class="k-button-icon" [ngClass]="iconClass"></span>
        <span class="k-button-text"><ng-content></ng-content></span>
    `
    }]
  }], function () {
    return [{
      type: EditService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i2.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    clickHandler: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

/**
 * @hidden
 */
class TemplateContextDirective {
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  set templateContext(context) {
    if (this.insertedViewRef) {
      this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.insertedViewRef));
      this.insertedViewRef = undefined;
    }
    if (context.templateRef) {
      this.insertedViewRef = this.viewContainerRef.createEmbeddedView(context.templateRef, context);
    }
  }
}
TemplateContextDirective.ɵfac = function TemplateContextDirective_Factory(t) {
  return new (t || TemplateContextDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
TemplateContextDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TemplateContextDirective,
  selectors: [["", "templateContext", ""]],
  inputs: {
    templateContext: "templateContext"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TemplateContextDirective, [{
    type: Directive,
    args: [{
      selector: '[templateContext]' // eslint-disable-line
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    templateContext: [{
      type: Input
    }]
  });
})();
const TEMPLATE_DIRECTIVES = [ItemTemplateDirective, HeaderTemplateDirective, FooterTemplateDirective, LoaderTemplateDirective];
const BINDING_DIRECTIVES = [DataBindingDirective];
const EDITING_DIRECTIVES = [EditTemplateDirective, EditCommandDirective, CancelCommandDirective, SaveCommandDirective, RemoveCommandDirective, AddCommandDirective];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the ListView component.
 * @example
 *
 * ```ts
 * import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { NgModule } from '@angular/core';
 * import { ListViewModule } from '@progress/kendo-angular-listview';
 * import { AppComponent } from './app.component';
 *
 * @NgModule({
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, BrowserAnimationsModule, ListViewModule],
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *```
 */
class ListViewModule {}
ListViewModule.ɵfac = function ListViewModule_Factory(t) {
  return new (t || ListViewModule)();
};
ListViewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ListViewModule
});
ListViewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PagerModule, EventsModule, IconsModule], CommonModule, EventsModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListViewModule, [{
    type: NgModule,
    args: [{
      declarations: [TEMPLATE_DIRECTIVES, BINDING_DIRECTIVES, EDITING_DIRECTIVES, ListViewComponent, TemplateContextDirective, ListViewNavigableItemDirective],
      exports: [TEMPLATE_DIRECTIVES, BINDING_DIRECTIVES, EDITING_DIRECTIVES, ListViewComponent, CommonModule, EventsModule],
      imports: [CommonModule, PagerModule, EventsModule, IconsModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AddCommandDirective, CancelCommandDirective, DataBindingDirective, EditCommandDirective, EditTemplateDirective, FooterTemplateDirective, HeaderTemplateDirective, ItemTemplateDirective, ListViewComponent, ListViewModule, LoaderTemplateDirective, PageSizeChangeEvent, RemoveCommandDirective, SaveCommandDirective };