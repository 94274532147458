/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { signal } from '@angular/core';

export interface LangEntry {name: string, code: string, lang: string, locale: string}

export const langData = new Map<string, LangEntry>([
  ["en", {name: "English", code: "gb", lang: "en", locale: "en-GB"}],
  ["de", {name: "Deutsch", code: "de", lang: "de", locale: "de-DE"}],
  ["fr", {name: "Français", code: "fr", lang: "fr", locale: "fr-FR"}]
]);

export const defaultLangData = {name: "Deutsch", code: "de", lang: "de", locale: "de-DE"};

export const currentLangData = signal(defaultLangData)