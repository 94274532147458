/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Injectable } from '@angular/core';
import { type AuthRequest, type AuthResult, type UserRegistration } from '@snipocc/api';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, concatMap, from, type Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import addSeconds from 'date-fns/addSeconds';
import isBefore from 'date-fns/isBefore';
import { environment } from '@env/environment';
import * as JOSE from 'jose';
import { type JWTPayload } from 'jose';
import { map } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { Router } from '@angular/router';


export const TOKEN_ID_ITEM_KEY = 'id_token';
export const TOKEN_EXPIRES_ITEM_KEY = 'expires_at';

export interface AccessToken extends JWTPayload {
  scp?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  public userLoggedIn$ = new BehaviorSubject<boolean>(this.isLoggedIn());
  private tokenSubject = new Subject<string | null>();
  /**
   * Emits whenever the token is updated, and performs validation for said token.
   * May emit null if the token was just cleared, or the current token fails validation.
   *
   * For eager token updates with validation, see {@link getToken}
   */
  public tokenUpdated$ = this.tokenSubject.pipe(
    switchMap(t => {
      if (t) {
        return this.verifyToken(t).pipe(concatMap(() => t));
      }
      return of(null);
    }),
    catchError(
      (err: JOSE.errors.JOSEError) => {
        this.logout(true);
        console.error(err);
        return of(null);
      },
    ),
  );
  private endpoint = `${environment.apiURL}/auth`;
  private JWKS = JOSE.createRemoteJWKSet(new URL(`${environment.backendURL == '' ? location.origin : environment.backendURL}/.well-known/jwks.json`));

  constructor(private http: HttpClient, private router: Router) {
  }

  public login(authRequest: AuthRequest) {
    return this.http.post<AuthResult>(`${this.endpoint}/authenticate`, authRequest)
      .pipe(tap(result => this.setSession(result)), shareReplay(), tap(() => this.userLoggedIn$.next(this.isLoggedIn())));
  }

  public register(registration: UserRegistration) {
    return this.http.post(`${this.endpoint}/register`, registration, { observe: 'response' });
  }

  public activate(token: string) {
    return this.http.post(`${this.endpoint}/activate`, token, { observe: 'response' });
  }

  public setUserActive(id: string, state: boolean) {
    return this.http.post(`${this.endpoint}/activate/${id}`, state, { observe: 'response' });
  }

  public requestReset(email: string) {
    return this.http.post(`${this.endpoint}/requestPasswordReset`, email, { observe: 'response' });
  }

  public passwordReset(password: string, key: string) {
    return this.http.post(`${this.endpoint}/passwordReset`, password, { observe: 'response', params: { key } });
  }

  public logout(redirectToLogin = false) {
    this.clearSession();

    if (redirectToLogin) {
      void this.router.navigateByUrl('/login');
    }
    this.userLoggedIn$.next(this.isLoggedIn());
  }

  public isLoggedIn(): boolean {
    const expiration = this.getExpiration();
    if (!expiration) {
      return false;
    }
    return isBefore(new Date(), expiration);
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }

  public getExpiration() {
    const expiration = localStorage.getItem(TOKEN_EXPIRES_ITEM_KEY);
    if (expiration) {
      return parseISO(JSON.parse(expiration) as string);
    }
    return null;
  }

  /**
   * Returns an observable that always emits the current validated token,
   * or null iff there is no token, or it fails validation.
   *
   * For lazy token updates with validation, see {@link tokenUpdated$}
   */
  public getToken(): Observable<string | null> {
    const token = localStorage.getItem(TOKEN_ID_ITEM_KEY);
    if (token) { // verify token hasn't been altered
      return this.verifyToken(token).pipe(catchError((err: JOSE.errors.JOSEError) => {
          this.logout(true);
          console.error(err);
          return of(null);
        }),
        concatMap(() => of(token)));
    }
    return of(null);
  }

  public getTokenAsJwt(): Observable<AccessToken | null> {
    return this.getToken().pipe(map(t => {
      return t !== null ? JOSE.decodeJwt(t) : null;
    }));
  }

  public setSession(result: AuthResult) {
    const expiresAt = addSeconds(new Date(), result.expiresIn);
    localStorage.setItem(TOKEN_ID_ITEM_KEY, result.accessToken);
    localStorage.setItem(TOKEN_EXPIRES_ITEM_KEY, JSON.stringify(expiresAt));
    this.tokenSubject.next(result.accessToken);
  }

  public verifyToken(jwt: string) {
    return from(JOSE.jwtVerify(jwt, this.JWKS));
  }

  private clearSession() {
    localStorage.removeItem(TOKEN_ID_ITEM_KEY);
    localStorage.removeItem(TOKEN_EXPIRES_ITEM_KEY);
    this.tokenSubject.next(null);
  }
}