/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { Component } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { UserService } from '@core/services/user.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-preferences-dialog',
  templateUrl: './user-preferences-dialog.component.html',
  styleUrls: ['./user-preferences-dialog.component.scss']
})
export class UserPreferencesDialogComponent extends DialogContentBase {

  protected form = this.fb.group({
    input: ['']
  })

  constructor(public override dialog: DialogRef,
              private fb: FormBuilder,
              private userService: UserService) {
    super(dialog)
  }

  protected cancel() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  protected save() {
    if (this.dialog) {
      this.dialog.close();
    }
  }
}
